import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import QuestoesTabs from './QuestoesTabs';

const Container = styled.div`
  width: 900px;
  margin-bottom: 8px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  /* color: theme.palette.text.clear; */
  flex-direction: column;
  @media (max-width: 1280px) {
    width: 70px;
    margin: 0 auto;
    margin-top: 16px;
    justify-content: center;
  }
  @media (max-width: 720px) {
    width: 0;
    padding: 0;
    margin-bottom: -65px;
  }
`;

function Simulados() {
  const history = useHistory();

  useEffect(() => {
    history.push(`/simulados${window.location.search}`);
  }, []);

  return (
    <Container>
      <QuestoesTabs />
    </Container>
  );
}

export default Simulados;
