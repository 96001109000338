import React from "react";
import styled from "styled-components";

import IconButton from "material-ui/IconButton";
import BackIcon from "material-ui-icons/ArrowBack";

const CollapseButtonContainer = styled.div`
  background: rgb(239, 239, 239);
  cursor: pointer;
  width: 128px;
  height: 30px;
  display: flex;
  border-radius: 4px;
  justify-content: space-evenly;
  align-content: center;
`;
const CollapseButtonIcon = styled.div`
  flex: 1;
  width: 18px;
  height: 18px;
  align-self: center;
`;

const CollapseButtonText = styled.p`
  flex: 3;
  margin: 0px;
  align-self: center;
  color: rgba(0, 0, 0, 0.5);
  font-family: "Proxima Nova";
  font-size: 13px;
  line-height: 13px;
  font-weight: 500;
  letter-spacing: 0px;
`;

export default function SidebarCollapseButton({ show, setShow }) {
  return (
    <CollapseButtonContainer onClick={() => setShow(!show)}>
      <CollapseButtonIcon>
        <BackIcon
          style={{ color: "rgb(0,0,0,0.6)", width: "100%", height: "100%", transform: show ? "rotate(0deg)" : "rotate(180deg)"}}
        />
      </CollapseButtonIcon>
      <CollapseButtonText>
        {show ? "Ocultar menu" : "Exibir menu"}
      </CollapseButtonText>
    </CollapseButtonContainer>
  );
}
