import React, { Component } from 'react';
import { withStyles } from 'material-ui/styles';
import ItemAula from './ItemAula';
import { FetchList } from '../../components';
import { ElementList } from '../../components/common';
import ModalGabarito from '../Simulados.old/ModalGabarito';
import ModalSimulado from '../Questoes';
import ModalVideo from './ModalVideo';
import ModalRedacao from './ModalRedacao';
import { SORT_ASC } from '../../reducers/resource/list/queryReducer';

const styles = theme => ({
  wrapper: {
    flex: 1,
    background: theme.palette.background.primary,
    paddingTop: '64px'
  }
})

const resource = 'courseClass/user';
const sort = { field: 'number', order: SORT_ASC }

class CursoContent extends Component {
  state = {
    modal: false,
    type: null,
    classData: null
  }

  handleModal = (_id, type, classData) => {
    this.setState({ modal: _id, type, classData })
  }

  render() {
    const { classes, course, refreshState } = this.props;
    const { modal, type, classData } = this.state;

    return (
      <div className={classes.wrapper}>
        <FetchList {...this.props}
          filter={{ payload: { course: course._id } }}
          stateRefresh={refreshState}
          sort={sort}
          resource={resource} >
          <ElementList
            renderRow={(props) => <ItemAula {...props} course={course}
              handleModal={this.handleModal} />}
          />
        </FetchList>

        <ModalGabarito handleOpen={this.handleModal} open={type === 'gabarito' && modal} />
        <ModalSimulado handleOpen={this.handleModal} open={type === 'simulado' && modal} />
        <ModalVideo handleOpen={this.handleModal} open={type === 'video' && modal} course={course} classData={classData} />
        <ModalRedacao handleOpen={this.handleModal} open={/redacao/.test(type) && modal} type={type} />
      </div>
    )
  }
}

export default withStyles(styles)(CursoContent);
