import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import compose from 'recompose/compose';
import { withStyles } from 'material-ui/styles';
import ModalBusca from './ModalBusca';

const styles = theme => ({});

class Busca extends Component {
  constructor(props) {
    super(props);

    const { resource = false }  = props.routeParams;

    this.state = {
      modal: resource
    }
  }
  
  handleModal = (resource) => {
    this.setState({modal: resource})
    this.props.pushRouter(resource ? `/busca/${resource}?q=brasil` : '/clippings')
  }

  componentWillReceiveProps(nextProps) {
    const { resource } = nextProps.routeParams;

    if(this.props.routeParams.resource !== resource) {
      this.setState({ modal: resource || false })
    }
  }

  render() {
    const { modal } = this.state;
    
    return (
      <ModalBusca {...this.props} handleOpen={this.handleModal} open={modal} />
    )
  }
}

const mapDispatchToProps = {
  pushRouter: (path) => push(path),
}

const mapStateToProps = (state, ownProps) => {
  return {}
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(Busca);
