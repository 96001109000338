import React from 'react';
import Button from 'material-ui/Button';
import { withStyles } from 'material-ui/styles';
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'material-ui/Dialog';
import Close from 'material-ui-icons/Close'
import IconButton from 'material-ui/IconButton';
import { crudUpdate as crudUpdateAction } from '../../actions/dataActions';
import { reduxForm, Field, change, SubmissionError } from 'redux-form';
import { renderTextField } from '../../utils/form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';

const resource = 'notebook'

const styles = theme => ({
    container: {
      borderRadius: '4px',
      [theme.breakpoints.up('md')]: {
        minWidth: '700px'
        },
    },
    title: {
        color: theme.palette.orange,
        borderBottom: '1px solid #96969626',
        fontSize: '18px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: '600',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '80px',
        paddingLeft: '16px',
        paddingRight: '16px'
    },
    close: {
        color: theme.palette.text.clear,
        cursor: 'pointer',
        marginTop: '-15px',
        width: '20px',
        '&:hover': {
            color: theme.palette.orange,
        },
    },
    content: {
        padding: '18px',
    }
})

class ModalAddPost extends React.Component {

  handleClose = () => {
    this.props.handleOpen(false)
  };

  updatePost = (post) => {
    const {title} = post
    const {crudUpdate, _id, trackMetric} = this.props
    const data =  {
        title,
    }
    if(!title) {
        throw new SubmissionError({ 
            title: !title ? 'Dê um título ao seu caderno.' : false, 
            _error: 'Preencha o título do caderno antes de publicar!' 
        })
    }

    trackMetric('Caderno - Ativação', { target: 'notebook', trigger: 'click', resource: _id, action: 'edit' })
    crudUpdate(resource, _id, data, {}, '/cadernos', false)
    this.handleClose()
  }

  render() {
    const {classes, handleSubmit, open} = this.props

    return (
        <Dialog
            open={Boolean(open)}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
            classes={{paper: classes.container}}
        >
            <DialogTitle disableTypography className={classes.title}>
                Editar Caderno
                <div className={classes.close} >
                    
                </div>
                <IconButton
                    className={classes.close}
                    aria-label="Fechar"
                    onClick={this.handleClose} >
                        <Close/> 
                    </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(this.updatePost)}>
            <DialogContent className={classes.content}>
                <Field fullWidth margin="dense" label="Título" name="title" id="title" component={renderTextField} />
            </DialogContent>
            <DialogActions>
                <Button type="submit" color="primary">
                    SALVAR
                </Button>
            </DialogActions>
            </form>
        </Dialog>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
    const {title, _id} = state[resource].data[ownProps.open] || {}
    return  {
        submitting: state.waiting,
        initialValues: {title},
        _id
    }
}
  
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        changeForm: (field, value) => {
            dispatch(change(resource, field, value))
        },
        crudUpdate : (...params) => {
            dispatch(crudUpdateAction(...params))
        },
        trackMetric: (name, data) => dispatch(trackMetricAction(name, data))
    }
}

const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        enableReinitialize: true,
        form: resource + 'edit',
        validate: (values, props) => {
            const errors = {};
            if (!values.title) {
                errors.title = 'Dê um título ao seu caderno.';
            }
            return errors;
        }
    }),
);

export default enhance(ModalAddPost)
