import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { courseModalAction } from "../../containers/Cursos/actions";

import IconButton from "material-ui/IconButton";
import BackIcon from "material-ui-icons/ArrowBack";
// import ClockIcon from "material-ui-icons/Timer";

import Dropdown from "../Dropdown";
import { push } from "react-router-redux";

const CourseHeaderContainer = styled.div`
  overflow: visible;
  z-index: 6;
  position: relative;
  width: 100%;
  background: ${(props) =>
    props.backgroundUrl ? `url(${props.backgroundUrl})` : "rgba(0,0,0,0.8)"};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-blend-mode: multiply; */
  box-shadow: 0px 1px 0px 0px rgb(232, 232, 232);
  height: 220px;
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
`;

const NavItems = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
`;

const BackButton = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  padding: 10px 0px 0px 12px;
`;

function CourseHeader({ backgroundUrl }) {
  const dispatch = useDispatch();
  function hideCourse() {
    dispatch(push("/cursos"));
  }

  return (
    <CourseHeaderContainer backgroundUrl={backgroundUrl}>
      <NavItems>
        <BackButton>
          <IconButton
            style={{ color: "#fff" }}
            aria-label="voltar"
            onClick={() => hideCourse()}
          >
            <BackIcon />
          </IconButton>
        </BackButton>
      </NavItems>
      <Dropdown scrolled={false} />
    </CourseHeaderContainer>
  );
}

export default CourseHeader;
