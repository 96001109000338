import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from 'material-ui/styles';
import Select from 'material-ui/Select';
import { MenuItem } from 'material-ui/Menu';
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';
import _ from 'lodash'; 

const styles = theme => ({
    root: {
        color: theme.palette.black.a4,
        '&:hover': {
            color: theme.palette.black.a6
        }
    },
    select: {
        fontWeight: 600,
        letterSpacing: '0.54px',
        fontSize: '14px',
        color: 'inherit'
    },
    icon: {
        color: 'inherit',
        marginTop: '-2px'
    },
    container: {
        float: 'right'
    }
});

class SectionFilter extends Component {
    render() {
        const { classes, filterValues, setFilters, doeSections, trackMetric } = this.props;

        return (
            <div className={classes.container}>
                <Select 
                    classes={{
                        root: classes.root,
                        select: classes.select,
                        icon: classes.icon
                    }}
                    value={filterValues.doeSection || ''}
                    onChange={({target: { value: doeSection }}) => {
                        setFilters(doeSection ? {doeSection} : {})
                        trackMetric('Discursos Oficiais - Ativação', { target: 'index', trigger: 'click', resource: doeSection })
                    }}
                    displayEmpty
                    disableUnderline={true}
                    inputProps={{name: 'doeSection'}}
                    onClick={() => trackMetric('Discursos Oficiais - Intenção de uso', { target: 'index', trigger: 'click' })}
                >
                    <MenuItem value="">
                        <span>ÍNDICE</span>
                    </MenuItem>
                    {
                        _.keys(doeSections).map((_id, key) => {
                            let doeSection = doeSections[_id];
                            return <MenuItem key={key} value={_id}>{doeSection.title}</MenuItem>
                        })
                    }
                </Select>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        trackMetric: (name, data) => dispatch(trackMetricAction(name, data))
    }
}

export default compose(
    withStyles(styles),
    connect(null, mapDispatchToProps),
)(SectionFilter);