import { FETCH_END } from '../../actions/fetchActions';
import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    LIKE,
    DELETE,
    GET_LIST_BY_ONE,
    CLEAR
} from '../../services/rest_types';

import {
    syncCommentQtd,
    SYNC_COMMENTS,
} from '../../actions/commentsActions';

import _ from 'lodash'; 

/**
 * The data state is an instance pool, which keeps track of the fetch date of each instance.
 *
 * @example
 * {
 *   23: { id: 23, title: 'War and Peace' },
 *   67: { id: 67, title: 'Anna Karenina' },
 *   fetchedAt: { // non enumerable
 *     23: new Date('2016-08-05T19:33:15.012Z'),
 *     67: new Date('2016-08-05T19:33:43.449Z'),
 *   },
 * }
 */

const cacheDuration = 24 * 60 * 60 * 1000; // one day

/**
 * Add new records to the pool, and remove outdated ones.
 *
 * This is the equivalent of a stale-while-revalidate caching strategy:
 * The cached data is displayed before fetching, and stale data is removed
 * only once fresh data is fetched.
 */
const addRecords = (newRecords = [], oldRecords, type) => {
    // prepare new records and timestamp them
    const newRecordsById = newRecords.reduce((prev, record) => {
        prev[record._id] = record; // eslint-disable-line no-param-reassign
        return prev;
    }, {});
    const now = new Date();
    const newRecordsFetchedAt = newRecords.reduce((prev, record) => {
        prev[record._id] = now; // eslint-disable-line no-param-reassign
        return prev;
    }, {});
    // remove outdated old records
    const latestValidDate = new Date();
    latestValidDate.setTime(latestValidDate.getTime() - cacheDuration);
    const oldValidRecordIds = oldRecords.fetchedAt
        ? _.keys(oldRecords.fetchedAt)
            .filter(id => oldRecords.fetchedAt[id] > latestValidDate)
        : [];
    const oldValidRecords = oldValidRecordIds.reduce((prev, id) => {
        prev[id] = oldRecords[id]; // eslint-disable-line no-param-reassign
        return prev;
    }, {});
    const oldValidRecordsFetchedAt = oldValidRecordIds.reduce((prev, id) => {
        prev[id] = oldRecords.fetchedAt[id]; // eslint-disable-line no-param-reassign
        return prev;
    }, {});

    let records = {}
    // combine old records and new records, add on top if type = CREATE
    if(type === CREATE) {
        records = {
            ...newRecordsById,            
            ...oldValidRecords,
        };
        Object.defineProperty(records, 'fetchedAt', { value: {
            ...newRecordsFetchedAt,
            ...oldValidRecordsFetchedAt,
        } });
    } else if(type === GET_ONE) {
        records = {
            ...oldValidRecords,
            ...newRecordsById,
        };
        Object.defineProperty(records, 'fetchedAt', { value: {
            ...oldValidRecordsFetchedAt,
            ...newRecordsFetchedAt,
        } }); // non enumerable by default
    }else {
        records = {
            ...oldValidRecords,
            ...newRecordsById,
        };
        Object.defineProperty(records, 'fetchedAt', { value: {
            ...oldValidRecordsFetchedAt,
            ...newRecordsFetchedAt,
        } });
    }
    
    return records;
};

const likeItem = ({id, like, user}, previousState ) => {
    const likes = previousState[id].likes
    if(like) {
        likes.push(user.toString())
    } else {
        likes.pop(user.toString())
    }
    return {...previousState, [id]: {...previousState[id], likes: likes.slice(0)}}
}

const deleteRecord = (requestPayload = {}, oldRecords) => {
    delete oldRecords[requestPayload.id]
    return _.assign({}, {...oldRecords})
}

const createRecord = (newRecord, oldRecords) => {
    return _.assign({}, {...oldRecords, [newRecord._id]: newRecord})
}

const initialState = {};
Object.defineProperty(initialState, 'fetchedAt', { value: {} }); // non enumerable by default

export default resource => (previousState = initialState, { payload, meta, requestPayload }) => {
    
    if (!meta || (meta.resource !== resource && !meta.clear) ) {
        return previousState;
    }
    if ((!meta.fetchResponse || meta.fetchStatus !== FETCH_END) && !meta.clear) {
        return previousState;
    }

    switch (meta.fetchResponse) {
    case GET_LIST:
    case GET_MANY:
    case GET_MANY_REFERENCE:
    case GET_LIST_BY_ONE:
        return addRecords(payload.data, previousState, GET_LIST);
    case GET_ONE:
        return addRecords([payload.data], previousState, GET_ONE);
    case UPDATE:
    case CREATE:
        return createRecord(payload.data, previousState, CREATE);
    case SYNC_COMMENTS: 
        return syncCommentQtd(previousState, meta)
    case LIKE:
        return likeItem(payload.data, previousState);
    case DELETE:
        return deleteRecord(requestPayload, previousState)
    case CLEAR:
        return initialState
    default:
        return previousState;
    }
};

export const getRecord = (state, id) => state[id];
