import { SET_AUTH_DATA } from "../actions/clippingChatAction";

export default (
  previousState = { refreshState: 0, responsive: false },
  { type, payload }
) => {
  switch (type) {
    case SET_AUTH_DATA:
      return {...previousState, userId: payload.userId, authToken: payload.authToken}; ;
    default:
      return previousState;
  }
};
