import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Avatar } from "@clippingcacd/core";
import moment from 'moment'
import Button from 'material-ui/Button';
import Edit from 'material-ui-icons/Edit'
import Delete from 'material-ui-icons/Delete'

import { Buttons as SocialButton } from '../../../components/Social'
import { trackMetric as trackMetricAction } from '../../../actions/plataformActions';

import * as S from "./styles";

export default function PostCard({ createdAt, updatedAt, _id, classes, title, openModal, initialLikes, likes = [], text, comments = [], avatar, verified, authorName = "", user, loggedUser, image, externalId, handleLikePost }) {
    const dispatch = useDispatch();
    const contentRef = useRef(null);
    const [readMore, setReadMore] = useState(false);
    const date = moment(createdAt);
    const updatedDate = moment(updatedAt);

    function handleDelete(event) {
        event.stopPropagation();
        deletePost(_id)
    }

    function handleEdit(event) {
        event.stopPropagation();
        editPost(_id)
    }

    function handleLike(_id) {
        let wasLiked = likes.includes(loggedUser._id.toString());

        dispatch(trackMetricAction('Mural - Ativação', {
            target: 'like',
            trigger: 'click',
            resource: _id,
            action: wasLiked ? 'delete' : 'create'
        }));
       

        handleLikePost(_id);
    }

    useEffect(() => {
        if (contentRef.current.scrollHeight > 150 && contentRef.current.clientHeight > 140)
            setReadMore(true);
    }, []);

    return (
        <S.Root>
            <S.Paper onClick={() => openModal(_id)}>
                <S.CardHeader>
                    <Avatar
                        alt={authorName}
                        src={avatar}
                        size="sm">
                        {authorName}
                    </Avatar>

                    <S.HederCardInfo>
                        <S.AuthorName>{authorName.split(' ')[0]}</S.AuthorName>
                        <S.PostDate>Atualizado há {updatedDate.fromNow()} - Postado há {date.fromNow()}</S.PostDate>
                    </S.HederCardInfo>

                    {verified && (<S.VerifiedBadge>Perfil Verificado</S.VerifiedBadge>)}

                    {(user === loggedUser._id) && (
                        <S.CardActionsContainer>
                            <S.ActionButtonTooltip title="Editar" >
                                <S.ActionButton onClick={handleEdit} >
                                    <Edit />
                                </S.ActionButton>
                            </S.ActionButtonTooltip>

                            <S.ActionButtonTooltip title="Excluir" >
                                <S.ActionButton onClick={handleDelete} >
                                    <Delete />
                                </S.ActionButton>
                            </S.ActionButtonTooltip>
                        </S.CardActionsContainer>
                    )}
                </S.CardHeader>

                {image && <S.CardImage src={image} alt='content' />}

                <S.CardTitle>{title}</S.CardTitle>

                <S.Content
                    ref={contentRef}
                    style={(image || readMore) ? { border: "none" } : {}}
                    dangerouslySetInnerHTML={{ __html: text }} />

                {readMore && <S.ReadMore>CONTINUE LENDO...</S.ReadMore>}

                <S.CardFooter>
                    <S.FooterActionButtonsContainer>
                        <SocialButton type='likes' items={likes} initialLikes={initialLikes} loggedUserId={loggedUser._id} onClick={() => handleLike(_id)} />
                        <SocialButton type='comments' items={comments} loggedUserId={loggedUser._id} onClick={() => { }} identifier={externalId || _id} />
                    </S.FooterActionButtonsContainer>

                    <Button color="primary">
                        COMENTAR
                    </Button>
                </S.CardFooter>
            </S.Paper>
        </S.Root>
    )
}