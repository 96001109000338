import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { withStyles } from 'material-ui/styles';
import Dialog from 'material-ui/Dialog';

const styles = theme => ({
  dialog: {
    display: 'flex',
    alignItems: 'stretch',
    minHeight: '100vh',
    width: '100%',
    overflow: 'auto',
    overflowX: 'hidden',
  }
})

class ModalScreen extends Component {
  static propTypes = {
    children: PropTypes.any,
    open: PropTypes.bool,
    handleOpen: PropTypes.func,
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,
    id: PropTypes.string,
    handleKeyboard: PropTypes.func
  }

  static defaultProps = {
    open: false,
    fullScreen: true,
    id: undefined
  }

  render() {
    const {children, open, handleOpen, classes, fullScreen, id, handleKeyboard} = this.props
    return (
      <Dialog
        disableBackdropClick={fullScreen}
        PaperProps={{id, onKeyDown: handleKeyboard}}
        classes={{root: classes.dialog}}
        fullScreen={fullScreen}
        open={Boolean(open)}
        onRequestClose={() => handleOpen(false)}
      >
      {children}
      </Dialog>
    )
  }
}

export default withStyles(styles)(ModalScreen)
