import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "material-ui/styles";
import Dialog from "material-ui/Dialog";
import DefaultLoading from "../../../components/Loading";

import { openConfirmDelete, deleteCardRequest } from "../actions";

const styles = {
  paper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 24px;
  font-family: "Proxima Nova" !important;
`;

const TitleContainer = styled.div`
  width: 100%;
  height: 60px;
  padding: 0px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
`;

const Title = styled.span`
  width: 100%;
  font-size: 20px;
  text-align: center;
  line-height: 22px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  @media (max-width: 400px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const MessageContainer = styled.div`
  width: 100%;
  height: fit-content;
`;

const Message = styled.p`
  color: rgba(0, 0, 0, 0.75);
  font-size: 16px;
  font-weight: normal;
  height: fit-content;
  letter-spacing: 0px;
  line-height: 22px;
  text-align: center;
  white-space: pre-line;
  width: fit-content;
  margin: 0px;
  font-family: "Proxima Nova";
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 400px) {
    width: fit-content;
    height: 96px;
    flex-direction: column;
  }
`;

const CustomButton = styled.button`
  border: none;
  margin-right: 30px;
  background-color: #fc7954;
  border-radius: 25px;
  font-size: 13px;
  color: #fff;
  font-weight: 600;
  width: 150px;
  height: 40px;
  padding: 0px 30px;
  text-transform: uppercase;
  @media (max-width: 400px) {
    margin-right: 0px;
  }
  font-family: "Proxima Nova";
  &:hover {
    background: #e95b34;
    border: #e95b34;
  }
`;

const CloseButton = styled.button`
  border: 2px solid rgb(252, 121, 84);
  background-color: #fff;
  border-radius: 25px;
  font-size: 13px;
  color: rgb(252, 121, 84);
  font-weight: 600;
  width: 150px;
  height: 40px;
  padding: 0px 30px;
  text-transform: uppercase;
  font-family: "Proxima Nova";
  &:hover {
    border-color: #e95b34;
    color: #e95b34;
  }
`;

function ConfirmDelete({ classes, cardID }) {
  const dispatch = useDispatch();

  const { confirmDelete: open, loading } = useSelector(
    (state) => state.paymentGroup.deleteCard
  );

  function handleAction(action) {
    if (action === "continue") {
      dispatch(deleteCardRequest(cardID));
    } else {
      dispatch(openConfirmDelete(false));
    }
  }

  return (
    <Dialog open={open} classes={{ paper: classes.paper }}>
      <Container>
        <TitleContainer>
          <Title>Deseja remover o cartão salvo?</Title>
        </TitleContainer>
        <Content>
          {/* <MessageContainer>
            <Message>
              Se você sair sem preencher seus dados pessoais e de endereço, não
              será possível realizar compras.
            </Message>
          </MessageContainer> */}
          {loading ? (
            <DefaultLoading sidebar={true} />
          ) : (
            <ButtonsWrapper>
              <CustomButton onClick={() => handleAction("continue")}>
                Remover
              </CustomButton>
              <CloseButton onClick={() => handleAction("closeAll")}>
                Cancelar
              </CloseButton>
            </ButtonsWrapper>
          )}
        </Content>
      </Container>
    </Dialog>
  );
}

export default withStyles(styles)(ConfirmDelete);
