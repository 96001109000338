import React, { useState, useEffect } from "react";

import styled from "styled-components";
import Dialog from "material-ui/Dialog";
import { withStyles } from "material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Tooltip } from "@material-ui/core";
import ConfirmDelete from "./ConfirmDelete";
import {
  Close,
  RadioButtonUnchecked,
  RadioButtonChecked,
  RemoveCircle,
  AddCircle,
} from "@material-ui/icons";

import "react-credit-cards/es/styles-compiled.css";

import visa from "./CardBrands/visa.png";
import mastercard from "./CardBrands/mastercard.png";
import aura from "./CardBrands/aura.png";
import diners from "./CardBrands/diners.png";
import jcb from "./CardBrands/jcb.png";
import elo from "./CardBrands/elo.png";
import hipercard from "./CardBrands/hipercard.png";
import amex from "./CardBrands/americanexpress.png";
import discover from "./CardBrands/discover.png";
import defaultImg from "./CardBrands/default.png";

import {
  setChooseCardOpen,
  setChooseInstallmentsOpen,
  setChoosePaymentOpen,
  setPaymentModalOpen,
  openFinancialResponsible,
  payCourseClassRequest,
  openConfirmDelete,
} from "../actions";

const styles = {
  paper: {
    minWidth: 650,
    "@media (max-width: 900px)": {
      minWidth: "100vw",
      width: "100vw",
      height: "100vh",
      margin: 0,
      maxHeight: "100vh",
    },
  },
  paperResponsive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconButton: {
    width: "20px",
    height: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
    cursor: "pointer",
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Proxima Nova" !important;
`;

const CustomCloseButton = styled(IconButton)`
  position: absolute !important;
  right: 0;
  margin-right: 8px !important;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 8px;
  height: 64px;
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
`;

const HeaderTitle = styled.span`
  color: rgba(0, 0, 0, 0.7);
  font-size: 20px;
  font-weight: bold;
  user-select: none;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 56px 16px;

  @media (max-width: 500px) {
    padding: 56px 8px;
  }
`;

const CardOptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const SavedCardSelector = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 8px;
`;

const SavedCardTitle = styled.div`
  width: calc(100% - 20px);
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
`;

const Column = styled.div`
  flex: ${(props) => (props.first ? "3" : "2")};
  display: flex;
  padding-right: 8px;
  justify-content: flex-start;
  align-items: center;
`;

const ThirdColumn = styled.div`
  width: 60px;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FirstColumnTitle = styled.span`
  font-size: 15px;
  font-weight: bold;
  width: fit-content;
  color: rgba(0, 0, 0, 0.75);
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const ColumnTitle = styled.span`
  font-size: 13px;
  font-weight: normal;
  width: fit-content;
  color: rgba(0, 0, 0, 0.5);
  /* @media (max-width: 500px) {
    font-size: 11px;
  } */
`;

const ListWrapper = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const CardWrapper = styled.div`
  width: 100%;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Card = styled.div`
  width: 100%;
  min-height: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-right: ${(props) => props.selected && "1px solid #ddd"};
  &:hover {
    background: #eee;
    cursor: pointer;
  }
`;

const SelectButton = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  padding: 0px;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const CardInfo = styled.div`
  width: fit-content;
  min-height: 48px;
  display: flex;
  padding-left: 8px;
  justify-content: center;
  align-items: center;
`;

const CardBrand = styled.img`
  margin-left: 8px;
  width: 30px;
  min-height: 48px;
  object-fit: contain;
  @media (max-width: 500px) {
    margin-left: 6px;
    width: 24px;
  }
`;

const CardData = styled.p`
  width: fit-content;
  text-align: left;
  margin: 0px;
  font-size: 13px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.6);
  /* @media (max-width: 500px) {
    font-size: 11px;
  } */
`;

const CardBrandName = styled.span`
  font-weight: bold;
  width: fit-content;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.75);
  @media (max-width: 500px) {
    display: none;
  }
`;

const CardFinalNumber = styled.span`
  font-weight: normal;
  width: fit-content;
  @media (max-width: 500px) {
    display: none;
  }
`;

const CardFinalNumberResponsive = styled.span`
  display: none;
  font-weight: normal;
  width: fit-content;
  @media (max-width: 500px) {
    display: initial;
  }
`;

const Checked = styled(RadioButtonChecked)`
  color: #fc743b;
  width: 16px !important;
  height: 16px !important;
  @media (max-width: 500px) {
    width: 14px !important;
    height: 14px !important;
  }
`;

const Unchecked = styled(RadioButtonUnchecked)`
  color: rgba(0, 0, 0, 0.6);

  width: 16px !important;
  height: 16px !important;
  @media (max-width: 500px) {
    width: 14px !important;
    height: 14px !important;
  }
`;

const Remove = styled(RemoveCircle)`
  color: #c90000;
  width: 16px !important;
  height: 16px !important;
  @media (max-width: 500px) {
    width: 14px !important;
    height: 14px !important;
  }
`;

const Add = styled(AddCircle)`
  color: #0baa00;
  width: 16px !important;
  height: 16px !important;
  @media (max-width: 500px) {
    width: 14px !important;
    height: 14px !important;
  }
`;

const PaymentButton = styled.button`
  font-family: "Proxima Nova";
  width: 240px;
  height: 52px;
  background-color: transparent;
  border: 2px solid rgb(252, 121, 84);
  color: rgb(252, 121, 84);
  border-radius: 26px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 40px;
  transition: background 0.2s;
  &:hover {
    background: #fc7954;
    color: white;
  }
`;

function ChooseCard({ classes, qpq = true, pacoteID = "", amount = 0 }) {
  const dispatch = useDispatch();
  const responsive = useSelector((state) => state.plataform.responsive);
  const { open } = useSelector((state) => state.paymentGroup.chooseCard);
  const userCards = useSelector((state) => state.userData.data.user_cards);

  const [selected, setSelected] = useState("");
  const [empty, setEmpty] = useState(false);
  const [toDelete, setToDelete] = useState("");

  useEffect(() => {
    if (userCards.length) {
      setSelected(userCards[0].card_id);
      setEmpty(false);
    } else {
      setEmpty(true);
    }
  }, [JSON.stringify(userCards)]);

  useEffect(() => {
    dispatch(openConfirmDelete(false));
  }, []);

  function handleClose() {
    if (qpq) {
      dispatch(setPaymentModalOpen(true));
    } else {
      dispatch(setChoosePaymentOpen(true));
    }
    dispatch(setChooseCardOpen(false));
  }

  function handlePayment() {
    if (qpq && userCards.length) {
      const data = {
        gateway: "pagarme",
        payment_method: "credit_card",
        card_id: selected,
        installments: 1,
        amount: parseInt(`${amount}00`),
        items: [{ id: pacoteID }],
      };
      dispatch(payCourseClassRequest(data));
      dispatch(setChooseCardOpen(false));
    } else {
      dispatch(setChooseCardOpen(false));
      dispatch(setChooseInstallmentsOpen(true));
    }
  }

  function handleDelete(id) {
    setToDelete(id);
    dispatch(openConfirmDelete(true));
  }

  function handleNewCard() {
    dispatch(setChooseCardOpen(false));
    dispatch(openFinancialResponsible(true));
  }

  function getBrand(brand) {
    switch (brand) {
      case "amex":
        return amex;
      case "aura":
        return aura;
      case "discover":
        return discover;
      case "diners":
        return diners;
      case "elo":
        return elo;
      case "hipercard":
        return hipercard;
      case "jcb":
        return jcb;
      case "mastercard":
        return mastercard;
      case "visa":
        return visa;
      default:
        return defaultImg;
    }
  }

  function getExpiryDate(date = "") {
    try {
      let formatedDate = date.toString();
      if (date.toString().length === 3) {
        formatedDate = `0${date}`;
      }
      return formatedDate.slice(0, 2) + "/" + formatedDate.slice(2);
    } catch (error) {
      return "";
    }
  }

  return (
    <Dialog
      open={open}
      fullScreen={responsive}
      classes={{ paper: classes.paper }}
    >
      <Container>
        <Header>
          <HeaderTitle>Escolha o cartão de crédito</HeaderTitle>
          <CustomCloseButton aria-label="Fechar" onClick={() => handleClose()}>
            <Close />
          </CustomCloseButton>
        </Header>
        <Content>
          <CardOptions>
            <SavedCardSelector>
              <SavedCardTitle>
                <Column first={"true"}>
                  <FirstColumnTitle>Seus cartões de crédito</FirstColumnTitle>
                </Column>
                <Column>
                  <ColumnTitle>Nome no cartão</ColumnTitle>
                </Column>
                <ThirdColumn>
                  <ColumnTitle>Validade</ColumnTitle>
                </ThirdColumn>
              </SavedCardTitle>
              <ListWrapper>
                {userCards.map((card, index) => (
                  <CardWrapper
                    key={index}
                    onClick={() => setSelected(card.card_id)}
                  >
                    <Card>
                      <Column first={"true"}>
                        <SelectButton>
                          {selected === card.card_id ? (
                            <Checked />
                          ) : (
                            <Unchecked />
                          )}
                        </SelectButton>
                        <CardBrand src={getBrand(card.brand)} />
                        <CardInfo>
                          <CardData>
                            <CardBrandName>{card.brand}</CardBrandName>
                            <CardFinalNumber>{` terminado em ${card.last_digits}`}</CardFinalNumber>
                            <CardFinalNumberResponsive>{` final ${card.last_digits}`}</CardFinalNumberResponsive>
                          </CardData>
                        </CardInfo>
                      </Column>
                      <Column>
                        <CardData>{card.holder_name}</CardData>
                      </Column>
                      <ThirdColumn>
                        <CardData>
                          {getExpiryDate(card.expiration_date)}
                        </CardData>
                      </ThirdColumn>
                    </Card>
                    <Tooltip title="Remover este cartão dos cartões salvos">
                      <SelectButton onClick={() => handleDelete(card.id)}>
                        <Remove />
                      </SelectButton>
                    </Tooltip>
                  </CardWrapper>
                ))}
                <CardWrapper key={"add_card"} onClick={() => handleNewCard()}>
                  <Card>
                    <SelectButton>
                      <Add />
                    </SelectButton>
                    <CardInfo>
                      <CardData>Pagar com outro Cartão</CardData>
                    </CardInfo>
                  </Card>
                </CardWrapper>
              </ListWrapper>
            </SavedCardSelector>
            {!empty && (
              <PaymentButton onClick={() => handlePayment()}>
                Prosseguir
              </PaymentButton>
            )}
          </CardOptions>
        </Content>
      </Container>
      <ConfirmDelete cardID={toDelete} />
    </Dialog>
  );
}

export default withStyles(styles)(ChooseCard);
