import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import VideoButton from "./Video/VideoButton";
import { getText } from "../../utils/textHandle";
import PresentationHeader from "../../components/CourseHeader/presentation";


import { setShownedClass, setShowPresentation } from "../Cursos/actions";

const PresentationContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 184px 24px 24px 24px;
`;

const PresentationTitle = styled.span`
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  font-size: 20px;
  font-weight: bold;
  height: 24px;
  letter-spacing: -0.4px;
  line-height: 24px;
  width: fit-content;
  margin-top: 16px;
  margin-bottom: 40px;
`;

const PresentationContent = styled.div`
  color: rgba(0, 0, 0, 0.75);
  padding-bottom: 60px;
  font-family: "Proxima Nova";
  font-size: 15px;
  font-weight: normal;
  height: fit-content;
  letter-spacing: 0px;
  line-height: 20px;
  width: 100%;
`;

const PresentationButton = styled.div`
  z-index: 4;
  width: fit-content;
  height: fit-content;
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
`;

function CoursePresentation() {
  const dispatch = useDispatch();

  const { description } = useSelector(
    (state) => state.courseGroup.currentCourse.data
  );
  const { lastVisited } = useSelector(
    (state) => state.courseGroup.currentCourse
  );

  const currentClass = useSelector(
    (state) => state.courseGroup.currentClass.data
  );
  const currentModule = useSelector(
    (state) => state.courseGroup.currentModule.data
  );

  useEffect(() => {
    if(!description){
      return window.location.href ='/cursos';
    }
    function hideElements() {
      const intercom = document.getElementsByClassName(
        "intercom-lightweight-app-launcher"
      )[0];

      if (intercom) {
        intercom.setAttribute("style", "display: none");
      }
    }
    function cleanup(timeout) {
      clearTimeout(timeout);

      const intercom = document.getElementsByClassName(
        "intercom-lightweight-app-launcher"
      )[0];
      if (intercom) {
        intercom.setAttribute("style", "display: block");
      }
    }

    if (lastVisited === false) {
      let timeout = setTimeout(() => {
        hideElements();
      }, 1000);

      return () => cleanup(timeout);
    }

    return;
  }, [lastVisited]);

  function watchFirstClass() {
    dispatch(
      setShownedClass({
        classID: currentClass.id,
        moduleID: currentModule.id,
        moduleNumber: currentModule.number,
      })
    );
    const content = document.getElementById("content");
    if (content) {
      content.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
    dispatch(setShowPresentation(false));
  }

  return (
    <PresentationContainer>
      <PresentationHeader />
      <PresentationTitle>Apresentação do curso 🖐</PresentationTitle>
      <PresentationContent
        dangerouslySetInnerHTML={{
          __html: getText(description),
        }}
      ></PresentationContent>
      <PresentationButton>
        {lastVisited !== false && (
          <VideoButton
            presentation={true}
            text={
              currentModule.number === 1 && currentClass.number === 1
                ? "Acessar primeira aula"
                : "Acessar última aula"
            }
            action={() => watchFirstClass()}
          />
        )}
      </PresentationButton>
    </PresentationContainer>
  );
}

export default CoursePresentation;
