import React, { Component } from 'react';
import { essaysTab,essaysTabUser } from '../../config';
import { Tabs } from '../../components/common';

class EssayFilter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      exhibition: props.isCorrecao? 'unreplied' : 'all' ,
      processedExhibition: false
    }

  }

  componentDidMount(){
    if(this.props.isCorrecao){
      this.handleChange(this.state.exhibition)
    }else{
      this.handleChangeUser(this.state.exhibition)
    }
  }
  componentWillReceiveProps(nextProps){
    if(this.state.exhibition != this.state.processedExhibition){
      if(nextProps.isCorrecao){
        this.handleChange(this.state.exhibition)
      }else{
        this.handleChangeUser(this.state.exhibition)
      }
    }
  }
  handleChange = (exhibition) => {

    const { setFilters } = this.props;

    if(exhibition === 'replied') {
      setFilters({
           score: { "$exists": true }
      });
    }
    else {
      setFilters({
        score: { "$exists": false }
      });
    }

    this.setState({ exhibition, processedExhibition:exhibition });
  }

  handleChangeUser = (exhibition) => {
    const { setFilters, user } = this.props;

    if(exhibition === 'mine') {
      setFilters({
           user: user
      });
    }
    else {
      setFilters({
        score: { "$gt": 0 },
        shared: true
      });
    }
    this.setState({ exhibition, processedExhibition:exhibition });
  }

  render() {
    const { isCorrecao, setFilters,user } = this.props;
    const { exhibition } = this.state;
      if(isCorrecao){
      return (<div>
        <Tabs options={essaysTab}
          active={exhibition}
          onChange={this.handleChange} />
          <h3>Número de {this.props.questionEssayAnswerStatus }:{this.props.questionEssayAnswerNumber}</h3>
      </div>)
    }else{
      return (<div>
        <Tabs options={essaysTabUser}
          active={exhibition}
          onChange={this.handleChangeUser} />
      </div>)
    }
    return  <div/>
  }
}

export default EssayFilter;
