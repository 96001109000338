import axios from 'axios';

import { NEW_API_BASE_URL } from '../config';

const headers = {
  Authorization: localStorage.getItem('token'),
};

const api = axios.create({
    baseURL: NEW_API_BASE_URL,
    headers,
});

export default api;
