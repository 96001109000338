import React from 'react';
import CreatableSelect from 'react-select/creatable';
import _ from 'lodash';



export default function SelectWrapped(props) {
  const { name, value, placeholder , isMulti, options } = props;

  const customStyles = {
    container: (base) => ({ ...base, minWidth: 105, maxWidth: "100%", maxHeight: 40,}),
    menu: (base) => ({ ...base, background: "#fff", minWidth: 260}),
    menuList: (base) => ({ ...base, background: "#fff", width: '100%' }),
    noOptionsMessage: (base) => ({ ...base, fontSize: "13px", lineHeight: "20px" }),
    indicatorsContainer: (base) => ({ ...base, "& svg": { width: "16px", height: "16px", color: "rgba(0,0,0,0.4)" } }),
    valueContainer: (base) => ({
      ...base,
      flexWrap: "nowrap",
      overflowX: "none",
      justifyContent: "flex-start",
      padding: "0px",
      margin: "2px 8px 2px 16px",
      fontFamily: "Inter",
      fontSize: "13px",
      lineHeight: "20px",
      color: "rgba(0, 0, 0, 0.4)",
    }),
    control: (base) => ({
      ...base,
      backgroundColor: "#F2EEED",
      borderColor: "transparent",
      borderRadius: "5px",
      minHeight: "40px",
      cursor: 'pointer', 
    }),

    option: (base, { isSelected, isDisabled, isFocused, theme }) => ({
      ...base,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "rgba(220, 220, 220, .8)"
        : isFocused
        ? "rgba(220, 220, 220, .2)"
        : undefined,
      fontFamily: "Inter",
      fontSize: "13px",
      lineHeight: "20px",
      color: "#222",
      marinBottom: '5px',
    }),
    
  }

  return (
    <div style={{width: '100%'}}>
    <CreatableSelect
      options={options}
      placeholder={placeholder}
      width='744px'
      value={value}
      onChange={props.onChange}
      name={name}
      isMulti={isMulti}
      size={8}
      styles={customStyles}
    />
    </div>
  );
}