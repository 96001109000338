import React, { useState, useEffect } from "react";

import styled from "styled-components";
import Dialog from "material-ui/Dialog";
import { withStyles } from "material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@material-ui/icons";
import "react-credit-cards/es/styles-compiled.css";

import { IconButton, MenuItem, Select, Button } from "material-ui/";

import {
  setChooseCardOpen,
  setChooseInstallmentsOpen,
  fetchPayment,
} from "../actions";

const styles = {
  paper: {
    minWidth: 650,
    "@media (max-width: 900px)": {
      minWidth: "100vw",
      width: "100vw",
      height: "100vh",
      margin: 0,
      maxHeight: "100vh",
    },
  },
  paperResponsive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paymentButton: {
    height: "40px",
    width: "310px",
    borderRadius: "20px",
    background: "rgb(252, 121, 84)",
    color: "white",
    fontSize: "14px",
    fontWeight: "600",
    textTransform: "none",
    "&:hover": {
      background: "#E95B34",
    },
  },
  selectFieldStart: {
    "div.MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "rgba(0,0,0, 0.6)",
      },
      "&.Mui-focused fieldset": {
        fontFamily: "Proxima Nova",
        borderColor: "rgba(0,0,0, 0.6)",
      },
    },
  },
  selectRoot: {
    height: "56px",
    display: "flex",
    alignItems: "center",
  },
  selectContent: {
    height: "56px",
    display: "flex",
    alignItems: "center",
    padding: 0,
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "4px",
    paddingLeft: "12px",
    fontSize: "17px",
    "&:focus": {
      borderRadius: "4px",
    },
    "&:hover": {
      border: "1px solid rgba(0, 0, 0, 0.6)",
    },
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Proxima Nova" !important;
`;

const CustomCloseButton = styled(IconButton)`
  position: absolute !important;
  right: 0;
  margin-right: 8px !important;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 8px;
  height: 64px;
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
`;

const HeaderTitle = styled.span`
  color: rgba(0, 0, 0, 0.7);
  font-size: 20px;
  font-weight: bold;
  user-select: none;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 56px 88px 80px 88px;
  @media (max-width: 900px) {
    padding: 56px 16px 80px 16px;
  }
`;

const ItemContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${(props) => props.button && "40px"};
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > div.Mui-focused {
    border-color: #31c400 !important;
  }
`;

function ChooseCard({ classes }) {
  const dispatch = useDispatch();
  const responsive = useSelector((state) => state.plataform.responsive);
  const { open } = useSelector(
    (state) => state.paymentGroup.chooseInstallments
  );

  let card_id;
  const { user_cards } = useSelector((state) => state.userData.data);

  if (user_cards.length) {
    card_id = user_cards[0].card_id;
  }

  const checkoutPrice = useSelector((state) => state.checkout.cart.price);
  const checkoutItems = useSelector((state) => state.checkout.cart.items);
  const qtyInstallments = useSelector(
    (state) => state.paymentGroup.creditPaymentModal.installments
  );

  const [installment, setInstallment] = useState(1);
  const [installmentsOptions, setInstallmentOptions] = useState([]);

  useEffect(() => {
    let installments = [];

    for (let index = 1; index < qtyInstallments + 1; index++) {
      let installmentsPrice = 0;
      if (index === 1) {
        if (checkoutPrice.a_vista > 0) {
          installmentsPrice = Number(checkoutPrice.a_vista / index)
            .toFixed(2)
            .replace(".", ",");

          installments.push({
            value: index,
            text: `${index}x de R$ ${installmentsPrice}`,
          });
        } else {
          installmentsPrice = Number(checkoutPrice.parcelado / index)
            .toFixed(2)
            .replace(".", ",");

          installments.push({
            value: index,
            text: `${index}x de R$ ${installmentsPrice}`,
          });
        }
      } else {
        installmentsPrice = Number(checkoutPrice.parcelado / index)
          .toFixed(2)
          .replace(".", ",");

        installments.push({
          value: index,
          text: `${index}x de R$ ${installmentsPrice}`,
        });
      }
    }

    setInstallmentOptions(installments);
  }, [checkoutPrice, qtyInstallments]);

  function handleClose() {
    dispatch(setChooseCardOpen(true));
    dispatch(setChooseInstallmentsOpen(false));
  }

  const handleChange = (event) => {
    setInstallment(event.target.value);
  };

  function handlePayment() {
    const data = {
      gateway: "pagarme",
      payment_method: "credit_card",
      card_id,
      installments: installment,
      items: checkoutItems,
    };
    dispatch(fetchPayment(data));
    dispatch(setChooseInstallmentsOpen(false));
  }

  return (
    <Dialog
      open={open}
      fullScreen={responsive}
      classes={{ paper: classes.paper }}
    >
      <Container>
        <Header>
          <HeaderTitle>Escolha as parcelas</HeaderTitle>
          <CustomCloseButton aria-label="Fechar" onClick={() => handleClose()}>
            <Close />
          </CustomCloseButton>
        </Header>
        <Content>
          <ItemContainer>
            <InputContainer>
              <Select
                classes={{
                  root: classes.selectRoot,
                  select: classes.selectContent,
                }}
                value={installment}
                onChange={handleChange}
                disableUnderline={true}
                name="parcelas"
              >
                {installmentsOptions.map((installment, index) => {
                  return (
                    <MenuItem key={installment.value} value={installment.value}>
                      {installment.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </InputContainer>
          </ItemContainer>
          <ItemContainer button={true} onClick={() => handlePayment()}>
            <Button
              type="submit"
              color="primary"
              className={classes.paymentButton}
            >
              Pagar
            </Button>
          </ItemContainer>
        </Content>
      </Container>
    </Dialog>
  );
}

export default withStyles(styles)(ChooseCard);
