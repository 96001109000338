const INITIAL_STATE = {
  loading: false,
  totalPage: 0,
  atualidades: {
    page: 1,
    filter: {
      filtered: false,
      query: "",
      choosedFilter: null,
    },
  },
  porMaterias: {
    page: 1,
    filter: {
      filtered: false,
      query: "",
      choosedFilter: null,
      page: 1,
    },
  },
  gabarito: {
    _id: null,
  },
  simulado: {
    _id: null,
  },
  relatorio: {
    _id: null,
  },
};

export default (previousState = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case "SET_LOADING":
      if (payload === true) {
        return {
          ...previousState,
          totalPage: 0,
          loading: payload,
        };
      }
      return {
        ...previousState,
        loading: payload,
      };
    case "CHANGE_ATUALIDADES_PAGE":
      return {
        ...previousState,
        atualidades: {
          ...previousState.atualidades,
          page: payload,
        },
      };
    case "CHANGE_POR_MATERIAS_PAGE":
      return {
        ...previousState,
        porMaterias: {
          ...previousState.porMaterias,
          page: payload,
        },
      };
    case "CHANGE_POR_MATERIAS_FILTER_PAGE":
      return {
        ...previousState,
        porMaterias: {
          ...previousState.porMaterias,
          filter: {
            ...previousState.porMaterias.filter,
            page: payload,
          },
        },
      };
    case "SET_POR_MATERIAS_FILTER":
      return {
        ...previousState,
        porMaterias: {
          ...previousState.porMaterias,
          filter: {
            ...previousState.porMaterias.filter,
            query: payload,
          },
        },
      };
    case "SET_ATUALIDADES_FILTER":
      return {
        ...previousState,
        atualidades: {
          ...previousState.atualidades,
          filter: {
            ...previousState.atualidades.filter,
            query: payload,
          },
        },
      };
    case "FILTER_ATUALIDADES_LIST":
      return {
        ...previousState,
        atualidades: {
          ...previousState.atualidades,
          filter: {
            ...previousState.atualidades.filter,
            filtered: true,
            choosedFilter: payload,
          },
        },
      };
    case "CLEAR_ATUALIDADES_FILTER":
      return {
        ...previousState,
        atualidades: {
          ...previousState.atualidades,
          filter: {
            query: "",
            filtered: false,
            choosedFilter: null,
          },
        },
      };
    case "FILTER_POR_MATERIAS_LIST":
      return {
        ...previousState,
        porMaterias: {
          ...previousState.porMaterias,
          filter: {
            ...previousState.porMaterias.filter,
            filtered: true,
            choosedFilter: payload,
          },
        },
      };
    case "CLEAR_POR_MATERIAS_FILTER":
      return {
        ...previousState,
        porMaterias: {
          ...previousState.porMaterias,
          filter: {
            query: "",
            filtered: false,
            choosedFilter: null,
            page: 1,
          },
        },
      };
    case "SET_SIMULADO":
      return {
        ...previousState,
        simulado: payload,
      };
    case "SET_GABARITO":
      return {
        ...previousState,
        gabarito: payload,
      };
    case "SET_RELATORIO":
      return {
        ...previousState,
        relatorio: payload,
      };
    case "SET_TOTAL_PAGE":
      return {
        ...previousState,
        totalPage: payload,
      };
    default:
      return previousState;
  }
};
