import React, { Component } from 'react'
import { withStyles } from 'material-ui/styles';
import Paper from 'material-ui/Paper';
import moment from 'moment'
import BookmarkFullIcon from 'material-ui-icons/Bookmark'
import DeleteIcon from 'material-ui-icons/Delete'
import IconButton from 'material-ui/IconButton';
import Tooltip from 'material-ui/Tooltip';

const styles = theme => ({
  container: {
    width: '100%',
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    color: theme.palette.text.clear,
    flexDirection: 'column',
  },
  card: {
    ...theme.transitions.card,
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&:hover $icon': {
      color: theme.palette.orange
    },
    minHeight: '70px',
    padding: '16px',
    cursor: 'pointer'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.cardTitle,
    paddingBottom: '8px',
    justifyContent: 'space-between'
  },
  bookmarkIcon: {
    color: theme.palette.orange,
    height: '15px',
    width: '15px'
  },
  dateRow: {
    alignItems: 'center',
    display: 'flex',
    height: '25px'
  },
  date: {
    paddingLeft: '4px',
    ...theme.typography.percent,
    fontSize: '13px'
  },
  content: {
    paddingBottom: '8px',
    fontSize: '15px',
    fontFamily: theme.typography.fontFamily,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        width: '80%',
    },
  },
  deleteIcon: {
    color: theme.palette.black.a3,
    height: '18px',
    width: '18px'
  },
  deleteContainer: {
      paddingLeft: '18px'
  },
  row: {
    display: 'flex'
  },
  icon: {
    
  }
})

class Bookmark extends Component {
  
  handleDelete = (event) => {
    event.stopPropagation();
    this.props.deleteBookmark(this.props._id)
  }

  render() {
    const {createdAt, _id, classes, title, url, openModal, resourceId, resourceType, content} = this.props
    const date = moment(createdAt, "YYYY-MM-DD");

    var openInModal = () => openModal(resourceId);
    var openInNewTab = () => window.open(url, '_blank');
    
    var handleClick = (resourceType === 'external') ? openInNewTab : openInModal;

    return (
      <div className={classes.container}>
        <Paper className={classes.card}  onClick={handleClick}>
            <div className={classes.title}>
                <div>{title}</div>
                <div className={classes.deleteContainer}>
                <Tooltip title="Deletar item" enterDelay={300}>
                  <IconButton
                    aria-label="change direction"
                    onClick={this.handleDelete}
                  >
                    <DeleteIcon className={classes.deleteIcon} />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <div className={classes.content}>{content}...</div>
            <div className={classes.row}><BookmarkFullIcon className={classes.bookmarkIcon}/> <div className={classes.date}>Salvo {date.format('DD/MM/YYYY')}</div></div>
        </Paper>
        
      </div>
    )
  }
}

export default withStyles(styles)(Bookmark)
