import { call, takeLatest, put, select, take, race } from "redux-saga/effects";
import { push } from "react-router-redux";

import {
  getInitialInfo,
  getPaymentInfo,
  handleClassInfo,
} from "./sagaUtils.js";

import {
  COURSE_FETCH,
  CATEGORY_FETCH,
  MODULE_FETCH,
  MODULE_FETCH_SUCCESS,
  MODULE_FETCH_FAILURE,
  CURRENT_MODULE_FETCH,
  CURRENT_CLASS_FETCH,
  CLASS_INFO_FETCH,
  COURSE_DATA_FETCH,
  UPDATE_LAST_VISITED,
  CURRENT_CLASS_FETCH_FAILURE,
  CURRENT_CLASS_FETCH_SUCCESS,
  CURRENT_MODULE_FETCH_FAILURE,
  CURRENT_MODULE_FETCH_SUCCESS,
  LAST_VISITED_FETCH_FAILURE,
  LAST_VISITED_FETCH_SUCCESS,
  LAST_VISITED_FETCH,
  SINGLE_COURSE_FETCH,
  SINGLE_COURSE_FETCH_FAILURE,
  SINGLE_COURSE_FETCH_SUCCESS,
} from "./constants";

import {
  DEBITS_FETCH_SUCCESS,
} from "../Payments/constants";

import {
  courseFetchFailure,
  courseFetchSuccess,
  categoryFetchFailure,
  categoryFetchSuccess,
  moduleFetchRequest,
  moduleFetchFailure,
  moduleFetchSuccess,
  currentModuleFetchFailure,
  currentModuleFetchSuccess,
  currentClassFetchFailure,
  currentClassFetchSuccess,
  courseDataFetchFailure,
  courseDataFetchSuccess,
  lastVisitedFetch,
  lastVisitedFetchFailure,
  lastVisitedFetchSuccess,
  classInfoFetchFailure,
  classInfoFetchSuccess,
  setShownedClass,
  setCurrentCourse,
  setLastVisited,
  currentClassFetchRequest,
  currentModuleFetchRequest,
  singleCourseFetchRequest,
  singleCourseFetchSuccess,
  singleCourseFetchFailure,
} from "./actions";

import { debitsFetchRequest } from "../Payments/actions";

import api from "../../services/newApi";

export function* fetchCourseData(action) {
  const headers = { Authorization: localStorage.getItem("token") };
  try {
    const { payload: courseData, profile } = action;

    const payload = {
      data: courseData,
      lastVisited: false,
      showPresentation: true,
    };

    let accessedCourseQuery = {
      index: "cursos_acessados",
      sort: [
        {
          updated_at: {
            order: "desc",
          },
        },
      ],
      query: {
        bool: {
          must: [
            {
              match: {
                perfil: profile,
              },
            },
            {
              match: {
                curso: payload.data.id,
              },
            },
          ],
        },
      },
    };

    const { data: accessedCourses } = yield call(
      api.post,
      "/elastic/",
      accessedCourseQuery,
      { headers }
    );

    const modules = yield select((state) => state.courseGroup.modules.data);
    modules.sort((moduleA, moduleB) => moduleA.number - moduleB.number);

    if (accessedCourses.results.length > 0) {
      payload.showPresentation = true;
      payload.lastVisited = false;

      for (let i = 0; i < accessedCourses.results.length; i++) {
        let visit = accessedCourses.results[i];

        const moduleInfo = modules.find(
          (modulo) => modulo.id === visit.modulo
        );

        if (moduleInfo) {
          const classInfo = moduleInfo.aulas.find(
            (aula) => aula.id === visit.aula
          );

          if (classInfo) {
            payload.showPresentation = false;

            payload.lastVisited = {
              moduleID: moduleInfo.id,
              moduleNumber: moduleInfo.number,
              classID: classInfo.id,
            };

            break;
          }
        }
      }
    }

    const categoryName = payload.data.categoria.nome;

    let checkoutData = {
      index: "checkoutselect",
      query: {
        bool: {
          must: [
            {
              match: {
                categoria: categoryName,
              },
            },
            {
              match: {
                ano: payload.data.ano,
              },
            },
            {
              match: {
                ativo: true,
              },
            },
          ],
        },
      },
    };

    const checkoutResponse = yield call(api.post, "/elastic/", checkoutData, {
      headers,
    });

    if (checkoutResponse.data && checkoutResponse.data.results.length) {
      const [checkout] = checkoutResponse.data.results;

      payload.data.checkout_url = checkout.url;
    }

    yield put(setCurrentCourse({ payload }));

    yield put(
      moduleFetchRequest(payload.data.id, payload.lastVisited, profile)
    );

    const { moduleSuccess, moduleFailure } = yield race({
      moduleSuccess: take(MODULE_FETCH_SUCCESS),
      moduleFailure: take(MODULE_FETCH_FAILURE),
    });

    if (moduleFailure !== undefined) {
      throw new Error(400);
    }

    const courseID = payload.data.id;

    if (modules.length > 0) {
      const lastVisited = payload.lastVisited;

      if (lastVisited !== false) {
        yield put(
          setShownedClass({ ...lastVisited })
        );

        yield put(
          push(`/cursos/${courseID}/modulo/${lastVisited.moduleID}/aula/${lastVisited.classID}`)
        );
      } else {
        const [moduleInfo] = modules;
        if (
          moduleInfo.aulas.length > 0 &&
          moduleInfo.aulas.find((aula) => aula.number !== 0) !== undefined
        ) {
          const moduleID = moduleInfo.id;
          const aulas = moduleInfo.aulas
            .filter((aula) => aula.number > 0)
            .sort((aulaA, aulaB) => aulaA.number - aulaB.number)
            .map((aula) => aula.id);

          const [classID] = aulas;

          yield put(
            setShownedClass({
              moduleNumber: "",
              moduleID: "",
              classID: "",
            })
          );

          yield put(
            push(`/cursos/${courseID}/modulo/${moduleID}/aula/${classID}`)
          );
        } else {
          yield put(
            setShownedClass({
              moduleNumber: "",
              moduleID: "",
              classID: "",
            })
          );
          yield put(push(`/cursos/${courseID}/presentation/`));
        }
      }
    } else {
      yield put(
        setShownedClass({
          moduleNumber: "",
          moduleID: "",
          classID: "",
        })
      );
      yield put(push(`/cursos/${courseID}/presentation/`));
    }

    yield put(courseDataFetchSuccess());
  } catch (error) {
    // console.log(error);
    yield put(courseDataFetchFailure());
    // yield put(resetCourse());
  }
}

export function* fetchModules(action) {
  const headers = { Authorization: localStorage.getItem("token") };

  try {
    const { courseID } = action;

    let data = {
      index: "modulos",
      query: {
        nested: {
          path: "curso",
          query: {
            bool: {
              must: [
                {
                  match: {
                    "curso.id": courseID,
                  },
                },
              ],
            },
          },
        },
      },
    };

    const { data: response } = yield call(api.post, "/elastic/", data, {
      headers,
    });

    if (response.results.length > 0) {
      yield put(moduleFetchSuccess({ payload: response.results }));
    } else {
      yield put(moduleFetchSuccess({ payload: [] }));
      yield put(setShownedClass({ moduleNumber: "" }));
    }
  } catch (error) {
    yield put(moduleFetchFailure());
  }
}

export function* updateLastVisitedData(action) {
  const headers = { Authorization: localStorage.getItem("token") };

  const lastVisitedID = yield select(
    (state) => state.courseGroup.currentCourse.lastVisited.id
  );
  const { seconds, duration } = action;
  const { data } = yield call(
    api.patch,
    `/cursos/cursos-acessados/${lastVisitedID}/`,
    { seconds, duration },
    { headers }
  );
  yield put(setLastVisited(data));
}

export function* fetchCurrentClass(action) {
  const headers = { Authorization: localStorage.getItem("token") };

  try {
    const { classID } = action;

    let data = {
      index: "aulas",
      query: {
        match: {
          id: `${classID}`,
        },
      },
    };
    const { data: response } = yield call(api.post, "/elastic/", data, {
      headers,
    });
    if (response.results.length > 0) {
      yield put(currentClassFetchSuccess({ payload: response.results[0] }));
    } else {
      throw new Error("The current class does not exists");
    }
  } catch (error) {
    yield put(currentClassFetchFailure());
  }
}

export function* fetchClassInfo(action) {
  const headers = { Authorization: localStorage.getItem("token") };

  try {
    //traz os dados da action
    const { payload } = action;
    const { moduleID, classID, courseID, profile, isPaid = false } = payload;

    //busca os dados do curso
    yield put(singleCourseFetchRequest(courseID));
    const { singleCourseSuccess, singleCourseFailure } = yield race({
      singleCourseSuccess: take(SINGLE_COURSE_FETCH_SUCCESS),
      singleCourseFailure: take(SINGLE_COURSE_FETCH_FAILURE),
    });

    //se falha, interrompe a saga.
    if (singleCourseFailure !== undefined) {
      throw new Error(400);
    }

    let onlyPresentation = false;

    //busca os modulos do curso
    yield put(moduleFetchRequest(courseID));
    const { moduleSuccess, moduleFailure } = yield race({
      moduleSuccess: take(MODULE_FETCH_SUCCESS),
      moduleFailure: take(MODULE_FETCH_FAILURE),
    });

    //se erro, interrompe a saga.
    if (moduleFailure !== undefined) {
      throw new Error(400);
    }

    //apos atualizar o estado, buscamos os modulos atualizados
    const updatedModules = [
      ...(yield select((state) => state.courseGroup.modules.data)),
    ];

    //se a listagem de modulos foi igual a 0, marcamos que tera apenas a apresentacao
    if (updatedModules.length === 0) {
      onlyPresentation = true;
    }

    //caso o curso nao contenha nenhum modulo
    if (onlyPresentation) {
      yield put(
        setShownedClass({
          moduleNumber: "",
          moduleID: "",
          classID: "",
        })
      );
    } else {
      let currentModule,
        matriculaID,
        isClassADebit = false,
        hasDebits = false;

      //busca a class que tem o mesmo classID
      yield put(currentClassFetchRequest(classID));
      const { classSuccess, classFailure } = yield race({
        classSuccess: take(CURRENT_CLASS_FETCH_SUCCESS),
        classFailure: take(CURRENT_CLASS_FETCH_FAILURE),
      });

      if (classFailure !== undefined) {
        throw new Error(400);
      }

      const currentClass = yield select(
        (state) => state.courseGroup.currentClass.data
      );

      //busca o modulo que tem o mesmo moduleID
      const activedModule = yield select(
        (state) => state.courseGroup.currentModule.data
      );

      if (currentClass.modulos.id !== activedModule.id) {
        yield put(currentModuleFetchRequest(moduleID));
        const { currentModuleSuccess, currentModuleFailure } = yield race({
          currentModuleSuccess: take(CURRENT_MODULE_FETCH_SUCCESS),
          currentModuleFailure: take(CURRENT_MODULE_FETCH_FAILURE),
        });

        if (currentModuleFailure !== undefined) {
          throw new Error(400);
        }

        currentModule = yield select(
          (state) => state.courseGroup.currentModule.data
        );
      } else {
        currentModule = activedModule;
      }

      const currentCourse = yield select(
        (state) => state.courseGroup.currentCourse
      );

      if (currentCourse.lastVisited !== false) {
        yield put(
          setShownedClass({
            moduleNumber: currentModule.number,
            moduleID: moduleID,
            classID: classID,
          })
        );
      }

      yield put(debitsFetchRequest(moduleID));
      yield take(DEBITS_FETCH_SUCCESS);

      const enabledDebits = yield select(
        (state) => state.paymentGroup.debits.data.enabled
      );
      const disabledDebits = yield select(
        (state) => state.paymentGroup.debits.data.disabled
      );

      if (isPaid !== true) {
        //verifica entre todos os debitos se a aula é um deles
        if (enabledDebits.length) {
          for (const debit of enabledDebits) {
            if (debit.aula.id === classID) {
              isClassADebit = true;
              hasDebits = false;
              break;
            }
            hasDebits = true;
          }
        }
        if (disabledDebits.length) {
          for (const debit of disabledDebits) {
            if (debit.aula.id === classID) {
              isClassADebit = true;
              break;
            }
          }
        }
      }

      //busca a matricula que tem o mesmo moduleID
      try {
        let data = {
          index: "aulas",
          query: {
            bool: {
              must: [
                {
                  match: {
                    "modulos.id": moduleID,
                  },
                },
                {
                  match: {
                    number: 0,
                  },
                },
              ],
            },
          },
        };
        const { data: response } = yield call(api.post, "/elastic/", data, {
          headers,
        });
        if (response.results.length > 0) {
          matriculaID = response.results[0].id;
        } else {
          throw new Error("A matrícula atual não existe");
        }
      } catch (error) {
        matriculaID = null;
      }

      const userPayments = yield select(
        (state) => state.paymentGroup.userPayments.data
      );
      const paymentInfo = getPaymentInfo(
        userPayments,
        courseID,
        moduleID,
        matriculaID,
        classID
      );

      const info = getInitialInfo(currentClass, currentModule, paymentInfo.ends_on);
      let lastVisitedClass;
      try {
        lastVisitedClass = yield select(
          (state) => state.courseGroup.currentCourse.lastVisited.aula
        );
      } catch (error) {
        lastVisitedClass = null;
      }
      if (classID !== lastVisitedClass) {
        yield put(lastVisitedFetch(profile, courseID, moduleID, classID));
        yield take(LAST_VISITED_FETCH_SUCCESS || LAST_VISITED_FETCH_FAILURE);
      }

      if (info.type !== "missing") {
        handleClassInfo(
          hasDebits,
          isClassADebit,
          paymentInfo,
          currentClass,
          info
        );
        if (
          !paymentInfo.moduleHasPayment &&
          !paymentInfo.classHasPayment &&
          !paymentInfo.courseHasPayment &&
          hasDebits
        ) {
          info.type = "hasDebits";
          info.showPaymentBar = true;
        }
      } else {
        if (hasDebits) {
          if (
            !paymentInfo.moduleHasPayment &&
            !paymentInfo.classHasPayment &&
            !paymentInfo.courseHasPayment &&
            hasDebits
          ) {
            info.showPaymentBar = true;
          }
        }
      }
      yield put(classInfoFetchSuccess({ payload: info }));
    }
  } catch (error) {
    yield put(classInfoFetchFailure());
    yield put(push("/cursos"));
  }
}

export function* fetchCurrentModule(action) {
  const headers = { Authorization: localStorage.getItem("token") };

  try {
    const { moduleID } = action;

    let data = {
      index: "modulos",
      query: {
        match: {
          id: `${moduleID}`,
        },
      },
    };
    const { data: response } = yield call(api.post, "/elastic/", data, {
      headers,
    });
    if (response.results.length > 0) {
      yield put(currentModuleFetchSuccess({ payload: response.results[0] }));
    } else {
      throw new Error("The current module does not exists");
    }
  } catch (error) {
    yield put(currentModuleFetchFailure());
  }
}

export function* fetchCourses() {
  const headers = { Authorization: localStorage.getItem("token") };

  try {
    const concursoAtivo = yield select(
      (state) => state.concurso.active.concursoId
    );

    let data = {
      index: "cursos",
      query: {
        bool: {
          must: [
            {
              nested: {
                path: "concurso",
                query: {
                  match: {
                    "concurso.id": concursoAtivo,
                  },
                },
              },
            },
            {
              match: {
                visible: true,
              },
            },
          ],
        },
      },
      size: 300,
    };

    const { data: response } = yield call(api.post, "/elastic/", data, {
      headers,
    });
    yield put(courseFetchSuccess({ payload: response.results }));
  } catch (error) {
    yield put(courseFetchFailure());
  }
}

export function* fetchSingleCourse(action) {
  const headers = { Authorization: localStorage.getItem("token") };
  const { courseID } = action;
  const profile = yield select((state) => state.userData.data.id);

  try {
    let courseData = {};

    let data = {
      index: "cursos",
      query: {
        match: {
          id: courseID,
        },
      },
    };

    const { data: response } = yield call(api.post, "/elastic/", data, {
      headers,
    });

    if (response.results.length > 0) {
      courseData = response.results[0];
    } else {
      throw new Error(400);
    }

    const payload = {
      data: courseData,
      lastVisited: false,
      showPresentation: true,
    };

    let accessedCourseQuery = {
      index: "cursos_acessados",
      sort: [
        {
          updated_at: {
            order: "desc",
          },
        },
      ],
      query: {
        bool: {
          must: [
            {
              match: {
                perfil: profile,
              },
            },
            {
              match: {
                curso: payload.data.id,
              },
            },
          ],
        },
      },
    };

    const { data: accessedCourses } = yield call(
      api.post,
      "/elastic/",
      accessedCourseQuery,
      { headers }
    );

    const showPresentation = yield select(
      (state) => state.courseGroup.currentCourse.showPresentation
    );

    if (accessedCourses.results.length > 0) {
      payload.lastVisited = accessedCourses.results[0];
      if (showPresentation !== undefined) {
        payload.showPresentation = showPresentation;
      } else {
        payload.showPresentation = false;
      }
    } else {
      payload.lastVisited = false;
      if (showPresentation !== undefined) {
        payload.showPresentation = showPresentation;
      } else {
        payload.showPresentation = true;
      }
    }
    const categoryName = payload.data.categoria.nome;

    let checkoutData = {
      index: "checkoutselect",
      query: {
        bool: {
          must: [
            {
              match: {
                categoria: categoryName,
              },
            },
            {
              match: {
                ano: payload.data.ano,
              },
            },
            {
              match: {
                ativo: true,
              }
            }
          ],
        },
      },
    };

    const checkoutResponse = yield call(api.post, "/elastic/", checkoutData, {
      headers,
    });

    if (checkoutResponse.data && checkoutResponse.data.results.length) {
      const [checkout] = checkoutResponse.data.results;

      payload.data.checkout_url = checkout.url;
    }

    yield put(setCurrentCourse({ payload }));
    yield put(singleCourseFetchSuccess());
  } catch (error) {
    yield put(singleCourseFetchFailure());
  }
}

export function* fetchLastVisited(action) {
  const headers = { Authorization: localStorage.getItem("token") };

  try {
    const { profile, courseID, moduleID, classID } = action;
    let visitedClassQuery = {
      index: "cursos_acessados",
      query: {
        bool: {
          must: [
            {
              match: {
                perfil: profile,
              },
            },
            {
              match: {
                curso: courseID,
              },
            },
            {
              match: {
                modulo: moduleID,
              },
            },
            {
              match: {
                aula: classID,
              },
            },
          ],
        },
      },
      _source: ["id"],
    };

    const { data: visitedClass } = yield call(
      api.post,
      "/elastic/",
      visitedClassQuery,
      { headers }
    );

    if (visitedClass.results.length) {
      const { data } = yield call(
        api.patch,
        `/cursos/cursos-acessados/${visitedClass.results[0].id}/`,
        {},
        { headers }
      );
      yield put(setLastVisited(data));
    } else {
      let body = {
        perfil: profile,
        curso: courseID,
        modulo: moduleID,
        aula: classID,
        seconds: parseFloat("0"),
        duration: parseFloat("0"),
      };

      const { data } = yield call(api.post, "/cursos/cursos-acessados/", body, {
        headers,
      });
      yield put(setLastVisited(data));
    }
    yield put(lastVisitedFetchSuccess());
  } catch (error) {
    yield put(lastVisitedFetchFailure());
  }
}

export function* fetchCategories() {
  const headers = { Authorization: localStorage.getItem("token") };

  try {
    const { data } = yield call(api.get, "/core/categorias/", { headers });
    if (data.length) {
      data.sort(
        (catA, catB) => new Date(catB.created_at) - new Date(catA.created_at)
      );
    }
    yield put(categoryFetchSuccess({ payload: data }));
  } catch (error) {
    yield put(categoryFetchFailure());
  }
}

export default function* courseSaga() {
  yield [
    takeLatest(SINGLE_COURSE_FETCH, fetchSingleCourse),
    takeLatest(UPDATE_LAST_VISITED, updateLastVisitedData),
    takeLatest(COURSE_DATA_FETCH, fetchCourseData),
    takeLatest(CLASS_INFO_FETCH, fetchClassInfo),
    takeLatest(LAST_VISITED_FETCH, fetchLastVisited),
    takeLatest(CURRENT_CLASS_FETCH, fetchCurrentClass),
    takeLatest(MODULE_FETCH, fetchModules),
    takeLatest(CURRENT_MODULE_FETCH, fetchCurrentModule),
    takeLatest(COURSE_FETCH, fetchCourses),
    takeLatest(CATEGORY_FETCH, fetchCategories),
  ];
}
