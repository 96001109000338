import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { UnfoldMore } from "@material-ui/icons";

import CurrentClassSelector from "../CurrentClassSelector";
import { classSelectorModalAction } from "../../containers/Cursos/actions";

const CurrentClassInfoContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  margin-bottom: 8px;
`;
const ClassWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 0px;
  height: fit-content;
  display: flex;
  flex-direction: column;
`;

const ModuleInfo = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-family: "Proxima Nova";
  font-size: 11px;
  font-weight: 500;
  height: fit-content;
  letter-spacing: 0px;
  line-height: 16px;
  width: 100%;
  text-align: left;
  padding: 0px;
  margin: 0px;
`;

const ClassText = styled.p`
  color: rgba(0, 0, 0, 0.7);
  font-family: "Proxima Nova";
  font-size: 20px;
  font-weight: bold;
  height: fit-content;
  letter-spacing: -0.4px;
  line-height: 24px;
  text-align: left;
  padding: 0px;
  margin: 0px;
`;

const ClassButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function CurrentClassInfo({ moduleNumber, currentClassName }) {
  const dispatch = useDispatch();
  const openClassSelector = useSelector(
    (state) => state.courseGroup.classSelectorModal.open
  );

  function close(event) {
    event.preventDefault();
    dispatch(classSelectorModalAction({ open: true }));
  }

  return (
    <div>
      <CurrentClassInfoContainer>
        <ClassWrapper onClick={() => dispatch(classSelectorModalAction({ open: true }))}>
          <ModuleInfo>MODULO {moduleNumber}</ModuleInfo>
          <ClassButtonWrapper>
            <ClassText>{currentClassName}</ClassText>
            <IconWrapper>
              <UnfoldMore
                style={{
                  width: "18px",
                  height: "18px",
                  color: "rgba(0,0,0, .6)",
                }}
              />
            </IconWrapper>
          </ClassButtonWrapper>
        </ClassWrapper>
        <CurrentClassSelector open={openClassSelector} />
      </CurrentClassInfoContainer>
    </div>
  );
}
