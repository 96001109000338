import React from 'react';
import styled from 'styled-components';

import { CircularProgress } from 'material-ui/Progress';

const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: ${props => props.sidebar ? "0px" : "35vh"};
`

function DefaultLoading({sidebar=false}) {
    return <LoadingContainer sidebar={sidebar}>
        <CircularProgress
            style={{
                fontSize: "55px !important",
                color: '#F58220'
            }}
        />
    </LoadingContainer>
}

export default DefaultLoading;