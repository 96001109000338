import { SET_CONCURSO } from '../actions/concursoActions';
import { CRUD_GET_LIST_SUCCESS } from '../actions/dataActions';
import {CACD} from '../config';
import _ from 'lodash'; 

const initialState = {data: [CACD],  active: CACD}

export default (previousState = initialState, { type, payload, meta }) => {
    switch (type) {
    case CRUD_GET_LIST_SUCCESS:
      if(meta.resource === 'concurso') {
      const newRecordsById = payload.data.reduce((prev, record) => {
        prev[record._id] = record;
        return prev;
      }, {});
      
      return _.assign({}, {...previousState, data: newRecordsById, active: newRecordsById[previousState.active && previousState.active._id]});
    } else {
      return previousState;
    }
    case SET_CONCURSO:
      return {...previousState, active: previousState.data[payload] || {_id: payload}};
      
    default:
        return previousState;
    }
};
