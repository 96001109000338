import React, { Component } from 'react';
import moment from 'moment'
import { withStyles } from 'material-ui/styles';
import Paper from 'material-ui/Paper';

const styles = theme => ({
    container: {
        width: '100%',
        marginBottom: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoration: 'none',
        color: theme.palette.text.clear,
        flexDirection: 'column',
    },
    card: {
        boxShadow: theme.shadows[25],
        '&:hover $icon': {
            color: theme.palette.orange
        },
        minHeight: '70px',
        padding: '16px 46px 32px 16px'
    },
    author: {
        ...theme.typography.body3,
        color: theme.palette.text.primary,
        fontSize: '15px',
        fontWeight: 'normal'
    },
    authorData: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    authorPhoto: {
        background: '#999999',
        color: '#fff',
        borderRadius: '50%',
        height: '45px',
        width: '45px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '10px'
    },
    date: {
        ...theme.typography.percent,
        fontSize: '13px',
        lineHeight: '14px'
    },
    content: {
        marginTop: '24px',
        fontSize: '17px',
        lineHeight: '21px',
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.black.a8,
        width: '100%'
    },
    footer: {
        ...theme.typography.text,
        color: theme.palette.black.a6,
        marginTop: '24px',
        fontSize: '13px'
    },
    title: {
        display: 'block'
    }
})

class Discurso extends Component {
    render () {
        const { createdAt, classes, title, author, placeAndDate, content } = this.props

        return (
            <div className={classes.container}>
                <Paper className={classes.card}>
                    <div className={classes.authorData}>
                        <div className={classes.authorData}>
                            <div className={classes.authorPhoto}>{(author && author.slice(0,1)) || 'C'}</div> 
                            <div> 
                                <div className={classes.author}> {author}</div>
                                <div className={classes.date}> {placeAndDate}</div>
                            </div>
                        </div>
                    </div>

                    <div dangerouslySetInnerHTML={{__html: content}} className={classes.content}></div>

                    <div className={classes.footer}>
                        <span className={classes.title}>{title}</span>
                        <span className={classes.placeAndDate}>{placeAndDate}</span>
                    </div>
                </Paper>
            </div>
        )
    }
}

export default withStyles(styles)(Discurso)