import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { InsertDriveFileOutlined } from '@material-ui/icons';

import CustomizedMenu from './CustomBtn';
import { TopicosEdital } from './TopicosEdital';

import {
  CardContainer,
  CardHeader,
  HeaderIcon,
  HeaderTitle,
  CardContent,
  CiclesContainer,
  CicleContent,
  ButtonsContainer,
  StartBtn,
  TestScore,
  TopicsArea,
} from './styles';

function Cards({
  _id,
  subjects = [],
  topics = [],
  questionsSize,
  date,
  answer = null,
}) {
  const dispatch = useDispatch();

  const [subjectsTitle, setSubjectsTitle] = useState('');

  useEffect(() => {
    const _subjectsTitle = subjects
      .map((subject) => subject.name || '')
      .join(', ');

    setSubjectsTitle(_subjectsTitle);
  });

  function openModal(modalType) {
    if (modalType === 'simulado') {
      dispatch({ type: 'SET_SIMULADO', payload: { _id } });
    }
    if (modalType === 'gabarito') {
      dispatch({ type: 'SET_GABARITO', payload: { _id } });
    }
    if (modalType === 'relatorio') {
      dispatch({ type: 'SET_RELATORIO', payload: { _id } });
    }
  }

  return (
    <CardContainer>
      <CardHeader>
        <HeaderIcon>
          <InsertDriveFileOutlined />
        </HeaderIcon>
        <HeaderTitle>
          <span>
            {questionsSize} QUESTÕES · {date.toLocaleString()}
          </span>
          <h3>{subjectsTitle}</h3>
        </HeaderTitle>
      </CardHeader>
      <CardContent>
        {subjects && topics && (
          <TopicosEdital subjects={subjects} topics={topics} />
        )}
        {/* {subjects.map((subject) => {
          const topicsEdital = topics
            .map((topic) => topic.name.replace('.', '') || '')
            .join('/ ');
          return (
            <TopicsArea>
              <span key={subject._id}>{subject.name} : </span>
              <p>{topicsEdital}</p>
            </TopicsArea>
          );
        })} */}
        <ButtonsContainer>
          {answer ? (
            <>
              <TestScore>{answer.score} pts</TestScore>
              <StartBtn alt onClick={() => openModal('simulado')}>
                Refazer
              </StartBtn>
              <CustomizedMenu _id={_id} />
            </>
          ) : (
            <StartBtn onClick={() => openModal('simulado')}>
              Iniciar Simulado
            </StartBtn>
          )}
        </ButtonsContainer>
      </CardContent>
    </CardContainer>
  );
}
export default Cards;
