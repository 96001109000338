import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { CircularProgress } from "material-ui/Progress";

import Cards from "./Cards/index";
import Pagination from "../../components/PaginationQS/Pagination";

import api from "../../services/api";
import { id } from "date-fns/locale";

const AtualidadesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 770px;
  @media (max-width: 900px) {
    margin: 0;
    width: 95vw;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
  padding-top: 48px;
  @media (max-width: 1390px) {
  }
`;

const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const EmptySeach = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 16px;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.7);
  padding: 32px;
  height: 150px;
`;

function Atualidades() {
  const dispatch = useDispatch();
  const state = useSelector((reduxState) => reduxState.tests);

  const concursoId = useSelector(
    (reduxState) => reduxState.concurso.active.concursoId
  );
  const concursoIdsData = useSelector(
    (reduxState) => reduxState.concurso.active.test
  );

  const [tests, setTests] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const set_loading = (status) => {
    dispatch({
      type: "SET_LOADING",
      payload: status,
    });
  };

  function set_totalPages(totalPage) {
    dispatch({
      type: "SET_TOTAL_PAGE",
      payload: totalPage,
    });
  }

  useEffect(() => {
    async function fetchQuestions() {
      try {
        set_loading(true);

        if (concursoIdsData) {
          const testType  = window.location.search.search('testType=tps') >= 0 ? 'tps' : 'weekly';

          const URL_PARAMS = `?${concursoIdsData.reduce((acum, current) => {
            return (acum = `concursoId=${current}&` + acum);
          }, "")}page=${state.atualidades.page}&testType=${testType}`;

          if (typeof api.defaults.headers.Authorization === "object") {
            api.defaults.headers.Authorization = localStorage.getItem("token");
          }

          const { data } = await api.get(`/test/listsimulados${URL_PARAMS}`);

          if (!data || !data?.tests.length) {
            throw new Error("Sem Conteúdo");
          }

          const _tests = [];

          for (const _test of data.tests) {
            _tests.push({
              ..._test,
              date: new Date(_test.createdAt).toLocaleDateString(),
            });
          }

          set_totalPages(data.count);
          setTotalPages(data.count);
          setTests(_tests);

          set_loading(false);
        }
      } catch (error) {
        set_totalPages(1);
        set_loading(false);
        console.error("Falha ao carregar questões de atualidades", error);
      }
    }

    fetchQuestions();
  }, [
    state.atualidades.page,
    state.atualidades.filter.choosedFilter,
    state.gabarito._id,
    concursoId,
    window.location.search,
  ]);

  if (state.loading) {
    return (
      <LoadingContainer>
        <CircularProgress style={{ color: "#F58220" }} />
      </LoadingContainer>
    );
  }

  return (
    <AtualidadesContainer>
      {tests.length === 0 ? (
        <EmptySeach>Nenhum resultado encontrado</EmptySeach>
      ) : (
        <QuestionsContainer>
          {tests.map((test) => (
            <Cards
              subjects={test.subjects}
              topics={test.topics}
              questionsSize={test.questionsSize}
              date={test.date}
              answer={test.answer}
              key={test._id}
              _id={test._id}
            />
          ))}
        </QuestionsContainer>
      )}
      <Pagination
        total={totalPages}
        perPage={10}
        currentPage={state.atualidades.page}
        tab={"atualidades"}
      />
    </AtualidadesContainer>
  );
}

export default Atualidades;
