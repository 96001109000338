import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import Table, {
  TableBody
} from 'material-ui/Table';
import EnhancedTableHead from './TableHead'
import EnhancedTableToolbar from './Toolbar'
import Row from './Row'
import Loading from './Loading'

const styles = theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing.unit * 3,
      height: 'fit-content'
    },
    table: {
      minWidth: 300,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    center: {
      textAlign: 'center'
    },
    itemText: {
      ...theme.typography.body1,
      color: theme.palette.text.basic
    },
    loading: {
      width: '100%',
      display: 'flex',
      alignItems: 'center', 
      justifyContent: 'center',
      minHeight: '150px'
    }
  });
  
  class EnhancedTable extends React.Component {
    constructor(props, context) {
      super(props, context);
  
      this.state = {

      };
    }
  
    handleRequestSort = (event, property) => {
      const orderBy = property;
      let order = 'desc';
  
      if (this.state.orderBy === property && this.state.order === 'desc') {
        order = 'asc';
      }
  
      const data =
        order === 'desc'
          ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
          : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
  
      this.setState({ data, order, orderBy });
    };
  
    handleSelectAllClick = (event, checked) => {
      if (checked) {
        this.setState({ selected: this.state.data.map(n => n.id) });
        return;
      }
      this.setState({ selected: [] });
    };
  
    handleClick = (event, id) => {
      const { selected } = this.state;
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];
  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
  
      this.setState({ selected: newSelected });
    };
  
    handleChangePage = (event, page) => {
      this.setState({ page });
    };
  
    handleChangeRowsPerPage = event => {
      this.setState({ rowsPerPage: event.target.value });
    };
  
    render() {
      const { classes, toolbar = false, columnData = [], data, changeEditalTopics, questionPerformance={}  } = this.props;
  
      return (
        <div className={classes.root}>
        { toolbar ? <EnhancedTableToolbar  /> : null }
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <EnhancedTableHead
                columnData={columnData}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {
                  data.length > 0 ? data.map(n => <Row changeEditalTopics={changeEditalTopics} key={n._id} questionPerformance={questionPerformance[n._id]} {...n}/>) : 
                    <Loading />
                }
              </TableBody>
            </Table>
          </div>
        </div>
      );
    }
  }
  
  EnhancedTable.propTypes = {
    classes: PropTypes.object.isRequired,
    columnData: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired
  };
  
  export default withStyles(styles)(EnhancedTable);