import React from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withStyles } from "material-ui/styles";
import ClearIcon from "material-ui-icons/Clear";
import { push } from "react-router-redux";

const styles = (theme) => ({
  input: {
    paddingLeft: "8px",
    paddingRight: "8px",
    width: "100%",
    background: "transparent",
    fontFamily: theme.typography.fontFamily2,
    fontSize: "16px",
    fontWeight: 500,
    color: theme.palette.text.basic,
    "&::-webkit-input-placeholder": {
      color: "rgba(0,0,0,0.4)",
    },
    outlineWidth: 0,
    border: 0,
  },
  container: {
    display: "flex",
    width: "100%",
  },
  icon: {
    margin: "0px 10px",
    fill: "rgba(0,0,0,0.2)",
    cursor: "pointer",
  },
});

class SearchInput extends React.Component {
  componentDidMount() {
    if (Boolean(this.props.searchFocus)) {
      this.searchInput && this.searchInput.focus();
    }
  }

  handleClear = () => {
    this.props.handleSearch("");
    this.searchInput.value = "";
    this.searchInput.focus();
  };

  handleKeyPress = (event) => {
    const { pushRouter, handleClearSearch, routeLabel } = this.props;

    if (routeLabel === "Clippings" && event.key === "Enter") {
      handleClearSearch();
      pushRouter(`/busca/noticias?q=${event.target.value}`);
    }
  };

  render() {
    const { classes, onFocus, onBlur, handleSearch, routeLabel } = this.props;

    return (
      <div className={classes.container}>
        <input
          ref={(input) => (this.searchInput = input)}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyPress={this.handleKeyPress}
          onChange={(e) => handleSearch(e.target.value)}
        //   placeholder={`Pesquisar em ${routeLabel === "Clippings" ? "Notícias Diárias" : routeLabel}`}
          placeholder={`Pesquisar em ${routeLabel}`}
          className={classes.input}
        />
        {Boolean(this.props.searchTerms) && <ClearIcon className={classes.icon} onClick={this.handleClear} />}
      </div>
    );
  }
}

const mapDispatchToProps = {
  pushRouter: (path) => push(path),
};

export default compose(withStyles(styles), connect(null, mapDispatchToProps))(SearchInput);
