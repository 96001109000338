import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DragSource, DropTarget } from 'react-dnd'
import { withStyles } from 'material-ui/styles';
import compose from 'recompose/compose';
import SwapVert from 'material-ui-icons/SwapVert'

const styles = theme => ({
  wrapper: {
		cursor: 'move',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
		background: '#fff',
		width: '100%',
		marginTop: '16px',
		marginBottom: '16px',
		padding: '8px',
		boxShadow: theme.shadows[25],
		paddingLeft: '12px',
		position: 'relative',
		'&:hover': {
			zIndex: 1,
			paddingLeft: '8px',
			boxShadow: theme.shadows[26],
			borderLeftColor: theme.palette.clearOrange,
			borderLeftStyle: 'solid',
			borderLeftWidth: '4px'
		},
		'&:hover $icon': {
			color: '#B6B6B6'
		}
	},
	title: {
		...theme.typography.body3,
		fontSize: '15px',
		display: 'flex',
		justifyContent: 'space-between'
	},
	content: {
		marginTop: '8px',
		...theme.typography.small,
		color: theme.palette.text.clear,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		WebkitBoxOrient: 'vertical',
		WebkitLineClamp: 4, 
		lineHeight: '13px',       
		maxHeight: '65px'
	},
	icon: {
		height: '20px',
		width: '20px',
		color: 'transparent'
	}
})

const cardSource = {
	beginDrag(props) {
		return {
			_id: props._id,
			originalIndex: props.findCard(props.id).index,
		}
	},

	endDrag(props, monitor) {
		const { _id: droppedId, originalIndex } = monitor.getItem()
		const didDrop = monitor.didDrop()

		if (!didDrop) {
			props.moveCard(droppedId, originalIndex)
		}
	},
}

const cardTarget = {
	canDrop() {
		return false
	},

	hover(props, monitor) {
		const { _id: draggedId } = monitor.getItem()
		const { _id: overId } = props

		if (draggedId !== overId) {
			const { index: overIndex } = props.findCard(overId)
			props.moveCard(draggedId, overIndex)
		}
	},
}

// @DropTarget('card', cardTarget, connect => ({
// 	connectDropTarget: connect.dropTarget(),
// }))
// @DragSource('card', cardSource, (connect, monitor) => ({
// 	connectDragSource: connect.dragSource(),
// 	isDragging: monitor.isDragging(),
// }))

class Card extends Component {
	static propTypes = {
		connectDragSource: PropTypes.func.isRequired,
		connectDropTarget: PropTypes.func.isRequired,
		isDragging: PropTypes.bool.isRequired,
		_id: PropTypes.any.isRequired,
		title: PropTypes.string,
		moveCard: PropTypes.func.isRequired,
		findCard: PropTypes.func.isRequired,
	}

	render() {
		const {
			title,
			content = '',
			isDragging,
			connectDragSource,
			connectDropTarget,
			classes,
			onClick
		} = this.props
		const opacity = isDragging ? 0 : 1

		return connectDragSource(
			connectDropTarget(<div onClick={onClick} className={classes.wrapper} style={{opacity}}>
				<div className={classes.title}>
					<div className={classes.titleContent}>{title}</div>
					<SwapVert className={classes.icon}/>
				</div>
				<div className={classes.content}>{content.replace(/<\/?[^>]+(>|$)/g, "")}</div>
			</div>),
		)
	}
}


export default compose(
	withStyles(styles),
	DropTarget('card', cardTarget, connect => ({
			connectDropTarget: connect.dropTarget(),
	})),
	DragSource('card', cardSource, (connect, monitor) => ({
			connectDragSource: connect.dragSource(),
			isDragging: monitor.isDragging(),
	}))
)(Card);