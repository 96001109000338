export const USER_LOGIN = 'USER_LOGIN';
export const USER_MOUNT = 'USER_MOUNT';
export const USER_LOGIN_LOADING = 'USER_LOGIN_LOADING';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';

export const userLogin = (payload, pathName) => {
    return {
        type: USER_LOGIN,
        payload,
        meta: { auth: true, pathName },
    };
}

export const userMount = () => {
    return {
        type: USER_MOUNT,
        meta: { auth: true, pathName: '' },
    };
}

export const USER_CHECK = 'USER_CHECK';

export const userCheck = (payload, pathName) => ({
    type: USER_CHECK,
    payload,
    meta: { auth: true, pathName },
});

export const USER_LOGOUT = 'USER_LOGOUT';

export const userLogout = () => ({
    type: USER_LOGOUT,
    meta: { auth: true },
});

export const REDIRECT_TO_LOGIN = 'REDIRECT_TO_LOGIN';

export const redirectToLogin = () => ({
    type: REDIRECT_TO_LOGIN,
    meta: { auth: true },
});