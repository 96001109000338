import React, { useState } from "react";
import { withStyles } from "material-ui/styles";
import { IconButton } from "material-ui";
import { Timer, Close } from "material-ui-icons";

import Counter from "./counter";

const styles = (theme) => ({
  mainContainer: {
    width: "100%",
    height: 50,
    background: "#8377f2",
    position: "fixed",
    zIndex: 11,
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 620px)": {
      justifyContent: "flex-start",
      paddingLeft: "20px",
    },
    "@media (max-width: 600px)": {
      justifyContent: "center",
      paddingLeft: "0px",
      height: 70,
    },
  },
  content: {
    width: "fit-content",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 600px)": {
      height: 70,
      justifyContent: "center",
      flexDirection: "column",
    },
  },

  closeIcon: {
    width: "24px",
    height: "24px",
    alignSelf: "center",
    "@media (max-width: 900px)": {
      width: "18px",
      height: "18px",
    },
  },
  closeButton: {
    zIndex: "3",
    width: "24px",
    height: "24px",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: "24px",
    color: "rgba(255, 255, 255, 0.5)",
    "@media (max-width: 900px)": {
      right: "12px",
      width: "18px",
      height: "18px",
    },
    "@media (max-width: 730px)": {
      right: "6px",
    },
    "@media (max-width: 600px)": {
      right: "6px",
    },
  },
  info: {
    width: "fit-content",
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    "@media (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  text: {
    fontFamily: "Proxima Nova",
    width: "fit-content",
    height: "fit-content",
    fontSize: "17px",
    lineHeight: "17px",
    fontWeight: 600,
    color: "rgba(255, 255, 255, 0.7);",
    margin: 0,
    "@media (max-width: 800px)": {
      fontSize: "15px",
      lineHeight: "15px",
    },
    "@media (max-width: 700px)": {
      fontSize: "13px",
      lineHeight: "13px",
    },
    "@media (max-width: 600px)": {
      fontSize: "13px",
      lineHeight: "16px",
    },
    "@media (max-width: 400px)": {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
  link: {
    alignSelf: "center",
    cursor: "pointer",
    marginLeft: "4px",
    color: "#fff",
    fontFamily: "Proxima Nova",
    fontSize: "15px",
    fontWeight: "bold",
    height: "fit-content",
    lineHeight: "15px",
    width: "fit-content",
    textAlign: "center",
    textDecoration: "underline",
    "@media (max-width: 800px)": {
      fontSize: "14px",
      lineHeight: "14px",
    },
    "@media (max-width: 700px)": {
      fontSize: "12px",
      lineHeight: "12px",
    },
    "@media (max-width: 600px)": {
      fontSize: "13px",
      lineHeight: "16px",
    },
  },
});

function SecondaryBar({ classes, hideBar }) {
  return (
    <div className={classes.mainContainer}>
      <div className={classes.content}>
        <div className={classes.info}>
          <p className={classes.text}>
            [Vagas Limitadas] Matricule-se no Curso Intensivo CACD 2020 com até
            48% de Desconto
          </p>
          <a
            href="https://app.clippingconcursos.com.br/checkout/intensivo-teorico-pratico?utm_source=faixa&utm_medium=plataforma&utm_campaign=faixa-plataforma-checkout-intensivoos"
            className={classes.link}
          >
            GARANTIR VAGA
          </a>
        </div>
      </div>
      <IconButton className={classes.closeButton} onClick={() => hideBar()}>
        <Close className={classes.closeIcon} />
      </IconButton>
    </div>
  );
}

export default withStyles(styles)(SecondaryBar);
