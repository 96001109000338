import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import {ElementList, Title} from '../../components/common';
import Grid from 'material-ui/Grid';
import {FetchList} from '../../components';
import Item from './Discurso';
import SectionFilter from './SectionFilter';
import { push } from 'react-router-redux';
import { crudGetList as crudGetListAction } from '../../actions/dataActions';
import { SORT_ASC } from '../../reducers/resource/list/queryReducer';

const resource = 'doeContent'

const styles = theme => ({
  content: {
    paddingTop: '8px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '32px'
    }
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between'
  },
  contentMain: {
    padding: '40px 16px 16px 16px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px'
    }
  },
  contentSidebar: {
    display: 'none',
    padding: '8px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px 0 0 0',
      marginTop: '150px',
      marginLeft: '0',
      marginRight: 'auto',
      display: 'block',
      order: '1'
    }
  },
  root: {
    flexGrow: 1,
    padding: '8px'
  },
});

class Discursos extends Component {
  componentWillMount() {
    this.props.loadDoeSections();
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.refreshState !== this.props.refreshState) {
      window.scrollTo(0, 0);
    }

    if(nextProps.concurso.name !== 'CACD') {
      this.props.pushRouter('/mural');
    }
  }

  render() {
    const {classes, doeSections} = this.props

    return (
      <div>
        <section className={classes.content}>
          <Grid container className={classes.root} >
            <Grid item xs={12} md={12} className={classes.contentMain}>
              <FetchList {...this.props}
                  feedback={true}
                  filters={<SectionFilter doeSections={doeSections} />}
                  resource={resource} >
                <ElementList
                  renderRow={(props) => <Item {...props} />}
                />
              </FetchList>
            </Grid>
          </Grid>
        </section>
      </div>
    )
  }
}

Discursos.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    refreshState: state.plataform.refreshState,
    doeSections: state.doeSection.data,
    concurso: state.concurso.active
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    pushRouter: (path) => dispatch(push(path)),
    loadDoeSections: () => dispatch(crudGetListAction(
      'doeSection', { page: 1, perPage: 99 }, 
      { field: 'order', order: SORT_ASC }, {}, true
    ))
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(Discursos);
