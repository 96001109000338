import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { CircularProgress } from "material-ui/Progress";

const VideoLoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: #7f7f7f;
  position: absolute;
  align-items: center;
`;

import VideoPlayer from "../../Cursos/VideoFunctional";
import FakeVideo from "./FakeVideo";

function Video({ responsive = false }) {
  const videoLoaded = useSelector((state) => state.courseGroup.videoLoaded);
  const curso = useSelector((state) => state.courseGroup.currentCourse.data);
  const aula = useSelector((state) => state.courseGroup.currentClass.data);
  const showVideo = useSelector(
    (state) => state.courseGroup.classInfo.data.showVideo
  );

  return (
    <div>
      {showVideo === false ? (
        <FakeVideo responsive={responsive} />
      ) : (
        <div
          style={{
            position: "relative",
            width: responsive ? "100vw" : "665px",
            height: responsive ? "56.25vw" : "375px",
          }}
        >
          {!videoLoaded && (
            <VideoLoadingContainer>
              <CircularProgress style={{ color: "#fff" }} />
            </VideoLoadingContainer>
          )}
          <VideoPlayer
            newHeight={true}
            responsive={responsive}
            classData={{
              title: aula.name,
              number: aula.number,
            }}
            course={curso}
            open={{ video: aula.video_id }}
          />
        </div>
      )}
    </div>
  );
}

export default Video;
