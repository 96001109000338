import React, { useState } from 'react';

import IconButton from 'material-ui/IconButton';
import Menu from 'material-ui/Menu';
import { MenuItem } from 'material-ui/Menu';
import { MoreVert } from '@material-ui/icons';
import { MdOutlinedFlag, MdOutlineRemoveCircleOutline } from 'react-icons/md';
import { ModalReportDialog } from './ModalReport';
import { ModalDeleteDialog } from './ModalDelete';
import { useSelector } from 'react-redux';

export const ButtonDeleteAndReport = ({
  commentId,
  answer,
  commentParent,
  userCommentId,
  objectResource,
  resource,
}) => {
  const profile = useSelector((state) => state.user.profile);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showModalReport, setShowModalReport] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openModalReport = () => {
    setShowModalReport(true);
    setAnchorEl(null);
  };

  const deleteComment = () => {
    setShowModalDelete(true);
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: '112px',
            width: '258px',
            borderRadius: '8px',
          },
        }}
      >
        <MenuItem key={1} onClick={openModalReport}>
          <MdOutlinedFlag />
          <span style={{ marginLeft: '10px' }}>Denunciar Abuso</span>
        </MenuItem>
        {profile._id === userCommentId._id && (
          <MenuItem key={1} onClick={deleteComment} style={{ color: 'red' }}>
            <MdOutlineRemoveCircleOutline />
            <span style={{ marginLeft: '10px' }}>Excluir Comentário</span>
          </MenuItem>
        )}
      </Menu>

      <ModalReportDialog
        commentId={commentId}
        open={showModalReport}
        onClose={() => setShowModalReport(false)}
      />
      <ModalDeleteDialog
        resource={resource}
        commentId={commentId}
        objectResource={objectResource}
        commentParent={commentParent}
        answer={answer}
        open={showModalDelete}
        setOpen={setShowModalDelete}
        onClose={() => setShowModalDelete(false)}
      />
    </React.Fragment>
  );
};
