import { call, takeLatest, put, select, race, take } from "redux-saga/effects";
import { delay } from "redux-saga";
import { isAfter } from "date-fns";
import { sendToAnalytics } from "./utils";

import {
  NEW_PAY_COURSE_CLASS,
  DEBITS_FETCH,
  CREATE_DEBIT,
  CURRENT_DEBIT_FETCH,
  USER_PAYMENTS_FETCH,
  USER_PAYMENTS_FETCH_SUCCESS,
  USER_PAYMENTS_FETCH_FAILURE,
  USER_CARD_DELETE_REQUEST,
  BOLETO_FETCH,
  PAYMENT_FETCH,
  SHOULD_UPDATE_MASK,
  SAVE_SPONSOR,
  DEBITS_FETCH_FAILURE,
  DEBITS_FETCH_SUCCESS,
} from "./constants";

import { closeRegister, stepLoading } from "../Checkout/actions";

import {
  payCourseClassFailure,
  payCourseClassSuccess,
  createDebitFailure,
  createDebitSuccess,
  debitsFetchFailure,
  debitsFetchSuccess,
  debitsFetchRequest,
  currentDebitFetchFailure,
  currentDebitFetchSuccess,
  currentDebitFetchRequest,
  setPaymentStatusModalOpen,
  fetchBoletoFailure,
  fetchBoletoSuccess,
  fetchPaymentFailure,
  fetchPaymentSuccess,
  setLoadingModalOpen,
  userPaymentsFetchRequest,
  userPaymentsFetchFailure,
  userPaymentsFetchSuccess,
  deleteCardSuccess,
  deleteCardFailure,
  openConfirmDelete,
  setCreditPayModalOpen,
  setSponsorID,
} from "./actions";

import { classInfoFetchRequest, classInfoFetchFailure } from "../Cursos/actions";

import api from "../../services/newApi";
import { push } from "react-router-redux";
import { userFetchRequest } from "../User/actions";
import { USER_FETCH_FAILURE, USER_FETCH_SUCCESS } from "../User/constants";

export function* saveSponsor(action) {
  try {
    const headers = { Authorization: localStorage.getItem("token") };

    const student = yield select((state) => state.userData.data.id);
    const { address, personalData } = action.payload;

    const { city, state, zipcode, neighborhood, complementary, street, street_number } = address;
    const { cpf, rg, name, email, mobile } = personalData;

    const payload = {
      cpf,
      rg,
      email,
      name,
      mobile,
      street,
      street_number,
      complementary,
      neighborhood,
      city,
      state,
      zipcode,
      student,
    };

    const { data } = yield call(api.post, `/users/cartao-terceiro/`, payload, {
      headers,
    });
    yield put(setSponsorID(data.id));

    yield put(stepLoading(false));
    yield put(closeRegister());
    yield put(setCreditPayModalOpen(true));
  } catch (error) {
    yield put(stepLoading(false));
    yield put(setSponsorID(""));

    window.alert("Falha ao salvar dados Responsável Financeiro, tente novamente");
  }
}

export function* updateMaskSaga(action) {
  try {
    yield put(userPaymentsFetchRequest());
    const { paymentFetchSuccess, paymentFetchFailure } = yield race({
      paymentFetchSuccess: take(USER_PAYMENTS_FETCH_SUCCESS),
      paymentFetchFailure: take(USER_PAYMENTS_FETCH_FAILURE),
    });

    if (paymentFetchFailure !== undefined) {
      throw new Error(400);
    }

    const { content, socket } = action;
    const { id: profile } = yield select((state) => state.userData.data);
    const courseData = yield select((state) => state.courseGroup.currentCourse.data);
    const moduleData = yield select((state) => state.courseGroup.currentModule.data);
    const classData = yield select((state) => state.courseGroup.currentClass.data);
    let classID, moduleID, courseID, currentModulePacote, currentCoursePacote;
    if (classData.id) {
      classID = classData.id;
    }
    if (moduleData.id) {
      moduleID = moduleData.id;
      currentModulePacote = moduleData.pacotes_modulo[0].id;
    }
    if (courseData.id) {
      courseID = courseData.id;
      currentCoursePacote = courseData.pacotes_curso[0].id;
    }
    for (const pacote of content.pacotes) {
      if (currentCoursePacote === pacote.id) {
        yield put(
          classInfoFetchRequest({
            profile,
            classID,
            moduleID,
            courseID,
            isPaid: true,
          })
        );
        break;
      }
      if (currentModulePacote === pacote.id) {
        yield put(
          classInfoFetchRequest({
            profile,
            classID,
            moduleID,
            courseID,
            isPaid: true,
          })
        );
        break;
      }
      for (const aulaModulo of moduleData.aulas) {
        if (pacote.aulas.findIndex((aulaPacote) => aulaPacote.id === aulaModulo.id) !== -1) {
          yield put(
            classInfoFetchRequest({
              profile,
              classID,
              moduleID,
              courseID,
              isPaid: true,
            })
          );
          break;
        }
      }
    }
  } catch (error) {
    yield put(classInfoFetchFailure());
    yield put(push("/cursos/"));
  }
}

export function* payCourseClassSaga(action) {
  const headers = { Authorization: localStorage.getItem("token") };
  const ip_address = localStorage.getItem("user_ip");

  try {
    // exibir o loading
    yield put(setLoadingModalOpen(true));

    const { body } = action;
    body.ip_address = ip_address;

    const { data: response } = yield call(api.post, "/payments/qpq/", body, {
      headers,
    });

    const moduleID = yield select((state) => state.courseGroup.currentModule.data.id);
    yield delay(1000);

    yield put(debitsFetchRequest(moduleID));
    const { debitsSuccess, debitsFailure } = yield race({
      debitsSuccess: take(DEBITS_FETCH_SUCCESS),
      debitsFailure: take(DEBITS_FETCH_FAILURE),
    });

    if (debitsFailure !== undefined) {
      throw new Error(400);
    }

    yield put(userPaymentsFetchRequest());
    const { paymentFetchSuccess, paymentFetchFailure } = yield race({
      paymentFetchSuccess: take(USER_PAYMENTS_FETCH_SUCCESS),
      paymentFetchFailure: take(USER_PAYMENTS_FETCH_FAILURE),
    });

    if (paymentFetchFailure !== undefined) {
      throw new Error(400);
    }

    yield put(userFetchRequest());
    const { userSuccess, userFailure } = yield race({
      userSuccess: take(USER_FETCH_SUCCESS),
      userFailure: take(USER_FETCH_FAILURE),
    });
    if (userFailure !== undefined) {
      throw new Error(400);
    }

    yield put(payCourseClassSuccess({ payload: response }));
    yield put(setLoadingModalOpen(false));
    yield put(setPaymentStatusModalOpen(true));
  } catch (error) {
    yield delay(2000);
    yield put(payCourseClassFailure());
    yield put(setLoadingModalOpen(false));
    yield put(setPaymentStatusModalOpen(true));
  }
}

export function* fetchUserPayments() {
  const headers = { Authorization: localStorage.getItem("token") };

  try {
    const profile = yield select((state) => state.userData.data.id);

    let data = {
      index: "payments",
      query: {
        bool: {
          must: [
            {
              match: {
                "perfil.id": profile,
              },
            },
            {
              match: {
                status: "paid",
              },
            },
          ],
        },
      },
      size: 1000,
    };
    const { data: response } = yield call(api.post, "/elastic/", data, {
      headers,
    });

    const userPayments = response.results;

    const paymentsData = {
      matriculas: [],
      aulas: [],
      modulos: [],
      cursos: [],
    };
    if (userPayments.length) {
      for (const payment of userPayments) {
        const ends_on = new Date(payment.expiration);
        for (const pacote of payment.pacote) {
          for (const curso of pacote.cursos) {
            paymentsData.cursos.push({
              courseID: curso.id,
              pacote: pacote.id,
              ends_on,
            });
          }
          for (const modulo of pacote.modulos) {
            paymentsData.modulos.push({
              moduleID: modulo.id,
              pacote: pacote.id,
              ends_on,
            });
          }
          for (const aula of pacote.aulas) {
            if (aula.number === 0) {
              paymentsData.matriculas.push({
                aula: aula.id,
                pacote: pacote.id,
                ends_on,
              });
            } else {
              paymentsData.aulas.push(aula.id);
            }
          }
        }
      }
    }
    yield put(userPaymentsFetchSuccess({ payload: paymentsData }));
  } catch (error) {
    yield put(userPaymentsFetchFailure());
  }
}

export function* fetchDebits(action) {
  const headers = { Authorization: localStorage.getItem("token") };

  try {
    const { moduleID } = action;

    const profile = yield select((state) => state.userData.data.id);

    //busca os débitos do usuário dentro do módulo em questão
    let data = {
      index: "debits_qpq",
      query: {
        bool: {
          must: [
            {
              match: {
                "perfil.id": profile,
              },
            },
            {
              match: {
                "modulo.id": moduleID,
              },
            },
          ],
        },
      },
    };
    const { data: response } = yield call(api.post, "/elastic/", data, {
      headers,
    });
    const userDebits = response.results;

    //cria um objeto com débitos válidos (que já podem ser cobrados) e inválidos (que ainda não podem ser cobrados)
    const debits = {
      enabled: [],
      disabled: [],
    };

    if (userDebits.length) {
      for (const debit of userDebits) {
        //busca a aula referente ao débito
        let debitClassQuery = {
          index: "aulas",
          query: {
            match: {
              id: debit.aula.id,
            },
          },
        };

        const { data: debitClassData } = yield call(api.post, "/elastic/", debitClassQuery, {
          headers,
        });

        const debitClass = debitClassData.results[0];
        const currentDate = new Date();

        //verifica se a aula contém vídeo
        if (debitClass.qpq) {
          if (debitClass.video_id) {
            debits.enabled.push(debit);
          } else {
            if (debitClass.webinar_id) {
              if (isAfter(currentDate, debitClass.publish_zoom)) {
                debits.enabled.push(debit);
              } else {
                debits.disabled.push(debit);
              }
            } else {
              debits.disabled.push(debit);
            }
          }
        } else {
          debits.disabled.push(debit);
        }
      }
      if (debits.enabled.length) {
        yield put(currentDebitFetchRequest(debits.enabled[0].pacote));
      } else {
        yield put(currentDebitFetchRequest(null));
      }
      yield put(debitsFetchSuccess({ payload: debits }));
    } else {
      yield put(currentDebitFetchRequest(null));
      yield put(debitsFetchSuccess({ payload: debits }));
    }
  } catch (error) {
    yield put(debitsFetchFailure());
  }
}

export function* fetchCurrentDebit(action) {
  const headers = { Authorization: localStorage.getItem("token") };

  try {
    const { pacote } = action;
    if (pacote !== null) {
      let data = {
        index: "pacotes",
        query: {
          match: {
            id: pacote,
          },
        },
      };

      const { data: pacoteData } = yield call(api.post, "/elastic/", data, {
        headers,
      });

      if (pacoteData.results.length > 0) {
        yield put(currentDebitFetchSuccess({ payload: pacoteData.results[0] }));
      } else {
        yield put(currentDebitFetchSuccess({ payload: { id: "", value: "" } }));
      }
    } else {
      yield put(currentDebitFetchSuccess({ payload: { id: "", value: "" } }));
    }
  } catch (error) {
    yield put(currentDebitFetchFailure());
  }
}

export function* createDebit(action) {
  const headers = { Authorization: localStorage.getItem("token") };
  const { body } = action;
  try {
    const { data: response } = yield call(api.post, "/payments/debits/", body, {
      headers,
    });
    yield put(createDebitSuccess({ payload: response }));
    yield put(
      classInfoFetchRequest({
        profile: body.perfil,
        classID: body.aula,
        moduleID: body.modulo,
        courseID: body.curso,
      })
    );
  } catch (error) {
    yield put(createDebitFailure());
    yield put(
      classInfoFetchRequest({
        profile: body.perfil,
        classID: body.aula,
        moduleID: body.modulo,
        courseID: body.curso,
      })
    );
  }
}

export function* fetchBoleto(action) {
  const headers = { Authorization: localStorage.getItem("token") };
  const ip_address = localStorage.getItem("user_ip");

  try {
    const purchasedItems = action.payload;
    const boletoPayload = {
      gateway: "pagarme",
      boleto_instructions: "PAGÁVEL EM QUALQUER BANCO ATÉ O VENCIMENTO.",
      payment_method: "boleto",
      installments: 1,
      items: purchasedItems,
      ip_address,
      checkout_name: purchasedItems.checkout_name,
    };
    const response = yield call(api.post, "/payments/pagarme/", boletoPayload, {
      headers,
    });

    const { googleGA } = yield select((state) => state.checkout);
    sendToAnalytics({ ...googleGA, payment_method: "boleto" });

    yield delay(3000);
    yield put(fetchBoletoSuccess());
  } catch (error) {
    yield put(fetchBoletoFailure());
  }
}

export function* fetchPayment(action) {
  const headers = { Authorization: localStorage.getItem("token") };
  const ip_address = localStorage.getItem("user_ip");

  yield put(setLoadingModalOpen(true));

  try {
    const creditCardPayload = action.payload;
    creditCardPayload.ip_address = ip_address;

    const response = yield call(api.post, "/payments/pagarme/", creditCardPayload, { headers });

    yield delay(1000);

    yield put(userFetchRequest());
    const { userFetchSuccess, userFetchFailure } = yield race({
      userFetchSuccess: take(USER_FETCH_SUCCESS),
      userFetchFailure: take(USER_FETCH_FAILURE),
    });

    if (userFetchFailure !== undefined) {
      throw new Error(400);
    }

    yield put(userPaymentsFetchRequest());
    const { paymentFetchSuccess, paymentFetchFailure } = yield race({
      paymentFetchSuccess: take(USER_PAYMENTS_FETCH_SUCCESS),
      paymentFetchFailure: take(USER_PAYMENTS_FETCH_FAILURE),
    });

    if (paymentFetchFailure !== undefined) {
      throw new Error(400);
    }

    const { googleGA } = yield select((state) => state.checkout);
    sendToAnalytics({ ...googleGA, payment_method: "credit_card" });

    yield put(fetchPaymentSuccess());
    yield put(setLoadingModalOpen(false));
    yield put(setPaymentStatusModalOpen(true));
  } catch (error) {
    yield delay(3000);
    yield put(fetchPaymentFailure());
    yield put(setLoadingModalOpen(false));
    yield put(setPaymentStatusModalOpen(true));
  }
}

export function* deleteSavedCard(action) {
  const headers = { Authorization: localStorage.getItem("token") };
  try {
    const { id } = action;

    yield call(api.delete, `/payments/remover-user-card/${id}/`, { headers });

    yield delay(1000);
    yield put(userFetchRequest());
    const { userFetchSuccess, userFetchFailure } = yield race({
      userFetchSuccess: take(USER_FETCH_SUCCESS),
      userFetchFailure: take(USER_FETCH_FAILURE),
    });

    if (userFetchFailure !== undefined) {
      throw new Error(400);
    }

    yield put(deleteCardSuccess());
    yield put(openConfirmDelete(false));
  } catch (error) {
    yield delay(1000);
    yield put(openConfirmDelete(false));
    yield put(deleteCardFailure());
  }
}

export default function* paymentSaga() {
  yield [
    takeLatest(SAVE_SPONSOR, saveSponsor),
    takeLatest(USER_CARD_DELETE_REQUEST, deleteSavedCard),
    takeLatest(SHOULD_UPDATE_MASK, updateMaskSaga),
    takeLatest(CURRENT_DEBIT_FETCH, fetchCurrentDebit),
    takeLatest(NEW_PAY_COURSE_CLASS, payCourseClassSaga),
    takeLatest(DEBITS_FETCH, fetchDebits),
    takeLatest(CREATE_DEBIT, createDebit),
    takeLatest(BOLETO_FETCH, fetchBoleto),
    takeLatest(PAYMENT_FETCH, fetchPayment),
    takeLatest(USER_PAYMENTS_FETCH, fetchUserPayments),
  ];
}
