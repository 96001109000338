import React, { useEffect, useState } from 'react';
import { withStyles } from 'material-ui/styles';
import { reduxForm, reset } from 'redux-form';
import compose from 'recompose/compose';
import Button from '../../components/Elements/Button'
import {validateEmail} from '../../utils/validation'
import InputText from '../../components/Elements/InputText';

const formName = 'kindleEdit'

const styles = theme => ({
  container: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    maxWidth: '970px',
    margin: '0 auto',
    padding: '32px 24px',
    '&:last-child': {
      borderBottom: 'none',
      marginBottom: '100px'
    }
  },
  title: {
    ...theme.typography.subtitle,
    margin: '0px'
  },
  info: {
    ...theme.typography.observation,
    marginBottom: '35px'
  },
  listItem: {
    ...theme.typography.observation,
    counterIncrement: 'item',
    listStyleType: 'none',
    paddingBottom: '8px',
    '&:before': {
      textAlign: 'left',
      fontWeight: 'bold',
      content: 'counter(item) "."',
      display: 'inline-block',
      width: '16px',
      paddingRight: '8px'
    }
  },
  list: {
    padding: '0px',
    counterReset: 'item',
    marginBottom: '48px'
  },
  inputContainer: {
    marginBottom: '32px',
    maxWidth: '350px'
  }
})

function KindleEdit({ classes, handleSubmit, profile, ...rest }) {
  const [email, setEmail] = useState(profile.kindle);
  const [error, setError] = useState("");

  function handleSubmitKindle() {
    const { kindleConnect, kindleDisconnect, dispatch } = rest;
  
    setError("");

    if(!email)
      return setError ("Digite um e-mail");

    if(validateEmail(email))
      return setError("E-mail inválido");

    if(profile.kindle) {
      kindleDisconnect(profile._id, 'E-mail desvinculado com sucesso!');
      
      return dispatch(reset(formName));
    }

    kindleConnect(profile._id, { kindle: email }, 'E-mail atualizado com sucesso!');
  }

  useEffect(() => {
    setEmail(profile.kindle);
  }, [profile.kindle]);

  return (
    <div className={classes.wrapper}>
      <form className={classes.container} onSubmit={handleSubmit(handleSubmitKindle)}>
          <h2 className={classes.title}>Configurar envio para Kindle</h2>
          <div className={classes.info}>Para configurar o envio do clipping para seu kindle, siga os passos abaixo:</div>
          <ol className={classes.list}>
            <li className={classes.listItem}>Adicione o email contato@clippingcacd.com.br como confiável em sua conta Amazon vinculada ao Kindle.</li>
            <li className={classes.listItem}>Verifique o email do aparelho que deverá receber o conteúdo e insira-o no campo abaixo.</li>
            <li className={classes.listItem}>Não fazemos envio retroativo, portanto é necessário aguardar o próximo envio (no próximo dia útil) para começar a receber.</li>
          </ol>
          <div className={classes.inputContainer}>
            <InputText
              label="E-MAIL DO SEU KINDLE"
              name="e-mail"
              autocomplete="e-mail"
              input={{
                value: email,
                onChange: (e) => {
                  setError("");
                  setEmail(e.target.value);
                },
                disabled: Boolean(profile.kindle)
              }}
              helperText={error} />
          </div>

          {profile.kindle ? (
            <Button type="submit">REMOVER</Button>
          ) : (
            <Button type="submit">SALVAR</Button>
          )}
      </form>
    </div>
  )
}

const enhance = compose(
  withStyles(styles),
  reduxForm({
      form: formName
  })
);

export default enhance(KindleEdit)
