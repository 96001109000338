import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { push } from "react-router-redux";
import { withStyles } from "material-ui/styles";
import Dialog from "material-ui/Dialog";
import { CircularProgress, IconButton } from "@material-ui/core";
import { CheckCircleOutline, ErrorOutline, Close } from "@material-ui/icons";

import { setBoletoModalOpen, setBoletoUrl, fetchBoleto } from "../actions";

const styles = {
  paper: {
    minWidth: 650,
  },
  paperResponsive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const CloseButton = styled(IconButton)`
  position: absolute !important;
  top: 4px;
  right: 4px;
  color: rgba(0, 0, 0, 0.4);
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 48px 64px;
  font-family: "Proxima Nova" !important;
`;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoadingTitle = styled.div`
  font-size: 25px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.75);
`;

const SuccessContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 48px;
`;

const SuccessTitle = styled.span`
  width: 100%;
  font-size: 25px;
  letter-spacing: -0.7px;
  text-align: center;
  line-height: 28px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.75);
  margin-bottom: 32px;
  font-family: "Proxima Nova";
`;

const FailureContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 48px;
`;

const FailureTitle = styled.div`
  font-size: 25px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.75);
  margin-bottom: 16px;
`;

const MessageContainer = styled.div`
  width: 100%;
  height: fit-content;
  margin-bottom: 30px;
`;

const Message = styled.p`
  color: rgba(0, 0, 0, 0.75);
  font-size: 16px;
  font-weight: normal;
  height: fit-content;
  letter-spacing: 0px;
  line-height: 22px;
  text-align: center;
  white-space: pre-line;
  width: fit-content;
  margin: 0px;
  font-family: "Proxima Nova";
`;

const CustomButton = styled.button`
  border: none;
  background-color: #fc7954;
  border-radius: 25px;
  font-size: 13px;
  color: #fff;
  font-weight: 600;
  width: ${(props) => (props.error ? "150px" : "100%")};
  height: 40px;
  text-transform: uppercase;
  font-family: "Proxima Nova";
  &:hover {
    background: #e95b34;
    border: #e95b34;
  }
`;

const ReturnButton = styled.button`
  border: 2px solid rgb(252, 121, 84);
  background-color: #fff;
  border-radius: 25px;
  font-size: 13px;
  color: rgb(252, 121, 84);
  font-weight: 600;
  width: 100%;
  height: 40px;
  text-transform: uppercase;
  font-family: "Proxima Nova";
  &:hover {
    border-color: #e95b34;
    color: #e95b34;
  }
`;

const SuccessButtonsWrapper = styled.div`
  display: flex;
  width: 232px;
  height: 96px;
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const CustomCircularProgress = styled(CircularProgress)`
  margin-bottom: 24px;
  & > svg {
    color: rgb(252, 121, 84);
  }
`;

const CustomFailureIcon = styled(ErrorOutline)`
  margin-bottom: 22px;
  font-size: 64px !important;
  color: rgba(0, 0, 0, 0.4);
`;

const CustomSuccessIcon = styled(CheckCircleOutline)`
  margin-bottom: 22px;
  font-size: 64px !important;
  color: #31c98e;
`;

function BoletoModal({ classes }) {
  const dispatch = useDispatch();
  const { open, boletoUrl, loading, error } = useSelector(
    (state) => state.paymentGroup.boletoModal
  );
  
  const checkout_name = location.href.split('/').pop()

  const responsive = useSelector((state) => state.plataform.responsive);

  const checkoutItems = useSelector((state) => state.checkout.cart.items);

  const courseID = useSelector(
    (state) => state.courseGroup.currentCourse.data.id
  );
  const moduleID = useSelector(
    (state) => state.courseGroup.currentModule.data.id
  );
  const classID = useSelector(
    (state) => state.courseGroup.currentClass.data.id
  );

  function handleClose() {
    dispatch(setBoletoUrl(""));
    dispatch(setBoletoModalOpen(false));
  }

  function handleReturn() {
    dispatch(setBoletoUrl(""));
    dispatch(setBoletoModalOpen(false));
    if (
      courseID !== undefined &&
      moduleID !== undefined &&
      classID !== undefined
    ) {
      dispatch(push(`/cursos/${courseID}/modulo/${moduleID}/aula/${classID}`));
    }
  }

  function openBoletoUrl() {
    window.open(boletoUrl, "_blank");
  }

  useEffect(() => {
    dispatch(setBoletoUrl(""));
    if (open) {
      Object.assign(checkoutItems, {checkout_name})
      dispatch(fetchBoleto(checkoutItems));
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      fullScreen={responsive}
      classes={{ paper: responsive ? classes.paperResponsive : classes.paper }}
    >
      <Content>
        {!loading && (
          <CloseButton aria-label="Fechar" onClick={() => handleClose()}>
            <Close />
          </CloseButton>
        )}
        {loading && (
          <LoadingContainer>
            <CustomCircularProgress />
            <LoadingTitle>Aguarde...</LoadingTitle>
          </LoadingContainer>
        )}
        {error && (
          <FailureContainer>
            <CustomFailureIcon />
            <FailureTitle>Falha ao gerar boleto</FailureTitle>
            <MessageContainer>
              <Message>
                {
                  "Ocorreu um erro ao gerar seu boleto, por favor repita o processo e tente novamente."
                }
              </Message>
            </MessageContainer>
            <CustomButton error={true} onClick={() => handleClose()}>
              Entendi
            </CustomButton>
          </FailureContainer>
        )}
        {!loading && boletoUrl && (
          <SuccessContainer>
            <CustomSuccessIcon />
            <SuccessTitle>Recebemos seu pedido</SuccessTitle>
            <MessageContainer>
              <Message>
                {
                  "Você receberá um email assim que o pagamento do boleto for confirmado. O prazo de liberação é de até "
                }
                <b>72h úteis após o pagamento.</b>
                {"\n\nTambém enviaremos um email com uma cópia do boleto."}
              </Message>
            </MessageContainer>
            <SuccessButtonsWrapper responsive={responsive}>
              <CustomButton onClick={() => openBoletoUrl()}>
                Visualizar Boleto
              </CustomButton>
              <ReturnButton onClick={() => handleReturn()}>
                Voltar para Cursos
              </ReturnButton>
            </SuccessButtonsWrapper>
          </SuccessContainer>
        )}
      </Content>
    </Dialog>
  );
}

export default withStyles(styles)(BoletoModal);
