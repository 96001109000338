import React, { Component } from 'react'
import { connect } from 'react-redux';
import _ from 'lodash'
import { withStyles } from 'material-ui/styles';
import compose from 'recompose/compose';
import Dialog from '../../components/ModalScreen';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import Button from 'material-ui/Button';
import Slide from 'material-ui/transitions/Slide';
import ArrowLeft from 'material-ui-icons/ChevronLeft'
import {crudGetOne as crudGetOneAction} from '../../actions/dataActions'
import BookmarkContent from './BookmarkContent'


const styles = theme => ({
  appBar: {
    top: 0,
    left: 'auto',
    right: 0,
    position: 'sticky',
    backgroundColor: '#FFF',
    transition: theme.transitions.create('width'),
    height: '65px',
    paddingRight: '24px',
    justifyContent: 'center',
    boxShadow: theme.shadows[27],
  },
  backContainer: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      padding: '18px'
    },
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  backIcon: {
    ...theme.typography.menu,
    color: theme.palette.text.clear,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '8px',
    paddingLeft: '0px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  responsiveDisplay: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inherit'
    }
  },

  content: {
    marginTop: '65px',
    flex: 1,
    marginLeft: '8px',
    marginRight: '8px'
  },
  toolbar: {
    padding: '0px'
  }, 
  icon: {
    color: theme.palette.text.clear,
  },
  titleContainer: {
    ...theme.typography.title,
    paddingTop: '4px',
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',

  },
  menuIcons: {
    display: 'flex',
    color: theme.palette.common.white,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  dialog: {
    display: 'flex',
    alignItems: 'stretch',
    minHeight: '100vh',
    width: '100%',
  },
  slide: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: '100%'
  },
  arrowLeft: {
    height: '40px',
    width: '40px',
    color: theme.palette.black.a3,
    [theme.breakpoints.up('sm')]: {
      height: '25px',
      width: '25px',
      color: theme.palette.text.clear
    },
  }
});

class ItemBibliografia extends Component {
  
  componentDidMount() {
    if(this.props.open && this.props.open.clippingId) {
      this.props.crudGetOne(this.props.open.clippingId)
    }
  }

  transition = (props) => {
    return <Slide direction="up" {...props} className={this.props.classes.slide}  />;
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.open && (!this.props.open || this.props.open !== nextProps.open)){
      this.props.crudGetOne(nextProps.open)
    }
  }

  render () {
    const {open = {}, handleOpen, classes, bookmark} = this.props
    return (
      <Dialog open={Boolean(open)} handleOpen={handleOpen} >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.backContainer}>
              <Button
                disableRipple
                disableFocusRipple
                aria-label="voltar"
                onClick={() => handleOpen(false)}
                className={classes.backIcon}
              >
                <ArrowLeft className={classes.arrowLeft}/> <div className={classes.responsiveDisplay}>voltar</div>
              </Button>
            </div>
            <div className={classes.titleContainer}>
                LER DEPOIS
            </div>
            <div className={classes.menuIcons}>
            </div>
          </Toolbar>
        </AppBar>
          <BookmarkContent {...bookmark}/>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, {open} ) => {
  return {
    user: state.user.profile,
    bookmark: state.article.data[open]
  }
}

const mapDispatchToProps = {
  crudGetOne: (_id) => crudGetOneAction('article', _id, '', false, false, {$embed: ['section']}),
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ItemBibliografia);

