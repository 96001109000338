import React, { Component } from 'react';
import { withStyles } from 'material-ui/styles';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Paper from 'material-ui/Paper';
import BookmarkBorder from 'material-ui-icons/BookmarkBorder';
import Bookmark from 'material-ui-icons/Bookmark';
import classNames from 'classnames';
import {Link} from 'react-router';
import moment from 'moment';
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';

const styles = theme => ({
  icontainer: {
    width: '100%',
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    color: theme.palette.text.secondary,
    flexDirection: 'column',
    '&:last-child $row': {
      borderBottom: 'none'
    }
  },
  row: {
    ...theme.typography.subheading,
    display: 'flex',
    width: '90%',
    padding: '8px',
    paddingTop: '0px',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid #97979733',
    background: 'transparent'
  },
  iarticlesC1: {
    ...theme.typography.default,
    flex: 8,
    minHeight: '31px',
    cursor: 'pointer',
    textDecoration: 'none',
    [theme.breakpoints.down('xs')]: {
      marginRight: '8px'
    }
  }, 
  iarticlesC2: {
    ...theme.typography.body3,
    fontFamily: theme.typography.fontFamily1,
    fontWeight: '400',
    fontSize: '13px',
    color: theme.palette.black.clear,
    flex: 2,
    textAlign: 'center',
    cursor: 'pointer',
    padding: '0px 8px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '8px'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }, 
  iarticlesC3: {
    flex: 1,
    textAlign: 'center',
    height: '25px',
    '&:hover': {
      color: theme.palette.orange,
      opacity: 1,
    },
    cursor: 'pointer'
  },
  bookmark: {
    width: '21px',
    height: '21px',
    color: 'rgba(0, 0, 0, 0.4)',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.7)',
    },
    '&$bookmarked': {
      color: 'rgba(0, 0, 0, 0.7)',
    }
  },
  bookmarked: {
    
  },
  source: {
    color: theme.palette.black.a5,
    fontFamily: theme.typography.fontFamily1,
    fontSize: '11px',
    lineHeight: '13px',
    margin: '0px',
    marginTop: '4px'
  },
  title :{
    paddingTop: '4px',
    margin: '0px',
    lineHeight: '16px',
    overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1, 
    [theme.breakpoints.down('sm')]: {
      WebkitLineClamp: 2, 
    }
  }
})

class ArticleItem extends Component {
  track() {
    let { _id, clippingId, trackMetric } = this.props;

    trackMetric('Clippings - Intenção de uso', { 
      target: 'card',
      trigger: 'click',
      resource: _id,
      parentResource: clippingId
    });
  } 

  render() {
    const { name, title, _id, classes, clippingId, bookmark, handleBookmark, source, pubDate, concurso } = this.props;
    const date = moment(pubDate, "YYYY-MM-DD");

    return (
      <div className={classes.icontainer}>
        <Paper className={classes.row} elevation={0} >
          <Link className={classes.iarticlesC1} to={`/clippings/${clippingId}/${_id}`} >
            <div onClick={() => this.track()}>
              <p className={classes.title}>{title}</p>
              <p className={classes.source}>{pubDate && date.format('DD/MM/YYYY')} {source ? `(${source})` : ''}</p>
            </div>
          </Link>
          <div className={classes.iarticlesC2}>{name}</div>
          {(concurso.name !== 'TRT') && (
            <div className={classes.iarticlesC3} onClick={() => handleBookmark({ clippingId, resourceId: _id, title }, !Boolean(bookmark))} >
              { bookmark ? <Bookmark className={classNames(classes.bookmark, classes.bookmarked)} /> : 
                <BookmarkBorder className={classNames(classes.bookmark)} />}
            </div>
          )}
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  concurso: state.concurso.active
});

const mapDispatchToProps = (dispatch) => {
  return {
    trackMetric: (name, data) => dispatch(trackMetricAction(name, data))
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ArticleItem);