export function sendToAnalytics({ items, purchase, payment_method }) {
  const nowTimestamp = new Date().getTime();

  let products = [];
  for (const item of items) {
    const payload = {
      id: item.id,
      name: item.name,
      category: item.category,
      brand: item.brand,
      variant: item.variant,
      price: item.price,
      quantity: 1,
    };
    products.push(payload);
  }

  window.dataLayer.push({
    event: 'purchase',
    eventCallback() {
      dataLayer.push({
        event: 'custom-event-pageview',
        eventCategory: 'Checkout',
        eventAction: 'Compra de Pacoted de Cursos',
        pageview: '/checkout/compra-com-sucesso'
      });

      document.location.href = redirectTo;
    },
    ecommerce: {
      purchase: {
        actionField: {
          id: `${purchase.id}_${nowTimestamp}`,
          affiliation: purchase.affiliation,
          revenue: purchase.revenue,
          tax: purchase.tax,
          shipping: purchase.shipping,
          payment_type: payment_method,
        },
        products: products
      }
    }
  });

}
