import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import {ElementList, Title} from '../../components/common';
import Grid from 'material-ui/Grid';
import {FetchList} from '../../components';
import Item from './Item';
import ModalBookmark from './ModalBookmark';
import {crudDelete as crudDeleteAction} from '../../actions/dataActions';
import swal from 'sweetalert';
import EmptyList from './EmptyList';
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';

const resource = 'bookmark'

const styles = theme => ({
  content: {
    paddingTop: '8px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '32px'
    }
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between'
  },
  contentMain: {
    padding: '40px 16px 16px 16px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px'
    }
  },
  contentSidebar: {
    display: 'none',
    padding: '8px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px 0 0 0',
      marginTop: '150px',
      marginLeft: '0',
      marginRight: 'auto',
      display: 'block',
      order: '1'
    }
  },
  root: {
    flexGrow: 1,
    padding: '8px'
  },
});


class ListaLeitura extends Component {
  constructor(props) {
    super(props)
    
    const {_id}  = props.routeParams
    this.state = {
      modal: _id || false
    }
  }

  handleModal = (_id) => {
    const { trackMetric } = this.props;
    Boolean(_id) && trackMetric('Clippings - Ativação', { target: 'bookmark', trigger: 'click', resource: _id });
    this.setState({modal: _id})
    this.props.pushRouter(`/ler-depois${_id ? '/' + _id : ''}`)
  }
  
  componentWillReceiveProps(nextProps) {
    if(nextProps.refreshState !== this.props.refreshState) {
      window.scrollTo(0, 0);
    }
  }

  render() {

    const {classes, deleteBookmark} = this.props
    const {modal} = this.state

    return (
      <div>
        <section className={classes.content}>
          <Grid container className={classes.root} >
            <Grid item xs={12} md={12} className={classes.contentMain}>
              <FetchList {...this.props}
                  stateRefresh={open}
                  noData={<EmptyList />}
                  resource={resource} >
                <ElementList
                      renderRow={(props) => <Item {...props} openModal={this.handleModal} deleteBookmark={deleteBookmark}/>}
                    />
                </FetchList>
              </Grid>
            </Grid>
        </section>
        <ModalBookmark handleOpen={this.handleModal} open={modal} />
      </div>
    )
  }
}

ListaLeitura.propTypes = {
  classes: PropTypes.object.isRequired,
  pushRouter: PropTypes.func.isRequired
};


const mapDispatchToProps = (dispatch) => {
  return {
    trackMetric: (name, data) => dispatch(trackMetricAction(name, data)),
    pushRouter: (path) => dispatch(push(path)),
    deleteBookmark: (_id) => {
      swal({
          title: "Deseja remover este item da lista de leitura?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        },
      )
      .then((willDelete) => {
        if (willDelete) {
          dispatch(crudDeleteAction(resource, _id, '', false, false, false))
          swal("O item foi removido de sua lista de leitura!", {
            icon: "success",
          });
        } else {
          swal.close()
        }
      });
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    refreshState: state.plataform.refreshState
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ListaLeitura);
