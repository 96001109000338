import React, { Component } from 'react'
import { withStyles } from 'material-ui/styles';
import {IconButton} from '../../components/common'
import AddIcon from 'material-ui-icons/Add'

const styles = theme => ({
  container: {
    marginTop: '40px',
    marginBottom: '40px',
    width: '100%',
    height: '1px',
    justifyContent: 'center',
    display: 'flex',
    borderBottom: '1px dashed rgba(245,130,32,0.4)',
    opacity: 0,
    transition: 'opacity .5s'
  },
  button: {
    marginTop: '-18px',
    background: 'linear-gradient(134.12deg, #FAD961 0%, #F76B1C 100%)',
    border: 'none',
    height: '36px',
    width: '36px',
    boxShadow: theme.shadows[26],
  },
  wrapper: {
    width: '100%',
    height: '70px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover $container': {
      opacity: 1
    },
  },
  icon: {
    height: '15px',
    width: '15px',
    color: '#FFF'
  }
})

class AddCaderno extends Component {

  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  handleClick = () => {
    const {order, addFichamento} = this.props 
    addFichamento(order)
  }

  render() {
    const {classes, forceDisplay} = this.props

    return (
      <div className={classes.wrapper}>
        <div className={classes.container} style={forceDisplay ? {opacity: 1} : {}}>
          <IconButton customClasses={{button: classes.button}} 
                onClick={this.handleClick}>
            <AddIcon className={classes.icon}/>
          </IconButton> 
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(AddCaderno)
