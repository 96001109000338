import React, { Component } from 'react'
import ContaEdit from './ContaEdit'
import KindleEdit from './KindleEdit'
import PlanoEdit from './PlanoEdit'
import SenhaEdit from './SenhaEdit'
import CoursePackSummary from './CoursePackSummary';

import { withStyles } from 'material-ui/styles';

const styles = theme => ({
  container: {
    flex: 1,
    padding: '16px',
    [theme.breakpoints.up('md')]: {
      padding: '24px 0px 0px 360px'
    },
    '@media (min-width: 1600px)': {
      padding: '24px 0',
      position: 'absolute',
      top: '72px',
      left: 'calc(50% - 485px)'
    }
  }
})

class Container extends Component {
  render() {
    const {section, classes, ...props} = this.props

    return (
      <div className={classes.container}>
        {{
          conta: <ContaEdit {...props} />,
          'plano-cobranca': <PlanoEdit subscription={{}} {...props} />,
          'alterar-senha': <SenhaEdit {...props} />,
          'kindle': <KindleEdit {...props} initialValues={{'e-mail': props.profile && props.profile.kindle}}/>
        }[section]}
      </div>
    )
  }
}


export default withStyles(styles)(Container)