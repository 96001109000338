import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';

const DISQUS_PUBLIC_KEY = 'WrwXjMffDfyIhfG4pYegaD1WTkqSqjHise8KOYbfiLtGdpKLr5ave5DjBbwtwJWS'
const SHORTNAME = 'clippingcacd';
const WEBSITE_URL = 'https://app.clippingconcursos.com.br';

var addComment = () => {}
var trackMetric = () => {}

class Disqus extends React.Component{
  renderDisqus = (remote_auth_s3) => {
    if (window.DISQUS === undefined) {
      var script = document.createElement('script');
      script.async = true;
      script.src = '//' + SHORTNAME + '.disqus.com/embed.js';

      window.disqus_config = function () {
        this.page.remote_auth_s3 = remote_auth_s3;
        this.page.api_key = DISQUS_PUBLIC_KEY;

        this.callbacks.onNewComment = [() => {
          let [resourceId, feature] = window.disqus_identifier_id.split('/');

          trackMetric(`${(feature && feature === 'gabarito') ? 'Simulados' : 'Mural'} - Ativação`, {
            target: 'comment',
            trigger: 'click',
            action: 'create',
            resource: resourceId
          });

          addComment(window.disqus_identifier_id)
        }];
      }
      document.getElementsByTagName('head')[0].appendChild(script);
    }
    else {
      window.DISQUS.reset && window.DISQUS.reset({reload: true});
    }
  }

  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  };

  shouldComponentUpdate(nextProps) {
    return this.props.id !== nextProps.id ||
      this.props.title !== nextProps.title ||
      this.props.disqus_auth !== nextProps.disqus_auth
  }

  componentDidMount() {
    addComment = this.props.addComment || (() => {});
    trackMetric = this.props.trackMetric;
    this.renderDisqus(this.props.remote_auth_s3)
  }

  componentDidUpdate() {
    this.renderDisqus(this.props.remote_auth_s3)
  }

  render() {
    let { id, title, resource, externalId, ...other} = this.props;

    window.disqus_shortname = SHORTNAME;
    window.disqus_identifier = externalId || id;
    window.disqus_identifier_id = id
    window.disqus_title = title;
    window.disqus_url = `${WEBSITE_URL}/${resource}/${externalId || id}`;
    delete other.remote_auth_s3
    delete other.addComment
    delete other.trackMetric

    return <div {...other} id="disqus_thread" style={{WebkitOverflowScrolling: 'touch', height: 'auto', position: 'static', overflow: 'auto'}}/>;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    trackMetric: (name, data) => dispatch(trackMetricAction(name, data))
  }
}

export default connect(null, mapDispatchToProps)(Disqus);
