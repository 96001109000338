import React from "react";
import styled from "styled-components";
import { withStyles } from "material-ui/styles";
import Dialog from "material-ui/Dialog";
import DialogContent from "material-ui/Dialog/DialogContent";
import DefaultLoading from "../../components/Loading";

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
`;

const styles = {
  root: {
    scrollbarWidth: "15px",
    position: "relative",
    padding: 0,
    paddingTop: 0,
    display: "block",
    width: "100vw",
    height: "100vh",
  },
};

function LoadingModal({ classes }) {
  return (
    <Dialog id="dialog" scroll={"paper"} open={true} fullScreen={true}>
      <DialogContent
        id="content"
        classes={{ root: classes.root }}
        dividers="false"
      >
        <LoadingContainer>
          <DefaultLoading sidebar={true} />
        </LoadingContainer>
      </DialogContent>
    </Dialog>
  );
}

export default withStyles(styles, { index: 1 })(LoadingModal);
