import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const plugins = [
  {
    beforeDraw: function (chart) {
      let width = chart.width,
        height = chart.height + 5,
        ctx = chart.ctx;
      ctx.restore();
      let fontSize = (height / 160).toFixed(2);
      ctx.font = fontSize + ' 13px Arial';
      ctx.fillStyle = '#717171';
      ctx.textBaseline = 'top';
      let text = 'Item',
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      let text2 = 'anulado';
      ctx.fillText(text, textX, textY);
      ctx.fillText(text2, textX - 8, textY + 15);
      ctx.save();
    },
  },
];

const options = {
  plugins: {
    legend: {
      display: true,
      labels: {
        usePointStyle: true,
      },
      align: 'start',
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let total = 0;
          let percent = 0;

          for (let i = 0; i < context.dataset.data.length; i++)
            total += context.dataset.data[i];

          percent = Number((context.raw / total) * 100).toFixed(2);

          return `${context.label}: ${context.raw}・${percent}%`;
        },
      },
    },
  },
};

const optionsAnnulled = {
  events: [],
  plugins: {
    legend: {
      display: true,
      labels: {
        usePointStyle: true,
      },
      align: 'start',
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let total = 0;
          let percent = 0;

          for (let i = 0; i < context.dataset.data.length; i++)
            total += context.dataset.data[i];

          percent = Number((context.raw / total) * 100).toFixed(2);

          return `${context.label}: ${context.raw}・${percent}%`;
        },
      },
    },
  },
};

export default function DoughnutChart({ data, localQuestion }) {
  return (
    <Doughnut
      data={data}
      options={localQuestion.annulled ? optionsAnnulled : options}
      plugins={localQuestion.annulled ? plugins : ''}
    />
  );
}
