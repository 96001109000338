// @flow

import React from 'react';
import classNames from 'classnames';
import { withStyles } from 'material-ui/styles';

const styles = theme => ({
  wrapper: {
		cursor: 'pointer',
    flex: 1,
    alignItems: 'center',
    borderRadius: '3px',
    display: 'flex',
		width: '100%',
		marginTop: '8px',
		marginBottom: '8px',
		padding: '8px',
    paddingLeft: '12px',
    color: theme.palette.text.clear,
		'&$active': {
      color: theme.palette.text.basic,
      fontWeight: 600
    },
    fontSize: '15px',
    fontFamily: 'Proxima Nova',
    lineHeight: '13px',
    textDecoration: 'none',
    minHeight: '40px'
	},
  active: {

  },
  icon: {
    opacity: '0.6'
  },
  name: {
    paddingLeft: '8px'
  }
});

class AppDrawerNavItem extends React.Component {

  handleClick = (pathname) => {
    this.props.closeDrawer();
    this.props.pushRouter(pathname)
  };

  render() {
    const { classes, pathname, icon, name, section } = this.props;

    const active = pathname === section
      ? classes.active
      : ''

    return (
      <div
        onClick={() => this.handleClick(pathname)}
        className={classNames(classes.wrapper, active)}
      > 
        {
          icon.custom
            ? <img className={classes.icon} src={icon.item} />
            : <i className={classNames(classes.icon.item, `icon-${icon.item} icons`)}></i>
        }
        <span className={classes.name}>{name}</span>
      </div>
    );
  }
}

// AppDrawerNavItem.propTypes = {
//   children: PropTypes.node,
//   classes: PropTypes.object.isRequired,
//   href: PropTypes.string,
//   onClick: PropTypes.func,
//   openImmediately: PropTypes.bool,
//   title: PropTypes.string.isRequired,
// };

export default withStyles(styles)(AppDrawerNavItem);
