import React, { Component } from 'react'
import { withStyles } from 'material-ui/styles';
import _ from 'lodash'
import AddFichamento from './AddFichamento'
import ItemFichamento from './ItemFichamento'

const styles = theme => ({
  wrapper: {
    flex: 1,
    background: '#fff',
    paddingTop: '70px',
    paddingLeft: '8px',
    paddingRight: '8px',
    left: 'calc(50% - 370px)',
    position: 'absolute',
    '@media (max-width: 1600px)': {
      position: 'static',
      marginLeft: '305px'
    },
    '@media (max-width: 960px)': {
      marginLeft: '0px'
    },
    height: 'max-content',
    paddingBottom: '80px',
  }
})

class FichamentosContent extends Component {
  render () {
    const {classes, fichamentos, addFichamento, fichamentoId} = this.props
    const propsWithoutClasses = {...this.props, classes: null}

    return (
      <div className={classes.wrapper}>
        {fichamentos.length > 0 ? _.map(fichamentos, (props = {}) => {
          return <ItemFichamento key={props._id} focus={fichamentoId} {...propsWithoutClasses} {...props} />
        }) : <AddFichamento addFichamento={addFichamento} order={0} forceDisplay={true}/>}
      </div>
    )
  }
}

export default withStyles(styles)(FichamentosContent)
