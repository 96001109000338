import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 32px;
  font-family: 'Inter' !important;

  @media (max-width: 720px) {
    padding: 24px;
  }
`;

export const TopicsArea = styled.div`
  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    display: inline;
    color: rgba(0, 0, 0, 0.75);
  }
  p {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    display: inline;
    color: rgba(0, 0, 0, 0.4);
  }
  margin: 0px 20px 20px 0px;
`;

export const CardHeader = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
`;

export const HeaderIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background: #f2eeed;
  border-radius: 50%;

  & svg {
    width: 20px;
    height: 20px;
    fill: #fc5836;
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

export const HeaderTitle = styled.div`
  width: calc(100% - 52px);
  display: flex;
  flex-direction: column;
  margin-left: 16px;

  & h3 {
    margin: 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.75);

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & span {
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.4);
  }

  @media (max-width: 720px) {
    margin-left: 0px;
    width: 100%;

    & h3 {
      -webkit-line-clamp: 2;
      font-size: 15px;
      line-height: 24px;
    }
  }
`;

export const CardContent = styled.div`
  width: calc(100% - 52px);
  display: flex;
  flex-direction: column;
  margin: 24px 0px 0px 48px;

  @media (max-width: 720px) {
    margin: 16px 0px 0px 0px;
    width: 100%;
  }
`;

export const CiclesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CicleContent = styled.p`
  width: 100%;

  margin: 0px 0px 8px 0px;

  font-weight: 500;
  font-size: 13px;
  line-height: 20px;

  color: rgba(0, 0, 0, 0.4);

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;

  & b {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.75);
  }

  &:last-child {
    margin: 0px;
  }

  @media (max-width: 720px) {
    margin: 0px 0px 12px 0px;
    width: 100%;
    -webkit-line-clamp: 3;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  /* margin-top: 24px; */

  & * {
    margin-right: 16px;

    @media (max-width: 720px) {
      margin-right: 10px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }
`;

export const StartBtn = styled.button`
  height: 40px;
  border-radius: 20px;

  border: ${({ alt }) => (alt ? '1px solid #fc7954' : 'none')};
  background-color: ${({ alt }) => (alt ? '#fff' : '#fc7954')};
  color: ${({ alt }) => (alt ? '#fc7954' : '#fff')};

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;

  font-weight: 500;
  font-size: 15px;
  line-height: 24px;

  &:hover {
    background-color: ${({ alt }) => (alt ? '#fc7954' : 'rgb(233, 91, 52)')};
    color: #fff;
  }

  @media (max-width: 400px) {
    font-size: 13px;
    line-height: 20px;
    height: 36px;
    border-radius: 18px;
    padding: 0px 12px;
  }
`;

export const DropDownBtn = styled.button`
  position: relative;
  height: 40px;
  border-radius: 20px;

  border: 1px solid #fc7954;
  background-color: #fff;
  color: #fc7954;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;

  font-weight: 500;
  font-size: 15px;
  line-height: 24px;

  &:hover {
    background-color: #fc7954;
    color: #fff;
  }

  & span {
    margin: 0px;
  }

  @media (max-width: 400px) {
    font-size: 13px;
    line-height: 20px;
    height: 36px;
    border-radius: 18px;
    padding: 0px 12px;
  }
`;

export const OptionsContainer = styled.div`
  box-shadow: rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 5%) 0px 0px 0px 1px,
    rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px;
`;

export const OptionsGroup = styled.ul`
  box-shadow: rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 5%) 0px 0px 0px 1px,
    rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px;
  position: absolute;

  z-index: 2;
  min-width: 150px;
  top: 30px;
  left: -2px;
  padding: 4px 0px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);

  & > li {
    width: 100%;
    padding: 6px 16px;
    display: flex;
    align-items: center;
    font-weight: 400;
    line-height: 1.5;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  @media (max-width: 400px) {
    & > li {
      height: 32px;
    }

    bottom: -68px;
  }
`;

export const TestScore = styled.div`
  height: 40px;

  border: none;
  border-radius: 20px;
  background-color: #f2eeed;
  color: rgba(0, 0, 0, 0.75);

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;

  font-weight: 500;
  font-size: 15px;
  line-height: 24px;

  @media (max-width: 400px) {
    font-size: 13px;
    line-height: 20px;
    height: 36px;
    border-radius: 18px;
    padding: 0px 12px;
  }
`;
