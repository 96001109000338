import {
  CHECKOUTS_OPEN,
  CHECKOUTS_CLOSE,
  CHECKOUTS_FETCH,
  CHECKOUTS_FETCH_SUCCESS,
  CHECKOUTS_FETCH_FAILURE,
  CHECKOUT_FETCH,
  CHECKOUT_FETCH_SUCCESS,
  CHECKOUT_FETCH_FAILURE,
  CHECKOUT_SET_URL,
  CHECKOUT_SET_DATA,
  CHECKOUT_SET_GOOGLEGA,
  CART_SET_PRODUCTS,
  RESET_CHECKOUT,
  NEXT_STEP,
  PREVIOUS_STEP,
  STEP_LOADING,
  SET_STEPS,
  OPEN_REGISTER,
  OPEN_CONFIRM_EXIT,
  CLOSE_REGISTER,
} from "./constants";

const INITIAL_STATE = {
  currentCheckout: {
    open: false,
    url: "",
    ano: "",
    concurso: "",
    categoria: "",
    nome: "",
    loading: false,
    error: false,
    data: {
      matriculas: {
        show: false,
        modulos: [],
        checkedAll: {},
        indeterminate: {},
        pacotes: [],
      },
      matriculas_aulas: {
        show: false,
        modulos: [],
        checkedAll: {},
        indeterminate: {},
        pacotes: [],
      },
      pacote_completo: {
        show: false,
        modulos: [],
        checkedAll: {},
        indeterminate: {},
        pacotes: [],
      },
      personalizado: {
        show: false,
        modulos: [],
        checkedAll: {},
        pacotes: [],
      },
    },
  },
  googleGA: {
    items: [],
    purchase: {
      id: "",
      affiliation: "",
      revenue: 0,
      tax: 0,
      shipping: 0,
    },
  },
  cart: {
    price: {
      a_vista: 0,
      parcelado: 0,
    },
    items: [],
  },
  checkoutSelect: {
    open: false,
    loading: false,
    error: false,
    data: [],
  },
  openRegister: false,
  openConfirmExit: false,
  stepLoading: false,
  steps: ["Dados pessoais", "Endereço"],
  step: 0,
  sponsor: false,
};

export default (previousState = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case STEP_LOADING:
      return {
        ...previousState,
        stepLoading: payload,
      };
    case OPEN_CONFIRM_EXIT:
      return {
        ...previousState,
        openConfirmExit: payload,
      };
    case OPEN_REGISTER:
      return {
        ...previousState,
        openRegister: true,
      };
    case CLOSE_REGISTER:
      return {
        ...previousState,
        openRegister: false,
        step: 0,
      };
    case SET_STEPS:
      return {
        ...previousState,
        steps: payload.steps,
      };
    case NEXT_STEP:
      if (previousState.step < previousState.steps.length) {
        return {
          ...previousState,
          step: previousState.step + 1,
        };
      }
      break;
    case PREVIOUS_STEP:
      if (previousState.step !== 0) {
        return {
          ...previousState,
          step: previousState.step - 1,
        };
      }
      break;
    case CHECKOUTS_OPEN:
      return {
        ...previousState,
        checkoutSelect: {
          ...previousState.checkoutSelect,
          open: true,
        },
      };
    case CHECKOUTS_CLOSE:
      return {
        ...previousState,
        checkoutSelect: {
          ...previousState.checkoutSelect,
          open: false,
        },
      };
    case CHECKOUTS_FETCH:
      return {
        ...previousState,
        checkoutSelect: {
          ...previousState.checkoutSelect,
          loading: true,
        },
      };
    case CHECKOUTS_FETCH_SUCCESS:
      return {
        ...previousState,
        checkoutSelect: {
          ...previousState.checkoutSelect,
          loading: false,
          error: false,
          data: payload,
        },
      };
    case CHECKOUTS_FETCH_FAILURE:
      return {
        ...previousState,
        checkoutSelect: {
          ...previousState.checkoutSelect,
          loading: false,
          error: true,
          data: [],
        },
      };
    case CHECKOUT_FETCH:
      return {
        ...previousState,
        currentCheckout: {
          ...previousState.currentCheckout,
          loading: true,
        },
      };
    case CHECKOUT_FETCH_SUCCESS:
      return {
        ...previousState,
        currentCheckout: {
          ...previousState.currentCheckout,
          loading: false,
          error: false,
          data: payload,
        },
      };
    case CHECKOUT_FETCH_FAILURE:
      return {
        ...previousState,
        currentCheckout: {
          ...previousState.currentCheckout,
          loading: false,
          error: true,
          data: INITIAL_STATE.currentCheckout.data,
        },
      };
    case CHECKOUT_SET_GOOGLEGA:
      return {
        ...previousState,
        googleGA: {
          items: payload.items,
          purchase: payload.purchase,
        },
      };
    case CHECKOUT_SET_URL:
      return {
        ...previousState,
        currentCheckout: {
          ...previousState.currentCheckout,
          url: payload,
        },
      };
    case CHECKOUT_SET_DATA:
      return {
        ...previousState,
        currentCheckout: {
          ...previousState.currentCheckout,
          ano: payload.ano,
          categoria: payload.categoria,
          concurso: payload.concurso,
          nome: payload.nome,
        },
      };
    case CART_SET_PRODUCTS:
      const { items, price } = payload;

      return {
        ...previousState,
        cart: {
          ...previousState.cart,
          items,
          price,
        },
      };
    case RESET_CHECKOUT:
      return {
        ...previousState,
        currentCheckout: INITIAL_STATE.currentCheckout,
        cart: INITIAL_STATE.cart,
      };
    default:
      return previousState;
  }
};