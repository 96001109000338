import React, { Component } from 'react';
import classNames from 'classnames';
import { withStyles } from 'material-ui/styles';

const styles = (theme) => ({
  container: {
    padding: '40px 40px 40px 72px',
    ...theme.typography.body1,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    fontSize: '15px',
    '& + $container': {
      borderTop: '1px solid rgba(0,0,0,0.1)',
    },
    '&:first-child': {
      paddingTop: '32px',
    },
  },
  text: {
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '22px',
    ...theme.typography.noMargin,
  },
  item: {
    fontSize: '13px',
    fontWeight: 600,
    paddingLeft: '15.6px',
    backgroundPosition: 'left 5px',
    backgroundRepeat: 'no-repeat',
  },
});

class ItemQuestao extends Component {
  render() {
    const { classes, text, children, _id, sequenceKey } = this.props;

    return (
      <div id={_id} className={classes.container}>
        <div className={classNames(classes.item, 'image-arrow-right-alt')}>
          Item {sequenceKey}
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: text }}
          className={classes.text}
        ></div>
        {React.cloneElement(children, { ...this.props, classes: undefined })}
      </div>
    );
  }
}

export default withStyles(styles)(ItemQuestao);
