import styled from "styled-components";

export const Root = styled.div`
    padding-top: 40px;
    
    @media (max-width: 600px) {
      padding-top: 8px;
    }
`;

export const Content = styled.div`
    width: 100%;

    @media (min-width: 1300px) and (max-width: 1540px) {
      left: "435px",
    }

    @media (max-width: 600px) {
      padding: 8px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
`;

export const PostList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0px;

  > :not(:first-child) {
    margin-top: 12px;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;

  @media (max-width: 600px) {
    max-width: 95vw;
  }
`;