export const PAYMENT_MODAL_SLIDER_SHOW = 'PAYMENT_MODAL_SLIDER_SHOW';
export const PAYMENT_MODAL_SLIDER_HIDE = 'PAYMENT_MODAL_SLIDER_HIDE';

export const  PAYMENT_MODAL_TEXT_SHOW = 'PAYMENT_MODAL_TEXT_SHOW';
export const  PAYMENT_MODAL_TEXT_HIDE = 'PAYMENT_MODAL_TEXT_HIDE';

export const ENROLMENT_PAYMENT_MODAL_SHOW = 'ENROLMENT_PAYMENT_MODAL_SHOW';
export const ENROLMENT_PAYMENT_MODAL_HIDE = 'ENROLMENT_PAYMENT_MODAL_HIDE';

export const PAYMENT_SUCCESS_MODAL_SHOW = 'PAYMENT_SUCCESS_MODAL_SHOW';
export const PAYMENT_SUCCESS_MODAL_HIDE = 'PAYMENT_SUCCESS_MODAL_HIDE';

export const PAYMENT_ERROR_MODAL_SHOW = 'PAYMENT_ERROR_MODAL_SHOW';
export const PAYMENT_ERROR_MODAL_HIDE = 'PAYMENT_ERROR_MODAL_HIDE';

//Pagar Aula - Slider de pagamento
export const paymentSliderModalShow = (courseClassData) => ({
    type: PAYMENT_MODAL_SLIDER_SHOW,
    payload: { paymentSlider : { courseClassData, open : true }}
});
export const paymentSliderModalHide = () => ({    
    type: PAYMENT_MODAL_SLIDER_HIDE,
    payload: { paymentSlider: { open : false } }
});

//Pagar aula - Pagamento por texto
export const paymentTextModalShow = (courseClassData) => ({
    type: PAYMENT_MODAL_TEXT_SHOW,
    payload: { paymentText : { courseClassData, open : true }}
});
export const paymentTextModalHide = (courseClassData) => ({    
    type: PAYMENT_MODAL_TEXT_HIDE,
    payload: {
        paymentText: { courseClassData, open : false },
        paymentSlider: { courseClassData, open: true }
    }
});

//Efetuar matrícula
export const enrolmentPaymentModalShow = (courseClassData) => ({
    type: ENROLMENT_PAYMENT_MODAL_SHOW,
    payload: { enrolmentPayment : { courseClassData, open : true }}
});
export const enrolmentPaymentModalHide = () => ({    
    type: ENROLMENT_PAYMENT_MODAL_HIDE,
    payload: { enrolmentPayment: { open : false } }
});

//Pagamento com sucesso
export const paymentSuccessModalShow = (enrolment = false) => ({
    type: PAYMENT_SUCCESS_MODAL_SHOW,
    payload: { paymentSuccess: { open : true , enrolment}}
});
export const paymentSuccessModalHide = () => ({    
    type: PAYMENT_SUCCESS_MODAL_HIDE,
    payload: { paymentSuccess : { open : false } }
});


//Pagamento com falha
export const paymentErrorModalShow = () => ({
    type: PAYMENT_ERROR_MODAL_SHOW,
    payload: { paymentError : { open : true }}
});
export const paymentErrorModalHide = () => ({    
    type: PAYMENT_ERROR_MODAL_SHOW,
    payload: { paymentError : { open : false } }
});

//Cadastro de cartão???
    

