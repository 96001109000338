import React from 'react'
import { Link } from 'react-router';
import {activeRoute} from '../../utils'
export default ({location}) => {
  return (
  <nav className="main-nav">
      <ul>
          <li className={`mural ${activeRoute('/mural', location)}`}><Link to="/mural"><i aria-hidden="true"></i> Mural</Link></li>
          <li className={`clippings ${activeRoute('/clippings', location)}`}><Link to="/clippings"><i aria-hidden="true"></i> Clippings</Link></li>
          <li className={`organizador ${activeRoute('/organizador', location)}`}><Link to="/organizador"><i aria-hidden="true"></i> Organizador</Link></li>
          <li className={`cadernos ${activeRoute('/cadernos', location)}`}><Link to="/cadernos"><i aria-hidden="true"></i> Cadernos</Link></li>
          <li className={`cacd-tv ${activeRoute('/videos', location)}`}><Link to="/videos"><i aria-hidden="true"></i> CACD TV</Link></li>
          <li className={`questoes-comentadas ${activeRoute('/questoes', location)}`}><Link to="/questoes"><i aria-hidden="true"></i> Questões</Link></li>
          <li className={`discursos-oficiais ${activeRoute('/discursos', location)}`}><Link to="/discursos"><i aria-hidden="true"></i> Discursos</Link></li>
          <li className={`extras ${activeRoute('/extras', location)}`}><Link to="/extras"><i aria-hidden="true"></i> Extras</Link></li>
      </ul>
  </nav>
  )
}