import { put, call, takeEvery, select } from 'redux-saga/effects';
import { replace } from 'react-router-redux';
import { LoginUrl } from '../config';

import { showAlert } from '../actions/alertActions';
import {
  USER_MOUNT,
  USER_LOGIN,
  USER_LOGIN_LOADING,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_CHECK,
  USER_LOGOUT,
  REDIRECT_TO_LOGIN,
  redirectToLogin,
} from '../actions/authActions';

import { setConcurso } from '../actions/concursoActions';

import { SET_USER_PROFILE } from '../actions/userActions';

import { FETCH_ERROR } from '../actions/fetchActions';
import {
  AUTH_LOGIN,
  AUTH_MOUNT,
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_LOGOUT,
} from '../auth';

export default (authClient) => {
  if (!authClient) return () => null;
  function* handleAuth(action) {
    const { type, payload, error, meta } = action;
    switch (type) {
      case USER_MOUNT: {
        try {
          const authJson = yield call(authClient, AUTH_MOUNT);
          const concurso = localStorage.concurso;

          yield put({
            type: SET_USER_PROFILE,
            payload: {
              intercomHash: authJson.intercomHash,
              ...authJson.profile,
              ...authJson.user,
            },
          });
          if (concurso) {
            yield put(setConcurso(concurso));
          } else {
            if (authJson.profile && authJson.profile.mainConcurso) {
              yield put(setConcurso(authJson.profile.mainConcurso));
            }
          }
        } catch (e) {}
        break;
      }
      case USER_LOGIN: {
        try {
          yield put({ type: USER_LOGIN_LOADING });
          const authJson = yield call(authClient, AUTH_LOGIN, payload);

          yield put({
            type: SET_USER_PROFILE,
            payload: {
              intercomHash: authJson.intercomHash,
              ...authJson.profile,
              ...authJson.user,
            },
          });
          if (authJson.profile && authJson.profile.mainConcurso) {
            yield put(setConcurso(authJson.profile.mainConcurso));
          }
          yield put({ type: USER_LOGIN_SUCCESS });
          yield put(replace(meta.pathName || '/'));
        } catch (e) {
          yield put({
            type: USER_LOGIN_FAILURE,
            error: e,
            meta: { auth: true },
          });
          const errorMessage =
            typeof e === 'string'
              ? e
              : typeof e === 'undefined' || !e.message
              ? 'Login error'
              : e.message;
          yield put(showAlert(errorMessage, 'warning'));
          yield put(redirectToLogin());
        }
        break;
      }
      case USER_CHECK: {
        try {
          yield call(authClient, AUTH_CHECK, payload);
        } catch (e) {
          yield call(authClient, AUTH_LOGOUT);
          yield put(
            replace({
              pathname: (e && e.redirectTo) || '/login',
              state: { nextPathname: meta.pathName },
            })
          );
          yield put(redirectToLogin());
        }
        break;
      }
      case USER_LOGOUT: {
        yield call(authClient, AUTH_LOGOUT);
        yield put(redirectToLogin());
        break;
      }
      case FETCH_ERROR:
        try {
          yield call(authClient, AUTH_ERROR, error);
        } catch (e) {
          yield call(authClient, AUTH_LOGOUT);
          yield put(redirectToLogin());
        }
        break;
      case REDIRECT_TO_LOGIN:
        // BEGIN COURSE REDIRECTS
        let matches = location.pathname.match(/\w{24}/);

        let cursosJD = [
          '5c23c5074b46b0e24f6dc8b6',
          '5bf2ddd35c4dfb936f64bd9d',
          '5bf8262bce2a8708fbae18a1',
        ];
        let cursosPT = [
          '5c094cd524463d87fc5d1689',
          '5c361a6d54c5a8b7295d0974',
          '5c51f7ab8a042e4754f82733',
          '5c4b07e570a188ef891f2bf8',
        ];
        let cursosECO = [
          '5c656184518e2ab0faab3bf4',
          '5c01670ff3d323e7f9cb535e',
        ];

        if (Boolean(matches) && cursosJD.includes(matches[0])) {
          yield window.location.replace(
            'https://clippingcacd.com.br/quer-pagar-quanto/joao-daniel/'
          );
          break;
        }

        if (Boolean(matches) && cursosPT.includes(matches[0])) {
          yield window.location.replace(
            'https://clippingcacd.com.br/quer-pagar-quanto/luis-ladeira/'
          );
          break;
        }

        if (Boolean(matches) && cursosECO.includes(matches[0])) {
          yield window.location.replace(
            'https://clippingcacd.com.br/quer-pagar-quanto/michelle-miltons/'
          );
          break;
        }
        // END COURSE REDIRECTS

        const concurso = yield select((state) => state.concurso.active);
        const redirectTo = `${LoginUrl}?redirectTo=${location.pathname}&custom={"concursoId":${concurso.concursoId}}`;
        // const redirectTo = `${LoginUrl}?redirectTo=/mural/&custom={"concursoId":${concurso.concursoId}}`;
        yield window.location.replace(redirectTo);

        break;
      default:
        break;
    }
  }
  return function* watchAuthActions() {
    yield [
      takeEvery((action) => action.meta && action.meta.auth, handleAuth),
      takeEvery(FETCH_ERROR, handleAuth),
    ];
  };
};
