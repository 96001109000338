import React, { Component } from 'react'
import Favorite from 'material-ui-icons/Favorite';
import ChatBubble from 'material-ui-icons/ChatBubble'
import classNames from 'classnames';
import { withStyles } from 'material-ui/styles';

const styles = theme => ({
  socialContainer: {
    marginRight: '16px',
    fontSize: '12px',
    color: theme.palette.text.clearGrey,
    alignItems: 'center',
    display: 'flex',
    '&:hover': {
        color: theme.palette.text.clear,
    },
    '&:hover $like': {
      [theme.breakpoints.up('sm')]: {
        color: theme.palette.red
      }
    },
    '&:hover $comment': {
      color: theme.palette.text.clear
    }
  },
  like: {
    height: '16px',
    width: '24px',
    color: 'rgba(0,0,0,0.1)'
  },
  comment: {
    height: '16px',
    width: '24px',
    color: 'rgba(0,0,0,0.1)'
  },
  active: {
    color: theme.palette.red
  },
  mobile: {
    [theme.breakpoints.down('xs')]: {
      width: '25px',
      overflow: 'hidden',
      height: '12px'
    }
  }
})

// {`${items.length} Comentário${items.length === 1 ? '' : 's'}`}

class SocialButton extends Component {
  handleClick = (event) => {
    event.stopPropagation();
    this.props.onClick()
  } 

  render() {
    const {classes, type, items, loggedUserId = '', identifier = '', initialLikes = 0} = this.props

    if(type === 'comments') {
      return (
        <div className={classes.socialContainer}>
          <ChatBubble className={classNames(classes.comment)} /> 
          <span className={`disqus-comment-count ${classes.mobile}`} data-disqus-identifier={identifier}>0 Comentários</span>
        </div>
      )
    } else {
      return (
        <div className={classes.socialContainer} onClick={this.handleClick}>
          <Favorite className={classNames(classes.like, items.includes(loggedUserId.toString()) ? classes.active : '' )} />
          <span className={classes.mobile}>{`${initialLikes + items.length} Curtida${items.length === 1 ? '' : 's'}`}</span>
        </div>
      )
    }
  }
}


export default withStyles(styles)(SocialButton)