import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Snackbar, SnackbarContent, Button } from "@material-ui/core";
import { HighlightOffOutlined, CheckCircleOutline } from "@material-ui/icons";

import { clearCurrentPayment } from "../../containers/Payments/actions";

function SnackBar() {
  const dispatch = useDispatch();

  const { status } = useSelector(
    (state) => state.paymentGroup.pendingPayments.currentPayment
  );

  const responsive = useSelector((state) => state.plataform.responsive);

  function closeSnackBar() {
    dispatch(clearCurrentPayment());
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: responsive ? "center" : "left",
      }}
      open={status === "paid" || status === "refused"}
    >
      <SnackbarContent
        message={
          status === "paid" ? (
            <div>
              <CheckCircleOutline
                style={{ color: "rgb(49, 201, 142)" }}
                fontSize="small"
              />
              <span
                style={{
                  marginLeft: "10px",
                  color: "rgb(255, 255, 255)",
                  fontFamily: "Proxima Nova",
                  fontSize: "13px",
                  fontWeight: 600,
                  height: "16px",
                  letterSpacing: "0px",
                  width: "fit-content",
                }}
              >
                Pagamento recebido!
              </span>
            </div>
          ) : (
            status === "refused" && (
              <div>
                <HighlightOffOutlined
                  style={{ color: "rgb(254, 63, 75)" }}
                  fontSize="small"
                />
                <span
                  style={{
                    marginLeft: "10px",
                    color: "rgb(255, 255, 255)",
                    fontFamily: "Proxima Nova",
                    fontSize: "13px",
                    fontWeight: 600,
                    height: "16px",
                    letterSpacing: "0px",
                    width: "fit-content",
                  }}
                >
                  Pagamento recusado. Veja os detalhes no seu email.
                </span>
              </div>
            )
          )
        }
        action={
          <div>
            <Button
              style={{ color: "rgb(25, 115, 232)" }}
              size="small"
              onClick={() => closeSnackBar()}
            >
              FECHAR
            </Button>
          </div>
        }
      />
    </Snackbar>
  );
}

export default SnackBar;
