import React, { Component } from 'react'
import { withStyles } from 'material-ui/styles'
import TextEditor from '../../components/TextEditor'
import Header from './FichamentoHeader'
import AddFichamento from './AddFichamento'

const styles = theme => ({
  fichamento: {
    maxWidth: '740px',
    width: '100%',
    '@media (max-width: 1600px)': {
      margin: '0 auto'
    }, 
  },
  notebookEditor: {    
    '& .public-DraftEditor-content div > div': {
      margin: '0 0 15px 0'
    }      
  }
})

class ItemFichamento extends Component {
    scrollToFichamento = (_id) => {
      const elem = document.getElementById(_id); 
      elem && elem.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }	 
  
    componentDidMount() {
      if(this.props._id === this.props.focus) {
        setTimeout(() => this.scrollToFichamento(this.props._id), 0)
      }
    }
  
    render() {
      const {classes, _id, setSaving, saving, addFichamento, deleteFichamento, saveTitle, onSaveEditorState, order, title, loading} = this.props
      const propsWithoutClasses = {...this.props, classes: null}

      return (
        <div key={_id} id={_id} className={classes.fichamento}>
          <Header _id={_id} deleteFichamento={deleteFichamento} title={title} setSaving={setSaving} saving={saving} saveTitle={saveTitle} />
          <TextEditor {...propsWithoutClasses} notebookId={_id} setSaving={setSaving} saving={saving} onSaveEditorState={onSaveEditorState} loading={loading} customClass={classes.notebookEditor}/>
          <AddFichamento addFichamento={addFichamento} order={order || 1} />
        </div>
      )
    }
}

export default withStyles(styles)(ItemFichamento)