import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parse, stringify } from 'query-string';
import { push as pushAction } from 'react-router-redux';
import compose from 'recompose/compose';
import _ from 'lodash';
import { createSelector } from 'reselect';
import queryReducer, { SET_SORT, SET_PAGE, SET_FILTER, SORT_DESC } from '../../reducers/resource/list/queryReducer';
import DefaultPagination from './Pagination';
import { crudGetList as crudGetListAction } from '../../actions/dataActions';
import { changeListParams as changeListParamsAction } from '../../actions/listActions';
import { changePlataformParams as changePlataformParamsAction } from '../../actions/plataformActions'
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';
import FeedbackPopover from '../../components/FeedbackPopover'

// import translate from '../../i18n/translate';

const styles = {
    noResults: {
        padding: 20
    },
    feedback: {
        marginTop: '25px',
        marginBottom: '60px'
    }
};

/**
 * FetchList page component
 *
 * The <FetchList> component renders the list layout (title, buttons, filters, pagination),
 * and fetches the list of records from the REST API.
 * It then delegates the rendering of the list of records to its child component.
 *
 * In Redux terms, <FetchList> is a connected component.
 *
 * Props:
 *   - title
 *   - perPage
 *   - sort
 *   - filter (the permanent filter to apply to the query)
 *   - actions
 *   - filters (a React Element used to display the filter form)
 *   - pagination
 *
 * @example
 *     const PostFilter = (props) => (
 *         <Filter {...props}>
 *             <TextInput label="Search" source="q" alwaysOn />
 *             <TextInput label="Title" source="title" />
 *         </Filter>
 *     );
 *     export const PostList = (props) => (
 *         <FetchList {...props}
 *             title="List of posts"
 *             sort={{ field: 'published_at' }}
 *             filter={{ is_published: true }}
 *             filters={<PostFilter />}
 *         >
 *             <Datagrid>
 *                 <TextField source="id" />
 *                 <TextField source="title" />
 *                 <EditButton />
 *             </Datagrid>
 *         </FetchList>
 *     );
 */
export class FetchList extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { key: 0 };
    }

    componentDidMount() {
        this.updateData();
        this.setFilters(this.props.fixedFilters)
        if (this.props.fixedFilters && this.props.fixedFilters.sort) {
            this.setSort(this.props.fixedFilters.sort)
        }
        if (_.keys(this.props.query).length > 0) {
            this.props.changeListParams(this.props.resource, this.props.query);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.resource !== this.props.resource
            || nextProps.query.sort !== this.props.query.sort
            || !_.isEqual(nextProps.sort, this.props.sort)
            || nextProps.query.order !== this.props.query.order
            || nextProps.query.page !== this.props.query.page
            || nextProps.query.filter !== this.props.query.filter
            || nextProps.refresh > this.props.refresh
            || nextProps.stateRefresh !== this.props.stateRefresh
            || this.props.params !== nextProps.params
            || !_.isEqual(this.props.query, nextProps.query)
            || nextProps.concurso !== this.props.concurso) {
            const query = { ...nextProps.query, ...nextProps.params }
            if (typeof nextProps.query.q === 'string') {
                query.q = nextProps.query.q
            }
            this.updateData(query, nextProps.resource);
        }

        if (nextProps.data !== this.props.data && this.fullRefresh) {
            this.fullRefresh = false;
            this.setState({ key: this.state.key + 1 });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { params, total, isLoading, width, data, resource, stateRefresh, update } = this.props
        if (params === nextProps.params
            && nextProps.resource === resource
            && nextProps.total === total
            && nextProps.isLoading === isLoading
            && nextProps.width === width
            && nextState === this.state
            && nextProps.data === data
            && nextProps.stateRefresh === stateRefresh
            && nextProps.update === update) {
            return false;
        }
        return true;
    }

    getBasePath() {
        return this.props.location.pathname;
    }

    refresh = (event) => {
        event.stopPropagation();
        this.fullRefresh = true;
        this.updateData();
    }

    /**
     * Merge list params from 3 different sources:
     *   - the query string
     *   - the params stored in the state (from previous navigation)
     *   - the props passed to the List component
     */
    getQuery() {
        const query = { ...this.props.query, ...this.props.params }

        if (this.props.query.q) {
            query.q = this.props.query.q
        }

        if (!query.perPage) {
            query.perPage = this.props.perPage;
        }
        if (!query.page) {
            query.page = this.props.page;
        }
        return query;
    }

    updateData = (query, resource) => {
        const params = query || this.getQuery(); // TODO: tratar change filter, url está sempre sobreescrevendo os filtros.
        const { fixedFilters = {}, cancelPrevious, crudGetList } = this.props;
        const { sort, order, page, perPage, filter, ...urlParams } = params;
        const permanentFilter = this.props.filter;
        crudGetList(resource || this.props.resource, { page, perPage }, { field: sort, order }, { ...filter, ...permanentFilter, ...fixedFilters, urlParams }, Boolean(cancelPrevious));
    }

    setSort = sort => this.changeParams({ type: SET_SORT, payload: sort });

    setPage = page => {
        this.trackEvent(page);
        this.changeParams({ type: SET_PAGE, payload: page })
    };

    trackEvent = (page) => {
        const { resource, trackMetric } = this.props;
        let feature = undefined;

        switch (resource) {
            case 'doeContent': feature = 'Discursos Oficiais'; break;
            case 'timelinePost': feature = 'Mural'; break;
            // no default
        }

        Boolean(feature) && trackMetric(`${feature} - Ativação`, { target: 'pagination', trigger: 'click', page });
    }

    setFilters = filters => this.changeParams({ type: SET_FILTER, payload: { ...filters, ...this.props.fixedFilters } });


    showFilter = (filterName, defaultValue) => {
        this.setState({ [filterName]: true });
        if (typeof defaultValue !== 'undefined') {
            this.setFilters({ ...this.props.filterValues, [filterName]: defaultValue });
        }
    }

    hideFilter = (filterName) => {
        this.setState({ [filterName]: false });
        const newFilters = _.keys(this.props.filterValues).reduce((filters, key) => (
            key === filterName ?
                filters :
                ({
                    ...filters,
                    [key]: this.props.filterValues[key],
                })
        ), {});
        this.setFilters(newFilters);
    }

    changeParams(action) {
        const newParams = queryReducer(this.getQuery(), action);
        this.props.changeListParams(this.props.resource, newParams);
        this.props.push({ ...this.props.location, search: `?${stringify({ ...newParams, filter: JSON.stringify(newParams.filter) })}` });
    }

    render() {
        const { filters, feedback, pagination = <DefaultPagination />, resource, data, ids , children, isLoading, noData } = this.props;
        const total = this.props.total ? this.props.total.value ? this.props.total.value : this.props.total: 0
        const { key } = this.state;
        const query = this.getQuery();
        const filterValues = query.filter;
        const basePath = this.getBasePath();
        return (
            <div className="list-page">
                {
                    filters && React.cloneElement(filters, {
                        resource,
                        hideFilter: this.hideFilter,
                        filterValues,
                        displayedFilters: this.state,
                        setFilters: this.setFilters,
                        context: 'form',
                        setSort: this.setSort
                    })
                }
                {

                    isLoading || total  
                        ?
                            <div key={key}>
                                {children && React.cloneElement(children, {
                                    resource,
                                    ids,
                                    data,
                                    currentSort: { field: query.sort, order: query.order },
                                    basePath,
                                    isLoading,
                                    setSort: this.setSort,
                                })}
                                {feedback && <FeedbackPopover style={styles.feedback} />}
                                {pagination && React.cloneElement(pagination, {
                                    total,
                                    page: parseInt(query.page, 10),
                                    perPage: parseInt(query.perPage, 10),
                                    setPage: this.setPage
                                })}
                            </div>
                        : noData ? noData : <p style={styles.noResults}>{'Nenhum resultado encontrado'}</p>
                }
            </div>
        );
    }
}

FetchList.propTypes = {
    // the props you can change
    title: PropTypes.any,
    filter: PropTypes.object,
    filters: PropTypes.element,
    pagination: PropTypes.any,
    actions: PropTypes.element,
    perPage: PropTypes.number.isRequired,
    sort: PropTypes.shape({
        field: PropTypes.string,
        order: PropTypes.string,
    }),
    children: PropTypes.element.isRequired,
    // the props managed by admin-on-rest
    changeListParams: PropTypes.func.isRequired,
    crudGetList: PropTypes.func.isRequired,
    data: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    filterValues: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    ids: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    path: PropTypes.string,
    params: PropTypes.object.isRequired,
    push: PropTypes.func.isRequired,
    query: PropTypes.object.isRequired,
    resource: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    // translate: PropTypes.func.isRequired,
};

FetchList.defaultProps = {
    filter: {},
    filterValues: {},
    perPage: 10,
    page: 1,
    sort: {
        field: 'createdAt',
        order: SORT_DESC
    }
};

const getLocationSearch = props => props.location.search;
const getQuery = createSelector(
    getLocationSearch,
    (locationSearch) => {
        const query = parse(locationSearch);
        if (query.filter && typeof query.filter === 'string') {
            query.filter = JSON.parse(query.filter);
        }
        return query;
    },
);

function mapStateToProps(state, props) {
    const resourceState = state[props.resource];
    const { fixedFilters = {} } = props
    const idList = resourceState.list.ids.map(String)
    const data = _.keys(resourceState.data)
        .filter(_id => idList.includes(_id))
        .reduce((obj, _id) => {
            return {
                ...obj,
                [_id]: resourceState.data[_id]
            };
        }, {});

    return {
        query: getQuery(props),
        params: resourceState.list.params,
        ids: resourceState.list.ids,
        total: resourceState.list.total,
        data,
        isLoading: state.loading > 0,
        filterValues: resourceState.list.params.filter,
        fixedFilters: fixedFilters,
        refresh: resourceState.list.refresh,
        concurso: state.concurso.active
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        crudGetList: (...params) => dispatch(crudGetListAction(...params)),
        changeListParams: (resource, params = {}) => {
            if (params.custom) {
                dispatch(changePlataformParamsAction(resource, { custom: params.custom }));
            }
            dispatch(changeListParamsAction(resource, params));
        },
        push: (...params) => dispatch(pushAction(...params)),
        trackMetric: (name, data) => dispatch(trackMetricAction(name, data))
    }
}

const enhance = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    // translate,
);

export default enhance(FetchList);
