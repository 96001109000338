import {
    SELECTED_COURSE
} from '../actions/courseSelectedActions'


const INITIAL_STATE = {
    data: ''
};

export default (previousState = INITIAL_STATE, { type, data }) => {
    switch (type) {
        case SELECTED_COURSE:
            return { ...previousState, data };
       
        default:
            return previousState;
    }
};