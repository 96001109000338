import styled, { css } from "styled-components";
import { CircularProgress } from "@material-ui/core";

export const Root = styled.div`
    margin-bottom: 24px;
`;

export const FilterContainer = styled.div`
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 16px;
    overflow: hidden;
    height: 40px;
    padding: 0px 16px;

    &:focus-within {
        outline: 1px solid rgb(220, 220, 220);

        &:hover {
            outline: 2px solid rgb(220, 220, 220);
        }
    }

    ${props => props.disabled && css`
        background: #D9D5D4;

        & input {
            opacity: 0.8;
        }
    `}

    input {
        font-family: Inter;
        border: none;
        outline: none;
        height: 100%;
        flex: 1;
        background: transparent;
        color: rgba(0, 0, 0, .6);
        font-weight: 600;

        &::placeholder {
            color: rgba(0, 0, 0, .2);
        }
    }

    svg {
        fill: rgba(0, 0, 0, .4);
    }
`;

export const LoadingIndicator = styled(CircularProgress).attrs(props => ({ size: 16 }))`
    svg {
        color: rgba(0, 0, 0, .4);
    }
`;