 import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { withStyles } from 'material-ui/styles';
import Paper from 'material-ui/Paper';
import moment from 'moment';
import _ from 'lodash';
import { Button, ElementList } from '../../components/common';
import FolderOpen from 'material-ui-icons/FolderOpen';
import { crudCreate as crudCreateAction } from '../../actions/dataActions';
import { payCourseClassPagarme as payCourseClassPagarmeAction } from '../../actions/courseActions';
import PaymentModal from './PaymentModal';
import { PAGARME_ENCRYPTION_KEY } from '../../config';

const styles = theme => ({
  container: {
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    color: theme.palette.text.clear,
    flexDirection: 'column',
    maxWidth: '932px',
    margin: '0 auto 8px auto',
    [theme.breakpoints.down('sm')]: {
      margin: '0 10px 8px 10px'
    }
  },
  card: {
    boxShadow: theme.shadows[25],
    display: 'flex',
    width: '99%',
    justifyContent: 'space-between',
    minHeight: '70px',
    padding: '16px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  buttons: {
    flex: 1,
    textAlign: 'center',
    marginLeft: '20px'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.body3,
    paddingBottom: '8px',
    justifyContent: 'space-between',
    color: theme.palette.text.basic
  },
  dateRow: {
    alignItems: 'center',
    display: 'flex',
    height: '25px'
  },
  date: {
    paddingLeft: '4px',
    ...theme.typography.percent,
    fontSize: '13px'
  },
  content: {
    paddingBottom: '8px',
    fontSize: '15px',
    flex: 10,
    fontFamily: theme.typography.fontFamily,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '80%'
    }
  },
  temaItem: {
    display: 'flex',
    borderLeft: '1px dotted rgba(0,0,0,0.1)',
    marginLeft: '10px'
  },
  number: {
    color: theme.palette.orange,
    width: '35px',
    textAlign: 'right',
    marginRight: '8px'
  },
  text: {
    ...theme.typography.body1,
    color: theme.palette.text.clear,
    width: '100%'
  },
  temasAbordados: {
    ...theme.typography.body3,
    color: theme.palette.text.clear,
    paddingBottom: '4px',
    display: 'flex',
    alignItems: 'center'
  },
  references: {
    marginTop: '16px'
  },
  folderOpen: {
    height: '20px',
    width: '20px',
    color: theme.palette.black.a4,
    marginRight: '8px'
  },
  refazer: {
    color: theme.palette.black.a5,
    fontSize: '11px'
  },
  nota: {
    color: '#fff',
    textTransform: 'none',
    fontSize: '16px',
    lineHeight: '16px',
    marginTop: '-5px'
  },
  disabled: {
    color: 'rgba(0,0,0,0.1)',
    borderColor: 'rgba(0,0,0,0.05)',
    cursor: 'not-allowed'
  },
  description: {
    marginBottom: '16px'
  },
  pre: {
    whiteSpace: 'pre-wrap',
    fontFamily: 'inherit',
    marginTop: 0
  }
});

const ADMIN_ROLE = '5a29412efcf99317fb323d6e';
const TEACHER_ROLE = '5ba26014f12846696c0c3eab';

class ItemAula extends Component {
  state = {
    paymentModal: false
  };

  handleEnrollment = _.once(() => {
    let { _id, course, user } = this.props;

    new window.PagarMeCheckout.Checkout({
      encryption_key: PAGARME_ENCRYPTION_KEY,
      success: data => {
        delete data.installments;
        delete data.payment_method;
        data.type = 'enrollment';
        this.props.payCourseClassPagarme(data, _id);
      }
    }).open({
      amount: Math.round(course.enrollmentValue * 100),
      payment_methods: 'credit_card',
      maxInstallments: 1,
      createToken: 'false',
      customerData: `${!Boolean(user.customer)}`,
      paymentButtonText: 'Matricular',
      uiColor: '#F58220'
    });
  });

  handleSubscribe = _.once(() => {
    let { _id, crudCreate, course, number, title } = this.props;
    let data = { courseClass: _id };

    crudCreate(
      'courseClassUser',
      data,
      '',
      false,
      '',
      true,
      'Inscrição realizada!'
    );
    crudCreate(
      'event',
      {
        name: 'Inscreveu',
        data: {
          Curso: course.name,
          'Número da aula': number,
          'Nome da aula': title,
          course
        }
      },
      '',
      false,
      '',
      false
    );
  });

  handleOpenActivity = () => {
    let { activityUrl } = this.props;
    window.open(activityUrl).focus();
  };

  handlePaymentModal = _.once(() => {
    let { _id, course, user } = this.props;

    new window.PagarMeCheckout.Checkout({
      encryption_key: PAGARME_ENCRYPTION_KEY,
      success: data => {
        delete data.installments;
        delete data.payment_method;
        data.type = 'enrollment';
        this.props.payCourseClassPagarme(data, _id);
      }
    }).open({
      amount: Math.round(course.minimumPayment * 100),
      payment_methods: 'credit_card',
      maxInstallments: 1,
      createToken: 'false',
      customerData: 'false',
      paymentButtonText: 'Matricular',
      uiColor: '#F58220'
    });
  });

  handleDownloadTextBook = () => {
    let { textBook, course, number, title, crudCreate } = this.props;

    if (textBook && textBook.length) {
      window.open(textBook[0].url).focus();
      crudCreate(
        'event',
        {
          name: 'Visualizou apostila',
          data: {
            Curso: course.name,
            'Número da aula': number,
            'Nome da aula': title,
            course
          }
        },
        '',
        false,
        '',
        false
      );
    };
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.refreshState !== this.props.refreshState) {
      this.setState({ refreshState: nextProps.refreshState });
    }
  }

  handleBiblioMaterial = () => {
    const { biblioMaterial } = this.props;
    this.props.pushRouter(`/plano-de-estudos/${biblioMaterial}/bysubject`);
  };

  canSubscribe = classDate => {
    var daysToSubtract =
      classDate.weekday() === 4 || classDate.weekday() === 6 ? 2 : 5;
    return classDate.subtract(daysToSubtract, 'days').isBefore();
  };

  getItemContent() {
    const {
      classes,
      number,
      title,
      liveDate,
      description = '',
      topics = [],
      biblioReferences,
      _id,
      course
    } = this.props;

    return (
      <div className={classes.content}>
        <div className={classes.title}>
          {number === 0 && course.enrollmentValue > 0
            ? title
            : `Aula ${number} - ${title}`}
        </div>
        <div className={classes.list}>
          <div className={classes.description}>
            <pre className={classes.pre}>{description}</pre>
          </div>

          {topics.length > 0 && (
            <div>
              <div className={classes.temasAbordados}>
                <FolderOpen className={classes.folderOpen} />{' '}
                <div>Temas abordados</div>
              </div>
              <ElementList
                data={topics}
                type={classes.listTemas}
                renderRow={({ orderName, name }) => {
                  return (
                    <div className={classes.temaItem} key={orderName}>
                      <div className={classes.number}>{orderName}</div>
                      <div className={classes.text}>{name}</div>
                    </div>
                  );
                }}
              />
            </div>
          )}

          {biblioReferences && (
            <div className={classes.references}>
              <div className={classes.temasAbordados}>
                <div>Referências bibliográficas</div>
              </div>
              <div className={classes.text}>{biblioReferences}</div>
            </div>
          )}
        </div>
      </div>
    );
  }

  getPaymentButton() {
    const { number, _id, amountPaid, hasVideo, course } = this.props;

    if (number === 0) {
      if (!(amountPaid > 0 || course.enrolled)) {
        return moment(course.expiresAt).isAfter() ? (
          <Button buttonFull={true} onClick={this.handleEnrollment}>
            MATRICULAR
          </Button>
        ) : null;
      } else {
        return <Button disabled={true}>MATRICULADO</Button>;
      }
    }
    if (amountPaid === 0) {
      return (
        <Button buttonFull={true} onClick={() => this.handlePaymentModal(_id)}>
          PAGAR
        </Button>
      );
    }
    if (
      amountPaid === undefined &&
      hasVideo &&
      moment(course.expiresAt).isAfter()
    ) {
      if (course.enrollmentValue === 0 && course._id !== '5d3892808e7b51934839b0ca') {
        return (
          <Button onClick={() => this.handlePaymentModal(_id)}>
            LIBERAR ACESSO
          </Button>
        );
      }
      if (course.enrollmentValue === 0 && course._id === '5d3892808e7b51934839b0ca'){
        return <Button disabled={true}>VENDAS ENCERRADAS TEMPORARIAMENTE</Button>;
      }
      return <Button disabled={true}>LIBERAR ACESSO</Button>;
    }

    return null;
  }

  getSubscriptionButton() {
    const { course, amountPaid, number, prevHappened } = this.props;
    const disableSubscribeButton =
      course.pending ||
      (course.enrollmentValue > 0 && !course.enrolled && number !== 0);

    if (amountPaid >= 0) {
      return <Button disabled={true}>INSCRITO</Button>;
    }

    return prevHappened ? (
      <Button disabled={disableSubscribeButton} onClick={this.handleSubscribe}>
        INSCREVER
      </Button>
    ) : (
      <Button disabled={true}>EM BREVE</Button>
    );
  }

  getWatchAgainButton() {
    const { handleModal, video, title, number } = this.props;
    let classData = { title, number };

    return (
      <Button onClick={() => handleModal({ video }, 'video', classData)}>
        ASSISTIR VIDEO
      </Button>
    );
  }

  getTextBookButton() {
    return <Button onClick={this.handleDownloadTextBook}>VER MATERIAL</Button>;
  }

  getBiblioMaterialButton() {
    return (
      <Button onClick={this.handleBiblioMaterial}>VER PLANO DE ESTUDOS</Button>
    );
  }

  getTestButton() {
    const { classes, handleModal, course, test, answer, _id, user } = this.props;

    return Boolean(course.hasEssayQuestions) ? (
      <div>
      {!answer &&  <Button
          buttonFull={answer}
          onClick={() => handleModal(test, 'simulado')}
        >
          FAZER TAREFA
        </Button>  }
        {answer && user.role === ADMIN_ROLE && <Button
            buttonFull={answer}
            onClick={() => handleModal(test, 'simulado')}
          >
            REFAZER TAREFA
          </Button>  }
        {answer && (
          <Button onClick={() => handleModal(_id, 'redacao-resposta')}>
            VER RESPOSTAS
          </Button>
        )}
      </div>
    ) : (
      <div>
        <Button
          buttonFull={answer}
          onClick={() => handleModal(test, 'simulado')}
        >
          {answer ? (
            <div className={classes.nota}>
              {`${answer.score} pts`}
              <div className={classes.refazer}>REFAZER</div>
            </div>
          ) : (
            'FAZER SIMULADO'
          )}
        </Button>
        <Button
          disabled={!answer}
          classes={{ disabled: classes.disabled }}
          onClick={() => handleModal(test, 'gabarito')}
        >
          GABARITO
        </Button>
      </div>
    );
  }

  canShow(featureBeforePayment) {
    const { amountPaid, hasPayment } = this.props;

    return (
      amountPaid > 0 ||
      (featureBeforePayment && amountPaid === 0) ||
      !hasPayment
    );
  }

  getItemButtons() {
    const {
      classes,
      liveDate,
      video,
      answer,
      textBook,
      test,
      biblioMaterial,
      course,
      handleModal,
      questions = [],
      user,
      _id,
      hasPayment,
      title,
      number
    } = this.props;
    const { paymentModal } = this.state;
    const classDate = moment(liveDate);

    return (
      <div className={classes.buttons}>
        {hasPayment &&
          classDate &&
          classDate.isBefore() &&
          this.getPaymentButton()}

        {classDate && classDate.isAfter() && this.getSubscriptionButton()}

        <div>
          {video && answer && this.getWatchAgainButton()}
          {test && this.getTestButton()}
          {textBook && answer && this.getTextBookButton()}
          {biblioMaterial && this.getBiblioMaterialButton()}
        </div>

        {questions.length > 0 &&
          (user.role === ADMIN_ROLE || user.role === TEACHER_ROLE) &&
          course.hasEssayQuestions && (
            <Button onClick={() => handleModal(_id, 'redacao-correcao')}>
              CORRIGIR REDAÇÕES
            </Button>
          )}

        <PaymentModal
          handleOpen={this.handlePaymentModal}
          open={paymentModal}
          courseClassData={{ course, title, number }}
          minimumPayment={course.minimumPayment}
        />
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <Paper className={classes.card}>
          {this.getItemContent()}
          {this.getItemButtons()}
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    refreshState: state.plataform.refreshState,
    user: state.user.profile
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    pushRouter: path => dispatch(push(path)),
    crudCreate: (...params) => {
      dispatch(crudCreateAction(...params));
    },
    payCourseClassPagarme: (...params) => {
      dispatch(payCourseClassPagarmeAction(...params));
    }
  };
};

const enhance = compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(ItemAula);
