import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { push } from 'react-router-redux'

import {
    getMyPurchases as getMyPurchasesAction
} from '../../actions/coursePackActions';

import { withStyles } from 'material-ui/styles';
import Icon from 'material-ui/Icon';
import Chip from 'material-ui/Chip';
import Tooltip from 'material-ui/Tooltip';
import Button from 'material-ui/Button';

// #### ICONS ####
import Check_Off from '../../assets/img/icon/highlight-off.svg';

const styles = () => ({
    container: {
        paddingLeft: '104px',
        paddingTop: '80px',
        fontFamily: 'Proxima Nova',
    },
    title: {
        color: 'rgba(0, 0, 0, 0.7)',
        fontSize: '18px',
        paddingBottom: '32px'
    },
    table: {
        borderCollapse: 'collapse',
        marginLeft: '48px',
        marginBottom: '48px'
    },
    th: {
        border: '1px solid',
        borderColor: 'rgb(217, 217, 217)',
        height: '40px',
        width: '130px',
        color: 'rgba(0, 0, 0, 0.7)',
        fontWeight: 'bold'
    },
    tdCourse: {
        textAlign: 'left',
        height: '40px',
        border: '1px solid',
        borderColor: 'rgb(217, 217, 217)',
        fontSize: '16px',
        fontWeight: '500',
        padding: '10px 24px',
        color: 'rgba(0, 0, 0, 0.8)',
        width: '250px',
    },
    td: {
        border: '1px solid',
        borderColor: 'rgb(217, 217, 217)',
        textAlign: 'center',
        height: '40px'
    },
    tableHead: {
        height: '40px',
    },
    checkedIcon: {
        fontSize: '15px !important',
        color: '#31c98e',
        width: 'auto'
    },
    checkedOffIcon: {
        fontSize: '15px !important',
        color: 'rgba(0, 0, 0, 0.4)',
        width: '15px',
        opacity: '0.4',
    },
    thChip: {
        textAlign: 'left'
    },
    chip: {
        color: 'white',
        borderRadius: '4px',
        fontSize: '14px',
        fontWeight: '500',
        backgroundColor: '#0091ff',
        height: '23px',
        marginBottom: '8px'
    },
    lightTooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        fontSize: '13px',
    },
    info: {
        cursor: 'help',
        color: 'rgba(0, 0, 0, 0.5)',
        fontSize: '13px',
        fontWeight: '500',
    },
    emptyContainer: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Proxima Nova',
        paddingTop: '56px',
        alignItems: 'center'
    },
    emptyTitle: {
        color: 'rgba(0, 0, 0, 0.7)',
        fontSize: '18px',
        fontWeight: 'bold',
    },
    emptyDescription: {
        color: 'rgba(0, 0, 0, 0.75)',
        fontSize: '15px',
        paddingTop: '4px',
        paddingBottom: '32px',
    },
    emptyButton: {
        borderRadius: '20px',
        border: '2px solid rgb(252, 121, 84)',
        height: '36px',
        width: '212px',
        textTransform: 'none',
        color: 'rgb(252, 121, 84)',
        fontSize: '14px',
        fontWeight: '600',
        '&:hover': {
            color: 'white',
            backgroundColor: 'rgb(252, 121, 84)',
        }
    }
});

class CoursePackSummary extends Component {
    componentWillMount() {
        this.props.getMyPurchases();
    };

    renderTable(data, packageName, category) {
        const { classes, loadingRequest } = this.props;

        if (!data) return (<div></div>);

        const enrollments = Object
            .values(data)
            .filter(items => items.category === category);

        if (!enrollments.length) return (<div></div>);

        return (
            !loadingRequest &&
            <div>
                <table className={classes.table}>
                    <thead>
                        <tr>
                            <th className={classes.thChip}>
                                <Chip
                                    className={classes.chip}
                                    label={packageName}
                                />
                            </th>
                            <th className={classes.th}>Módulo 1</th>
                            <th className={classes.th}>Módulo 2</th>
                            <th className={classes.th}>Módulo 3</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            enrollments.map(course => {
                                course.modules = course.modules.sort();

                                
                                if (category === 'Intensivo') {
                                    return (
                                        <tr key={course.id}>
                                            <td className={classes.tdCourse}>
                                                {
                                                    category === 'Aprofundamento'
                                                        ? course.name.replace(`${category} em`, '').trim()
                                                        : course.name.replace(`${category} de`, '').trim()
                                                }
                                            </td>
                                            {this.renderCheck(course.modules, 1)}
                                            <td className={classes.td}>
                                                <Tooltip
                                                    title='Este curso não possui esse módulo'
                                                    placement='bottom'
                                                    classes={{ tooltip: classes.lightTooltip }}
                                                    >
                                                    <span className={classes.info}>---</span>
                                                </Tooltip>
                                            </td>
                                            <td className={classes.td}>
                                                <Tooltip
                                                    title='Este curso não possui esse módulo'
                                                    placement='bottom'
                                                    classes={{ tooltip: classes.lightTooltip }}
                                                    >
                                                    <span className={classes.info}>---</span>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    );
                                }

                                /**
                                 * Curso 5db9b524d2173e8363f4772e possui apenas dois módulos
                                 */

                                if (course.id === '5db9b524d2173e8363f4772e') {
                                    return (
                                        <tr key={course.id}>
                                            <td className={classes.tdCourse}>
                                                {course.name.replace('Extensivo de ', '').trim()}
                                            </td>
                                            {this.renderCheck(course.modules, 1)}
                                            {this.renderCheck(course.modules, 2)}
                                            <td className={classes.td}>
                                                <Tooltip
                                                    title='Este curso não possui esse módulo'
                                                    placement='bottom'
                                                    classes={{ tooltip: classes.lightTooltip }}
                                                >
                                                    <span className={classes.info}>---</span>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    );
                                }

                                return (
                                    <tr key={course.id}>
                                        <td className={classes.tdCourse}>
                                            {
                                                category === 'Aprofundamento'
                                                    ? course.name.replace(`${category} em`, '').trim()
                                                    : course.name.replace(`${category} de`, '').trim()
                                            }
                                        </td>
                                        {this.renderCheck(course.modules, 1)}
                                        {this.renderCheck(course.modules, 2)}
                                        {this.renderCheck(course.modules, 3)}
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
        );
    }

    renderCheck(modules = [], module) {
        const { classes } = this.props;

        const enrolled = modules.filter(item => item === module);

        if (enrolled.length) {
            return (
                <td className={classes.td}>
                    <Icon className={classes.checkedIcon}>check_circle_outline</Icon>
                </td>
            );
        };

        return (
            <td className={classes.td}>
                <img className={classes.checkedOffIcon} src={Check_Off} />
            </td>
        );
    };

    renderTableByCategory(category) {
        const {
            classes,
            loadingRequest,
            myPurchases,
            pushRouter,
        } = this.props;

        const enrollmentLenght = Object
            .values(myPurchases.enrollments)
            .filter(items => items.category === category)
            .length;
        const enrollmentClassesLength = Object
            .values(myPurchases.enrollmentsAndClasses)
            .filter(items => items.category === category)
            .length;
        const fullPackageLength = Object
            .values(myPurchases.fullPackage)
            .filter(items => items.category === category)
            .length;

        if (enrollmentLenght || enrollmentClassesLength || fullPackageLength) {
            return (
                !loadingRequest &&
                <div>
                    <div className={classes.title}>CACD 2020 - {category}</div>
                    {this.renderTable(myPurchases.enrollments, 'Matrículas avulsas', category)}
                    {this.renderTable(myPurchases.enrollmentsAndClasses, 'Pacote Aulas + Matrículas', category)}
                    {this.renderTable(myPurchases.fullPackage, 'Pacote completo', category)}
                </div>
            );
        }
    }

    render() {
        const {
            classes,
            myPurchases,
            loadingRequest,
            failureRequest,
            pushRouter
        } = this.props;

        if (loadingRequest || failureRequest) {
            return <div></div>;
        }

        const enrollmentLenght = Object.values(myPurchases.enrollments).length;
        const enrollmentClassesLength = Object.values(myPurchases.enrollmentsAndClasses).length;
        const fullPackageLength = Object.values(myPurchases.fullPackage).length;

        if (enrollmentLenght || enrollmentClassesLength || fullPackageLength) {
            return (
                <div className={classes.container}>
                    {this.renderTableByCategory('Intensivo')}
                    {this.renderTableByCategory('Aprofundamento')}
                    {this.renderTableByCategory('Extensivo')}
                </div>
            );
        }

        return (
            <div className={classes.emptyContainer}>
                <div className={classes.emptyTitle}>Ops! Ainda não registramos nenhuma compra</div>
                <div className={classes.emptyDescription}>Veja abaixo quais os pacotes de cursos disponíveis no momento</div>
                <div>
                    <Button
                        onClick={() => pushRouter('/checkout-select')}
                        className={classes.emptyButton}
                    >
                        Ver pacotes de cursos
                    </Button>
                </div>
            </div>
        );
    };
};

const mapStateToProps = (state) => {
    return {
        myPurchases: state.coursePackage.myPurchases.data,
        loadingRequest: state.coursePackage.myPurchases.loading,
        failureRequest: state.coursePackage.myPurchases.failure,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMyPurchases: () => {
            dispatch(getMyPurchasesAction())
        },
        pushRouter: (path) => dispatch(push(path)),
    }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(CoursePackSummary);