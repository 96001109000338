import  React, {Component} from 'react'
import { withStyles } from 'material-ui/styles';
import classNames from 'classnames'

const styles = theme => ({
    container: {
        width: '100%',
        marginBottom: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoration: 'none',
        color: theme.palette.text.clear,
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },
    card: {
        ...theme.typography.body1,
        borderRadius: '3px',
        // backgroundColor: '#D3C5B5',
        color: '#6E5B4B',
        fontFamily: theme.typography.fontFamily2,
        display: 'flex',
        width: '99%',
        justifyContent: 'space-between',
        minHeight: '70px',
    },
    content: {
        flex: 3,
        fontSize: '15px',
        fontFamily: theme.typography.fontFamily,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '80%',
        },
        padding: '16px',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    title: {
        textAlign: 'center',
        ...theme.typography.headline,
        fontSize: '32px',
        paddingBottom: '0px',
        color: '#FFF',
        lineHeight: '1em',
    },
    marginTitle: {
        marginRight: '20px',
        [theme.breakpoints.up('sm')]: {
            marginRight: '160px',
        },
    }
})


class Title extends Component {

    render() {
        const {classes, title, imageClass} = this.props
        return (
                <div className={classes.container}>
                    <div className={`${classes.card} image-background`} preserveAspectRatio="none">
                        <div className={imageClass} ></div>
                        <div className={classes.content}>
                            <div className={classNames(classes.title, imageClass ? classes.marginTitle : '')}>
                               {title}
                            </div>
                        </div>
                    </div>
            </div> 
        )
    }
}
export default withStyles(styles)(Title)