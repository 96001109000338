import  React from 'react'
import { withStyles } from 'material-ui/styles';
import Bookmark from 'material-ui-icons/BookmarkBorder'

const styles = theme => ({
    container: {
      width: '100%',
      marginBottom: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
      color: theme.palette.text.clear,
      flexDirection: 'column',
      paddingTop: '40px'
    },
    title: {
        textAlign: 'center',
        ...theme.typography.headline,
        paddingBottom: '8px',
        color: '#F06D1A'
    },
    text: {
        ...theme.typography.body2,
        textAlign: 'center',
        maxWidth: '400px'
    }, 
    icon: {
        marginTop: '18px'
    },
    bookmark: {
        color: theme.palette.orange
    }
  })

const EmptyList = ({classes}) => {
    return (
        <div className={classes.container}>
            <div className={classes.title}>
                Você ainda não tem itens para ler depois
            </div>
            <div className={classes.text}>
                Sempre que você estiver lendo os clippings com pressa e se deparar com algum texto que valha a pena dedicar atenção maior, você pode marca-lo para <spam style={{fontWeight: 600}}>LER DEPOIS</spam> e ter acesso fácil ao texto no futuro.
            </div>
            <div className={classes.icon}>
                <Bookmark className={classes.bookmark} />
            </div>
        </div>
    )
}

export default withStyles(styles)(EmptyList)