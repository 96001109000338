import React, { Component } from 'react';
import _ from 'lodash'
import { Link } from 'react-router';
// import ExecutionEnvironment from 'exenv'
import { connect } from 'react-redux';
// import {capitalize} from 'inflection'
import CourseSelect from './CourseSelect'
import Notifications from '../Notifications'
import UserMenu from './UserMenu'
import HamburguerMenu from './HamburguerMenu'
import MainNav from './MainNav'
// import Welcome from './Welcome'
// import {showProfile} from '../../config'
import {subscribeNotification as subscribeNotificationAction} from '../../actions/notificationActions'
import { crudGetList } from '../../actions/dataActions'
import { SORT_ASC } from '../../reducers/resource/list/queryReducer'
import { setConcurso as setConcursoAction } from '../../actions/concursoActions'

class Header extends Component {
  constructor(props) {
      super(props)
      this.state = {
          sticky: false
      }
  } 

//   handleScroll = () => {
//     if(window.innerWidth >= 1190) {
//         // const { pathname } = this.props.location
//         const headerHeight = 120 // _.includes(showProfile, pathname) ? 350 : 240
//         if(window.pageYOffset > headerHeight  && this.state.sticky === false) {
//             this.setState({sticky: true})
//         } else if (window.pageYOffset < 110 && this.state.sticky === true) {
//             this.setState({sticky: false})
//         }
//     }
//   }

  componentDidMount() {
    // if (ExecutionEnvironment.canUseDOM) {
    //     document.addEventListener('scroll', this.handleScroll);
    // }
     this.props.loadConcursos()
  }

  componentWillReceiveProps(nextProps) {
    if((this.props.profile && this.props.profile._id) !== (nextProps.profile && nextProps.profile._id)) {
        this.props.subscribeNotification(nextProps.profile && nextProps.profile._id)
    }
  }
  
  handleSetConcurso = (concursoUid) => {
    const {setConcurso, concurso: {data: concursos}} = this.props
    const concurso = _.find(concursos, {_id: concursoUid})
    setConcurso(concurso)
  }

  render() {
    const { location, profile, name, label, parentUrl, parentLabel, notificaiton, router, concurso } = this.props
    // const { pathname } = location
    const { sticky } = this.state
    // const displayWelcome = _.includes(showProfile, pathname)

    return (
       <header className="header">
            <div className="container">
                <div className={`main-nav--wrapper ${sticky ? 'is-sticky slideDown' : ''}`}>
                    <div className="top-bar">
                        <div className="container">
                             <Link to="/mural" className="logo">Clipping CACD</Link>

                            <CourseSelect concurso={concurso} setConcurso={this.handleSetConcurso}/>

                            <Notifications list={notificaiton} router={router} /> 

                            <UserMenu profile={profile} />

                            <HamburguerMenu />  

                        </div>
                    </div>

                    <MainNav location={location} />
                </div>

                {/* {displayWelcome && <Welcome profile={profile} />} */}
                <div className="page-title-container">
                    <div className="page-title">
                        <div className="page-title--title">
                            <div>
                                {parentUrl ? <Link to={parentUrl}>{parentLabel} <label style={{color: 'white'}}> > </label></Link> : ''}  
                                <label style={parentUrl ? {color: 'white'} : {}} >{label || name}</label>
                            </div>
                        </div>

                        {/*<Search />*/}
                    </div> 
                </div>
            </div>
        </header>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
    return {
        profile: state.user.profile,
        notificaiton: state.notification,
        concurso: state.concurso
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        subscribeNotification: (userUid) => subscribeNotificationAction(dispatch, userUid),
        loadConcursos: () => dispatch(crudGetList('concurso', {page: 1, perPage: 99}, {field: 'concursoId', order: SORT_ASC}, {}, false)),
        setConcurso: (concurso) => dispatch(setConcursoAction(concurso))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
