import React, { Component } from 'react'
import { withStyles } from 'material-ui/styles';
import Paper from 'material-ui/Paper';
import ArrowDown from 'material-ui-icons/ArrowDropDown'
import ArrowUp from 'material-ui-icons/ArrowDropUp'
import classNames from 'classnames';
import ContentList from './ContentList'

const styles = theme => ({
  container: {
    width: '100%',
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    color: theme.palette.text.secondary,
    flexDirection: 'column'
  },
  input: {
    width: '90%',
  },
  card: {
    ...theme.transitions.card,
    flexDirection: 'column',
    minHeight: '70px',
    paddingBottom: '0px',
    justifyContent: 'space-between',
    '&:hover $icon': {
      color: theme.palette.orange
    },
    '&$active': {
      boxShadow: theme.shadows[26],
      width: '100%'
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.title,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1em'
    }
  },
  text: {
    paddingLeft: '8px'
  },
  percent: {
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.subheading,
    color: '#999',
    padding: '8px',
    [theme.breakpoints.up('sm')]: {
      paddingRight: '24px'
    }
  },
  icon: {
    width: '21px',
    height: '21px',
    color: theme.palette.text.clearGrey,
    '&$active': {
      color: theme.palette.orange
    }
  },
  percentBarContainer: {
    width: '100%',
    height: '2px',
    '&$active': {
      backgroundColor: theme.palette.clearGrey,
    }
  },
  percentBar: {
    backgroundColor: theme.palette.orange,
    height: '2px',
  },
  row: {
    padding: '8px 16px 8px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    cursor: 'pointer',
    height: '80px',
  },
  active: {
    '& $row': {
      borderBottom: '1px solid rgba(151, 151, 151, 0.2)'
    }
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
})

class CardItem extends Component {
  render() {
    const { classes, ...other } = this.props;
    const { title, active, _id, handleOpenClose } = other;

    return (
      <div className={classNames(classes.container, active ? classes.active : '')}>
        <Paper className={classNames(classes.card, active ? classes.active : '')}  >
          <div className={classes.row} onClick={() => handleOpenClose(_id)}>
            <div className={classes.title}>
              <div className={classes.text}>{title}</div>
            </div>
            <div className={classes.right}>
              {active ? <ArrowUp className={classNames(classes.icon, classes.active)} /> : <ArrowDown className={classes.icon}/>}
            </div>
          </div>          
          {active ? <ContentList {...other}/> : null}
        </Paper>
        
      </div>
    )
  }
}

export default withStyles(styles)(CardItem)
