import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from 'material-ui/styles';
import ButtonBase from 'material-ui/ButtonBase'
import classNames from 'classnames';

const styles = theme => ({
  root: {
    ...theme.typography.button,
    border: '1px solid #F58220',
    color: theme.palette.actionOrange,
    padding: '4px 8px'
  },
})

class ButtonAction extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    customClasses: PropTypes.object
  }

  static defaultProps = {
    disabled: false,
    customClasses: {}
  }

  render() {
    const {onClick, children, classes, customClasses, disabled} = this.props
    
    return (
      <ButtonBase 
        onClick={onClick}
        disabled={disabled}
        classes={{root: classNames(classes.root, customClasses.root), disabled: classNames(classes.disable, customClasses.disable)}} 
        children={children} />
    )
  }
}


export default withStyles(styles)(ButtonAction)
