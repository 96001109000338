import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { ChevronRight, LockOutlined } from "@material-ui/icons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  position: relative;
`;

const Text = styled.p`
  max-width: 70%;
  white-space: pre-line;
  height: fit-content;
  color: rgb(255, 255, 255);
  font-size: 15px;
  font-family: "Proxima Nova";
  font-weight: 500;
  text-align: center;
  letter-spacing: 0px;
  line-height: 20px;
  margin: 0 auto;

  @media (max-width: 1000px) {
    font-size: 13px;
  }
`;

const Button = styled.button`
  background: linear-gradient(
    -225deg,
    rgb(253, 87, 40) 0%,
    rgb(255, 128, 0) 100%
  );
  border-radius: 4px;
  box-shadow: 0px 25px 11px -20px rgba(0, 0, 0, 0.2);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: 600;
  height: 48px;
  width: 336px;
  color: white;
  text-align: center;
  text-transform: uppercase;
  margin: 24px auto 0px auto;
  border: none;
  position: relative;
`;

const DisabledButton = styled.div`
  background: rgb(187, 187, 187);
  border-radius: 4px;
  box-shadow: 0px 25px 11px -20px rgba(0, 0, 0, 0.2);
  height: 48px;
  width: 336px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px auto 0px auto;
`;

const DisabledText = styled.span`
  color: rgba(0, 0, 0, 0.35);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: bold;
  height: 16px;
  letter-spacing: 0px;
  text-align: center;
  width: fit-content;
`;

const DisabledTextSecondary = styled.span`
  color: rgba(0, 0, 0, 0.35);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: normal;
  height: 16px;
  letter-spacing: 0px;
  text-align: center;
  width: fit-content;
`;

const Footer = styled.p`
  margin: 0px;
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  height: 16px;
  width: fit-content;
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-family: "Proxima Nova";
  font-weight: 500;
  text-align: center;
  letter-spacing: 0px;
  line-height: 16px;
  @media (max-width: 1000px) {
    font-size: 11px;
    height: 14px;
    line-height: 14px;
    bottom: 16px;
  }
`;

function VideoTextWithButton({
  text,
  buttonText,
  footer = "",
  icon,
  action,
  responsive,
}) {
  const pendingPayments = useSelector(
    (state) => state.paymentGroup.pendingPayments
  );
  const pacotes_modulo = useSelector(
    (state) => state.courseGroup.currentModule.data.pacotes_modulo
  );
  const pacotes_curso = useSelector(
    (state) => state.courseGroup.currentCourse.data.pacotes_curso
  );

  const checkPendingPayment = () => {
    const pendingMod = pacotes_modulo.find((pacote) =>
      pendingPayments.pacotes.includes(pacote.id)
    );

    const pendingCourse = pacotes_curso.find((pacote) =>
      pendingPayments.pacotes.includes(pacote.id)
    );

    return pendingMod || pendingCourse;
  };

  return (
    <Container>
      {icon && (
        <LockOutlined
          style={{
            alignSelf: "center",
            color: "#fff",
            width: "24px",
            height: "24px",
            marginBottom: responsive ? "8px" : "18px",
          }}
        />
      )}
      <Text>
        <span
          style={{
            background: "rgb(24, 24, 24)",
            boxShadow:
              "6px 0 0 2px rgb(24, 24, 24), -6px 0 0 2px rgb(24, 24, 24)",
            lineHeight: responsive ? "20px" : "24px",
          }}
        >
          {text}
        </span>
      </Text>
      {!responsive && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {checkPendingPayment() ? (
            <DisabledButton>
              <DisabledText>AGUARDANDO PAGAMENTO</DisabledText>
              <DisabledTextSecondary>
                O boleto pode demorar até 3 dias úteis.
              </DisabledTextSecondary>
            </DisabledButton>
          ) : (
            <Button onClick={() => action()}>
              {buttonText}
              <ChevronRight
                style={{
                  color: "#fff",
                  width: "24px",
                  height: "24px",
                  position: "absolute",
                  transform: "translateY(-50%)",
                  right: "16px",
                  top: "50%",
                }}
              />
            </Button>
          )}
        </div>
      )}
      {footer && <Footer>{footer}</Footer>}
    </Container>
  );
}

export default VideoTextWithButton;
