import React, { Component } from 'react'
import { connect } from 'react-redux';
import { push } from 'react-router-redux'
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import { ElementList, Title } from '../../components/common'
import Grid from 'material-ui/Grid';
import { FetchList } from '../../components'
import Caderno from './Caderno'
import ModalFichamentos from './ModalFichamentos'
import { crudDelete as crudDeleteAction } from '../../actions/dataActions'
import swal from 'sweetalert'
import EmptyList from './EmptyList'
import FullButton from '../../components/common/FullButton';
import ModalAddCaderno from './ModalAddCaderno'
import ModalEditCaderno from './ModalEditCaderno'
import { SORT_ASC } from '../../reducers/resource/list/queryReducer'
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';

const resource = 'notebook'
const fixedFilters = {
  // type: 'standard', // Isso faz mostrar todos os cadernos do usuário no menu Meus Fichamentos
  sort: {
    field: 'normalized',
    order: SORT_ASC 
  },
  // limit: 20
}

const styles = theme => ({
  content: {
    paddingTop: '8px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '32px'
    }
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between'
  },
  contentMain: {
    padding: '40px 16px 16px 16px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px'
    }
  },
  contentSidebar: {
    display: 'none',
    padding: '8px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px 0 0 0',
      marginTop: '150px',
      marginLeft: '0',
      marginRight: 'auto',
      display: 'block',
      order: '1'
    }
  },
  root: {
    flexGrow: 1,
    padding: '8px'
  },
});


class ListaLeitura extends Component {
  constructor(props) {
    super(props)

    const { _id, fichamentoId } = props.routeParams

    this.state = {
      modal: _id ? { _id, fichamentoId } : false,
      addCaderno: false,
      editCaderno: false
    }
  }

  handleModal = (_id) => {
    this.setState({ modal: _id });
    this.props.pushRouter(`/cadernos${_id ? '/' + _id : ''}`);
  }

  handleAddCaderno = (open) => {
    if (open) {
      this.props.trackMetric('Caderno - Intenção de uso', { target: 'notebook', trigger: 'click', action: 'create' });
    }

    this.setState({ addCaderno: open });
  }

  handleEditCaderno = (open) => {
    this.setState({ editCaderno: open });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.refreshState !== this.props.refreshState) {
      window.scrollTo(0, 0);
    }

    const { _id, fichamentoId } = nextProps.routeParams

    if (this.props.routeParams._id !== _id || this.props.routeParams.fichamentoId !== fichamentoId) {
      this.setState({ modal: _id ? { _id, fichamentoId } : false });
    }
  }

  render() {

    const { classes, deleteCaderno } = this.props;
    const { modal, addCaderno, editCaderno } = this.state;

    return (
      <div>
        <section className={classes.content}>
          <Grid container className={classes.root} >
            <Grid item xs={12} md={12} className={classes.contentMain}>
              <FullButton onClick={() => this.handleAddCaderno(true)}> + CRIAR NOVO CADERNO </FullButton>
              <FetchList {...this.props}
                stateRefresh={modal}
                fixedFilters={fixedFilters}
                noData={<EmptyList />}
                resource={resource} >
                <ElementList
                  renderRow={(props) => <Caderno {...props} openModal={this.handleModal} deleteCaderno={deleteCaderno} openEdit={this.handleEditCaderno} />}
                />
              </FetchList>
            </Grid>
          </Grid>
        </section>
        <ModalFichamentos handleOpen={this.handleModal} open={modal} />
        <ModalAddCaderno handleOpen={this.handleAddCaderno} open={addCaderno} />
        <ModalEditCaderno handleOpen={this.handleEditCaderno} open={editCaderno} />
      </div>
    )
  }
}

ListaLeitura.propTypes = {
  classes: PropTypes.object.isRequired,
  pushRouter: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => {
  return {
    pushRouter: (path) => dispatch(push(path)),
    deleteCaderno: (_id) => {
      swal({
        title: "Deseja remover este caderno?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      },
      )
        .then((willDelete) => {
          if (willDelete) {
            dispatch(trackMetricAction('Caderno - Ativação', { target: 'notebook', trigger: 'click', resource: _id, action: 'delete' }));
            dispatch(crudDeleteAction(resource, _id, '', false, false, false))
            swal("O caderno foi removido com sucesso!", {
              icon: "success",
            });
          } else {
            swal.close()
          }
        });
    },
    trackMetric: (name, data) => dispatch(trackMetricAction(name, data))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    refreshState: state.plataform.refreshState
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ListaLeitura);
