import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import compose from 'recompose/compose';
import { withStyles } from 'material-ui/styles';
import Dialog from 'material-ui/Dialog';
import CloseIcon from 'material-ui-icons/Close';
import { ElementList } from '../../components/common';
import { crudGetOne as crudGetOneAction } from '../../actions/dataActions';
import moment from 'moment';
import Button from '../../components/Elements/Button';
import { crudCreate as crudCreateAction } from '../../actions/dataActions';
import Questao from './Questao';
import MultipleChoice from './MultipleChoice';
import RightWrong from './RightWrong';
import Essay from './Essay';
import map from 'lodash/map';
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';

const styles = (theme) => ({
  header: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.black.a6,
    fontFamily: theme.typography.fontFamily,
    textAlign: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.black.a1,
    height: '250px',
    paddingBottom: '54px',
  },
  screen: {
    fontSize: '31px',
    letterSpacing: '-1.5px',
    margin: 0,
  },
  date: {
    letterSpacing: '2px',
    fontSize: '15px',
    marginTop: '-3px',
  },
  content: {
    height: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '-56px 31px 31px 31px',
    position: 'relative',
  },
  dialog: {
    overflow: 'auto',
    alignItems: 'baseline',
    paddingTop: '24px',
    paddingBottom: '24px',
  },
  container: {
    maxHeight: 'none',
    margin: 0,
    maxWidth: '900px',
    width: '95%',
    overflowX: 'hidden',
    backgroundColor: '#F2EEED',
  },
  close: {
    position: 'absolute',
    right: '24px',
    top: '24px',
    width: '28px',
    height: '28px',
    color: '#999',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.orange,
    },
  },
  footer: {
    marginBottom: '55px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  qtdQuestoes: {
    ...theme.typography.text,
    fontSize: '15px',
    marginBottom: '8px',
  },
  button: {
    width: '300px',
    [theme.breakpoints.down('xs')]: {
      width: '240px',
    },
  },
});

class ModalSimulado extends Component {
  state = { answers: {}, loading: false };

  handleRequestClose = () => {
    if (this.props.from === 'plano-de-estudos') {
      let split_path = window.location.pathname.split('/');
      split_path.splice(-1, 1);
      const path = split_path.join('/');
      this.props.pushRouter(path);
    }

    if (!!this.props.closeSimuladoModal) {
      this.props.closeSimuladoModal('simulado');
    } else {
      this.props.handleOpen(false);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && nextProps.open !== this.props.open) {
      const idSimulado = nextProps.open;
      if (this.props.openModal !== undefined) {
        this.props.openModal('simulado', idSimulado);
      }
      this.setState({ answers: {}, loading: false });
      this.props.crudGetOne(nextProps.open);
    }

    if (this.state.loading && this.props.saving && !nextProps.saving) {
      this.setState({ loading: false });
      if (!!this.props.closeSimuladoModal) {
        const idGabarito = nextProps.open;
        this.props.closeSimuladoModal('simulado');
        this.props.openGabarito(idGabarito);
      }
    }
  }

  handleChangeAnswer = (question, item, answer) => {
    const { test = {}, trackMetric } = this.props;
    trackMetric('Simulados - Ativação', {
      target: 'alternative',
      trigger: 'click',
      test: test._id,
      type: test.type,
      parentResource: question,
      resource: item,
    });
    this.setState({
      ...this.state,
      answers: { ...this.state.answers, [item]: { question, item, answer } },
    });
  };

  handleSave = () => {
    this.setState({ loading: true });
    const { crudCreate, test = {}, bibliografia, trackMetric } = this.props;
    const answers = map(this.state.answers);
    var shouldRedirect = typeof answers[0].answer !== 'object';
    const redirectUrl = bibliografia
      ? `/plano-de-estudos/${bibliografia}/gabarito/${test._id}`
      : `/simulados/${test._id}/gabarito`;
    crudCreate(
      `testAnswer/response/${test._id}`,
      { answers },
      redirectUrl,
      shouldRedirect,
      '',
      true,
      'Simulado enviado com sucesso!',
      true
    );
    trackMetric('Simulados - Ativação', {
      target: 'test',
      trigger: 'click',
      type: test.type,
      resource: test._id,
      action: 'create',
    });
    !shouldRedirect && this.handleRequestClose();
  };

  render() {
    const { open, classes, test = {}, name } = this.props;
    const { createdAt, questions = [] } = test;
    const testDate = moment(createdAt, 'YYYY-MM-DD');
    const { answers, loading } = this.state;

    let totalItems = 0;
    map(questions, ({ items }) =>
      items ? map(items, (item) => totalItems++) : ''
    );
    let totalAnswer = 0;
    map(answers, (answers) => totalAnswer++);

    var number = 0;

    questions &&
      questions.forEach((question) => {
        question.items.forEach((item) => {
          item.sequenceKey = ++number;
        });
      });

    return (
      <Dialog
        classes={{ root: classes.dialog, paper: classes.container }}
        open={Boolean(this.props.open)}
        onRequestClose={this.handleRequestClose}
      >
        <div className={classes.header}>
          <h3 className={classes.screen}>
            {open ? name || 'Simulado' : 'Carregando...'}
          </h3>
          <h3 className={classes.screen}>{this.props.type}</h3>

          {test.type === 'weekly' && (
            <div className={classes.date}>
              {testDate &&
                !(questions.length == 1 && questions[0].type === 'essay') &&
                testDate.format('DD/MM/YYYY')}
            </div>
          )}

          <CloseIcon
            className={classes.close}
            onClick={this.handleRequestClose}
          />
        </div>

        <div className={classes.content}>
          <ElementList
            data={questions}
            style={{ margin: '0', width: '100%' }}
            renderRow={(props) => (
              <Questao
                {...props}
                showTitle={questions.length > 1}
                answers={answers}
                changeAnswer={this.handleChangeAnswer}
                questions={questions}
              >
                {props.type === 'multiple_choice' ? (
                  <MultipleChoice />
                ) : props.type === 'essay' ? (
                  <Essay />
                ) : (
                  <RightWrong />
                )}
              </Questao>
            )}
          />
        </div>

        <div className={classes.footer}>
          <div className={classes.qtdQuestoes}>
            {totalAnswer !== totalItems
              ? `Você respondeu ${totalAnswer} de ${totalItems} questões`
              : `Você respondeu todas as questões`}
          </div>
          <Button
            customClasses={{ root: classes.button }}
            disabled={totalAnswer === 0 || loading}
            onClick={this.handleSave}
            type='submit'
          >
            {loading ? 'ENVIANDO...' : 'ENVIAR'}
          </Button>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, { open }) => {
  return {
    test: state.test.data[open],
    saving: state.saving,
  };
};

const mapDispatchToProps = {
  crudGetOne: (_id) =>
    crudGetOneAction('test', `${_id}`, '', false, true, {
      $embed: ['questions', 'questions.items', 'questions.board'],
    }),
  crudCreate: (...params) => crudCreateAction(...params),
  pushRouter: (path) => push(path),
  trackMetric: (name, data) => trackMetricAction(name, data),
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ModalSimulado);
