import React, { Component } from 'react'
import { connect } from 'react-redux';
import _ from 'lodash';
import { withStyles } from 'material-ui/styles';
import compose from 'recompose/compose';
import Dialog from 'material-ui/Dialog';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import Button from 'material-ui/Button';
import ArrowLeft from 'material-ui-icons/ChevronLeft';
import { withRouter } from 'react-router';
import CardItem from './CardItem';
import CardItemCorrecao from './CardItemCorrecao';

import EssayFilter from './EssayFilter';
import { FetchList } from '../../components';
import { ElementList } from '../../components/common';
import { SORT_ASC,SORT_DESC } from '../../reducers/resource/list/queryReducer';
import { Disqus } from '../../components';

const styles = theme => ({
  appBar: {
    top: 0,
    left: 'auto',
    right: 0,
    position: 'sticky',
    backgroundColor: '#FFF',
    transition: theme.transitions.create('width'),
    height: '65px',
    paddingRight: '24px',
    justifyContent: 'center',
    boxShadow: theme.shadows[27],
  },
  backContainer: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      padding: '18px'
    },
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  backIcon: {
    ...theme.typography.menu,
    color: theme.palette.text.clear,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '8px',
    paddingLeft: '0px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  responsiveDisplay: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inherit'
    }
  },
  toolbar: {
    padding: '0px'
  },
  icon: {
    color: theme.palette.text.clear,
  },
  titleContainer: {
    paddingTop: '4px',
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    ...theme.typography.title,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  subtitle: {
    ...theme.typography.body1,
    color: theme.palette.text.clear,
    textTransform: 'uppercase',
    display: 'none',
    flex: 1,
    textAlign: 'center',
    lineHeight: '1rem',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '1.8rem',
    margin: '0 auto',
  },
  menuIcons: {
    display: 'flex',
    color: theme.palette.common.white,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  dialog: {
    display: 'flex',
    alignItems: 'stretch',
    minHeight: '100vh',
    width: '100%'
  },
  slide: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: '100%'
  },
  arrowLeft: {
    height: '40px',
    width: '40px',
    color: theme.palette.black.a3,
    [theme.breakpoints.up('sm')]: {
      height: '25px',
      width: '25px',
      color: theme.palette.text.clear
    },
  },
  wrapper: {
    background: '#f9f9f9',
    overflow: 'auto',
    overflowX: 'hidden'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: '8px'
  },
  content: {
    paddingTop: '24px',
    marginBottom: '100px',
    height: 'max-content',
    margin: '0 auto 100px auto'
  },
  disqus: {
    marginTop: '16px'
  }
});

class ModalRedacao extends Component {
  componentWillReceiveProps(nextProps) {
    if(nextProps.refreshState !== this.props.refreshState) {
      const elem = this.refs.content;
      elem && elem.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }

  render () {
    const { open = {}, handleOpen, classes, disqus_auth, courseClass = {}, type, questionEssayAnswerStatus,questionEssayAnswerNumber } = this.props;
    var isCorrecao = type === 'redacao-correcao';

    var filters = {
      payload: {
        $embed: ['question', 'user', 'item']
      },
      sort: {
        field: isCorrecao? 'createdAt' : 'score',
        order: isCorrecao? SORT_ASC : SORT_DESC
      }
    }

    if(type === 'redacao-resposta') {
      filters['payload']['test'] = courseClass.test;
    }
    else {
      filters['payload']['question'] = courseClass.questions;
    }

    return (
      <Dialog
          classes={{root: classes.dialog}}
          fullScreen
          open={Boolean(open)}
          onRequestClose={() => handleOpen(false)}
        >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.backContainer}>
              <Button
                disableRipple
                disableFocusRipple
                aria-label="voltar"
                onClick={() => handleOpen(false)}
                className={classes.backIcon}
              >
                <ArrowLeft className={classes.arrowLeft}/> <div className={classes.responsiveDisplay}>voltar</div>
              </Button>
            </div>
            <div className={classes.titleContainer}>
              <div className={classes.title}>
                CORREÇÃO DE REDAÇÕES
              </div>
              <div className={classes.subtitle}>
                {courseClass.title}
              </div>
            </div>
            <div className={classes.menuIcons}>
            </div>
          </Toolbar>
        </AppBar>

        <div className={classes.wrapper}>
          <div className={classes.container}>
            <div ref="content" className={classes.content}>

              <FetchList {...this.props}
                fixedFilters={filters}
                resource={`questionEssayAnswer`}
                stateRefresh ={this.props.refreshState}
                filters={<EssayFilter questionEssayAnswerStatus={questionEssayAnswerStatus} questionEssayAnswerNumber={questionEssayAnswerNumber} user={this.props.user_id} isCorrecao={isCorrecao} />}>
                <ElementList
                  renderRow={(props) => { if(isCorrecao){return  <CardItemCorrecao type={type} {...props}/>} return  <CardItem loggedUser={this.props.user_id} type={type} {...props}/>}}
                />
              </FetchList>

              <div className={classes.disqus}>
                { !isCorrecao && courseClass.test && <Disqus id={courseClass.test}
                  resource='test'
                  remote_auth_s3={disqus_auth}
                  title={courseClass.title} /> }
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, {open} ) => {
  return {
    courseClass: state['courseClass/user'].data[open],
    questionEssayAnswerStatus: "Respostas",
    questionEssayAnswerNumber: state.questionEssayAnswer.list.total,
    refreshState: state.plataform.refreshState,
    user_id : state.user.profile._id,
    disqus_auth: state.comments.disqus_auth
  }
}

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, null),
)(ModalRedacao);
