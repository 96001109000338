import styled from 'styled-components';
import { CircularProgress } from 'material-ui';
import { TextField } from '@material-ui/core';

export const ReportErroContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #444444;
  }
  .fontSize {
    font-size: 15px;
  }
  button {
    float: right;
    margin-top: 15px;
    text-transform: none;
    span {
      color: #444444 !important;
    }
  }
  button:disabled {
    span {
      color: #808080 !important;
    }
  }
`;
export const FeedbackError = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
`;

export const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#FC7954',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FC7954',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#FC7954',
    },
  },
});

export const LoadingIndicator = styled(CircularProgress).attrs({
  size: 20,
})`
  color: #444444;
  margin-right: 8px;
`;
