import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import shallowEqual from 'recompose/shallowEqual';

import FilterForm from './FilterForm';

class Filter extends Component {

    componentWillReceiveProps(nextProps) {
        this.filters = nextProps.filterValues;
    }

    componentWillUnmount() {
        if (this.props.setFilters) {
            // this.setFilters.cancel();
        }
    }

    setFilters = (filters) => {
        if (!shallowEqual(filters, this.filters)) { // fix for redux-form bug with onChange and enableReinitialize
            const filtersWithoutEmpty = filters;
            _.keys(filtersWithoutEmpty).forEach((filterName) => {
                if (filtersWithoutEmpty[filterName] === '') {
                    // remove empty filter from query
                    delete filtersWithoutEmpty[filterName];
                }
            })
            this.props.setFilters(filtersWithoutEmpty);
            this.filters = filtersWithoutEmpty;
        }
    }


    render() {
      const { resource, children, hideFilter, displayedFilters, filterValues, setSort } = this.props;
      return (
        <FilterForm
          resource={resource}
          filters={React.Children.toArray(children)}
          hideFilter={hideFilter}
          displayedFilters={displayedFilters}
          initialValues={filterValues}
          setFilters={this.setFilters}
          setSort={setSort}
        />
      )
    }
}

Filter.propTypes = {
    children: PropTypes.node,
    context: PropTypes.oneOf(['form', 'button']),
    debounce: PropTypes.number.isRequired,
    displayedFilters: PropTypes.object,
    filterValues: PropTypes.object,
    hideFilter: PropTypes.func,
    setFilters: PropTypes.func,
    showFilter: PropTypes.func,
    resource: PropTypes.string.isRequired,
};

Filter.defaultProps = {
    debounce: 500,
};

export default Filter;
