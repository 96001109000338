import axios from "axios";

import { BaseUrl } from "../config";

const headers = {
  Authorization: localStorage.getItem("token"),
};

const api = axios.create({
  baseURL: BaseUrl,
  headers,
});

export default api;

export function getTimelinePosts({ concursoIds, page = 1, token }) {
  let url = "/timelinePost?";

  concursoIds.map((id, idx) => {
    url += `concursoId=${id}`;

    if (idx < concursoIds.length - 1) url += "&";
  });

  return api.get(url, {
    params: {
      $where: { postType: "post" },
      $sort: "-createdAt",
      $limit: 10,
      $skip: (page - 1) * 10,
      $page: page,
      isDeleted: false,
    },
    headers: {
      Authorization: token,
    },
  });
}
