import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import Input from 'material-ui/Input';
import { MenuItem } from 'material-ui/Menu';
import { FormControl } from 'material-ui/Form';
import Select from 'material-ui/Select';
import { Excelente, Medio, Ruim, EmptyCircle } from './SvgIcons'

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 80,
        maxWidth: 200,
        height: '25px'
    },
    circle: {
        height: '20px',
        width: '20px',
        borderRadius: '10px',
        border: '1px solid rgba(151, 151, 151, 0.3)',
    },
    underline: {
        '&:before': {
            display: 'none'
        },
        '&:after': {
            display: 'none'
        }
    },
    disableIcon: {
        display: 'none'
    },
    content: {
        display: 'flex',
        justifyContent: 'center'
    },
    text: {
        paddingLeft: '8px'
    },
    selectMenu: {
        padding: 0,
        height: '25px'
    }
});

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;

class MultipleSelect extends React.Component {
    constructor(props, context) {
        super(props)
        this.state = {
            value: parseInt(props.value, 10),
        };
    }
    
    handleChange = event => {
        this.setState({ value: event.target.value });
        this.props.onChange(event, event.target.value)
    };
    
    componentWillReceiveProps(nextProps) {
        this.setState({value: parseInt(nextProps.value || 0, 10)})
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <FormControl className={classes.formControl}>
                    <Select
                    id="picker"
                    value={this.state.value || 0}
                    classes={{icon: classes.disableIcon, root: classes.content, selectMenu: classes.selectMenu}}
                    onChange={this.handleChange}
                    defaultValue={0}
                    input={<Input classes={{underline: classes.underline}}  id="name-multiple" />}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                width: 150,
                                padding: 0
                            },
                        },
                    }}
                    >
                        <MenuItem
                            key={0}
                            value={0}
                            style={{
                                fontWeight: this.state.value === 0 ? '500' : '400',
                            }}
                        >
                            <EmptyCircle/> <div className={classes.text}>Não estudei</div>
                        </MenuItem>
                        <MenuItem
                            key={1}
                            value={1}
                            style={{
                                fontWeight: 0 < this.state.value < 1 ? '500' : '400',
                            }}
                        >
                            <Ruim/> <div className={classes.text}>Ruim</div>
                        </MenuItem>
                        <MenuItem
                            key={2}
                            value={2}
                            style={{
                                fontWeight: 1 < this.state.value < 2 ? '500' : '400'
                            }}
                        >
                            <Medio/> <div className={classes.text}>Bom</div>
                        </MenuItem>
                        
                        <MenuItem
                            key={3}
                            value={3}
                            style={{
                                fontWeight: 2 < this.state.value ? '500' : '400',
                            }}
                        >
                            <Excelente/> <div className={classes.text}>Excelente</div>
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
        );
    }
}

MultipleSelect.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MultipleSelect);