import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from 'material-ui/styles';
import { activeRoute } from '../../utils';
import { Link } from 'react-router';
import { titleFor } from '../../utils/strings';
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';

const styles = theme => ({
  wrapper: {
		cursor: 'pointer',
    flex: 1,
    alignItems: 'center',
    borderRadius: '3px',
    display: 'block',
		background: '#fff',
		width: '100%',
		marginTop: '8px',
		marginBottom: '8px',
		padding: '10px 8px 12px 12px',
		boxShadow: theme.shadows[25],
		paddingLeft: '12px',
    ...theme.typography.small,
    color: theme.palette.text.clear,
    fontSize: '12px',
    lineHeight: '13px',
    textDecoration: 'none',
    textAlign: 'left'
	},
  active: {
    paddingLeft: '10px',
    borderLeftColor: theme.palette.clearOrange,
    borderLeftStyle: 'solid',
    borderLeftWidth: '2px'
  },
  exhibition: {
    fontSize: '13px',
    fontWeight: '600',
    color: theme.palette.black.a8,
    lineHeight: '22px',
    textTransform: 'uppercase'
  },
  title: {
    fontSize: '15px',
    lineHeight: '18px',
    color: theme.palette.black.a6
  }
});

class NavItem extends React.Component {
  track = () => {
    const { _id, trackMetric } = this.props;
    
    trackMetric('Bibliografia - Ativação', {
      target: 'navigation',
      trigger: 'click',
      resource: _id
    });
  }

  render() {
    const { classes, _id , week, subject, title, location, concurso, exhibition } = this.props;
    const active = activeRoute(_id, location) ? classes.active : '';
    
    return (
      <div onClick={this.track}>
        <Link
          to={`/plano-de-estudos/${_id}/${exhibition}`}
          className={classNames(classes.wrapper, active)}
        >
          <div className={classes.exhibition}>{(exhibition === 'byweek') ? subject.name : `${titleFor('ciclo', concurso)} ${week}`}</div>
          <div className={classes.title}>{title}</div>
        </Link>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    trackMetric: (name, data) => dispatch(trackMetricAction(name, data))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    concurso: state.concurso.active
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(NavItem);
