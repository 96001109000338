import {
  parseISO,
  isAfter,
  format,
  isSameDay,
  differenceInHours,
} from 'date-fns';
import moment from 'moment';

export function getInitialInfo(currentClass, currentModule, ends_on) {
  let moduleEndsDate,
    currentDate = new Date(),
    zoomDate;

  const info = {
    qpq: currentClass.qpq === true ? true : false,
    enrolled: false,
    time: '',
    type: '',
    classTempo: '',
    availableDate: '',
    showVideo: false,
    showLive: false,
    showPaymentBar: false,
    isLive: false,
    isBefore: false,
  };

  if (!(currentClass.publish_zoom === (undefined || null || ''))) {
    zoomDate = parseISO(currentClass.publish_zoom);
  } else {
    info.type = 'missing';
    return true;
  }

  if (!(currentModule.expiration === (undefined || null || ''))) {
    moduleEndsDate = parseISO(currentModule.expiration);
  } else {
    info.type = 'missing';
    return true;
  }

  if (isSameDay(zoomDate, currentDate)) {
    info.classTempo = 'live';
    info.availableDate = format(zoomDate, 'dd/MM/yyyy');
    info.time = format(zoomDate, 'HH:mm');
    if (
      -2 <= differenceInHours(zoomDate, currentDate) &&
      0 >= differenceInHours(zoomDate, currentDate)
    ) {
      info.isLive = true;
    } else {
      if (!isAfter(currentDate, zoomDate)) {
        info.isBefore = true;
      }
    }
  } else {
    if (!isAfter(currentDate, zoomDate)) {
      info.isBefore = true;
      info.classTempo = 'before';
      info.availableDate = format(zoomDate, 'dd/MM/yyyy');
      info.time = format(zoomDate, 'HH:mm');
    } else {
      let earlyDate = moduleEndsDate;
      if (ends_on && isAfter(moduleEndsDate, ends_on)) {
        earlyDate = ends_on;
      }

      info.classTempo = 'after';
      info.availableDate = format(earlyDate, 'dd/MM/yyyy');
      info.time = format(earlyDate, 'HH:mm');
    }
  }
  return info;
}

export function getPaymentInfo(
  userPayments,
  courseID,
  moduleID,
  matriculaID,
  classID
) {
  const paymentInfo = {
    courseHasPayment: false,
    moduleHasPayment: false,
    matriculaHasPayment: false,
    classHasPayment: false,
    ends_on: '',
  };
  //verifica se o curso atual esta pago (usuario o tem)
  for (const curso of userPayments.cursos) {
    if (curso.courseID === courseID) {
      paymentInfo.courseHasPayment = true;
      paymentInfo.ends_on = curso.ends_on;
      break;
    }
  }

  if (paymentInfo.courseHasPayment) {
    paymentInfo.matriculaHasPayment = true;
    paymentInfo.moduleHasPayment = true;
    paymentInfo.classHasPayment = true;
  } else {
    //verifica se o modulo atual esta pago (usuario o tem)
    for (const modulo of userPayments.modulos) {
      if (modulo.moduleID === moduleID) {
        paymentInfo.moduleHasPayment = true;
        paymentInfo.classHasPayment = true;
        paymentInfo.ends_on = modulo.ends_on;
        break;
      }
    }

    //verifica se a matricula atual esta paga (usuario a tem)
    if (!paymentInfo.moduleHasPayment) {
      for (const matricula of userPayments.matriculas) {
        if (matricula.aula === matriculaID) {
          paymentInfo.matriculaHasPayment = true;
          paymentInfo.ends_on = matricula.ends_on;
          break;
        }
      }

      //verifica se a aula atual esta paga (usuario a tem)
      // if (paymentInfo.matriculaHasPayment) { ///// Não precisa estar matriculado para liberar acesso à aula paga.
        for (const aula of userPayments.aulas) {
          if (aula === classID) {
            paymentInfo.classHasPayment = true;
            break;
          }
        }
      // }
    }
  }
  return paymentInfo;
}

export function handleClassInfo(
  hasDebits,
  isClassADebit,
  paymentInfo,
  currentClass,
  info
) {
  if (paymentInfo.moduleHasPayment || paymentInfo.matriculaHasPayment || paymentInfo.classHasPayment) {
    info.enrolled = true;
    info.type = 'processing';
    if (currentClass.webinar_id !== null) {
      if (info.isLive) {
        if (isClassADebit || paymentInfo.classHasPayment || !info.qpq) {
          info.showLive = true;
        }
        info.type = 'live';
      } else {
        if (info.isBefore) {
          info.type = 'hasToInscribe';
          if (isClassADebit || paymentInfo.classHasPayment || !info.qpq) {
            info.type = 'inscribed';
          }
        }
      }
    }
    if (currentClass.video_id !== null && !info.isLive) {
      const date = moment(info.availableDate, 'DD/MM/yyyy').toDate();
      const currentDate = new Date();
      if (date > currentDate) {
        info.type = 'available';
        if (isClassADebit || paymentInfo.classHasPayment || !info.qpq) {
          info.showVideo = true;
          if (paymentInfo.moduleHasPayment) {
            info.showPaymentBar = false;
          } else {
            info.showPaymentBar = hasDebits || isClassADebit;
          }
        } else {
          info.showVideo = false;
        }
      } else {
        info.type = 'expired';
      }
    }
  } else {
    info.type = 'notEnrolled';
  }
}
