import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from 'material-ui/styles';
import ButtonBase from 'material-ui/ButtonBase'
import classNames from 'classnames';
import {Facebook} from '../common/SvgIcons'

const styles = theme => ({
  container: {
    width: '100%'
  },
  root: {
    ...theme.typography.input,
    border: '1px solid rgba(0,0,0,0.1)',
    color: '#3E5F96',
    borderRadius: '3px',
    minWidth: '133px',
    padding: '15px 48px',
    marginBottom: '16px',
    [theme.breakpoints.up('sm')]: {
      padding: '15px 100px',
    }
  },
  disable: {
    border: '1px solid rgba(0,0,0,0.1)',
    color: theme.palette.black.a3,
  },
  icon: {
    marginRight: '24px',
    marginLeft: '-36px'
  },
  fbDisconnect: {
    ...theme.typography.observation,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  userImg: {
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain'
  },
  fbConnected: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

class Button extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    customClasses: PropTypes.object,
    facebookId: PropTypes.string
  }
  
  static defaultProps = {
    disabled: false,
    customClasses: {}
  }
  
  state = {}

  handleFacebookSubmit = (e) => {
    this.setState({isLoading: true});
    
    window.FB.login(response => {
      this.setState({isLoading: false});
      
      if(response.status === "connected") {
        window.FB.api(
          `/me?fields=id,email,name,picture,birthday,gender`,
          'GET',
          {  },
          profile => {
            if(!profile.error) {
              this.props.facebookConnect(response.authResponse.accessToken)
              this.setState({
                firstName: profile.name.split(' ')[0],
                picture: profile.picture.data.url,
              });
            }
            else {
              this.setState({err: profile.error})
            }
          }
        );
      }
    }, { scope: 'public_profile, email, user_birthday, user_hometown'});
  }
  
  handleFacebookLogout = () => {
    this.setState({
      firstName: undefined,
      picture: undefined,
    });
    this.props.facebookDisconnect()
    window.FB.logout(response => true);
  }

  // loadFbData = (facebookId) => {
    
  //   if(facebookId && window.FB) {
  //     window.FB.api(
  //       `/${facebookId}/picture`,
  //       profile => {
  //         (profile)
  //         if(!profile.error) {
  //             this.setState({
  //                 firstName: profile.name.split(' ')[0],
  //                 picture: profile.picture.data.url
  //             });
  //         }
  //         else {
  //           this.setState({err: profile.error})
  //         }
  //       }
  //     );
  //   } else {
  //     this.setState({
  //       firstName: undefined,
  //       picture: undefined,
  //     });
  //   }
  // }

  // componentDidMount() {
  //   const {facebookId} = this.props
    
  //   this.loadFbData(facebookId)
  // }

  // componentWillReceiveProps({facebookId}) {
  //   if(facebookId && (facebookId !== this.props.facebookId)) {
  //     this.loadFbData(facebookId)
  //   }
  // }
  
  render() {
    const {classes, disabled, customClasses, facebookId} = this.props
  
    return (
      <div className={classes.container}>
        <ButtonBase 
        disabled={disabled}
        onClick={facebookId ? () => {} : this.handleFacebookSubmit}
        classes={{root: classNames(classes.root, customClasses.root), disabled: classNames(classes.disable, customClasses.disable)}} 
        children={<div className={classes.container}>
          {facebookId ? 
          <div className={classes.fbConnected}><div className={classNames(classes.icon,classes.userImg)} 
              style={{backgroundImage: `url("https://graph.facebook.com/v2.11/${facebookId}/picture")`}}></div> Conectado ao facebook</div>: 
          <div><Facebook className={classes.icon} /> Conectar-se ao Facebook</div>}
        </div>}/>
        {facebookId ? <div>
          <a onClick={this.handleFacebookLogout} className={classes.fbDisconnect}>Desvincular conta do facebook</a>
        </div> : false}
      </div>
    )
  }
}


export default withStyles(styles)(Button)
