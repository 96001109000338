import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux'
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import { withStyles } from 'material-ui/styles';
import Paper from 'material-ui/Paper';

import BasketLoaded from '../../assets/img/icon/basket-loaded.svg';
import BackgroundBanner from '../../assets/img/img_professores.png';

const styles = () => ({
    banner: {
        display: 'grid',
        gridTemplateColumns: '0.2fr 0.8fr',
        gridTemplateRows: '84px',
        width: '665px',
        borderRadius: '8px',
        cursor: 'pointer',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.1)',
        margin: '10px auto',
        backgroundColor: '#8377f2',
        fontFamily: 'Proxima Nova',
        '@media (max-width: 578px)': {
            width: '95vw'
        },
    },
    image: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        color: 'white',
        justifyContent: 'center',
        backgroundImage: `url(${BackgroundBanner})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        '@media (max-width: 578px)': {
            backgroundImage: 'none',
        }
    },
    title: {
        fontSize: '25px',
        fontWeight: 'bold',
        letterSpacing: '-0.7px',
        '@media (max-width: 578px)': {
            display: 'none'
        }
    },
    mobileTitle: {
        fontSize: '20px',
        fontWeight: 'bold',
        letterSpacing: '-0.7px',
        display: 'none',
        '@media (max-width: 578px)': {
            display: 'block'
        }
    },
    label: {
        fontSize: '15px',
        fontWeight: 'normal',
    },
});

class Banner extends Component {
    render() {
        const {
            classes,
            link,
            pushRouter,
            title,
            label,
            mobileTitle
        } = this.props;

        return (
            <Paper onClick={() => pushRouter(link)} className={classes.banner}>
                <div className={classes.image}>
                    <img src={BasketLoaded} />
                </div>
                <div className={classes.textContainer}>
                    <span className={classes.title}>
                        {title}
                    </span>
                    <span className={classes.mobileTitle}>
                        {mobileTitle}
                    </span>
                    <span className={classes.label}>
                        {label}
                    </span>
                </div>
            </Paper>
        );
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        pushRouter: (path) => dispatch(push(path)),
    }
}

Banner.propTypes = {
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    mobileTitle: PropTypes.string,
    label: PropTypes.string,
};

export default compose(
    connect(null, mapDispatchToProps),
    withStyles(styles),
)(Banner);

