import React from "react";
import styled from "styled-components";

import { CircularProgress } from "@material-ui/core";

const CustomLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const CustomLoading = styled(CircularProgress)`
  margin-top: 8px;

  & > svg {
    color: #f58220;
  }
`;

function UpgradePlanConfirm({ loading, confirm }) {
  if (confirm) {
    return (
      <CustomLoadingContainer>
        <div><b>Upgrade realizado com sucesso!</b></div>
        <div>Para acessar as novas funcionalidades, é necessário fazer novo <a href="https://checkout.clippingconcursos.com.br/acessar?redirectTo=/mural&custom={%22concursoId%22:1}">login aqui</a>.</div>
      </CustomLoadingContainer>
    );
  }

  if (loading) {
    return (
      <CustomLoadingContainer>
        <div>Aguarde...</div>
        <CustomLoading />
      </CustomLoadingContainer>
    );
  }

  return <React.Fragment></React.Fragment>;
}

export default UpgradePlanConfirm;
