import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "material-ui/styles";
import Dialog from "material-ui/Dialog";
import { Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

import {
  showSponsorModal,
  openFinancialResponsible,
  setCreditPayModalOpen,
  setChooseCardOpen,
  clearSponsor,
  setSponsorID,
} from "../actions";

import { openRegister } from "../../Checkout/actions";

const styles = {
  paper: {
    minWidth: 650,
    margin: "0px",
  },
  paperResponsive: {
    margin: "0px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 48px;
  font-family: "Proxima Nova" !important;
  @media (max-width: 500px) {
    padding: 32px 24px;
  }
  @media (max-width: 400px) {
    padding: 32px 16px;
  }
`;

const TitleContainer = styled.div`
  position: relative;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
`;

const Title = styled.span`
  width: 100%;
  font-size: 25px;
  letter-spacing: -0.7px;
  text-align: center;
  line-height: 28px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  @media (max-width: 500px) {
    font-size: 20px;
    letter-spacing: 0px;
    line-height: 22px;
  }
  @media (max-width: 440px) {
    font-size: 18px;
    line-height: 20px;
  }
  @media (max-width: 400px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

const MessageContainer = styled.div`
  width: 100%;
  height: fit-content;
`;

const Message = styled.p`
  color: rgba(0, 0, 0, 0.75);
  font-size: 16px;
  font-weight: normal;
  height: fit-content;
  letter-spacing: 0px;
  line-height: 22px;
  text-align: center;
  white-space: pre-line;
  width: fit-content;
  margin: 0px;
  font-family: "Proxima Nova";
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
  display: flex;
  margin-top: 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 700px) {
    width: fit-content;
    height: 96px;
    flex-direction: column;
  }
`;

const CustomButton = styled.button`
  border: none;
  background-color: #fc7954;
  border-radius: 25px;
  font-size: 13px;
  color: #fff;
  font-weight: 600;
  width: 230px;
  height: 40px;
  margin-right: 30px;
  text-transform: uppercase;
  font-family: "Proxima Nova";
  &:hover {
    background: #e95b34;
    border: #e95b34;
  }

  @media (max-width: 700px) {
    margin-right: 0px;
  }
`;

const AltButton = styled.button`
  border: 2px solid rgb(252, 121, 84);
  background-color: #fff;
  border-radius: 25px;
  font-size: 13px;
  color: rgb(252, 121, 84);
  font-weight: 600;
  width: 230px;
  height: 40px;
  text-transform: uppercase;
  font-family: "Proxima Nova";
  &:hover {
    border-color: #e95b34;
    color: #e95b34;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute !important;
  top: 4px;
  right: 4px;
  color: rgba(0, 0, 0, 0.4);
`;

function ConfirmExit({ classes }) {
  const dispatch = useDispatch();

  const open = useSelector(
    (state) => state.paymentGroup.openFinancialResponsible
  );
  const responsive = useSelector((state) => state.plataform.responsive);
  const userCards = useSelector((state) => state.userData.data.user_cards);

  function handleAction(action) {
    dispatch(showSponsorModal(false));
    dispatch(setSponsorID(""));
    dispatch(clearSponsor());

    if (action === "sim") {
      dispatch(setCreditPayModalOpen(true));
    } else {
      dispatch(showSponsorModal(true));
      dispatch(openRegister());
    }
    dispatch(openFinancialResponsible(false));
  }

  function handleClose() {
    dispatch(openFinancialResponsible(false));
    dispatch(setChooseCardOpen(true));
  }

  return (
    <Dialog
      open={open}
      classes={{ paper: responsive ? classes.paperResponsive : classes.paper }}
    >
      <Container>
        <TitleContainer>
          <Title>Você será o responsável financeiro?</Title>
          <CloseButton aria-label="Fechar" onClick={() => handleClose()}>
            <Close />
          </CloseButton>
        </TitleContainer>
        <Content>
          <MessageContainer>
            <Message>
              Se você for o responsável financeiro, utilizaremos seus dados
              pessoais e de endereço como dados de cobrança do cartão.
            </Message>
            <Message>
              Caso for comprar com um cartão de terceiro, ou cartão pessoal com
              endereço de cobrança distinto será necessário escolher a opção
              "Não"
            </Message>
          </MessageContainer>
          <ButtonsWrapper responsive={responsive}>
            <CustomButton onClick={() => handleAction("sim")}>Sim</CustomButton>
            <AltButton onClick={() => handleAction("não")}>Não</AltButton>
          </ButtonsWrapper>
        </Content>
      </Container>
    </Dialog>
  );
}

export default withStyles(styles)(ConfirmExit);
