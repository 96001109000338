import React from "react";
import styled from "styled-components";

import CalendarIcon from "../../../components/SvgComponents/CalendarIcon.js";

import {
  CheckCircleOutline,
  AccessTime,
  LockOutlined,
  VideocamOff,
  CloudUploadOutlined,
} from "@material-ui/icons";

const VideoTextWithIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const VideoTextWithIconText = styled.p`
  max-width: 70%;
  height: fit-content;
  color: rgb(255, 255, 255);
  font-size: 15px;
  font-family: "Proxima Nova";
  font-weight: 500;
  text-align: center;
  letter-spacing: 0px;
  line-height: 20px;
  white-space: pre-line;
  margin: 0 auto;

  @media (max-width: 1000px) {
    font-size: 13px;
  }
`;

const VideoTextWithIconIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    width: 18px;
    height: 18px;
    margin-bottom: 16px;
  }
`;

const InscribedContainer = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const InscribedNotification = styled.span`
  font-family: "Proxima Nova";
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  height: 16px;
  letter-spacing: 0px;
  text-align: center;

  @media (max-width: 1000px) {
    font-size: 11px;
    line-height: 14px;
    height: 14px;
  }
`;

const InscribedInformationGroup = styled.div`
  width: 316px;
  height: 80px;
  margin-top: 32px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1000px) {
    width: 256px;
    height: 60px;
  }
`;

const InscribedInformationCard = styled.div`
  background: ${(props) =>
    props.green ? "rgb(49, 201, 142)" : "rgba(216, 216, 216, 0.4)"};
  border-radius: 5px;
  height: 80px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 17px 0px;

  @media (max-width: 1000px) {
    height: 60px;
    width: 80px;
    padding: 11px 0px;
  }
`;

const InscribedInformationCardText = styled.div`
  color: rgb(255, 255, 255);
  font-family: "Proxima Nova";
  font-size: 15px;
  height: 20px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: center;

  @media (max-width: 1000px) {
    font-size: 13px;
    height: 16px;
    line-height: 16px;
  }
`;

function VideoTextWithIcon({ data }) {
  return (
    <VideoTextWithIconContainer>
      {data.tipo !== "EMPTY" &&
        (data.tipo !== "ENROLLEDFOR_LIVE" && (
          <VideoTextWithIconIcon>
            {data.tipo === "LOCKED_CLASS" ? (
              <LockOutlined style={{ color: "#fff" }} />
            ) : data.tipo === "EXPIRED_CLASS" ? (
              <VideocamOff style={{ color: "#fff" }} />
            ) : data.tipo === "PROCESSING_LIVE" ? (
              <CloudUploadOutlined style={{ color: "#fff" }} />
            ) : (
              ""
            )}
          </VideoTextWithIconIcon>
        ))}
      <VideoTextWithIconText>
        <span
          style={{
            background: "rgb(24, 24, 24)",
            boxShadow:
              "6px 0 0 2px rgb(24, 24, 24), -6px 0 0 2px rgb(24, 24, 24)",
            lineHeight: "24px",
          }}
        >
          {data.text}
        </span>
      </VideoTextWithIconText>
      {data.tipo === "ENROLLEDFOR_LIVE" && (
        <InscribedContainer>
          <InscribedNotification>
            Te avisaremos por email cerca de 45 minutos antes da aula começar.
          </InscribedNotification>
          <InscribedInformationGroup>
            <InscribedInformationCard green={true}>
              <CheckCircleOutline
                style={{ width: "18px", heigth: "18px", color: "#fff" }}
              />
              <InscribedInformationCardText>
                Inscrito
              </InscribedInformationCardText>
            </InscribedInformationCard>
            <InscribedInformationCard>
              <CalendarIcon width={"18px"} height={"18px"} color={"#fff"} />
              <InscribedInformationCardText>
                {data.date}
              </InscribedInformationCardText>
            </InscribedInformationCard>
            <InscribedInformationCard>
              <AccessTime
                style={{ width: "18px", heigth: "18px", color: "#fff" }}
              />
              <InscribedInformationCardText>
                {data.time}h
              </InscribedInformationCardText>
            </InscribedInformationCard>
          </InscribedInformationGroup>
        </InscribedContainer>
      )}
    </VideoTextWithIconContainer>
  );
}

export default VideoTextWithIcon;
