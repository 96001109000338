import React from 'react';
import moment from 'moment';
import { ProfilePicture } from '../common';
import { LikeButton } from '../common/ui';
import _ from 'lodash'; 

const CommentItem = (props) => {
  const { comment, likes = {}, userSocialId, firstName, lastName, created_at, profile, modalData, _id, id, likeComment } = props;
  const likeList = _.keys(likes);
  const liked = likeList.includes(profile._id.toString());

  return  <li >
            <div>
                <ProfilePicture userSocialId={userSocialId} />
            </div>

            <div>
                <div className="comment--name">{`${firstName} ${lastName}`}</div>
                <div className="comment--time">{moment(created_at).fromNow()}</div>

                <div className="comment--text">{comment}</div>

                <div className="comment--actions">
                    <LikeButton onPress={() => likeComment(_id, modalData, liked, id)} likes={likeList} loggedUserId={profile._id} />
                </div>
            </div>
        </li>
}

export default CommentItem;