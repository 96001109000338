import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Cards from "react-credit-cards";
import { withStyles } from "material-ui/styles";
import { TextField } from "@material-ui/core";

import {
  Dialog,
  Checkbox,
  IconButton,
  Icon,
  MenuItem,
  FormControlLabel,
  Select,
  Button,
} from "material-ui/";

import DialogContent from "material-ui/Dialog/DialogContent";
import { Close, ArrowBack } from "material-ui-icons";

import "react-credit-cards/es/styles-compiled.css";

import {
  setCreditPayModalOpen,
  setPaymentModalOpen,
  setChooseCardOpen,
  payCourseClassRequest,
  fetchPayment,
} from "../actions";

import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatCreditCardName,
  validateCVV,
  validateExpiration,
  validateCreditCardNumber,
  getBrand,
} from "./utils";

const styles = {
  paper: {
    position: "relative",
    padding: 0,
    paddingTop: 0,
    display: "grid",
    gridTemplateColumns: "650px",
    gridTemplateRows: "65px 40px 210px auto",
    minWidth: "650px",
    height: "fit-content",
    minHeight: "900px",
    fontFamily: "Proxima Nova",
    borderRadius: "4px",
    overflowY: "hidden",
    "&:first-of-type": {
      paddingTop: 0,
    },
    "@media (max-width: 578px)": {
      minWidth: "auto",
      height: "100vh",
      maxHeight: "100vh",
      gridTemplateColumns: "auto",
      width: "-webkit-fill-available",
      margin: "0",
      width: "100vw",
      minWidth: "100vw",
    },
  },
  paperResponsive: {
    position: "relative",
    padding: 0,
    display: "grid",
    gridTemplateColumns: "100vw",
    gridTemplateRows: "65px 40px 210px auto",
    minWidth: "360px",
    height: "fit-content",
    minHeight: "900px",
    fontFamily: "Proxima Nova",
    borderRadius: "4px",
    overflowY: "hidden",
    "&:first-of-type": {
      paddingTop: 0,
    },
    "@media (max-width: 578px)": {
      minWidth: "auto",
      height: "100vh",
      maxHeight: "100vh",
      gridTemplateColumns: "auto",
      width: "-webkit-fill-available",
      margin: "0",
    },
  },
  paperQPQ: {
    position: "relative",
    padding: 0,
    paddingTop: 0,
    display: "grid",
    gridTemplateColumns: "650px",
    gridTemplateRows: "65px 40px 210px auto",
    minWidth: "650px",
    height: "fit-content",
    minHeight: "760px",
    fontFamily: "Proxima Nova",
    borderRadius: "4px",
    overflowY: "hidden",
    "&:first-of-type": {
      paddingTop: 0,
    },
    "@media (max-width: 578px)": {
      minWidth: "auto",
      height: "100vh",
      maxHeight: "100vh",
      gridTemplateColumns: "auto",
      width: "-webkit-fill-available",
      margin: "0",
      width: "100vw",
      minWidth: "100vw",
    },
  },
  paperResponsiveQPQ: {
    position: "relative",
    padding: 0,
    display: "grid",
    gridTemplateColumns: "100vw",
    gridTemplateRows: "65px 40px 210px auto",
    minWidth: "360px",
    height: "fit-content",
    minHeight: "900px",
    fontFamily: "Proxima Nova",
    borderRadius: "4px",
    overflowY: "hidden",
    "&:first-of-type": {
      paddingTop: 0,
    },
    "@media (max-width: 578px)": {
      minWidth: "auto",
      height: "100vh",
      maxHeight: "100vh",
      gridTemplateColumns: "auto",
      width: "-webkit-fill-available",
      margin: "0",
    },
  },
  paymentButton: {
    height: "40px",
    width: "310px",
    borderRadius: "20px",
    background: "rgb(252, 121, 84)",
    color: "white",
    fontSize: "14px",
    fontWeight: "600",
    textTransform: "none",
    "&:hover": {
      background: "#E95B34",
    },
  },
  form: {
    gridArea: "4 / 1 / 5 / 2",
    display: "grid",
    gridTemplateColumns: "310px",
    gridTemplateRows: "repeat(3, 80px) 60px 48px 40px 20px",
    // gridTemplateRows: "repeat(2, 80px) 90px 75px 40px 20px",
    gridRowGap: "12px",
    justifyContent: "center",
  },
  qpqForm: {
    gridArea: "4 / 1 / 5 / 2",
    display: "grid",
    gridTemplateColumns: "310px",
    gridTemplateRows: "repeat(3, 80px) 48px 40px 20px",
    gridRowGap: "12px",
    justifyContent: "center",
  },
  lockIcon: {
    fontSize: "18px !important",
  },
  lockIconLabel: {
    fontSize: "13px",
  },
  checkbox: {
    marginRight: "0",
  },
  checkboxLabel: {
    fontSize: "13px",
  },
  inputFieldStart: {
    width: "100%",
    "& label.Mui-focused": {
      color: "rgba(0,0,0, 0.6)",
    },
    "& label": {
      fontFamily: "Proxima Nova",
    },
    "&:hover label": {
      color: "rgba(0,0,0, 0.6)",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "rgba(0,0,0, 0.6)",
      },
      "&.Mui-focused fieldset": {
        fontFamily: "Proxima Nova",
        borderColor: "rgba(0,0,0, 0.6)",
      },
    },
  },
  inputFieldCorrect: {
    width: "100%",
    "& label.Mui-focused": {
      color: "rgba(0,0,0, 0.5)",
    },
    "& label": {
      color: "#31c400",
      fontFamily: "Proxima Nova",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#31c400",
      },
      "&:hover fieldset": {
        borderColor: "#31c400",
      },
      "&.Mui-focused fieldset": {
        fontFamily: "Proxima Nova",
        borderColor: "#31c400",
      },
    },
  },
  selectFieldStart: {
    "div.MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "rgba(0,0,0, 0.6)",
      },
      "&.Mui-focused fieldset": {
        fontFamily: "Proxima Nova",
        borderColor: "rgba(0,0,0, 0.6)",
      },
    },
  },
  selectRoot: {
    height: "56px",
    display: "flex",
    alignItems: "center",
  },
  selectContent: {
    height: "56px",
    display: "flex",
    alignItems: "center",
    padding: 0,
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "4px",
    paddingLeft: "12px",
    fontSize: "17px",
    "&:focus": {
      borderRadius: "4px",
    },
    "&:hover": {
      border: "1px solid rgba(0, 0, 0, 0.6)",
    },
  },
};

const DialogHeader = styled.div`
  display: flex;
  font-family: "Proxima Nova";
  overflow: visible;
  z-index: 4;
  position: fixed;
  width: ${(props) => (props.responsive ? "100vw" : "650px")};
  height: 65px;
  justify-content: center;
  background: #fff;
  align-items: center;
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.7);
  font-size: 20px;
  font-weight: bold;
`;

const DialogHeaderTitle = styled.div`
  width: fit-content;
  margin: 0 auto;
  user-select: none;
`;

const DialogHeaderButton = styled.div`
  width: fit-content;
  position: absolute;
  right: 0;
`;

const DialogHeaderButtonMobile = styled.div`
  width: fit-content;
  display: block;
  position: absolute;
  left: 0;
  padding-left: 0px;
`;

const Info = styled.div`
  grid-area: 2 / 1 / 3 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
  font-size: 15px;
  font-weight: normal;
`;

const CardPreview = styled.div`
  grid-area: 3 / 1 / 4 / 2;
  padding-bottom: 24px;
`;

const CardNumber = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  justify-content: center;
`;

const CardName = styled.div`
  grid-area: 2 / 1 / 3 / 2;
  display: flex;
  justify-content: center;
`;

const Row = styled.div`
  grid-area: 3 / 1 / 4 / 2;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const InstallmentsContainer = styled.div`
  grid-area: 4 / 1 / 5 / 2;
`;

const CheckboxContainer = styled.div`
  grid-area: ${(props) => (props.qpq ? "4 / 1 / 5 / 2" : "5/ 1 / 6 / 2")};
`;

const PaymentButtonContainer = styled.div`
  grid-area: ${(props) => (props.qpq ? "5/ 1 / 6 / 2" : "6 / 1 / 7 / 2")};
`;

const LockIconContainer = styled.div`
  grid-area: ${(props) => (props.qpq ? "6 / 1 / 7 / 2" : "7 / 1 / 8 / 2")};
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > div.Mui-focused {
    border-color: #31c400 !important;
  }
`;

const InputSm = styled.div`
  width: 150px;
  display: flex;
  flex-direction: column;
`;

const ErrorLog = styled.p`
  margin: 0px;
  font-family: "Proxima Nova";
  height: fit-content;
  width: fit-content;
  align-self: left;
  font-size: 14px;
  padding-left: 6px;
  color: #f22424;
  margin-top: 4px;
`;

function CreditPaymentModal({
  classes,
  open,
  qpq_price = 0,
  pacoteID = "",
  qpq = true,
}) {
  const dispatch = useDispatch();

  const [number, setNumber] = useState("");
  const [name, setName] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [focused, setFocused] = useState("");
  const [brand, setBrand] = useState("");
  const [saveCard, setSaveCard] = useState(true);
  const [installment, setInstallment] = useState(1);
  const [installmentsOptions, setInstallmentOptions] = useState([]);

  const [validNumber, setValidNumber] = useState("initial");
  const [validName, setValidName] = useState("initial");
  const [validExpirity, setValidExpirity] = useState("initial");
  const [validCVC, setValidCVC] = useState("initial");

  const checkoutPrice = useSelector((state) => state.checkout.cart.price);
  const checkoutItems = useSelector((state) => state.checkout.cart.items);
  const title = useSelector(
    (state) => state.paymentGroup.creditPaymentModal.title
  );
  const qtyInstallments = useSelector(
    (state) => state.paymentGroup.creditPaymentModal.installments
  );

  const { sponsorID } = useSelector((state) => state.paymentGroup);

  const responsive = useSelector((state) => state.plataform.responsive);

  useEffect(() => {
    let installments = [];

    for (let index = 1; index < qtyInstallments + 1; index++) {
      let installmentsPrice = 0;

      if (checkoutPrice.a_vista > 0 && index === 1) {
        installmentsPrice = Number(checkoutPrice.a_vista / index)
          .toFixed(2)
          .replace(".", ",");

        installments.push({
          value: index,
          text: `${index}x de R$ ${installmentsPrice}`,
        });
      }else{
  
        installmentsPrice = Number(checkoutPrice.parcelado / index)
          .toFixed(2)
          .replace(".", ",");
  
        installments.push({
          value: index,
          text: `${index}x de R$ ${installmentsPrice}`,
        });
      }
    }

    setInstallmentOptions(installments);
  }, [checkoutPrice, qtyInstallments]);

  const handleChange = (event) => {
    setInstallment(event.target.value);
  };

  function clearState() {
    setNumber("");
    setName("");
    setExpiry("");
    setCvc("");
    setFocused("");
    setBrand("");
    setSaveCard(true);
    setValidNumber("initial");
    setValidName("initial");
    setValidExpirity("initial");
    setValidCVC("initial");
    setInstallment(1);
  }

  function actionCreator() {
    clearState();
    dispatch(setCreditPayModalOpen(false));
    if (qpq) {
      dispatch(setPaymentModalOpen(true));
    } else {
      dispatch(setChooseCardOpen(true));
    }
  }

  function handleInputChange({ target }) {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
      setValidNumber("initial");
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
      setValidExpirity("initial");
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
      setValidCVC("initial");
    } else if (target.name === "name") {
      target.value = formatCreditCardName(target.value);
      setValidName("initial");
    }

    if (getBrand(number)) {
      setBrand(getBrand(number));
    } else {
      setBrand("");
    }

    if (target.name === "number") {
      setNumber(target.value);
    }
    if (target.name === "name") {
      setName(target.value);
    }
    if (target.name === "expiry") {
      setExpiry(target.value);
    }
    if (target.name === "cvc") {
      setCvc(target.value);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (
      validExpirity === true &&
      validCVC === true &&
      validName === true &&
      validNumber === true
    ) {
      const card_holder_name = formRef.current.elements["name"].value;
      const card_number = formRef.current.elements["number"].value.replace(
        /\s/g,
        ""
      );
      const card_expiration_date = formRef.current.elements[
        "expiry"
      ].value.replace(/\//g, "");
      const card_cvv = formRef.current.elements["cvc"].value;
      // const save_card = formRef.current.elements["savecard"].value;

      let amount = 0;

      let data = {};

      if (qpq) {
        amount = parseInt(`${qpq_price}00`);
        data = {
          gateway: "pagarme",
          payment_method: "credit_card",
          card_number,
          card_cvv,
          card_expiration_date,
          card_holder_name,
          save_card: saveCard ? "True" : "False",
          amount,
          installments: 1,
          items: [{ id: pacoteID }],
        };
        if (sponsorID !== "") {
          data.new_address = sponsorID;
        }
        dispatch(payCourseClassRequest(data));
      } else {
        parseInt(`${checkoutPrice}00`);
        data = {
          gateway: "pagarme",
          payment_method: "credit_card",
          save_card: saveCard ? "True" : "False",
          card_number,
          card_cvv,
          card_expiration_date,
          card_holder_name,
          installments: installment,
          items: checkoutItems,
          checkout_name : location.href.split('/').pop()
        };
        if (sponsorID !== "") {
          data.new_address = sponsorID;
        }
        dispatch(fetchPayment(data));
        //dispatch action de  compra do checkout
      }

      clearState();

      dispatch(setCreditPayModalOpen(false));
    } else {
      handleErrors();
    }
  }

  function handleSaveCard(e) {
    setSaveCard(e.target.checked);
  }

  function handleCallback() {
    return;
  }

  function handleInputFocus({ target }) {
    setFocused(target.name);
  }

  function handleErrors() {
    validNumber === true ? "" : setValidNumber(false);
    validName === true ? "" : setValidName(false);
    validExpirity === true ? "" : setValidExpirity(false);
    validCVC === true ? "" : setValidCVC(false);
  }

  function getStyle() {
    if (qpq) {
      if (responsive) {
        return classes.paperResponsiveQPQ;
      }
      return classes.paperQPQ;
    }
    if (responsive) {
      return classes.paperResponsive;
    }
    return classes.paper;
  }

  function handleInputBlur({ target }) {
    switch (target.name) {
      case "number":
        setValidNumber(validateCreditCardNumber(target.value));
        break;
      case "cvc":
        setValidCVC(validateCVV(target.value, brand));
        break;
      case "expiry":
        setValidExpirity(validateExpiration(target.value));
        break;
      case "name":
        target.value.length ? setValidName(true) : setValidName(false);
        break;
      default:
        break;
    }
  }

  function required(value = String, fieldName = String) {
    switch (fieldName) {
      case "number":
        if (value.length > 0) {
          return "Número do cartão é inválido";
        } else {
          return `Número do cartão é obrigatório`;
        }
      case "expiry":
        if (value.length > 0) {
          return "Validade do cartão é inválida";
        } else {
          return `Validade do cartão é obrigatório`;
        }
      case "name":
        if (value.length > 0) {
          return "Nome do titular é inválido";
        } else {
          return `Nome do titular é obrigatório`;
        }

      case "cvc":
        if (value.length > 0) {
          return "Código de verificação é inválido";
        } else {
          return `Código de verificação é obrigatório`;
        }
      default:
        return;
    }
  }

  function formatPrice(value = Number, checkout) {
    if (value === undefined || null) {
      return "0,00";
    }

    return value.toFixed(2).replace(".", ",");
  }

  const formRef = useRef(null);

  return (
    <div>
      <Dialog
        maxWidth={"md"}
        open={open}
        disableBackdropClick={false}
        fullScreen={responsive}
        id={"creditcardModal"}
        scroll={"paper"}
      >
        <DialogContent
          id="content"
          classes={{
            root: getStyle(),
          }}
          dividers={0}
        >
          <DialogHeader responsive={responsive}>
            {responsive && (
              <DialogHeaderButtonMobile>
                <IconButton
                  className={classes.close}
                  aria-label="Fechar"
                  onClick={() => actionCreator()}
                >
                  <ArrowBack />
                </IconButton>
              </DialogHeaderButtonMobile>
            )}
            <DialogHeaderTitle>
              <span>{qpq ? "Pagar Aula" : title}</span>
            </DialogHeaderTitle>
            {!responsive && (
              <DialogHeaderButton>
                <IconButton
                  className={classes.close}
                  aria-label="Fechar"
                  onClick={() => actionCreator()}
                >
                  <Close />
                </IconButton>
              </DialogHeaderButton>
            )}
          </DialogHeader>

          <Info>Informe os dados do seu cartão</Info>

          <CardPreview>
            <Cards
              number={number}
              name={name}
              expiry={expiry}
              cvc={cvc}
              focused={focused}
              placeholders={{
                name: "SEU NOME",
              }}
              callback={handleCallback}
            />
          </CardPreview>

          <form
            className={qpq ? classes.qpqForm : classes.form}
            ref={formRef}
            onSubmit={(e) => handleSubmit(e)}
          >
            <CardNumber>
              <InputContainer>
                <TextField
                  classes={{
                    root:
                      validNumber === "initial"
                        ? classes.inputFieldStart
                        : validNumber
                        ? classes.inputFieldCorrect
                        : classes.inputFieldStart,
                  }}
                  variant="outlined"
                  id="cardnumber"
                  name="number"
                  type="tel"
                  label="NÚMERO DO CARTÃO"
                  error={validNumber === "initial" ? false : !validNumber}
                  onChange={(e) => handleInputChange(e)}
                  onFocus={(e) => handleInputFocus(e)}
                  onBlur={(e) => handleInputBlur(e)}
                />
                {validNumber === false && (
                  <ErrorLog>{required(number, "number")}</ErrorLog>
                )}
              </InputContainer>
            </CardNumber>
            <CardName>
              <InputContainer>
                <TextField
                  classes={{
                    root:
                      validName === "initial"
                        ? classes.inputFieldStart
                        : validName
                        ? classes.inputFieldCorrect
                        : classes.inputFieldStart,
                  }}
                  variant="outlined"
                  id="name"
                  name="name"
                  type="text"
                  label="NOME"
                  error={validName === "initial" ? false : !validName}
                  onChange={(e) => handleInputChange(e)}
                  onFocus={(e) => handleInputFocus(e)}
                  onBlur={(e) => handleInputBlur(e)}
                />
                {validName === false && (
                  <ErrorLog>{required(name, "name")}</ErrorLog>
                )}
              </InputContainer>
            </CardName>
            <Row>
              <InputSm>
                <TextField
                  classes={{
                    root:
                      validExpirity === "initial"
                        ? classes.inputFieldStart
                        : validExpirity
                        ? classes.inputFieldCorrect
                        : classes.inputFieldStart,
                  }}
                  variant="outlined"
                  id="expiry"
                  name="expiry"
                  type="text"
                  label="VALIDADE"
                  error={validExpirity === "initial" ? false : !validExpirity}
                  onChange={(e) => handleInputChange(e)}
                  onFocus={(e) => handleInputFocus(e)}
                  onBlur={(e) => handleInputBlur(e)}
                />
                {validExpirity === false && (
                  <ErrorLog>{required(expiry, "expiry")}</ErrorLog>
                )}
              </InputSm>
              <InputSm>
                <TextField
                  classes={{
                    root:
                      validCVC === "initial"
                        ? classes.inputFieldStart
                        : validCVC
                        ? classes.inputFieldCorrect
                        : classes.inputFieldStart,
                  }}
                  variant="outlined"
                  id="cvc"
                  type="tel"
                  label="CVC"
                  name="cvc"
                  error={validCVC === "initial" ? false : !validCVC}
                  onChange={(e) => handleInputChange(e)}
                  onFocus={(e) => handleInputFocus(e)}
                  onBlur={(e) => handleInputBlur(e)}
                />
                {validCVC === false && (
                  <ErrorLog>{required(cvc, "cvc")}</ErrorLog>
                )}
              </InputSm>
            </Row>
            {!qpq && (
              <InstallmentsContainer>
                <InputContainer>
                  <Select
                    classes={{
                      root: classes.selectRoot,
                      select: classes.selectContent,
                    }}
                    value={installment}
                    onChange={handleChange}
                    disableUnderline={true}
                    name="parcelas"
                  >
                    {installmentsOptions.map((installment, index) => {
                      return (
                        <MenuItem
                          key={installment.value}
                          value={installment.value}
                        >
                          {installment.text}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </InputContainer>
              </InstallmentsContainer>
            )}
            <CheckboxContainer qpq={qpq}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="savecard"
                    value={saveCard}
                    onChange={(e) => handleSaveCard(e)}
                    defaultChecked={true}
                  />
                }
                label={"Deseja salvar este cartão para compras futuras?"}
                classes={{
                  root: classes.checkbox,
                  label: classes.checkboxLabel,
                }}
              />
            </CheckboxContainer>
            <PaymentButtonContainer qpq={qpq}>
              <Button
                type="submit"
                color="primary"
                className={classes.paymentButton}
              >
                {qpq
                  ? `Pagar Aula R$${formatPrice(qpq_price, false)}`
                  : `Pagar`}
              </Button>
            </PaymentButtonContainer>
            <LockIconContainer qpq={qpq}>
              <Icon className={classes.lockIcon}>lock</Icon>
              <span className={classes.lockIconLabel}>Ambiente Seguro</span>
            </LockIconContainer>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withStyles(styles, { index: 1 })(CreditPaymentModal);
