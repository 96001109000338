import React, { Component } from 'react'
import moment from 'moment'
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import _ from 'lodash'
import { ElementList, ProfilePicture } from '../common'
import { LikeButton } from '../common/ui'
import { renderInput } from '../../utils/form';
import { sendComment as sendCommentAction } from '../../actions/commentsActions';
import CommentChild from './CommentChild'

class CommentItem extends Component {
    constructor(props) {
        super(props)

        this.state = {
            openComment: false
        }
    }

    handleSendComment = (comment) => {
        const { modalData, _id, userId } = this.props.modal
        const { id } = this.props
        this.props.reset()
        this.props.sendComment(_id, {comment: comment[`commentChild-${id}`]}, modalData, id, userId);
    }

    render() {
        const { comment, likes = {}, handleSubmit, submitting, id, commentList, userSocialId, profile = {} , firstName, lastName,
            created_at, likeComment, modal: {modalData, _id} } = this.props
        const { openComment } = this.state
        const childList = _.filter(commentList, (o) => o.parent_id === id)
        const likeList = _.keys(likes)
        const liked = likeList.includes(profile._id.toString())
        return (
            <li>
                <div>
                    <ProfilePicture userSocialId={userSocialId} />
                </div>

                <div>
                    <div className="comment--name">{`${firstName} ${lastName}`}</div>
                    <div className="comment--time">{moment(created_at).fromNow()}</div>

                    <div className="comment--text">{comment}</div>

                    <div className="comment--actions">
                        <LikeButton onPress={() => likeComment(_id, modalData, liked, id)} likes={likeList} loggedUserId={profile._id} />
                        <button onClick={() => this.setState({openComment: !openComment})} className={`comment--comments ${openComment ? 'is-active' : ''}`}>responder</button>
                    </div>
                </div>

                { childList.length > 0 ? (
                    <ElementList 
                        clearPadding={false}
                        data={childList}
                        type="comment--child"
                        renderRow={(props) => <CommentChild {...props} commentList={childList} modalData={modalData} likeComment={likeComment} _id={_id} profile={profile} />}/>) : null }
                {
                    
                    openComment ? ( 
                        <div className="comment--area" style={{marginTop: '0px', padding: '10px 10px', width: '100%'}}>
                            <div>
                                <ProfilePicture userSocialId={profile.facebookId} />
                            </div>

                            <div>
                                <form onSubmit={handleSubmit(this.handleSendComment)}>
                                    <Field
                                        inputType="commentChild"
                                        name={`commentChild-${id}`}
                                        component={renderInput}
                                        placeholder="Resposta"
                                        disabled={submitting}
                                    /> 
                                    <button className={`btn btn-action ${ submitting && 'btn-disabled' }`} type="submit" disabled={submitting} >responder</button>
                                </form>
                            </div>
                        </div>    
                    ) : null 
                }
            </li>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
  return  {
    submitting: state.waiting,
    modal: state.modal,
    commentList: state.comments.list
  }
}

const enhance = compose(
    reduxForm({
        form: 'commentChild',
        validate: (values, props) => {
            const errors = {};
            if (!values.comment) errors.comment = 'Digite um comentário.';
            return errors;
        },
    }),
    connect(mapStateToProps, { sendComment : sendCommentAction }),
);

export default enhance(CommentItem)