import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'material-ui/styles';
import compose from 'recompose/compose';
import Dialog from '../../components/ModalScreen';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import IconButton from 'material-ui/IconButton';
import Button from 'material-ui/Button';
import Slide from 'material-ui/transitions/Slide';
import ArrowLeft from 'material-ui-icons/ChevronLeft';
import Tooltip from 'material-ui/Tooltip';
import TimerIcon from 'material-ui-icons/AccessTime';
import Profile from 'material-ui-icons/Person';
import {
  crudGetOne as crudGetOneAction,
  crudGetList as crudGetListAction,
  crudGetListByOneAction,
} from '../../actions/dataActions';
import BibliografiaContent from './BibliografiaContent';
import { setModalData as setModalDataAction } from '../../actions/modalActions';
import {
  materialCheckUserStatus as materialCheckUserStatusAction,
  changeEditalTopics as changeEditalTopicsAction,
} from '../../actions/organizadorActions';
import { subscribeComments as subscribeCommentsAction } from '../../actions/commentsActions';
import ModalRoteiro from './ModalRoteiro';
import ModalQuestoes from '../Questoes';
import ModalVideo from './ModalVideo';
import ModalGabarito from '../Simulados/ModalGabarito';
import Drawer from '../../components/DrawerBibliografia';
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';
import _ from 'lodash';

const modalData = { type: 'biblioSubjectMaterial', id: 'forum' };

const styles = (theme) => ({
  appBar: {
    top: 0,
    left: 'auto',
    right: 0,
    backgroundColor: '#FFF',
    boxShadow: '0 1px 0 0 #E8E8E8',
    transition: theme.transitions.create('width'),
    height: '65px',
    paddingRight: '24px',
    justifyContent: 'center',
  },
  backContainer: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      padding: '18px',
    },
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  backIcon: {
    ...theme.typography.menu,
    color: theme.palette.text.clear,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '8px',
    paddingLeft: '0px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  responsiveDisplay: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inherit',
    },
  },
  content: {
    marginTop: '65px',
    flex: 1,
    marginLeft: '8px',
    marginRight: '8px',
  },
  toolbar: {
    padding: '0px',
  },
  ajuda: {
    fontWeight: 600,
    lineHeight: '14px',
    fontSize: '12px',
    color: theme.palette.text.clear,
  },
  icon: {
    color: theme.palette.text.clear,
  },
  titleContainer: {
    paddingTop: '4px',
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    ...theme.typography.headline,
    lineHeight: '1.1em',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: theme.palette.text.clear,
  },
  subtitle: {
    ...theme.typography.body1,
    textTransform: 'uppercase',
    display: 'none',
    flex: 1,
    textAlign: 'center',
    color: theme.palette.text.clear,
    lineHeight: '1rem',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '1.8rem',
    margin: '0 auto',
    fontSize: '12px',
  },
  menuIcons: {
    display: 'flex',
    color: theme.palette.text.clear,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  dialog: {
    alignItems: 'initial',
  },
  slide: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'auto',
    width: '100%',
  },
  paper: {
    overflowY: 'hidden',
  },
});

class ItemBibliografia extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileOpen: false,
      roteiro: false,
      video: false,
    };
  }

  componentDidMount() {
    if (this.props.openId && this.props.questaoId) {
      const { questions } = this.props.data.material;
      this.setState({
        questoes: { _id: this.props.questaoId, name: questions[0].name },
      });
    } else if (this.props.openId) {
      this.props.crudGetOne(this.props.openId);
    }
  }

  handleRoteiro = (roteiro) => {
    const { trackMetric, openId } = this.props;
    this.setState({ roteiro });

    if (roteiro) {
      trackMetric('Bibliografia - Ativação', {
        target: 'roteiro',
        trigger: 'click',
        parentResource: openId,
        resource: roteiro._id,
        filter: location.href.split('/').pop(),
      });
    }
  };

  handleQuestoes = (test) => {
    const { trackMetric, openId } = this.props;
    this.setState({ questoes: test });
    if (test) {
      const path = window.location.pathname;
      this.props.pushRouter(`${path}/${test._id}`);
      trackMetric('Bibliografia - Ativação', {
        target: 'test',
        trigger: 'click',
        parentResource: openId,
        resource: test._id,
        filter: location.href.split('/').pop(),
      });
    }
  };

  handleVideo = (video) => {
    const { trackMetric, openId } = this.props;
    this.setState({ video });

    if (video) {
      trackMetric('Bibliografia - Ativação', {
        target: 'link',
        trigger: 'click',
        parentResource: openId,
        resource: video._id,
        resourceName: 'videos',
        filter: location.href.split('/').pop(),
      });
    }
  };

  transition = (props) => {
    return (
      <Slide direction='up' {...props} className={this.props.classes.slide} />
    );
  };

  closeDrawer = () => this.setState({ mobileOpen: false });

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.openId !== nextProps.openId && Boolean(nextProps.openId)) {
      this.props.crudGetOne(nextProps.openId);
    }
    if (
      nextProps.modalGabarito &&
      this.props.modalGabarito !== nextProps.modalGabarito
    ) {
      this.handleQuestoes(false);
      this.props.crudGetOne(nextProps.openId);
    }
    if (this.props.data !== nextProps.data && Boolean(nextProps.data)) {
      var subjectId = nextProps.data.material.subject._id;
      var week = nextProps.data.material.week;
      var listId = this.props.exhibition === 'byweek' ? week : subjectId;

      this.props.crudGetListByOne(listId, this.props.exhibition);
      this.props.crudGetList(this.props.exhibition);
    }
  }

  track = () => {
    this.props.trackMetric('Clicou em ajuda', { url: location.href });
    window.open('http://ajuda.clippingcacd.com.br/', '_blank');
  };

  openTimer = () => {
    const { concurso } = this.props;
    const token =
      localStorage.token && localStorage.token.replace('Bearer ', '');
    this.props.trackMetric('Clicou no cronômetro', { url: location.href });
    window.open(
      `https://clippingcacd.com.br/timer/${
        concurso && concurso.active.name
      }?token=${token}`,
      '_blank'
    );
  };

  render() {
    const { roteiro, questoes = {}, video } = this.state;
    const {
      openId,
      handleOpen,
      classes,
      data = {},
      setModalData,
      subscribeComments,
      materialCheckUserStatus,
      exhibition,
      changeEditalTopics,
      user = {},
      modalGabarito,
      handleModalGabarito,
    } = this.props;
    const { material } = data;

    return (
      <Dialog open={Boolean(openId)} handleOpen={handleOpen}>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.backContainer}>
              <Button
                disableRipple
                disableFocusRipple
                aria-label='voltar'
                onClick={() => handleOpen(false)}
                className={classes.backIcon}
              >
                <ArrowLeft />{' '}
                <div className={classes.responsiveDisplay}>voltar</div>
              </Button>
            </div>
            <div className={classes.titleContainer}>
              <div className={classes.title}>
                {material && material.subject ? material.subject.name : ''}
              </div>
              <div className={classes.subtitle}>
                {material ? material.title : ''}
              </div>
            </div>
            <div className={classes.menuIcons}>
              <Tooltip
                title='Ir para central de ajuda'
                enterDelay={300}
                className={classes.responsiveDisplay}
              >
                <Button
                  color='primary'
                  className={classes.ajuda}
                  onClick={this.track}
                >
                  Ajuda
                </Button>
              </Tooltip>
              <Tooltip
                title='Cronômetro de estudos'
                enterDelay={300}
                className={classes.responsiveDisplay}
              >
                <IconButton aria-label='change theme' onClick={this.openTimer}>
                  <TimerIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip
                title='Ajustes do perfil'
                enterDelay={300}
                className={classes.responsiveDisplay}
              >
                <IconButton
                  aria-label='change direction'
                  onClick={() => this.props.pushRouter('/perfil')}
                >
                  <Profile className={classes.icon} />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          pushRouter={handleOpen}
          location={location}
          className={classes.drawer}
          disablePermanent={false}
          onRequestClose={this.handleDrawerToggle}
          mobileOpen={this.state.mobileOpen}
          closeDrawer={this.closeDrawer}
          exhibition={exhibition}
          material={material}
          items={this.props.items}
          navItems={this.props.navItems}
        />

        <BibliografiaContent
          {...this.props.data}
          userId={user._id}
          setModalData={setModalData}
          subscribeComments={subscribeComments}
          changeEditalTopics={changeEditalTopics}
          handleRoteiro={this.handleRoteiro}
          handleVideo={this.handleVideo}
          handleQuestoes={this.handleQuestoes}
          materialCheckUserStatus={materialCheckUserStatus}
        />

        <ModalRoteiro open={roteiro} handleOpen={this.handleRoteiro} />
        <ModalQuestoes
          open={questoes._id}
          name={questoes.name}
          handleOpen={this.handleQuestoes}
          bibliografia={openId}
          from={'plano-de-estudos'}
        />
        <ModalVideo open={video} handleOpen={this.handleVideo} />
        <ModalGabarito
          open={modalGabarito}
          handleOpen={handleModalGabarito}
          exercicio={material ? material.title : ''}
        />
        {/* <ModalForum open={true} /> */}
      </Dialog>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  var data = state.biblioSubjectMaterial.data[ownProps.openId];
  var items = state[`biblioSubjectMaterial/${ownProps.exhibition}`].data;

  if (Boolean(data) && Boolean(items)) {
    items = _.values(items)
      .filter((x) =>
        ownProps.exhibition === 'byweek'
          ? x.week === data.material.week
          : x.subject._id === data.material.subject._id
      )
      .reduce((obj, x) => {
        return { ...obj, [x._id]: x };
      }, {});
  } else {
    items = [];
  }
  return {
    user: state.user.profile,
    data,
    concurso: state.concurso,
    items,
    navItems: state[`biblioSubject/${ownProps.exhibition}`].data,
  };
};

const mapDispatchToProps = {
  crudGetOne: (_id) =>
    crudGetOneAction('biblioSubjectMaterial', `${_id}/user`, '', false),
  crudGetListByOne: (_id, exhibition) =>
    crudGetListByOneAction(
      `biblioSubjectMaterial/${exhibition}`,
      _id,
      '',
      false
    ),
  crudGetList: (exhibition) =>
    crudGetListAction(
      `biblioSubject/${exhibition}`,
      { page: 1, perPage: 100 },
      { field: 'name', order: 'ASC' },
      false
    ),
  setModalData: (data) => setModalDataAction(data, modalData),
  subscribeComments: subscribeCommentsAction,
  materialCheckUserStatus: materialCheckUserStatusAction,
  changeEditalTopics: changeEditalTopicsAction,
  trackMetric: (name, data) => trackMetricAction(name, data),
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ItemBibliografia);
