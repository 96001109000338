import React, { Component } from 'react';
import { withStyles } from 'material-ui/styles';
import compose from 'recompose/compose';
import { connect, useSelector } from 'react-redux';
import { ElementList } from '../../components/common';
import QuestionList from './QuestionList';
import Questao from '../Questoes/Questao';
import { loginDisqus as loginDisqusAction } from '../../actions/commentsActions';
import { Disqus } from '../../components';
import ItemGabarito from './ItemGabarito';
import FeedbackPopover from '../../components/FeedbackPopover';
import Comments from '../../components/NewComments/Comments';
import { CircularProgress } from 'material-ui';
import { LoadingContainer } from '../../components/NewComments/Comments/styles';

const styles = (theme) => ({
  wrapper: {
    background: '#f9f9f9',
    overflow: 'auto',
    overflowX: 'hidden',
    height: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    padding: '8px',
  },
  questionList: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      width: '305px',
    },
  },
  content: {
    marginBottom: '100px',
    height: 'max-content',
    margin: '0 auto 100px auto',
    '@media (min-width: 1600px)': {
      position: 'absolute',
      left: 'calc(50% - 480px)',
    },
  },
  comments: {
    marginTop: '16px',
    marginBottom: '20px',
    maxWidth: '970px',
  },
  feedback: {
    marginTop: '25px',
    marginBottom: '30px',
  },
  notaContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '150px',
    margin: '0 auto',
    borderBottom: '1px solid rgba(151, 151, 151, 0.3)',
    paddingBottom: '20px',
  },
  nota: {
    ...theme.typography.display1,
    fontWeight: 'bold',
  },
  notaText: {
    ...theme.typography.body1,
    color: theme.palette.text.basic,
    marginTop: '-8px',
  },
  title: {
    ...theme.typography.body3,
    fontSize: '15px',
    color: theme.palette.text.clear,
    textAlign: 'center',
    paddingTop: '18px',
  },
});

const GabaritoContent = ({ classes, gabaritoId }) => {
  const test = useSelector((state) => state.test.data[gabaritoId]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.questionList}>
          {test ? (
            <QuestionList test={test} />
          ) : (
            <LoadingContainer>
              <CircularProgress style={{ color: '#F58220' }} />
            </LoadingContainer>
          )}
        </div>
        {test && (
          <div className={classes.content}>
            <div className={classes.list}>
              {test.questions.map((question, index) => {
                return (
                  <Questao
                    {...question}
                    _key={index}
                    key={question._id}
                    questions={test.questions}
                  >
                    <ItemGabarito />
                  </Questao>
                );
              })}
            </div>

            <div className={classes.comments}>
              <h1
                style={{
                  fontSize: '22px',
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  color: '#585858',
                }}
              >
                Discussão
              </h1>
              {gabaritoId && (
                <Comments
                  objectResource={{ _id: gabaritoId }}
                  resource={'tests'}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default compose(withStyles(styles))(GabaritoContent);
