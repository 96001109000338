import React from "react";
import styled from "styled-components";

import CalendarIcon from "../../components/SvgComponents/CalendarIcon.js";

const ClassDateContainer = styled.div`
  margin-top: 8px;
  width: fit-content;
  height: 32px;
  border-radius: 4px;
  background: ${(props) =>
    props.classTempo === "live" ? "rgb(255, 225, 225)" : "rgb(238, 238, 238)"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
  margin-bottom: ${(props) => props.responsive && "30px"};
`;

const ClassDateContent = styled.span`
  margin-left: 8px;
  width: fit-content;
  font-family: "Proxima Nova", sans-serif;
  font-size: 11px;
  font-weight: 500;
  height: 13px;
  letter-spacing: 0px;
  line-height: 13px;
  text-transform: uppercase;
  color: ${(props) =>
    props.classTempo === "live" ? "rgb(254, 63, 75)" : "rgba(0, 0, 0, 0.5)"};
`;

export default function ClassDate({
  date,
  time,
  classTempo,
  responsive = false,
}) {
  return (
    <ClassDateContainer responsive={responsive} classTempo={classTempo}>
      <CalendarIcon
        width={"18px"}
        height={"18px"}
        color={classTempo === "live" ? "rgb(254, 63, 75)" : "rgba(0, 0, 0, 0.5)"}
      />
      <ClassDateContent classTempo={classTempo}>
        {classTempo === "live"
          ? `Hoje às ${time}h`
          : classTempo === "before"
          ? `Ao vivo ${date} às ${time}h`
          : classTempo === "after" && `Disponível até ${date}`}
      </ClassDateContent>
    </ClassDateContainer>
  );
}
