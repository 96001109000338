import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import {
  makeStyles,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@material-ui/core";

import InputMask from "react-input-mask";
import StepButton from "./StepButton";

import CPFCheck from "./utils";

import { nextStep, stepLoading } from "./actions";
import { saveSponsorPersonalData } from "../Payments/actions";

const styles = makeStyles({
  inputFieldStart: {
    width: "100%",
    "& label.Mui-focused": {
      color: "rgba(0,0,0, 0.6)",
    },
    "& label": {
      fontFamily: "Proxima Nova",
    },
    "&:hover label": {
      color: "rgba(0,0,0, 0.6)",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "rgba(0,0,0, 0.6)",
      },
      "&.Mui-focused fieldset": {
        fontFamily: "Proxima Nova",
        borderColor: "rgba(0,0,0, 0.6)",
      },
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: 0,
      color: "#f44336",
    },
  },
});

const Container = styled.div`
  width: 100%;
`;

const Form = styled.form`
  padding: 16px;
`;

const InputContainer = styled.div`
  height: 56px;
  margin-bottom: 24px;
  width: 100%;
  display: flex;
`;

const StepButtons = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  width: 100%;
  margin-top: 8px;
  padding-right: 16px;
  margin-bottom: 16px;
`;

const patterns = {
  name: /./,
  rg: /./,
  ddd: /[0-9]{2}/,
  cpf: /[0-9]{11}/,
  email: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  mobile: /[0-9]{11}/,
  //   olderCpf: /[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}/,
  //   olderMobile: /(\([0-9]{2}\)) ([0-9]{5})-([0-9]{4})/,
};

function PersonalDataForm() {
  const classes = styles();
  const dispatch = useDispatch();

  const { personalData } = useSelector(
    (state) => state.paymentGroup.creditCardSponsor
  );

  const [email, setEmail] = useState(personalData.email || "");
  const [name, setName] = useState(personalData.name || "");
  const [cpf, setCPF] = useState(personalData.cpf || "");
  const [rg, setRg] = useState(personalData.rg || "");
  const [mobile, setMobile] = useState(personalData.mobile || "");

  const [invalidCpf, setInvalidCpf] = useState(false);
  const [invalidRg, setInvalidRg] = useState(false);
  const [invalidMobile, setInvalidMobile] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const handleInputChange = (ev) => {
    switch (ev.target.name) {
      case "name":
        return setName(ev.target.value);
      case "email":
        return setEmail(ev.target.value);
      case "cpf":
        let userCpf = ev.target.value.replace(/[.-]/gm, "");
        return setCPF(userCpf);
      case "rg":
        return setRg(ev.target.value);
      case "mobile":
        let mobileNumber = ev.target.value.replace(/[\\(\\) -]/gm, "");
        return setMobile(mobileNumber);
      default:
        break;
    }
  };

  const validateEmail = () => {
    const validation = RegExp(patterns.email).test(email);

    setInvalidEmail(!validation);

    return validation;
  };

  const validateCPF = () => {
    const validation = RegExp(patterns.cpf).test(cpf);

    if (validation) {
      const formattedCPF = cpf.replace(/\D/g, "");
      const validCPF = CPFCheck(formattedCPF);

      setInvalidCpf(!validCPF);

      return validCPF;
    }

    setInvalidCpf(!validation);
    return validation;
  };

  const validateMobile = () => {
    const validation = RegExp(patterns.mobile).test(mobile);

    setInvalidMobile(!validation);

    return validation;
  };

  const validateName = () => {
    const validation = RegExp(patterns.name).test(name);

    setInvalidName(!validation);

    return validation;
  };

  const validateRg = () => {
    // const validation = RegExp(patterns.rg).test(rg);

    // setInvalidRg(!validation);

    // return validation;
    return true;
  };

  const handleNextStep = async () => {
    dispatch(stepLoading(true));
    const validName = validateName();
    const validEmail = validateEmail();
    const validCPF = validateCPF();
    const validMobile = validateMobile();
    const validRg = validateRg();

    if (validName && validEmail && validCPF && validMobile && validRg) {
      dispatch(saveSponsorPersonalData({ name, email, cpf, mobile, rg }));
      dispatch(stepLoading(false));
      dispatch(nextStep());
    }
    dispatch(stepLoading(false));
  };

  return (
    <Container>
      <Form noValidate>
        <InputContainer>
          <FormControl
            variant="outlined"
            fullWidth
            classes={{
              root: classes.inputFieldStart,
            }}
          >
            <InputLabel htmlFor="name">Nome</InputLabel>
            <OutlinedInput
              id="name"
              name="name"
              variant="outlined"
              label="Nome"
              value={name}
              error={invalidName}
              onChange={handleInputChange}
              onBlur={validateName}
            />
            <FormHelperText>{invalidName && "Nome inválido"}</FormHelperText>
          </FormControl>
        </InputContainer>
        <InputContainer>
          <FormControl
            variant="outlined"
            fullWidth
            classes={{
              root: classes.inputFieldStart,
            }}
          >
            <InputLabel htmlFor="email">E-mail</InputLabel>
            <OutlinedInput
              id="email"
              name="email"
              variant="outlined"
              label="E-mail"
              value={email}
              error={invalidEmail}
              onChange={handleInputChange}
              onBlur={validateEmail}
            />
            <FormHelperText>{invalidEmail && "Email inválido"}</FormHelperText>
          </FormControl>
        </InputContainer>
        <InputContainer>
          <FormControl
            variant="outlined"
            fullWidth
            classes={{
              root: classes.inputFieldStart,
            }}
          >
            <InputLabel htmlFor="cpf">CPF</InputLabel>
            <InputMask
              mask="999.999.999-99"
              value={cpf}
              onChange={handleInputChange}
              onBlur={() => validateCPF()}
            >
              {() => (
                <OutlinedInput
                  id="cpf"
                  name="cpf"
                  label="CPF"
                  variant="outlined"
                  error={invalidCpf}
                />
              )}
            </InputMask>
            <FormHelperText>{invalidCpf && "CPF inválido"}</FormHelperText>
          </FormControl>
        </InputContainer>
        <InputContainer>
          <FormControl
            variant="outlined"
            fullWidth
            classes={{
              root: classes.inputFieldStart,
            }}
          >
            <InputLabel htmlFor="rg">RG</InputLabel>
            <OutlinedInput
              id="rg"
              name="rg"
              variant="outlined"
              label="RG"
              value={rg}
              error={invalidRg}
              onChange={handleInputChange}
              onBlur={validateRg}
            />
            <FormHelperText>{invalidRg && "RG inválido"}</FormHelperText>
          </FormControl>
        </InputContainer>
        <InputContainer>
          <FormControl
            variant="outlined"
            fullWidth
            classes={{
              root: classes.inputFieldStart,
            }}
          >
            <InputLabel htmlFor="ddd">Tel. Celular</InputLabel>
            <InputMask
              mask="(99) 99999-9999"
              value={mobile}
              on
              onChange={handleInputChange}
              onBlur={() => validateMobile()}
            >
              {() => (
                <OutlinedInput
                  id="mobile"
                  name="mobile"
                  label="Tel. Celular"
                  variant="outlined"
                  error={invalidMobile}
                />
              )}
            </InputMask>
            <FormHelperText>
              {invalidMobile && "Telefone inválido"}
            </FormHelperText>
          </FormControl>
        </InputContainer>
      </Form>
      <StepButtons>
        {/* <StepButton
                name="previousStep"
                text="Etapa anterior"
                clickFn={handlePreviousStep}
                disabled
            /> */}
        <StepButton
          name="nextStep"
          text="Próxima etapa"
          clickFn={handleNextStep}
        />
      </StepButtons>
    </Container>
  );
}

export default PersonalDataForm;
