import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import DoughnutChart from './DoughnutChart';
import AlternativesBarChart from './BarChart';

import * as S from './styles';

import ArrowUp from 'material-ui-icons/ArrowDropUp';
import ArrowDown from 'material-ui-icons/ArrowDropDown';

import {
  DescriptionOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  BarChart,
  Cancel,
  CheckCircle,
  ErrorOutline,
  Error,
} from '@material-ui/icons';
import { BsChatLeft } from 'react-icons/bs';

import { CircularProgress } from '@material-ui/core';

import { AnswerFlag, AnswerFlagWithDatetime } from './AnswerFlag';

import api from '../../services/api';
import ReportErrorArea from './ReportErrorArea/ReportErrorArea';
import Comments from '../../components/NewComments/Comments';
import CardFooter from './CardFooter';
import {
  getCommentsByTopic,
  getCounterCommentsByTopic,
  createCommentClipping,
} from '../../services/comments';
import { MdOutlineSchool } from 'react-icons/md';

function Card({ question }) {
  const profile = useSelector((state) => state.user.profile);
  const user = useSelector((state) => state.user);
  const responsive = useSelector((state) => state.plataform.responsive);
  const concursoId = useSelector((state) => state.concurso.active.concursoId);

  const [itemAnswer, setItemAnswer] = useState(null);
  const [showComments, setShowComments] = useState(false);
  const [questionAnswer, setQuestionAnswer] = useState(null);
  const [localQuestion, setQuestion] = useState(question);
  const [showSupportingText, setShowSupportingText] = useState(false);
  const [footerContent, setFooterContent] = useState(false);
  const [handledAnswer, setHandledAnswer] = useState(false);
  const [commentsCounter, setCommentsCounter] = useState('');

  function getQuestionInfos() {
    let info = [];

    if (question.board) info.push(question.board);

    if (question.subject) info.push(question.subject);

    if (question.year) info.push(question.year);

    return (
      <div>
        {info.map((el, i) => (
          <span key={i}>
            <label>{el}</label>

            {i < info.length - 1 && <span>/</span>}
          </span>
        ))}
      </div>
    );
  }

  async function handleAnswer() {
    if (!itemAnswer) {
      return;
    }

    const payload = {
      user: profile._id,
      questionItem: question._id,
      answer: itemAnswer,
      correct: itemAnswer === question.correct,
    };

    await api.post('/questionItemLooseAnswer', {
      ...payload,
    });

    const _question = {
      ...question,
      answer: {
        correct: itemAnswer === question.correct,
        answer: question.correct,
        comment: question.comment,
      },
    };

    setQuestion(_question);
    setHandledAnswer(true);
  }

  function handleFooterContent(content) {
    return () => setFooterContent(content);
  }

  function handleAccordionFooterContent(content) {
    return () => {
      if (footerContent === content) return setFooterContent(false);

      setFooterContent(content);
    };
  }

  async function createClippingComment() {
    setShowComments(true);
    try {
      const { data } = await getCommentsByTopic(
        `questions_${question._id}`,
        0,
        8
      );

      const comments = data[0].docs;

      if (
        !localQuestion.comment
          .replace(/<[^>]p>/g, '\n\n')
          .replace(/<[^>]*>/g, '')
          .replaceAll('&nbsp;', ' ') === comments[0].text ||
        !comments[0].flagged === true
      ) {
        const { data: createdCommentClipping } = await createCommentClipping({
          text: localQuestion.comment
            .replace(/<[^>]p>/g, '\n\n')
            .replace(/<[^>]*>/g, '')
            .replaceAll('&nbsp;', ' '),
          parentComment: null,
          topic: `questions_${localQuestion._id}`,
        });
        setShowComments(false);
        createClippingComment();
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        const { data: createdCommentClipping } = await createCommentClipping({
          text: localQuestion.comment
            .replace(/<[^>]p>/g, '\n\n')
            .replace(/<[^>]*>/g, '')
            .replaceAll('&nbsp;', ' '),
          parentComment: null,
          topic: `questions_${localQuestion._id}`,
        });
        setShowComments(false);
        createClippingComment();
      }
      console.log(error);
    }
  }

  async function getCommentsCount() {
    const { data: counter } = await getCounterCommentsByTopic(
      `questions_${question._id}`
    );
    if (counter === 0) {
      setCommentsCounter('(1)');
    } else {
      setCommentsCounter(`(${counter})`);
    }
  }

  function toggleSupportingText() {
    setShowSupportingText(!showSupportingText);
  }

  function CardChartContent() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    const LastAnswerItem = ({ answer }) => {
      const date = new Date(answer.createdAt);

      const pad = (num) =>
        num < 10 ? '0' + Number(num).toString() : Number(num).toString();

      let answerLabel = {};

      localQuestion.alternatives.map((value) => {
        if (localQuestion.alternatives.length === 3) {
          answerLabel[value.name] = value.text;
        } else {
          answerLabel[value.name] = value.name;
        }
      });

      let dateFormated = `${pad(date.getDate())}/${pad(
        date.getMonth() + 1
      )}/${date.getFullYear().toString().substring(2)} às ${pad(
        date.getHours()
      )}:${pad(date.getMinutes())}`;

      return (
        <S.LastAnswerItem>
          <span>
            Em {dateFormated}, você respondeu "{answerLabel[answer.answer]}".
          </span>
          {localQuestion.annulled ? (
            <S.AnswerLabel annulled={localQuestion.annulled}>
              <div>
                <Error />
                <span>Item anulado</span>
              </div>
            </S.AnswerLabel>
          ) : (
            <S.AnswerLabel correct={answer.correct}>
              {answer.correct ? (
                <div>
                  <CheckCircle />
                  <span>Você acertou!</span>
                </div>
              ) : (
                <div>
                  <Cancel />
                  <span>Você errou!</span>
                </div>
              )}
            </S.AnswerLabel>
          )}
        </S.LastAnswerItem>
      );
    };

    useEffect(() => {
      async function getStatistics() {
        setLoading(true);

        try {
          const { data } = await api.get(
            '/questionItemLooseAnswer/statistics/' + question._id
          );

          const doughnut = {
            labels: ['Acertos', 'Erros'],
            datasets: [
              {
                data: [
                  localQuestion.annulled ? 0 : data.correct,
                  localQuestion.annulled ? 0 : data.wrong,
                  localQuestion.annulled ? 1 : 0,
                ],
                backgroundColor: ['#2DB87E', '#F7626B', '#CCCCCC'],
                hoverBorderColor: ['#FFFFFF', '#FFFFFF', '#FFFFFF'],
                spacing: localQuestion.annulled ? null : 0.5,
              },
            ],
          };

          const labels = localQuestion.alternatives.map((v, index) => {
            if (localQuestion.alternatives.length === 3) {
              if (index === 2) {
                return 'Em branco';
              } else {
                return v.text;
              }
            } else {
              return v.name;
            }
          });

          const dataLabel = localQuestion.alternatives.map((v) => {
            return data.countByAnswer[v.name] || 0;
          });

          const bar = {
            labels: labels,
            datasets: [
              {
                data: dataLabel,
                backgroundColor: '#31A9C1',
              },
            ],
          };

          const lastAnswers = data.lastAnswers;

          setData({
            doughnut,
            bar,
            lastAnswers,
          });
        } catch (err) {
          console.error('Falha ao buscar estatísticas da questão', err);
        } finally {
          setLoading(false);
        }
      }

      getStatistics();
    }, []);

    if (loading)
      return (
        <S.CardChartContent
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <CircularProgress color='inherit' style={{ opacity: 0.4 }} />
        </S.CardChartContent>
      );

    return (
      <S.CardChartContent>
        {data?.doughnut && data?.bar && (
          <S.ChartsContainer>
            <div>
              <strong>Percentual de Rendimento</strong>
              <div style={{ maxWidth: '184px', maxHeight: '184px' }}>
                <DoughnutChart
                  data={data.doughnut}
                  localQuestion={localQuestion}
                />
              </div>
            </div>

            <div>
              <strong>Alternativas mais respondidas</strong>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'end',
                  height: '100%',
                  maxHeight: '184px',
                  maxWidth: '100%',
                }}
              >
                <AlternativesBarChart data={data.bar} />
              </div>
            </div>
          </S.ChartsContainer>
        )}

        {data?.lastAnswers && (
          <S.LastAnswersContainer>
            {data.lastAnswers.length > 0 && (
              <strong>Suas últimas 10 respostas</strong>
            )}

            <ul>
              {data.lastAnswers.map((answer, i) => (
                <LastAnswerItem key={i} answer={answer} />
              ))}
            </ul>
          </S.LastAnswersContainer>
        )}
      </S.CardChartContent>
    );
  }

  useEffect(() => {
    getCommentsCount();
    createClippingComment();

    if (question.answer) {
      setQuestionAnswer(question.answer);
      setQuestion({
        ...question,
        answer: {
          ...question.answer,
          correct: question.answer.answer === question.correct,
          answer: question.correct,
          comment: question.comment,
        },
      });
    }
  }, []);

  return (
    <S.CardRoot>
      <S.CardHeader>
        <S.HeaderIcon>
          <DescriptionOutlined />
        </S.HeaderIcon>

        <S.HeaderTitle>{getQuestionInfos()}</S.HeaderTitle>
      </S.CardHeader>

      <S.CardContent>
        {localQuestion.supportingText && localQuestion.supportingText !== '' && (
          <S.SupportingText>
            <S.SupportingTextLink onClick={toggleSupportingText}>
              {showSupportingText ? 'Ocultar ' : 'Exibir '}
              texto de apoio
              {showSupportingText ? <ArrowUp /> : <ArrowDown />}
            </S.SupportingTextLink>

            {showSupportingText && (
              <S.SupportingTextContent
                dangerouslySetInnerHTML={{
                  __html: localQuestion.supportingText,
                }}
              />
            )}
          </S.SupportingText>
        )}

        {questionAnswer && (
          <AnswerFlagWithDatetime
            correct={questionAnswer.correct}
            answer={questionAnswer.answer}
            dateTime={questionAnswer.updatedAt || questionAnswer.createdAt}
            annulled={localQuestion.annulled}
            style={{ marginBottom: responsive ? '8px' : '16px' }}
          />
        )}

        <S.QuestionTextContainer>
          <S.QuestionStatement>
            <S.QuestionText
              dangerouslySetInnerHTML={{ __html: localQuestion.statement }}
            />

            <S.QuestionText
              dangerouslySetInnerHTML={{ __html: localQuestion.text }}
            />
          </S.QuestionStatement>

          <S.QuestionAnswerContainer>
            <S.QuestionAlternatives>
              {localQuestion.alternatives.map((alternative) => (
                <S.Alternative disabled={handledAnswer}>
                  <S.RadioBtn
                    disabled={handledAnswer}
                    checked={itemAnswer === alternative.name}
                    value={alternative.name}
                    onChange={() => setItemAnswer(alternative.name)}
                  />
                  {localQuestion.alternatives.length !== 3 && (
                    <span className='option'>
                      <b>{alternative.name})</b>
                    </span>
                  )}

                  <span
                    className='option'
                    dangerouslySetInnerHTML={{ __html: alternative.text }}
                  ></span>
                </S.Alternative>
              ))}
            </S.QuestionAlternatives>

            <S.AnswerContainer responsive={responsive}>
              <S.AnswerBtn onClick={handleAnswer} disabled={handledAnswer}>
                Responder
              </S.AnswerBtn>

              {handledAnswer && localQuestion?.answer && (
                <AnswerFlag
                  correct={localQuestion.answer.correct}
                  answer={localQuestion.answer.answer}
                  annulled={localQuestion?.annulled}
                />
              )}
            </S.AnswerContainer>
          </S.QuestionAnswerContainer>
        </S.QuestionTextContainer>
      </S.CardContent>

      {responsive ? (
        <div>
          <S.Accordion
            disabled={!localQuestion?.comment}
            expanded={footerContent === 'feedback'}
            onChange={handleAccordionFooterContent('feedback')}
          >
            <S.AccordionSummary>
              <label>
                <MdOutlineSchool />

                <span>Gabarito comentado</span>
              </label>

              {footerContent === 'feedback' ? (
                <KeyboardArrowUp />
              ) : (
                <KeyboardArrowDown />
              )}
            </S.AccordionSummary>

            <S.AccordionDetails>
              {localQuestion?.comment && (
                <S.QuestionText
                  comment
                  dangerouslySetInnerHTML={{
                    __html: localQuestion.comment,
                  }}
                />
              )}
            </S.AccordionDetails>
          </S.Accordion>
          <S.Accordion
            expanded={footerContent === 'comments'}
            onChange={handleAccordionFooterContent('comments')}
          >
            <S.AccordionSummary>
              <label>
                <BsChatLeft />

                <span>Comentários | {commentsCounter}</span>
              </label>

              {footerContent === 'comments' ? (
                <KeyboardArrowUp />
              ) : (
                <KeyboardArrowDown />
              )}
            </S.AccordionSummary>

            <S.AccordionDetails>
              {showComments && (
                <Comments
                  objectResource={localQuestion}
                  resource={'questions'}
                />
              )}
            </S.AccordionDetails>
          </S.Accordion>
          <S.Accordion
            expanded={footerContent === 'charts'}
            onChange={handleAccordionFooterContent('charts')}
          >
            <S.AccordionSummary>
              <label>
                <BarChart />

                <span>Estatísticas</span>
              </label>

              {footerContent === 'charts' ? (
                <KeyboardArrowUp />
              ) : (
                <KeyboardArrowDown />
              )}
            </S.AccordionSummary>

            <S.AccordionDetails>
              <S.CardChartContent />
            </S.AccordionDetails>
          </S.Accordion>

          <S.Accordion
            expanded={footerContent === 'report'}
            onChange={handleAccordionFooterContent('report')}
          >
            <S.AccordionSummary>
              <label>
                <ErrorOutline />

                <span>Reportar erro</span>
              </label>

              {footerContent === 'report' ? (
                <KeyboardArrowUp />
              ) : (
                <KeyboardArrowDown />
              )}
            </S.AccordionSummary>

            <S.AccordionDetails>
              <ReportErrorArea
                question={localQuestion}
                concursoId={concursoId}
                user={profile}
              />
            </S.AccordionDetails>
          </S.Accordion>
        </div>
      ) : (
        <CardFooter
          localQuestion={localQuestion}
          footerContent={footerContent}
          totalComments={commentsCounter}
          handleFooterContent={handleFooterContent}
        />
      )}

      {!responsive && (
        <S.FooterContent openFooterContent={footerContent}>
          <div>
            {footerContent === 'feedback' && (
              <S.QuestionText
                comment
                dangerouslySetInnerHTML={{
                  __html: localQuestion.comment,
                }}
              />
            )}
            {footerContent === 'charts' && <CardChartContent />}

            {footerContent === 'comments' && (
              <Comments objectResource={localQuestion} resource={'questions'} />
            )}

            {footerContent === 'report' && (
              <ReportErrorArea
                question={localQuestion}
                concursoId={concursoId}
                user={profile}
              />
            )}
          </div>
        </S.FooterContent>
      )}
    </S.CardRoot>
  );
}

export default Card;
