import {
  COURSE_FETCH,
  COURSE_FETCH_FAILURE,
  COURSE_FETCH_SUCCESS,
  SINGLE_COURSE_FETCH,
  SINGLE_COURSE_FETCH_FAILURE,
  SINGLE_COURSE_FETCH_SUCCESS,
  CATEGORY_FETCH,
  CATEGORY_FETCH_FAILURE,
  CATEGORY_FETCH_SUCCESS,
  MODULE_FETCH,
  MODULE_FETCH_FAILURE,
  MODULE_FETCH_SUCCESS,
  CLASS_INFO_FETCH,
  CLASS_INFO_FETCH_FAILURE,
  CLASS_INFO_FETCH_SUCCESS,
  CURRENT_MODULE_FETCH,
  CURRENT_MODULE_FETCH_FAILURE,
  CURRENT_MODULE_FETCH_SUCCESS,
  CURRENT_CLASS_FETCH,
  CURRENT_CLASS_FETCH_FAILURE,
  CURRENT_CLASS_FETCH_SUCCESS,
  COURSE_DATA_FETCH,
  COURSE_DATA_FETCH_FAILURE,
  COURSE_DATA_FETCH_SUCCESS,
  LAST_VISITED_FETCH,
  LAST_VISITED_FETCH_FAILURE,
  LAST_VISITED_FETCH_SUCCESS,
  SET_LAST_VISITED,
  UPDATE_LAST_VISITED,
  SET_EXPANDED_COURSE_CARD,
  SET_CURRENT_COURSE,
  SET_SHOWNED_CLASS,
  SET_SHOW_PRESENTATION,
  SET_VIDEO_LOADED,
  RESET_COURSE,
  REDIRECT_TO_CHECKOUT,
  COURSE_MODAL_OPEN,
  CLASS_SELECTOR_MODAL_OPEN,
  UPDATE_MODULES_WATCHED,
  REMOVE_MODULE_WATCHED,
} from "./constants";

export function categoryFetchRequest() {
  return {
    type: CATEGORY_FETCH,
  };
}

export function categoryFetchSuccess({ payload }) {
  return {
    type: CATEGORY_FETCH_SUCCESS,
    payload: { ...payload },
  };
}

export function categoryFetchFailure() {
  return {
    type: CATEGORY_FETCH_FAILURE,
  };
}

export function lastVisitedFetch(profile, courseID, moduleID, classID) {
  return {
    type: LAST_VISITED_FETCH,
    profile,
    courseID,
    moduleID,
    classID,
  };
}

export function lastVisitedFetchSuccess() {
  return {
    type: LAST_VISITED_FETCH_SUCCESS,
  };
}

export function lastVisitedFetchFailure() {
  return {
    type: LAST_VISITED_FETCH_FAILURE,
  };
}

export function singleCourseFetchRequest(courseID) {
  return {
    type: SINGLE_COURSE_FETCH,
    courseID,
  };
}

export function singleCourseFetchSuccess() {
  return {
    type: SINGLE_COURSE_FETCH_SUCCESS,
  };
}

export function singleCourseFetchFailure() {
  return {
    type: SINGLE_COURSE_FETCH_FAILURE,
  };
}

export function courseFetchRequest() {
  return {
    type: COURSE_FETCH,
  };
}

export function courseFetchSuccess({ payload }) {
  return {
    type: COURSE_FETCH_SUCCESS,
    payload,
  };
}

export function courseFetchFailure() {
  return {
    type: COURSE_FETCH_FAILURE,
  };
}

export function moduleFetchRequest(courseID, lastVisited, profile) {
  return {
    type: MODULE_FETCH,
    courseID,
    lastVisited,
    profile,
  };
}

export function moduleFetchSuccess({ payload }) {
  return {
    type: MODULE_FETCH_SUCCESS,
    payload,
  };
}

export function updateModulesWatched({ payload }) {
  return {
    type: UPDATE_MODULES_WATCHED,
    payload
  }
}

export function removeModuleWatched({ payload }) {
  return {
    type: REMOVE_MODULE_WATCHED,
    payload
  }
}

export function moduleFetchFailure() {
  return {
    type: MODULE_FETCH_FAILURE,
  };
}

export function currentModuleFetchRequest(moduleID) {
  return {
    type: CURRENT_MODULE_FETCH,
    moduleID,
  };
}

export function currentModuleFetchSuccess({ payload }) {
  return {
    type: CURRENT_MODULE_FETCH_SUCCESS,
    payload: { ...payload },
  };
}

export function currentModuleFetchFailure() {
  return {
    type: CURRENT_MODULE_FETCH_FAILURE,
  };
}

export function currentClassFetchRequest(classID) {
  return {
    type: CURRENT_CLASS_FETCH,
    classID,
  };
}

export function currentClassFetchSuccess({ payload }) {
  return {
    type: CURRENT_CLASS_FETCH_SUCCESS,
    payload: { ...payload },
  };
}

export function currentClassFetchFailure() {
  return {
    type: CURRENT_CLASS_FETCH_FAILURE,
  };
}

export function classInfoFetchRequest(payload) {
  return {
    type: CLASS_INFO_FETCH,
    payload,
  };
}

export function classInfoFetchSuccess({ payload }) {
  return {
    type: CLASS_INFO_FETCH_SUCCESS,
    payload: { ...payload },
  };
}

export function classInfoFetchFailure() {
  return {
    type: CLASS_INFO_FETCH_FAILURE,
  };
}

export function courseDataFetchRequest(payload, profile) {
  return {
    type: COURSE_DATA_FETCH,
    payload,
    profile,
  };
}

export function courseDataFetchSuccess() {
  return {
    type: COURSE_DATA_FETCH_SUCCESS,
  };
}

export function courseDataFetchFailure() {
  return {
    type: COURSE_DATA_FETCH_FAILURE,
  };
}

export function setExpandedCourseCard({ payload }) {
  return {
    type: SET_EXPANDED_COURSE_CARD,
    payload,
  };
}

export function setCurrentCourse({ payload }) {
  return {
    type: SET_CURRENT_COURSE,
    payload,
  };
}

export function courseModalAction(payload) {
  return {
    type: COURSE_MODAL_OPEN,
    payload,
  };
}

export function classSelectorModalAction(payload) {
  return {
    type: CLASS_SELECTOR_MODAL_OPEN,
    payload,
  };
}

export function setShownedClass(payload) {
  return {
    type: SET_SHOWNED_CLASS,
    payload,
  };
}

export function setLastVisited(payload) {
  return {
    type: SET_LAST_VISITED,
    payload,
  };
}

export function updateLastVisited(seconds, duration) {
  return {
    type: UPDATE_LAST_VISITED,
    seconds,
    duration,
  };
}

export function setShowPresentation(payload) {
  return {
    type: SET_SHOW_PRESENTATION,
    payload,
  };
}

export function setVideoLoaded(payload) {
  return {
    type: SET_VIDEO_LOADED,
    payload,
  };
}

export function resetCourse(payload) {
  return {
    type: RESET_COURSE,
    payload,
  };
}

export function redirectToCheckoutAction(payload) {
  return {
    type: REDIRECT_TO_CHECKOUT,
    payload,
  };
}
