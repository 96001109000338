import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from 'material-ui/styles';

const styles = theme => ({
  container: {
    background: '#82D3FF',
    color: '#fff',
    borderRadius: '50%',
    height: '45px',
    width: '45px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.15)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '8px',
    fontSize: '18px',
    fontFamily: theme.typography.fontFamily
  },
})

class ProfileImage extends Component {
  static propTypes = {
    url: PropTypes.string,
    name: PropTypes.string
  }

  static defaultProps = {
    name: 'Clipping'
  }

  render() {
    const {url, name, classes, onClick} = this.props;
    
    return (
      url ? (
        <img className={classes.container} alt='avatar' onClick={onClick} src={url}></img>) : (
        <div className={classes.container} onClick={onClick}>{(name && name.slice(0,1)) || 'C'}</div>
      )
    )
  }
}


export default withStyles(styles)(ProfileImage)
