import {
    SET_USER_PROFILE,
    SET_MAIN_CONCURSO
} from '../actions/userActions'

import {
    PROFILE_UPDATE_SUCCESS,
    KINDLE_CONNECT_SUCCESS,
    KINDLE_DISCONNECT_SUCCESS,
    GET_ACCOUNT_DATA_SUCCESS,
    GET_ACCOUNT_ACTIVE_SUBSCRIPTIONS_SUCCESS,
    GET_ACCOUNT_PM_SUCCESS,
    FACEBOOK_CONNECT_SUCCESS,
    FACEBOOK_DISCONNECT_SUCCESS,
    CANCEL_SUBSCRIPTION_SUCCESS,
    UPDATE_SUBSCRIPTION_SUCCESS,
    UPDATE_CREDITCARD_SUCCESS
} from '../actions/profileActions'

export default (previousState = { subscription: {}, profile: {},userPM:{}, fetching: false }, { type, payload }) => {
    switch (type) {
    case SET_USER_PROFILE:
        return {...previousState, profile: {...payload}};
    case SET_MAIN_CONCURSO:
        return {...previousState, profile: {...previousState.profile, mainConcurso: payload}};
    case PROFILE_UPDATE_SUCCESS: {
        return {...previousState, profile: {...previousState.profile, ...payload.data}};
    }
    case KINDLE_CONNECT_SUCCESS: {
        return {...previousState, profile: {...previousState.profile, kindle: payload.data.kindle}};
    }
    case KINDLE_DISCONNECT_SUCCESS: {
        return {...previousState, profile: {...previousState.profile, kindle: ''}};
    }
    case GET_ACCOUNT_DATA_SUCCESS: {
        return {...previousState, subscription: {...payload.data}};
    }
    case GET_ACCOUNT_ACTIVE_SUBSCRIPTIONS_SUCCESS: {
        return {...previousState, activeSubscriptions: payload.data};
    }
    case GET_ACCOUNT_PM_SUCCESS: {
        return {...previousState, userPM: {...payload.data}};
    }
    case UPDATE_CREDITCARD_SUCCESS: {
        return {...previousState, subscription: {...payload.data}};
    }
    case FACEBOOK_CONNECT_SUCCESS: {
        return {...previousState, profile: {...previousState.profile, facebookId: payload.data.facebookId}};
    }
    case FACEBOOK_DISCONNECT_SUCCESS: {
        return {...previousState, profile: {...previousState.profile, facebookId: ''}};
    }
    case CANCEL_SUBSCRIPTION_SUCCESS: {
        return {...previousState, subscription: {...previousState.subscription, status: 'canceled'}};
    }
    case UPDATE_SUBSCRIPTION_SUCCESS: {
        return {...previousState, subscription: payload.data};
    }
    default:
        return previousState;
    }
};
