import React, { useEffect, useState } from "react";
import { isAfter } from "date-fns";

import MainBar from "./main";
import SecondaryBar from "./secondary";

function DivulgationBar({ hideBar }) {
  const [showMain, setShowMain] = useState(false);

  // function verifyDate() {
  //   const currentDate = new Date();
  //   const eventDate = new Date(2020, 5, 24, 23, 59, 59, 0);
  //   if (isAfter(currentDate, eventDate)) {
  //     setShowMain(false);
  //   } else {
  //     setShowMain(true);
  //   }
  //   return;
  // }

  // useEffect(() => {
  //   verifyDate();
  //   const interval = setInterval(() => {
  //     verifyDate();
  //   }, 10000);
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <div>
      {/* {showMain ? (
        <MainBar hideBar={() => hideBar()} />
      ) : ( */}
        <SecondaryBar hideBar={() => hideBar()} />
      {/* )} */}
    </div>
  );
}

export default DivulgationBar;
