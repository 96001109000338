import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "material-ui/styles";
import Dialog from "material-ui/Dialog";

import { closeRegister, openConfirmExit, card } from "./actions";
import { setChooseCardOpen } from "../Payments/actions";

const styles = {
  paper: {
    minWidth: 650,
  },
  paperResponsive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 48px;
  font-family: "Proxima Nova" !important;
  align-items: center;
  @media (max-width: 500px) {
    padding: 32px 24px;
  }
  @media (max-width: 400px) {
    padding: 32px 16px;
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
`;

const Title = styled.span`
  width: 100%;
  font-size: 25px;
  letter-spacing: -0.7px;
  text-align: center;
  line-height: 28px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  @media (max-width: 500px) {
    font-size: 20px;
    letter-spacing: 0px;
    line-height: 22px;
  }
  @media (max-width: 440px) {
    font-size: 18px;
    line-height: 20px;
  }
`;

const MessageContainer = styled.div`
  width: 100%;
  height: fit-content;
`;

const Message = styled.p`
  color: rgba(0, 0, 0, 0.75);
  font-size: 16px;
  font-weight: normal;
  height: fit-content;
  letter-spacing: 0px;
  line-height: 22px;
  text-align: center;
  white-space: pre-line;
  width: fit-content;
  margin: 0px;
  font-family: "Proxima Nova";
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
  display: flex;
  margin-top: 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 700px) {
    height: 96px;
    flex-direction: column;
  }
`;

const CustomButton = styled.button`
  border: none;
  background-color: #fc7954;
  border-radius: 25px;
  margin-right: 30px;
  font-size: 13px;
  color: #fff;
  font-weight: 600;
  width: 230px;
  height: 40px;
  text-transform: uppercase;
  font-family: "Proxima Nova";
  &:hover {
    background: #e95b34;
    border: #e95b34;
  }

  @media (max-width: 700px) {
    margin-right: 0px;
  }
`;

const CloseButton = styled.button`
  border: 2px solid rgb(252, 121, 84);
  background-color: #fff;
  border-radius: 25px;
  font-size: 13px;
  color: rgb(252, 121, 84);
  font-weight: 600;
  width: 230px;
  height: 40px;
  text-transform: uppercase;
  font-family: "Proxima Nova";
  &:hover {
    border-color: #e95b34;
    color: #e95b34;
  }
`;

function ConfirmExit({ classes }) {
  const dispatch = useDispatch();

  const open = useSelector((state) => state.checkout.openConfirmExit);
  const responsive = useSelector((state) => state.plataform.responsive);
  const sponsorModal = useSelector((state) => state.paymentGroup.sponsorModal);

  function handleAction(action) {
    dispatch(openConfirmExit(false));
    if (action === "closeAll") {
      dispatch(closeRegister());
      if (sponsorModal) {
        dispatch(setChooseCardOpen(true));
      }
    }
  }

  return (
    <Dialog
      open={open}
      classes={{ paper: responsive ? classes.paperResponsive : classes.paper }}
    >
      <Container>
        <TitleContainer>
          <Title>Deseja realmente sair?</Title>
        </TitleContainer>
        <Content>
          <MessageContainer>
            <Message>
              {sponsorModal
                ? "Se você sair sem preencher os dados do responsável financeiro, não conseguirá fazer a compra com o cartão do responsável"
                : "Se você sair sem preencher seus dados pessoais e de endereço, não será possível realizar compras."}
            </Message>
          </MessageContainer>
          <ButtonsWrapper responsive={responsive}>
            <CustomButton onClick={() => handleAction("continue")}>
              {sponsorModal ? "Preencher dados" : "Preencher meus dados"}
            </CustomButton>
            <CloseButton onClick={() => handleAction("closeAll")}>
              Fechar tudo
            </CloseButton>
          </ButtonsWrapper>
        </Content>
      </Container>
    </Dialog>
  );
}

export default withStyles(styles)(ConfirmExit);
