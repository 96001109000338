import  React from 'react'
import { withStyles } from 'material-ui/styles';

const styles = theme => ({
    container: {
      width: '100%',
      marginBottom: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
      color: theme.palette.text.clear,
      flexDirection: 'column',
      paddingTop: '40px'
    },
    title: {
        textAlign: 'center',
        ...theme.typography.headline,
        paddingBottom: '8px',
        color: '#F06D1A'
    },
  })

const EmptyList = ({classes}) => {
    return (
        <div className={classes.container}>
            <div className={classes.title}>
                Sua lista de leitura está vazia.
            </div>
        </div>
    )
}

export default withStyles(styles)(EmptyList)