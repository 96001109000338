import {
    COURSE_PACK_NOTIFICATION_BAR_CLOSE,
    COURSE_PACK_NOTIFICATION_BAR_OPEN
} from '../actions/notificationBarActions';

const INITIAL_STATE = {
    coursePack: { open: false }
};

export default (prevState = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case COURSE_PACK_NOTIFICATION_BAR_CLOSE:
            return { ...prevState, coursePack: { ...payload } }
        case COURSE_PACK_NOTIFICATION_BAR_OPEN:
            return { ...prevState, coursePack: { ...payload } }
        default:
            return prevState;
    };
};