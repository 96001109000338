import React from 'react'
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from 'material-ui/styles';
import Button from 'material-ui/Button';
import classNames from 'classnames';
import ProfileIcon from 'material-ui-icons/Person'
import LogoutIcon from 'material-ui-icons/ExitToApp';
import TimerIcon from 'material-ui-icons/Schedule';
import HelpIcon from 'material-ui-icons/HelpOutline';
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';

const styles = theme => ({
  button: theme.mixins.gutters({
    justifyContent: 'flex-start',
    textTransform: 'none',
    width: '95%',
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      textDecoration: 'none',
      background: 'transparent',
      color: theme.palette.text.primary,
    },
    height: '50px',
    borderRadius: '3px',
    color: theme.palette.text.primary,
    fontWeight: 500,
    '&:before': {
      content : '""',
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%'
    },
  }),
  navLinkButton: {
    color: theme.palette.text.clear,
    textIndent: theme.spacing.unit * 3,
    fontSize: 15,
    paddingRight: '0px',
    paddingLeft: '0px',
    textAlign: 'left',
    fontFamily: theme.typography.fontFamily
  },
  container: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
    borderTop: '1px solid rgba(151,151,151,0.2)',
    marginTop: '16px',
    padding: '8px 16px'
  },
  icons: {
    fontSize: '18px !important',
    color: '#4F4033',
    opacity: '0.5',
    width: '18x',
    maxWidth: '42px',
    marginRight: '24px',
    marginTop: '-3px'
  },
})

const MobileItems = (props) => {
  const {userLogout, openTimer, pushRouter, classes, closeDrawer, trackMetric} = props

  let openHelp = () => {
    window.open('http://ajuda.clippingcacd.com.br/', "_blank")
  }

  return (
    <div className={classes.container} >
      <Button
        className={classNames(classes.button, classes.navLinkButton)}
        disableRipple
        disableFocusRipple
        onClick={() => {openHelp(); closeDrawer(); trackMetric('Clicou em ajuda', { url: location.href });}}
        >
        <HelpIcon className={classes.icons} />
        Ajuda
      </Button>
      <Button
        className={classNames(classes.button, classes.navLinkButton)}
        disableRipple
        disableFocusRipple
        onClick={() => {openTimer(); closeDrawer();}}
        >
        <TimerIcon className={classes.icons} />
        Cronômetro
      </Button>
      <Button
        className={classNames(classes.button, classes.navLinkButton)}
        disableRipple
        disableFocusRipple
        onClick={() => {pushRouter('/perfil'); closeDrawer();}}
        >
        <ProfileIcon className={classes.icons} />
        Perfil
      </Button>
      <Button
        className={classNames(classes.button, classes.navLinkButton)}
        disableRipple
        disableFocusRipple
        onClick={() => {userLogout(); closeDrawer();}}
        >
        <LogoutIcon className={classes.icons} />
        Sair
      </Button>
    </div>
  )
}

const mapDispatchToProps = {
  trackMetric: (name, data) => trackMetricAction(name, data)
}

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps),
)(MobileItems);