import { put, takeLatest, call, select } from 'redux-saga/effects';
import {SORT_DESC} from '../reducers/resource/list/queryReducer'
import {
    GET_LIST
} from '../services/rest_types';

import {
    FETCH_START,
    FETCH_END,
    FETCH_ERROR
} from '../actions/fetchActions';

import {
    GET_DOE_SECTION
} from '../actions/sectionActions';

const params = {
    filter: {}, 
    sort: {field: 'order', order: SORT_DESC},
    pagination: {page: 1, perPage: 1000}
}

const sectionFetch = (restClient) => {
  function* handleSection(action) {
        const { type } = action;
        switch (type) {
            case GET_DOE_SECTION: {
                let response;
                try {
                    yield put({ type: FETCH_START });
                    const concurso = yield select(state => state.concurso.active);
                    response = yield call(restClient, GET_LIST, 'doeSection', {...params, concurso});
                    if (!response.data) {
                        throw new Error('REST response must contain a data key');
                    }
                    yield put({
                        type: `${type}_SUCCESS`,
                        payload: response.data
                    });
                    yield put({ type: FETCH_END });    
                } catch (e) {
                    const errorMessage = typeof e === 'string'
                            ? e
                            : (typeof e === 'undefined' || !e.message ? 'Login error' : e.message);
                    // yield put(showNotification(errorMessage, 'warning'));
                    yield put({ type: FETCH_ERROR,  errorMessage});
                }
                break;
            }
            default:
                break;   
      }
  }

  return function* watchSectionActions() {
      yield [
          takeLatest(action => action.meta && action.meta.section, handleSection),
      ];
  };
}

export default sectionFetch