import {
  USER_FETCH,
  USER_FETCH_FAILURE,
  USER_FETCH_SUCCESS,
} from "./constants";

const INITIAL_STATE = {
  loading: false,
  error: false,
  data: {},
};

export default (previousState = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case USER_FETCH:
      return {
        ...previousState,
        loading: true,
      };

    case USER_FETCH_FAILURE:
      return {
        ...previousState,

        loading: false,
        error: true,
      };

    case USER_FETCH_SUCCESS:
      return {
        loading: false,
        error: false,
        data: payload,
      };

    default:
      return previousState;
  }
};
