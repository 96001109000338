import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { crudCreate } from "../../actions/dataActions";
import { updateLastVisited, setVideoLoaded } from "./actions";

function VideoPlayer({ open = {}, course, classData, newHeight = false }) {
  const dispatch = useDispatch();

  const { seconds: lastVideoTime } = useSelector(
    (state) => state.courseGroup.currentCourse.lastVisited
  );

  const iframeRef = useRef(null);

  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [last, setLast] = useState("");
  const [lastTime, setLastTime] = useState(0);
  const [lastSeconds, setLastSeconds] = useState(0);
  const [lastDuration, setLastDuration] = useState(0);

  function setToTwoDigits(value) {
    value = value.toString();
    if (value.length === 0) {
      return "00";
    }
    if (value.length === 1) {
      return "0" + value;
    }
    return value;
  }

  function toTimeString(time) {
    let seconds = setToTwoDigits(Math.trunc(time % 60));
    let minutes = setToTwoDigits(Math.trunc((time / 60) % 60));
    let hour = setToTwoDigits(Math.trunc((time / 60 / 60) % 60));

    return `${hour}:${minutes}:${seconds}`;
  }

  useEffect(() => {
    async function setVideoTime() {
      try {
        await player.setCurrentTime(lastVideoTime);
      } catch (error) {}
    }
    const iframe = iframeRef.current;
    let player = new window.Vimeo.Player(iframe);
    setVideoTime();

    player.on("play", (data) => {
      setFrom(Math.trunc(data.seconds));
      setLast("play");
    });
    player.on("pause", (data) => {
      setTo(data.seconds);
      let seconds = Math.trunc(data.seconds);

      if (seconds > from) {
        dispatch(
          crudCreate(
            "event",
            {
              name: "Assistiu à gravação",
              data: {
                De: toTimeString(from),
                Até: toTimeString(seconds),
                Total: toTimeString(seconds - from),
                Curso: course.name,
                "Nome da aula": classData.title,
                "Número da aula": classData.number,
                course,
              },
            },
            "",
            false,
            "",
            false
          )
        );
      }
      setLast("pause");
    });
    player.on("timeupdate", (data) => {
      let seconds = Math.trunc(data.seconds);
      let duration = Math.trunc(data.duration);

      if (seconds !== lastTime) {
        setLastTime(seconds);
        setLastDuration(duration);

        if (Math.abs(seconds - lastTime) > 1) {
          if (lastTime > from) {
            dispatch(
              crudCreate(
                "event",
                {
                  name: "Assistiu à gravação",
                  data: {
                    De: toTimeString(from),
                    Até: toTimeString(lastTime),
                    Total: toTimeString(lastTime - from),
                    Curso: course.name,
                    "Nome da aula": classData.title,
                    "Número da aula": classData.number,
                    course,
                  },
                },
                "",
                false,
                "",
                false
              )
            );
            setFrom(seconds);
          }
        }
      }
    });
    return () => dispatch(setVideoLoaded(false));
  }, []);

  useEffect(() => {
    if (lastTime > 10 + lastSeconds) {
      setLastSeconds(lastTime);
      dispatch(updateLastVisited(parseFloat(lastTime), parseFloat(lastDuration)));
    }
  }, [lastTime]);

  return (
    <iframe
      onLoad={() => dispatch(setVideoLoaded(true))}
      ref={iframeRef}
      src={`https://player.vimeo.com/video/${open.video}`}
      style={{ border: 0 }}
      width="100%"
      height={newHeight ? "100%" : "533.6px"}
      frameBorder="0"
      allowFullScreen
    ></iframe>
  );
}

export default VideoPlayer;
