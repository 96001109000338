import { BrasilAPI, API } from "../../services/servicesAPI";

export async function getAddress({ zipcode }) {
  try {
    const { data } = await BrasilAPI.get(`/${zipcode}`);

    return data;
  } catch (error) {
    return null;
  }
}

export async function updateUser({ cpf, rg, ddd, phone }) {
  try {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));

    const formattedCPF = cpf.replace(/\D/g, "");
    const formattedRG = rg.replace(/\D/g, "");
    const mobile = `${ddd}${phone}`.replace(/\D/g, "");

    const headers = { Authorization: token };
    const { data } = await API.patch(
      `/users/students/${user.id}/`,
      {
        cpf: formattedCPF,
        rg: formattedRG,
        mobile,
        birthday: "1980-05-10",
      },
      { headers }
    );

    return true;
  } catch (error) {
    console.error("Falha ao atualizar dados do usuário", error);
    return false;
  }
}

export async function updateUserAddress({
  address,
  number,
  complement,
  neighborhood,
  city,
  state,
  zipcode,
}) {
  try {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));

    const headers = { Authorization: token };

    const formattedZipcode = zipcode.replace(/\D/g, "");

    const payload = {
      student: user.id,
      street: address,
      street_number: number,
      complementary: complement,
      neighborhood,
      city,
      state,
      zipcode: formattedZipcode,
    };

    const { data } = await API.post(`/users/address/`, payload, { headers });

    return true;
  } catch (error) {
    console.error("Falha ao salvar endereço do usuário", error);
    return false;
  }
}
