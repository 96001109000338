export default function (container, file, fileName, callback) {
  const blobUri = 'https://avatars.blob.core.windows.net';
  let sasToken = '';

  if (container === 'user-uploads') {
    sasToken = localStorage.userUploads || 'se=2018-09-02T09%3A21%3A08Z&sp=w&sv=2017-07-29&sr=c&sig=B6vkZO8dUnt59HHAwVxUijj1hZ%2FpU18kx%2B%2BsKA8VuPE%3D';
  } else {
    // sasToken = "se=2019-08-02T03%3A38%3A34Z&sp=w&sv=2017-07-29&sr=c&sig=DMi%2BWeMkFnjFEGoSOwXJ6XEi7pcS6%2BPBjE4nFIoJY0k%3D"
    sasToken = localStorage.mediaUploads; // || "se=2018-09-02T09%3A21%3A08Z&sp=w&sv=2017-07-29&sr=c&sig=8KwtqElSm8Y5j9WbqMMz9ctCSqxP3ekggCQXH85MvO0%3D";
  }
  const blobService = window.AzureStorage.Blob.createBlobServiceWithSas(blobUri, sasToken).withFilter(new window.AzureStorage.Blob.ExponentialRetryPolicyFilter());

  const blockSize = file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
  const options = {
    storeBlobContentMD5: false,
    blockSize,
  };

  blobService.singleBlobPutThresholdInBytes = blockSize;
  blobService.createBlockBlobFromBrowserFile(container, fileName, file, options, callback);
}
