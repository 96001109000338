
import {
    PAY_COURSE_PACK_SUCCESS,
    PAY_COURSE_PACK_FAILURE,
    GET_COURSE_PACK_PURCHASES_SUCCESS,
    GET_COURSE_PACK_PURCHASES_FAILURE,
    RESET_COURSE_PACK_STATE
} from '../actions/coursePackActions';

const INITIAL_STATE = {
    finish: false,
    paid: false,
    failure: false,
    myPurchases: {
        loading: true,
        failure: false,
        data: []
    },

};

export default (previousState = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case PAY_COURSE_PACK_SUCCESS:
            return { 
                ...previousState,
                ...{ ...payload, finish: true, paid: true, failure: false }
            }
        case PAY_COURSE_PACK_FAILURE:
            return {
                ...previousState,
                ...{ ...payload, finish: true, paid: false, failure: true }
            }
        case GET_COURSE_PACK_PURCHASES_SUCCESS:
            return {
                ...previousState,
                myPurchases: { ...payload, loading: false }
            }
        case GET_COURSE_PACK_PURCHASES_FAILURE:
            return {
                ...previousState,
                myPurchases: {
                    ...payload,
                    loading: true,
                    failure: true,
                }
            }
        case RESET_COURSE_PACK_STATE:
            return {
                ...previousState,
                ...payload
            }
        default:
            return previousState;
    }
};
