import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import compose from 'recompose/compose';
import ModalTemas from './ModalTemas';
import { crudGetOne as crudGetOneAction } from '../../actions/dataActions';

class Temas extends Component {
  constructor(props) {
    super(props);

    const { _id = false }  = props.routeParams;

    this.state = {
      modal: _id
    }
  }
  
  handleModal = (_id) => {
    this.setState({ modal: _id });
    Boolean(_id) ? this.props.pushRouter(`/temas/${_id}`) : window.history.back();
  }

  componentWillReceiveProps(nextProps) {
    const { _id } = nextProps.routeParams;

    if(this.props.routeParams._id !== _id) {
      this.props.crudGetOne(_id);
      this.setState({ modal: _id || false });
    }
  }

  componentWillMount() {
    this.props.crudGetOne(this.props.routeParams._id);
  }  

  render() {
    const { modal } = this.state;
    
    return (
      <ModalTemas {...this.props} handleOpen={this.handleModal} open={modal} />
    );
  }
}

const mapDispatchToProps = {
  pushRouter: (path) => push(path),
  crudGetOne: (_id) => crudGetOneAction('clippingSectionCategory', _id, '', false, true),
}

export default compose(
  connect(null, mapDispatchToProps),
)(Temas);
