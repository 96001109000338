import React, { Component } from 'react';

class NotificationItem extends Component {
  render() {
    const {label, read} = this.props

    return (
      <li className={!read ? 'not-read' : ''}>
        <a href="#" ><div dangerouslySetInnerHTML={{__html: label}} /></a>
      </li>
    );
  }
}

export default NotificationItem;