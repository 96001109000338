import React from "react";
import styled from "styled-components";
import Dialog from "material-ui/Dialog";

import { useSelector } from "react-redux";
import { withStyles } from "material-ui/styles";

import DefaultLoading from "../../../components/Loading";

const styles = {
  paper: {
    minWidth: 650,
  },
  paperResponsive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 325px;
  width: ${(props) => (props.responsive ? "100%" : "650px")};
  padding: ${(props) => props.responsive && "0px 20px"};
`;

const ProcessingText = styled.p`
  margin: 0;
  font-family: "Proxima Nova";
  font-size: 20px;
  font-weight: 600;
  width: ${(props) => (props.responsive ? "100%" : "fit-content")};
  height: fit-content;
  color: rgba(0, 0, 0, 0.75);
  align-self: center;
  text-align: center;
  margin-top: 50px;
`;

function LoadingModal({ classes }) {
  const open = useSelector((state) => state.paymentGroup.loadingModal.open);
  const responsive = useSelector((state) => state.plataform.responsive);

  return (
    <Dialog
      open={open}
      fullScreen={responsive}
      classes={{ paper: responsive ? classes.paperResponsive : classes.paper }}
    >
      <Container responsive={responsive}>
        <DefaultLoading sidebar={true} />
        <ProcessingText responsive={responsive}>
          Aguarde, o seu pagamento está sendo processado...
        </ProcessingText>
      </Container>
    </Dialog>
  );
}

export default withStyles(styles)(LoadingModal);
