import React, { useEffect, useRef, useState } from 'react';
import { Avatar } from '@clippingcacd/core';
import { IoSend } from 'react-icons/io5';

import * as S from './styles';
import { createComment, getUsers } from '../../../../services/comments';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAnswers,
  updateAnswerCount,
} from '../../../../actions/questionsCommentsActions';

export default function WriteCommentParent({
  profile,
  objectResource,
  commentId,
  resource,
}) {
  const dispatch = useDispatch();
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const textareaRef = useRef(null);
  const responsive = useSelector((state) => state.plataform.responsive);

  async function handleComment() {
    try {
      setLoading(true);

      const { data: createdAnswer } = await createComment({
        topic: `${resource}_${objectResource._id}`,
        text,
        parentComment: commentId,
      });

      const { data: users } = await getUsers([createdAnswer.user]);

      if (users && users.length) {
        createdAnswer.user = users[0];
      }

      createdAnswer['userLike'] = false;

      setText('');
      dispatch(
        addAnswers(resource, createdAnswer, objectResource._id, commentId)
      );
      dispatch(
        updateAnswerCount(resource, 'INCREMENT', objectResource._id, commentId)
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  function handleOnChange(e) {
    if (text.length >= 1000) return setText(e.target.value.substring(0, 1000));

    setText(e.target.value);
  }

  useEffect(() => {
    textareaRef.current.style.height = '0px';
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = scrollHeight + 'px';
  }, [text]);

  return (
    <React.Fragment>
      <S.EditorContainer>
        {!responsive && (
          <S.AvatarContainer>
            <Avatar size='xs' src={profile?.avatar}>
              {profile?.name}
            </Avatar>
          </S.AvatarContainer>
        )}

        <S.ParentCommentEditor
          ref={textareaRef}
          responsive={responsive}
          placeholder='Participe da discussão...'
          value={text}
          onChange={handleOnChange}
          disabled={loading}
        />
        <S.ButtonSendCommentParent
          startIcon={loading ? null : <IoSend size={20} />}
          onClick={handleComment}
          loading={loading}
          variant='text'
          disabled={text.length === 0}
          responsive={responsive}
        />
      </S.EditorContainer>
    </React.Fragment>
  );
}
