import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import { withStyles } from 'material-ui/styles';
import Toolbar from 'material-ui/Toolbar';
import Pagination from '@material-ui/lab/Pagination';
import compose from 'recompose/compose';
import withWidth from 'material-ui/utils/withWidth';
import {makeStyles} from '@material-ui/core'

const styles = theme => ({
    container: {
        ...theme.typography.pagination,
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'rgba(0, 0, 0, 0.5)',
    },

    buttonNavigate: { '& > ul > li > button': {
        ...theme.typography.pagination,
        color: theme.palette.text.clearGrey,
        fontSize: '17px',
        fontWeight: 'bold',
    }},

})

export class UsedPagination extends Component {
    
    getNbPages() {
        return Math.ceil(this.props.total / this.props.perPage) || 1;
    }

    prevPage = (event) => {
        event.stopPropagation();
        if (this.props.page === 1) {
            throw new Error('Não é possível voltar antes da página 1');
        }
        this.props.setPage(this.props.page - 1);
    }

    nextPage = (event) => {
        event.stopPropagation();
        if (this.props.page > this.getNbPages()) {
            throw new Error('Não é possível ir além da última página');
        }
        this.props.setPage(this.props.page + 1);
    }

    gotoPage = (event, value) => {
        // event.stopPropagation();
        const page = value;
        if (page < 1 || page > this.getNbPages()) {
            throw new Error(`A página ${page} não existe`);
        }
        this.props.setPage(page);
    }

    
    render() {
        const { page, perPage ,total, width, classes } = this.props;
        if (total === 0) return null;
        const offsetEnd = Math.min(page * perPage, total);
        const offsetBegin = Math.min((page - 1) * perPage + 1, offsetEnd);
        const nbPages = this.getNbPages(); 

        return width === "sm" || width === "xs" ?
            (<Toolbar>
                <div className={classes.container}>
                    <Pagination 
                        hideNextButton 
                        hidePrevButton 
                        count={nbPages} 
                        selected
                        onChange={this.gotoPage}
                        className={classes.buttonNavigate}
                        >
                            
                    </Pagination>
                </div>
            </Toolbar>) : 
            (<Toolbar >
                <div className={classes.container}>
                    <Pagination 
                        count={nbPages} 
                        selected
                        onChange={this.gotoPage}
                        className={classes.buttonNavigate}
                        siblingCount={3}
                        >
                    </Pagination>
                </div>
            </Toolbar> )
    }
}

UsedPagination.propTypes = {
    page: PropTypes.number,
    perPage: PropTypes.number,
    total: PropTypes.number,
    setPage: PropTypes.func,
    // translate: PropTypes.func.isRequired,
    width: PropTypes.string,
};

const enhance = compose(
    pure,
    withWidth(),
    withStyles(styles),
);

export default enhance(UsedPagination);
