import {
  LOAD_REPORT_SUCCESS
} from '../actions/loadReportActions'

export default (previousState = {payload: {}}, { type, payload }) => {
    switch (type) {
    case LOAD_REPORT_SUCCESS:    
      return {...payload.data }
    default:    
        return previousState;
    }
};