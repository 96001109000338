import React from 'react'
import {
  TableCell,
  TableHead,
  TableRow,
} from 'material-ui/Table';
import classNames from 'classnames';
import { withStyles } from 'material-ui/styles';

const styles = theme => ({
  title: {
    ...theme.typography.body3,
  },
  center: {
    textAlign: 'center'
  }
})

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };
  
  render() {
    const { columnData, classes } = this.props;
    return (
      <TableHead>
        <TableRow>
        {columnData.map(column => {
          return (
            <TableCell
            key={column.id}
            numeric={column.numeric}
            padding={'none'}
            >
              <div className={classNames(classes.title, column.center ? classes.center : '')}>{column.label}</div>
            </TableCell>
          );
        }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

export default withStyles(styles)(EnhancedTableHead)