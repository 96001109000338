import React, { Component } from 'react'
import MovibeNav from './MobileNav'
import SocialNav from './SocialNav'
import FooterNav from './FooterNav'

export default class Footer extends Component {
  render() {
    const { location } = this.props
      
    return (
      <div>
          <div className="mobile-bar">
            <div className="container">
                <a href="#modal-add-post" data-modal-open className="btn btn-add">Add</a>
                <MovibeNav location={location} />
            </div>
        </div>

        <footer className="footer">
            <div className="container">
                <div className="footer--copy">® Clipping CACD 2017. Todos os direitos reservados.</div>
                <FooterNav />

                <SocialNav />
            </div>
        </footer>
      </div>
    )
  }
}