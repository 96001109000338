import {
  GET_GLOBAL_SETTING_SUCCESS
} from '../actions/plataformActions';

export default (previousState = {payload: {}}, { type, payload }) => {
    switch (type) {
    case GET_GLOBAL_SETTING_SUCCESS:    
      return { ...payload.data.docs[0] }
    default:    
        return previousState;
    }
};