import React, { Component } from 'react';
import compose from 'recompose/compose';
import { withStyles } from 'material-ui/styles';
import Grid from 'material-ui/Grid';
import { ElementList, Title } from '../../components/common';
import { FetchList } from '../../components';
import Item from './Item';
import { SORT_ASC } from '../../reducers/resource/list/queryReducer';

const styles = theme => ({
  content: {
    paddingTop: '8px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '32px'
    }
  },
  root: {
    flexGrow: 1,
    padding: '8px'
  }
});

const resource = 'law';
const fixedFilters = { sort: { field: 'order', order: SORT_ASC } }

class LeiSeca extends Component {
  render() {
    const { classes } = this.props;

    return (
      <section className={classes.content}>
        <Grid container className={classes.root} >
          <Grid item xs={12} md={12} className={classes.contentMain}>
            <Title title="Lei Seca" />
            <FetchList {...this.props}
                fixedFilters={fixedFilters}
                resource={resource} >
              <ElementList
                renderRow={(props) => <Item {...props} />}
              />
            </FetchList>
          </Grid>
        </Grid>
      </section>
    );
  }
}

export default compose(
  withStyles(styles)
)(LeiSeca);