import { SET_MODAL_DATA, CLEAR_MODAL_DATA } from '../actions/modalActions';

const initialState = {user: {}, modalData: {}}

export default (previousState = initialState, { type, payload }) => {
    switch (type) {
    case SET_MODAL_DATA:
      return payload;
    case CLEAR_MODAL_DATA: 
      return initialState
    default:
        return previousState;
    }
};
