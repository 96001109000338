import styled, { css } from 'styled-components';

import {
  Tabs,
  Checkbox,
  IconButton,
  Dialog,
  Radio,
  FormControlLabel,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@material-ui/core';

// import { InsertDriveFileOutlined, ThumbUpOutlined, StarRounded  } from "@material-ui/icons";

// import Dialog from "material-ui/Dialog";

export const ModalTitle = styled.div`
  width: 100%;
  height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  padding-left: 16px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
`;

export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  margin-top: 20px;

  & > div {
    margin-bottom: 16px;
  }
`;

export const CustomDialog = styled(Dialog)`
  & .MuiDialog-paper {
    width: 100%;
    height: fit-content;
    border-radius: 16px;
    margin: 16px;
    background-color: #ece6e4;
    display: flex;
    flex-direction: column;
    position: relative;
    font-family: 'Inter', sans-serif;
    overflow: visible;
  }

  & [role='dialog'] > div:first-child {
    display: flex;
  }
`;

export const CloseBtn = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 0;

  & svg {
    color: rgba(0, 0, 0, 0, 4);
  }
`;

export const QuestionsContainer = styled.div`
  width: 770px;
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    margin: 0;
    width: 95vw;
  } ;
`;

export const QuestionContainerMain = styled.div`
  width: 900px;
  margin-bottom: 8px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  /* color: theme.palette.text.clear; */
  flex-direction: column;
  @media (max-width: 1280px) {
    width: 70px;
    margin: 0 auto;
    margin-top: 16px;
    justify-content: center;
  }
  @media (max-width: 720px) {
    width: 0;
    padding: 0;
    margin-bottom: -65px;
  }
`;

export const FilterDesktop = styled.div``;

export const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ece6e4;
  border-radius: 16px;
  padding: 24px;
  min-height: 268px;

  @media (max-width: 640px) {
    display: none;
  }
`;

export const ActiveFiltersList = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const HideContainer = styled.div`
  width: 100%;
  max-height: 800px;
  transition: 350ms ease-in-out;
  opacity: 1;

  ${({ hide }) =>
    hide &&
    css`
      max-height: 0px;
      padding: 0px;
      opacity: 0;
      overflow: hidden;
    `}
`;

export const FilterBtn = styled.div`
  width: ${({ responsive }) => (responsive ? '100%' : 'fit-content')};
  height: 36px;
  padding: 0px 16px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #fff;
  cursor: pointer;

  margin-top: 16px;

  align-self: ${({ responsive }) => (responsive ? 'center' : 'flex-end')};
  background: rgba(0, 0, 0, 0.4);

  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 640px) {
    display: ${({ responsive }) => !responsive && 'none'};
  }
`;

export const DefaultFilters = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const AdditionalFilters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  & span {
    font-size: 11px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.6);
    text-transform: uppercase;
  }
`;

export const ExtraOptionsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & span {
    font-size: 13px;
    text-transform: none;
  }

  ${({ responsive }) =>
    responsive &&
    css`
      & > div > *:not(:last-child) {
        margin-bottom: 8px;
      }
    `}
`;

export const ExtraOption = styled.div`
  width: fit-content;
  height: 40px;
  padding: 0px 16px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;

  background: ${({ selected }) => (selected ? '#fff' : 'transparent')};
  border: ${({ selected }) =>
    selected ? 'none' : '1px solid rgba(0,0,0,0.16)'};

  margin-right: 8px;

  &:last-of-type {
    margin-right: 0px;
  }

  &:hover {
    background: ${({ selected }) =>
      selected ? '#fff' : 'rgba(255,255,255,0.3)'};
  }
`;

export const SelectWrapper = styled.div`
  width: 100%;
  display: flex;

  & > div {
    padding-right: 16px;

    &:last-of-type {
      padding-right: 0px;
    }
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  font-weight: 500;

  width: ${({ width }) => width};

  & > span {
    font-size: 11px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.6);
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  & .Mui-checked {
    color: #fc7954 !important;
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  padding: 0px;

  & svg {
    width: 20px;
    height: 20px;
  }
`;

export const QuestionsContent = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 8px;
  }
`;

export const SearchResults = styled.div`
  padding-bottom: 8px;
  margin-top: 8px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.4);

  & > strong {
    color: rgba(0, 0, 0, 0.75);
  }
`;

export const CardRoot = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  font-family: 'Inter';
`;

export const CardHeader = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  padding: 16px 24px;
`;

export const CardContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 16px 80px 40px 80px;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);

  @media (max-width: 720px) {
    padding: 24px 24px 40px 24px;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 72px;
  height: 56px;

  ${({ openFooterContent }) =>
    !openFooterContent &&
    css`
      box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
    `}

  &, & * {
    font-family: 'Inter';
  }
`;

export const FooterContent = styled.section`
  overflow: hidden;

  & > div {
    display: flex;
    align-items: start;
    justify-content: end;
    padding: 56px 80px 40px 80px;
    font-size: 13px;
    color: #444444;
    transition: opacity 500ms;

    & > p {
      flex: 1;
      margin: 0px;
      white-space: pre-line;
    }

    & > button {
      background: #f2eeed;
      color: #808080;
      margin-left: 16px;
      padding: 8px;

      &:hover {
        background: #f2eeed;
      }

      & svg {
        height: 20px;
        width: 20px;
      }
    }
  }

  ${({ openFooterContent }) =>
    openFooterContent &&
    css`
      box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
    `}

  ${({ openFooterContent }) =>
    !openFooterContent &&
    css`
      max-height: 0px;

      & > div {
        poiinter-events: none;
        opacity: 0;
      }
    `}
`;

export const TabsFooter = styled(Tabs).attrs({
  TabIndicatorProps: {
    style: { background: '#FC7954' },
  },
})`
  height: 100%;

  & > div > div {
    height: 100%;
    display: flex;
    align-items: center;
  }

  & button {
    min-height: fit-content;
    text-transform: none;
    height: 100%;
    min-width: fit-content;
    padding: 0px 12px;
    color: #585858;
    opacity: 1;

    &:disabled {
      color: #b0b0b0;
      opacity: 1;
      cursor: no-drop;
      pointer-events: auto;
    }

    &[class*='Mui-selected'] {
      color: #fc7954;
    }

    & > span:first-child {
      display: flex;
      flex-direction: row;
      align-items: center;

      & svg {
        height: 18px;
        width: 18px;
        margin-right: 4px;
        margin-bottom: 0px !important;
      }
    }
  }
`;

export const Accordion = styled(MuiAccordion).attrs({
  disableGutters: true,
  elevation: 0,
})`
  border-radius: 0px !important;
  width: 100%;

  &:not(:first-child) {
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.12);
  }

  &:not(:last-child) {
    border-bottom: 0;
  }

  &:before {
    display: none;
  }

  &[class*='Mui-disabled'] {
    background: transparent;
    cursor: no-drop;

    & * {
      color: #b0b0b0;
      opacity: 1;
    }
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  padding: 0px 24px;
  min-height: fit-content !important;
  font-size: 14px;
  color: #808080;
  font-weight: 500;

  &,
  & * {
    cursor: pointer;
  }

  & > div {
    display: flex;
    margin: 16px 0px !important;

    & > label {
      flex: 1;

      & > svg {
        height: 16px;
        width: 16px;
      }

      & > :not(:last-child) {
        margin-right: 8px;
      }
    }

    & > svg {
      height: 20px;
      width: 20px;
    }
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  padding: 0px 24px 24px 24px;
  font-size: 13px;
  line-height: 20px;
  color: #444444;

  & > p {
    margin: 0px;
  }
`;

export const SupportingText = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const SupportingTextLink = styled.div`
  font-family: 'Inter';
  font-size: 12px;
  cursor: pointer;

  svg: {
    fontsize: '20px';
  }

  @media (max-width: 1024px) {
    margin-left: 0%;
  }
`;

export const SupportingTextContent = styled.div`
  font-family: 'Inter';
  font-size: 12px;
  margin-top: 4px;

  img {
    max-width: 100%;
  }
  @media (max-width: 1024px) {
    margin-left: 0%;
  }
`;

export const HeaderIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background: #f2eeed;
  border-radius: 50%;

  & svg {
    width: 20px;
    height: 20px;
    fill: #fc7954;
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

export const HeaderTitle = styled.div`
  width: calc(100% - 52px);
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  font-size: 13px;
  line-height: 18px;

  & > div > span {
    & > label {
      font-weight: 500;
      color: #585858;
    }

    & > span {
      font-weight: 400;
      color: #b0b0b0;
      margin: 0px 4px;
    }
  }

  @media (max-width: 720px) {
    margin-left: 0px;
    width: 100%;
  }
`;

export const QuestionTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 720px) {
    padding-left: 0px;
  }
`;

export const QuestionStatement = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  & > :not(:first-child) {
    margin-top: 16px;
  }
`;

export const QuestionText = styled.div`
  width: 100%;

  &,
  & * {
    ${({ comment }) =>
      comment
        ? css`
            font-size: 14px;
            line-height: 20px;
          `
        : css`
            font-size: 15px;
            line-height: 22px;
          `}

    color: #444444;
    margin: 0;
  }

  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const QuestionAnswerContainer = styled.div`
  margin-left: 40px;

  & > :not(:first-child) {
    margin-top: 32px;
  }

  @media (max-width: 720px) {
    margin-left: 16px;
  }
`;

export const QuestionAlternatives = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 16px;

    @media (max-width: 720px) {
      margin-bottom: 12px;
    }

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

export const Alternative = styled.div`
  display: flex;
  align-items: center;

  & .option {
    margin-left: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #444444;

    ${({ disabled }) =>
      disabled &&
      css`
        color: #b0b0b0;
      `}

    & p {
      margin: 0;
    }
  }

  & .Mui-checked {
    color: #4299fc !important;
  }
`;

export const RadioBtn = styled(Radio)`
  padding: 0px !important;
  color: #444444;

  & svg {
    width: 20px;
    height: 20px;
    color: #444444;

    ${({ disabled }) =>
      disabled &&
      css`
        color: #b0b0b0;
      `}
  }
`;

export const AnswerBtn = styled.button`
  width: fit-content;
  height: 40px;
  border-radius: 8px;
  margin-right: 16px;

  border: none;
  background-color: #fc7954;
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;

  font-family: 'Inter';
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;

  &:disabled {
    background: #cccccc;
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    background-color: rgb(233, 91, 52);
    color: #fff;
  }

  @media (max-width: 400px) {
    font-size: 13px;
    line-height: 20px;
    height: 36px;
    padding: 0px 12px;
  }
`;

export const AnswerContainer = styled.div`
  display: flex;

  ${({ responsive }) =>
    responsive &&
    css`
      flex-direction: column;

      & > button {
        width: 100%;
      }

      & > div {
        margin-top: 8px;
        align-self: center;
      }
    `}
`;

export const AnswerBox = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  border-radius: 8px;

  background: ${({ correct }) => (correct ? '#EDFFF8' : '#FCDEE2')};
  border: ${({ correct }) =>
    correct ? '1px solid #8BCFB3' : '1px solid #F69DA7'};

  & .iconAnswer {
    transform: ${({ correct }) =>
      correct ? 'rotate(0deg)' : 'rotate(180deg)'};
    width: 24px;
    height: 24px;

    color: ${({ correct }) => (correct ? '#2DB87E' : '#E05162')};
  }

  & .answerResult {
    color: ${({ correct }) => (correct ? '#2DB87E' : '#E05162')};
  }
`;

export const AnswerText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  & .answerResult {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
  }

  & .correctOption {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;

    color: rgba(0, 0, 0, 0.6);
  }
`;

export const AnswerComment = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  background: rgba(178, 178, 178, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export const AnswerCommentHeader = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  & svg {
    width: 12px;
    height: 12px;
    color: #f8a947;
  }

  & span {
    margin-left: 8px;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;

    color: rgba(0, 0, 0, 0.8);
  }
`;

export const AnswerCommentBox = styled.div`
  width: 100%;
  padding: 16px 16px 32px 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
`;

export const CustomFormControlLabel = styled(FormControlLabel)`
  color: rgba(0, 0, 0, 0.4);

  & > span {
    padding: 0px;
    margin-right: 4px;
  }

  & * {
    color: currentColor !important;
  }

  ${({ selected }) =>
    selected &&
    css`
      color: rgba(0, 0, 0, 0.75);
    `}
`;

export const ResponsiveFilterList = styled.div`
  & > * {
    margin: 0px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

export const CardChartContent = styled.div`
  flex: 1;
  margin-top: 32px;
  max-width: 100%;

  & strong {
    color: '#444444';
    font-size: 15px;
    font-weight: 600;
  }
`;

export const ChartsContainer = styled.div`
  display: flex;
  min-height: 258px;

  @media (max-width: 720px) {
    flex-direction: column;

    & > div {
      display: flex;
      flex-direction: column;

      & > div {
        align-self: center;
        max-width: 300px;
      }

      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }

  & > div {
    flex: 1;

    & > strong {
      display: block;
      margin-bottom: 16px;
    }
  }
`;

export const LastAnswersContainer = styled.div`
  margin-top: 40px;

  & > ul {
    list-style: none;
    padding: 0px;
    margin: 4px 0px 0px 0px;
  }
`;

export const LastAnswerItem = styled.li`
  display: flex;
  padding: 12px 0px;
  color: #585858;
  font-size: 13px;

  & > span {
    flex: 1;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #e0e0e0;
  }
`;

export const AnswerLabel = styled.div`
  ${({ correct, annulled }) =>
    correct &&
    css`
      color: #2db87e;
    `}

  ${({ annulled }) =>
    annulled &&
    css`
      color: #b0b0b0;
    `}

  ${({ correct, annulled }) =>
    !correct &&
    !annulled &&
    css`
      color: #f7626b;
    `}

  & > div {
    & > :not(:last-child) {
      margin-right: 4px;
    }

    & > svg {
      height: 20px;
      width: 20px;
    }
  }
`;
