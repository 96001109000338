import React from "react";
import styled, { css } from "styled-components";
import { AiOutlineLike } from "react-icons/ai";
import { BiComment } from "react-icons/bi";

const Root = styled.button`
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.colors.surface.orangeGrayDark};
    color: #717171;
    border: none;
    border-radius: 16px;
    padding: 8px 16px;
    transition: 125ms;

    & > span {
        margin-left: 4px;
    }

    & > svg {
        height: 16px;
        width: auto;
    }

    &, & * {
        font-family: ${({ theme }) => theme.fonts.inter};
    }

    &:hover {
        background: ${({ theme }) => theme.colors.surface.main}
    }

    &:disabled {
        cursor: default;
        opacity: 0.5;
    }

    ${({ active }) => active && css`
        color: ${({ theme }) => theme.colors.status.success.main};
    `}
`;

export const Counter = styled.span`
    font-weight: 500;
`;

export default function ToggleButton({ active, disabled, onClick, children }) {
    return (
        <Root active={active} disabled={disabled} onClick={onClick}>
            {children}
        </Root>
    );
}

export function LikeButton({ count, loading, ...rest }) {
    return (
        <ToggleButton {...rest} >
            {loading ? (
                <span>Loading...</span>
            ) : (
                <AiOutlineLike />
            )}
            <Counter>{count}</Counter>
        </ToggleButton>
    )
}

export function CommentsButton({ count, ...rest }) {
    return (
        <ToggleButton {...rest}>
            <BiComment />
            <Counter>{count}</Counter>
        </ToggleButton>
    );
}