import React, { Component } from 'react'
import { withStyles } from 'material-ui/styles';
import moment from 'moment'
import sanitizeHtml from 'sanitize-html'

const styles = theme => ({
  wrapper: {
    flex: 1,
    display: 'flex',
    background: '#fff',
    maxHeight: '100vh',
    padding: '16px',
    paddingTop: '40px',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      padding: '32px',
      paddingTop: '64px',
    },
    
  },
  content: {
    ...theme.typography.clipping,
    width: '100%',
    paddingBottom: '100px'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '720px'
  },
  authorData: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '24px'
  },
  authorPhoto: {
    background: 'yellowgreen',
    color: '#fff',
    borderRadius: '50%',
    height: '40px',
    width: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '8px'
  },
  author: {
    ...theme.typography.body3,
    color: theme.palette.text.primary
  },
  date: {
    ...theme.typography.small,
    fontWeight: 'bold',
    color: theme.palette.text.clear
  },
  titleContainer: {
    ...theme.typography.display1,
    paddingTop: '24px',
    paddingBottom: '24px',
    color: theme.palette.text.primary,
    maxWidth: '90vw',
    [theme.breakpoints.up('md')]: {
      maxWidth: '70vw'
    }
  },
  section: {
    backgroundColor: theme.palette.clearOrange,
    width: 'fit-content',
    padding: '8px 16px 8px 16px',
    minWidth: '180px',
    color: '#fff',
    fontSize: '13px',
    fontWeight: '600',
    marginLeft: '-16px',
    '&:after': {
      content : '""',
      borderTop: '15px solid transparent',
      borderLeft: `7px solid ${theme.palette.clearOrange}`,
      marginRight: '-23px',
      borderBottom: '15px solid transparent',
      marginTop: '-8px',
      float: 'right'
    }
  },
  continue: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px',
    marginBottom: '60px',
    ...theme.typography.body2,
    color: theme.palette.text.clear
  },
  buttonTools: {
    position: 'absolute',
    right: '52px',
    display: 'none',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.up('lg')]: {
      left: '1200px',
    }
  },
  icon: {
    color: theme.palette.text.clearGrey,
    '&$active': {
      color: theme.palette.orange,
      borderColor: theme.palette.orange
    }
  },
  active: {}
})


class ClippingContent extends Component {


  render () {
    const {classes, content, source, title, section = {}, createdAt} = this.props
    const date = moment(createdAt, "YYYY-MM-DD");

    return (
      <div className={classes.wrapper} id="clippingContent">
        <div className={classes.container}>
          <div className={classes.authorData}>
            <div className={classes.authorPhoto}>C</div> 
            <div className={classes.clippingData}> 
              <div className={classes.author}> {source}</div>
              <div className={classes.date}> {date.format('DD.MMM.YYYY')}</div>
            </div>  
          </div>
          <div className={classes.titleContainer}>
            {title}
          </div>
          <div>
            <div className={classes.section}> {section.name}</div>
          </div>
          <div className={classes.content} dangerouslySetInnerHTML={{__html: sanitizeHtml(content, {
            allowedTags: [ 'h1','h2','h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
            'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
            'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'iframe' ],
            allowedAttributes: {
              a: [ 'href', 'name', 'target' ],
              img: [ 'src' ],
              iframe: [ 'width','height','scrolling','frameborder','allow','src','url','color','auto_play','hide_related','show_comments','show_user','show_reposts','show_teaser' ]
            }})}} />
        </div>
      </div>
    )
  }
}


export default withStyles(styles)(ClippingContent)
