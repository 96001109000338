import React, { Component } from 'react'
import { connect } from 'react-redux';
import { push } from 'react-router-redux'
import PropTypes from 'prop-types';
import ItemCurso from './ItemCurso';

class Cursos extends Component {
  constructor(props) {
    super(props);

    const { _id = false }  = props.routeParams;

    this.state = {
      modal: _id
    }
  }
  
  handleModal = (_id) => {
    this.setState({modal: _id})

    this.props.pushRouter(_id ? `/cursos/${_id}` : '/mural')
  }

  componentWillReceiveProps(nextProps) {
    const { _id } = nextProps.routeParams;

    if(this.props.routeParams._id !== _id) {
      this.setState({ modal: _id || false })
    }
  }

  render() {
    const { modal } = this.state;
    
    return (
      <div>
        Teste Curso
        <ItemCurso {...this.props} handleOpen={this.handleModal} openId={modal} />
      </div>
    )
  }
}

Cursos.propTypes = {
  pushRouter: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  pushRouter: (path) => push(path)
}

export default connect(null, mapDispatchToProps)(Cursos);
