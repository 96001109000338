import React, { Component } from 'react'
import { connect } from 'react-redux';
import _ from 'lodash'
import { withStyles } from 'material-ui/styles';
import compose from 'recompose/compose';
import Dialog from 'material-ui/Dialog';
import moment from 'moment'
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import Button from 'material-ui/Button';
import Slide from 'material-ui/transitions/Slide';
import ArrowLeft from 'material-ui-icons/ChevronLeft'
import {crudGetOne as crudGetOneAction} from '../../actions/dataActions'
import { withRouter } from 'react-router'
import ReportContent from './ReportContent';

const styles = theme => ({
  appBar: {
    top: 0,
    left: 'auto',
    right: 0,
    position: 'sticky',
    backgroundColor: '#FFF',
    transition: theme.transitions.create('width'),
    height: '65px',
    paddingRight: '24px',
    justifyContent: 'center',
    boxShadow: theme.shadows[27],
    
  },
  backContainer: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      padding: '18px'
    },
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  backIcon: {
    ...theme.typography.menu,
    color: theme.palette.text.clear,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '8px',
    paddingLeft: '0px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  responsiveDisplay: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inherit'
    }
  },

  content: {
    marginTop: '65px',
    flex: 1,
    marginLeft: '8px',
    marginRight: '8px'
  },
  toolbar: {
    padding: '0px'
  }, 
  icon: {
    color: theme.palette.text.clear,
  },
  titleContainer: {
    paddingTop: '4px',
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    ...theme.typography.title,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  subtitle: {
    ...theme.typography.body1,
    color: theme.palette.text.clear,
    textTransform: 'uppercase',
    display: 'none',
    flex: 1,
    textAlign: 'center',
    lineHeight: '1rem',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '1.8rem',
    margin: '0 auto',
  },
  menuIcons: {
    display: 'flex',
    color: theme.palette.common.white,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  dialog: {
    display: 'flex',
    alignItems: 'stretch',
    minHeight: '100vh',
    width: '100%'
  },
  slide: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: '100%'
  },
  arrowLeft: {
    height: '40px',
    width: '40px',
    color: theme.palette.black.a3,
    [theme.breakpoints.up('sm')]: {
      height: '25px',
      width: '25px',
      color: theme.palette.text.clear
    },
  },
});

class ModalReport extends Component {
  
  transition = (props) => {
    return <Slide direction="up" {...props} className={this.props.classes.slide}  />;
  }

  componentWillReceiveProps(nextProps) {
    const {open} = nextProps 
    const idRelatorio = nextProps.open;
    if((this.props.open !== open) && Boolean(open)){
      if(this.props.openModal !== undefined){
      this.props.openModal('relatorio', idRelatorio);
      }
      this.props.crudGetOne(open)
    }
  }

  render () {
    const {open = {}, handleOpen, classes, test = {}, exercicio = ''} = this.props
    const {createdAt} = test
    const testDate = moment(createdAt, "YYYY-MM-DD");


    return (
      <Dialog
          classes={{root: classes.dialog}}
          fullScreen
          open={Boolean(open)}
          onRequestClose={() => handleOpen(false)}
        >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.backContainer}>
              <Button
                disableRipple
                disableFocusRipple
                aria-label="voltar"
                onClick={!!this.props.closeRelatorioModal
                  ? ()=>this.props.closeRelatorioModal('relatorio') 
                  : ()=> handleOpen(false)}
                className={classes.backIcon}
              >
                <ArrowLeft className={classes.arrowLeft}/> <div className={classes.responsiveDisplay}>voltar</div>
              </Button>
            </div>
            <div className={classes.titleContainer}>
              <div className={classes.title}>
                  RELATÓRIO
              </div>  
              <div className={classes.subtitle}> 
                {exercicio ? `EXERCÍCIO ${exercicio}` : `SIMULADO ${testDate.format('DD/MM/YYYY')}`}
              </div>
            </div>
            <div className={classes.menuIcons}>
            </div>
          </Toolbar>
        </AppBar>
        <ReportContent {...test}/>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, {open} ) => {
  return {
    test: state.test.data[open]
  }
}

const mapDispatchToProps = {
  crudGetOne: (_id) => crudGetOneAction('test', `${_id}/user`, '', false, false)
}

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ModalReport);
