import React from 'react';
import {activeRoute} from '../../utils'
import { Link } from 'react-router';

const MobileNav = ({location}) => (
  <nav className="mobile-nav">
      <ul>
        <li className={`mural ${activeRoute('/mural', location)}`}><Link to="/mural"><i aria-hidden="true"></i> Mural</Link></li>
        <li className={`clippings ${activeRoute('/clippings', location)}`}><Link to="/clippings"><i aria-hidden="true"></i> Clippings</Link></li>
        <li className={`organizador ${activeRoute('/organizador', location)}`}><Link to="/organizador"><i aria-hidden="true"></i> Organizador</Link></li>
        <li className={`perfil ${activeRoute('/perfil', location)}`}><Link to="/perfil"><i aria-hidden="true"></i> Perfil</Link></li>
      </ul>
  </nav>
);

export default MobileNav;