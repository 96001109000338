import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function BarChart({ data }) {
	const options = {
		responsive: true,
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				callbacks: {
					label: function(context) {
						let total = 0;
						let percent = 0;
						
						for(let i = 0; i < context.dataset.data.length; i++)
							total += context.dataset.data[i];

						percent = Number((context.raw/total)*100).toFixed(2);

						return `${context.label}: ${context.raw}・${percent}%`;
					}
				}
			}
		},
	};

	return (
		<Bar options={options} data={data} />
	);
}