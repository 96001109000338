import React from 'react';
import compose from 'recompose/compose';
import NProgress from 'nprogress';
import { withStyles } from 'material-ui/styles';
import moment from 'moment';
import { Link } from 'react-router'


const styles = theme => ({
  pendingPaymentAppBar: {
    top: 50,
    '@media (max-width: 964px)': {
      top: 68
    },
    '@media (max-width: 530px)': {
      top: 86
    }
  },
  link: {
    color: '#F2F2F2',
    outline: 'none'
  },
  pendingPaymentAlert: {
    position: 'fixed',
    top: "66px", left: 0, right: 0,
    backgroundColor: '#fe3f4b',
    minHeight: 50,
    '@media (max-width: 964px)': {
      minHeight: 68
    },
    '@media (max-width: 530px)': {
      minHeight: 86
    },
    zIndex: 1300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    color: '#F2F2F2',
    fontWeight: 'bold',
    padding: 16,
  },
});
class BarraContador extends React.Component {

  constructor(props) {
    super(props);
    var eventdate = moment("2019-07-20");
    var todaysdate = moment();
    let days =   eventdate.diff(todaysdate, 'days');
    let duration = moment.duration(eventdate.diff(todaysdate));
    this.state = {hours:Math.floor(duration.asHours()),minutes:duration.minutes(), seconds:duration.seconds()};
  }

  componentDidMount() {
    this.timerID = setInterval(
      () => this.tick(),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }


  tick() {
    var eventdate = moment("2019-07-20");
    var todaysdate = moment();
    let days =   eventdate.diff(todaysdate, 'days');
    let duration = moment.duration(eventdate.diff(todaysdate));
   this.setState({ hours: (Math.floor(duration.asHours())<10?"0":"")+Math.floor(duration.asHours()),
                  minutes:(duration.minutes()<10?"0":"")+duration.minutes(),
                  seconds:(duration.seconds()<10?"0":"")+duration.seconds()});
  }

  render() {
    const {classes} = this.props
    return (
    <div className={classes.pendingPaymentAlert}><span>[URGENTE]: Faltam <span>{this.state.hours}:{this.state.minutes}:{this.state.seconds}</span> horas para o encerramento das matrículas do Intensivão do CACD 2019 - <Link className={classes.link} to={'/lista-cursos/5d13e407fe29b3d1251eea55'}>GARANTA SUA VAGA CLICANDO AQUI!</Link>.</span></div>

    );
  }
}

export default compose(
  withStyles(styles),
)(BarraContador);
