import React from "react";
import styled from "styled-components";
import { withStyles } from "material-ui/styles";
import { Button } from "@material-ui/core";

const styles = (theme) => ({
  planContainer: {
    display: "flex",
    marginBottom: "40px",
  },
  planImage: {
    width: "83px",
    height: "83px",
    minHeight: "83px",
    minWidth: "83px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  planDesc: {
    marginLeft: "32px",
    ...theme.typography.small,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 1,
  },
  planName: {
    color: theme.palette.text.basic,
    fontWeight: 600,
    fontSize: "15px",
  },
  planInfo: {
    color: theme.palette.text.basic,
  },
  planPrice: {
    color: "#71D3D9",
    fontWeight: "bold",
    lineHeight: "16px",
  },
  changePlan: {
    display: "flex",
    alignItems: "center",
    marginLeft: "16px",
  },
});

const CustomButton = styled(Button)`
  border: ${(props) =>
    props.disabled ? "1px solid rgba(0, 0, 0, 0.26)" : "1px solid #f58220"};
  color: #f58220;
  height: 40px;
  border-radius: 20px;
  font-size: 11px;

  & > span {
    padding: 0 8px;
  }
`;

function PlanItem({
  classes,
  plan,
  buttonText = "Quero esse!",
  onClick,
  disabled = false,
}) {
  return (
    <div className={classes.planContainer}>
      <div
        className={classes.planImage}
        style={{ backgroundImage: `url("${plan.image}")` }}
      ></div>
      <div className={classes.planDesc}>
        <div className={classes.planName}>{plan.name}</div>
        <div className={classes.planInfo}>{plan.description}</div>
        <div className={classes.planPrice}>
          {plan.installments > 1 ? 
            <>{plan.installments}x{(plan.price / plan.installments).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </> : <>
            {(plan.price).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </>}
        </div>
      </div>
      <div className={classes.changePlan}>
        <CustomButton disabled={disabled} onClick={onClick} type="submit">
          <span>{buttonText}</span>
        </CustomButton>
      </div>
    </div>
  );
}

export default withStyles(styles)(PlanItem);
