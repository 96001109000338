export function normalizeData(rawData) {
  return rawData.map(item => {
    item.publishedDate = `Published at ${prettyDisplayDate(item.publishedAt)}`;
    return item;
  });
}

export function prettyDisplayDate(dateStr) {
  return dateStr.substr(0, 10);
}

export const activeRoute = (routeName, location) => (
  location.pathname.indexOf(routeName) > -1 ? true : false
)