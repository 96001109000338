import React from 'react';
import Button from 'material-ui/Button';
import { withStyles } from 'material-ui/styles';
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'material-ui/Dialog';
import Close from 'material-ui-icons/Close'
import IconButton from 'material-ui/IconButton';
import { crudCreate as crudCreateAction } from '../../actions/dataActions';
import { reduxForm, Field, change, SubmissionError } from 'redux-form';
import { renderTextField } from '../../utils/form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';

const formName = 'timelinePost'

const styles = theme => ({
    container: {
      borderRadius: '4px',
      [theme.breakpoints.up('md')]: {
        minWidth: '700px'
        },
    },
    title: {
        color: theme.palette.orange,
        borderBottom: '1px solid #96969626',
        fontSize: '18px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: '600',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '80px',
        paddingLeft: '16px',
        paddingRight: '16px'
    },
    close: {
        color: theme.palette.text.clear,
        cursor: 'pointer',
        marginTop: '-15px',
        width: '20px',
        '&:hover': {
            color: theme.palette.orange,
        },
    },
    content: {
        padding: '18px',
    },
    error: {
        paddingLeft: '24px',
        ...theme.typography.error,
        marginBottom: '16px',
        float: 'right',
        paddingRight: '24px'
    },
})

class ModalAddPost extends React.Component {

  handleClose = () => {
    this.props.handleOpen(false);
    this.props.reset();
  };

  createPost = (post) => {
    const { text, title } = post;
    const { profile, crudCreate, trackMetric } = this.props;
    const { facebookId } = profile;

    if(!text || !title) {
        throw new SubmissionError({ 
            text: !text ? 'Seu post deve ter algum conteúdo.' : false, 
            title: !title ? 'Dê um título ao seu post.' : false, 
            _error: 'Preencha o título e o conteúdo do post antes de publicar!' 
        });
    }

    trackMetric('ListaCursos - Ativação', { 
        target: 'publish',
        trigger: 'click',
        action: 'create'
    });

    const data =  {
        userSocialId: facebookId || '',
        user: profile._id.toString(),
        text, 
        title
    }

    crudCreate(formName, data, '/lista-cursos', false, 'modal-add-post');
    this.handleClose();
  }

  render() {
    const {classes, handleSubmit, open, error} = this.props
    
    return (
        <Dialog
            open={Boolean(open)}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
            classes={{paper: classes.container}}
        >
            <DialogTitle disableTypography className={classes.title}>
                Adicionar Post
                <div className={classes.close} >
                    
                </div>
                <IconButton
                    className={classes.close}
                    aria-label="Fechar"
                    onClick={this.handleClose} >
                        <Close/> 
                    </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(this.createPost)}>
            <DialogContent className={classes.content}>
                <Field fullWidth autoFocus margin="dense" label="Título" name="title" id="title" component={renderTextField} />
                <Field multiline fullWidth margin="dense" label="Meu post..." name="text" id="text" component={renderTextField} />   
            </DialogContent>
            <DialogActions>
                <Button type="submit" color="primary">
                    PUBLICAR
                </Button>
            </DialogActions>
            {error && <div className={classes.error}>{error}</div>}
            </form>
        </Dialog>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
    return  {
        submitting: state.waiting,
        profile: state.user.profile
    }
}
  
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        trackMetric: (name, data) => dispatch(trackMetricAction(name, data)),
        changeForm: (field, value) => {
            dispatch(change(formName, field, value))
        },
        crudCreate : (...params) => {
            dispatch(crudCreateAction(...params))
        }
    }
}

const enhance = compose(
    withStyles(styles),
    reduxForm({
        form: formName,
        validate: (values, props) => {
            const errors = {};
            if (!values.title) {
                errors.title = 'Dê um título ao seu post.';
            }
            if(!values.text) {
                errors.text = 'Seu post deve ter algum conteúdo.';
            }
            return errors;
        }
    }),
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(ModalAddPost)
