import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    DELETE,
    LIKE,
    GET_LIST_BY_ONE,
    CUSTOM
} from './rest_types';

import {
    fetchJson,
    queryParameters,
    handleFilters,
    handleSort
} from '../utils/fetch'

import { BaseUrl } from '../config'

export const API_URL = `${BaseUrl}`;
export const API_PAGARME = `${BaseUrl}/pagarme`;

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The REST request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertRESTRequestToHTTP = (type, resource, params) => {
    let url = '';
    const options = {};
    //Auth
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    const token = localStorage.getItem('token');
    
    options.headers.set('Authorization', token);
    const {filters, $embed, concursoId, urlParams, payload} = handleFilters(params.filter, params.concurso, resource)
    
    switch (type) {
      case GET_LIST: {
        const sort =  handleSort(params.sort)
        const { page = 1, perPage = 10 } = params.pagination;

        const query = {
            ...urlParams,
            ...filters,
            ...payload,
            "isDeleted": false,
            $sort: sort ,
            $skip: (page - 1) * perPage,
            $limit: perPage || 10,
            $page: page
        };

        url = `${API_URL}/${resource}?${concursoId}${$embed}${queryParameters(query)}`;
        break;
      }
      case GET_LIST_BY_ONE:
      case GET_ONE:
        const query = {
            ...filters
        }
        url = params.noFilter ? `${API_URL}/${resource}/${params.id}?${$embed}` : `${API_URL}/${resource}/${params.id}?${concursoId}${$embed}${queryParameters(query)}`;
        break;
      case GET_MANY_REFERENCE: {
        const sort =  handleSort(params.sort)
        const { page = 1, perPage = 10 } = params.pagination;
        const query = {
            ...filters,
            "isDeleted": false,
            $sort: sort ,
            $skip: (page - 1) * perPage,
            $limit: perPage || 10,
            $page: page
        };
        url = `${API_URL}/${resource}?${concursoId}${$embed}${queryParameters(query)}`;
        break;
      }
      case UPDATE:
          url = `${API_URL}/${resource}${params.id ? '/' + params.id : ''}`;
          options.method = 'PUT';
          options.body = JSON.stringify(params.data);
          break;
      case CREATE:
          url = `${API_URL}/${resource}`;
          options.method = 'POST';
          const bodyData = resource.indexOf("Content") === -1 ? {...params.data, concursoId: params.concurso.concursoId} : params.data
          options.body = JSON.stringify(bodyData);
          break;
      case DELETE:
          url = `${API_URL}/${resource}/${params.id}`;
          options.method = 'DELETE';
          break;
      case LIKE:
          url = `${API_URL}/${resource}/${params.id}/addLikeCount`;
          options.method = 'PUT';
          break;
      case CUSTOM:
          url = `${API_URL}/${resource}`;
          if(params.urlParams){
            url = `${url}?${params.urlParams}`
          }
          options.method = params.customMethod;
          if(params.customMethod !== 'GET') {
            options.body = JSON.stringify(params.data);
          }
          break;
      default:
          throw new Error(`Unsupported fetch action type ${type}`);
      }
      return { url, options };
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The REST request params, depending on the type
 * @returns {Object} REST response
 */
const convertHTTPResponseToREST = (response, type, resource, params) => {
    const { json } = response;

    if(Boolean(response.headers.get('x-auth-header'))) {
        localStorage.setItem('token', response.headers.get('x-auth-header'));
    }

    switch (type) {
    case GET_LIST:
    case GET_MANY_REFERENCE:
    case GET_LIST_BY_ONE:
        if (!json.items || json.items.total === undefined) {
            throw new Error('Return the total of documents in the response items!');
        }
        return {
            data: json.docs,
            total: json.items.total,
        };
    case CREATE:
        return { data: { ...params.data, ...json } };
    case LIKE:
        return { data: { ...params, like: json } };
    case DELETE:
        return { data: 'deleted' };    
    case CUSTOM: 
        return { data: json || 'success' }    
    default:
        return { data: json };
    }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a REST response
 */
export default (type, resource, params) => {
  if (type === GET_MANY) {
      return Promise.all(params.ids.map(id => fetchJson(`${API_URL}/${resource}/${id}`)))
          .then(responses => ({ data: responses.map(response => response.json) }));
  }
  const { url, options } = convertRESTRequestToHTTP(type, resource, params);
  return fetchJson(url, options)
      .then(response => convertHTTPResponseToREST(response, type, resource, params));
};