export const SET_COMMENTS = 'SET_COMMENTS';
export const ADD_COMMENT = 'ADD_COMMENT';
export const SET_ANSWERS = 'SET_ANSWERS';
export const ADD_ANSWERS = 'ADD_ANSWERS';
export const PAGINATION_ADD_COMMENTS = 'PAGINATION_ADD_COMMENTS';
export const PAGINATION_ADD_ANSWERS = 'PAGINATION_ADD_ANSWERS';
import { CLEAR } from '../services/rest_types';

export const setQuestionComments = (resource, comments, questionId) => ({
  type: SET_COMMENTS,
  payload: { resource, comments, questionId },
});

export const addQuestionComment = (resource, comment, questionId) => ({
  type: ADD_COMMENT,
  payload: { resource, comment, questionId },
});

export const setAnswers = (resource, answers, questionId, commentId) => ({
  type: SET_ANSWERS,
  payload: { resource, answers, questionId, commentId },
});

export const addAnswers = (resource, answer, questionId, commentId) => ({
  type: ADD_ANSWERS,
  payload: { resource, answer, questionId, commentId },
});

export const paginationAddComments = (resource, comments, questionId) => ({
  type: PAGINATION_ADD_COMMENTS,
  payload: { resource, comments, questionId },
});

export const paginationAddAnswers = (
  resource,
  answers,
  questionId,
  commentId
) => ({
  type: PAGINATION_ADD_ANSWERS,
  payload: { resource, answers, questionId, commentId },
});

export const removeComment = (resource, commentId, questionId) => {
  return {
    type: '@comments/DELETE_COMMENT',
    payload: { resource, commentId, questionId },
  };
};

export const removeCommentAnswer = (
  resource,
  commentId,
  questionId,
  parentCommentId
) => {
  return {
    type: '@comments/DELETE_COMMENT_ANSWER',
    payload: { resource, commentId, questionId, parentCommentId },
  };
};

export const updateAnswerCount = (resource, type, questionId, commentId) => {
  return {
    type: '@comments/UPDATE_ANSWER_COUNT',
    payload: { resource, type, questionId, commentId },
  };
};
