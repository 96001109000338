import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { Check } from "@material-ui/icons";
import { Checkbox } from "material-ui";

import TermsOfUse from "./TermsOfUse";
import Alert from "./Alert";

import {
  setChoosePaymentOpen,
  setCreditPayModalInstallments,
  setCreditPayModalTitle,
} from "../Payments/actions";

import { setCartProducts, checkoutSetGoogleGA } from "./actions";

const PackageItem = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 100%;
  width: 440px;
  padding-left: 32px;
  padding-right: 32px;

  @media (max-width: 1334px) {
    min-height: 497px;
    height: fit-content;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 30px;
    margin-bottom: 36px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 578px) {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: none;
  }
`;

const PackageTitle = styled.div`
  width: 100%;
  height: fit-content;
  color: rgba(0, 0, 0, 0.75);
  font-size: 25px;
  font-weight: bold;
  padding-bottom: 8px;
`;

const PackageSubtitle = styled.div`
  color: rgba(0, 0, 0, 0.75);
  font-size: 15px;
  font-weight: normal;
  min-height: 60px;
  margin-bottom: 20px;
`;

const Module = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 578px) {
    width: auto;
  }
`;

const Modules = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModuleTitle = styled.div`
  flex: 1;
  align-items: center;
  max-height: 32px;
  color: rgba(0, 0, 0, 0.75);
  font-size: 15px;
  font-weight: 600;
  padding-left: 8px;
`;

const ModuleInfo = styled.div`
  display: flex;
  margin: 0;
  min-height: 32px;
  height: fit-content;
  align-items: center;
  background: white;
  margin-bottom: ${(props) => (props.last ? "30px" : "8px")};
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 3px 0px 3px 8px;
`;

const PaymentInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

const PaymentButton = styled.button`
  font-family: "Proxima Nova";
  width: 240px;
  height: 52px;
  background-color: #fc7954;
  border: 2px solid rgb(252, 121, 84);
  color: white;
  border-radius: 26px;
  font-size: 16px;
  font-weight: 600;
  transition: background 0.2;
  &:hover {
    background: #e95b34;
    border: #e95b34;
  }
  @media (max-width: 578px) {
    width: 100%;
  }
`;

const PaymentDisabledButton = styled.button`
  font-family: "Proxima Nova";
  width: 240px;
  height: 52px;
  background-color: #f7f8f9;
  border: 2px solid rgba(97, 97, 98, 0.08);
  color: rgba(0, 0, 0, 0.3);
  border-radius: 26px;
  font-size: 16px;
  font-weight: 600;
  cursor: not-allowed;
  @media (max-width: 578px) {
    width: 100%;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  /* @media (max-width: 1330px) {
    margin-top: 30px;
  } */
`;

const PriceInstallments = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
`;

const CashPriceContainer = styled.div`
  color: rgba(0, 0, 0, 0.5);
`;

const PricePrefix = styled.span`
  height: fit-content;
  color: rgba(0, 0, 0, 0.5);
  font-size: 25px;
  font-weight: 300;
`;

const Price = styled.span`
  height: fit-content;
  color: rgba(0, 0, 0, 0.75);
  font-size: 25px;
  font-weight: bold;
  padding-left: 4px;
`;

const Installments = styled.div`
  height: fit-content;
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  font-weight: normal;
  margin-top: auto;
`;

const Terms = styled.div`
  display: flex;
  align-items: center;
  color: rgb(0, 0, 0, 0.5);
  margin-bottom: 8px;
  margin-top: 35px;
`;

const TermsText = styled.span`
  font-size: 13px;
  font-weight: bold;
`;

const TermsCheckBox = styled(Checkbox)`
  .MuiSvgIcon-root {
    fill: ${(props) =>
      props.checked ? "rgb(252, 121, 84)" : "rgba(0, 0, 0, 0.54)"};
  }
`;

const TermsLink = styled.a`
  text-decoration: underline;
  color: rgb(25, 83, 171);
  cursor: pointer;
`;

const DiscountMessage = styled.span`
  color: #31c98e;
  font-size: 16px;
  margin-left: 10px;
`;

const GreenCheck = styled(Check)`
  color: #31c98e;
`;

function FullPackage() {
  const dispatch = useDispatch();
  const packages = useSelector(
    (state) => state.checkout.currentCheckout.data.pacote_completo
  );

  const userID = useSelector((state) => state.userData.data.id);
  const { ano, categoria, concurso, nome } = useSelector(
    (state) => state.checkout.currentCheckout
  );

  const [openTerms, setOpenTerms] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [termChecked, setTermChecked] = useState(false);

  // const userPayments = useSelector(
  //   (state) => state.paymentGroup.userPayments.data
  // );

  function handlePayment() {
    const itemsPayload = [];
    for (const item of packages.pacotes) {
      let payload = {
        id: item.id,
        name: `${item.curso}-modulo-${item.modulo}`,
        category: `${categoria}-${ano}`,
        brand: concurso,
        price: item.value,
        quantity: 1,
        variant: "pacote completo",
      };
      itemsPayload.push(payload);
    }

    const purchasePayload = {
      id: `${userID}`,
      affiliation: concurso,
      revenue: packages.a_vista,
      tax: 0,
      shipping: 0,
    };

    dispatch(
      checkoutSetGoogleGA({ items: itemsPayload, purchase: purchasePayload })
    );

    dispatch(setCreditPayModalInstallments(packages.parcelas));
    dispatch(
      setCreditPayModalTitle(`Compra ${String(packages.titulo).toLowerCase()}`)
    );
    dispatch(
      setCartProducts({
        items: [packages.pacotes[0]],
        price: {
          parcelado: packages.valor_prazo,
          a_vista: packages.a_vista,
        },
      })
    );
    dispatch(setChoosePaymentOpen(true));
  }

  function getPriceTerm(valor, parcelas) {
    return (valor / parcelas).toFixed(2).replace(".", ",");
  }

  function PriceComposer() {
    if (packages.parcelas === 1) {
      return (
        <PriceContainer>
          <Price>R${packages.a_vista.toFixed(2).replace(".", ",")}</Price>
        </PriceContainer>
      );
    }

    return (
      <PriceContainer>
        <Installments>{packages.parcelas}x de</Installments>
        <PriceInstallments>
          <PricePrefix>R$</PricePrefix>
          <Price>{getPriceTerm(packages.valor_prazo, packages.parcelas)}</Price>
          {packages.texto_a_prazo !== null && (
            <DiscountMessage>{packages.texto_a_prazo}</DiscountMessage>
          )}
        </PriceInstallments>
        <CashPriceContainer>
          OU <b>R${packages.a_vista.toFixed(2).replace(".", ",")}</b> À VISTA
          {packages.texto_a_vista !== null && (
            <DiscountMessage>{packages.texto_a_vista}</DiscountMessage>
          )}
        </CashPriceContainer>
      </PriceContainer>
    );
  }

  return (
    <PackageItem>
      <PackageTitle>{packages.titulo}</PackageTitle>
      <PackageSubtitle>{packages.sub_titulo}</PackageSubtitle>
      <Alert
        message={`Você já possui o ${packages.titulo}.`}
        open={alertOpen}
        setClose={() => setAlertOpen(false)}
      />
      {
        <Modules>
          {packages.cursos.length > 0
            ? packages.cursos.map((curso, index) => {
                return (
                  <Module key={curso}>
                    <ModuleInfo last={packages.cursos.length - 1 === index}>
                      <GreenCheck />
                      <ModuleTitle>{curso}</ModuleTitle>
                    </ModuleInfo>
                  </Module>
                );
              })
            : "Não há Pacotão disponível."}
        </Modules>
      }
      {packages.cursos.length > 0 && (
        <PaymentInfo>
          <PriceComposer />
          <Terms>
            <TermsCheckBox
              checked={termChecked}
              defaultChecked={termChecked}
              onChange={() => setTermChecked(!termChecked)}
            />
            <TermsText>
              Declaro que li e concordo com os {"  "}
              <TermsLink onClick={() => setOpenTerms(true)}>
                Termos de Uso e Política de Privacidades
              </TermsLink>
            </TermsText>
          </Terms>
          {termChecked ? (
            <PaymentButton onClick={() => handlePayment()}>
              {packages.buttom ? packages.buttom : "Efetuar pagamento"}
            </PaymentButton>
          ) : (
            <PaymentDisabledButton>
              {packages.buttom ? packages.buttom : "Efetuar pagamento"}
            </PaymentDisabledButton>
          )}
          <TermsOfUse open={openTerms} closeModal={setOpenTerms} />
        </PaymentInfo>
      )}
    </PackageItem>
  );
}

export default FullPackage;
