import React, { Component } from 'react'
import { connect } from 'react-redux';
import { replace, goBack } from 'react-router-redux';
import { withStyles } from 'material-ui/styles';
import compose from 'recompose/compose';
import Dialog from 'material-ui/Dialog';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import Button from 'material-ui/Button';
import Close from 'material-ui-icons/Close'
import Container from './Container'
import Drawer from '../../components/DrawerProfile'
import {
  profileUpdate as profileUpdateAction,
  kindleConnect as kindleConnectAction,
  kindleDisconnect as kindleDisconnectAction,
  updatePassword as updatePasswordAction,
  getAccountData as getAccountDataAction,
  updateSubscription as updateSubscriptionAction,
  facebookConnect as facebookConnectAction,
  cancelSubscription as cancelSubscriptionAction,
  facebookDisconnect as facebookDisconnectAction } from '../../actions/profileActions'
  import { crudUpdate as crudUpdateAction } from '../../actions/dataActions';
import {setMainConcurso} from '../../actions/userActions'
import { crudGetList } from '../../actions/dataActions'
import { SORT_ASC } from '../../reducers/resource/list/queryReducer'
import MenuIcon from 'material-ui-icons/MoreVert'
import IconButton from 'material-ui/IconButton';

// ##### ICONS #####
import Videocam_Outline from '../../assets/img/icon/videocam-outline.svg';
import Portrait from '../../assets/img/icon/stay-current-portrait.svg';
import Lock_Open from '../../assets/img/icon/lock-open.svg';
import Credit_Card from '../../assets/img/icon/credit-card.svg';
import Rounded_Person from '../../assets/img/icon/rounded-person.svg';

const styles = theme => ({
  appBar: {
    top: 0,
    left: 'auto',
    right: 0,
    position: 'sticky',
    backgroundColor: '#FFF',
    transition: theme.transitions.create('width'),
    height: '65px',
    [theme.breakpoints.up('sm')]: {
      paddingRight: '24px',
    },
    justifyContent: 'center',
    boxShadow: theme.shadows[27],
    fontFamily: 'Proxima Nova',
  },
  backContainer: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      padding: '18px'
    },
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  backIcon: {
    ...theme.typography.menu,
    color: theme.palette.text.clear,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '8px',
    paddingLeft: '0px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  responsiveDisplay: {
    ...theme.typography.back,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inherit'
    }
  },
  toolbar: {
    padding: '0px'
  },
  icon: {
    color: theme.palette.text.clear,
  },
  titleContainer: {
    ...theme.typography.title,
    paddingTop: '4px',
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    
  },
  menuIcons: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
  },
  dialog: {
    display: 'flex',
    alignItems: 'stretch',
    minHeight: '100vh',
    width: '100%',
  },
  close: {
    height: '35px',
    width: '35px',
    color: theme.palette.black.a3,
  },
  menuIcon: {
    color: theme.palette.black.a3,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
});

const sections = [
  {
    name: 'Conta',
    pathname: 'conta',
    icon: {
      item: Rounded_Person,
      custom: true,
    }
  },
  {
    name: 'Plano e Cobrança',
    pathname: 'plano-cobranca',
    icon: {
      item: Credit_Card,
      custom: true,
    }
  },
  {
    name: 'Alterar senha',
    pathname: 'alterar-senha',
    icon: {
      item: Lock_Open,
      custom: true,
    }
  },
  {
    name: 'Kindle',
    pathname: 'kindle',
    icon: {
      item: Portrait,
      custom: true,
    }
  },
  // {
  //   name: 'Cursos / Pagamentos',
  //   pathname: 'historico-pacotes',
  //   icon: {
  //     item: Videocam_Outline,
  //     custom: true,
  //   }
  // },
]

class Perfil extends Component {
  constructor(props) {
    super(props)

    var section = props.routeParams && props.routeParams.section;
    
    this.state = {
      section: section || 'conta',
      mobileOpen: false
    }
  }

  closeDrawer = () => this.setState({ mobileOpen: false });

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    this.props.getAccountData()
    this.props.loadTransactions()
    this.props.loadPlans()
  }

  handleRouteProfile = (section) => {
    this.setState({ section });
    this.props.replaceRouter(`/perfil/${section}`)
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }

  closeDrawer = () => {
    this.setState({ mobileOpen: false });
  }

  updateMainConcurso = (_id, name) => {
    this.props.crudUpdate('userProfile', this.props.profile._id, {mainConcurso: _id}, {}, '/', false, true, `Concurso principal alterado para ${name}` )
    this.props.setMainConcurso(_id)
  }
  
  changeAvatar = (avatar) => {
    this.props.crudUpdate('userProfile', this.props.profile._id, {avatar}, {}, '/', false, true, '' );
  }

  handleGoBack = () => {
    var { goBack, replaceRouter } = this.props;
    var prevLocation = document.location.href;

    goBack();

    setTimeout(() => {
      var currentLocation = document.location.href;
      if(prevLocation === currentLocation) {
        replaceRouter('/mural');
      }
    }, 500);
  }

  render () {
    const { classes, location, ...props } = this.props
    const { section } = this.state

    return (
      <Dialog
          classes={{root: classes.dialog}}
          fullScreen
          open={true}
          transitionDuration={500}
        >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.backContainer}>
              <Button
                disableRipple
                disableFocusRipple
                aria-label="voltar"
                onClick={() => this.handleGoBack()}
                className={classes.backIcon}
              >
                <Close className={classes.close}/> <div className={classes.responsiveDisplay}>fechar</div>
              </Button>
            </div>
            <div className={classes.titleContainer}>
                PERFIL
            </div>
            <div className={classes.menuIcons}>
              <IconButton
                aria-label="Menu do Perfil"
                onClick={this.handleDrawerToggle}
              >
                <MenuIcon className={classes.menuIcon} />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          pushRouter={this.handleRouteProfile}
          location={location}
          disablePermanent={false}
          onRequestClose={this.handleDrawerToggle}
          mobileOpen={this.state.mobileOpen}
          closeDrawer={this.closeDrawer}
          items={sections}
          section={section}
        />
        <Container
          {...props}
          section={section}
          sections={sections}
          handleRouteProfile={this.handleRouteProfile}
          updateMainConcurso={this.updateMainConcurso}
          changeAvatar={this.changeAvatar}
        />
      </Dialog>
    )
  }
}

const mapStateToProps = (state, {open} ) => {
  return {
    profile: state.user.profile,
    subscription: state.user.subscription,
    plans: state.plan.data,
    transactions: state['subscription/pagarme/transactions'].data,
    concursos: state.concurso.data
  }
}

const mapDispatchToProps = {
  profileUpdate: (_id, data, customMessage) => profileUpdateAction(_id, data, false, true, customMessage),
  kindleConnect: (_id, data, customMessage) => kindleConnectAction(_id, data, false, true, customMessage),
  kindleDisconnect: (_id, customMessage) => kindleDisconnectAction(_id, customMessage),
  updatePassword: (data, customMessage) => updatePasswordAction(data, customMessage),
  getAccountData: () => getAccountDataAction(),
  loadTransactions: () => crudGetList('subscription/pagarme/transactions', {page: 1, perPage: 6}, {field: 'createdAt', order: SORT_ASC}, {}, false),
  loadPlans: () => crudGetList('plan', {page: 1, perPage: 999}, {field: 'createdAt', order: SORT_ASC}, {}, false),
  cancelSubscription: (data) => cancelSubscriptionAction(data, 'Seu plano não sera mais renovado automaticamente.'),
  updateSubscription: (data, customMessage) => updateSubscriptionAction(data, customMessage),
  facebookConnect: (token) => facebookConnectAction(token, 'Facebook conectado com sucesso!'),
  facebookDisconnect: () => facebookDisconnectAction('Facebook desconectado'),
  crudUpdate : (...params) => crudUpdateAction(...params),
  setMainConcurso: (concurso) => setMainConcurso(concurso),
  replaceRouter: (path) => replace(path),
  goBack: () => goBack()
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(Perfil);
