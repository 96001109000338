import React from 'react';
import { withStyles } from 'material-ui/styles';
import classNames from 'classnames';

const styles = theme => ({
  wrapper: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      paddingRight: '250px',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  oldRoute: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  img: {
    minHeight: '200px',
    minWidth: '300px',
    [theme.breakpoints.up('sm')]: {
      minWidth: '500px',
    }
  },
  container: {
    textAlign: 'center',
    marginTop: '40px'
  },
  error: {
    ...theme.typography.modalTitle,
    color: theme.palette.orange,
    fontWeight: 'normal'
  },
  message: {
    ...theme.typography.modalTitle,
    color: theme.palette.text.clear,
    fontWeight: 'normal'
  }
})

function NotFound({classes, oldRoute=false}) {
  return (
    <div className={oldRoute ? classes.oldRoute : classes.wrapper}>
      <div className={classNames("not-found", classes.img)}/>
      <div className={classes.container}>
        <div className={classes.error}>Error 404</div>
        <div className={classes.message}>Algo deu errado...</div>
      </div>
    </div>
  )
}

export default withStyles(styles)(NotFound)
