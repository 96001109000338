import React from 'react';

const LikeButton = ({likes, onPress, loggedUserId, count = true}) => {
  return count ? (
      <button onClick={onPress} className={`post--likes ${loggedUserId && likes.includes(loggedUserId.toString()) ? 'is-active' : ''}`}>
        {likes && likes.length}
      </button>
    ) :  (
      <a onClick={onPress} style={{cursor: 'pointer'}}>
        <i className={`like ${loggedUserId && likes.includes(loggedUserId.toString()) ? 'is-active' : ''}`}></i>
        Curtir
      </a>
  )
};

export default LikeButton;