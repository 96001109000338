import React from "react";
import styled from "styled-components";
import Dialog from "material-ui/Dialog";
import DialogContent from "material-ui/Dialog/DialogContent";
import { withStyles } from "material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { push } from "react-router-redux";
import { CheckCircleOutline } from "@material-ui/icons";

import { setPaymentStatusModalOpen } from "../actions";

const styles = {
  root: {
    position: "relative",
    padding: 0,
    paddingTop: 0,
    display: "block",
    height: "500px",
    width: "650px",
    // maxWidth: ""
    "&:first-of-type": {
      paddingTop: 0,
    },
  },
  rootResponsive: {
    position: "relative",
    padding: 0,
    paddingTop: 0,
    display: "block",
    height: "100vh",
    width: "100vw",
    // maxWidth: ""
    "&:first-of-type": {
      paddingTop: 0,
    },
  },
};

const PaymentStatusModalContainer = styled.div`
  position: relative;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: 0px 22px 10px 0px rgba(0, 0, 0, 0.07);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StatusContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

const StatusIcon = styled.div`
  width: ${(props) => (props.responsive ? "54px" : "64px")};
  height: ${(props) => (props.responsive ? "54px" : "64px")};
  align-self: center;
`;

const StatusText = styled.span`
  font-family: "Proxima Nova";
  font-size: ${(props) => (props.responsive ? "22px" : "25px")};
  height: fit-content;
  line-height: ${(props) => (props.responsive ? "22px" : "28px")};
  font-weight: 600;
  max-width: ${(props) => props.responsive && "80vw"};
  width: fit-content;
  color: rgba(0, 0, 0, 0.75);
  align-self: center;
  margin-top: 22px;
`;

const StatusInfo = styled.p`
  margin: 0;
  font-family: "Proxima Nova";
  padding: ${(props) => !props.responsive && "0px 64px"};
  font-size: ${(props) => (props.responsive ? "13px" : "17px")};
  line-height: ${(props) => (props.responsive ? "20px" : "22px")};
  font-weight: 500;
  max-width: ${(props) => props.responsive && "90vw"};
  height: fit-content;
  color: ${(props) =>
    props.responsive ? "rgba(0, 0, 0, 0.6)" : "rgba(0, 0, 0, 0.75)"};
  align-self: center;
  margin-top: 32px;
  white-space: pre-line;
  text-align: center;
`;

const CloseButton = styled.button`
  justify-self: flex-end;
  background: rgb(252, 121, 84);
  border-radius: 20px;
  border: none;
  width: ${(props) => (props.responsive ? "180px" : "220px")};
  height: 40px;
  color: rgb(255, 255, 255);
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
  text-transform: ${(props) => !props.responsive && "uppercase"};
  margin-top: ${(props) => (props.responsive ? "100px" : "40px")};
  margin-bottom: ${(props) => !props.qpq && "30px"};
  align-self: center;
`;

const ActionButton = styled.button`
  justify-self: flex-end;
  color: rgb(252, 121, 84);
  border: 2px solid rgb(252, 121, 84);
  border-radius: 20px;
  width: ${(props) => (props.responsive ? "180px" : "220px")};
  height: 40px;
  background: rgb(255, 255, 255);
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
  text-transform: ${(props) => !props.responsive && "uppercase"};
  align-self: center;
  &:hover {
    border-color: #e95b34;
    color: #e95b34;
  }
`;

function PaymentStatusModal({ classes, qpq = false }) {
  const dispatch = useDispatch();

  const open = useSelector(
    (state) => state.paymentGroup.paymentStatusModal.open
  );

  const courseID = useSelector(
    (state) => state.courseGroup.currentCourse.data.id
  );
  const moduleID = useSelector(
    (state) => state.courseGroup.currentModule.data.id
  );
  const classID = useSelector(
    (state) => state.courseGroup.currentClass.data.id
  );

  const responsive = useSelector((state) => state.plataform.responsive);

  function closeModalAction() {
    dispatch(setPaymentStatusModalOpen(false));
    // !qpq && location.reload();
  }

  function returnAction() {
    dispatch(setPaymentStatusModalOpen(false));
    if (!qpq) {
      if (
        courseID !== undefined &&
        moduleID !== undefined &&
        classID !== undefined
      ) {
        dispatch(
          push(`/cursos/${courseID}/modulo/${moduleID}/aula/${classID}`)
        );
      } else {
        dispatch(push(`/cursos`));
      }
    }
  }

  return (
    <div>
      <Dialog
        id="paymentStatusDialog"
        scroll={"paper"}
        open={open}
        fullScreen={responsive}
        maxWidth={"md"}
      >
        <DialogContent
          id="content"
          classes={{ root: responsive ? classes.rootResponsive : classes.root }}
          dividers={false}
        >
          <PaymentStatusModalContainer>
            <StatusContent>
              <StatusIcon responsive={responsive}>
                <CheckCircleOutline
                  style={{
                    width: "100%",
                    height: "100%",
                    color: "rgb(49, 201, 142)",
                  }}
                />
              </StatusIcon>
              <StatusText responsive={responsive}>
                Recebemos o seu pedido
              </StatusText>
              <StatusInfo responsive={responsive}>
                {
                  "O seu pagamento está em análise e você receberá um email assim que a compra for confirmada. Geralmente não leva mais que 5 minutos.\n\nFique de olho no seu email porque todos os detalhes serão enviados por lá."
                }
              </StatusInfo>

              {qpq ? (
                <CloseButton
                  qpq={qpq}
                  responsive={responsive}
                  onClick={() => closeModalAction()}
                >
                  Entendi
                </CloseButton>
              ) : (
                <CloseButton
                  responsive={responsive}
                  onClick={() => closeModalAction()}
                >
                  Continuar Comprando
                </CloseButton>
              )}
              {!qpq && (
                <ActionButton
                  responsive={responsive}
                  onClick={() => returnAction()}
                >
                  Voltar para Cursos
                </ActionButton>
              )}
            </StatusContent>
          </PaymentStatusModalContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(PaymentStatusModal);
