import React, {Component} from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { reduxForm, Field, change } from 'redux-form';
import Popover from 'material-ui/Popover';
import Card from 'material-ui/Card';
import CardHeader from 'material-ui/Card/CardHeader';
import CardContent from 'material-ui/Card/CardContent';
import CardActions from 'material-ui/Card/CardActions';
import { renderTextField } from '../../utils/form';
import Button from '../../components/Elements/Button';
import { Wrench } from '../../components/common/SvgIcons';
import { crudCreate as crudCreateAction } from '../../actions/dataActions';
import { withStyles } from 'material-ui/styles';

const formName = 'feedback';

const styles = theme => ({
  feedback: {
    width: '190px',
    display: 'block',
    cursor: 'pointer',
    textAlign: 'center',
    color: 'rgba(0,0,0,0.3)',
    fontSize: '12px',
    margin: '0 auto'
  },
  wrench: {
    fontSize: '19px'
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  textArea: {
    border: '1px solid rgba(0,0,0,0.1)',
    padding: '12px 8px',
    borderRadius: '3px',
    minWidth: '280px'
  },
  actions: {
    justifyContent: 'flex-end',
    padding: '16px 24px'
  }
})

class FeedbackPopover extends Component {
  state = {
    anchorEl: null
  }

  openFeedback = (e) => {
    this.setState({
      anchorEl: e.currentTarget
    });
    this.props.changeForm('message', '');
  }
  
  closeFeedback = () => {
    this.setState({
      anchorEl: null
    });
  }

  createFeedback = (form) => {
    let data = {...form, url: document.location.href}
    this.props.crudCreate('ticket', data, '', false, '', true, 'Feedback recebido');
    this.closeFeedback();
  }

  render() {
    const { classes, style, handleSubmit, pristine, customClass } = this.props;
    const { anchorEl } = this.state;

    return (
      <div className={customClass} style={style}>
        <a className={classes.feedback} onClick={this.openFeedback}>
          <Wrench className={classes.wrench} /> Encontrou algum erro?
        </a>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.closeFeedback}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Card>
          <CardHeader 
            className={classes.header}
            title="Feedback"
            subheader="Encontrou algum erro?"/>
          <CardContent>
          <Field
            autoFocus
            className={classes.textArea}
            name="message"
            component={renderTextField}
            rows={3}
            multiline={true}
            placeholder="Escreva uma mensagem"
            InputProps={{
              disableUnderline: true
            }}
          /> 
          </CardContent>
            <CardActions className={classes.actions}>
              <Button 
                onClick={handleSubmit(this.createFeedback)}
                disabled={pristine}
                customClasses={{
                  root: classes.feedbackButton
                }} 
              >
                Enviar
              </Button>
            </CardActions>
          </Card>
        </Popover>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeForm: (field, value) => {
      dispatch(change(formName, field, value))
    },
    crudCreate: (...params) => {
      dispatch(crudCreateAction(...params))
    }
  }
}

const enhance = compose(
  withStyles(styles),
  reduxForm({
    form: formName
  }),
  connect(null, mapDispatchToProps),
);

export default enhance(FeedbackPopover)
