import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withStyles } from 'material-ui/styles';
import compose from 'recompose/compose';
import Paper from 'material-ui/Paper';
import moment from 'moment'
import IconButton from 'material-ui/IconButton';
import Button from 'material-ui/Button';
import {Buttons as SocialButton} from '../../components/Social'
import Tooltip from 'material-ui/Tooltip';
import Edit from 'material-ui-icons/Edit'
import Delete from 'material-ui-icons/Delete'
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';

const styles = theme => ({
  container: {
    width: '100%',
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    color: theme.palette.text.clear,
    flexDirection: 'column',
  },
  card: {
    display: 'flex',
    boxShadow: theme.shadows[25],
    width: '99%',
    '-webkit-tap-highlight-color': 'transparent',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '70px',
    padding: '16px',
    cursor: 'pointer',
    '&:hover $actionButton': {
      color: theme.palette.text.clearGrey,
      '&:hover': {
        color: theme.palette.text.clear,
      }
    },
    '&:hover $actions': {
      display: 'flex'
    }
  },
  authorData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  authorPhoto: {
    background: 'yellowgreen',
    backgroundSize: 'contain',
    color: '#fff',
    borderRadius: '50%',
    height: '40px',
    width: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '8px'
  },
  author: {
    ...theme.typography.body3,
    color: theme.palette.text.primary
  },
  date: {
    ...theme.typography.small,
    fontSize: '12px',
    color: theme.palette.black.a3
  },
  title: {
    ...theme.typography.subheading,
    fontSize: '18px',
    color: theme.palette.text.basic,
    fontWeight: 600,
    paddingTop: '24px'
  },
  content : {
    ...theme.typography.body1,
    fontSize: '15px',
    color: theme.palette.text.basic,
    fontWeight: 'normal',
    paddingBottom: '16px',
    borderBottom: '1px solid #96969626',
    maxHeight: '150px',
    overflow: 'hidden',
    maxWidth: '80vw',
  },
  verifiedAccount: {
    border: `1px solid ${theme.palette.black.a3}`,
    borderRadius: '3px',
    fontFamily: theme.typography.fontFamily,
    padding: '4px 7px 3px 7px',
    fontSize: '13px'
  },
  clippingData: {
    flexGrow: 1
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '16px',
    maxHeight: '50px'
  },
  comentar: {
    color: theme.palette.clearOrange,
    fontWeight: 600,
    '&:hover': {
      color: theme.palette.orange
    },
      [theme.breakpoints.down('sm')]: {
       WebkitMarginAfter: '30px'
      }
  },
  buttons: {
    display: 'flex'
  },
  actions: {
    width: '160px',
    justifyContent: 'flex-end',
    display: 'none'
  },
  icon: {
    height: '16px',
    width: '24px',
    color: 'rgba(0,0,0,0.1)'
  },
  socialContainer :{
    marginRight: '16px',
    fontSize: '12px',
    color: theme.palette.text.clearGrey,
    alignItems: 'center',
    display: 'flex',
    '&:hover': {
      color: theme.palette.text.clear,
    },
    '&:hover $icon': {
      color: theme.palette.orange
    },
  },
  tooltip: {
    top: '-15px !important' 
  },
  actionButton: {
    color: 'transparent',
  },
  actionIcon: {
    height: '20px',
    width: '20px',
  },
  image: {
    width: '100%',
    marginTop: '8px'
  },
  readMore: {
    width: '100%',
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.7)',
    background: 'linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 1))',
    display: 'flex',
    justifyContent: 'center',
    height: '60px',
    paddingBottom: '4px',
    alignItems: 'flex-end',
    marginTop: '-30px'
  }
})

class Post extends Component {
  state = {
    readMore: false
  }

  handleDelete = (event) => {
    event.stopPropagation();
    this.props.deletePost(this.props._id)
  }

  handleEdit = (event) => {
    event.stopPropagation();
    this.props.editPost(this.props._id)
  }

  handleLike = (_id) => {
    const { loggedUser, likes, trackMetric } = this.props;
    let wasLiked = likes.includes(loggedUser._id.toString());

    trackMetric('Mural - Ativação', {
      target: 'like',
      trigger: 'click',
      resource: _id,
      action: wasLiked ? 'delete' : 'create'
    });

    this.props.handleLikePost(_id);
  }

  componentDidMount() {
    if(this.refs.content.scrollHeight > 150 && this.refs.content.clientHeight > 140) {
      this.setState({readMore: true})
    }
  }

  render() {
    const {createdAt, _id, classes, title, openModal, initialLikes, likes = [], text, comments = [], avatar, verified, authorName = "", user, loggedUser, image, externalId} = this.props
    const {readMore} = this.state
    const date = moment(createdAt);
    
    return (  
      <div className={classes.container}>
        <Paper className={classes.card}  onClick={() => openModal(_id)}>
          <div className={classes.authorData}>
            <div className={classes.authorData}>
              {Boolean(avatar) ? (
                <div className={classes.authorPhoto} style={{
                  backgroundImage: `url(${avatar})`,
                  backgroundColor: '#FFFFFF'
                }}></div>
              ) : (
                <div className={classes.authorPhoto}>
                  {(authorName && authorName.slice(0,1)) || 'C'}
                </div> 
              )}
              <div className={classes.clippingData}> 
                <div className={classes.author}> {authorName.split(' ')[0]}</div>
                <div className={classes.date}> {date.fromNow()}</div>
              </div>
              {verified && (
                <div className={classes.verifiedAccount}>Perfil Verificado</div>
              )}
            </div>
            {user === loggedUser._id ? <div className={classes.actions} >
              <Tooltip classes={{popper: classes.tooltip}} title="Editar" enterDelay={300} >
                <IconButton
                  className={classes.actionButton}
                  aria-label="Editar"
                  onClick={this.handleEdit} >
                  <Edit className={classes.actionIcon}/> 
                </IconButton>
              </Tooltip>  
              <Tooltip classes={{popper: classes.tooltip}} title="Excluir" enterDelay={300} >  
                <IconButton
                  className={classes.actionButton}
                  aria-label="Excluir"
                  onClick={this.handleDelete} >
                  <Delete className={classes.actionIcon}/> 
                </IconButton>
              </Tooltip>
            </div> : null}
          </div>
          {image ?<img src={image} alt='content' className={classes.image} /> : null}
          <div className={classes.title}>{title}</div>
          <div ref="content" style={image || readMore ? {border: 'none'} : {}} className={classes.content} dangerouslySetInnerHTML={{__html: text}}></div>
          {readMore ? <div className={classes.readMore}>CONTINUE LENDO...</div> : null}
          <div className={classes.footer}>
            <div className={classes.buttons}>
              <SocialButton type='likes' items={likes} initialLikes={initialLikes} loggedUserId={loggedUser._id} onClick={() => this.handleLike(_id)} />
              <SocialButton type='comments' items={comments} loggedUserId={loggedUser._id} onClick={() => {}} identifier={externalId || _id} />
            </div>
            <div className={classes.comentar}>
              <Button color="primary" className={classes.comentar} onClick={() => {}}>
                COMENTAR
              </Button>   
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    trackMetric: (name, data) => dispatch(trackMetricAction(name, data))
  }
}

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps)
)(Post);
