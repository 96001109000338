import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import compose from 'recompose/compose';
import NProgress from 'nprogress';
import { withStyles } from 'material-ui/styles';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import IconButton from 'material-ui/IconButton';
import Tooltip from 'material-ui/Tooltip';
import MenuIcon from 'material-ui-icons/Menu';
import Button from 'material-ui/Button';
import TimerIcon from 'material-ui-icons/AccessTime'
import Profile from 'material-ui-icons/Person'
import SvgIcon from 'material-ui/SvgIcon'
import Close from 'material-ui-icons/Close'
import { setConcurso as setConcursoAction } from '../../actions/concursoActions'
import { crudGetList } from '../../actions/dataActions'
import { SORT_ASC } from '../../reducers/resource/list/queryReducer'
import AppDrawer from './AppDrawer';
import BarraContador from './BarraContador';
import SelectConcurso from './SelectConcurso'
import Search from './Search';
import ArrowLeft from 'material-ui-icons/ChevronLeft'
import { showAlert } from '../../actions/alertActions';
import { Link } from 'react-router'
import Menu, { MenuItem } from 'material-ui/Menu';
import {userLogout as userLogoutAction} from '../../actions/authActions';
import {getAccountData as getAccountDataAction} from '../../actions/profileActions';
import {getActiveSubscriptions as getActiveSubscriptionsAction} from '../../actions/profileActions';
import classNames from 'classnames';
import moment from 'moment';
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';
import { getGlobalSetting as getGlobalSettingAction } from '../../actions/plataformActions';
import { closeNotificationBar as closeNotificationBarAction } from '../../actions/notificationBarActions';

import MentalIcon from '../../assets/img/icon/ri_mental-health-line.svg';
const ITEM_HEIGHT = 48;

// Disaply a progress bar between route transitions
NProgress.configure({
  template: `
    <div class="bar" role="bar">
      <dt></dt>
      <dd></dd>
    </div>
  `,
});

// Router.onRouteChangeStart = () => {
//   NProgress.start();
// };

// Router.onRouteChangeComplete = () => {
//   NProgress.done();
// };

// Router.onRouteChangeError = () => {
//   NProgress.done();
// };

const styles = theme => {
  return {
    '@global': {
      html: {
        background: theme.palette.background.default,
        WebkitFontSmoothing: 'antialiased', // Antialiasing.
        MozOsxFontSmoothing: 'grayscale', // Antialiasing.
        boxSizing: 'border-box',
      },
      '*, *:before, *:after': {
        boxSizing: 'inherit',
      },
      body: {
        margin: 0,
      },
      '#nprogress': {
        pointerEvents: 'none',
        '& .bar': {
          position: 'fixed',
          background:
            theme.palette.type === 'light' ? theme.palette.common.black : theme.palette.common.white,
          borderRadius: 1,
          zIndex: theme.zIndex.tooltip,
          top: 0,
          left: 0,
          width: '100%',
          height: 2,
        },
        '& dd, & dt': {
          position: 'absolute',
          top: 0,
          height: 2,
          boxShadow: `${theme.palette.type === 'light'
            ? theme.palette.common.black
            : theme.palette.common.white} 1px 0 6px 1px`,
          borderRadius: '100%',
          animation: 'nprogress-pulse 2s ease-out 0s infinite',
        },
        '& dd': {
          opacity: 0.6,
          width: 20,
          right: 0,
          clip: 'rect(-6px,22px,14px,10px)',
        },
        '& dt': {
          opacity: 0.6,
          width: 180,
          right: -80,
          clip: 'rect(-6px,90px,14px,-6px)',
        },
      },
      '@keyframes nprogress-pulse': {
        '30%': {
          opacity: 0.6,
        },
        '60%': {
          opacity: 0,
        },
        to: {
          opacity: 0.6,
        },
      },
    },
    root: {
      display: 'flex',
      alignItems: 'stretch',
      minHeight: '100vh',
      width: '100%'
    },
    rootDivulgation: {
      paddingTop: '50px',
      display: 'flex',
      alignItems: 'stretch',
      minHeight: '100vh',
      width: '100%',
      "@media (max-width: 600px)": {
        paddingTop: "70px"
      },
    },
    grow: {
      flex: '1 1 auto',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      marginLeft: 24,
      flex: '0 1 auto',
    },
    searchBarContainer: {
      paddingLeft: '24px',
      [theme.breakpoints.down('sm')]: {
        padding: '0'
      },
      '@media (max-width: 768px)': {
        padding: 0
      }
    },
    appBar: {
      [theme.breakpoints.down('sm')]: {
        paddingRight: '0 !important',
      },
      boxShadow: theme.shadows[27],
      left: 0,
      transition: theme.transitions.create('width'),
      backgroundColor: '#FFF'
    },
    appBarDivulgation: {
      [theme.breakpoints.down('sm')]: {
        paddingRight: '0 !important',
      },
      top: "auto",
      boxShadow: 'none',
      left: 0,
      transition: theme.transitions.create('width'),
      backgroundColor: '#FFF'
    },
    appBarHome: {
      boxShadow: 'none',
    },
    appBarShift: {
      [theme.breakpoints.up('lg')]: {
        width: '100%',
      },
    },

    drawer: {
      [theme.breakpoints.up('lg')]: {
        width: 250,
      },
    },
    overlay: {
      width: '100%',
      position: 'fixed',
      height: '100%',
      background: 'rgba(0,0,0,0.4)',
      left: 0,
      top: 0,
      zIndex: 1299
    },
    navIconHide: {
      height: '56px',
      width: '56px',
      [theme.breakpoints.up('md')]: {
        height: '65px',
        width: '65px',
      },
      borderRadius: '0px',
      fontSize: '30px',
      color: 'rgba(0, 0, 0, 0.6)',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    content: {
      marginTop: '65px',
      flex: 1,
      marginLeft: 'calc(50% - 485px)',
      marginRight: '8px',
      background: '#F2EEED',
      position: 'absolute',
      width: '1002px',
      minHeight: 'calc(100vh - 64px)',
      '@media (max-width: 1650px)': {
        position: 'static',
        maxWidth: '990px',   
        margin: '65px auto'
      }
    },
    logoDiv: {
      height: '65px',
      width: 'fit-content',
      paddingLeft: '12px',
      display: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
      },
    },
    newlogo: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    toolbar: {
      zIndex: 10,
      paddingLeft: '16px',
      background: '#FFF',
      [theme.breakpoints.down('sm')]: {
        paddingRight:0,
      }
    },
    ajuda: {
      color: theme.palette.text.secondary,
      fontWeight: 600,
      lineHeight: '14px',
      fontSize: '12px'
    },
    searchIcon: {
      display: 'inline',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    icon: {
      color: theme.palette.text.secondary
    },
    displayResponsive: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    pageTitle: {
      display: 'block',
      ...theme.typography.title,
      textTransform: 'uppercase',
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      '@media (max-width: 768px)': {
        color: 'rgba(0, 0, 0, 0.75)',
        fontSize: '17px',
        lineHeight: '17px',
        fontWeight: 'normal',
        letterSpacing: '-0.05px',
        textTransform: 'none',
        textAlign: 'left',
      }
    },
    closeSearch: {
      width: '56px'
    },
    toolbarSmall: {
      position: 'absolute',
      background: 'white',
      width: '100%',
      zIndex: 20,
      display: 'flex',
      paddingLeft: '0px',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    pendingPaymentAppBar: {
      // top: 50,
      '@media (max-width: 964px)': {
        // top: 68
      },
      '@media (max-width: 530px)': {
        // top: 86
      }
    },
    pendingPaymentAlert: {
      position: 'fixed',
      top: "66px", left: 0, right: 0,
      backgroundColor: '#fe3f4b',
      minHeight: 50,
      '@media (max-width: 964px)': {
        minHeight: 68
      },
      '@media (max-width: 530px)': {
        minHeight: 86
      },
      zIndex: 1300,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      fontFamily: theme.typography.fontFamily,
      color: '#F2F2F2',
      fontWeight: 'bold',
      padding: 16,
    },
    notificationBar: {
      position: 'fixed',
      top: "66px", left: 0, right: 0,
      backgroundColor: '#41aeef',
      minHeight: 50,
      '@media (max-width: 964px)': {
        minHeight: 68
      },
      '@media (max-width: 530px)': {
        display: "flex",
        minHeight: 86
      }, 
      zIndex: 1300,
      display: 'flex', //flex
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      fontFamily: theme.typography.fontFamily,
      color: '#F2F2F2',
      fontWeight: 'bold',
      padding: 16,
    },
    openConcursoAlert: {
      position: 'fixed',
      top: 0, left: 0, right: 0,
      backgroundColor: '#41AEEF',
      minHeight: 50,
      '@media (max-width: 964px)': {
        minHeight: 68
      },
      '@media (max-width: 530px)': {
        minHeight: 86
      },
      zIndex: 1300,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      fontFamily: theme.typography.fontFamily,
      color: '#F2F2F2',
      fontWeight: 'bold',
      padding: 16,
    },
    link: {
      color: '#F2F2F2',
      outline: 'none'
    },
    close: {
      cursor: 'pointer',
      width: 'auto',
      height: 'auto',
      color: 'white',
      '&:hover': {
        color: theme.palette.white,
      },
    },
    notificationBarClose: {
      width: 'fit-content',
      position: 'absolute',
      right: '0',
      paddingRight: '24px',
      '@media (max-width: 578px)': {
        paddingRight: '8px'
      },
    },
    notificationBarMessage: {
      '@media (max-width: 578px)': {
        width: '300px'
      }
    }
  }
}

var GLOBALS = {
  globalLastDate: null,
  lastDate: null,
  lastLocation: null,
  lastToken: null
}

class AppFrame extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      mobileOpen: false,
      search: false,
      profileMenu: false,
      anchorEl: null,
      overlay: false,
      countdown: "",
    }
  }

  componentDidMount() {
    const { loadConcursos } = this.props;
    loadConcursos();

    this.loginIntercom();
  }

  loginIntercom = () => {
    setTimeout(() => {
      if(window.Intercom) {
        const {externalId, _id, intercomHash, email, name} = this.props.profile
        window.Intercom("boot", {
          app_id: "kaf4w5f6",
          user_id: externalId || _id,
          user_hash: intercomHash,
          email: email,
          name: name
        });
      } else {
        this.loginIntercom()
      }
    }, 2000)
  }

  handleSearch = (open) => {
    this.setState({ search: open , searchFocus: open })
  }

  closeDrawer = () => this.setState({ mobileOpen: false });

  openTimer = () => {
    const { concurso } = this.props
    const token = localStorage.token && localStorage.token.replace('Bearer ', '')
    this.props.trackMetric('Clicou no cronômetro', { url: location.href });
    window.open(`https://clippingcacd.com.br/timer/${concurso && concurso.active.name}?token=${token}`, '_blank')
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }

  handleModal = (name, open) => {

    this.setState({[name]: open})
  }

  handleBtnMenu = event => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  }

  handleBtnMenuClose = () => {
    this.setState({ anchorEl: null });
  }

  handleFocus = () => {
    this.setState({ searchFocus: true });
  }

  handleBlur = () => {
    this.setState({ searchFocus: false });
  }

  extractIds = (path) => {
    let blacklist = ['exhibition', 'gabarito'];
    let matches = path.match(/:\w+/g) || [];
    return matches.map(x => x.substr(1)).filter(x => !blacklist.includes(x));
  }

  trackTime = (target) => {
    var deltaTime = (new Date() - GLOBALS.lastDate) / 1000;
    if(deltaTime < 0.8) return;

    let { children, concurso, resourceParams } = this.props;

    var label = children.props.route.label;
    var concursoId = concurso.active.concursoId;

    switch(label) {
      case 'Post': label = 'Mural'; break;
      case 'Artigos': label = 'Clippings'; break;
      case 'Edital Esquematizado': label = 'Edital'; break;
      case 'Fichamentos':
      case 'Meus Cadernos': label = 'Caderno'; break;
      // no default
    }

    var ids = this.extractIds(children.props.route.path);
    var params = ids.map(x => children.props.params[x]);

    var resource, parentResource;

    switch(params.length) {
      case 1: resource = params[0]; break;
      case 2: [parentResource, resource] = params; break;
      // no default
    }

    let url = 'https://api.clippingcacd.com.br/event/beacon';

    let data = {
      target,
      trigger: 'time',
      time: deltaTime,
      resource,
      parentResource,
      location: GLOBALS.lastLocation,
      nextLocation: (target === 'navigation') ? location.href : undefined,
      page: resourceParams ? resourceParams.page : undefined
    }

    let body = {
      name: `${label} - Ativação`,
      data,
      concursoId,
      authorization: GLOBALS.lastToken
    }

    navigator.sendBeacon(url, JSON.stringify(body));

    GLOBALS.lastDate = new Date();
    GLOBALS.lastLocation = location.href;
  }

  trackGlobalTime = () => {
    var deltaTime = (new Date() - GLOBALS.globalLastDate) / 1000;

    let { concurso } = this.props;
    var concursoId = concurso.active.concursoId;

    let url = 'https://api.clippingcacd.com.br/event/beacon';

    let body = {
      name: 'Encerrou sessão',
      data: { time: deltaTime },
      concursoId,
      authorization: GLOBALS.lastToken
    }

    navigator.sendBeacon(url, JSON.stringify(body));
  }

  componentWillMount() {
    this.props.getGlobalSetting();

    GLOBALS.lastDate = new Date();
    GLOBALS.globalLastDate = new Date();
    GLOBALS.lastToken = localStorage.token;

    GLOBALS.lastLocation = location.href;



    window.addEventListener('beforeunload', () => this.trackTime('close'));
    window.addEventListener('beforeunload', this.trackGlobalTime);

    this.props.loadSubscription();
    this.props.loadActiveSubscriptions();
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.children !== nextProps.children) {
      this.trackTime('navigation');
    }
  }

  trackHelp = () => {
    this.props.trackMetric('Clicou em ajuda', { url: location.href });
    window.open('http://ajuda.clippingcacd.com.br/', "_blank");
  }

  shouldShowOpenConcursoAlert = () => {
    const { concurso, openConcursos } = this.props;
    return openConcursos && !localStorage.scope.includes(`CONCURSO-${concurso.active.concursoId}`);
  }

  render() {
    const { children, classes, location, pushRouter, routeLabel, loadSearch, setConcurso, concurso = {},
      notFound = false, profile = {}, userLogout, subscription, loadSearchTerms, loadSearchTrending,
      closeNotificationBar, divulgation = false, activeSubscriptions = [],

    } = this.props;
    const { search, anchorEl, notificationBar } = this.state;
    let disablePermanent = false;
    let appBarClassName = divulgation ? classes.appBarDivulgation : classes.appBar;
    appBarClassName += ` ${classes.appBarShift}`;
    let moveAppBar =  (subscription.status === 'pending_payment' || this.shouldShowOpenConcursoAlert()) && classes.pendingPaymentAppBar;
    var eventdate = moment("2019-07-20");
    var todaysdate = moment();
    let days =   eventdate.diff(todaysdate, 'days');
    let duration = moment.duration(eventdate.diff(todaysdate));
    return (
      <div className={divulgation ? classes.rootDivulgation : classes.root}>
        {/* {subscription.status === 'pending_payment' && <div className={classes.pendingPaymentAlert}><span>Olá! Não foi possível renovar a assinatura do Clipping e seu acesso será bloqueado em breve. <Link className={classes.link} to={'/perfil/plano-cobranca'}>Clique aqui</Link> para alterar o cartão.</span></div>}
        {this.shouldShowOpenConcursoAlert() && <div className={classes.openConcursoAlert}><span>O Concurso {concurso.active.name} e todos os demais estão temporariamente desbloqueados para o livre acesso.</span></div>} */}
          <AppBar ref='appbar' className={classNames(appBarClassName, moveAppBar)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              aria-label="Abrir menu"
              onClick={this.handleDrawerToggle}
              className={classes.navIconHide}
            >
              <MenuIcon />
            </IconButton>
            <SelectConcurso setConcurso={setConcurso} concurso={concurso} className={classes.newlogo} activeSubscriptions={activeSubscriptions}/>
            <div className={classes.grow+" "+classes.searchBarContainer} style={{}}>
              {!notFound && <Search routeLabel={routeLabel} loadSearch={loadSearch} concurso={concurso} alertBarIsOn={this.shouldShowOpenConcursoAlert()} loadSearchTerms={loadSearchTerms}
                searchFocus={this.state.searchFocus} onFocus={this.handleFocus} onClear={this.handleBlur} loadSearchTrending={loadSearchTrending} />}
              {!notFound && <div className={classes.pageTitle}>{routeLabel}</div>}
            </div>
            <Tooltip title="Mental Health" enterDelay={300} className={classes.displayResponsive}>
              <IconButton
                aria-label="Mental Health"
                onClick={()=> {window.open("https://paciente.vittude.com/clipping")}}
              >
                <img src={MentalIcon} alt="Ícone" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ir para central de ajuda" enterDelay={300} className={classes.displayResponsive}>
              <Button color="primary" className={classes.ajuda} onClick={this.trackHelp}>
                Ajuda
              </Button>
            </Tooltip>
            <Tooltip title="Cronômetro de estudos" enterDelay={300} className={classes.displayResponsive}>
              <IconButton
                aria-label="Cronômetro de estudos"
                onClick={this.openTimer}
              >
                <TimerIcon className={classes.icon}/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Opções de Perfil" enterDelay={300} className={classes.displayResponsive}>
              <IconButton
                aria-label="Opções de Perfil"
                onClick={this.handleBtnMenu}
              >
                <Profile className={classes.icon} />
              </IconButton>
            </Tooltip>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleBtnMenuClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: 200,
                },
              }}
            >
              <MenuItem key={'Perfil'} onClick={() => { this.props.pushRouter('/perfil'); this.handleBtnMenuClose()}}>Perfil</MenuItem>
              <MenuItem key={'Logout'} onClick={userLogout}>Sair</MenuItem>
            </Menu>
          </Toolbar>
          <Toolbar className={classes.toolbarSmall} style={!search ? {display: 'none'} : {}}>
            <IconButton
              className={classes.closeSearch}
              aria-label="Fechar busca"
              onClick={() => {this.handleSearch(false)}}
            >
              <ArrowLeft />
            </IconButton>
            <div className={classes.grow}>
              {Boolean(search) && (
                <Search routeLabel={routeLabel} loadSearch={loadSearch} alertBarIsOn={this.shouldShowOpenConcursoAlert()} concurso={concurso}
                  searchFocus={this.state.searchFocus} loadSearchTerms={loadSearchTerms}
                  loadSearchTrending={loadSearchTrending} search={search} onFocus={this.handleFocus} />
              )}
            </div>
          </Toolbar>
        </AppBar>
        <AppDrawer  
          divulgation={divulgation}
          pushRouter={pushRouter}
          location={location}
          className={classes.drawer}
          disablePermanent={disablePermanent || notFound}
          onRequestClose={this.handleDrawerToggle}
          mobileOpen={this.state.mobileOpen}
          closeDrawer={this.closeDrawer}
          concurso={concurso.active}
          setConcurso={setConcurso}
          concursos={concurso}
          userLogout={userLogout}
          openTimer={this.openTimer}
          activeSubscriptions={activeSubscriptions}
        />
        <div>
          {this.state.searchFocus && <div onClick={this.handleBlur} className={classes.overlay}></div>}
        </div>
        <div className={classes.content}>
          {profile._id && React.cloneElement(children)}
        </div>
      </div>
    );
  }
}

AppFrame.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  uiTheme: PropTypes.object.isRequired,
};

AppFrame.contextTypes = {
  activePage: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

const mapDispatchToProps = (dispatch) => {
  var searchFilters = (concurso, terms) => ({
    payload: {
      q: terms,
      concursoId: concurso.active.concursoId
    }
  });

  return {
    pushRouter: (path) => dispatch(push(path)),
    setConcurso: (concurso, name) =>  {
      dispatch(setConcursoAction(concurso))
      name ? dispatch(showAlert(`Concurso alterado para ${name}`)) : dispatch(showAlert(`Concurso alterado`))
    },
    loadConcursos: () => dispatch(crudGetList('concurso', {page: 1, perPage: 99}, {field: 'concursoId', order: SORT_ASC}, {}, false)),
    loadSubscription: () => dispatch(getAccountDataAction()),
    loadActiveSubscriptions: () => dispatch(getActiveSubscriptionsAction()),
    userLogout: () => dispatch(userLogoutAction()),
    loadSearch: (concurso, terms, resource) => dispatch(crudGetList(`search/${resource}`, { perPage: 3 }, {}, searchFilters(concurso, terms, resource), false)),
    loadSearchTerms: (resource) => dispatch(crudGetList(`search/${resource}/lastTerms`, { perPage: 5 }, {}, {}, false)),
    loadSearchTrending: (resource) => dispatch(crudGetList(`search/${resource}/trending`, { perPage: 3 }, {}, {}, false)),
    trackMetric: (name, data) => dispatch(trackMetricAction(name, data)),
    getGlobalSetting: () => dispatch(getGlobalSettingAction()),
    closeNotificationBar: () => dispatch(closeNotificationBarAction()),
  }
}

const mapStateToProps = (state, ownProps) => {
  let childrenProps = ownProps.children && ownProps.children.props;
  let childrenRoute = childrenProps && childrenProps.route;
  let resource = state[childrenRoute && childrenRoute.resource];

  return {
    uiTheme: state.theme,
    concurso: state.concurso,
    profile: state.user.profile || {},
    search: state.search.data,
    subscription: state.user.subscription,
    activeSubscriptions: state.user.activeSubscriptions,
    resourceParams: resource && resource.list.params,
    openConcursos: state.globalSetting.openConcursos,
  }
}

export default compose(
  withStyles(styles, {
    name: 'AppFrame',
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(AppFrame);
