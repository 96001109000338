import styled, { css } from 'styled-components';

import {
  Tabs as MuiTabs,
  IconButton as MuiIconButton,
} from '@material-ui/core';

export const CardFooter = styled.div`
  position: relative;
  background: white;
  display: flex;
  align-items: center;
  padding: 0px 72px;
  height: 56px;
  transition: 75ms;

  ${({ openFooterContent }) =>
    !openFooterContent &&
    css`
      box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
    `}

  &, & * {
    font-family: ${({ theme }) => theme.fonts.inter};
  }

  &.sticky {
    position: fixed;
    z-index: 100;
    top: 65px;
    width: 100%;
    box-shadow: ${({ theme }) => theme.shadows.light.sm};
  }
`;

export const FooterContent = styled.section`
  overflow: hidden;

  & > div {
    display: flex;
    align-items: start;
    justify-content: end;
    padding: 24px 24px 40px 80px;
    font-size: 13px;
    color: #444444;
    transition: opacity 500ms;

    & > p {
      flex: 1;
      margin: 0px;
      white-space: pre-line;
    }

    & > button {
      background: #f2eeed;
      color: #808080;
      margin-left: 16px;
      padding: 8px;

      &:hover {
        background: #f2eeed;
      }

      & svg {
        height: 20px;
        width: 20px;
      }
    }
  }

  ${({ openFooterContent }) =>
    openFooterContent &&
    css`
      box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
    `}

  ${({ openFooterContent }) =>
    !openFooterContent &&
    css`
      max-height: 0px;

      & > div {
        poiinter-events: none;
        opacity: 0;
      }
    `}
`;

export const Tabs = styled(MuiTabs).attrs({
  TabIndicatorProps: {
    style: { background: '#FC7954' },
  },
})`
  height: 100%;

  & > div > div {
    height: 100%;
    display: flex;
    align-items: center;
  }

  & button {
    min-height: fit-content;
    text-transform: none;
    height: 100%;
    min-width: fit-content;
    padding: 0px 12px;
    color: #585858;
    opacity: 1;

    &:disabled {
      color: #b0b0b0;
      opacity: 1;
      cursor: no-drop;
      pointer-events: auto;
    }

    &[class*='Mui-selected'] {
      color: #fc7954;
    }

    & > span:first-child {
      display: flex;
      flex-direction: row;
      align-items: center;

      & svg {
        height: 18px;
        width: 18px;
        margin-right: 4px;
        margin-bottom: 0px !important;
      }
    }
  }
`;

export const IconButton = styled(MuiIconButton)`
  position: absolute;
  right: 24px;
  bottom: -67px;
  width: 40px;
  height: 40px;
  transform: translateY(100%);
  background: #f2eeed;
`;
