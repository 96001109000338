import { GET_DOE_SECTION } from '../actions/sectionActions';

export default (previousState = {doeSection: []}, { type, payload }) => {
    switch (type) {
        case `${GET_DOE_SECTION}_SUCCESS`:
            return {...previousState, doeSection: payload};
        default:
            return previousState;
    }
};
