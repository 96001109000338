import React from "react";
import { withStyles } from "material-ui/styles";
import { IconButton } from "material-ui";
import { Timer } from "material-ui-icons";
import { Close } from "material-ui-icons";
import Counter from "./counter";

const styles = (theme) => ({
  mainContainer: {
    width: "100%",
    height: 50,
    background: "rgb(254, 63, 75)",
    position: "fixed",
    zIndex: 11,
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 640px)": {
      justifyContent: "flex-start",
    },
    "@media (max-width: 600px)": {
      justifyContent: "center",
      height: 70,
    },
  },
  content: {
    width: "fit-content",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 600px)": {
      height: 70,
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  timer: {
    alignSelf: "center",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    width: "fit-content",
    height: "fit-content",
    "@media (max-width: 600px)": {
      marginBottom: "4px",
    },
  },
  timerIcon: {
    width: "24px",
    height: "24px",
    alignSelf: "center",
    color: "#fff",
    "@media (max-width: 900px)": {
      width: "18px",
      height: "18px",
    },
  },
  closeIcon: {
    width: "24px",
    height: "24px",
    alignSelf: "center",
    "@media (max-width: 900px)": {
      width: "18px",
      height: "18px",
    },
  },
  closeButton: {
    zIndex: "3",
    width: "24px",
    height: "24px",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: "24px",
    color: "rgba(255, 255, 255, 0.5)",
    "@media (max-width: 900px)": {
      right: "12px",
      width: "18px",
      height: "18px",
    },
    "@media (max-width: 900px)": {
      right: "12px",
    },
    "@media (max-width: 700px)": {
      right: "0px",
    },
    "@media (max-width: 600px)": {
      right: "6px",
    },
  },
  info: {
    marginLeft: "8px",
    width: "fit-content",
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    "@media (max-width: 700px)": {
      marginLeft: "4px",
    },
    "@media (max-width: 600px)": {
      flexDirection: "column",
      marginLeft: "0px",
    },
  },
  text: {
    fontFamily: "Proxima Nova",
    width: "fit-content",
    height: "fit-content",
    fontSize: "17px",
    lineHeight: "17px",
    fontWeight: 600,
    color: "rgba(255, 255, 255, 0.7);",
    margin: 0,
    "@media (max-width: 900px)": {
      fontSize: "13px",
      lineHeight: "13px",
    },
    "@media (max-width: 600px)": {
      fontSize: "13px",
      lineHeight: "16px",
    },
  },
  link: {
    alignSelf: "center",
    cursor: "pointer",
    marginLeft: "4px",
    color: "#fff",
    fontFamily: "Proxima Nova",
    fontSize: "15px",
    fontWeight: "bold",
    height: "fit-content",
    lineHeight: "15px",
    width: "fit-content",
    textAlign: "center",
    textDecoration: "underline",
    "@media (max-width: 900px)": {
      fontSize: "12px",
      lineHeight: "12px",
    },
    "@media (max-width: 600px)": {
      fontSize: "13px",
      lineHeight: "16px",
      marginLeft: "0px"
    },
  },
});

function DivulgationBar({ classes, hideBar }) {
  return (
    <div className={classes.mainContainer}>
      <div className={classes.content}>
        <div className={classes.timer}>
          <Timer className={classes.timerIcon} />
          <Counter />
        </div>
        <div className={classes.info}>
          <p className={classes.text}>
            As matrículas do Intensivão estão se encerrando!
          </p>
          <a
            href="https://new-app.clippingconcursos.com.br/pacotes-intensivo"
            className={classes.link}
          >
            GARANTA SUA VAGA
          </a>
        </div>
      </div>
      <IconButton className={classes.closeButton} onClick={() => hideBar()}>
        <Close className={classes.closeIcon} />
      </IconButton>
    </div>
  );
}

export default withStyles(styles)(DivulgationBar);
