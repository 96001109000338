import "./utils/TextHighlighter";
import "./assets/scss/main.css";

import moment from "moment";
import momentTimezone from "moment-timezone";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fab);

import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import { syncHistoryWithStore, routerMiddleware } from "react-router-redux";
import { browserHistory } from "react-router";
import createSagaMiddleware from "redux-saga";
import { fork } from "redux-saga/effects";

import reducer from "./reducers";
import { listReducers } from "./config";

import Routes from "./routes";
import { Router as RouterDOM, Route } from "react-router-dom";

import { rootSaga } from "./sagas";
import authClient from "./services/auth_client";
import restClient from "./services/rest_client";

import mixpanel from "./utils/courseEvents";

import { LoadCssImports } from "./styles/getContext";

moment.locale("pt-br");
momentTimezone.tz.setDefault("America/Sao_Paulo");
LoadCssImports();
mixpanel.initialize();

const saga = function* appSaga() {
  yield [rootSaga(restClient, authClient)].map(fork);
};

const sagaMiddleware = createSagaMiddleware();
const reduxRouterMiddleware = routerMiddleware(browserHistory);
const store = createStore(
  reducer(listReducers),
  undefined,
  composeWithDevTools({ trace: true, traceLimit: 25 })(applyMiddleware(sagaMiddleware, reduxRouterMiddleware))
);

// Begin our Index Saga
sagaMiddleware.run(saga);

// sync history
const history = syncHistoryWithStore(browserHistory, store);

const routerProps = {
  history,
  store,
  saga,
};

ReactDOM.render(
  <Provider store={store}>
    <Routes {...routerProps} />
  </Provider>,
  document.getElementById("root")
);
