import React, { useCallback } from "react";
import { withStyles } from "material-ui/styles";
import classNames from "classnames";
import Star from "material-ui-icons/Star";
import MultipleChoice from "../Questoes/MultipleChoice";

const styles = (theme) => ({
  containerRespostas: {
    fontWeight: "bold",
    display: "flex",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "4px",
    margin: "16px 0 24px 0",
    padding: "9px 16px",
  },
  star: {
    color: "#F8A947",
    height: "12px",
    width: "12px",
  },
  starPosition: {
    float: "left",
    marginTop: "-1px",
    marginRight: "4px",
  },
  correct: {
    borderColor: "#8BCFB3",
    backgroundColor: "#EDFFF8",
    "& $answerMessage": {
      color: "#2DB87E",
    },
  },
  incorrect: {
    borderColor: "#F69DA7",
    backgroundColor: "#FCDEE2",
    "& $answerMessage": {
      color: "#E05162",
    },
  },
  empty: {
    borderColor: "#D6D6D6",
    "& $answerMessage": {
      color: theme.palette.black.a4,
    },
  },
  answerMessage: {
    fontSize: "14px",
    lineHeight: "17px",
  },
  answerIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "45px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left center",
  },
  answer: {
    fontSize: "15px",
    color: theme.palette.black.a6,
  },
  comentarioTitle: {
    fontWeight: 600,
    fontSize: "12px",
    borderBottom: "1px solid rgba(0,0,0,0.1)",
    padding: "16px 14px 14px 14px",
  },
  comentarioProfessor: {
    ...theme.typography.body1,
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: "3px",
    backgroundColor: "rgba(178,178,178,0.1)",
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily2,
  },
  comentarioText: {
    padding: "13.5px 21px 15.8px 14px",
    fontSize: "15px",
    lineHeight: "22px",
    color: theme.palette.text.basic,
    ...theme.typography.noMargin,
    "& span": {
      color: `${theme.palette.text.basic} !important`,
    },
    "& a": {
      color: "#4A90E2",
      textDecoration: "none",
      wordBreak: "break-all",
    },
    "& img": {
      maxWidth: "100%",
    },
  },
});

const answers = {
  C: "Certo",
  E: "Errado",
  B: "Branco",
};

const ItemGabarito = ({
  classes,
  userAnswer,
  alternatives,
  correct,
  type,
  comment,
  annulled,
}) => {
  const answerResult = () => {
    if (correct?.toLowerCase() === "x" || annulled || !userAnswer) {
      return "empty";
    }

    if (type !== "multiple_choice" && userAnswer?.toUpperCase() === "B") {
      return "empty";
    }

    return userAnswer === correct ? "correct" : "incorrect";
  };

  const result = answerResult();

  const renderAnswerMessage = () => {
    if (result === "correct") {
      return "Parabéns! Você acertou";
    }

    if (type === "multiple_choice") {
      return `Você marcou: ${userAnswer || "Branco"}`;
    }

    return userAnswer ? answers[userAnswer] : answers.B;
  };

  const renderAnswer = () => {
    if (correct?.toLowerCase() === "x" || annulled) {
      return "Anulada";
    } else if (type === "multiple_choice") {
      return correct;
    } else {
      return answers[correct];
    }
  };

  return (
    <div>
      {type === "multiple_choice" && (
        <MultipleChoice readOnly={true} alternatives={alternatives} />
      )}

      <div className={classNames(classes.containerRespostas, classes[result])}>
        <div className={classNames(classes.answerIcon)}>
          {result === "correct" ? "👍" : result === "incorrect" ? "👎" : "➖"}
        </div>
        <div className={classes.answersRight}>
          <div className={classes.answerMessage}>{renderAnswerMessage()}</div>
          <div className={classes.answer}>Resposta: {renderAnswer()}</div>
        </div>
      </div>

      {comment?.length > 15 && (
        <div className={classes.comentarioProfessor}>
          <div className={classes.comentarioTitle}>
            <div className={classes.starPosition}>
              <Star className={classes.star} />
            </div>
            COMENTÁRIO
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: comment }}
            className={classes.comentarioText}
          ></div>
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(ItemGabarito);
