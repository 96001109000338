import styled from "styled-components";
import { Button } from "@clippingcacd/core";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;

  & > label {
    margin-left: 8px;
  }
`;

export const EditorContainer = styled.div`
  position: relative;
`;

export const TempEditor = styled.textarea`
  width: 100%;
  border: 1.4px solid #b0b0b0;
  border-radius: 8px;
  min-height: 64px;
  max-height: 260px;

  padding: 16px;
  padding-right: 56px;
  font-family: ${({ theme }) => theme.fonts.inter};
  &:focus-within {
    border-color: black !important;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.grayscale.grayLight};
    border-radius: 10px;
  }

  resize: none;
`;

export const CharacterCounter = styled.span`
  align-self: flex-end;
  line-height: 18px;
  font-size: 11px;
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.grayscale.grayLight};
  font-family: ${({ theme }) => theme.fonts.inter};
`;

export const CommentButton = styled(Button)`
  position: absolute;
  right: 16px;
  bottom: 16px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AvatarContainer = styled.div`
  position: absolute;
  left: 16px;
  height: 40px;
  width: 40px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
`;

//Comment parent ->

export const EditorContainerParent = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  min-height: 47px;
  max-height: 170px;
  padding: 8px;
`;

export const ParentCommentEditor = styled.textarea`
  width: 100%;
  border: 1.4px solid #b0b0b0;
  border-radius: 8px;
  min-height: 64px;
  max-height: 260px;

  padding: 16px;
  padding-right: 56px;
  padding-left: ${({ responsive }) => (responsive ? "16px" : "56px")};
  font-family: ${({ theme }) => theme.fonts.inter};
  &:focus-within {
    border-color: black !important;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.grayscale.grayLight};
    border-radius: 10px;
  }

  resize: none;
`;

export const ButtonSendCommentParent = styled(Button)`
  position: absolute;
  right: ${({ responsive }) => (responsive ? "8px" : "16px")};
  height: 40px;
  width: 40px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.grayscale.grayLight};
`;
