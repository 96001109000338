import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { courseDataFetchRequest } from "../../containers/Cursos/actions.js";

import styled from "styled-components";

import IconButton from "material-ui/IconButton";
import ExpandMore from "material-ui-icons/ExpandMore";


const Container = styled.div`
  font-family: "Proxima Nova", sans-serif;
  width: fit-content;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

const SubContainer = styled.div`
  width: fit-content;
`;

const Category = styled.p`
  display: ${(props) => (props.show ? "none" : "flex")};
  color: rgb(255, 255, 255);
  padding: 0px 20px 0px 20px;
  font-size: 15px;
  font-weight: normal;
  margin: 0;
  width: fit-content;
  text-transform: uppercase;
  user-select: none;
`;

const SelectionGroup = styled.div`
  width: fit-content;
  padding: 0px 20px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
`;

const SelectedItem = styled.p`
  text-align: left;
  background: transparent;
  /* height: fit-content; */
  width: 100%;
  border: 0;
  margin: 0;
  color: ${(props) =>
    props.responsive
      ? "rgba(0,0,0, .75)"
      : props.scrolled
      ? "rgba(0,0,0, .5)"
      : "rgb(255, 255, 255)"};
  font-size: ${(props) => (props.scrolled ? "20px" : "36px")};
  line-height: 42px;
  font-weight: ${(props) => (props.scrolled ? "normal" : "bold")};
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  user-select: none;
`;

const ListGroup = styled.div`
  width: 100%;
  min-width: 420px;
  background: #fff;
  height: fit-content;
  position: absolute;
  padding: 4px 0px;
  top: 0;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
`;

const ListItem = styled.p`
  text-align: left;
  width: 100%;
  line-height: 36px;
  border: 0;
  margin: 0;
  padding: 0px 16px;
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.75);
  cursor: pointer;
  outline: none;
  user-select: none;
  background-color: ${(props) => props.selected && "rgba(0, 0, 0, 0.08)"};
  &:hover {
    background-color: ${(props) => !props.selected && "rgba(0, 0, 0, 0.04)"};
  }
`;

function Dropdown({ scrolled, responsive = false }) {

  const { name: selected, ano: year, categoria } = useSelector(
    (state) => state.courseGroup.currentCourse.data
  );
  const profile = useSelector((state) => state.userData.data.id);
  const coursesData = useSelector((state) => state.courseGroup.courses.data);


  const [visible, setVisible] = useState(false);
  const [cursos, setCursos] = useState([]);

  useEffect(() => {
    
    setCursos(
      Object.values(coursesData).filter(
        (curso) =>
          curso.categoria.id === categoria.id &&
          curso.visible &&
          curso.ano === year
      )
    );
  }, [coursesData]);

  // @see URL(https://reactjs.org/docs/hooks-reference.html#useref)
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        // @see URL(https://developer.mozilla.org/en-US/docs/Web/API/Node/compareDocumentPosition)
        if (
          ref.current.compareDocumentPosition(event.target) &
          Node.DOCUMENT_POSITION_CONTAINED_BY
        ) {
          //elemento pode ser selecionado, nao fecha dropdown
        } else {
          //clicou fora da dropdown
          setVisible(false);
        }
      }

      //adiciono o listener do click
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        //removo o listener do click ao sair
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const dispatch = useDispatch();

  function actionCreator(payload) {
    dispatch(courseDataFetchRequest(payload, profile));
    setVisible(false);
  }

  return (
    <Container ref={wrapperRef}>
      <SubContainer>
        <Category show={scrolled} name="categoria do curso">
          {categoria?.nome} {year} /
        </Category>
        <SelectionGroup>
          <SelectedItem
            scrolled={scrolled}
            responsive={responsive}
            onClick={() => setVisible(!visible)}
          >
            {selected}
          </SelectedItem>
          <IconButton
            style={{
              marginLeft: "10px",
              color: "#fff",
              transform: visible ? "rotate(180deg)" : "rotate(0deg)",
              display: scrolled ? "none" : "flex",
            }}
            aria-label="dropbutton"
            onClick={() => setVisible(!visible)}
          >
            <ExpandMore />
          </IconButton>
        </SelectionGroup>
      </SubContainer>
      <div
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {visible && (
          <ListGroup>
            {cursos.length > 0 &&
              cursos
                .sort((courseA, courseB) =>
                  courseA.name.localeCompare(courseB.name)
                )
                .map((curso, index) => (
                  <ListItem
                    onClick={() => actionCreator(curso)}
                    key={curso.id}
                    last={index === cursos.length - 1}
                    selected={curso.name === selected}
                  >
                    {curso.name}
                  </ListItem>
                ))}
          </ListGroup>
        )}
      </div>
    </Container>
  );
}

export default Dropdown;
