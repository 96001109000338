import React, { useEffect, useState } from "react";
import api from "../../services/newApi";

function CheckAPI() {
  const [status, setStatus] = useState("");

  async function checkRoute() {
    const response = await api.get("/check");
    setStatus(response.status);
  }

  useEffect(() => {
    checkRoute();
  }, []);

  return <div>{status}</div>;
}

export default CheckAPI;
