import {
    SUBS_PACK_SUCCESS_MODAL_SHOW,
    SUBS_PACK_SUCCESS_MODAL_HIDE,
    SUBS_PACK_FAILURE_MODAL_SHOW,
    SUBS_PACK_FAILURE_MODAL_HIDE,
    TERMS_OF_USE_MODAL_SHOW,
    TERMS_OF_USE_MODAL_HIDE,
} from '../actions/subsPackActions';
const initialState = {
    subsPackModalSuccess: { open: false },
    subsPackModalFailure: { open: false },
    termsOfUse: { open: false },
}

export default (previousState = initialState, { type, payload }) => {
    switch (type) {
        case SUBS_PACK_SUCCESS_MODAL_SHOW:
            return { ...previousState, subsPackModalSuccess: payload };
        case SUBS_PACK_SUCCESS_MODAL_HIDE:
            return { ...previousState, subsPackModalSuccess: payload };
        case SUBS_PACK_FAILURE_MODAL_SHOW:
            return { ...previousState, subsPackModalFailure: payload };
        case SUBS_PACK_FAILURE_MODAL_HIDE:
            return { ...previousState, subsPackModalFailure: payload };
        case TERMS_OF_USE_MODAL_SHOW:
            return { ...previousState, termsOfUse: payload };
        case TERMS_OF_USE_MODAL_HIDE:
            return { ...previousState, termsOfUse: payload };
        default:
            return previousState;
    }
}
