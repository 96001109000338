import React from 'react'
import { withStyles } from 'material-ui/styles';
import Button from 'material-ui/Button';

const styles = theme => ({
  container: {
    height: '40px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '32px'
    }
  },
  tabs: {
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '3px',
    borderColor: theme.palette.lightGrey,
    borderWidth: '1px',
    borderStyle: 'solid',
    flex: 1,
    maxWidth: '400px'
  },
  button: {
    flex: 1,
    margin: 0,
    minHeight: '34px',
    fontSize: '14px',
    color: theme.palette.text.primary,
    textTransform: 'initial',
    '&:hover': {
      backgroundColor: theme.palette.lightGrey
    }
  },
  active: {
    backgroundColor: theme.palette.lightGrey
  }
});

const Tabs = ({classes, options, active, onChange}) => {
  return (
    <div className={classes.container}>
      <div className={classes.tabs}>
        {options.map(({label, value}) => {
          return <Button
            key={value}
            className={`${classes.button} ${value === active ? classes.active : ''}`}
            onClick={() => onChange(value)}>
              {label}
            </Button>
        })}
      </div>
    </div>
  )
}

export default withStyles(styles)(Tabs);