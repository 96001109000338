import React from "react"
import ArrowForward from "material-ui-icons/ArrowForward";

import { Button } from "../../../components/common";
import * as S from "./styles";

export default function AddPost({ name, handleAddPost }) {
    return (
      <S.Root>
        <S.WelcomeMessage>
            Bem-vindo(a), {name && name.split(' ')[0]}!
        </S.WelcomeMessage>
        
        <S.Card onClick={() => handleAddPost(true)}>
            <span>Inicie uma discussão...</span>

            <Button buttonFull size={{ maxWidth: 200, height: 40 }}> 
                <S.ButtonLabel>
                    <span>INICIAR DISCUSSÃO</span> <ArrowForward />
                </S.ButtonLabel>
            </Button>
        </S.Card>
      </S.Root>
    );
}