import { takeLatest } from 'redux-saga/effects';
import mixpanel from 'mixpanel-browser';

import { CRUD_CREATE } from '../actions/dataActions';

function handler(action) {
  const { type, payload } = action;
  const CURSOS_JD = ['5bf2ddd35c4dfb936f64bd9d', '5bf8262bce2a8708fbae18a1', '5c23c5074b46b0e24f6dc8b6'];
  switch (type) {
    case CRUD_CREATE: {
      // Only tracks on mixpanel events with the course attribute inside the payload
      if (Boolean(payload.data.data.course) && CURSOS_JD.includes(payload.data.data.course._id)) {
        delete payload.data.data.course;
        try {
          mixpanel.track(payload.data.name, payload.data.data, (response) => console.log('Success:', payload.data.name, payload.data.data, response));
        } catch (error) {}
        break;
      }
    }
        // no default
  }
}

export default function* watchEventActions() {
  yield [
    takeLatest((action) => action.meta && action.meta.resource && (action.meta.resource === 'event'), handler),
  ];
}
