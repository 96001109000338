import {
  CHECKOUTS_OPEN,
  CHECKOUTS_CLOSE,
  CHECKOUTS_FETCH,
  CHECKOUTS_FETCH_FAILURE,
  CHECKOUTS_FETCH_SUCCESS,
  CHECKOUT_FETCH,
  CHECKOUT_FETCH_FAILURE,
  CHECKOUT_FETCH_SUCCESS,
  CHECKOUT_SET_URL,
  CHECKOUT_SET_GOOGLEGA,
  CART_SET_PRODUCTS,
  RESET_CHECKOUT,
  CHECKOUT_SET_VALUE,
  CHECKOUT_SET_DATA,
  OPEN_CONFIRM_EXIT,
  OPEN_REGISTER,
  CLOSE_REGISTER,
  STEP_LOADING,
  SET_STEPS,
  NEXT_STEP,
  PREVIOUS_STEP,
  SUBMIT_ADDRESS,
  SUBMIT_PERSONAL_DATA,
} from "./constants";

export function checkoutsOpen() {
  return {
    type: CHECKOUTS_OPEN,
  };
}

export function checkoutsClose() {
  return {
    type: CHECKOUTS_CLOSE,
  };
}

export function checkoutsFetchRequest() {
  return {
    type: CHECKOUTS_FETCH,
  };
}

export function checkoutsFetchSuccess({ payload }) {
  return {
    type: CHECKOUTS_FETCH_SUCCESS,
    payload,
  };
}

export function checkoutsFetchFailure() {
  return {
    type: CHECKOUTS_FETCH_FAILURE,
  };
}

export function checkoutFetchRequest({ url }) {
  return {
    type: CHECKOUT_FETCH,
    payload: {
      url,
    },
  };
}

export function checkoutFetchSuccess({ payload }) {
  return {
    type: CHECKOUT_FETCH_SUCCESS,
    payload,
  };
}

export function checkoutFetchFailure() {
  return {
    type: CHECKOUT_FETCH_FAILURE,
  };
}

export function checkoutSetUrl({ url }) {
  return {
    type: CHECKOUT_SET_URL,
    payload: url,
  };
}

export function checkoutSetGoogleGA(payload) {
  return {
    type: CHECKOUT_SET_GOOGLEGA,
    payload,
  };
}

export function checkoutSetData(payload) {
  return {
    type: CHECKOUT_SET_DATA,
    payload,
  };
}

export function setCartProducts({ items, price }) {
  return {
    type: CART_SET_PRODUCTS,
    payload: { items, price },
  };
}

export function resetCheckout() {
  return {
    type: RESET_CHECKOUT,
  };
}

export function openConfirmExit(payload) {
  return {
    type: OPEN_CONFIRM_EXIT,
    payload,
  };
}

export function openRegister() {
  return {
    type: OPEN_REGISTER,
  };
}

export function closeRegister() {
  return {
    type: CLOSE_REGISTER,
  };
}

export function setSteps({ qty }) {
  return {
    type: SET_STEPS,
    payload: { steps: qty },
  };
}

export function stepLoading(payload) {
  return {
    type: STEP_LOADING,
    payload,
  };
}

export function nextStep() {
  return {
    type: NEXT_STEP,
    payload: {},
  };
}

export function previousStep() {
  return {
    type: PREVIOUS_STEP,
    payload: {},
  };
}

export function submitPersonalData({ name, email, cpf, mobile, rg }) {
  return {
    type: SUBMIT_PERSONAL_DATA,
    payload: { name, email, cpf, mobile, rg },
  };
}

export function submitAddress({
  zipcode,
  street,
  street_number,
  complementary,
  neighborhood,
  city,
  state,
}) {
  return {
    type: SUBMIT_ADDRESS,
    payload: {
      zipcode,
      street,
      street_number,
      complementary,
      neighborhood,
      city,
      state,
    },
  };
}
