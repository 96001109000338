import React, { Component } from 'react'
import { withStyles } from 'material-ui/styles';
import Paper from 'material-ui/Paper';
import classNames from 'classnames'
import {ExcelenteGray, Excelente, MedioGray, Medio, RuimGray, Ruim} from '../../components/common/SvgIcons'

const styles = theme => ({
  container: {
    width: '100%',
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    color: theme.palette.text.secondary,
    flexDirection: 'column',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90vw'
    }
  },
  input: {
    width: '90%',
  },
  card: {
    ...theme.transitions.card,
    flexDirection: 'column',
    minHeight: '70px',
    paddingBottom: '0px',
    justifyContent: 'space-between',
    '&:hover $icon': {
      color: theme.palette.orange,
      fontSize: '20px',
      marginRight: '-4px'
    },
    '&:hover $statusIconGray': {
      display: 'none'
    },
    '&:hover $statusIcon': {
      display: 'block'
    }
  },
  title: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.title,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1em'
    }
  },
  text: {
    paddingLeft: '8px'
  },
  icon: {
    width: '30px',
    height: '30px',
    alignItems: 'center',
    display: 'flex'
  },
  percentBarContainer: {
    width: '100%',
    height: '2px'
  },
  percentBar: {
    backgroundColor: theme.palette.orange,
    height: '2px',
  },
  row: {
    padding: '8px 16px 8px 16px',
    [theme.breakpoints.down('xs')]: {
      padding: '8px',
    },
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1
  },
  listContainer: {
    marginTop: '24px',
    minHeight: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '32px'
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100px',
    [theme.breakpoints.down('xs')]: {
      width: '60px'
    }
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '16px'
    }
  },
  percent: {
    fontSize: '12px',
    paddingTop: '4px'
  },
  status: {
    fontSize: '12px',
    paddingTop: '4px',
    textAlign: 'center'
  },
  statusIconGray: {
    display: 'block'
  },
  statusIcon: {
    display: 'none'
  },
  percentNumber: {
    fontSize: '21px'
  }
})

class Item extends Component {
  renderStatus = (status, iconClass, iconGrayClass) => {
    if(status >= 2.5) {
      return (<div>
        <div className={iconGrayClass}><ExcelenteGray /></div>
        <div className={iconClass}><Excelente /></div>
      </div>)
    } else if(status >= 1.5) {
      return (<div>
        <div className={iconGrayClass}><MedioGray /></div>
        <div className={iconClass}><Medio /></div>
      </div>)
    } else {
      return (<div>
        <div className={iconGrayClass}><RuimGray className={iconGrayClass}/></div>
        <div className={iconClass}><Ruim className={iconClass}/></div>
      </div>)
    }
  }

  render() {
    const {classes, handleOpen, _id, name, percent, status} = this.props
    
    return (
      <div className={classes.container}>
        <Paper className={classes.card}  >
          <div className={classes.row} onClick={() => handleOpen(_id)}>
            <div className={classes.title}>
              <div className={classes.text}>{name}</div>
            </div>
            <div className={classes.rightContainer}>
              {this.renderStatus(status, classes.statusIcon, classes.statusIconGray)}
              <div className={classes.status}>
                como estou
              </div>
            </div>
            <div className={classes.rightContainer}>
              <div className={classes.percentNumber}>
                {parseInt(percent*100, 10)}%
              </div>
              <div className={classes.percent}>
                concluído
              </div>
            </div>
            <div className={classes.iconContainer}>
              <i className={classNames("icon-arrow-right icons", classes.icon)}></i>
            </div>
          </div>            
          <div className={classes.percentBarContainer}>
            <div className={classes.percentBar} style={{width: `${parseInt(percent*100, 10)}%`}}/>
          </div>
        </Paper>
        
      </div>
    )
  }
}

export default withStyles(styles)(Item)
