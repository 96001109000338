import authSaga from './authSaga';
import fetchSaga from './fetchSaga';
import responseSaga from './responseSaga';
import referenceSaga from './referenceSaga';
import commentsSaga from './commentsSaga';
import sectionSaga from './sectionSaga';
import customSaga from './customSaga';
import profileSaga from './profileSaga';
import plataformSagas from './plataformSagas';
import courseSaga from './courseSaga';
import eventSaga from './eventSaga';

import newUserSaga from '../containers/User/saga'
import newCourseSaga from '../containers/Cursos/saga'
import newPaymentSaga from '../containers/Payments/saga'
import checkoutSaga from '../containers/Checkout/saga';
/**
 * @param {Object} restClient A REST object with two methods: fetch() and convertResponse()
 */
export default (restClient, authClient) => function* crudSaga() {
    yield [
        authSaga(authClient)(),
        fetchSaga(restClient)(),
        responseSaga(),
        referenceSaga(),
        commentsSaga(restClient)(),
        sectionSaga(restClient)(),
        customSaga(restClient)(),
        profileSaga(restClient)(),
        plataformSagas(restClient)(),
        courseSaga(),
        eventSaga(),
        newUserSaga(),
        newCourseSaga(),
        newPaymentSaga(),
        checkoutSaga(),
    ];
};

