import React from "react";
import styled from "styled-components";
import Dialog from "material-ui/Dialog";
import { withStyles } from "material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import { Close, RadioButtonUnchecked } from "@material-ui/icons";

import {
  setChoosePaymentOpen,
  setBoletoModalOpen,
  setChooseCardOpen,
  showSponsorModal,
} from "../actions";

import { openRegister } from "../../Checkout/actions";

const styles = {
  paper: {
    minWidth: 650,
    "@media (max-width: 900px)": {
      minWidth: "100vw",
      width: "100vw",
      height: "100vh",
      margin: 0,
      maxHeight: "100vh",
    },
  },
  paperResponsive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Proxima Nova" !important;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 8px;
  height: 64px;
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
`;

const HeaderTitle = styled.span`
  color: rgba(0, 0, 0, 0.7);
  font-size: 20px;
  font-weight: bold;
  user-select: none;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 56px 88px 112px 88px;
  @media (max-width: 900px) {
    padding: 56px 16px 112px 16px;
  }
`;

const ListItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ListItem = styled.button`
  border: none;
  height: 64px;
  width: 100%;
  margin-bottom: 16px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  background-color: white;
  text-decoration: none;
  font-family: "Proxima Nova";
  border: 2px solid transparent;
  padding-left: 24px;
  &:hover {
    border: 2px solid rgb(252, 121, 84);
    box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.12);
    z-index: 1;
    position: relative;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const ListItemInfo = styled.div`
  padding-left: 8px;
  display: flex;
  flex-direction: column;
`;

const CheckoutName = styled.span`
  color: rgba(0, 0, 0, 0.75);
  font-size: 17px;
  font-weight: 600;
`;

const CustomCloseButton = styled(IconButton)`
  position: absolute !important;
  right: 0;
  margin-right: 8px !important;
`;

const CustomRadio = styled(RadioButtonUnchecked)`
  width: 16px !important;
  height: 16px !important;
  color: rgba(0, 0, 0, 0.4);
`;

function ChoosePayment({ classes }) {
  const dispatch = useDispatch();
  const { open } = useSelector((state) => state.paymentGroup.choosePayment);
  const responsive = useSelector((state) => state.plataform.responsive);
  const { data: userData } = useSelector((state) => state.userData);

  function handlePaymentOptions(type) {
    dispatch(setChoosePaymentOpen(false));

    if (userData.address.length) {
      if (
        !userData.address[0].zipcode ||
        !userData.address[0].city ||
        !userData.address[0].street ||
        !userData.address[0].street_number ||
        !userData.address[0].neighborhood ||
        !userData.address[0].state ||
        !userData.mobile ||
        !userData.cpf
      ) {
        dispatch(showSponsorModal(false));
        dispatch(openRegister());
      } else {
        if (type === "boleto") {
          return dispatch(setBoletoModalOpen(true));
        }
        dispatch(setChooseCardOpen(true));
      }
    } else {
      dispatch(showSponsorModal(false));
      dispatch(openRegister());
    }
  }

  return (
    <Dialog
      open={open}
      fullScreen={responsive}
      classes={{ paper: classes.paper }}
    >
      <Container>
        <Header>
          <HeaderTitle>Escolha a forma de pagamento</HeaderTitle>
          <CustomCloseButton
            aria-label="Fechar"
            onClick={() => dispatch(setChoosePaymentOpen(false))}
          >
            <Close />
          </CustomCloseButton>
        </Header>
        <Content>
          <ListItems>
            <ListItem onClick={() => handlePaymentOptions("boleto")}>
              <CustomRadio />
              <ListItemInfo>
                <CheckoutName>Boleto</CheckoutName>
              </ListItemInfo>
            </ListItem>
            <ListItem onClick={() => handlePaymentOptions("credit_card")}>
              <CustomRadio />
              <ListItemInfo>
                <CheckoutName>Cartão de Crédito</CheckoutName>
              </ListItemInfo>
            </ListItem>
          </ListItems>
        </Content>
      </Container>
    </Dialog>
  );
}

export default withStyles(styles)(ChoosePayment);
