import React from 'react';
import { withStyles } from 'material-ui/styles';
import Dialog, {
  DialogContent,
  DialogTitle,
} from 'material-ui/Dialog';
import Close from 'material-ui-icons/Close'
import IconButton from 'material-ui/IconButton';
import {crudGetOne as crudGetOneAction, crudUpdate} from '../../actions/dataActions'
import {loginDisqus as loginDisqusAction} from '../../actions/commentsActions'
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import moment from 'moment'
import {Disqus} from '../../components'

const resource = 'timelinePost'

const styles = theme => ({
    root: {
      overflow: 'auto',
      alignItems: 'baseline',
      WebkitOverflowScrolling: 'touch'
    },
    container: {
        overflow: 'auto',
      borderRadius: '4px',
      minWidth: '100%',
      maxHeight: 'fit-content',
      height: 'fit-content',
      [theme.breakpoints.up('md')]: {
        minWidth: '700px'
      },
    },
    title: {
        color: theme.palette.orange,
        fontSize: '18px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: '600',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '80px',
        paddingLeft: '16px',
        paddingRight: '16px'
    },
    close: {
        color: theme.palette.text.clear,
        cursor: 'pointer',
        marginTop: '-15px',
        width: '20px',
        '&:hover': {
            color: theme.palette.orange,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'space-between',
        minHeight: '70px',
        padding: '16px',
        cursor: 'pointer'
    },
    authorData: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '16px'
    },
    authorPhoto: {
        background: 'yellowgreen',
        backgroundSize: 'contain',
        color: '#fff',
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '8px'
    },
    author: {
        ...theme.typography.body3,
        color: theme.palette.text.primary
    },
    date: {
        ...theme.typography.small,
        fontSize: '12px',
        color: theme.palette.black.a3
    },
    titlePost: {
        ...theme.typography.subheading,
        fontSize: '18px',
        color: theme.palette.text.basic,
        fontWeight: 600
    },
    contentPost: {
        ...theme.typography.body1,
        color: theme.palette.text.basic,
        fontWeight: 'normal',
        paddingBottom: '16px',
        fontSize: '15px'
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '16px',
        paddingBottom: '16px'
    },
    comentar: {
        color: theme.palette.orange
    },
    buttons: {
        display: 'flex'
    },
    icon: {
        height: '16px',
        width: '24px',
        color: 'rgba(0,0,0,0.1)'
    },
    socialContainer: {
        marginRight: '16px',
        fontSize: '12px',
        color: theme.palette.text.clearGrey,
        alignItems: 'center',
        display: 'flex',
        '&:hover': {
            color: theme.palette.text.clear,
        },
        '&:hover $icon': {
            color: theme.palette.orange
        }
    },
    image: {
        width: '100%',
        marginTop: '8px'
    }
})

class ModalAddPost extends React.Component {
  handleClose = () => {
    this.props.handleOpen(false)
  };

  componentWillReceiveProps(nextProps) {
    if(nextProps.open && (!this.props.open || this.props.open !== nextProps.open || !nextProps[resource])) {
      this.props.crudGetOne(nextProps.open)
    }
  }
  
  componentDidMount() {
      this.props.loginDisqus()
  }

  render() {
    const {classes, timelinePost = {}, disqus_auth, addComment} = this.props
    const {createdAt, _id, title, text, avatar, authorName = "", image, externalId} = timelinePost
    const date = moment(createdAt);
    return (
        <Dialog
            open={Boolean(this.props.open)}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
            classes={{paper: classes.container, root: classes.root}}
        >
            <DialogTitle disableTypography className={classes.title}>
                <div className={classes.authorData}>                
                    {Boolean(avatar) ? (
                        <div className={classes.authorPhoto} style={{
                        backgroundImage: `url(${avatar})`,
                        backgroundColor: '#FFFFFF'
                        }}></div>
                    ) : (
                        <div className={classes.authorPhoto}>
                        {(authorName && authorName.slice(0,1)) || 'C'}
                        </div> 
                    )}
                    <div className={classes.clippingData}> 
                        <div className={classes.author}> {authorName.split(' ')[0]}</div>
                        <div className={classes.date}> {date.fromNow()}</div>
                    </div>
                </div>    
                <IconButton
                    className={classes.close}
                    aria-label="Fechar"
                    onClick={this.handleClose} >
                    <Close/> 
                </IconButton>
            </DialogTitle>
            
            <DialogContent className={classes.content}>
                { image && <img src={image} alt='content' className={classes.image} /> }
                <div className={classes.titlePost}>{title}</div>
                <div className={classes.contentPost} dangerouslySetInnerHTML={{__html: text}}></div>

                { _id && <Disqus id={_id}
                    addComment={addComment}
                    resource='mural'
                    externalId={externalId}
                    remote_auth_s3={disqus_auth}
                    title={title} /> }
            </DialogContent>
        </Dialog>
    );
  }
}
const mapStateToProps = (state, {open} ) => {
    return {
        [resource]: state[resource].data[open],
        user: state.user.profile,
        disqus_auth: state.comments.disqus_auth
    }
  }
  
const mapDispatchToProps = {
    crudGetOne: (_id) => crudGetOneAction(resource, _id, '', false, true, {}),
    addComment: (_id) => crudUpdate(resource, `${_id}/addComment`, '', false, false),
    loginDisqus: () => loginDisqusAction()
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(ModalAddPost);
