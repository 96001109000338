import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withStyles } from 'material-ui/styles';
import compose from 'recompose/compose';
import Dialog from '../../components/ModalScreen';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import Input from 'material-ui/Input';
import IconButton from 'material-ui/IconButton';
import Button from 'material-ui/Button';
import ArrowLeft from 'material-ui-icons/ChevronLeft'
import Tooltip from 'material-ui/Tooltip';
import TimerIcon from 'material-ui-icons/AccessTime';
import Profile from 'material-ui-icons/Person';
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';
import { ElementList } from '../../components/common';
import debounce from 'lodash/debounce';
import { FetchList } from '../../components';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Link } from 'react-router';
import SearchFilter from './SearchFilter';
import Highlighter from "react-highlight-words";
const removeDiacritics = require('diacritics').remove;

const styles = theme => ({
  appBar: {
    top: 0,
    left: 'auto',
    right: 0,
    backgroundColor: '#FFF',
    boxShadow: '0 1px 0 0 #E8E8E8',
    transition: theme.transitions.create('width'),
    height: '65px',
    paddingRight: '24px',
    justifyContent: 'center'
  },
  backContainer: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      padding: '18px'
    },
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  backIcon: {
    ...theme.typography.menu,
    color: theme.palette.text.clear,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '8px',
    paddingLeft: '0px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  responsiveDisplay: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inherit'
    }
  },
  content: {
    marginTop: '65px',
    flex: 1,
    marginLeft: '8px',
    marginRight: '8px'
  },
  toolbar: {
    padding: '0px'
  },
  ajuda: {
    fontWeight: 600,
    lineHeight: '14px',
    fontSize: '12px',
    color: theme.palette.text.clear,
  },
  icon: {
    color: theme.palette.text.clear,
  },
  titleContainer: {
    paddingTop: '4px',
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    ...theme.typography.headline,
    lineHeight: '1.1em',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: theme.palette.text.clear,
  },
  subtitle: {
    ...theme.typography.body1,
    textTransform: 'uppercase',
    display: 'none',
    flex: 1,
    textAlign: 'center',
    color: theme.palette.text.clear,
    lineHeight: '1rem',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '1.8rem',
    margin: '0 auto',
    fontSize: '12px'
  },
  menuIcons: {
    display: 'flex',
    color: theme.palette.text.clear,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  slide: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'auto',
    width: '100%'
  },
  root: {
    width: '100%',
    backgroundColor: '#F2EEED'
  },
  wrapper: {
    marginTop: '65px',
    flex: 1,
    marginLeft: 'calc(50% - 485px)',
    marginRight: '8px',
    padding: '40px 20px 0',
    maxWidth: '1030px',
    background: '#F2EEED',  
    minHeight: 'calc(100vh - 64px)',
    '@media (max-width: 1650px)': {
      position: 'static',
      margin: '65px auto'
    }
  },
  input: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
    fontSize: '34px',
    fontWeight: 'bold',
    letterSpacing: '-1.5px',
    padding: '6px 0 0 0',
    boxSizing: 'border-box',
    height: '40px',

    '&:hover': {
      borderBottom: '1px solid #cccccc'
    }
  },
  inputUnderline: {
    
    '&:before': {
      display: 'none'
    },

    '&:after': {
      backgroundColor: '#cccccc',
      transition: 'none',
      height: '1px'
    }
  },
  total: {
    color: 'rgba(0, 0, 0, 0.4)',
    fontFamily: '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
    fontSize: '14px',
    fontWeight: 500,
    height: '17px',
    marginBottom: '33px'
  },
  list: {
    paddingBottom: '30px',
    '& $item': {
      borderTop: '1px solid #E5E5E5'
    }
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    padding: '26px 0',
    textDecoration: 'none',
    '&:hover > div + div > div:first-child': {
      fontWeight: '600'
    }
  },
  itemDate: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
    fontSize: '13px',
    lineHeight: '18px',
    minWidth: '95px',
    margin: 'auto 0'
  },
  itemContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  itemTitle: {
    color: 'rgba(0, 0, 0, 0.8)',
    fontFamily: '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
    fontSize: '17px',
    fontWeight: 500,
    lineHeight: '24px',
  },
  itemText: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
    fontSize: '14px',
    margin: '4px 0 10px 0'
  },
  itemSection: {
    color: '#F68525',
    fontFamily: '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '18px',
    textTransform: 'uppercase',
  }

});

const resourceMap = {
  'noticias': 'articles',
  'fichamentos': 'notebookcontents'
}

class ModalBusca extends Component {
  constructor(props) {
    super(props);
    this.state = { query: this.props.location.query.q };
  }

  track = () => {
    this.props.trackMetric('Clicou em ajuda', { url: location.href });
    window.open('http://ajuda.clippingcacd.com.br/', "_blank");
  }

  openTimer = () => {
    const { concurso } = this.props
    const token = localStorage.token && localStorage.token.replace('Bearer ', '')
    this.props.trackMetric('Clicou no cronômetro', { url: location.href });
    window.open(`https://clippingcacd.com.br/timer/${concurso && concurso.active.name}?token=${token}`, '_blank')
  }

  refreshSearch = debounce(() => {
    const { resource }  = this.props.routeParams;
    const { query }  = this.state;
    this.props.pushRouter(`/busca/${resource}?q=${query}`);
  }, 500)

  handleChangeQuery = (event) => {
    this.setState({ query: event.target.value });
    if(this.state.query !== event.target.value) {
      this.refreshSearch();
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.refreshState !== this.props.refreshState) {
      const elem = this.refs.searchRoot; 
      elem && elem.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }

  sanitizeQuery = (text) => {
    return removeDiacritics(text);
  }

  render() {
    const { open, handleOpen, classes, searchResults } = this.props;
    const { query } = this.state;
    const { resource }  = this.props.routeParams;

    function getItemDate(pubDate, createdAt) {
      let date;
      
      if(pubDate || createdAt)
        date = moment(pubDate || createdAt);

      if(!date || !date.isValid())
        return;

      return date.format('DD, MMM. YYYY');
    }

    return (
      <Dialog open={Boolean(open)} handleOpen={handleOpen} className={classes.dialog}>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.backContainer}>
              <Button
                disableRipple
                disableFocusRipple
                aria-label="voltar"
                onClick={() => handleOpen(false)}
                className={classes.backIcon}
              >
                <ArrowLeft /> <div className={classes.responsiveDisplay}>voltar</div>
              </Button>
            </div>
            <div className={classes.titleContainer}></div>
            <div className={classes.menuIcons}>
              <Tooltip title="Ir para central de ajuda" enterDelay={300} className={classes.responsiveDisplay}>
                <Button color="primary" className={classes.ajuda} onClick={this.track}>
                  Ajuda
              </Button>
              </Tooltip>
              <Tooltip title="Cronômetro de estudos" enterDelay={300} className={classes.responsiveDisplay}>
                <IconButton
                  aria-label="change theme"
                  onClick={this.openTimer}
                >
                  <TimerIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Ajustes do perfil" enterDelay={300} className={classes.responsiveDisplay}>
                <IconButton
                  aria-label="change direction"
                  onClick={() => this.props.pushRouter('/perfil')}
                >
                  <Profile className={classes.icon} />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
        </AppBar>

        <div ref="searchRoot" className={classes.root}>
          <div className={classes.wrapper}>
            <Input
              inputProps={{ spellCheck: false }}
              value={query}
              onChange={this.handleChangeQuery}
              classes={{input: classes.input, underline: classes.inputUnderline}} 
              fullWidth
              autoFocus
              placeholder="Pesquisar em Clippings"
              />
            <div className={classes.total}>{searchResults.list.total && query ? `${searchResults.list.total.value} notícias encontradas` : `Nenhuma notícia encontrada`}</div>
            
            <div className={classes.list}>
              <FetchList {...this.props}
                cancelPrevious={true}
                filters={<SearchFilter showRelevance={true}/>}
                resource={`search/${resourceMap[resource]}`}
                noData={<div></div>}
                >
                <ElementList
                  renderRow={props => {
                    return (
                      <Link to={`/clippings/${props.section.clipping}/${props._id}`} key={props._id} className={classes.item}>
                        <div className={classes.itemDate}>
                          {getItemDate(props.pubDate, props.createdAt)}
                        </div>
                        <div className={classes.itemContent}>
                          <div className={classes.itemTitle}>{props.title}</div>
                          <Highlighter
                            className={classes.itemText}
                            highlightTag="strong"
                            searchWords={query.split(/\s/)}
                            autoEscape={true}
                            textToHighlight={props.content}
                          />
                          <div className={classes.itemSection}>{props.section.name}</div>
                        </div>
                      </Link>
                    )
                  }}
                  />
              </FetchList>
            </div>
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    concurso: state.concurso,
    searchResults: state[`search/${resourceMap[ownProps.routeParams.resource]}`],
    refreshState: state.plataform.refreshState
  }
}

const mapDispatchToProps = {
  trackMetric: (name, data) => trackMetricAction(name, data)
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ModalBusca);
