import { apiComments } from './index';
import api from '../api';

export function createComment({ text, parentComment, topic }) {
  return apiComments.post('/api/comments', {
    text,
    parentComment,
    topic,
  });
}

export function createCommentClipping({ text, parentComment, topic }) {
  return apiComments.post('/api/comments/clipping', {
    text,
    parentComment,
    topic,
    flagged: true,
  });
}

/**
 *
 * @param {string} topic
 * @returns {Promise<number>} commentsAndAnswersCounter
 */
export function getCounterCommentsByTopic(topic) {
  if (!topic || topic === '') return 0;

  return apiComments.get(`api/comments/topic/${topic}/count`);
}

export function getCommentsByTopic(topic, skip, limit) {
  return apiComments.get(
    '/api/comments/topic/' + topic + `?skip=${skip}&limit=${limit}&sort=recent`
  );
}
export function getCommentsAnswers(commentId, skip, limit) {
  return apiComments.get(
    '/api/comments/' +
      commentId +
      '/answers' +
      `?skip=${skip}&limit=${limit}&sort=recent`
  );
}

export function deleteComment(commentId) {
  return apiComments.delete(`/api/comments/` + commentId);
}

export function likeComment(commentId) {
  return apiComments.post('/api/comments/' + commentId + '/like');
}

export function removeLikeComment(commentId) {
  return apiComments.delete('/api/comments/' + commentId + '/interaction');
}

export function createReport({ comment, message }) {
  return apiComments.post('/api/report', {
    comment,
    message,
  });
}

export function getUser(userId) {
  return api.get('user/' + userId);
}

export function getUsers(userIds) {
  return api.post('user/details', {
    userIds: userIds,
  });
}
