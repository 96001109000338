import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from 'material-ui/styles';
import Paper from 'material-ui/Paper';
import { push } from 'react-router-redux';

const styles = theme => ({
  container: {
    width: '100%',
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    color: theme.palette.text.clear,
    flexDirection: 'column',
  },
  card: {
    ...theme.transitions.card,
    flexDirection: 'column',
    minHeight: '70px',
    paddingBottom: '0px',
    justifyContent: 'space-between'
  },
  row: {
    padding: '8px 16px 8px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    cursor: 'pointer',
    height: '80px'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.title,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1em'
    }
  },
  text: {
    paddingLeft: '8px'
  }
});

class Item extends Component {
  render() {
    const { classes, name, clipping, article, pushRouter } = this.props;

    return (
      <div className={classes.container}>
        <Paper className={classes.card}>
          <div className={classes.row} onClick={() => pushRouter(`/clippings/${clipping}/${article}`)}>
            <div className={classes.title}>
              <div className={classes.text}>{name}</div>
            </div>
          </div>  
        </Paper>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    pushRouter: (path) => dispatch(push(path))
  }
}

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps),
)(Item);
