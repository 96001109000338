import React, { Component } from 'react'
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import _ from 'lodash';
import { withStyles } from 'material-ui/styles';
import compose from 'recompose/compose';
import Dialog from '../../components/ModalScreen';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import Tooltip from 'material-ui/Tooltip';
import Button from 'material-ui/Button';
import Slide from 'material-ui/transitions/Slide';
import ArrowLeft from 'material-ui-icons/ChevronLeft';
import RotateRight from 'material-ui-icons/RotateRight';
import Check from 'material-ui-icons/Check';
import {crudGetList as crudGetListAction} from '../../actions/dataActions';
import FichamentosContent from './FichamentosContent';
import Drawer from '../../components/DrawerFichamentos';
import { SORT_ASC } from '../../reducers/resource/list/queryReducer';
import debounce from 'lodash/debounce';
import { crudCreate as crudCreateAction,
         crudUpdate as crudUpdateAction,
         crudDelete as crudDeleteAction } from '../../actions/dataActions';
import swal from 'sweetalert';
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';

const resource = 'notebookContent';

const styles = theme => ({
  appBar: {
    top: 0,
    left: 'auto',
    right: 0,
    backgroundColor: '#FFF',
    transition: theme.transitions.create('width'),
    height: '65px',
    paddingRight: '24px',
    justifyContent: 'center',
    boxShadow: theme.shadows[27],
  },
  backContainer: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      padding: '18px'
    },
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  backIcon: {
    ...theme.typography.menu,
    color: theme.palette.text.clear,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '8px',
    paddingLeft: '0px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  responsiveDisplay: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inherit'
    }
  },
  toolbar: {
    padding: '0px'
  },
  icon: {
    color: theme.palette.text.clear,
  },
  titleContainer: {
    ...theme.typography.title,
    paddingTop: '4px',
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    
  },
  menuIcons: {
    display: 'flex',
    color: theme.palette.common.white,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  dialog: {
    display: 'flex',
    alignItems: 'stretch',
    minHeight: '100vh',
    width: '100%',
  },
  slide: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: '100%'
  },
  drawer: {
    
  },
  arrowLeft: {
    height: '40px',
    width: '40px',
    color: theme.palette.black.a3,
    [theme.breakpoints.up('sm')]: {
      height: '25px',
      width: '25px',
      color: theme.palette.text.clear
    },
  },
  titleScreen: {
    fontSize: '12px'
  },
  saving: {
    ...theme.typography.body2,
    color: theme.palette.green,
    alignItems: 'center',
    display: 'flex'
  },
  tooltip: {
    top: '-15px !important' 
  },
  savingIcon: {
    height: '20px'
  }
});

class ModalFichamentos extends Component {

  constructor(props) {
    super(props)

    const {fichamentos} = props
    const order = fichamentos.map(({_id, order}) => {return {_id, order: order || 99999999}})
    this.state = {
      mobileOpen: false,
      saving: false,
      order,
      scrolledToFichamento: false
    };
  }
  
  addFichamento = (order) => {
    const newOrder = this.state.order.map(item => {
      return (item.order > order ? {...item, order: item.order ? item.order + 10 : 0 } : {...item, order: item.order ? item.order - 10 : 0 })
    })

    const { saveOrder, crudCreate, open = {}, concurso, trackMetric } = this.props
    saveOrder(open._id, newOrder)
    trackMetric('Caderno - Ativação', { target: 'notebook', trigger: 'click', resource: open._id, action: 'create' });
    crudCreate(resource, {order: order+1, notebook: open._id, type: 'user', concursoId: concurso.concursoId }, '/cadernos', false)
  }

  saveOrder = debounce((order) => {
    if(this.props.open && this.props.open._id)
      this.props.saveOrder(this.props.open._id, order)
  }, 1000);

  changeOrder = (order) => {
    this.setState({order})
    this.saveOrder(order)
  }

  closeDrawer = () => this.setState({ mobileOpen: false });

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  
  componentDidMount() {
    if(this.props.open && this.props.open._id) {
      this.props.crudGetList(this.props.open._id)
    }
  }

  transition = (props) => {
    return <Slide direction="up" {...props} className={this.props.classes.slide}  />;
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.open && nextProps.open._id && (this.props.open && this.props.open._id) !== nextProps.open._id) {
      this.props.crudGetList(nextProps.open._id)
    }
    if(nextProps.fichamentos !== this.props.fichamentos) {
      const order = nextProps.fichamentos.map(({_id, order}) => {return {_id, order}})
      this.setState({order})
    }
    if(nextProps.open && nextProps.open._id && nextProps.fichamentos.length !== this.props.fichamentos.length) {
      this.props.crudGetList(nextProps.open._id)
    }
  }

  setSaving = (saving) => {
    this.setState({saving})
  }

  getArrayOfArticles = (sections = []) => {
    let arrayArticles = []
    let count = 0
    sections.map(({name, articles = []}) => {
      return articles.map((article) => {
        count++
        return arrayArticles[count] = {...article, section: name, number: count}
      })
    })

    return {arrayArticles, total: count}
  }

  render () {
    const {open = '', handleOpen, classes, activeNotebook = {}, fichamentos, deleteFichamento, saveTitle, onSaveEditorState, loading} = this.props
    const {saving, order} = this.state
    
    const listFichamentos = fichamentos.map(x => _.assign(x, order.find(y => y._id === x._id))).sort((a,b) => a.order - b.order);

    return (
      <Dialog open={Boolean(open)} handleOpen={handleOpen}>
        <AppBar className={classes.appBar} position='fixed'>
          <Toolbar className={classes.toolbar}>
            <div className={classes.backContainer}>
              <Button
                disableRipple
                disableFocusRipple
                aria-label="voltar"
                onClick={() => handleOpen(false)}
                className={classes.backIcon}
              >
                <ArrowLeft className={classes.arrowLeft}/> <div className={classes.responsiveDisplay}>voltar</div>
              </Button>
            </div>
            <div className={classes.titleContainer}>
                <div className={classes.titleScreen}>FICHAMENTOS</div>
                <div className={classes.titleMateria}>{activeNotebook.title}</div>
            </div>
            <div className={classes.menuIcons}>

            {saving ? <Tooltip classes={{popper: classes.tooltip}} title="Aguarde, o seu fichamento está sendo salvo." enterDelay={300} >
              <div className={classes.saving} >
                <RotateRight className={classes.savingIcon} /> salvando...
              </div>
            </Tooltip> : <Tooltip classes={{popper: classes.tooltip}} title="Todas as informações já estão salvas no sistema." enterDelay={300} >
              <div className={classes.saving} >
                <Check className={classes.savingIcon} /> salvo
              </div>
            </Tooltip>}
            
            </div>
          </Toolbar>
        </AppBar>
          <Drawer
            changeOrder={this.changeOrder}
            pushRouter={handleOpen}
            location={location}
            className={classes.drawer}
            disablePermanent={false}
            onRequestClose={this.handleDrawerToggle}
            mobileOpen={this.state.mobileOpen}
            closeDrawer={this.closeDrawer}
            items={listFichamentos}
          />
          <FichamentosContent 
            fichamentos={listFichamentos} deleteFichamento={deleteFichamento} onSaveEditorState={onSaveEditorState} fichamentoId={open && open.fichamentoId}
            setSaving={this.setSaving} saving={saving} addFichamento={this.addFichamento} saveTitle={saveTitle} loading={loading} />
      </Dialog>
    )
  }
}

const mapStateToProps = (state, {open} ) => {
  const fichamentos = _.filter(state[resource].data, {notebook: open && open._id})

  return {
    notebook: state['notebook'].data,
    activeNotebook: state['notebook'].data[open && open._id],
    loading: state.loading,
    fichamentos,
    concurso: state.concurso.active
  }
}

const mapDispatchToProps = (dispatch, ownProps) =>  {
  return {
    crudGetList: (_id) => dispatch(crudGetListAction(resource, {page: 1, perPage: 999}, {field: 'order', order: SORT_ASC}, {notebook: _id}, true)),
    pushRouter: (path) => dispatch(push(path)),
    crudCreate: (...params) => dispatch(crudCreateAction(...params)),
    saveTitle: (_id, title) => dispatch(crudUpdateAction(resource, _id, {title}, {}, '/cadernos', false, false)),
    saveOrder: (_id, order) => dispatch(crudUpdateAction(`notebook/${_id}/reorder`, '', order, {}, '/cadernos', false, false)),
    onSaveEditorState: (_id, editorState) => {
      dispatch(crudUpdateAction('notebookContent', _id, {content: editorState}, {}, '/cadernos', false, false))
      dispatch(trackMetricAction('Caderno - Ativação', { target: 'notebookContent', trigger: 'click', parentResource: ownProps.open._id, resource: _id, action: 'edit' }))
    },
    deleteFichamento: (_id) => {
      swal({
          title: "Deseja remover este fichamento?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        },
      )
      .then((willDelete) => {
        if (willDelete) {
          dispatch(trackMetricAction('Caderno - Ativação', { target: 'notebookContent', trigger: 'click', parentResource: ownProps.open._id, resource: _id, action: 'delete' }))
          dispatch(crudDeleteAction(resource, _id, '', false, false, false))
          swal("O fichamento foi removido com sucesso!", {
            icon: "success",
          });
        } else {
          swal.close()
        }
      });
    },
    trackMetric: (name, data) => dispatch(trackMetricAction(name, data))
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ModalFichamentos);
