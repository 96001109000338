import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import Filters from './Filters';

import {
  QuestionsContainer,
  QuestionsContent,
  SearchResults,
  QuestionContainerMain,
} from './styles';

import QuestionCard from './Card';
import Pagination from '../../components/PaginationQS/Pagination';
import QustionAndTestHeader from '../../components/QuestionAndTestHeader';

import api from '../../services/api';

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
  padding-top: 48px;
`;

const EmptySeach = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 16px;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.7);
  padding: 32px;
  height: 150px;
`;

function Questions() {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const userId = useSelector((state) => state.user.profile?._id);

  const state = useSelector((reduxState) => reduxState.tests);
  const concursoId = useSelector(
    (reduxState) => reduxState.concurso.active.concursoId
  );
  const concursoIdsData = useSelector(
    (reduxState) => reduxState.concurso.active.test
  );

  const concursoActive = useSelector(
    (reduxState) => reduxState.concurso.active
  );

  const [questions, setQuestions] = useState([]);
  const [filterLoading, setFilterLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);

  const set_loading = (status) => {
    dispatch({
      type: 'SET_LOADING',
      payload: status,
    });
  };

  function set_totalPages(totalPage) {
    dispatch({
      type: 'SET_TOTAL_PAGE',
      payload: totalPage,
    });
  }

  async function fetchQuestions({ concursoIdsData }) {
    try {
      let URL_PARAMS = `?${concursoIdsData.reduce((acum, current) => {
        return (acum = `concursoId=${current}&` + acum);
      }, '')}page=${state.porMaterias.page}`;

      if (state.porMaterias.filter.query) {
        URL_PARAMS = `?${concursoIdsData.reduce((acum, current) => {
          return (acum = `concursoId=${current}&` + acum);
        }, '')}${state.porMaterias.filter.query}`;
      }

      if (typeof api.defaults.headers.Authorization === 'object') {
        api.defaults.headers.Authorization = localStorage.getItem('token');
      }

      const { data } = await api.get(`/questionItem/questions${URL_PARAMS}`);

      if (!data?.questions) {
        return setQuestions([]);
      }

      const questionIds = data.questions
        .map((question) => {
          return `questionItem=${question._id}&`;
        })
        .join('');
      const { data: docs } = await api.get(
        `/questionItemLooseAnswer?user=${userId}&${questionIds}&isDeleted=false`
      );

      if (docs.docs) {
        const answers = docs.docs.reduce(
          (acum, current, index, arrayAnswers) => {
            if (
              index === 0 ||
              arrayAnswers[index - 1].questionItem !== current.questionItem
            ) {
              acum[current.questionItem] = [current];
            } else {
              acum[current.questionItem].push(current);
            }
            return acum;
          },
          []
        );

        data.questions.forEach((question) => {
          if (answers[question._id]) {
            question.answer = answers[question._id].at(-1);
          }
        });
      }

      setTotalPages(data.count);

      set_totalPages(data.count);
      setQuestions(data.questions);
    } catch (error) {
      set_totalPages(10);
      set_loading(false);
      console.error('Falha ao carregar questões de atualidades', error);
    }
  }

  async function fixUrlFilters({ concursoIdsData, search }) {
    try {
      if (search && !state.porMaterias.filter.query) {
        const filter = search.replace('?', '');
        const { data } = await api.get(
          `/questionItem/questions?${concursoIdsData.reduce((acum, current) => {
            return (acum = `concursoId=${current}&` + acum);
          }, '')}${filter}`
        );

        if (!data?.questions) {
          setQuestions([]);
        }

        setTotalPages(data.count);

        set_totalPages(data.count);
        setQuestions(data.questions);
      }
    } catch (error) {
      set_totalPages(10);
      set_loading(false);
      console.error('Falha ao carregar questões', error);
    }
  }

  useEffect(() => {
    async function filterQuestions() {
      setFilterLoading(true);
      const idConcurso = localStorage.getItem('concurso');
      if (concursoActive && idConcurso && concursoIdsData) {
        if (concursoActive._id === idConcurso) {
          await fetchQuestions({ concursoIdsData });
          setFilterLoading(false);
          set_loading(false);
          await fixUrlFilters({ concursoIdsData, search });
        }
      } else {
        await fetchQuestions({ concursoIdsData });
        setFilterLoading(false);
        set_loading(false);
        await fixUrlFilters({ concursoIdsData, search });
      }
    }

    filterQuestions();
  }, [state.porMaterias.page, state.porMaterias.filter.query, concursoId]);

  useEffect(() => {
    const page = new URLSearchParams(search).get('page');

    const filter = search.replace('?', '');

    dispatch({
      type: 'SET_POR_MATERIAS_FILTER',
      payload: filter,
    });
    return dispatch({
      type: 'CHANGE_POR_MATERIAS_PAGE',
      payload: Number(page) || 1,
    });
  }, [search, concursoId]);

  return (
    <QuestionContainerMain>
      <QuestionsContainer>
        <QustionAndTestHeader />
        <Filters loading={filterLoading} />
        {filterLoading ? (
          <LoadingContainer>
            <CircularProgress style={{ color: '#F58220' }} />
          </LoadingContainer>
        ) : (
          <QuestionsContent>
            <SearchResults>
              Encontramos <strong>{totalPages || 0}</strong> questões
            </SearchResults>

            {questions && questions.length > 0 ? (
              questions.map((question, index) => {
                return (
                  <QuestionCard
                    question={question}
                    key={`questionItem-${index}`}
                  />
                );
              })
            ) : (
              <EmptySeach>
                Não localizamos nenhuma questão com esses termos de busca...
              </EmptySeach>
            )}
          </QuestionsContent>
        )}
        <Pagination
          total={totalPages}
          perPage={10}
          currentPage={state.porMaterias.page}
          tab={'por_materia'}
        />
      </QuestionsContainer>
    </QuestionContainerMain>
  );
}

export default Questions;
