import React from 'react';
import compose from 'recompose/compose';
import { withStyles } from 'material-ui/styles';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import Button from 'material-ui/Button';
import Menu, { MenuItem } from 'material-ui/Menu';
import LockIcon from 'material-ui-icons/Lock';
import ArrowDown from 'material-ui-icons/ArrowDropDown';
import _ from 'lodash';
import classNames from 'classnames';
import Fragment from 'react-dot-fragment';
import Divider from 'material-ui/Divider';
import { crudCreate } from '../../actions/dataActions';
import { setConcurso as setConcursoAction } from '../../actions/concursoActions';
import SvgIcon from 'material-ui/SvgIcon';

const LogoIcon = (props) => (
  <SvgIcon
    viewBox='0 0 37 37'
    {...props}
    style={{ fill: '#FC5836', height: '40px', width: '40px' }}
  >
    <g id='Page-1' stroke='none' strokeWidth='1'>
      <polygon
        id='Fill-1'
        points='9.49472741 18.0000016 26.4000024 18.0000016 26.4000024 17.0000015 9.49472741 17.0000015'
      ></polygon>
      <polygon
        id='Fill-2'
        points='9.49472741 15.2000014 26.4000024 15.2000014 26.4000024 14.2000013 9.49472741 14.2000013'
      ></polygon>
      <polygon
        id='Fill-3'
        points='20.4000018 6.80000061 26.4000024 6.80000061 26.4000024 5.80000052 20.4000018 5.80000052'
      ></polygon>
      <polygon
        id='Fill-4'
        points='20.4000018 9.60000086 26.4000024 9.60000086 26.4000024 8.60000077 20.4000018 8.60000077'
      ></polygon>
      <polygon
        id='Fill-5'
        points='20.4000018 12.4000011 26.4000024 12.4000011 26.4000024 11.400001 20.4000018 11.400001'
      ></polygon>
      <polygon
        id='Fill-6'
        points='9.49472741 20.8000019 17.741203 20.8000019 17.741203 19.8000018 9.49472741 19.8000018'
      ></polygon>
      <path
        d='M9.40000084,12.8000011 L9.40000084,5.80000052 L17.4000016,5.80000052 L17.4000016,12.8000011 L9.40000084,12.8000011 Z M10.6000009,11.600001 L16.2000014,11.600001 L16.2000014,7.00000063 L10.6000009,7.00000063 L10.6000009,11.600001 Z'
        id='Fill-7'
      ></path>
      <path
        d='M30.4000027,18.4725892 L30.4000027,8.20000073 C32.7723228,11.0396051 34.2000031,14.6747583 34.2000031,18.6340575 C34.2000031,21.044894 33.6713233,23.3356876 32.722089,25.4000023 L32.722089,19.8175536 L30.4000027,18.4725892 Z M31.6000028,27.5090738 C30.2009181,29.5878027 28.3267244,31.334365 26.130909,32.6000029 L19.0000017,28.4798841 L31.6000028,21.2000019 L31.6000028,27.5090738 Z M10.6000009,33.2622421 L17.6083056,29.2000026 L24.6000022,33.2530482 C22.473263,34.2448076 20.0964968,34.8000031 17.5895838,34.8000031 C15.0911257,34.8000031 12.7216067,34.2477734 10.6000009,33.2622421 L10.6000009,33.2622421 Z M4.00000036,27.5568454 L4.00000036,21.2000019 L16.6000015,28.4730235 L9.45048036,32.6000029 C7.26534503,31.3457044 5.3993902,29.615411 4.00000036,27.5568454 L4.00000036,27.5568454 Z M5.00000045,8.20000073 L5.00000045,18.4431503 L2.7004073,19.7658347 L2.7004073,19.7784432 L2.7004073,25.4000023 C1.73744129,23.329818 1.20000011,21.0278787 1.20000011,18.6034575 C1.20000011,14.6519151 2.62882935,11.0254901 5.00000045,8.20000073 L5.00000045,8.20000073 Z M6.20000055,2.86419365 C6.20000055,1.94629819 6.94893769,1.20000011 7.86970759,1.20000011 C8.79017549,1.20000011 9.53911263,1.94629819 9.53911263,2.86419365 L9.53911263,3.48224527 L29.0000026,3.48224527 L29.0000026,21.1332937 L17.6001526,27.8000025 L6.20000055,21.1332937 L6.20000055,2.86419365 Z M30.3979506,6.40434995 L30.3979506,3.51430359 L32.6944962,3.51430359 L32.6944962,2.89696251 C32.6944962,1.29933309 31.3852138,7.10542736e-14 29.7760263,7.10542736e-14 L7.95808623,7.10542736e-14 C6.34859586,7.10542736e-14 5.03961639,1.29933309 5.03961639,2.89696251 L5.03961639,6.36646151 C1.92363333,9.53495787 0,13.863863 0,18.6305902 C0,28.3186053 7.94021311,36.2000032 17.700153,36.2000032 C27.4597901,36.2000032 35.4000032,28.3186053 35.4000032,18.6305902 C35.4000032,13.8825065 33.4918195,9.56923789 30.3979506,6.40434995 L30.3979506,6.40434995 Z'
        id='Fill-8'
      ></path>
    </g>
  </SvgIcon>
);

const styles = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    minHeight: '65px',
  },
  formControl: {
    margin: theme.spacing.unit,
    width: '100%',
    minWidth: 120,
  },
  button: {
    color: theme.palette.text.secondary,
    height: '100%',
    paddingRight: '0px',
    paddingLeft: '12px',
    paddingTop: '0px',
    paddingBottom: '0px',

    '&:hover': {
      backgroundColor: '#ededed',
    },
  },
  clippingText: {
    fontSize: '24px',
    fontWeight: 600,
    color: '#545454',
    lineHeight: '26px',
    marginLeft: '8px',
    marginRight: '4px',
    textTransform: 'lowercase',
    letterSpacing: '-1px',
  },
  concursoText: {
    fontSize: '18px',
    marginTop: '4px',
  },
  arrowDownIcon: {
    fontSize: '10px',
  },
  beta: {
    width: '35px',
    height: '35px',
  },
  betaContainer: {
    marginTop: '-40px',
    marginLeft: '-60px',
  },
  buttonSubmit: {
    fontSize: '14px',
  },
  menu: {
    marginTop: '50px',
  },
  paper: {
    boxShadow: '0 22px 22px 0 rgba(0,0,0,0.1)',

    '& ul': {
      padding: '0px',
    },
  },
  menuItem: {
    width: '211px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '16px 23px 16px 33px',
  },
  icon: {
    fontSize: '18px',
    color: 'rgba(0, 0, 0, 0.3)',
  },
});

class SelectConcurso extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClickOpen = (event) => {
    this.setState({ ...this.state, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ ...this.state, anchorEl: null });
  };

  handleSelectConcurso = (concurso) => {
    this.handleRequestClose();
    ///////TODO: NÃO REPETIR ISSO ALI EMBAIXO. BOTAR NO ESTADO QUANDO DER COMPONENTWILLMOUNT.... mas tô sem tempo.
    const isAdminUser = this.props.userRole == "5a29412efcf99317fb323d6e" ? true : false;
    const isGuestUser = this.props.userRole == "5aafc618b4c5f16590774ed5" ? true : false;

    const scopes = localStorage['scope'] ? localStorage['scope'].split(',') : [];

    if (
        concurso.unlocked || 
        this.props.openConcursos || 
        isAdminUser || 
        (isGuestUser && scopes.includes(`CONCURSO-${concurso.concursoId}`)) ||
        this.getActiveSubscriptions()[concurso._id]) {
      localStorage.setItem('concurso', concurso._id);
      this.props.setConcurso(concurso._id);
      
      if(window.Intercom) {
        window.Intercom('update', { activeConcurso: concurso.name });
      }

      this.props.pushRouter(`/mural`);
    } else {
      this.props.track('Selecionou concurso', { _id: concurso._id });
      this.props.pushRouter(`/concurso/${concurso._id}`);
      this.setState({ ...this.state, anchorEl: null });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (Boolean(nextState.anchorEl)) {
      document.body.classList.add('overflow-initial');
      this.props.track('Abriu menu de concursos', {
        url: document.location.href,
      });
    } else {
      setTimeout(() => {
        document.body.classList.remove('overflow-initial');
      }, 600);
    }

    return true;
  }

  getOptions(concursos, active) {
    return _.values(concursos).reduce(
      (results, { name, _id, concursoId, unlocked }) => {
        if (_id !== active._id) {
          results.push({ name, _id, concursoId, unlocked });
        }
        return results;
      },
      []
    );
  }

  getActiveSubscriptions() {
    const activeConcursos = {}; // { concursoId: subscription }
    if (this.props.activeSubscriptions) {
      this.props.activeSubscriptions.map((subs) => { activeConcursos[subs.plan.concurso] = subs; });
    }
    return activeConcursos;
     
  }

  render() {
    const { classes, concursos, className, buttonClassName } = this.props;
    const { active } = concursos;

    const isAdminUser = this.props.userRole == "5a29412efcf99317fb323d6e" ? true : false;
    const isGuestUser = this.props.userRole == "5aafc618b4c5f16590774ed5" ? true : false;

    const scopes = localStorage['scope'] ? localStorage['scope'].split(',') : [];
    const activeConcursos = this.getActiveSubscriptions();
    

    let options = this.getOptions(concursos.data, active);

    return (
      <div className={classNames(classes.container, className)}>
        <Button
          color='primary'
          className={classNames(classes.button, buttonClassName)}
          onClick={this.handleClickOpen}
        >
          <LogoIcon />
          <div className={classes.clippingText}>clipping</div>
          <div className={classes.concursoText}>{active.name}</div>
          <div className={classes.arrowDownIcon}>
            <ArrowDown />
          </div>
        </Button>
        <Menu
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleRequestClose}
          className={classes.menu}
          classes={{
            paper: classes.paper,
          }}
        >
          {options.map((concurso, index) => {
            return Boolean(concurso) && !concurso.unlocked ? (
              <Fragment key={index}>
                <MenuItem
                  onClick={() => this.handleSelectConcurso(concurso)}
                  className={classes.menuItem}
                >
                  {concurso.name}
                  {!concurso.unlocked && !this.props.openConcursos && !activeConcursos[concurso._id] && !isAdminUser && !(isGuestUser && scopes.includes(`CONCURSO-${concurso.concursoId}`)) && (
                    <LockIcon className={classes.icon} />
                  )}
                </MenuItem>
                <Divider />
              </Fragment>
            ) : null;
          })}
        </Menu>
      </div>
    );
  }
}

SelectConcurso.propTypes = {
  pushRouter: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    concursos: state.concurso,
    openConcursos: state.globalSetting.openConcursos,
    userRole: state.user.profile.role,
    userScopes: state.user.profile.scope
  };
};

const mapDispatchToProps = {
  pushRouter: (path) => push(path),
  track: (name, data) =>
    crudCreate('event', { name, data }, '', false, '', false),
  setConcurso: (concurso) => setConcursoAction(concurso),
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SelectConcurso);
