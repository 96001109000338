import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from 'material-ui/styles';
import Dialog from 'material-ui/Dialog';
import Close from 'material-ui-icons/Close'
import IconButton from 'material-ui/IconButton';
import { Button } from '../../components/common';
import moment from 'moment';
import Radio, { RadioGroup } from 'material-ui/Radio';
import { FormControlLabel } from 'material-ui/Form';
import TextField from 'material-ui/TextField';
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';

const styles = theme => ({
  container: {
    borderRadius: '4px',
    [theme.breakpoints.up('md')]: {
      minWidth: '700px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '16px'
    }
  },
  title: {
    ...theme.typography.subtitle,
    marginBottom: '32px'
  },
  formTitle: {
    ...theme.typography.subtitle,
    marginBottom: '0px',
    textAlign: 'center'
  },
  close: {
      color: theme.palette.text.clear,
      cursor: 'pointer',
      float: 'right',
      '&:hover': {
          color: theme.palette.orange,
      },
  },
  cancelImage: {
    width: '101px',
    height: '101px',
    borderRadius: '50%',
    backgroundColor: '#D8D8D8;',
    marginBottom: '16px'
  },
  closeContainer: {
    width: '100%'
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    padding: '0 32px'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '40px',
    marginBottom: '40px'
  },
  desc: {
    maxWidth: '480px',
    padding: '0px 16px',
    textAlign: 'center',
    ...theme.typography.observation
  },
  formSubtitle: {
    maxWidth: '480px',
    padding: '0px 16px',
    textAlign: 'center',
    marginBottom: '32px',
    ...theme.typography.observation
  },
  button: {
    margin: '0px 8px'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '424px',
    margin: 'auto'
  },
  radios: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
})

const options = [
  'Passei no concurso',
  'Desisti do concurso',
  'Situação Financeira',
  'O Clipping não atendeu às minhas expectativas',
  'Não quero que minha assinatura se renove automaticamente',
  'Não consegui conciliar com a faculdade',
  'Outro motivo'
];

class ModalCancel extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      step: 1,
      selected: '',
      disable: true,
      suggestion: ''
    };
  }
  

  handleClose = () => {
    this.props.handleOpen(false)

    setTimeout(() => {
      this.setState({step: 1, disable: true, selected: '', suggestion: ''});
    }, 200);
  };
  
  handleNext = () => {
    this.setState({step: 2});
  };

  handleReasonChange = (event) => {
    this.setState({disable: false});
    this.setState({selected: event.target.value});
  }
  
  handlegChange = (event) => {
    this.setState({suggestion: event.target.value});
  }
  
  handleCancelSubscription = () => {
    const { trackMetric, cancelSubscription } = this.props;
    const { selected, suggestion } = this.state;
    window.gtag('event', 'cancel-subscription', {
      event_callback: () => {
        trackMetric('Cancelou', {});
        cancelSubscription({reason: selected, suggestion});
        this.handleClose();
      }
    });    
  }

  getContent() {
    const { classes, subscription={} } = this.props;
    const { step, selected, suggestion, disable } = this.state;

    if (step === 1) {
      return (
        <div className={classes.content}>
          <div className={classes.mainContainer}>
            <div className={'image-cancel-subscription'}></div>
            <div className={classes.title}>Ah não! Você já vai partir?</div>
            <div className={classes.desc}>Se você cancelar seu {subscription.name} hoje, terá acesso até dia&nbsp; 
              <b>{moment(subscription.current_period_end).format("DD/MM/YYYY")}</b>.
            Tem certeza que deseja continuar?</div>
          </div>
          <div className={classes.buttonsContainer}>
            <Button type="cancel" buttonFull={true} onClick={this.handleClose}>DESISTIR</Button>
            <Button type="submit" onClick={this.handleNext}>AVANÇAR</Button>
          </div>
        </div>
      )
    } else if (step === 2) {
      return (
        <div className={classes.content}>
          <div className={classes.mainContainer}>
            <div className={classes.formTitle}>Por favor, informe o motivo do cancelamento</div>
            <div className={classes.formSubtitle}>Nosso objetivo é proporcionar sempre o melhor serviço.</div>
          </div>
          <div className={classes.form}>
            <RadioGroup 
              className={classes.radios}
              name="reason"
              value={selected}
              onChange={this.handleReasonChange}
            >

              {options.map((option, index) => (
                <FormControlLabel
                key={index}
                value={option}
                label={option}
                control={<Radio/>}
                />
              ))}
            </RadioGroup>
              <TextField
                multiline
                margin="normal"
                rowsMax="4"
                fullWidth
                label="Como podemos melhorar?"
                value={suggestion}
                onChange={this.handleSuggestionChange}
              />
          </div>
          <div className={classes.buttonsContainer}>
            <Button disabled={disable} buttonFull={true} size={{width: 220}} type="submit" onClick={this.handleCancelSubscription}>CONTINUAR CANCELAMENTO</Button>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    const { classes, open } = this.props;

    return (
        <Dialog
            open={Boolean(open)}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
            classes={{paper: classes.container}}
        >
          <div className={classes.closeContainer}>
            <IconButton
            className={classes.close}
            aria-label="Fechar"
            onClick={this.handleClose} >
                <Close/> 
            </IconButton>
          </div>
          { this.getContent() }
        </Dialog>
    );
  }
}

const mapDispatchToProps = {
  trackMetric: (name, data) => trackMetricAction(name, data)
}

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps),
)(ModalCancel);
