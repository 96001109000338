import React, { Component } from 'react'
import { connect } from 'react-redux';
import { userLogin as userLoginAction } from '../../actions/authActions';
import { withStyles } from 'material-ui/styles';
import { CircularProgress } from 'material-ui/Progress';
import compose from 'recompose/compose';

const styles = theme => ({
  container: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
})

class Login extends Component {

  componentDidMount() {
    const {location : {query = {}}} = this.props
    this.props.userLogin(query, query.redirectTo)
  }

  render() {
    const {classes} = this.props
    return (
      <div className={classes.container}>
        <CircularProgress style={{color: '#F58220'}}/>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return  {
    submitting: state.waiting
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { userLogin: userLoginAction }),
)(Login);
