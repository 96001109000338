import React, {Component} from 'react' 
// import ProgressBar from 'progressbar.js' // graficos

export default class StartusCard extends Component {
    // getColor = (value) => {
    //     switch (true) {
    //         case value < 0.25:
    //             return '#fe3f4b'     
    //         case value < 0.50:
    //             return '#fdca00'
    //         case value < 0.75:
    //             return '#564eab'
    //         default:
    //             return '#4cd964'
    //     }
    // }
    // componentDidMount() {
    //     const {progress} = this.props
    //     const bar = new ProgressBar.Circle('#progress-chart', {
    //         color: this.getColor(progress),
    //         // This has to be the same size as the maximum width to
    //         // prevent clipping
    //         strokeWidth: 4,
    //         trailWidth: 4,
    //         easing: 'easeInOut',
    //         duration: 1400,
    //         text: {
    //         autoStyleContainer: false
    //         },

    //         // Set default step function for all animate calls
    //         step: function(state, circle) {
    //         var value = Math.round(circle.value() * 100);
    //         if (value === 0) {
    //         circle.setText('');
    //         } else {
    //         circle.setText(value + '<span>%</span>');
    //         }

    //         }
    //     });

    //     bar.text.style.fontFamily = '"Poxima Nova", Helvetica, sans-serif';
    //     bar.text.style.fontSize = '30px';
    //     bar.text.style.fontWeight = '300';
    //     bar.text.style.color =  this.getColor(progress);

    //     bar.animate(this.props.progress);
    // }
    render() {
        const {title, message} = this.props
        return (
            <div className="content--sidebar-section">
                <div className="cacd-testing">
                    <h3>Período de teste</h3>
                    <p>Acesso à todos os recursos Clipping CACD por 1 dia.</p>

                    <a href="#" className="btn btn-action">Assinar agora</a>
                </div>

                <div className="cacd-progress">
                    <div className="progress-chart" id="progress-chart" data-value="0.31"></div>
                    <div>
                        <h3>{title}</h3>
                        <a href="#">{message}</a>
                    </div>
                </div>
            </div>
        )
    } 
}