import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { Dialog } from "material-ui/";
import {
  makeStyles,
  Stepper,
  Step,
  StepLabel,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import PersonalDataForm from "./PersonalData";
import AddressForm from "./Address";

import SponsorPersonalData from "./SponsorPersonalData";
import SponsorAddress from "./SponsorAddress";

import DefaultLoading from "../../components/Loading";

import { openConfirmExit } from "./actions";

const styles = makeStyles({
  paper: {
    minWidth: "100vw",
    width: "100vw",
    height: "100vh",
    margin: 0,
    maxHeight: "100vh",
  },
  root: {
    minWidth: "100vw",
    width: "100vw",
    height: "100vh",
    margin: 0,
    maxHeight: "100vh",
  },
  stepperRoot: {
    padding: "16px",
    width: "100%",
  },
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Proxima Nova" !important;
  align-items: center;
`;

const Header = styled.header`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
  width: 100vw;
  position: fixed;
  background: white;
  z-index: 2;
  left: 0;
`;

const HeaderTitle = styled.span`
  color: rgba(0, 0, 0, 0.7);
  font-size: 20px;
  font-weight: bold;
  user-select: none;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 18px;
  }
  @media (max-width: 500px) {
    font-size: 15px;
  }
  @media (max-width: 400px) {
    font-size: 13px;
  }
`;

const CustomCloseButton = styled(IconButton)`
  position: absolute !important;
  right: 12px !important;
  padding: 2px !important;
  @media (max-width: 500px) {
    right: 6px !important;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
  max-width: 650px;
  width: 650px;
  @media (max-width: 700px) {
    max-width: 100vw;
    width: 100vw;
  }
`;

function getStepContent(step, sponsorModal) {
  switch (step) {
    case 0:
      return sponsorModal ? <SponsorPersonalData /> : <PersonalDataForm />;
    case 1:
      return sponsorModal ? <SponsorAddress /> : <AddressForm />;
    default:
      return <div />;
  }
}

function RegisterForm() {
  const dispatch = useDispatch();
  const classes = styles();

  const steps = useSelector((state) => state.checkout.steps);
  const activeStep = useSelector((state) => state.checkout.step);

  const open = useSelector((state) => state.checkout.openRegister);
  const loading = useSelector((state) => state.checkout.stepLoading);

  const sponsorModal = useSelector((state) => state.paymentGroup.sponsorModal);

  function handleClose() {
    dispatch(openConfirmExit(true));
  }

  return (
    <Dialog open={open} fullScreen>
      <Container>
        <Header>
          <HeaderTitle>
            {sponsorModal
              ? "Preencha os dados do Responsável Financeiro"
              : "Preencha os campos para continuar"}
          </HeaderTitle>
          <CustomCloseButton name="Fechar" onClick={handleClose}>
            <Close />
          </CustomCloseButton>
        </Header>
        <Content>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            classes={{ root: classes.stepperRoot }}
          >
            {steps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {loading ? (
            <DefaultLoading sidebar={true} />
          ) : (
            getStepContent(activeStep, sponsorModal)
          )}
        </Content>
      </Container>
    </Dialog>
  );
}

export default RegisterForm;