import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createCommentClipping,
  getCommentsByTopic,
  getUsers,
} from '../../../services/comments';
import Comment from './comment';
import {
  paginationAddComments,
  setQuestionComments,
} from '../../../actions/questionsCommentsActions';
import { CircularProgress } from '@material-ui/core';

import * as S from './styles';
import WriteComment from './WriteComment';

export default function Comments({ objectResource, resource }) {
  const dispatch = useDispatch();

  const [limit, setLimit] = useState(8);
  const [skip, setSkip] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const commentsQuestion = useSelector(
    (state) => state.commentsQuestion[`${resource}_${objectResource._id}`]
  );

  async function paginationComments(skip, limit) {
    setLoadingMore(true);

    try {
      const { data } = await getCommentsByTopic(
        `${resource}_${objectResource._id}`,
        skip,
        limit
      );

      const commentsByTopic = data[0].docs;

      const userIds = commentsByTopic.map((comment) => comment.user);
      const { data: users } = await getUsers(userIds);

      for (const comment of commentsByTopic) {
        const findUser = users.find((user) => user._id === comment.user);

        if (findUser) {
          comment.user = {
            _id: findUser._id,
            name: findUser.name,
            avatar: findUser.avatar || '',
          };
        }
      }

      setSkip(skip + limit);
      setLimit(limit + 8);
      setLoadingMore(false);

      dispatch(
        paginationAddComments(resource, commentsByTopic, objectResource._id)
      );
    } catch (error) {
      console.log(error);
    }
  }

  async function getComments() {
    try {
      setLoading(true);

      const { data } = await getCommentsByTopic(
        `${resource}_${objectResource._id}`,
        skip,
        limit
      );

      if (data && data.length) {
        const commentsByTopic = data[0].docs;

        if (data[0].total) {
          setTotal(data[0].total[0]);
        }

        const userIds = commentsByTopic.map((comment) => comment.user);
        const { data: users } = await getUsers(userIds);

        for (const comment of commentsByTopic) {
          const findUser = users.find((user) => user._id === comment.user);

          if (findUser) {
            comment.user = {
              _id: findUser._id,
              name: findUser.name,
              avatar: findUser.avatar || '',
            };
          }
        }

        dispatch(
          setQuestionComments(resource, commentsByTopic, objectResource._id)
        );
        setLoading(false);
      }
    } catch (error) {
      dispatch(setQuestionComments(resource, [], objectResource._id));
      setLoading(false);
      console.log(error);
    }
  }

  useEffect(() => {
    setLimit(8);
    setSkip(0);
    getComments();
  }, []);

  return (
    <S.Root>
      <WriteComment objectResource={objectResource} resource={resource} />
      {!loading ? (
        commentsQuestion &&
        commentsQuestion.comments &&
        commentsQuestion.comments.length > 0 ? (
          <S.CommentsList>
            {commentsQuestion.comments.length > 0 &&
              commentsQuestion.comments.slice(0, limit).map((comment) => {
                return (
                  <Comment
                    key={comment._id}
                    likes={comment?.likes}
                    answers={comment?.answers}
                    commentId={comment._id}
                    objectResource={objectResource}
                    userLike={comment.userLike}
                    user={comment.user}
                    createdAt={comment.createdAt}
                    resource={resource}
                  >
                    {comment.text}
                  </Comment>
                );
              })}
            {total > limit && (
              <S.LoadMoreButton
                primary={true}
                variant='outlined'
                onClick={() => {
                  paginationComments(skip + limit, limit + 8);
                }}
                loading={loadingMore}
                labelLoading={'Carregando comentários...'}
              >
                Carregar mais comentários
              </S.LoadMoreButton>
            )}
          </S.CommentsList>
        ) : (
          <S.EmptySeach>Seja o primeiro a comentar! </S.EmptySeach>
        )
      ) : (
        <S.LoadingContainer>
          <CircularProgress style={{ color: '#F58220' }} />
        </S.LoadingContainer>
      )}
    </S.Root>
  );
}
