import React from 'react';
import styled from 'styled-components';

import ErrorOutline from 'material-ui-icons/ErrorOutline'

const NoContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const NoContentInfo = styled.span`
    color: rgba(0, 0, 0, 0.5);
    font-size: 15px;
    font-family: 'Proxima Nova';
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 20px;
    margin-top: 12px;
`;

function ClassTabsNoContent() {
    return <NoContentContainer>
        <ErrorOutline style={{ color: 'rgba(0, 0, 0, 0.4)' }}/>
        <NoContentInfo>Nenhum conteúdo cadastrado no momento.</NoContentInfo>
    </NoContentContainer>
}

export default ClassTabsNoContent;