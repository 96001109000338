import React from 'react'
import {GenericSelect} from '../common/Filters'
import _ from 'lodash'
import {CACD} from '../../config'

export default ({concurso, setConcurso}) => {
  const {data, active = CACD} = concurso
  const options = data ? _.map(data, ({name, _id}) => {return {label: name, value: _id}}) : [CACD]

  return (
    <div className="course-selector">
        <span>Você está em:</span>
        <GenericSelect 
          iconStyle={{opacity: 0.3,	color: '#FFFFFF', top: '-2px', right: '20px'}} 
          options={options} 
          onChange={setConcurso} 
          value={active._id} width={120}/>
    </div>
  )
}