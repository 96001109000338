import React from 'react';
import Select from 'material-ui/Select';
import { withStyles } from 'material-ui/styles';
import { MenuItem } from 'material-ui/Menu';
import Input, { InputLabel } from 'material-ui/Input';


const styles = theme => ({
  container: {
    width: '100%'
  },
  input: {
    width: '90%'
  }
})

const MaterialSelect = ({label = '', onChange = ()=>{}, options = [], value = '', classes = {}}) => { 
  options = options.filter(x => localStorage.scope.includes(`CONCURSO-${x.concursoId}`));

  return (
    <div className={classes.container}>
      <InputLabel htmlFor="concurso-simple">{label}</InputLabel>
      <Select
        className={classes.input}
        value={value}
        onChange={onChange}
        input={<Input id="concurso-simple" />}
      >
      {
        options.map((option) => {
          return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
        })
      }
      </Select>
    </div>
  );
};

export default withStyles(styles)(MaterialSelect);