import React, { Component } from 'react'
import { withStyles } from 'material-ui/styles';
import Paper from 'material-ui/Paper';
import IconButton from 'material-ui/IconButton';
import Menu, { MenuItem } from 'material-ui/Menu';
import MoreVertIcon from 'material-ui-icons/MoreVert';
import classNames from 'classnames'

const styles = theme => ({
  container: {
    width: '100%',
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    color: theme.palette.text.clear,
    flexDirection: 'column',
  },
  card: {
    ...theme.transitions.card,
    justifyContent: 'space-between',
    '&:hover $titleText': {
      color: theme.palette.text.basic,
    },
    '&:hover $moreIcon': {
      color: theme.palette.black.a3,
    },
    minHeight: '70px',
    padding: '16px',
    cursor: 'pointer'
  },
  title: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.title,
    fontSize: '24px',
    fontWeight: '300',
  },
  icon: {
    color: theme.palette.orange,
    marginRight: '16px',
    fontSize: '18px'
  },
  fichamentos: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.black.a3
  },
  text: {
    fontSize: '12px',
    fontWeight: 300
  },
  number: {
    fontSize: '18px'
  },
  titleText: {

  },
  moreIcon: {
    color: 'transparent',
    height: '20px'
  },
  moreIconContainer: {
    marginTop: '-16px',
    marginRight: '-16px'
  }
})

const ITEM_HEIGHT = 48;

class Caderno extends Component {
  
  state = {
    anchorEl: null,
  };
  
  handleClick = event => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleDelete = (event) => {
    this.handleClose()
    this.props.deleteCaderno(this.props._id)
  }

  handleEdit = (event) => {
    this.handleClose()
    this.props.openEdit(this.props._id)
  }

  render() {
    const {_id, classes, title, openModal, contentCount} = this.props
    const { anchorEl } = this.state;

    return (
      <div className={classes.container}>
        <Paper className={classes.card}  onClick={() => openModal(_id)}>
            <div className={classes.title}>
                <i className={classNames(classes.icon, "icon-book-open icons")}></i>
                <div className={classes.titleText}>{title}</div>
                <div className={classes.deleteContainer}>
                
              </div>
            </div>
            <div className={classes.fichamentos}>
              <div className={classes.number}>
                {contentCount}
              </div>
              <div className={classes.text}>
                fichamentos
              </div>
            </div>
            <IconButton
              aria-label="More"
              aria-owns={anchorEl ? 'long-menu' : null}
              aria-haspopup="true"
              onClick={this.handleClick}
              className={classes.moreIconContainer}
            >
              <MoreVertIcon className={classes.moreIcon}/>
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={this.state.anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: 200,
                },
              }}
            >
              <MenuItem key={'Profile'} onClick={this.handleEdit}>Renomear</MenuItem>
              <MenuItem key={'My account'} onClick={this.handleDelete}>Excluir</MenuItem>
            </Menu>
        </Paper>
        
      </div>
    )
  }
}

export default withStyles(styles)(Caderno)
