import axios from 'axios';

export const API = axios.create({
    baseURL: process.env.REACT_APP_NEW_API_BASE_URL,
})

export const BrasilAPI = axios.create({
    baseURL: 'https://brasilapi.com.br/api/cep/v1'
})

export const PagarmeAPI = axios.create({
    baseURL: 'https://api.pagar.me/1/zipcodes/zipcode'
})