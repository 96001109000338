export const CRUD_CHANGE_LIST_PARAMS = 'CRUD_CHANGE_LIST_PARAMS';

export const changeListParams = (resource, params) => {
    delete params.custom
    return {
        type: CRUD_CHANGE_LIST_PARAMS,
        payload: params,
        meta: { resource },
    }
};
