import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

const PrettoSlider = withStyles(
  {
    root: {
      color: "#41aeef",
      height: 10,
    },
    thumb: {
      height: 18,
      width: 18,
      backgroundColor: "#329ad7",
      border: "2px solid #329ad7",
      "&:focus, &:hover, &:active": {
        boxShadow: "inherit",
      },
    },
    valueLabel: {
      left: "-60%",
    },
    track: {
      height: 10,
      borderRadius: 4,
    },
    rail: {
      color: "#ececec",
      height: 10,
      borderRadius: 4,
    },
  },
  { index: 1 }
)(Slider);

export default function PaymentSlider({ value, setValue, min, max }) {
  return (
    <PrettoSlider
      aria-label="pretto slider"
      valueLabelDisplay={"off"}
      value={value}
      min={min}
      max={max}
      onChange={(event, value) => setValue(value)}
    />
  );
}
