import {
  MORE_INFO_MODAL_HIDE,
  MORE_INFO_MODAL_SHOW,
  ABOUT_COURSE_MODAL_HIDE,
  ABOUT_COURSE_MODAL_SHOW,
} from "../actions/courseActions";

const initialState = {
  aboutCourse: {
    open: false,
    courseInfo: null,
  },
  moreInfo: {
    open: false,
    enrollmentInfo: null,
  },
  courseModal: {
    open: false,
  },
  classSelectorModal: {
    open: false,
  },
};

export default (previousState = initialState, { type, payload }) => {
  switch (type) {
    case MORE_INFO_MODAL_HIDE:
    case MORE_INFO_MODAL_SHOW:
      return { ...previousState, moreInfo: { ...payload } };
    case ABOUT_COURSE_MODAL_HIDE:
    case ABOUT_COURSE_MODAL_SHOW:
      return { ...previousState, aboutCourse: { ...payload } };
    default:
      return previousState;
  }
};
