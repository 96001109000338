import React, { useEffect, useState } from "react";
import { differenceInSeconds } from "date-fns";
import { withStyles } from "material-ui/styles";

const styles = (theme) => ({
  mainContainer: {
    marginLeft: "4px",
    width: "180px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    "@media (max-width: 900px)": {
      width: "125px",
    },
    "@media (max-width: 600px)": {
      width: "152px",
    },
  },
  text: {
    display: "inline-block",
    fontFamily: "Proxima Nova",
    width: "100%",
    height: "fit-content",
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: "bold",
    color: "#fff",
    textAlign: "left",
    margin: 0,
    "@media (max-width: 900px)": {
      fontSize: "14px",
      lineHeight: "14px",
    },
    
    "@media (max-width: 600px)": {
      fontSize: "17px",
      lineHeight: "17px",
    },
  },
});

function Counter({ classes }) {
  const calculateTimeLeft = () => {
    const currentDate = new Date();
    const eventDate = new Date(2020, 5, 24, 23, 59, 59, 0);
    const difference = differenceInSeconds(eventDate, currentDate);

    let timeLeft = {};
    if (difference > 0) {
      const days = Math.floor(difference / (60 * 60 * 24));
      const hours =
        days > 0
          ? Math.floor(difference / (60 * 60) - 24 * days)
          : Math.floor(difference / (60 * 60));
      const minutes = Math.floor((difference / 60) % 60);
      const seconds = Math.floor(difference % 60);
      timeLeft = {
        days,
        hours,
        minutes,
        seconds,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timeout);
  });

  return (
    <div className={classes.mainContainer}>
      <p className={classes.text}>
        {timeLeft.hours === undefined
          ? "00"
          : timeLeft.days < 10
          ? `0${timeLeft.days}`
          : ` ${timeLeft.days}`}
        d:
        {timeLeft.hours === undefined
          ? " 00"
          : timeLeft.hours < 10
          ? ` 0${timeLeft.hours}`
          : ` ${timeLeft.hours}`}
        h:
        {timeLeft.hours === undefined
          ? " 00"
          : timeLeft.minutes < 10
          ? ` 0${timeLeft.minutes}`
          : ` ${timeLeft.minutes}`}
        m:
        {timeLeft.hours === undefined
          ? " 00"
          : timeLeft.seconds < 10
          ? ` 0${timeLeft.seconds}`
          : ` ${timeLeft.seconds}`}
        s
      </p>
    </div>
  );
}

export default withStyles(styles, {index: 1})(Counter);
