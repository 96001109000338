import {
    CUSTOM
} from '../services/rest_types';

export const EXTRAS_CHECK_USER_STATUS = 'EXTRAS_CHECK_USER_STATUS';

export const extrasCheckUserStatus = (extraSection, extraContent, checked) => ({
    type: EXTRAS_CHECK_USER_STATUS,
    payload: { data: { extraSection, extraContent }},
    meta: {custom: true, customMethod: checked ? 'DELETE' : 'POST', resource: `extraContentUser${checked ? '/byextracontent' : ''}`, cancelPrevious: false, fetchCustom: CUSTOM}
});


export const CREATE_AUDIO = 'CREATE_AUDIO';
export const CREATE_AUDIO_LOADING = 'CREATE_AUDIO_LOADING';
export const CREATE_AUDIO_FAILURE = 'CREATE_AUDIO_FAILURE';
export const CREATE_AUDIO_SUCCESS = 'CREATE_AUDIO_SUCCESS';

export const createAudio = (id, data, redirect = true, modal = 'modal', message = true) => ({
    type: CREATE_AUDIO,
    payload: { id, data, basePath: '' , redirect },
    meta: {custom: true, customMethod: 'POST', resource: `questionEssayAnswer/sendAudio/${id}`, cancelPrevious: true, fetchCustom: CUSTOM}

});
