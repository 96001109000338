import React, {Component} from 'react'
import { Link } from 'react-router';
import _ from 'lodash' 
import NotificationItem from './NotificationItem'

export default class Notifications extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  handleClickOption = () => {
    this.setState({open: false})
  }

  handleBlur = (e) => {
    var currentTarget = e.currentTarget;

    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
          this.setState({open: false})
      }
    }, 0);
  }
  
  render() {  
    const { open } = this.state
    const { list, router } = this.props
    return <div className="notifications" tabIndex="0" onBlur={this.handleBlur}>
        <div className="notifications--icon" data-count="1" onClick={() => this.setState({open: !open})}></div>
         <ul className={`notifications--list ${open ? 'is-active' : ''}`}>
            {_.map(list, (props, index) => {
              return <NotificationItem {...props} key={index} />
            }).slice(0, 4)}
            <li className="notifications--list--view-all" onClick={() => router.push('/notificacoes')}>
              <Link style={{flex: 1}} to="/notificacoes" onClick={() => this.setState({open: false})}>ver todos</Link>
            </li>
        </ul> 
    </div>
  }
}

