import React, {Component} from 'react'
import _ from 'lodash'

export default class GenericSelect extends Component {
  constructor(props) {
    super(props)
    let {value, label} = props.options[0]
    
    if(props.value) {
      const option = _.find(props.options, {value: props.value}) || {}
      value = option.value
      label = option.label
    } 

    this.state = {
      open: false,
      label,
      value 
    }
  }
  
  componentWillReceiveProps(nextProps) {
    const option = _.find(this.props.options, {value: nextProps.value}) || {}
    if(option.value && (this.state.value !== option.value)) {
      this.setState({value: option.value, label: option.label})
    }
  }
  

  handleClickOption = (option) => {
    if (option.disable !== true) {
      this.setState({open: false, ...option})
      const {onChange, onChangeSort} = this.props
      onChange(option.value)
      setTimeout(function() {
        onChangeSort && onChangeSort(option.value)
      }, 0);
    }
  }    

  handleBlur = (e) => {
    var currentTarget = e.currentTarget;

    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
          this.setState({open: false})
      }
    }, 0);
  }
  
  render() {
    const { options, name, disable, width = 150, fixMargin, iconStyle = {}, valueAsClass} = this.props
    const { open, label, value } = this.state
    return (
      <div 
        tabIndex="0"
        className={`select selectric-wrapper selectric-select selectric-ordering ${open ? 'selectric-open' : ''} ${name}`}
        onBlur={this.handleBlur}>
        <div className="selectric" onClick={() => this.setState({open: !open})} style={width ? {width: `${width}px`, left: fixMargin ? `-${width-100}px` : '0px' } : {}}>
          <span className={`label ${valueAsClass ? value : ''}`}>{label}</span>
          <b className="button" style={iconStyle}><i className="fa fa-chevron-down" aria-hidden="true"></i></b>
        </div>
        <div className="selectric-items" tabIndex="0" style={width ? {width: `${width}px`, left: fixMargin ? `-${width-100}px` : '0px' } : {}}>
          <div className="selectric-scroll">
            <ul>
              {options.map((option, key) => <li key={key}
                  onClick={() => this.handleClickOption(option)}
                  data-index={key}
                  value={option.value}
                  disabled={disable}
                  className={`${valueAsClass ? `${option.value} ` : ''}${value === option.value ? 'selected' : option.disable === true ? 'disabled' : ''}`}>
                    {option.label}
                </li>
               )}
            </ul>
          </div>
        </div>
        <input className="selectric-input" tabIndex="0" />
      </div>
    )
  }
}