import styled from "styled-components";
import { Dialog as MuiDialog } from "material-ui";
import { Toolbar as MuiToolbar, DialogActions as MuiDialogActions, Button } from "@material-ui/core";

export const ResponsiveFilterButton = styled.div`
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0px 24px;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    background: transparent;
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.4);

    & svg {
    width: 20px;
    height: 20px;
    color: rgba(0, 0, 0, 0.4);
    margin-right: 2px;
    }

    &:hover {
    background: rgba(255, 255, 255, 0.3);
    }

    @media (min-width: 641px) {
    display: none;
    }
`;

export const Toolbar = styled(MuiToolbar)`
    display: flex;
    background: #FFFFFF;
    box-shadow: inset 0px -1px 0px #E5E5E5;
    font-family: Proxima Nova;
    height: 64px;
    position: fixed;
    width: 100%;
    z-index: 150;

    & > h2 {
        flex: 1;
        text-align: center;
        font-size: 15px;
    }

    & > button {
        position: absolute;
        right: 16px;
        color: #FC5836;
    }
`;

export const DialogActions = styled(MuiDialogActions)`
    position: fixed;
    z-index: 150;
    bottom: 0px;
    width: 100%;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px 6px 6px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
`;

export const ActionButton = styled(Button)`
    flex: 1;
    padding: 8px 16px;
    height: 40px;
    color: rgba(0, 0, 0, .4);

    & svg {
        margin-right: 8px;
    }
`;

export const Dialog = styled(MuiDialog).attrs(() => ({
    PaperProps: {
        style: {
            backgroundColor: "#F2EEED",
        }
    }
}))`
    z-index: 10000000000;
`;

export const Content = styled.div`
    padding: 96px 16px 104px 16px;

    & > *:not(:last-child) {
        margin-bottom: 32px;
    }
`;