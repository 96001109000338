import styled from "styled-components";
import MuiPaper from "material-ui/Paper";
import MuiIconButton from "material-ui/IconButton";
import { Tooltip as MuiTooltip } from "@material-ui/core";

export const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
`;

export const Paper = styled(MuiPaper)`
    display: flex;
    box-shadow: unset !important;
    flex-direction: column;
    justify-content: space-between;
    min-height: 70px;
    padding: 16px;
    width: 100%;
    cursor: pointer;
`;

export const CardHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const HederCardInfo = styled.div`
    flex: 1;
    margin-left: 8px;
    display: flex;
    flex-direction: column;
`;

export const AuthorName = styled.label`
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    color: #585858;
    font-family: "Inter";
`;

export const PostDate = styled.span`
    font-size: 11px;
    font-weight: 500;
    color: #B0B0B0;
    font-family: "Inter";
`;

export const VerifiedBadge = styled.span`
    border: 1px solid rgba(0,0,0,0.3);
    border-radius: 3px;
    font-family: "Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif";
    padding: 4px 7px 3px 7px;
    font-size: 13px;
`;

export const CardActionsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 160px;
`;

export const ActionButtonTooltip = styled(({ className, ...props }) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
)).attrs({
    enterDelay: 300
})`
    top: -15px !important;
`;

export const ActionButton = styled(MuiIconButton)`
    opacity: 0.5;
    transition: 300ms;

    svg {
        height: 20px;
        width: 20px;
    }

    &:hover {
        opacity: 1;
    }
`;

export const CardImage = styled.img`
    width: 100%;
    margin-top: 8px;
`;

export const CardTitle = styled.h3`
    color: red;
    font-size: 18px;
    font-weight: 600;
    color: rgba(0,0,0,0.7);
    padding-top: 24px;
    margin: 0px;
`;

export const Content = styled.div`
    font-size: 15px;
    line-height: 1.46429em;
    color: rgba(0,0,0,0.7);
    font-weight: normal;
    padding-bottom: 16px;
    border-bottom: 1px solid #96969626;
    max-height: 150px;
    overflow: hidden;
    max-width: 80vw;
    font-family: "Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif";
`;

export const ReadMore = styled.span`
    width: 100%;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.7);
    background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 1));
    display: flex;
    justify-content: center;
    height: 60px;
    padding-bottom: 4px;
    align-items: flex-end;
    margin-top: -30px;
`;

export const CardFooter = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 16px;
    max-height: 50px;
`;

export const FooterActionButtonsContainer = styled.div`
    flex: 1;
    display: flex;
`;