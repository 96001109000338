import React, { Component } from 'react'
import { connect } from 'react-redux';
import { push } from 'react-router-redux'
import _ from 'lodash'
import { withStyles } from 'material-ui/styles';
import compose from 'recompose/compose';
import Dialog from '../../components/ModalScreen';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import Button from 'material-ui/Button';
import Slide from 'material-ui/transitions/Slide';
import ArrowLeft from 'material-ui-icons/ChevronLeft'
import {crudGetOne as crudGetOneAction} from '../../actions/dataActions'
import EditalContent from './EditalContent'
import Drawer from '../../components/DrawerEditalTopics'
import {changeEditalTopics as changeEditalTopicsAction} from '../../actions/organizadorActions'

const resource = "editalTopic/bysubject"

const styles = theme => ({
  appBar: {
    top: 0,
    left: 'auto',
    right: 0,
    backgroundColor: '#FFF',
    transition: theme.transitions.create('width'),
    height: '65px',
    paddingRight: '24px',
    justifyContent: 'center',
    boxShadow: theme.shadows[27],
  },
  backContainer: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      padding: '18px'
    },
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  backIcon: {
    ...theme.typography.menu,
    color: theme.palette.text.clear,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '8px',
    paddingLeft: '0px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  responsiveDisplay: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inherit'
    }
  },
  toolbar: {
    padding: '0px'
  },
  icon: {
    color: theme.palette.text.clear,
  },
  titleContainer: {
    ...theme.typography.title,
    lineHeight: '0.8em',
    paddingTop: '4px',
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    
  },
  menuIcons: {
    display: 'flex',
    color: theme.palette.common.white,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  dialog: {
    display: 'flex',
    alignItems: 'stretch',
    minHeight: '100vh',
    width: '100%',
  },
  slide: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: '100%'
  },
  drawer: {
    
  },
  arrowLeft: {
    height: '40px',
    width: '40px',
    color: theme.palette.black.a3,
    [theme.breakpoints.up('sm')]: {
      height: '25px',
      width: '25px',
      color: theme.palette.text.clear
    },
  },
  titleScreen: {
    fontSize: '12px',
    lineHeight: '0.8em'
  },
  titleMateria: {
    overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    lineHeight: '1em'
  }
});

class ItemEditalEsquematizado extends Component {

  state = {
    mobileOpen: false,
  };

  closeDrawer = () => this.setState({ mobileOpen: false });

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  
  componentDidMount() {
    if(this.props.open) {
      this.props.crudGetOne(this.props.open)
    }
  }

  transition = (props) => {
    return <Slide direction="up" {...props} className={this.props.classes.slide}  />;
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.open && this.props.open !== nextProps.open){
      this.props.crudGetOne(nextProps.open)
      this.props.getPerformancePerEditalTopic(nextProps.open)
    }
  }

  getArrayOfArticles = (sections = []) => {
    let arrayArticles = []
    let count = 0
    sections.map(({name, articles = []}) => {
      return articles.map((article) => {
        count++
        return arrayArticles[count] = {...article, section: name, number: count}
      })
    })

    return {arrayArticles, total: count}
  }

  render () {
    const {open = '', handleOpen, classes, materias = {}, data, changeEditalTopics, questionPerformance} = this.props
    
    return (
      <Dialog open={Boolean(open)} handleOpen={handleOpen} >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.backContainer}>
              <Button
                disableRipple
                disableFocusRipple
                aria-label="voltar"
                onClick={() => handleOpen(false)}
                className={classes.backIcon}
              >
                <ArrowLeft className={classes.arrowLeft}/> <div className={classes.responsiveDisplay}>voltar</div>
              </Button>
            </div>
            <div className={classes.titleContainer}>
                <div className={classes.titleScreen}>EDITAL ESQUEMATIZADO</div>
                <div className={classes.titleMateria}>{materias[open] ? materias[open].name : ''}</div>
            </div>
            <div className={classes.menuIcons}>

            
            </div>
          </Toolbar>
        </AppBar>
          <Drawer
            pushRouter={handleOpen}
            location={location}
            className={classes.drawer}
            disablePermanent={false}
            onRequestClose={this.handleDrawerToggle}
            mobileOpen={this.state.mobileOpen}
            closeDrawer={this.closeDrawer}
            items={materias}
          />
          <EditalContent 
            data={data} questionPerformance={questionPerformance} changeEditalTopics={changeEditalTopics} />
      </Dialog>
    )
  }
}

const mapStateToProps = (state, {open} ) => {
  return {
    user: state.user.profile,
    data: state[resource].data[open],
    materias: state['editalSubject/user'].data,
    questionPerformance: state['editalTopic/userPerformance']?.data.undefined
  }
}

const mapDispatchToProps = {
  crudGetOne: (_id) => crudGetOneAction(resource, `${_id}/user`, '', false, true),
  pushRouter: (path) => push(path),
  changeEditalTopics: changeEditalTopicsAction,
  getPerformancePerEditalTopic: (_id) => crudGetOneAction('editalTopic/userPerformance', `${_id}`, '', true, true),
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ItemEditalEsquematizado);
