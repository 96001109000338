import React, { useState, useContext, useEffect } from 'react';
import { push } from 'react-router-redux';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ClassTabsContainer, ClassTabsContent, Container } from './styles';

import { useLocation } from 'react-router-dom';

import ModalSimulado from '../Questoes';
import ModalGabarito from './ModalGabarito';
import ModalRelatorio from './ModalRelatorio';
import Simulados from './Simulados';
import Header from './Header';

const PorMateriaTab = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const SIMULADOS = 'atualidades';

function QuestoesTabs(props) {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const path = window.location.pathname.split('/');
  const [activeTab, setActiveTab] = useState('');

  const concursoId = useSelector(
    (reduxState) => reduxState.concurso.active.concursoId
  );
  const state = useSelector((reduxState) => reduxState.tests);

  function closeModal(modalName) {
    if (modalName === 'simulado') {
      dispatch({ type: 'SET_SIMULADO', payload: { _id: null } });
    }
    if (modalName === 'gabarito') {
      dispatch({ type: 'SET_GABARITO', payload: { _id: null } });
    }
    if (modalName === 'relatorio') {
      dispatch({ type: 'SET_RELATORIO', payload: { _id: null } });
    }
    dispatch(push(`/simulados${search}`));
  }

  function openGabarito(id) {
    dispatch({ type: 'SET_GABARITO', payload: { _id: id } });
  }

  function openModal(modalName, id) {
    if (id) {
      dispatch(push(`/simulados/${id}/${modalName}`));
    }
  }

  useEffect(() => {
    const page = new URLSearchParams(search).get('page');
    setActiveTab(() => SIMULADOS);
    return dispatch({
      type: 'CHANGE_ATUALIDADES_PAGE',
      payload: Number(page) || 1,
    });
  }, [search, concursoId]);

  useEffect(() => {
    const pathId = path[2];
    const pathType = path[3];
    if (path.length > 3) {
      if (pathType === 'simulado') {
        dispatch({ type: 'SET_SIMULADO', payload: { _id: pathId } });
      }
      if (pathType === 'gabarito') {
        dispatch({ type: 'SET_GABARITO', payload: { _id: pathId } });
      }
      if (pathType === 'relatorio') {
        dispatch({ type: 'SET_RELATORIO', payload: { _id: pathId } });
      } else if (path.length > 3) {
        dispatch(push('/simulados/'));
      }
    } else if (path.length === 3) {
      dispatch(push(`/simulados/${pathId}/simulado`));
      dispatch({ type: 'SET_SIMULADO', payload: { _id: pathId } });
    }
  }, []);

  return (
    <Container>
      <ClassTabsContainer>
        <Header />
        <ClassTabsContent>
          {activeTab === SIMULADOS && <Simulados />}
        </ClassTabsContent>
      </ClassTabsContainer>

      <ModalSimulado
        open={state.simulado._id}
        closeSimuladoModal={closeModal}
        openModal={openModal}
        openGabarito={openGabarito}
      />
      <ModalGabarito
        open={state.gabarito._id}
        closeGabaritoModal={closeModal}
        openModal={openModal}
      />
      <ModalRelatorio
        open={state.relatorio._id}
        closeRelatorioModal={closeModal}
        openModal={openModal}
      />
    </Container>
  );
}

export default QuestoesTabs;
