import { LOCATION_CHANGE } from "react-router-redux";
import { REFRESH_STATE, SET_RESPONSIVE } from "../actions/plataformActions";

export default (
  previousState = { refreshState: 0, responsive: false },
  { type, payload }
) => {
  switch (type) {
    case SET_RESPONSIVE:
      return { ...previousState, responsive: payload };
    case REFRESH_STATE:
      return { ...previousState, refreshState: previousState.refreshState + 1 };
    case LOCATION_CHANGE:
      if (payload.action === "REPLACE") {
        return {
          ...previousState,
          refreshState: previousState.refreshState + 1,
        };
      } else {
        return previousState;
      }
    default:
      return previousState;
  }
};
