import { SET_NOTIFICATION } from '../actions/notificationActions';

export default (previousState = {}, { type, payload }) => {
    switch (type) {
    case SET_NOTIFICATION:
        return payload.notifications;
    default:    
        return previousState;
    }
};
