import {
    CUSTOM,
} from '../services/rest_types';

export const PLATAFORM_PARAMS = 'PLATAFORM_PARAMS';

export const changePlataformParams = (resource, params) => ({
    type: PLATAFORM_PARAMS,
    payload: params,
    meta: { resource, plataform: true, cancelPrevious: true },
});

export const DOWNLOAD_PDF = 'DOWNLOAD_PDF'

export const downloadPDF = (_id) => ({
    type: DOWNLOAD_PDF,
    payload: { _id },
    meta: { plataform: true}
})

export const TRACK_METRIC = 'TRACK_METRIC'

export const trackMetric = (name, data) => ({
    type: TRACK_METRIC,
    payload: { name, data },
    meta: { plataform: true }
})

export const REFRESH_STATE = 'REFRESH_STATE'

export const refreshState = () => ({
    type: REFRESH_STATE
})

export const SET_RESPONSIVE = 'SET_RESPONSIVE'

export const setResponsive = (payload) => ({
    type: SET_RESPONSIVE,
    payload
})

export const GET_GLOBAL_SETTING = 'GET_GLOBAL_SETTING';
export const GET_GLOBAL_SETTING_SUCCESS = 'GET_GLOBAL_SETTING_SUCCESS';

export const getGlobalSetting = () => ({
    type: GET_GLOBAL_SETTING,
    meta: { resource: `globalSetting`, fetch: CUSTOM, customMethod: 'GET' }
});