import React, { useEffect, useState } from "react";
import moment from "moment";

import { Avatar } from "@clippingcacd/core";
import * as S from "./styles";
import { CommentsButton, LikeButton } from "./ToggleButton";
import { ButtonDeleteAndReport } from "./ButtonReportAndDelete/ButtonDeleteAndReport";
import WriteCommentParent from "./WriteComment/writeCommentParent";
import { MdOutlineAutorenew } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  getCommentsAnswers,
  getUser,
  getUsers,
  likeComment,
  removeLikeComment,
} from "../../../services/comments";
import {
  paginationAddAnswers,
  setAnswers,
} from "../../../actions/questionsCommentsActions";
import { CircularProgress } from "@material-ui/core";

function CommentContent({
  user,
  createdAt,
  children,
  responsive,
  commentId,
  likes,
  commentParent,
  answer,
  answersCount,
  getCommentsParents,
  objectResource,
  userLike,
  resource,
}) {
  const [liked, setLiked] = useState(userLike);
  const [countLikes, setCountLikes] = useState(likes ? likes : 0);
  const [loading, setLoading] = useState(false);

  async function handleLike() {
    try {
      const { data } = await likeComment(commentId);
      if (data) {
        setCountLikes(countLikes + 1);
        setLiked(true);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        try {
          await removeLikeComment(commentId);
          if (countLikes > 0) {
            setCountLikes(countLikes - 1);
          }
          setLiked(false);
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  return (
    <React.Fragment>
      {loading ? (
        <S.LoadingContainer>
          <CircularProgress style={{ color: "#6c6b6a" }} />
        </S.LoadingContainer>
      ) : (
        <S.CommentParent answer={answer}>
          <S.CommentHeader>
            <>
              <S.AvatarContainer>
                <Avatar size="sm" src={user.avatar ? user.avatar : ""}>
                  {user.name}
                </Avatar>
              </S.AvatarContainer>
              <div>
                <label>{user.name}</label>
                <span>{moment(createdAt).format("D MMM. YYYY")}</span>
              </div>
            </>

            <ButtonDeleteAndReport
              commentId={commentId}
              objectResource={objectResource}
              answer={answer}
              commentParent={commentParent}
              userCommentId={user}
              resource={resource}
            />
          </S.CommentHeader>
          <S.ContentComment
            style={{ marginLeft: "16px" }}
            responsive={responsive}
            answer={answer}
          >
            <S.Message>{children}</S.Message>

            <S.ActionsContainer>
              <LikeButton
                count={countLikes}
                active={liked}
                onClick={handleLike}
              />
              {!answer && (
                <CommentsButton
                  count={answersCount || 0}
                  onClick={() => {
                    getCommentsParents();
                  }}
                />
              )}
            </S.ActionsContainer>
          </S.ContentComment>
        </S.CommentParent>
      )}
    </React.Fragment>
  );
}

export default function Comment({
  user,
  createdAt,
  children,
  commentId,
  objectResource,
  likes,
  answers,
  userLike,
  resource,
}) {
  const dispatch = useDispatch();
  const responsive = useSelector((state) => state.plataform.responsive);
  const profile = useSelector((state) => state.user.profile);

  const answerItems = useSelector((state) => {
    const currentQuestion =
      state.commentsQuestion[`${resource}_${objectResource._id}`];

    if (currentQuestion.comments && currentQuestion.comments.length) {
      const currentComment = currentQuestion.comments.find(
        (comment) => comment._id === commentId
      );

      return currentComment.answerItems || [];
    }

    return [];
  });

  const [showCommentsAnswers, setshowCommentsAnswers] = useState(false);
  const [limit, setLimit] = useState(10);
  const [addAnswerCount, setAddAnswerCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  async function paginationCommentsParents(skip, limit) {
    try {
      setLoadingMore(true);
      const { data } = await getCommentsAnswers(commentId, skip, limit);

      const answersComment = data[0].docs;

      const userIds = answersComment.map((comment) => comment.user);
      const { data: users } = await getUsers(userIds);

      for (const comment of answersComment) {
        const findUser = users.find((user) => user._id === comment.user);

        if (findUser) {
          comment.user = {
            _id: findUser._id,
            name: findUser.name,
            avatar: findUser.avatar || "",
          };
        }
      }

      setSkip(skip + limit);
      setLimit(limit + 10);
      setLoadingMore(false);
      dispatch(
        paginationAddAnswers(
          resource,
          answersComment,
          objectResource._id,
          commentId
        )
      );
    } catch (error) {
      console.log(error);
    }
  }

  async function getCommentsParents() {
    setshowCommentsAnswers(!showCommentsAnswers);
    setLimit(10);
    setSkip(0);

    if (showCommentsAnswers) return;

    try {
      setLoading(true);

      const { data } = await getCommentsAnswers(commentId, skip, limit);

      const commentAnswers = data[0].docs;

      if (data[0].total) {
        setTotal(data[0].total[0]);
      }

      const userIds = commentAnswers.map((comment) => comment.user);
      const { data: users } = await getUsers(userIds);

      for (const comment of commentAnswers) {
        const findUser = users.find((user) => user._id === comment.user);

        if (findUser) {
          comment.user = {
            _id: findUser._id,
            name: findUser.name,
            avatar: findUser.avatar || "",
          };
        }
      }

      dispatch(
        setAnswers(resource, commentAnswers, objectResource._id, commentId)
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setAddAnswerCount(0);
  }, [answers]);

  useEffect(() => {
    if (answers && answers > 0) {
      setshowCommentsAnswers(!showCommentsAnswers);
      getCommentsParents();
    }
  }, []);

  return (
    <S.Comment>
      <CommentContent 
        responsive={responsive}
        user={user}
        likes={likes}
        createdAt={createdAt}
        userLike={userLike}
        children={children}
        commentId={commentId}
        answersCount={answers}
        objectResource={objectResource}
        getCommentsParents={getCommentsParents}
        answer={false}
        resource={resource}
      />
      {showCommentsAnswers && (
        <S.AnswersContainer>
          <WriteCommentParent
            profile={profile}
            objectResource={objectResource}
            resource={resource}
            commentId={commentId}
            setAddAnswerCount={setAddAnswerCount}
            addAnswerCount={addAnswerCount}
          />
          {/* Loading state */}
          {loading && (
            <S.LoadingContainer>
              <CircularProgress style={{ color: "#F58220" }} />
            </S.LoadingContainer>
          )}
          {/* Empty state */}
          {!loading && answerItems.length === 0 && (
            <S.EmptySeach>Seja o primeiro a responder!</S.EmptySeach>
          )}
          {!loading && answerItems.length > 0 && (
            <>
              {answerItems.map((commentAnswer, index) => {
                console.log(commentAnswer);
                return (
                  <>
                    <CommentContent
                      key={`answer_${commentAnswer._id}`}
                      responsive={responsive}
                      user={commentAnswer.user}
                      likes={commentAnswer.likes}
                      createdAt={commentAnswer.createdAt}
                      children={commentAnswer.text}
                      answer={true}
                      userLike={commentAnswer.userLike}
                      commentId={commentAnswer._id}
                      commentParent={commentAnswer.parentComment}
                      objectResource={objectResource}
                      resource={resource}
                    />
                    {index !== answerItems.length - 1 && (
                      <S.LineDividerParent />
                    )}
                  </>
                );
              })}
              {total > limit && (
                <S.LoadMoreButton
                  variant="outlined"
                  responsive={responsive}
                  startIcon={!loadingMore && <MdOutlineAutorenew size={20} />}
                  onClick={() => {
                    paginationCommentsParents(skip + limit, limit + 10);
                  }}
                  loading={loadingMore}
                  labelLoading={"Carregando respostas..."}
                >
                  Carregar mais repostas
                </S.LoadMoreButton>
              )}
            </>
          )}
        </S.AnswersContainer>
      )}
    </S.Comment>
  );
}
