import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import {
  Check,
  CheckCircleOutline,
  CancelOutlined,
  ErrorOutlineOutlined,
} from '@material-ui/icons';

const pulse = keyframes`
  80% { opacity: 0.3; }
`;

const Root = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;

  ${({ annulled }) =>
    annulled &&
    css`
      color: #b0b0b0;
    `}

  ${({ correct, annulled }) =>
    correct &&
    !annulled &&
    css`
      color: #2db87e;
    `}

  ${({ correct, annulled }) =>
    !correct &&
    !annulled &&
    css`
      color: #f7626b;
    `}

    & > span {
    display: block;
    position: relative;
    z-index: 1;
    background: ${({ annulled }) => (annulled ? 'none' : 'currentColor')};
    padding: 2px;
    border-radius: 50%;

    ${({ highlightIcon, annulled }) =>
      highlightIcon &&
      !annulled &&
      css`
        margin: 8px;

        &:before {
          content: ' ';
          position: absolute;
          z-index: -1;
          inset: -8px;
          background: currentColor;
          border-radius: 50%;
          padding: 8px;
          opacity: 0.2;
          animation: ${pulse} 1.5s linear infinite;
        }
      `}

    & > svg {
      height: 20px;
      width: 20px;
      color: ${({ annulled }) => (annulled ? '#b0b0b0' : '#ffffff')};
    }
  }

  & > label {
    margin-left: ${({ annulled }) => (annulled ? '4px' : '8px')};
  }

  & label {
    font-weight: 500;
  }

  & > :not(:last-child) {
    margin-right: ${({ annulled }) => (annulled ? '0px' : '8px')};
  }
`;

const RootDatetime = styled(Root)`
  align-self: flex-end;
  border-width: 1px;
  border-style: solid;
  padding: 2px 6px;
  border-radius: 20px;

  ${({ annulled }) =>
    annulled &&
    css`
      border-color: #b0b0b0;
    `}

  ${({ correct, annulled }) =>
    correct &&
    !annulled &&
    css`
      border-color: #2db87e;
    `}

  ${({ correct, annulled }) =>
    !correct &&
    !annulled &&
    css`
      border-color: #f7626b;
    `}

    & > span {
    background: unset;
    padding: 0px;

    & > svg {
      height: 16px;
      width: 16px;
      color: currentColor;
    }
  }

  & label {
    font-weight: 500;
    line-height: 18px;
  }

  & > :not(:last-child) {
    margin-right: 4px;
  }
`;

const ContentWithDatetime = styled.div`
  display: flex;
  flex-direction: column;

  & > span {
    color: #b0b0b0;
    font-size: 11px;
  }

  & > :not(:last-child) {
    margin-bottom: 4px;
  }
`;

export function AnswerFlag({ style, correct, answer, annulled }) {
  const props = { style, correct, highlightIcon: true, annulled };

  return (
    <Root {...props}>
      <span>{annulled ? <ErrorOutlineOutlined /> : <Check />}</span>

      {annulled ? (
        <label>
          <strong>Item anulado</strong>
        </label>
      ) : correct ? (
        <label>
          Você acertou! <strong>Resposta: {answer}</strong>
        </label>
      ) : (
        <label>
          Você errou! <strong>Resposta: {answer}</strong>
        </label>
      )}
    </Root>
  );
}

export function AnswerFlagWithDatetime({
  style,
  correct,
  highlightIcon,
  annulled,
  dateTime,
}) {
  const props = { style, correct, highlightIcon, annulled };

  return (
    <RootDatetime {...props}>
      <span>
        {annulled ? (
          <ErrorOutlineOutlined />
        ) : correct ? (
          <CheckCircleOutline />
        ) : (
          <CancelOutlined />
        )}
      </span>

      <ContentWithDatetime>
        {annulled ? (
          <label>Item anulado</label>
        ) : correct ? (
          <label>Resolvi certo</label>
        ) : (
          <label>Resolvi errado</label>
        )}
      </ContentWithDatetime>
    </RootDatetime>
  );
}
