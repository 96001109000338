import styled from "styled-components";

export const Root = styled.div`
    width: 100%;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    text-decoration: none;
    flex-direction: column;

    &, * {
        font-family: "Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif";
    }
`;

export const WelcomeMessage = styled.h2`
    line-height: 36px;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
`;

export const Card = styled.div`
    background: #FFFFFF;
    color: rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 70px;
    padding: 8px 16px;
    cursor: pointer;
`;

export const ButtonLabel = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    letter-spacing: 0.252px;

    > span {
        margin-top: 2px;
    }

    svg {
        height: 16px;
        width: 16px;
        margin-left: 4px;
    }
`;