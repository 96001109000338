import React from 'react';
import {defaultAvatar} from '../../config'

const ProfilePicture = ({userSocialId = "", size = '36px'}) => {
  const socialPic = `http://graph.facebook.com/${userSocialId}/picture?type=square`
  return (
    <img src={userSocialId ? socialPic : defaultAvatar} role="presentation" style={{height: size, width: size}}/>
  );
};

export default ProfilePicture;