import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
// import ActionHide from 'material-ui/svg-icons/action/highlight-off';
import compose from 'recompose/compose';
import {renderInput} from '../../utils/form'
// import translate from '../../i18n/translate';

const styles = {
    card: { marginTop: '-14px', paddingTop: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexWrap: 'wrap' },
    body: { display: 'flex', alignItems: 'flex-end' },
    spacer: { width: 48 },
    icon: { color: '#00bcd4', paddingBottom: 0 },
    clearFix: { clear: 'right' },
};

const emptyRecord = {};

export class FilterForm extends Component {
    getShownFilters() {
        const { filters } = this.props;
        return filters
            // .filter(filterElement =>
            //     filterElement.props.alwaysOn ||
            //     displayedFilters[filterElement.props.source] ||
            //     typeof initialValues[filterElement.props.source] !== 'undefined',
            // );
    }

    handleHide = event => this.props.hideFilter(event.currentTarget.dataset.key);

    renderOptions = (name) => name === 'sort' ? {onChange: this.props.setSort} : {}

    render() {
        const { resource, setSort } = this.props;
        
        return (<div className="content--main-actions">
                {this.getShownFilters().map(filterElement => {
                    return <Field
                            key={filterElement.props.name}
                            allowEmpty
                            {...filterElement.props}
                            component={renderInput}
                            resource={resource}
                            record={emptyRecord}
                            onChangeSort={filterElement.props.name === 'sort' ? setSort : false}
                        />
                })}
            <div style={styles.clearFix} />
        </div>);
    }
}

FilterForm.propTypes = {
    resource: PropTypes.string.isRequired,
    filters: PropTypes.arrayOf(PropTypes.node).isRequired,
    displayedFilters: PropTypes.object.isRequired,
    hideFilter: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    // translate: PropTypes.func.isRequired,
};

const enhance = compose(
    // translate,
    reduxForm({
        form: 'filterForm',
        destroyOnUnmount: false,
        enableReinitialize: true,
        onChange: (values, dispatch, props) => props.setFilters(values)
    }),
);

export default enhance(FilterForm);
