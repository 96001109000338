import {
  NEW_PAY_COURSE_CLASS,
  NEW_PAY_COURSE_CLASS_FAILURE,
  NEW_PAY_COURSE_CLASS_SUCCESS,
  CREATE_DEBIT,
  CREATE_DEBIT_FAILURE,
  CREATE_DEBIT_SUCCESS,
  DEBITS_FETCH,
  DEBITS_FETCH_FAILURE,
  DEBITS_FETCH_SUCCESS,
  CURRENT_DEBIT_FETCH,
  CURRENT_DEBIT_FETCH_FAILURE,
  CURRENT_DEBIT_FETCH_SUCCESS,
  SET_CREDITPAY_MODAL_OPEN,
  SET_PAYMENT_MODAL_OPEN,
  SET_PAYMENT_STATUS_MODAL_OPEN,
  SET_CREDITPAY_MODAL_ITEMS,
  SET_CHOOSE_PAYMENT_OPEN,
  SET_CHOOSE_CARD_OPEN,
  SET_CHOOSE_INSTALLMENTS_OPEN,
  SET_BOLETO_MODAL_OPEN,
  SET_BOLETO_URL,
  BOLETO_MODAL_FETCH_SUCCESS,
  BOLETO_MODAL_FETCH_FAILURE,
  PAYMENT_FETCH,
  PAYMENT_FETCH_SUCCESS,
  PAYMENT_FETCH_FAILURE,
  USER_PAYMENTS_FETCH,
  USER_PAYMENTS_FETCH_SUCCESS,
  USER_PAYMENTS_FETCH_FAILURE,
  SET_LOADING_MODAL_OPEN,
  SET_CREDITPAY_MODAL_TITLE,
  SET_CREDITPAY_MODAL_INSTALLMENTS,
  FETCH_PENDING_PAYMENTS,
  INIT_PENDING_PAYMENTS,
  CLEAR_CURRENT_PAYMENT,
  SHOULD_UPDATE_MASK,
  USER_CARD_DELETE_REQUEST,
  USER_CARD_DELETE_FAILURE,
  USER_CARD_DELETE_SUCCESS,
  USER_CARD_CONFIRM_DELETE,
  BOLETO_FETCH,
  SUBMIT_SPONSOR_ADDRESS,
  SUBMIT_SPONSOR_PERSONALDATA,
  SHOW_SPONSOR_MODAL,
  OPEN_FINANCIAL_RESPONSIBLE,
  SET_SPONSOR_ID,
  CLEAR_SPONSOR,
  SET_LAST_PAYED,
} from "./constants";

const INITIAL_STATE = {
  pendingPayments: {
    pacotes: [],
    currentPayment: { status: "" },
  },
  lastPayed: [],
  payClass: {
    waiting: false,
    error: false,
    data: {
      id: null,
      status: null,
    },
  },
  currentDebit: {
    loading: false,
    error: false,
    id: "",
    value: "",
  },
  createdDebit: {
    waiting: false,
    error: false,
    response: {},
  },
  debits: {
    loading: false,
    error: false,
    data: {
      enabled: [],
      disabled: [],
    },
  },
  paymentModal: {
    open: false,
  },
  paymentStatusModal: {
    open: false,
  },
  sponsorModal: false,
  sponsorID: "",
  openFinancialResponsible: false,
  creditCardSponsor: {
    personalData: {
      name: "",
      email: "",
      cpf: "",
      rg: "",
      mobile: "",
    },
    address: {
      zipcode: "",
      street: "",
      street_number: "",
      complementary: "",
      neighborhood: "",
      city: "",
      state: "",
    },
  },
  creditPaymentModal: {
    open: false,
    installments: 1,
    title: "",
    items: [],
  },
  choosePayment: {
    open: false,
  },
  chooseCard: {
    open: false,
  },
  chooseInstallments: {
    open: false,
  },
  boletoModal: {
    boletoUrl: "",
    open: false,
    loading: false,
    error: false,
  },
  creditCardPayment: {
    error: false,
    loading: false,
  },
  loadingModal: {
    open: false,
  },
  userPayments: {
    error: false,
    loading: false,
    data: {
      matriculas: [],
      cursos: [],
      modulos: [],
      aulas: [],
    },
  },
  deleteCard: {
    loading: false,
    error: false,
    confirmDelete: false,
  },
};

export default (previousState = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_PENDING_PAYMENTS:
      const clonePacotes = previousState.pendingPayments.pacotes;
      let lastPayed = [];
      if (payload.pacotes.length) {
        for (const pacote of payload.pacotes) {
          let index = clonePacotes.findIndex(
            (pacoteID) => pacoteID === pacote.id
          );
          if (index !== -1) {
            if (payload.status === "paid" || payload.status === "refused") {
              lastPayed.push(pacote.id);
              clonePacotes.splice(index, 1);
            }
          } else {
            if (payload.status !== "paid" && payload.status !== "refused") {
              clonePacotes.push(pacote.id);
            } else {
              lastPayed.push(pacote.id);
            }
          }
        }
      }

      if (lastPayed.length > 0) {
        return {
          ...previousState,
          pendingPayments: { pacotes: clonePacotes, currentPayment: payload },
          lastPayed,
        };
      }
      return {
        ...previousState,
        pendingPayments: { pacotes: clonePacotes, currentPayment: payload },
      };

    case INIT_PENDING_PAYMENTS:
      return {
        ...previousState,
        pendingPayments: {
          pacotes: payload !== "blank" ? payload : [],
          currentPayment: INITIAL_STATE.pendingPayments.currentPayment,
        },
      };

    case CLEAR_CURRENT_PAYMENT:
      return {
        ...previousState,
        pendingPayments: {
          ...previousState.pendingPayments,
          currentPayment: INITIAL_STATE.pendingPayments.currentPayment,
        },
      };

    case SET_LAST_PAYED:
      return {
        ...previousState,
        lastPayed: payload,
      };

    case NEW_PAY_COURSE_CLASS:
      return {
        ...previousState,
        payClass: {
          ...previousState.payClass,
          waiting: true,
        },
      };

    case NEW_PAY_COURSE_CLASS_FAILURE:
      return {
        ...previousState,
        payClass: {
          ...previousState.payClass,
          waiting: false,
          error: true,
        },
      };

    case NEW_PAY_COURSE_CLASS_SUCCESS:
      return {
        ...previousState,
        payClass: {
          waiting: false,
          error: false,
          data: payload,
        },
      };

    case CREATE_DEBIT:
      return {
        ...previousState,
        createdDebit: {
          ...previousState.createdDebit,
          waiting: true,
        },
      };

    case CREATE_DEBIT_FAILURE:
      return {
        ...previousState,
        createdDebit: {
          ...previousState.createdDebit,
          waiting: false,
          error: true,
        },
      };
    case CREATE_DEBIT_SUCCESS:
      return {
        ...previousState,
        createdDebit: {
          waiting: false,
          error: false,
          response: payload,
        },
      };

    case DEBITS_FETCH:
      return {
        ...previousState,
        debits: {
          ...previousState.debits,
          loading: true,
        },
      };

    case DEBITS_FETCH_FAILURE:
      return {
        ...previousState,
        debits: {
          ...previousState.debits,
          loading: false,
          error: true,
        },
      };

    case DEBITS_FETCH_SUCCESS:
      return {
        ...previousState,
        debits: {
          loading: false,
          error: false,
          data: payload,
        },
      };

    case CURRENT_DEBIT_FETCH:
      return {
        ...previousState,
        currentDebit: {
          ...previousState.currentDebit,
          loading: true,
        },
      };

    case CURRENT_DEBIT_FETCH_FAILURE:
      return {
        ...previousState,
        currentDebit: {
          ...previousState.currentDebit,
          loading: false,
          error: true,
        },
      };
    case CURRENT_DEBIT_FETCH_SUCCESS:
      return {
        ...previousState,
        currentDebit: {
          loading: false,
          error: false,
          id: payload.id,
          value: payload.cost,
        },
      };

    case USER_PAYMENTS_FETCH:
      return {
        ...previousState,
        userPayments: {
          ...previousState.userPayments,
          loading: true,
        },
      };

    case USER_PAYMENTS_FETCH_FAILURE:
      return {
        ...previousState,
        userPayments: {
          ...previousState.userPayments,
          loading: false,
          error: true,
        },
      };

    case USER_PAYMENTS_FETCH_SUCCESS:
      return {
        ...previousState,
        userPayments: {
          loading: false,
          error: false,
          data: payload,
        },
      };

    case SET_PAYMENT_MODAL_OPEN:
      return {
        ...previousState,
        paymentModal: { open: payload },
      };

    case CLEAR_SPONSOR:
      return {
        ...previousState,
        creditCardSponsor: INITIAL_STATE.creditCardSponsor,
      };

    case SUBMIT_SPONSOR_ADDRESS:
      return {
        ...previousState,
        creditCardSponsor: {
          ...previousState.creditCardSponsor,
          address: payload,
        },
      };

    case SUBMIT_SPONSOR_PERSONALDATA:
      return {
        ...previousState,
        creditCardSponsor: {
          ...previousState.creditCardSponsor,
          personalData: payload,
        },
      };

    case SET_PAYMENT_STATUS_MODAL_OPEN:
      return {
        ...previousState,
        paymentStatusModal: { open: payload },
      };

    case SET_CREDITPAY_MODAL_OPEN:
      return {
        ...previousState,
        creditPaymentModal: {
          ...previousState.creditPaymentModal,
          open: payload,
        },
      };

    case SET_CREDITPAY_MODAL_ITEMS:
      return {
        ...previousState,
        creditPaymentModal: {
          ...previousState.creditPaymentModal,
          items: payload,
        },
      };

    case SET_CHOOSE_INSTALLMENTS_OPEN:
      return {
        ...previousState,
        chooseInstallments: {
          ...previousState.chooseInstallments,
          open: payload,
        },
      };

    case SHOW_SPONSOR_MODAL:
      return {
        ...previousState,
        sponsorModal: payload,
      };

    case SET_SPONSOR_ID:
      return {
        ...previousState,
        sponsorID: payload,
      };

    case OPEN_FINANCIAL_RESPONSIBLE:
      return {
        ...previousState,
        openFinancialResponsible: payload,
      };

    case SET_CHOOSE_CARD_OPEN:
      return {
        ...previousState,
        chooseCard: {
          ...previousState.chooseCard,
          open: payload,
        },
      };

    case SET_CHOOSE_PAYMENT_OPEN:
      return {
        ...previousState,
        choosePayment: {
          ...previousState.choosePayment,
          open: payload,
        },
      };

    case SET_BOLETO_MODAL_OPEN:
      return {
        ...previousState,
        boletoModal: {
          ...previousState.boletoModal,
          open: payload,
        },
      };

    case SET_BOLETO_URL:
      return {
        ...previousState,
        boletoModal: {
          ...previousState.boletoModal,
          boletoUrl: payload,
        },
      };

    case BOLETO_FETCH:
      return {
        ...previousState,
        boletoModal: {
          ...previousState.boletoModal,
          loading: true,
          error: false,
        },
      };

    case BOLETO_MODAL_FETCH_FAILURE:
      return {
        ...previousState,
        boletoModal: {
          ...previousState.boletoModal,
          loading: false,
          error: true,
        },
      };

    case BOLETO_MODAL_FETCH_SUCCESS:
      return {
        ...previousState,
        boletoModal: {
          ...previousState.boletoModal,
          loading: false,
          error: false,
        },
      };

    case PAYMENT_FETCH:
      return {
        ...previousState,
        creditCardPayment: {
          ...previousState.creditCardPayment,
          loading: true,
          error: false,
        },
      };

    case PAYMENT_FETCH_SUCCESS:
      return {
        ...previousState,
        creditCardPayment: {
          ...previousState.creditCardPayment,
          loading: false,
          error: false,
        },
      };

    case PAYMENT_FETCH_FAILURE:
      return {
        ...previousState,
        creditCardPayment: {
          ...previousState.creditCardPayment,
          loading: false,
          error: true,
        },
      };

    case SET_LOADING_MODAL_OPEN:
      return {
        ...previousState,
        loadingModal: {
          open: payload,
        },
      };

    case SET_CREDITPAY_MODAL_TITLE:
      return {
        ...previousState,
        creditPaymentModal: {
          ...previousState.creditPaymentModal,
          title: payload.title,
        },
      };

    case SET_CREDITPAY_MODAL_INSTALLMENTS:
      return {
        ...previousState,
        creditPaymentModal: {
          ...previousState.creditPaymentModal,
          installments: payload.installments,
        },
      };

    case SHOULD_UPDATE_MASK:
      return previousState;

    case USER_CARD_DELETE_REQUEST:
      return {
        ...previousState,
        deleteCard: {
          ...previousState.deleteCard,
          loading: true,
          error: false,
        },
      };

    case USER_CARD_DELETE_FAILURE:
      return {
        ...previousState,
        deleteCard: {
          ...previousState.deleteCard,
          loading: false,
          error: true,
        },
      };

    case USER_CARD_DELETE_SUCCESS:
      return {
        ...previousState,
        deleteCard: {
          ...previousState.deleteCard,
          loading: false,
          error: false,
        },
      };
    case USER_CARD_CONFIRM_DELETE:
      return {
        ...previousState,
        deleteCard: {
          ...previousState.deleteCard,
          confirmDelete: payload,
        },
      };

    default:
      return previousState;
  }
};
