import React, { Component } from 'react'
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {ElementList} from '../../components/common'
import { withStyles } from 'material-ui/styles';
import _ from 'lodash'
import Close from 'material-ui-icons/Close'
import Check from 'material-ui-icons/Check'
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        // position: 'fixed',
        // width: '25%',
        marginTop: '63px',
    }, 
    list: {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    title: {
        ...theme.typography.body3,
        fontSize: '15px',
        color: theme.palette.text.clear,
        textAlign: 'center',
        paddingTop: '18px'
    },
    question: {
        border: '1px solid rgba(0,0,0,0.1)',
        borderRadius: '17.5px',
        height: '39px',
        width: '133px',
        marginTop: '8px',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        cursor: 'pointer'
    },
    item: {
        ...theme.typography.small,
        fontSize: '11px',
        color: theme.palette.text.clear,
        fontWeight: 'bold',
        position: 'relative',
        right: '-10px'
    },
    verRelatorio: {
        textAlign: 'center',
        margin: '24px auto',
        ...theme.typography.body3,
        color: theme.palette.orange,
        borderBottom: '1px solid #9797974d',
        paddingBottom: '16px',
        maxWidth: '160px',
        cursor: 'pointer'
    },
    notaContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '150px',
        margin: '0 auto',
        borderBottom: '1px solid rgba(151, 151, 151, 0.3)',
        paddingBottom: '20px'
    },
    nota: {
        ...theme.typography.display1,
        fontWeight: 'bold'
    },
    notaText: {
        ...theme.typography.body1,
        color: theme.palette.text.basic,
        marginTop: '-8px'
    },
    check: {
        color: '#36C463',
        width: '20px',
        height: '20px'
    },
    close: {
        color: '#E05061',
        width: '20px',
        height: '20px'
    },
    icon: {
        position: 'relative',
        right: '-30px'
    },
    blank: {
        color: theme.palette.text.clear,
        width: '20px',
        height: '20px'
    }
})

class QuestionList extends Component {
    render() {
        const { questions, classes, testAnswer = {}, trackMetric, test } = this.props;
        const itemList = []

        var number = 1;
        _.map(questions, ({items,type}) => _.map(items, item => itemList.push({...item, number: number++, type})))

        return (
            <div className={classes.container}>
                <div className={classes.notaContainer}>
                    <div className={classes.nota}>{testAnswer.score}</div>
                    <div className={classes.notaText}>sua nota</div>
                </div>  
                <div className={classes.title}> Folha de respostas</div>
                <ElementList data={itemList} type={classes.list}
                    renderRow={({number, correct, userAnswer, _id, question, key, type}) => {
                        const scrollToQuestionItem = () => {
                            trackMetric('Simulados - Ativação', { target: 'navigation', trigger: 'click', type: this.props.type,
                                parentResource: question, resource: _id, test: test, source: 'gabarito' })
                            let elem = document.getElementById(_id);
                            elem.scrollIntoView({ block: 'start', behavior: 'smooth' });
                        }
                        return (
                            <div className={classes.question} onClick={scrollToQuestionItem} key={question + _id + key}>
                                <div className={classes.item}>ITEM {number}</div>
                                <div className={classes.icon}>
                                    {(!userAnswer || (userAnswer === 'B' && type === "right_wrong") || correct.toLowerCase() === 'x')
                                        ? <div className={classes.blank} ></div> 
                                        : (correct === userAnswer 
                                        ? <Check className={classes.check}/> 
                                        : <Close className={classes.close}/>)}
                                </div>
                            </div>
                        );
                    }}
                />     
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        trackMetric: (name, data) => dispatch(trackMetricAction(name, data))
    }
}

export default compose(
    withStyles(styles),
    connect(null, mapDispatchToProps),
)(QuestionList);
