import {
  put, takeLatest, select, fork, call,
} from 'redux-saga/effects';
// import firebase from 'firebase';
import moment from 'moment';
import { showAlert } from '../actions/alertActions';
import { WAITING_START, WAITING_DONE } from '../reducers/waitingReducer';
import {
  SUBSCRIBE_COMMENTS,
  UNSUBSCRIBE_COMMENTS,
  POST_COMMENT,
  EDIT_COMMENT,
  ERROR_COMMENT,
  LIKE_COMMENT,
  LOGIN_DISQUS,
  syncCommentList,
} from '../actions/commentsActions';
// import * as db from '../utils/firebase';

const commentsControl = (restClient) => {
  function* handleComments(action) {
    const { type, payload, meta } = action;
    switch (type) {
      case UNSUBSCRIBE_COMMENTS: {
        // try {
        //     yield call(authClient, AUTH_CHECK, payload);
        // } catch (e) {
        //     yield call(authClient, AUTH_LOGOUT);
        //     yield put(replace({
        //         pathname: (e && e.redirectTo) || '/login',
        //         state: { nextPathname: meta.pathName },
        //     }));
        // }
        break;
      }
      case POST_COMMENT: {
        try {
          yield put({ type: WAITING_START });
          const {
            comment, postId, modalData, parentId, sponsorId,
          } = payload;
          const profile = yield select((state) => state.user.profile);
          const {
            _id, firstName, lastName, facebookId,
          } = profile;
          const newComment = {
            ...comment,
            userId: _id,
            num_replies: 0,
            created_at: moment().valueOf(),
            parent_id: parentId,
            firstName,
            lastName,
            userSocialId: facebookId,
            sponsorId,
          };
          // yield firebase.database().ref(`/Comments/${modalData.type}/${modalData.id}/${postId}/`)
          //   .push(newComment);
        } catch (e) {
          const errorMessage = typeof e === 'string'
            ? e
            : (typeof e === 'undefined' || !e.message ? 'Login error' : e.message);
          yield put(showAlert(errorMessage, 'warning'));
        }
        yield put({ type: WAITING_DONE });
        break;
      }
      case LIKE_COMMENT:
        try {
          const {
            commentId, postId, modalData, liked,
          } = payload;
          const { _id } = yield select((state) => state.user.profile);

          if (liked) {
            // yield firebase.database().ref(`/Comments/${modalData.type}/${modalData.id}/${postId}/${commentId}/likes/${_id}`)
            //   .remove();
          } else {
            // yield firebase.database().ref(`/Comments/${modalData.type}/${modalData.id}/${postId}/${commentId}/likes`)
            //   .update({ [_id]: true });
          }
        } catch (e) {
        }
        break;
      case ERROR_COMMENT:
      case EDIT_COMMENT:
        // try {
        //     yield call(authClient, AUTH_ERROR, error);
        // } catch (e) {
        //     yield call(authClient, AUTH_LOGOUT);
        //     yield put(push('/login'));
        //     yield put(hideAlert());
        // }
        break;
      case LOGIN_DISQUS:
        try {
          const restType = meta.fetch;
          yield call(restClient, restType, meta.resource, { ...payload });
        } catch (err) {
        }
        break;
      default:
        break;
    }
  }

  function* syncComments() {
    const { _id, modalData } = yield select((state) => state.modal);
    const { id, type } = modalData;
    yield put(syncCommentList({}, _id, type, 'reset'));
    // yield fork(db.sync, `/Comments/${type}/${id}/${_id}/`, {
    //   child_added: (payload) => syncCommentList(payload, _id, type, 'added'),
    //   child_removed: (payload) => syncCommentList(payload, _id, type, 'removed'),
    //   child_changed: (payload) => syncCommentList(payload, _id, type, 'changed'),
    // });
  }

  return function* watchCommentsActions() {
    yield [
      takeLatest((action) => action.meta && action.meta.comment, handleComments),
      takeLatest(SUBSCRIBE_COMMENTS, syncComments),
    ];
  };
};

export default commentsControl;
