import React from 'react';
import Button from 'material-ui/Button';
import { withStyles } from 'material-ui/styles';
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'material-ui/Dialog';
import Close from 'material-ui-icons/Close'
import IconButton from 'material-ui/IconButton';
import { crudUpdate as crudUpdateAction } from '../../actions/dataActions';
import { reduxForm, Field, change } from 'redux-form';
import { renderTextField } from '../../utils/form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

const resource = 'timelinePost'

const styles = theme => ({
    container: {
      borderRadius: '4px',
      [theme.breakpoints.up('md')]: {
        minWidth: '700px'
        },
    },
    title: {
        color: theme.palette.orange,
        borderBottom: '1px solid #96969626',
        fontSize: '18px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: '600',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '80px',
        paddingLeft: '16px',
        paddingRight: '16px'
    },
    close: {
        color: theme.palette.text.clear,
        cursor: 'pointer',
        marginTop: '-15px',
        width: '20px',
        '&:hover': {
            color: theme.palette.orange,
        },
    },
    content: {
        padding: '18px',
    }
})

class ModalAddPost extends React.Component {

  handleClose = () => {
    this.props.handleOpen(false)
  };

  updatePost = (post) => {
    const {text, title} = post
    const {crudUpdate, _id} = this.props
    const data =  {
        text, 
        title,
    }
    crudUpdate(resource, _id, data, {}, '/mural', false)
    this.handleClose()
  }

  render() {
    const {classes, handleSubmit, open} = this.props

    return (
        <Dialog
            open={Boolean(open)}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
            classes={{paper: classes.container}}
        >
            <DialogTitle disableTypography className={classes.title}>
                Editar Post
                <div className={classes.close} >
                    
                </div>
                <IconButton
                    className={classes.close}
                    aria-label="Fechar"
                    onClick={this.handleClose} >
                        <Close/> 
                    </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(this.updatePost)}>
            <DialogContent className={classes.content}>
                <Field fullWidth margin="dense" label="Título" name="title" id="title" component={renderTextField} />
                <Field multiline fullWidth margin="dense" label="Meu post..." name="text" id="text" component={renderTextField} />   
            </DialogContent>
            <DialogActions>
                <Button type="submit" color="primary">
                    SALVAR
                </Button>
            </DialogActions>
            </form>
        </Dialog>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
    const {text, title, _id} = state[resource].data[ownProps.open] || {}
    return  {
        submitting: state.waiting,
        profile: state.user.profile,
        initialValues: {text, title},
        _id
    }
}
  
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        changeForm: (field, value) => {
            dispatch(change(resource, field, value))
        },
        crudUpdate : (...params) => {
            dispatch(crudUpdateAction(...params))
        }
    }
}

const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        enableReinitialize: true,
        form: resource + 'edit'
    }),
);

export default enhance(ModalAddPost)
