import React from "react";
import { withStyles } from "material-ui/styles";
import { connect } from "react-redux";
import compose from "recompose/compose";
import Paper from "material-ui/Paper";
import { Link } from "react-router";
import { titleFor } from "../../utils/strings";

const styles = (theme) => ({
  icontainer: {
    width: "90%",
    [theme.breakpoints.up("sm")]: {
      width: "80%",
    },
    marginBottom: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    color: theme.palette.text.secondary,
    flexDirection: "column",
    "&:hover $ititle": {
      color: theme.palette.text.primary,
    },
    cursor: "pointer",
  },
  iinput: {
    width: "90%",
  },
  icard: {
    display: "flex",
    flexDirection: "column",
    minHeight: "50px",
    paddingBottom: "0px",
    width: "100%",
    justifyContent: "space-between",
    "&:hover $iicon": {
      color: theme.palette.orange,
      opacity: 1,
      width: "31px",
      height: "31px",
      fontSize: "20px",
      marginRight: "-4px",
    },
  },
  ititle: {
    display: "flex",
    alignItems: "center",
    ...theme.typography.default,
  },
  itext: {
    paddingLeft: "8px",
  },
  iiconContainer: {
    display: "flex",
    alignItems: "center",
    ...theme.typography.subheading,
    color: "inherit",
    padding: "8px",
    height: "21px",
    width: "21px",
  },
  iicon: {
    width: "21px",
    height: "21px",
    color: "rgba(0, 0, 0, 0.3)",
  },
  ipercentBar: {
    backgroundColor: theme.palette.clearOrange,
    height: "2px",
  },
  ipercentBarContainer: {
    width: "100%",
    height: "2px",
    backgroundColor: theme.palette.clearGrey,
  },
  irow: {
    padding: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
  },
  ilistContainer: {
    height: "400px",
  },
  imateria: {
    ...theme.typography.fontTag,
    color: theme.palette.orange,
    lineHeight: "1.1em",
    minWidth: "125px",
    maxWidth: "125px",
    [theme.breakpoints.up("sm")]: {
      marginRight: "24px",
    },
  },
});

const ItemDisciplina = (props = {}) => {
  const {
    title,
    subject = {},
    week,
    concurso,
    _id,
    classes,
    exhibition,
    numOfStudiedMaterials,
    numOfRequiredMaterials,
  } = props;
  const razaoPercent = parseInt(
    (numOfStudiedMaterials / (numOfRequiredMaterials || 1)) * 100,
    10
  );
  const percent = `${razaoPercent > 100 ? 100 : razaoPercent}%`;

  const getListItemTitle = () => {
    if (exhibition === "byweek") {
      return subject?.name || "";
    }

    const title = titleFor("ciclo", concurso) || "";

    return `${title} ${week}`;
  };

  return (
    <Link
      to={`/plano-de-estudos/${_id}/${exhibition}`}
      className={classes.icontainer}
    >
      <Paper className={classes.icard} elevation={0}>
        <div className={classes.irow}>
          <div className={classes.ititle}>
            <div className={classes.imateria}>{getListItemTitle()}</div>
            <div className={classes.itext}>{title}</div>
          </div>
        </div>
        <div className={classes.ipercentBarContainer}>
          <div className={classes.ipercentBar} style={{ width: percent }} />
        </div>
      </Paper>
    </Link>
  );
};

const mapStateToProps = (state, ownProps) => ({
  concurso: state.concurso.active,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, null)
)(ItemDisciplina);
