import mixpanel from 'mixpanel-browser';

const CURSOS_JD = ['5bf2ddd35c4dfb936f64bd9d', '5bf8262bce2a8708fbae18a1', '5c23c5074b46b0e24f6dc8b6'];

const identifyUser = (user, course) => {
    if (CURSOS_JD.includes(course._id)) {
        mixpanel.identify(user._id);
        mixpanel.people.set_once({
            $id: user._id,
            $name: user.name
        });
    }
}

const initialize = () => {
    mixpanel.init('2f264cc958a9abc25e5c8ca5828845e5');
}

export default { identifyUser, initialize };

