export const GET_LIST = 'GET_LIST';
export const GET_ONE = 'GET_ONE';
export const GET_MANY = 'GET_MANY';
export const GET_MANY_REFERENCE = 'GET_MANY_REFERENCE';
export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const CUSTOM = 'CUSTOM'
export const GET_LIST_BY_ONE = 'GET_LIST_BY_ONE';

export const LIKE = 'LIKE'
export const CLEAR = 'CLEAR'