import React, { Component } from 'react';
import { withStyles } from 'material-ui/styles';
import compose from 'recompose/compose';
import { connect, useSelector } from 'react-redux';
import { ElementList } from '../../components/common';
import QuestionList from './QuestionList';
import Questao from '../Questoes/Questao';
import { loginDisqus as loginDisqusAction } from '../../actions/commentsActions';
import { Disqus } from '../../components';
import ItemGabarito from './ItemGabarito';
import FeedbackPopover from '../../components/FeedbackPopover';
import Comments from '../../components/NewComments/Comments';
import { CircularProgress } from 'material-ui';

const styles = (theme) => ({
  wrapper: {
    background: '#f9f9f9',
    overflow: 'auto',
    overflowX: 'hidden',
    height: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    padding: '8px',
  },
  questionList: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      width: '305px',
    },
  },
  content: {
    marginBottom: '100px',
    height: 'max-content',
    margin: '0 auto 100px auto',
    '@media (min-width: 1600px)': {
      position: 'absolute',
      left: 'calc(50% - 480px)',
    },
  },
  comments: {
    marginTop: '16px',
    marginBottom: '20px',
    maxWidth: '970px',
  },
  feedback: {
    marginTop: '25px',
    marginBottom: '30px',
  },
  notaContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '150px',
    margin: '0 auto',
    borderBottom: '1px solid rgba(151, 151, 151, 0.3)',
    paddingBottom: '20px',
  },
  nota: {
    ...theme.typography.display1,
    fontWeight: 'bold',
  },
  notaText: {
    ...theme.typography.body1,
    color: theme.palette.text.basic,
    marginTop: '-8px',
  },
});

const GabaritoContent = ({ classes }) => {
  // const idGabarito = useSelector((state) => state.tests.gabarito._id);
  const test = useSelector(
    (state) => state.test.data['61c0e1d40b74786fb2bf1d11']
  );
  const dataReport = useSelector((state) => state.dataReport);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.questionList}>
          {console.log(test)}
          {test ? (
            <div className={classes.notaContainer}>
              <div className={classes.nota}>{test?.testAnswer.score}</div>
              <div className={classes.notaText}>sua nota</div>
            </div>
          ) : (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress style={{ color: '#F58220' }} />
            </div>
          )}
        </div>
        <div className={classes.content}>
          {/* {test && (
            <div className={classes.comments}>
              <Comments
                objectResource={{ _id: '61c0e1d40b74786fb2bf1d11' }}
                resource={'tests'}
              />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

// class GabaritoContent extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       showComment: false,
//     };
//   }

//   render() {
//     const { classes, questions, _id, gabaritoId, ...other } = this.props;

//     let number = 0;

//     questions &&
//       questions.forEach((question) => {
//         question.items.forEach((item) => {
//           item.sequenceKey = ++number;
//         });
//       });

//     return (

//     );
//   }
// }

// const mapStateToProps = (state) => ({
//   dataReport: state.dataReport,
//   gabaritoId: state.tests.gabarito._id,
// });

// const mapDispatchToProps = {};

export default compose(withStyles(styles))(GabaritoContent);
