import { useState } from "react";
import { useDispatch } from "react-redux";

import { ClickAwayListener } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";

import { DropDownBtn, OptionsGroup } from "./styles";

export default function CustomBtnMenu({ _id }) {
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(false);

  function openModal(modalType) {
    if (modalType === "simulado") {
      dispatch({ type: "SET_SIMULADO", payload: { _id } });
    }
    if (modalType === "gabarito") {
      dispatch({ type: "SET_GABARITO", payload: { _id } });
    }
    if (modalType === "relatorio") {
      dispatch({ type: "SET_RELATORIO", payload: { _id } });
    }
  }

  return (
    <DropDownBtn alt onClick={() => setExpanded(!expanded)}>
      <span>
        Relatório
        <KeyboardArrowDown />
      </span>
      {expanded && (
        <ClickAwayListener onClickAway={() => setExpanded(false)}>
          <OptionsGroup>
            <li onClick={() => openModal("relatorio")}>Estatísticas</li>
            <li onClick={() => openModal("gabarito")}>Gabarito</li>
          </OptionsGroup>
        </ClickAwayListener>
      )}
    </DropDownBtn>
  );
}
