import React, {Component} from 'react'
import Dialog, { DialogActions, DialogContent, DialogTitle } from 'material-ui/Dialog';
import Button from 'material-ui/Button';
import { FormControl } from 'material-ui/Form';
import {MaterialSelect} from '../../components/common';
import _ from 'lodash'
import { withStyles } from 'material-ui/styles';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '65px'
  },
  formControl: {
    margin: theme.spacing.unit,
    width: '100%',
    minWidth: 120,
  },
  buttonSubmit :{
    fontSize: '14px'
  }
});

class SelectConcurso extends Component {
  state = {concurso: false}

  handleRequestConfirm = () => {
    const {updateMainConcurso, concursos, handleRequestClose} = this.props
    updateMainConcurso(this.state.concurso, concursos[this.state.concurso] && concursos[this.state.concurso].name)
    handleRequestClose()
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const { classes, concursos, open, handleRequestClose, active } = this.props;
    const options = _.map(concursos, ({name, _id, concursoId}) => {return {label: name, value: _id, concursoId}}) 

    return <Dialog
      open={Boolean(open)}
      onRequestClose={handleRequestClose}
    >
      <DialogTitle>Trocar seu concurso principal</DialogTitle>
      <DialogContent>
        <form className={classes.container}>
          <FormControl className={classes.formControl}>
            <MaterialSelect label='Concurso' value={this.state.concurso || active} onChange={this.handleChange('concurso')} options={options} />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button className={classes.buttonSubmit} onClick={handleRequestClose} >
          Cancelar
        </Button>
        <Button className={classes.buttonSubmit} onClick={this.handleRequestConfirm} >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  }
}

export default withStyles(styles)(SelectConcurso)