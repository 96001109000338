import {
  FETCH_END,
} from './fetchActions';
import {
  CUSTOM,
} from '../services/rest_types';

export const SUBSCRIBE_COMMENTS = 'SUBSCRIBE_COMMENT';
export const UNSUBSCRIBE_COMMENTS = 'UNSUBSCRIBE_COMMENT';
export const POST_COMMENT = 'POST_COMMENT';
export const EDIT_COMMENT = 'EDIT_COMMENT';
export const SYNC_COMMENT_REMOVED = 'SYNC_COMMENT_REMOVED';
export const ERROR_COMMENT = 'ERROR_COMMEN';
export const SYNC_COMMENTS = 'SYNC_COMMENTS';
export const CLEAR_COMMENT_LIST = 'CLEAR_COMMENT_LIST';
export const LIKE_COMMENT = 'LIKE_COMMENT';
export const LOGIN_DISQUS = 'LOGIN_DISQUS';

export const loginDisqus = (postId, modalData, liked, commentId) => ({
  type: LOGIN_DISQUS,
  payload: { id: '', noFilter: 'true', customMethod: 'GET' },
  meta: {
    comment: true, fetch: CUSTOM, cancelPrevious: true, resource: 'disqus',
  },
});

export const likeComment = (postId, modalData, liked, commentId) => ({
  type: LIKE_COMMENT,
  payload: {
    commentId, modalData, liked, postId,
  },
  meta: { comment: true },
});

export const sendComment = (postId, comment, modalData, parentId = false, ownerId) => ({
  type: POST_COMMENT,
  payload: {
    postId, comment, modalData, parentId, ownerId,
  },
  meta: { comment: true },
});

export const subscribeComments = (modalData, postId, commentId) => ({
  type: SUBSCRIBE_COMMENTS,
});


export const syncCommentList = (comment, id, resource, type) => {
  switch (type) {
    case 'added':
    case 'changed':
      const commentVal = comment.data.val();
      return {
        type: SYNC_COMMENTS,
        payload: { [comment.data.key]: { ...commentVal, id: comment.data.key, likes: commentVal.likes || {} } },
        meta: {
          resource, fetchResponse: SYNC_COMMENTS, fetchStatus: FETCH_END, userId: commentVal.userId, postId: id, type,
        },
      };
    case 'removed':
      return {
        type: SYNC_COMMENT_REMOVED,
        payload: comment.data.key,
      };
    case 'reset':
      return {
        type: SYNC_COMMENTS,
        meta: {
          resource, fetchResponse: SYNC_COMMENTS, fetchStatus: FETCH_END, postId: id, type,
        },
      };
    default:
      break;
  }
};

export const syncCommentQtd = (previousState, { postId, userId, type }) => {
  if (type === 'reset') {
    return { ...previousState, [postId]: { ...previousState[postId], comments: [] } };
  } if (type === 'added') {
    return { ...previousState, [postId]: { ...previousState[postId], comments: previousState[postId].comments.concat([userId]) } };
  }
  return previousState;
};

export const updateCommentCount = (identifier) => {
  // const params = identifier.split("-")
  // const itemRef  = firebase.database().ref(`/concursos/${params[0]}/Questoes/${parseInt(params[1].substr(1), 10)-1}/Itens/${parseInt(params[2].substr(1), 10)-1}/numComments`)
  // itemRef.transaction((numComments) => {
  //   return numComments + 1;
  // });
};
