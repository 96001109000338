export const CATEGORY_FETCH = "CATEGORY_FETCH";
export const CATEGORY_FETCH_SUCCESS = "CATEGORY_FETCH_SUCCESS";
export const CATEGORY_FETCH_FAILURE = "CATEGORY_FETCH_FAILURE";

export const COURSE_FETCH = "COURSE_FETCH";
export const COURSE_FETCH_SUCCESS = "COURSE_FETCH_SUCCESS";
export const COURSE_FETCH_FAILURE = "COURSE_FETCH_FAILURE";

export const SINGLE_COURSE_FETCH = "SINGLE_COURSE_FETCH";
export const SINGLE_COURSE_FETCH_SUCCESS = "SINGLE_COURSE_FETCH_SUCCESS";
export const SINGLE_COURSE_FETCH_FAILURE = "SINGLE_COURSE_FETCH_FAILURE";

export const MODULE_FETCH = "MODULE_FETCH";
export const MODULE_FETCH_SUCCESS = "MODULE_FETCH_SUCCESS";
export const MODULE_FETCH_FAILURE = "MODULE_FETCH_FAILURE";

export const UPDATE_MODULES_WATCHED = "UPDATE_MODULES_WATCHED";
export const REMOVE_MODULE_WATCHED = "REMOVE_MODULE_WATCHED";

export const CURRENT_MODULE_FETCH = "CURRENT_MODULE_FETCH";
export const CURRENT_MODULE_FETCH_SUCCESS = "CURRENT_MODULE_FETCH_SUCCESS";
export const CURRENT_MODULE_FETCH_FAILURE = "CURRENT_MODULE_FECTH_FAILURE";

export const CURRENT_CLASS_FETCH = "CURRENT_CLASS_FETCH";
export const CURRENT_CLASS_FETCH_SUCCESS = "CURRENT_CLASS_FETCH_SUCCESS";
export const CURRENT_CLASS_FETCH_FAILURE = "CURRENT_CLASS_FETCH_FAILURE";

export const CLASS_INFO_FETCH = "CLASS_INFO_FETCH";
export const CLASS_INFO_FETCH_SUCCESS = "CLASS_INFO_FETCH_SUCCESS";
export const CLASS_INFO_FETCH_FAILURE = "CLASS_INFO_FETCH_FAILURE";

export const COURSE_DATA_FETCH = "COURSE_DATA_FETCH";
export const COURSE_DATA_FETCH_SUCCESS = "COURSE_DATA_FETCH_SUCCESS";
export const COURSE_DATA_FETCH_FAILURE = "COURSE_DATA_FETCH_FAILURE";

export const COURSE_MODAL_OPEN = 'COURSE_MODAL_OPEN';
export const CLASS_SELECTOR_MODAL_OPEN = 'CLASS_SELECTOR_MODAL_OPEN';

export const SET_EXPANDED_COURSE_CARD = "SET_EXPANDED_COURSE_CARD";
export const SET_CURRENT_COURSE = "SET_CURRENT_COURSE";
export const SET_SHOWNED_CLASS = "SET_SHOWNED_CLASS";

export const LAST_VISITED_FETCH = "LAST_VISITED_FETCH";
export const LAST_VISITED_FETCH_SUCCESS = "LAST_VISITED_FETCH_SUCCESS";
export const LAST_VISITED_FETCH_FAILURE = "LAST_VISITED_FETCH_FAILURE";

export const UPDATE_LAST_VISITED = "UPDATE_LAST_VISITED";
export const SET_LAST_VISITED = "SET_LAST_VISITED";

export const SET_SHOW_PRESENTATION = "SET_SHOW_PRESENTATION";

export const SET_VIDEO_LOADED = "SET_VIDEO_LOADED";

export const RESET_COURSE = "RESET_COURSE";

export const REDIRECT_TO_CHECKOUT = "REDIRECT_TO_CHECKOUT";