import React from 'react';

const SocialNav = () => (
  <nav className="social-nav">
      <ul>
          <li className="facebook"><a href="http://facebook.com" target="_blank"><i aria-hidden="true" className="fa fa-facebook"></i></a></li>
          <li className="twitter"><a href="http://twitter.com" target="_blank"><i aria-hidden="true" className="fa fa-twitter"></i></a></li>
          <li className="google"><a href="http://google.com" target="_blank"><i aria-hidden="true" className="fa fa-google-plus"></i></a></li>
      </ul>
  </nav>
);

export default SocialNav;