import { CUSTOM } from "../services/rest_types";

export const SUBSCRIBE_PLAN = 'SUBSCRIBE_PLAN';
export const SUBSCRIBE_PLAN_SUCCESS = 'SUBSCRIBE_PLAN_SUCCESS';
export const SUBSCRIBE_PLAN_FAILURE = 'SUBSCRIBE_PLAN_FAILURE';
export const subscribe = (data, redirect = false, basePath, customMessage = 'Seu plano foi alterado com sucesso!') => ({
  type: SUBSCRIBE_PLAN,
  payload: { data, redirect, basePath },
  meta: { custom: true, customMethod: 'POST', resource: 'subscription/pagarme/new', cancelPrevious: false, fetchCustom: CUSTOM, message: true, customMessage }
});
