import React from 'react';
import { Input } from '../components/common';
import TextField from 'material-ui/TextField';
import Select from 'material-ui/Select';
import MaterialInput, { InputLabel, InputAdornment } from 'material-ui/Input';
import { FormControl } from 'material-ui/Form';
import _find from 'lodash/find';
import InputText from '../components/Elements/InputText';
import CreatableSelect from '../components/CreatableSelect';

const renderCustomInput = (props) => {
  const {
    type = 'text',
    placeholder,
    className,
    name,
    id,
    label,
    input,
  } = props;

  return (
    <input
      placeholder={placeholder}
      type={type}
      className={className}
      value={input.value}
      onChange={input.onChange}
      name={name}
      id={id}
      label={label}
    />
  );
};

const renderInput = (props) => {
  const {
    inputType,
    input,
    label,
    disabled = false,
    type = 'text',
    options = [],
    width,
    meta: { touched, error, warning },
    placeholder,
    className,
    customComponent,
    onChangeSort,
    fixMargin,
  } = props;
  return customComponent ? (
    customComponent(props)
  ) : (
    <Input
      fixMargin={fixMargin}
      width={width}
      options={options}
      warning={warning}
      label={label}
      className={className}
      inputType={inputType}
      error={Boolean(touched && error)}
      errorText={touched && error}
      onChangeSort={onChangeSort}
      input={{ ...input, placeholder, type, disabled }}
    />
  );
};

const renderTextField = ({
  input,
  className,
  label,
  meta: { touched, error },
  ...custom
}) => {
  delete custom.meta;
  return (
    <TextField
      className={className}
      error={Boolean(touched && error)}
      helperText={touched && error}
      label={label}
      {...input}
      {...custom}
    />
  );
};

const renderAmountTextField = ({
  input,
  className,
  label,
  meta: { touched, error },
  ...custom
}) => {
  delete custom.meta;
  return (
    <TextField
      className={className}
      InputProps={{
        startAdornment: <InputAdornment position='start'>R$</InputAdornment>,
      }}
      error={Boolean(touched && error)}
      helperText={touched && error}
      label={label}
      {...input}
      {...custom}
    />
  );
};

const renderSelectField = (props) => {
  const { input, children, classes, customClass, id, ...custom } = props;

  delete custom.meta;
  return (
    <FormControl className={customClass}>
      <InputLabel htmlFor={id}>Selecione um caderno</InputLabel>
      <Select
        classes={classes}
        id={id}
        {...input}
        onChange={(event, index, value) => {
          input.onChange(event.target.value);
        }}
        children={children}
        {...custom}
      />
    </FormControl>
  );
};

const renderCreatableSelect = (props) => {
  const { input, customClass, id, label, classes, placeholder,...custom } = props;

  return (
    <FormControl className={customClass}>
      <InputLabel
        classes={{ root: classes.creatableSelectLabel }}
        shrink={true}
        htmlFor={id}
      >
        {label}
      </InputLabel>
      <MaterialInput
        className={classes.creatableSelectInput}
        fullWidth
        disableUnderline
        inputComponent={CreatableSelect}
        inputProps={{
          name: input.name,
          id: id,
          placeholder: placeholder,
          options: custom.options,
          value: input.value,
          onChange: (value) => {
            input.onChange(value);
          },
          onBlur: () => input.onBlur(input.value),
          classes,
          isMulti: true,
          simpleValue: true,
        }}
      />
    </FormControl>
  );
};

const renderMaterialInput = (props) => {
  const { input, customClass, id, ...custom } = props;
  delete custom.meta;
  return (
    <FormControl className={customClass}>
      <InputLabel htmlFor={id}>ou crie um novo caderno</InputLabel>
      <MaterialInput id={id} {...input} {...custom} />
    </FormControl>
  );
};

const renderInputText = ({
  input,
  className,
  label,
  meta: { touched, error },
  ...custom
}) => {
  delete custom.meta;
  return (
    <InputText
      error={Boolean(touched && error)}
      helperText={touched && error}
      label={label}
      input={input}
      {...custom}
    />
  );
};

export {
  renderInput,
  renderTextField,
  renderAmountTextField,
  renderCustomInput,
  renderSelectField,
  renderMaterialInput,
  renderCreatableSelect,
  renderInputText,
};
