import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { ExpandMore } from "@material-ui/icons";
import { Button, CircularProgress } from "@material-ui/core";
import api from "../../services/newApi";

import Logo from "../../assets/img/Simbolo_Laranja.svg";

import { checkoutSetData, checkoutsFetchRequest } from "./actions";

const Container = styled.div`
  font-family: "Proxima Nova";
  display: flex;
  flex-direction: column;
  max-width: 500px;
  min-width: 350px;
  min-height: 64px;
  margin: 0 auto;
  @media (max-width: 700px) {
    max-width: 420px;
  }
  @media (max-width: 578px) {
    width: 100%;
    max-width: unset;
  }
`;

const Wrapper = styled.div`
  font-family: "Proxima Nova";
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 64px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  @media (max-width: 578px) {
    width: 100%;
  }
`;

const ButtonStyle = styled(Button)`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 64px;
  padding: 0px !important;
  border: none;
  background-color: transparent;
  font-family: "Proxima Nova" !important;
  &:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
  @media (max-width: 578px) {
    padding-left: 48px !important;
    padding-right: 16px !important;
  }
`;

const ButtonLabel = styled.span`
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.75);
  padding: 0px 16px;
  width: 100%;
  max-height: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 17px;
  font-weight: normal;
  line-height: 32px;
  /* padding-left: 16px; */
  @media (max-width: 700px) {
    font-size: 15px;
  }
`;

const List = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 22px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  z-index: 10;
  /* display: flex;
    flex-direction: column; */
`;

const ListItem = styled.a`
  display: flex;
  align-items: center;
  min-height: 56px;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  padding: 0 16px 0 16px;
  text-transform: uppercase;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.75);
  background-color: ${(props) => props.selected && "rgba(0, 0, 0, 0.04)"};

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

const ClippingLogo = styled.img`
  width: 40px;
  height: 40px;
  @media (max-width: 700px) {
    width: 36px;
    height: 36px;
  }
  @media (max-width: 500px) {
    display: none;
  }
`;

const CustomCircularProgress = styled(CircularProgress)`
  & > svg {
    color: rgb(252, 121, 84);
  }
`;

function CheckoutSwitch() {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [currentCheckout, setCurrentCheckout] = useState({ nome: "" });

  const checkouts = useSelector((state) => state.checkout.checkoutSelect.data);
  const currentUrl = useSelector((state) => state.checkout.currentCheckout.url);

  async function loadCheckoutData() {
    const headers = { Authorization: localStorage.getItem("token") };
    const query = {
      index: "checkoutselect",
      query: {
        bool: {
          must: [
            {
              match: {
                url: currentUrl,
              },
            },
          ],
        },
      },
    };
    const { data } = await api.post("/elastic/", query, { headers });

    const [filterCheckout] = data.results.filter(
      (item) => item.url === currentUrl
    );
    if (filterCheckout !== undefined) {
      dispatch(checkoutSetData(filterCheckout));
      setCurrentCheckout(filterCheckout);
    }
  }

  useEffect(() => {
    if (checkouts.length) {
      const [filterCheckout] = checkouts.filter(
        (item) => item.url === currentUrl
      );
      if (filterCheckout !== undefined) {
        dispatch(checkoutSetData(filterCheckout));
        setCurrentCheckout(filterCheckout);
      } else {
        loadCheckoutData();
      }
    }
  }, [currentUrl, checkouts]);

  useEffect(() => {
    dispatch(checkoutsFetchRequest());
  }, [])

  // @see URL(https://reactjs.org/docs/hooks-reference.html#useref)
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        // @see URL(https://developer.mozilla.org/en-US/docs/Web/API/Node/compareDocumentPosition)
        if (
          ref.current.compareDocumentPosition(event.target) &
          Node.DOCUMENT_POSITION_CONTAINED_BY
        ) {
          //elemento pode ser selecionado, nao fecha dropdown
        } else {
          //clicou fora da dropdown
          setExpanded(false);
        }
      }

      //adiciono o listener do click
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        //removo o listener do click ao sair
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <Container ref={wrapperRef}>
      {currentCheckout.nome !== "" && checkouts.length > 0 ? (
        <div>
          <ButtonStyle onClick={() => setExpanded(!expanded)}>
            <ClippingLogo src={Logo} />
            <ButtonLabel>
              {currentUrl !== "" &&
                `${currentCheckout.nome} ${currentCheckout.ano}`}
            </ButtonLabel>
            <ExpandMore />
          </ButtonStyle>
          <div style={{ width: "100%", position: "relative" }}>
            {expanded && (
              <List>
                {checkouts.map((checkout) => {
                  return (
                    <ListItem
                      href={checkout.url}
                      key={checkout.id}
                      selected={currentCheckout.id === checkout.id}
                    >
                      {checkout.nome} {checkout.ano}
                    </ListItem>
                  );
                })}
              </List>
            )}
          </div>
        </div>
      ) : (
        <Wrapper loading={1}>
          <CustomCircularProgress />
        </Wrapper>
      )}
    </Container>
  );
}

export default CheckoutSwitch;
