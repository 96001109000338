// @flow

import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from 'material-ui/styles';
import { ListItem } from 'material-ui/List';
import Button from 'material-ui/Button';
import {activeRoute} from '../../utils';
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';

const styles = theme => ({
  button: theme.mixins.gutters({
    justifyContent: 'flex-start',
    textTransform: 'none',
    width: '95%',
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      textDecoration: 'none',
      background: 'transparent',
      color: theme.palette.text.primary,
    },
    '&$activeButton': {
      background: '#FFF',
    },
    height: '50px',
    borderRadius: '3px',
    color: theme.palette.text.primary,
    fontWeight: 500
  }),
  navItem: {
    ...theme.typography.body2,
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  navLink: {
    fontWeight: theme.typography.fontWeightRegular,
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  separator: {
    borderWidth: "0px 0px 1px 0px",
    borderStyle: "groove",
    borderColor: "rgba(0,0,0,.1)",
    width: "80%",
    left: "12px",
    position: "relative",
    marginTop: "10px",
    marginBottom: "10px"
  },
  navLinkButton: {
    color: theme.palette.black.a6,
    textIndent: theme.spacing.unit * 3,
    fontSize: 17,
    paddingRight: '0px',
    paddingLeft: '0px',
    textAlign: 'left',
    fontFamily: theme.typography.fontFamily
  },
  activeButton: {
    color: theme.palette.text.primary,
    background: '#FFF',
    boxShadow: '0 5px 10px 0 rgba(0,0,0,0.07)'
  },
  icons: {
    fontFamily: 'icomoon',
    fontSize: '20px !important',
    fontStyle: 'normal',
    color: '#4F4033',
    opacity: '0.3',
    marginLeft: '-12px',
    marginRight: '16px',
    width: '48px'
  },
  soon: {
    position: 'absolute',
    bottom: '0px',
    left: '50px',
    height: '14px',
    width: '55px',
    border: '1px solid rgba(0,0,0,0.1)',
    borderRadius: '2px',
    lineHeight: '13px',
    fontSize: '11px',
    textIndent: '0px',
    textAlign: 'center'
  }
});

class AppDrawerSeparator extends React.Component {

  extractFeature = (title) => {
    switch(title) {
      case 'Plano de Estudos': return 'Bibliografia';
      case 'Edital Esquematizado': return 'Edital';
      case 'Meus Cadernos': return 'Cadernos';
      default: return title;
    }
  }

  handleClick = (href) => {
    const { title, trackMetric } = this.props;

    trackMetric(`${this.extractFeature(title)} - Intenção de uso`, {
      target: 'menu',
      trigger: 'click'
    });

    if(this.props.external) {
      window.open(href, "_blank");
    } 
    else {
      this.props.pushRouter(href)
      this.props.closeDrawer();
    }
  };

  render() {
    const { classes, href, title, icon, location, soon = false } = this.props;

    return (
        <ListItem className={`${classes.navLink} `} disableGutters>
            <div className={`${classes.separator}`}></div>
        </ListItem>
    );
  }
}

AppDrawerSeparator.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  openImmediately: PropTypes.bool,
  title: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackMetric: (name, data) => dispatch(trackMetricAction(name, data))
  }
}

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps),
)(AppDrawerSeparator);


{/*<li class="MuiListItem-root-205 MuiListItem-default-208 Connect-AppDrawerNavItem--navLink-200 "><div style="
    border-width: 0px 0px 3px 0px;
    border-style: groove;
    border-color: #FFF;
    width: 80%;
    left: 12px;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
"></div></li>*/}