import {
  put, call, cancelled, takeEvery, takeLatest,
} from 'redux-saga/effects';
import {
  PROFILE_UPDATE,
  KINDLE_CONNECT,
  UPDATE_PASSWORD,
  GET_ACCOUNT_DATA,
  GET_ACCOUNT_ACTIVE_SUBSCRIPTIONS,
  GET_ACCOUNT_PM,
  PROFILE_UPDATE_SUCCESS,
  KINDLE_DISCONNECT,
} from '../actions/profileActions';
import {
  FETCH_START,
  FETCH_END,
  FETCH_ERROR,
  FETCH_CANCEL,
} from '../actions/fetchActions';
import { CRUD_UPDATE, CRUD_UPDATE_SUCCESS } from '../actions/dataActions';
import { CREATE_AUDIO } from '../actions/extraActions';

const profileFetch = (restClient) => {
  function* handleFetch(action) {
    const { type, payload, meta: { profile: fetchMeta, ...meta } } = action;
    const restType = fetchMeta;
    delete meta.custom;
    yield [
      put({ type: `${type}_LOADING`, payload, meta }),
      put({ type: FETCH_START }),
    ];
    let response;
    try {
      switch (type) {
        case PROFILE_UPDATE:
        case KINDLE_CONNECT:
        case KINDLE_DISCONNECT:
        case UPDATE_PASSWORD:
        case GET_ACCOUNT_PM:
        case CREATE_AUDIO:
        case GET_ACCOUNT_DATA:
        case GET_ACCOUNT_ACTIVE_SUBSCRIPTIONS:
          response = yield call(restClient, restType, meta.resource, { ...meta, ...payload });
          if (!response.data) {
            throw new Error('REST response must contain a data key');
          }
          yield put({
            type: `${type}_SUCCESS`,
            payload: response,
            requestPayload: payload,
            meta: { ...meta, fetchResponse: restType, fetchStatus: FETCH_END },
          });
          break;
        default:
          break;
      }

      yield put({ type: FETCH_END });
    } catch (error) {
      yield put({
        type: `${type}_FAILURE`,
        error: error.message ? error.message : error,
        requestPayload: payload,
        meta: { ...meta, fetchResponse: restType, fetchStatus: FETCH_ERROR },
      });
      yield put({ type: FETCH_ERROR, error });
    } finally {
      if (yield cancelled()) {
        yield put({ type: FETCH_CANCEL });
        return; /* eslint no-unsafe-finally:0 */
      }
    }
  }

  function handleUserProfile() {
    window.location.href = `/login?token=${localStorage.token}`;
  }

  function* handleAvatarChange(action) {
    yield put({ type: PROFILE_UPDATE_SUCCESS, payload: action.payload });
  }

  return function* watchProfileFetch() {
    yield [
      takeLatest((action) => action.meta && action.meta.profile && action.meta.cancelPrevious, handleFetch),
      takeEvery((action) => action.meta && action.meta.profile && !action.meta.cancelPrevious, handleFetch),
      takeEvery((action) => action.meta && action.meta.resource === 'userProfile' && action.type === CRUD_UPDATE && action.payload.data && action.payload.data.mainConcurso, handleUserProfile),
      takeEvery((action) => action.meta && action.meta.resource === 'userProfile' && action.type === CRUD_UPDATE_SUCCESS, handleAvatarChange),

    ];
  };
};


export default profileFetch;
