import React from "react";
import axios from "axios";
import compose from "recompose/compose";
import { connect } from "react-redux";

import { withStyles } from "material-ui/styles";
import Dialog, { DialogContent, DialogTitle } from "material-ui/Dialog";
import Close from "material-ui-icons/Close";
import IconButton from "material-ui/IconButton";

import PlanItem from "./PlanItem";
import UpgradePlanConfirm from "./UpgradePlanConfirm.js";

import { getAccountData as getAccountDataAction } from "../../../actions/profileActions";
import { crudGetList } from "../../../actions/dataActions";
import { SORT_ASC } from "../../../reducers/resource/list/queryReducer";

const styles = (theme) => ({
  container: {
    borderRadius: "4px",
    [theme.breakpoints.up("md")]: {
      minWidth: "500px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "16px",
    },
  },
  title: {
    color: theme.palette.orange,
    borderBottom: "1px solid #96969626",
    fontSize: "18px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: "600",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "80px",
    paddingLeft: "38px",
    paddingRight: "24px",
  },
  close: {
    color: theme.palette.text.clear,
    cursor: "pointer",
    marginTop: "-15px",
    width: "20px",
    "&:hover": {
      color: theme.palette.orange,
    },
  },
  content: {
    padding: "38px",
    [theme.breakpoints.down("sm")]: {
      padding: "38px 16px",
    },
  },
});

class ModalUpgrade extends React.Component {
  state = {
    loading: false,
    currentPlan: {
      image: null,
      name: "",
      description: "",
      price: 0,
      buttonText: "PLANO ATUAL",
    },
    upgradePlan: {
      image: null,
      name: "",
      description: "",
      price: 0,
      buttonText: "QUERO ESSE!",
    },
    loadingPlanUpgrade: false,
    successPlanUpgrade: false,
  };

  handleClose = () => {
    this.props.handleOpen(false);

    setTimeout(() => {
      this.setState({
        ...this.state,
        loadingPlanUpgrade: false,
        successPlanUpgrade: false,
      });
    }, 500);
  };

  componentDidMount() {
    const { plans, subscription } = this.props;

    if (!plans) return;

    const _plans = Object.values(plans);

    const PLAN = process.env.REACT_APP_UPGRADE_PLAN;
    const upgradePlan = _plans.find((plan) => plan.externalId === PLAN);

    if (upgradePlan) {
      this.setState({
        ...this.state,
        currentPlan: { ...this.state.currentPlan, ...subscription },
        upgradePlan: { ...this.state.upgradePlan, ...upgradePlan },
      });
    }
  }

  async handleUpgradePlan() {
    const { getAccountData, loadTransactions } = this.props;

    this.setState({ ...this.state, loadingPlanUpgrade: true });

    try {
      const base_url = process.env.REACT_APP_API_BASE_URL;
      const token = localStorage.getItem("token");
      const planId = this.state.upgradePlan._id || null;

      if (!token) throw new Error("Token não encontrado");
      if (!planId) throw new Error("ID de plano inválido");

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios.put(`${base_url}/subscription/plan`, { planId }, { headers });

      getAccountData();
      loadTransactions();

      setTimeout(() => {
        this.setState({
          ...this.state,
          loadingPlanUpgrade: false,
          successPlanUpgrade: true,
        });
      }, 2000);
    } catch (error) {
      setTimeout(() => {
        this.setState({
          ...this.state,
          loadingPlanUpgrade: false,
          successPlanUpgrade: false,
        });
      }, 1000);
      console.error("Falha ao realizar upgrade de plano", error);
    }
  }

  handleConfirmUpgrade(open) {
    this.setState({ ...this.state, confirmPlanUpgrade: open });
  }

  render() {
    const { classes, open } = this.props;
    const {
      currentPlan,
      upgradePlan,
      loadingPlanUpgrade,
      successPlanUpgrade,
    } = this.state;

    return (
      <Dialog
        open={Boolean(open)}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.container }}
      >
        <DialogTitle disableTypography className={classes.title}>
          Alterar plano
          <div className={classes.close}></div>
          <IconButton
            className={classes.close}
            aria-label="Fechar"
            onClick={() => this.handleClose()}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <UpgradePlanConfirm
            loading={loadingPlanUpgrade}
            confirm={successPlanUpgrade}
          />
          {!successPlanUpgrade && !loadingPlanUpgrade && (
            <React.Fragment>
              <PlanItem
                plan={currentPlan}
                buttonText={currentPlan.buttonText}
                disabled={true}
              />
              <PlanItem
                plan={upgradePlan}
                buttonText={upgradePlan.buttonText}
                onClick={() => this.handleUpgradePlan()}
              />
            </React.Fragment>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapDispatchToProps = {
  getAccountData: getAccountDataAction,
  loadTransactions: () =>
    crudGetList(
      "subscription/pagarme/transactions",
      { page: 1, perPage: 6 },
      { field: "createdAt", order: SORT_ASC },
      {},
      false
    ),
};

const enhance = compose(withStyles(styles), connect(null, mapDispatchToProps));

export default enhance(ModalUpgrade);
