import React from "react";

// import { Container } from './styles';

function CalendarIcon({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      fill={color}
      color={color}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="MaterialPlus-/-calendar-month-outline"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        fillOpacity="1"
      >
        <path
          d="M7,12 L9,12 L9,14 L7,14 L7,12 M21,6 L21,20 C21,21.1045695 20.1045695,22 19,22 L5,22 C3.89,22 3,21.1 3,20 L3,6 C3,4.8954305 3.8954305,4 5,4 L6,4 L6,2 L8,2 L8,4 L16,4 L16,2 L18,2 L18,4 L19,4 C20.1045695,4 21,4.8954305 21,6 M5,8 L19,8 L19,6 L5,6 L5,8 M19,20 L19,10 L5,10 L5,20 L19,20 M15,14 L15,12 L17,12 L17,14 L15,14 M11,14 L11,12 L13,12 L13,14 L11,14 M7,16 L9,16 L9,18 L7,18 L7,16 M15,18 L15,16 L17,16 L17,18 L15,18 M11,18 L11,16 L13,16 L13,18 L11,18 Z"
          id="Shape"
          fill={color}
          color={color}
        ></path>
      </g>
    </svg>
  );
}

export default CalendarIcon;
