import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { courseModalAction } from "../../containers/Cursos/actions";

import IconButton from "material-ui/IconButton";
import BackIcon from "material-ui-icons/ArrowBack";
// import ClockIcon from "material-ui-icons/Timer";

import Dropdown from "../Dropdown";
import { push } from "react-router-redux";

const ScrolledCourseHeaderContainer = styled.div`
  overflow: visible;
  z-index: 6;
  position: fixed;
  top: 0;
  width: calc(100vw - 15px);
  background: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
  box-shadow: 0px 1px 0px 0px rgb(232, 232, 232);
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  text-align: center;
`;

const NavItems = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
`;

const BackButton = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  padding: 10px 0px 0px 12px;
`;

const RightGroup = styled.div`
  /* width: fit-content; */
  /* height: fit-content; */
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  margin-right: 24px;
`;

// const Ajuda = styled.a`
//   text-decoration: none;
//   width: fit-content;
//   font-family: "Proxima Nova", sans-serif;
//   font-size: 12px;
//   margin: auto 10px;
//   padding: 8px;
//   border-radius: 16px;
//   font-weight: 500;
//   color: rgb(113, 113, 113);
//   cursor: pointer;
//   text-transform: uppercase;
// `;

// const Profile = styled.div`
//   width: 32px;
//   height: 32px;
//   border-radius: 50%;
//   margin: auto 0px auto 20px;
//   background-color: #fff;
//   background-image: ${(props) => `url(${props.profileUrl})`};
//   background-position: center;
//   background-size: contain;
//   background-repeat: no-repeat;
// `;

function ScrolledCourseHeader({ backgroundUrl, profileUrl, scrolled, setScrolled }) {
  const dispatch = useDispatch();

  function hideCourse() {
    setScrolled(false);
    dispatch(push("/cursos"));
  }

  return (
    <ScrolledCourseHeaderContainer
      scrolled={scrolled}
      backgroundUrl={backgroundUrl}
    >
      <BackButton style={{ flex: 1 }}>
        <IconButton
          scrolled={true}
          style={{ color: "rgba(0,0,0, .4)" }}
          aria-label="voltar"
          onClick={hideCourse}
        >
          <BackIcon />
        </IconButton>
      </BackButton>

      <Dropdown style={{ flex: 1 }} scrolled={true} />

      <RightGroup style={{ flex: 1 }} scrolled={true}>
        {/* <Ajuda
          href={"http://ajuda.clippingcacd.com.br/pt-BR/"}
          target={"blank"}
          scrolled={true}
        >
          AJUDA
        </Ajuda>
        <IconButton
          href={"https://clippingcacd.com.br/timer/CACD/"}
          target={"blank"}
          title={"Cronômetro de Estudos"}
          style={{ color: "rgba(0,0,0, .4)" }}
          aria-label="cronometro"
        >
          <ClockIcon />
        </IconButton>
        <Profile profileUrl={profileUrl} /> */}
      </RightGroup>
    </ScrolledCourseHeaderContainer>
  );
}

export default ScrolledCourseHeader;
