// @flow

import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { withStyles } from 'material-ui/styles';
import {activeRoute} from '../../utils';
import { Link } from 'react-router';
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';

const styles = theme => ({
  wrapper: {
		cursor: 'pointer',
    flex: 1,
    alignItems: 'center',
    borderRadius: '3px',
    display: 'flex',
		background: '#fff',
		width: '100%',
		marginTop: '8px',
		marginBottom: '8px',
		padding: '8px',
		boxShadow: theme.shadows[25],
		paddingLeft: '12px',
		'&$active': {
			paddingLeft: '10px',
			borderLeftColor: theme.palette.clearOrange,
			borderLeftStyle: 'solid',
			borderLeftWidth: '2px'
    },
    ...theme.typography.small,
    color: theme.palette.text.clear,
    fontSize: '12px',
    lineHeight: '13px',
    textDecoration: 'none',
    minHeight: '60px'
	},
  active: {

  },
  percent: {
    paddingRight: '16px'
  }
});

class NavItem extends React.Component {
  track = () => {
    const { _id, trackMetric } = this.props;
    
    trackMetric('Edital - Ativação', {
      target: 'navigation',
      trigger: 'click',
      resource: _id
    });
  }

  render() {
    const { classes, _id , percent, name, location } = this.props;

    const active = activeRoute(_id, location) ? classes.active : ''
      return (
        <div onClick={this.track}>
          <Link
            to={`/edital-esquematizado/${_id}`}
            className={classNames(classes.wrapper, active)}
          >
            <div className={classes.percent}>{parseInt(percent*100, 10)}%</div><div className={classes.name}>{name}</div>
          </Link>
        </div>
      );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    trackMetric: (name, data) => dispatch(trackMetricAction(name, data))
  }
}

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps),
)(NavItem);