import {
  NEW_PAY_COURSE_CLASS,
  NEW_PAY_COURSE_CLASS_FAILURE,
  NEW_PAY_COURSE_CLASS_SUCCESS,
  CREATE_DEBIT,
  CREATE_DEBIT_FAILURE,
  CREATE_DEBIT_SUCCESS,
  DEBITS_FETCH,
  DEBITS_FETCH_FAILURE,
  DEBITS_FETCH_SUCCESS,
  CURRENT_DEBIT_FETCH,
  CURRENT_DEBIT_FETCH_FAILURE,
  CURRENT_DEBIT_FETCH_SUCCESS,
  SET_CREDITPAY_MODAL_OPEN,
  SET_PAYMENT_MODAL_OPEN,
  SET_PAYMENT_STATUS_MODAL_OPEN,
  SET_CREDITPAY_MODAL_ITEMS,
  SET_CHOOSE_PAYMENT_OPEN,
  SET_CHOOSE_CARD_OPEN,
  SET_CHOOSE_INSTALLMENTS_OPEN,
  SET_BOLETO_MODAL_OPEN,
  SET_BOLETO_URL,
  BOLETO_FETCH,
  BOLETO_MODAL_FETCH_FAILURE,
  BOLETO_MODAL_FETCH_SUCCESS,
  PAYMENT_FETCH,
  PAYMENT_FETCH_FAILURE,
  PAYMENT_FETCH_SUCCESS,
  USER_PAYMENTS_FETCH,
  USER_PAYMENTS_FETCH_FAILURE,
  USER_PAYMENTS_FETCH_SUCCESS,
  SET_LOADING_MODAL_OPEN,
  SET_CREDITPAY_MODAL_TITLE,
  SET_CREDITPAY_MODAL_INSTALLMENTS,
  FETCH_PENDING_PAYMENTS,
  INIT_PENDING_PAYMENTS,
  CLEAR_CURRENT_PAYMENT,
  SHOULD_UPDATE_MASK,
  USER_CARD_DELETE_REQUEST,
  USER_CARD_DELETE_FAILURE,
  USER_CARD_DELETE_SUCCESS,
  USER_CARD_CONFIRM_DELETE,
  SUBMIT_SPONSOR_ADDRESS,
  SUBMIT_SPONSOR_PERSONALDATA,
  OPEN_FINANCIAL_RESPONSIBLE,
  SAVE_SPONSOR,
  SHOW_SPONSOR_MODAL,
  SET_SPONSOR_ID,
  CLEAR_SPONSOR,
  SET_LAST_PAYED
} from "./constants";

/**
 *
 * @param {Object} params
 * @param {Array} params.pendingPayments
 */

export function setLastPayed(payload) {
  return {
    type: SET_LAST_PAYED,
    payload,
  };
}

export function openFinancialResponsible(payload) {
  return {
    type: OPEN_FINANCIAL_RESPONSIBLE,
    payload,
  };
}

export function clearSponsor() {
  return {
    type: CLEAR_SPONSOR,
  };
}

export function showSponsorModal(payload) {
  return {
    type: SHOW_SPONSOR_MODAL,
    payload,
  };
}

export function setSponsorID(payload) {
  return {
    type: SET_SPONSOR_ID,
    payload,
  };
}

export function saveSponsor(payload) {
  return {
    type: SAVE_SPONSOR,
    payload,
  };
}

export function saveSponsorAddress(payload) {
  return {
    type: SUBMIT_SPONSOR_ADDRESS,
    payload,
  };
}

export function saveSponsorPersonalData(payload) {
  return {
    type: SUBMIT_SPONSOR_PERSONALDATA,
    payload,
  };
}

export function setPendingPayment({ payment }) {
  return {
    type: FETCH_PENDING_PAYMENTS,
    payload: payment,
  };
}

export function clearCurrentPayment() {
  return {
    type: CLEAR_CURRENT_PAYMENT,
  };
}

export function initPendingPayment({ payload }) {
  return {
    type: INIT_PENDING_PAYMENTS,
    payload,
  };
}

export function shouldUpdateMask(content) {
  return {
    type: SHOULD_UPDATE_MASK,
    content,
  };
}

export function payCourseClassRequest(body) {
  return {
    type: NEW_PAY_COURSE_CLASS,
    body,
  };
}

export function payCourseClassSuccess({ payload }) {
  return {
    type: NEW_PAY_COURSE_CLASS_SUCCESS,
    payload: { ...payload },
  };
}

export function payCourseClassFailure() {
  return {
    type: NEW_PAY_COURSE_CLASS_FAILURE,
  };
}

export function createDebitRequest(body) {
  return {
    type: CREATE_DEBIT,
    body,
  };
}

export function createDebitSuccess({ payload }) {
  return {
    type: CREATE_DEBIT_SUCCESS,
    payload: { ...payload },
  };
}

export function createDebitFailure() {
  return {
    type: CREATE_DEBIT_FAILURE,
  };
}

export function debitsFetchRequest(moduleID) {
  return {
    type: DEBITS_FETCH,
    moduleID,
  };
}

export function debitsFetchSuccess({ payload }) {
  return {
    type: DEBITS_FETCH_SUCCESS,
    payload,
  };
}

export function debitsFetchFailure() {
  return {
    type: DEBITS_FETCH_FAILURE,
  };
}

export function currentDebitFetchRequest(pacote) {
  return {
    type: CURRENT_DEBIT_FETCH,
    pacote,
  };
}

export function currentDebitFetchSuccess({ payload }) {
  return {
    type: CURRENT_DEBIT_FETCH_SUCCESS,
    payload: { ...payload },
  };
}

export function currentDebitFetchFailure() {
  return {
    type: CURRENT_DEBIT_FETCH_FAILURE,
  };
}

export function userPaymentsFetchRequest() {
  return {
    type: USER_PAYMENTS_FETCH,
  };
}

export function userPaymentsFetchSuccess({ payload }) {
  return {
    type: USER_PAYMENTS_FETCH_SUCCESS,
    payload: { ...payload },
  };
}

export function userPaymentsFetchFailure() {
  return {
    type: USER_PAYMENTS_FETCH_FAILURE,
  };
}

export function setCreditPayModalOpen(payload) {
  return {
    type: SET_CREDITPAY_MODAL_OPEN,
    payload,
  };
}

export function setPaymentModalOpen(payload) {
  return {
    type: SET_PAYMENT_MODAL_OPEN,
    payload,
  };
}

export function setPaymentStatusModalOpen(payload) {
  return {
    type: SET_PAYMENT_STATUS_MODAL_OPEN,
    payload,
  };
}

export function setCreditPayModalItems(payload) {
  return {
    type: SET_CREDITPAY_MODAL_ITEMS,
    payload,
  };
}

export function setChoosePaymentOpen(payload) {
  return {
    type: SET_CHOOSE_PAYMENT_OPEN,
    payload,
  };
}

export function setChooseCardOpen(payload) {
  return {
    type: SET_CHOOSE_CARD_OPEN,
    payload,
  };
}

export function setChooseInstallmentsOpen(payload) {
  return {
    type: SET_CHOOSE_INSTALLMENTS_OPEN,
    payload,
  };
}

export function setBoletoModalOpen(payload) {
  return {
    type: SET_BOLETO_MODAL_OPEN,
    payload,
  };
}

export function setBoletoUrl(payload) {
  return {
    type: SET_BOLETO_URL,
    payload,
  };
}

export function fetchBoleto(payload) {
  return {
    type: BOLETO_FETCH,
    payload,
  };
}

export function fetchBoletoSuccess() {
  return {
    type: BOLETO_MODAL_FETCH_SUCCESS,
  };
}

export function fetchBoletoFailure() {
  return {
    type: BOLETO_MODAL_FETCH_FAILURE,
  };
}

export function fetchPayment(payload) {
  return {
    type: PAYMENT_FETCH,
    payload,
  };
}

export function fetchPaymentSuccess() {
  return {
    type: PAYMENT_FETCH_SUCCESS,
  };
}

export function fetchPaymentFailure() {
  return {
    type: PAYMENT_FETCH_FAILURE,
  };
}

export function setLoadingModalOpen(payload) {
  return {
    type: SET_LOADING_MODAL_OPEN,
    payload,
  };
}

export function setCreditPayModalTitle(title) {
  return {
    type: SET_CREDITPAY_MODAL_TITLE,
    payload: { title },
  };
}

export function setCreditPayModalInstallments(installments = 1) {
  return {
    type: SET_CREDITPAY_MODAL_INSTALLMENTS,
    payload: { installments },
  };
}

export function deleteCardRequest(id) {
  return {
    type: USER_CARD_DELETE_REQUEST,
    id,
  };
}

export function deleteCardSuccess() {
  return {
    type: USER_CARD_DELETE_SUCCESS,
  };
}

export function deleteCardFailure() {
  return {
    type: USER_CARD_DELETE_FAILURE,
  };
}

export function openConfirmDelete(payload) {
  return {
    type: USER_CARD_CONFIRM_DELETE,
    payload,
  };
}
