import React, { Component } from 'react'
import { withStyles } from 'material-ui/styles';
import IconButton from 'material-ui/IconButton';
import DeleteIcon from 'material-ui-icons/Delete'
import debounce from 'lodash/debounce';
import TextField from 'material-ui/TextField';

const styles = theme => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '8px',
    marginBottom: '8px',
    marginTop: '8px',
    '&:hover $icon': {
      color: 'rgba(0,0,0,0.3)'
    }
  },
  input: {    
    '& textarea': {
      fontSize: '28px',
      ...theme.typography.display1,
      color: 'rgba(0,0,0,0.8)',
      fontFamily: '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
      fontWeight: 'bold',
      paddingLeft: '10px'  
    }
  },
  icon: {
    height: '20px',
    width: '20px',
    color: 'rgba(0,0,0,0)'
  },
  '.Connect-RichEditorExample--root-596': {
    '.RichEditor-controls': {
      marginTop: '30px'
    }
  }
})

class FichamentoHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: props.title
    }
  }

  handleClick = () => {
    const {_id, deleteFichamento} = this.props 
    deleteFichamento(_id)
  }

  saveTitle = debounce((title) => {
    this.props.saveTitle(this.props._id, title);
  }, 2000);

  handleTitle = (event) => {
    this.setState({title: event.target.value})
    if(this.state.title !== event.target.value) {
      this.saveTitle(event.target.value);
      this.props.setSaving(true)
    }
  }

  componentWillReceiveProps(nextProps) {
    const {saving, setSaving} = this.props
    if(nextProps.loading === 0 && saving) {
      setSaving(false)
    }
  }

  render() {
    const {classes} = this.props

    return (
      <div className={classes.container}>
        <TextField className={classes.input} placeholder="Insira o título aqui..."
          name="title" value={this.state.title} onChange={this.handleTitle} multiline fullWidth 
          InputProps={{ disableUnderline: true }} />

        <IconButton className={classes.icon} 
              onClick={this.handleClick}>
          <DeleteIcon className={classes.icon}/>
        </IconButton> 
      </div>
    )
  }
}

export default withStyles(styles)(FichamentoHeader)
