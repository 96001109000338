import { 
  DELETE,
  CREATE,
  CLEAR
 } from '../../../services/rest_types';
import { FETCH_END } from '../../../actions/fetchActions';

const defaultState = 0

export default resource => (previousState = defaultState, { type, payload, meta }) => {
    if (!meta || (meta.resource !== resource && !meta.clear) ) {
        return previousState;
    }
    if ((!meta.fetchResponse || meta.fetchStatus !== FETCH_END) && !meta.clear) {
        return previousState;
    }
    switch (meta.fetchResponse) {
      case DELETE:
      case CREATE:
      case CLEAR:
          return previousState + 1;
      default:
          return previousState;
      }
};
