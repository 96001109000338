import styled from 'styled-components';

export const StartBtn = styled.button`
  height: 40px;
  border-radius: 20px;

  border: ${({ alt }) => (alt ? '1px solid #fc7954' : 'none')};
  background-color: ${({ alt }) => (alt ? '#fff' : '#fc7954')};
  color: ${({ alt }) => (alt ? '#fc7954' : '#fff')};

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;

  font-weight: 500;
  font-size: 15px;
  line-height: 24px;

  &:hover {
    background-color: ${({ alt }) => (alt ? '#fc7954' : 'rgb(233, 91, 52)')};
    color: #fff;
  }

  @media (max-width: 400px) {
    font-size: 13px;
    line-height: 20px;
    height: 36px;
    border-radius: 18px;
    padding: 0px 12px;
  }
`;
