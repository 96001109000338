import React, { Component } from 'react'
import { connect } from 'react-redux';
import { push } from 'react-router-redux'
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import {ElementList} from '../../components/common'
import {FetchList} from '../../components'
import Post from './Post'
// import ModalBookmark from './ModalBookmark'
import {crudDelete as crudDeleteAction} from '../../actions/dataActions'
import swal from 'sweetalert'
import EmptyList from './EmptyList'
import ModalAddComment from './ModalAddComment'
import ModalAddPost from './ModalAddPost'
import ModalEditPost from './ModalEditPost'
import { likeResource as likeResourceAction} from '../../actions/dataActions'
import { trackMetric as trackMetricAction } from '../../actions/plataformActions'
import { SORT_DESC } from '../../reducers/resource/list/queryReducer'

const resource = 'timelinePost'
const fixedFilters = {sort: {field: 'createdAt', order: SORT_DESC }, postType: "curso"}

const styles = theme => ({
  content: {
    paddingTop: '8px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '32px'
    }
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between'
  },
  contentMain: {
    padding: '40px 16px 16px 16px',
    left: 'calc(50% - 350px)',
    position: 'absolute',
    '@media (min-width: 1300px) and (max-width: 1540px)': {
      left: '435px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
      position: 'static',
      left: '0px'
    },
    maxWidth: '700px',
    width: '100%'
  },
  contentSidebar: {
    display: 'none',
    padding: '8px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px 0 0 0',
      marginTop: '150px',
      marginLeft: '0',
      marginRight: 'auto',
      display: 'block',
      order: '1'
    }
  },
  root: {
    flexGrow: 1,
    padding: '8px'
  },
});


class ListaCursos extends Component {
  constructor(props) {
    super(props)

    const {_id}  = props.routeParams
    this.state = {
      modal: _id || false,
      addPost: false,
      editPost: false
    }
  }

  handleModal = (_id) => {
    Boolean(_id) && this.props.trackMetric('Mural - Intenção de uso', {
      target: 'modal',
      trigger: 'click',
      resource: _id
    });

    this.setState({ modal: _id })
    this.props.pushRouter(`/lista-cursos${_id ? '/' + _id : ''}`)
  }

  handleEditPost = (open) => {
    this.setState({ editPost: open })
  }

  handleAddPost = (open) => {
    open && this.props.trackMetric('Mural - Intenção de uso', {
      target: 'publish',
      trigger: 'click'
    });

    this.setState({ addPost: open })
  }

  handleLikePost = (postId) => {
    this.props.likeResource(postId, this.props.user._id)
  }

  resetCommentCount = () => {
    setTimeout(() => {
      if(window.DISQUSWIDGETS) {
        window.DISQUSWIDGETS.getCount({reset: true});
      }
      this.resetCommentCount()
    }, 5000)
  }

  componentDidMount() {
    if(window.DISQUSWIDGETS) {
      window.DISQUSWIDGETS.getCount({reset: true});
    }
    this.resetCommentCount()
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.refreshState !== this.props.refreshState) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {classes, deletePost, user = {}} = this.props
    const {modal, addPost, editPost} = this.state

    return (
      <div>
      <section className={classes.content}>
        <div className={classes.contentMain}>
          {/*<AddPost name={user.name} handleAddPost={this.handleAddPost}/>*/}
          <FetchList {...this.props}
              stateRefresh={open}
              fixedFilters={fixedFilters}
              noData={<EmptyList />}
              resource={resource} >
            <ElementList
                  renderRow={(props) => <Post resource={resource} loggedUser={user} {...props} editPost={this.handleEditPost} openModal={this.handleModal} deletePost={deletePost} handleLikePost={this.handleLikePost} />}
                />
            </FetchList>
          </div>
      </section>
      <ModalAddComment handleOpen={this.handleModal} open={modal} handleLikePost={this.handleLikePost} />
      <ModalAddPost handleOpen={this.handleAddPost} open={addPost} />
      <ModalEditPost handleOpen={this.handleEditPost} open={editPost} />
    </div>
    )
  }
} // <ModalBookmark handleOpen={this.handleModal} open={modal} />

ListaCursos.propTypes = {
  classes: PropTypes.object.isRequired,
  pushRouter: PropTypes.func.isRequired
};


const mapDispatchToProps = (dispatch) => {
  return {
    trackMetric: (name, data) => dispatch(trackMetricAction(name, data)),
    likeResource: (postId, userId) => dispatch(likeResourceAction(resource, postId, userId)),
    pushRouter: (path) => dispatch(push(path)),
    deletePost: (_id) => {
      swal({
          title: "Deseja remover este post?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        },
      )
      .then((willDelete) => {
        if (willDelete) {
          dispatch(crudDeleteAction(resource, _id, '', false, false, false))
          swal("O post foi removido com sucesso!", {
            icon: "success",
          });
        } else {
          swal.close()
        }
      });
    }
  }
}

const mapStateToProps = (state, {open} ) => {
  return {
    user: state.user.profile,
    refreshState: state.plataform.refreshState
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ListaCursos);
