import React, { Component } from 'react';
import { withStyles } from 'material-ui/styles';
import Dialog from 'material-ui/Dialog';
import CloseIcon from 'material-ui-icons/Close'
import { CircularProgress } from 'material-ui/Progress';
import VideoPlayer from './VideoPlayer';

const styles = theme => ({
    header: {
        display: 'flex',
        height: '80px',
        alignItems: 'center',
        color: theme.typography.modalTitle,
        padding: '40px',
        justifyContent: 'space-between'
    },
    screen: {
        ...theme.typography.modalTitle,
    },
    content: {
        height: 'fit-content',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    dialog: {
        overflow: 'auto',
        alignItems: 'baseline',
        paddingTop: '24px',
        paddingBottom: '24px'
    },
    container: {
        maxHeight: 'none',
        margin: 0,
        maxWidth: '900px',
        width: '95%'
    },
    close: {
        width: '21px',
        height: '21px',
        color: '#999',
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.orange
        }
    },
})

class ModalVideo extends Component {

    state = { showLoading: true }

    handleRequestClose = () => {
        this.props.handleOpen(false);
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.open && this.props.open !== nextProps.open) {
            this.setState({ showLoading: true })
        }
    }

    handleLoaded = () => {
        this.setState({ showLoading: false });
    }

    render() {
        const { open = {}, classes, course, classData } = this.props

        return (
            <Dialog
                classes={{ root: classes.dialog, paper: classes.container }}
                open={Boolean(open)}
                onRequestClose={this.handleRequestClose}>
                <div className={classes.header}>
                    <h3 className={classes.screen}>Assistir novamente</h3>
                    <CloseIcon className={classes.close} onClick={this.handleRequestClose} />
                </div>
                <div className={classes.content}>
                    {this.state.showLoading && <CircularProgress style={{ color: '#F58220', position: 'absolute' }} />}
                    <VideoPlayer open={open} handleLoaded={this.handleLoaded} course={course} classData={classData} />
                </div>
            </Dialog>
        );
    }
}

export default withStyles(styles)(ModalVideo);