import React from 'react'
import Button from 'material-ui/Button'
import { withStyles } from 'material-ui/styles'
import classNames from 'classnames';

const styles = theme => ({
  wrapper: {
    width: '100%',
    textAlign: 'center'
  },
  container: {
    padding: 0,
    height: '48px',
    width: '99%',
    minWidth: '130px',
    borderRadius: '3px',
    background: '#FFF',
    border: '2px solid',
    borderColor: theme.palette.clearOrange,
    color: theme.palette.clearOrange,
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '14px',
    textAlign: 'center',
    fontFamily: '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
    transition: "border-color ease 0.5s, background-color ease 0.5s, color ease 0.5s",
    '&:hover': {
      borderColor: theme.palette.orange,
      color: theme.palette.orange,
      backgroundColor: '#f8a94729'
    },
    margin: '0px'
  }, 
  disabled: {
    color: theme.palette.black.a2,
    backgroundColor: '#FFF',
    borderColor: theme.palette.black.a2,
    cursor: 'not-allowed'
  },
  buttonFull: {
    borderColor: theme.palette.clearOrange,
    color: '#fff',
    backgroundColor: theme.palette.clearOrange,
    '&:hover': {
      borderColor: theme.palette.orange,
      backgroundColor: theme.palette.orange,
      color: '#fff',
    },
  }
})

const CustomButton = ({classes, children, onClick, disabled, customClass = '', buttonFull, size}) => {
  return (
    <div className={classes.wrapper}>
      <Button style={size ? {maxWidth: `${size.width}px`, height: `${size.height}px`, borderRadius: `${size.height/2}px`} : {}} className={classNames(classes.container, disabled ? classes.disabled : '', buttonFull ? classes.buttonFull : '')} onClick={onClick} disabled={disabled}>
        {children}
      </Button>
    </div>
  )
}

export default withStyles(styles)(CustomButton)
