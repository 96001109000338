import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from "react-router-redux";

import DefaultLoading from '../../components/Loading';

function Redirect() {
    const dispatch = useDispatch();

    const { pathname } = window.location;

    useEffect(() => {
        switch (pathname) {
            case '/pacotes-intensivo':
                return dispatch(push('/checkout/intensivo-2020'));
            case '/pacotes-cursos':
                return dispatch(push('/checkout/extensivo-2021'));
            case '/pacotes-aprofundamento':
            case '/pacotes-aprofundamento-mod4':
                return dispatch(push('/checkout/aprofundamento-2020'));
            case '/pacotes-dce':
                return dispatch(push('/checkout/dce-2020'));
            case 'pacotes-cursos-old':
                return dispatch(push('/checkout'));
            default:
                return dispatch(push('/checkout'));
        }
    }, []);

    return <DefaultLoading />
}

export default Redirect;