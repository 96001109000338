import React, { Component } from "react";
import { withStyles } from "material-ui/styles";
import { connect } from "react-redux";
import compose from "recompose/compose";
import Paper from "material-ui/Paper";
import ArrowDown from "material-ui-icons/ArrowDropDown";
import ArrowUp from "material-ui-icons/ArrowDropUp";
import classNames from "classnames";
import ListItem from "./ListItem";
import FetchListByOne from "../../components/FetchListByOne";
import { ElementList } from "../../components/common";
import { titleFor } from "../../utils/strings";

const styles = (theme) => ({
  container: {
    width: "100%",
    marginBottom: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    color: theme.palette.text.secondary,
    flexDirection: "column",
    cursor: "pointer",
  },
  input: {
    width: "90%",
  },
  card: {
    ...theme.transitions.card,
    flexDirection: "column",
    minHeight: "70px",
    paddingBottom: "0px",
    justifyContent: "space-between",
    "&:hover $icon": {
      color: theme.palette.orange,
    },
    "&$active": {
      boxShadow: theme.shadows[26],
      width: "100%",
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
    ...theme.typography.title,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1em",
    },
  },
  text: {
    paddingLeft: "8px",
  },
  percent: {
    display: "flex",
    alignItems: "center",
    ...theme.typography.subheading,
    color: "#999",
    padding: "8px",
    [theme.breakpoints.up("sm")]: {
      paddingRight: "24px",
    },
  },
  icon: {
    width: "21px",
    height: "21px",
    color: theme.palette.text.clearGrey,
    "&$active": {
      color: theme.palette.orange,
    },
  },
  percentBarContainer: {
    width: "100%",
    height: "2px",
    "&$active": {
      backgroundColor: theme.palette.clearGrey,
    },
  },
  percentBar: {
    backgroundColor: theme.palette.orange,
    height: "2px",
  },
  row: {
    padding: "8px 16px 8px 16px",
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
  },
  active: {},
  listContainer: {
    marginTop: "24px",
    minHeight: "100px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "32px",
  },
  right: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

class CardItem extends Component {
  render() {
    const {
      details = {},
      week,
      numOfRequiredMaterials,
      numOfStudiedMaterials,
      _id,
      classes,
      active,
      concurso,
      handleOpenClose,
      exhibition,
    } = this.props;
    const razaoPercent = parseInt(
      (numOfStudiedMaterials / (numOfRequiredMaterials || 1)) * 100,
      10
    );
    const percent = `${razaoPercent > 100 ? 100 : razaoPercent}%`;

    const getCardTitle = () => {
      if (exhibition === "byweek") {
        const title = titleFor("ciclo", concurso) || "";
        return `${title} ${week}`;
      }

      return details?.name || "";
    };

    return (
      <div
        className={classNames(classes.container, active ? classes.active : "")}
      >
        <Paper
          className={classNames(classes.card, active ? classes.active : "")}
        >
          <div className={classes.row} onClick={() => handleOpenClose(_id)}>
            <div className={classes.title}>
              <div className={classes.text}>{getCardTitle()}</div>
            </div>
            <div className={classes.right}>
              <div className={classes.percent}>{percent}</div>
              {active ? (
                <ArrowUp className={classNames(classes.icon, classes.active)} />
              ) : (
                <ArrowDown className={classes.icon} />
              )}
            </div>
          </div>
          <div
            className={classNames(
              classes.percentBarContainer,
              active ? classes.active : ""
            )}
          >
            <div className={classes.percentBar} style={{ width: percent }} />
          </div>
          {active ? (
            <div>
              <FetchListByOne
                resource={`biblioSubjectMaterial/${exhibition}`}
                _id={_id}
              >
                <ElementList
                  type={classes.listContainer}
                  renderRow={(props) => (
                    <ListItem {...props} exhibition={exhibition} />
                  )}
                />
              </FetchListByOne>
            </div>
          ) : null}
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  concurso: state.concurso.active,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, null)
)(CardItem);
