import React from "react";
import styled from "styled-components";
import ExpandMore from "@material-ui/icons/ExpandMore";

const TabContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-content: center;
  border-radius: 0px;
  height: 56px;
  width: 100%;
  padding: 0px 16px;
`;

const TabInfo = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  align-content: center;
  align-self: center;
  justify-content: space-between;
`;

const TabName = styled.span`
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: 500;
  height: fit-content;
  letter-spacing: 0px;
  width: fit-content;
  align-self: center;
`;

export default function TabResponsive({ name, clicked, id }) {
  return (
    <TabContainer>
      <TabInfo>
        <TabName>{name}</TabName>
        <ExpandMore style={{ color: "rgba(0,0,0,0.4)", transform: clicked === id ? 'rotate(180deg)' : 'rotate(0deg)' }} />
      </TabInfo>
    </TabContainer>
  );
}
