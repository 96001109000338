import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { push } from "react-router-redux";
import { CircularProgress } from "material-ui";
import { Pagination } from "@clippingcacd/core";
import _ from "lodash";

import { likeResource as likeResourceAction } from "../../actions/dataActions";
import { trackMetric as trackMetricAction } from "../../actions/plataformActions";
import api, { getTimelinePosts } from "../../services/api";
import AddPost from "./AddPost";
import Post from "./PostCard";
import ModalAddComment from "./ModalAddComment";
import ModalAddPost from "./ModalAddPost";
import ModalEditPost from "./ModalEditPost";
import * as S from "./styles";
import CommentCard from "./CommentCard";
import ForumTopicCard from "./ForumTopicCard";

const resource = "timelinePost";

export default function Mural() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.profile);
  const refreshState = useSelector((state) => state.plataform.refreshState);
  const responsive = useSelector((state) => state.plataform.responsive);
  const concursoId = useSelector((state) => state.concurso.active.concursoId);
  const concursosTimelinePosts = useSelector(
    (state) => state.concurso.active.timelinePost || []
  );
  const [posts, setPosts] = useState({
    loading: true,
    docs: [],
    pages: { current: 1 },
    items: { total: 0 },
  });
  const [modalOpen, setModalOpen] = useState({
    addComment: false,
    addPost: false,
    editPost: false,
  });

  async function getMuralComments({ startDate }) {
    try {
      const { data: comments } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/timelinePost/recents`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: {
            startDate,
            resource: "questions",
            concursoId: concursoId,
            skip: 0,
            limit: 10,
          },
        }
      );

      comments.forEach((comment) => {
        comment.createdAt = comment.comment.createdAt;
        comment.type = "comment";
      });

      if (!comments) {
        return [];
      }

      return comments;
    } catch (err) {
      console.error("Failed to get mural comments.", err);
      return [];
    }
  }

  async function getForumPosts() {

    const { data: topics } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/timelinePost/recent-topics`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );

    const posters = topics.users.reduce(function(acc, cur, i) {
      acc[cur.id] = cur;
      return acc;
    }, {});

    const categories = topics.categories.reduce(function(acc, cur, i) {
      acc[cur.id] = cur;
      return acc;
    }, {});

    return topics.topic_list.topics.slice(0, 10).map((topic) => {
      return {
        ...topic,
        type: "forum-topic",
        text: topic.title,
        createdAt: topic.last_posted_at,
        updatedAt: topic.updated_at,
        tags: topic.tags,
        posters: topic.posters.map((poster) => posters[poster.user_id]?.avatar_template.startsWith('https') ? posters[poster.user_id]?.avatar_template.replace("{size}", "24") : 'https://sjc6.discourse-cdn.com/standard17' + posters[poster.user_id]?.avatar_template.replace("{size}", "24")),
        category: categories[topic.category_id]?.name,
      }
    });
  }

  async function fetchPosts({ page }) {
    setPosts((before) => ({ ...before, loading: true }));

    try {
      const { data } = await getTimelinePosts({
        concursoIds: concursosTimelinePosts,
        page: page || posts.pages.current,
        token: localStorage.getItem("token"),
      });
      if (!page || (page === 1 && data.docs.length)) {
        const lastComment = data.docs[data.docs.length - 1];
        const startDate = moment(lastComment.updatedAt).format("YYYY-MM-DD");

        const comments = await getMuralComments({ startDate });
        var topics = [];

        if (concursoId === 1) {
          topics = await getForumPosts();
        }
        

        data.docs = [...data.docs, ...comments, ...topics].sort((a, b) =>
          a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0
        );

      }

      setPosts((before) => ({ ...before, ...data, loading: false }));
    } catch (err) {
      console.error("Failed to fetch post.", err);

      setPosts((before) => ({
        ...before,
        data: { ...before.data, docs: [] },
        loading: false,
      }));
    }
  }

  function resetCommentCount() {
    setTimeout(() => {
      if (window.DISQUSWIDGETS) window.DISQUSWIDGETS.getCount({ reset: true });

      resetCommentCount();
    }, 5000);
  }

  function handleOpenModal(keyModal, open) {
    return (_open) => setModalOpen({ ...modalOpen, [keyModal]: open || _open });
  }

  function handleOpenModalAddComment(id) {
    if (Boolean(id))
      dispatch(
        trackMetricAction("Mural - Intenção de uso", {
          target: "modal",
          trigger: "click",
          resource: id,
        })
      );

    setModalOpen({ ...modalOpen, addComment: id });
    dispatch(push(`/mural${id ? "/" + id : ""}`));
  }

  function handleAddPost(open) {
    // if (open)
    dispatch(
      trackMetricAction("Mural - Intenção de uso", {
        target: "publish",
        trigger: "click",
      })
    );

    if (concursoId === 1) {
      // window.open("https://forum.clippingcacd.com.br/new-topic?title=topic%20title&body=topic%20body&category=geral&tags=email,planned", "_blank");
      window.open("https://forum.clippingcacd.com.br/new-topic?category=geral", "_blank");
    } else {
      handleOpenModal("addPost", true)();
    }
  }

  function handleDeletePost() {
    swal({
      title: "Deseja remover este post?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(crudDeleteAction(resource, _id, "", false, false, false));
        swal("O post foi removido com sucesso!", {
          icon: "success",
        });
      } else {
        swal.close();
      }
    });
  }

  async function handleLikePost(postId) {
    const {data: liked} = await api.put(`/timelinePost/${postId}/addLikeCount`);
    const index = posts.docs.findIndex((post) => post._id === postId);
    const arrayPosts =  posts.docs; 
    if(liked){
      arrayPosts[index].likes.push(user._id)
    }else{
      const indexUserId =  arrayPosts[index].likes.indexOf(user._id)
      arrayPosts[index].likes.splice(indexUserId, 1)
    }
    setPosts((before) => ({...before, docs: arrayPosts}));
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [refreshState]);

  useEffect(() => {
    if (
      concursoId &&
      localStorage.getItem("token") !== null &&
      (concursosTimelinePosts || concursosTimelinePosts.length)
    ) {
      fetchPosts({
        page: posts.pages.current,
      });

      if (window.DISQUSWIDGETS) window.DISQUSWIDGETS.getCount({ reset: true });

      resetCommentCount();
    }
  }, [concursosTimelinePosts]);

  return (
    <Fragment>
      <S.Root>
        <S.Content>
          <AddPost name={user.name} handleAddPost={handleAddPost} />

          <S.PostList>
            {posts.loading && <CircularProgress />}

            {!posts.loading &&
              posts.docs.map((post, idx) => {
                if (post?.type === "comment")
                  return <CommentCard key={idx} comment={post} />;
                else if(post?.type === "forum-topic")
                  return <ForumTopicCard key={idx} topic={post} />;
                return (
                  <Post
                    key={idx}
                    {...post}
                    resource="timelinePost"
                    loggedUser={user}
                    editPost={handleOpenModal("editPost", true)}
                    openModal={handleOpenModalAddComment}
                    deletePost={handleDeletePost}
                    handleLikePost={handleLikePost}
                  />
                );
              })}
          </S.PostList>

          {!posts.loading && (
            <S.PaginationContainer>
              <Pagination
                current={posts.pages.current}
                total={Math.ceil(posts.items.total / 10)}
                showMax={responsive ? 5 : 10}
                onChange={(page) => fetchPosts({ page })}
              />
            </S.PaginationContainer>
          )}
        </S.Content>
      </S.Root>

      <ModalAddComment
        open={modalOpen.addComment}
        handleOpen={handleOpenModal("addComment")}
        handleLikePost={handleLikePost}
      />

      <ModalAddPost
        open={modalOpen.addPost}
        handleOpen={handleOpenModal("addPost")}
      />

      <ModalEditPost
        open={modalOpen.editPost}
        handleOpen={handleOpenModal("editPost")}
      />
    </Fragment>
  );
}
