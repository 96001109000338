import React, { Component } from 'react'
import { connect } from 'react-redux';
import { push } from 'react-router-redux'
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import {ElementList, Tabs, Title} from '../../components/common'
import {selectType} from '../../config'
import CardItem from './CardItem'
import Grid from 'material-ui/Grid';
import {FetchList} from '../../components'
import ItemBibliografia from './ItemBibliografia'
import { titleFor } from '../../utils/strings';
import ModalFichamentos from '../Cadernos/ModalFichamentos'
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';
import { SORT_ASC } from '../../reducers/resource/list/queryReducer'

const fixedFilters =  {sort: { field: 'week', order: SORT_ASC } }

const styles = theme => ({
  content: {
    paddingTop: '8px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '32px'
    }
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between'
  },
  contentMain: {
    padding: '40px 16px 16px 16px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px'
    }
  },
  contentSidebar: {
    display: 'none',
    padding: '8px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px 0 0 0',
      marginTop: '150px',
      marginLeft: '0',
      marginRight: 'auto',
      display: 'block',
      order: '1'
    }
  },
  root: {
    flexGrow: 1,
    padding: '8px'
  },
  tabsContainer: {
    height: '40px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
});

class Bibliografia extends Component {
  constructor(props) {
    super(props)

    const {_id = false, gabaritoId = false, cadernoId = false, exhibition = ['PRF', 'TRIBUNAIS', 'TRT'].includes(props.concurso.name) ? 'byweek' : 'bysubject', questaoId = false}  = props.routeParams

    this.state = {
      exhibition,
      questaoId,
      open: false,
      modal: _id,
      roteiro: false,
      modalGabarito: gabaritoId,
      modalFichamento: cadernoId,
    }
  }

  handleOpenClose = (_id) => {
    if (this.state.open === _id) {
      this.setState({open: false})
    } else {
      this.setState({open: _id});
      this.props.trackMetric('Bibliografia - Intenção de uso', { target: 'card', trigger: 'click', resource: _id, filter: this.state.exhibition });
    }
  }
  
  handleModal = (_id) => {
    this.setState({modal: _id})
    this.props.pushRouter(`/plano-de-estudos${_id ? '/'+_id : ''}`)
  }

  handleModalFichamento = (cadernoId) => {
    this.setState({modal: cadernoId})
    this.props.pushRouter(`/plano-de-estudos/${this.state.modal}${cadernoId ? '/caderno/' + cadernoId : ''}`)
  }

  componentWillReceiveProps(nextProps) {
    const {gabaritoId, _id, refreshState, cadernoId, questaoId} = nextProps.routeParams
    if(refreshState !== this.props.refreshState) {
      this.setState({open: false});
      window.scrollTo(0, 0);
    }


    if(
      this.props.routeParams._id !== _id || 
      this.props.routeParams.gabaritoId !== gabaritoId || 
      this.props.routeParams.cadernoId !== cadernoId || 
      this.props.routeParams.questaoId !== questaoId
      ) {
      this.setState({modal: _id || false,
          modalGabarito: gabaritoId || false,
          modalFichamento: cadernoId || false,
          questaoId: questaoId || false,  
        })
    }
  }

  handleModalGabarito = (gabaritoId) => {
    const {routeParams} = this.props;
    this.setState({modal: routeParams._id, modalGabarito: gabaritoId});
    this.props.pushRouter(`/plano-de-estudos${routeParams._id ? '/'+routeParams._id : ''}${routeParams._id && gabaritoId ? `/gabarito/${gabaritoId}` : '' }`)
  }

  onChangeTab = (exhibition) => {
    this.setState({exhibition});
    this.props.trackMetric('Bibliografia - Intenção de uso', { target: 'toggle', trigger: 'click', filter: exhibition });
  }

  render() {
    const {classes, concurso, pushRouter} = this.props
    const {exhibition=['PRF', 'TRIBUNAIS', 'TRT'].includes(concurso.name) ? 'byweek' : 'bysubject', open, modal, modalGabarito, modalFichamento, questaoId} = this.state
    return (
      <div>
        <section className={classes.content} ref="blibliografia-container">
            <Grid container className={classes.root} >
                <Grid item xs={12} md={12} className={classes.contentMain}>
                    <Tabs options={selectType(concurso)} active={exhibition} onChange={this.onChangeTab} />
                    <FetchList {...this.props}
                        pagination={false}
                        stateRefresh={`${modal}${open}`}
                        fixedFilters={fixedFilters}
                        resource={`biblioSubject/${exhibition}`} >
                        <ElementList 
                          renderRow={(props) => <CardItem {...props}
                          handleOpenClose={this.handleOpenClose}
                          active={open === props._id} 
                          exhibition={exhibition} />
                        }/>
                    </FetchList>
                </Grid>
            </Grid>
        </section>

        <ItemBibliografia modalGabarito={modalGabarito} pushRouter={pushRouter}
          handleOpen={this.handleModal} openId={modal} exhibition={exhibition}
          handleModalGabarito={this.handleModalGabarito} handleRoteiro={this.handleRoteiro}  questaoId={questaoId}/>
        <ModalFichamentos handleOpen={this.handleModalFichamento} open={modalFichamento ? {"_id": modalFichamento} : false}/>
      </div>
    )
  }
}

Bibliografia.propTypes = {
  classes: PropTypes.object.isRequired,
  pushRouter: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  pushRouter: (path) => push(path),
  trackMetric: (name, data) => trackMetricAction(name, data)
}

const mapStateToProps = (state, ownProps) => {
  return {
    refreshState: state.plataform.refreshState,
    concurso: state.concurso.active
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(Bibliografia);
