import React, { Component } from 'react'
import { withStyles } from 'material-ui/styles'
import map from 'lodash/map'
import Radio from 'material-ui/Radio'
import { FormControlLabel } from 'material-ui/Form'

const styles = theme => ({
    container: {
        marginTop: '14px',
        display:  'flex',
        flexDirection: 'column'
    },
    label: {
        fontSize: '13px',
        color: 'rgba(0,0,0,.6)',
        fontWeight: 600,
        position: 'relative',
        bottom: '-1px',
        left: '-4px'
    },
    labelRoot: {
        marginBottom: '14px'
    },
    radioDefault: {
        height: '0'
    },
    radioChecked: {
        color: '#4298FC'
    },
    alternative: {
        color: '#000000',
        fontWeight: 600,
        fontSize: '14px',
        marginRight: '8px',
        marginLeft: '-1px'
    },
    alternativeBox: {
        display: 'flex',
        alignItems: 'center'
    },
    alternativeText: {
        maxWidth: '600px',
        lineHeight: '18px'
    }
});

class MultipleChoice extends Component {
    selectAnswer = (answer) => {
        const {question, _id, changeAnswer} = this.props 
        changeAnswer(question, _id, answer)
    }

    render() {
        const {classes, readOnly=false, answers={}, alternatives, _id} = this.props
        var answer = (answers[_id] || {}).answer;

        return (
            <div className={classes.container}>
                {map(alternatives, ({text, name}, key) => (
                    (!readOnly ? (
                        <FormControlLabel key={key} 
                            classes={{
                                label: classes.label,
                                root: classes.labelRoot
                            }}
                            control={<Radio 
                                color={'primary'}
                                classes={{
                                    checkedPrimary: classes.radioChecked,
                                    default: classes.radioDefault
                                }}
                                checked={answer === name} 
                                onChange={() => this.selectAnswer(name)}
                            />} 
                            label={
                                <div className={classes.alternativeBox}>
                                    <strong className={classes.alternative}>{name})</strong>
                                    <div className={classes.alternativeText} dangerouslySetInnerHTML={{__html: text}}></div>
                                </div>
                            } 
                        />
                    ) : (
                        <div style={{marginBottom: 8}} className={classes.alternativeBox}>
                            <strong className={classes.alternative}>{name})</strong>
                            <div className={classes.alternativeText} dangerouslySetInnerHTML={{__html: text}}></div>
                        </div>
                    ))
                ))}
            </div>
        )
    }
}

export default withStyles(styles)(MultipleChoice);