import { call, takeLatest, put, select, race, take } from "redux-saga/effects";
import { delay } from "redux-saga";

import { CHECKOUTS_FETCH, CHECKOUT_FETCH, SUBMIT_PERSONAL_DATA, SUBMIT_ADDRESS } from "./constants";

import {
  checkoutsFetchFailure,
  checkoutsFetchSuccess,
  checkoutFetchSuccess,
  checkoutFetchFailure,
  nextStep,
  stepLoading,
  closeRegister,
} from "./actions";

import { setChoosePaymentOpen } from "../Payments/actions";

import api from "../../services/newApi";
import { userFetchRequest } from "../User/actions";
import { USER_FETCH_FAILURE, USER_FETCH_SUCCESS } from "../User/constants";

export function* updateUserAddress(action) {
  yield put(stepLoading(true));
  const headers = { Authorization: localStorage.getItem("token") };

  try {
    const { payload } = action;
    const { address } = yield select((state) => state.userData.data);
    if (address.length) {
      yield call(api.patch, `/users/address/${address[0].id}/`, payload, {
        headers,
      });
    } else {
      const userID = yield select((state) => state.userData.data.id);
      payload.student = userID;

      yield call(api.post, `/users/address/`, payload, {
        headers,
      });
    }

    yield put(userFetchRequest());
    const { userSuccess, userFailure } = yield race({
      userSuccess: take(USER_FETCH_SUCCESS),
      userFailure: take(USER_FETCH_FAILURE),
    });

    if (userFailure !== undefined) {
      throw new Error(400);
    }

    yield put(stepLoading(false));
    yield put(closeRegister());
    yield put(setChoosePaymentOpen(true));
  } catch (error) {
    yield put(stepLoading(false));
  }
}

export function* updateUserData(action) {
  yield put(stepLoading(true));

  const headers = { Authorization: localStorage.getItem("token") };

  try {
    const { payload } = action;
    const userID = yield select((state) => state.userData.data.id);
    const { data } = yield call(api.patch, `/users/students/${userID}/`, payload, {
      headers,
    });

    yield put(userFetchRequest());
    const { userSuccess, userFailure } = yield race({
      userSuccess: take(USER_FETCH_SUCCESS),
      userFailure: take(USER_FETCH_FAILURE),
    });
    if (userFailure !== undefined) {
      throw new Error(400);
    }
    yield put(stepLoading(false));
    yield put(nextStep());
  } catch (error) {
    yield put(stepLoading(false));
  }
}

export function* fetchCheckouts() {
  const headers = { Authorization: localStorage.getItem("token") };
  const concursoAtivo = yield select((state) => state.concurso.active.name);

  try {
    let data = {
      index: "checkoutselect",
      query: {
        bool: {
          must: [
            {
              match: {
                concurso: concursoAtivo,
              },
            },
            {
              match: {
                ativo: true,
              },
            },
          ],
        },
      },
      sort: [
        {
          ano: {
            order: "desc",
          },
        },
        {
          created_at: {
            order: "desc",
          },
        },
      ],
    };

    const { data: response } = yield call(api.post, "/elastic/", data, {
      headers,
    });

    yield delay(1000);
    yield put(checkoutsFetchSuccess({ payload: [...response.results] }));
  } catch (error) {
    yield put(checkoutsFetchFailure());
  }
}

export function* fetchCheckoutByURL(action) {
  const headers = { Authorization: localStorage.getItem("token") };

  try {
    const { url } = action.payload;

    const { data } = yield call(api.get, `/payments/checkout/${url}/`, {
      headers,
    });

    const results = {};
    for (const type in data) {
      results[type] = data[type][0];
    }

    //TODO: permitir array pra todos tipos de checkout
    results["personalizado"] = data["personalizado"];

    // Estrutura inicial de um pacote;
    const initialPackage = {
      show: false,
      modulos: [],
      checkedAll: {},
      indeterminate: {},
      pacotes: [],
    };

    const customPackage = {
      show: false,
      modulos: [],
      checkedAll: {},
      pacotes: [],
    };

    const packages = {
      matriculas: initialPackage,
      matriculas_aulas: initialPackage,
      pacote_completo: initialPackage,
      personalizado: customPackage,
    };

    const cart = yield select((state) => state.checkout.cart);

    if (results.matriculas) {
      packages.matriculas = { ...packages.matriculas, ...results.matriculas };
      packages.matriculas.show = true;

      packages.matriculas.modulos = [...new Set(packages.matriculas.pacotes.map((item) => item.modulo).sort())];

      packages.matriculas.pacotes = packages.matriculas.pacotes.map((pacote) => {
        const [hasItem] = cart.items.filter((items) => items.id === pacote.id);

        if (hasItem) {
          return {
            ...pacote,
            checked: true,
          };
        }

        return {
          ...pacote,
          checked: false,
        };
      });

      for (const modulo of packages.matriculas.modulos) {
        packages.matriculas.checkedAll = {
          ...packages.matriculas.checkedAll,
          [modulo]: false,
        };

        packages.matriculas.indeterminate = {
          ...packages.matriculas.indeterminate,
          [modulo]: false,
        };
      }
    }

    if (results.matriculas_aulas) {
      packages.matriculas_aulas = results.matriculas_aulas;
      packages.matriculas_aulas.show = true;

      packages.matriculas_aulas.modulos = [...new Set(packages.matriculas_aulas.pacotes.map((item) => item.modulo).sort())];

      packages.matriculas_aulas.pacotes = packages.matriculas_aulas.pacotes.map((pacote) => {
        const [hasItem] = cart.items.filter((items) => items.id === pacote.id);

        if (hasItem) {
          return { ...pacote, checked: true };
        }

        return { ...pacote, checked: false };
      });

      for (const modulo of packages.matriculas_aulas.modulos) {
        packages.matriculas_aulas.checkedAll = {
          ...packages.matriculas_aulas.checkedAll,
          [modulo]: false,
        };

        const checked = packages.matriculas_aulas.pacotes.filter((pacote) => pacote.modulo === modulo && pacote.checked);

        if (checked.length) {
          packages.matriculas_aulas.indeterminate = {
            ...packages.matriculas_aulas.indeterminate,
            [modulo]: true,
          };
        } else {
          packages.matriculas_aulas.indeterminate = {
            ...packages.matriculas_aulas.indeterminate,
            [modulo]: false,
          };
        }
      }
    }

    if (results.pacote_completo) {
      packages.pacote_completo = results.pacote_completo;
      packages.pacote_completo.show = true;

      packages.pacote_completo.cursos = [...new Set(packages.pacote_completo.pacotes.map((item) => item.curso).sort())];

      packages.pacote_completo.pacotes = packages.pacote_completo.pacotes.map((pacote) => {
        return {
          ...pacote,
          checked: true,
        };
      });
    }

    if (results.personalizado) {
      packages.personalizado = [];
      for (const pack of results.personalizado) {
        pack.show = true;

        pack.cursos = [...new Set(pack.pacotes.map((item) => item.curso).sort())];

        pack.modulos = [...new Set(pack.pacotes.map((item) => item.modulo).sort())];
        pack.pacotes = pack.pacotes.map((pacote) => {
          return {
            ...pacote,
            checked: false,
          };
        });

        const packages_map = pack.pacotes.map((pacote) => pacote.id);
        const packages_set = [...new Set(packages_map)];
        const packagesObj = {};

        for (const check_pack of packages_set) {
          let packageItems = pack.pacotes.filter((pacote) => pacote.id === check_pack);

          pack.checkedAll = {
            ...pack.checkedAll,
            [check_pack]: false,
          };

          packagesObj[check_pack] = packageItems;
        }
        pack.pacotes = { ...packagesObj };
        packages.personalizado.push(pack);
      }
    }

    yield put(checkoutFetchSuccess({ payload: packages }));
  } catch (error) {
    yield put(checkoutFetchFailure());
  }
}

export default function* checkoutSaga() {
  yield [
    takeLatest(SUBMIT_ADDRESS, updateUserAddress),
    takeLatest(SUBMIT_PERSONAL_DATA, updateUserData),
    takeLatest(CHECKOUTS_FETCH, fetchCheckouts),
    takeLatest(CHECKOUT_FETCH, fetchCheckoutByURL),
  ];
}