import React from 'react'
import IconButton from 'material-ui/IconButton';
import { withStyles } from 'material-ui/styles'
import classNames from 'classnames'
import Tooltip from 'material-ui/Tooltip';  

const styles = theme => ({
  default: {
    margin: theme.spacing.unit,
    border: '1px solid rgba(0,0,0,0.1)'
  },
})

const CustomButton = ({classes, children, customClasses, onClick, tooltipTitle}) => {
  const button = (
    <IconButton className={classNames(customClasses?.button, classes.default)} onClick={onClick}>
      {children}
    </IconButton>
  );

  return tooltipTitle ? (
    <Tooltip title={tooltipTitle} enterDelay={300}>
      {button}
    </Tooltip>
  ) : (
    button
  );
};

export default withStyles(styles)(CustomButton)