import React, { Component } from 'react'
import { connect } from 'react-redux';
import { push } from 'react-router-redux'
import _ from 'lodash';
import { withStyles } from 'material-ui/styles';
import compose from 'recompose/compose';
import Dialog from '../../components/ModalScreen';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import classNames from 'classnames';
import IconButton from 'material-ui/IconButton';
import Button from 'material-ui/Button';
import Slide from 'material-ui/transitions/Slide';
import Tooltip from 'material-ui/Tooltip';
import {crudGetOne as crudGetOneAction} from '../../actions/dataActions';
import ClippingContent from './ClippingContent';
import moment from 'moment';
import BookmarkIcon from 'material-ui-icons/BookmarkBorder';
import BookmarkFullIcon from 'material-ui-icons/Bookmark';
import Drawer from '../../components/DrawerClippings';
import ModalFicharConteudo from './ModalFicharConteudo';
import { ArrowLeft } from '../../components/common/SvgIcons';
import sortBy from 'lodash/sortBy';
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';

const styles = theme => ({
  appBar: {
    top: 0,
    left: 'auto',
    right: 0,
    backgroundColor: '#FFF',
    transition: theme.transitions.create('width'),
    height: '65px',
    paddingRight: '24px',
    justifyContent: 'center',
    boxShadow: theme.shadows[27],
    [theme.breakpoints.down('md')]: {
      paddingRight: '24px !important',
    },
  },
  backContainer: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      padding: '18px'
    },
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  backIcon: {
    ...theme.typography.back,
  },
  responsiveDisplay: {
    display: 'none',
    paddingLeft: '4px',
    [theme.breakpoints.up('sm')]: {
      display: 'inherit'
    }
  },
  mobileDisplay: {
    display: 'inherit',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  content: {
    marginTop: '65px',
    flex: 1,
    marginLeft: '8px',
    marginRight: '8px'
  },
  toolbar: {
    padding: '0px'
  }, 
  ajuda: {
    fontWeight: 600,
    lineHeight: '14px',
    fontSize: '12px',
    color: theme.palette.text.clear,
  },
  icon: {
    color: theme.palette.text.clear,
  },
  bookmark: {
    color: theme.palette.black.clear,
    [theme.breakpoints.up('sm')]: {
      color: theme.palette.text.clear
    }
  },
  titleContainer: {
    ...theme.typography.title,
    paddingTop: '4px',
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    // [theme.breakpoints.up('md')]: {
    //   display: 'flex'
    // }
  },
  titleContainerMobile: {
    ...theme.typography.body3,
    lineHeight: '1.1em',
    color: theme.palette.text.clear,
    paddingTop: '4px',
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  menuIcons: {
    display: 'flex',
    color: theme.palette.common.white,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  dialog: {
    display: 'flex',
    alignItems: 'stretch',
    minHeight: '100vh',
    width: '100%',
  },
  slide: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: '100%'
  },
  drawer: {
    
  },
  arrowLeft: {
    height: '40px',
    width: '40px',
    color: theme.palette.black.a3,
    [theme.breakpoints.up('sm')]: {
      height: '25px',
      width: '25px',
      color: theme.palette.text.clear
    },
  },
  titleMobile: {
    fontSize: '12px'
  },
  articleMobile:{ 
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '18px'
  },
  navigateMobile: {
    cursor: 'pointer'
  },
  disabled: {
    color: theme.palette.black.a1
  },
  bookmarked: {
    color: theme.palette.text.basic
  }
});

class ModalClipping extends Component {

  state = {
    mobileOpen: false,
    fichamento: false,
    getFichamentos: () => ''
  };

  closeDrawer = () => this.setState({ mobileOpen: false });

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
    
  componentDidMount() {
    if(this.props.open && this.props.open.clippingId) {
      this.props.crudGetOne(this.props.open.clippingId)
    }
  }

  transition = (props) => {
    return <Slide direction="up" {...props} className={this.props.classes.slide}  />;
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.open && (!this.props.open || (this.props.open && this.props.open.clippingId !== nextProps.open.clippingId))){
      this.props.crudGetOne(nextProps.open.clippingId)
    }
  }

  getArrayOfArticles = (sections = []) => {

    let arrayArticles = []
    let count = 0
    sortBy(sections || [], 'order').map(({name, articles = []}) => {
      return sortBy(articles, 'order').map((article) => {
        count++
        return arrayArticles[count] = {...article, section: name, number: count}
      })
    })

    return {arrayArticles, total: count}
  }

  openNext = (_id, trigger) => {
    const { handleOpen, clipping = {}, trackMetric } = this.props;

    if(_id) {
      handleOpen({ clippingId: clipping._id, articleId: _id });
      this.scrollTop();

      trackMetric('Clippings - Ativação', {
        trigger,
        target: 'pagination',
        type: clipping.type,
        resource: _id,
        parentResource: clipping._id
      });
    }
  }

  scrollTop = () => {
    const divRef = document.getElementById('clippingModal')
    divRef && divRef.scrollTo(0, 0)
  }

  handleModalFichamento = (open) => {
    const { clipping = {} } = this.props;

    open && this.props.trackMetric('Clippings - Ativação', {
      target: 'notebook',
      trigger: 'click',
      type: clipping.type,
      resource: this.props.open.articleId,
      parentResource: this.props.open.clippingId
    });

    this.setState({fichamento: open});
  }

  configGetFichamentos = (getFichamentos) => {
    this.setState({getFichamentos})
  }

  onKeyDown = (event) => {
    if(event.keyCode === 39) { //next
      const {idNextArticle} = this.getDataArticle()
      if(idNextArticle)
        this.openNext(idNextArticle, 'keyboard')
    }
    if(event.keyCode === 37) { //back
      const {idPreviousArticle} = this.getDataArticle()
      if(idPreviousArticle)
        this.openNext(idPreviousArticle, 'keyboard')
    }
  }

  getDataArticle = () => {
    let selectedData
    const {open = {}, clipping = {}} = this.props
    const {arrayArticles, total} = this.getArrayOfArticles(clipping.sections)

   
    open.articleId && clipping.sections ? (
      selectedData = _.find(arrayArticles, {_id: open.articleId})
    ) : selectedData = {} 

    const idPreviousArticle = selectedData.number > 1 ? arrayArticles[selectedData.number - 1]._id : false
    const idNextArticle = selectedData.number < total ? arrayArticles[selectedData.number + 1]._id : false
    return { selectedData, total, idNextArticle, idPreviousArticle }
  }
 
  render () {
    const { open = {}, handleOpen, classes, clipping = {}, bookmarks, handleBookmark, concurso } = this.props;
    const { fichamento, getFichamentos } = this.state;
    const date = moment(clipping.pubDate, "YYYY-MM-DD");
    
    const { selectedData, total, idNextArticle } = this.getDataArticle();
    
    const sections = sortBy(clipping.sections || [], 'order');
    sections.forEach((section) => {
      section.articles = sortBy(section.articles, 'order');
    })

    return (
      <Dialog
          id="clippingModal"
          open={Boolean(open)}
          handleOpen={handleOpen}
          handleKeyboard={this.onKeyDown}
        >
        <AppBar className={classes.appBar} position='fixed'>
          <Toolbar className={classes.toolbar}>
            <div className={classes.backContainer}>
              <Button
                disableRipple
                disableFocusRipple
                aria-label="voltar"
                onClick={() => handleOpen(false)}
                classes={{root: classes.backIcon}}
              >
                <ArrowLeft className={classes.arrowLeft}/> <div className={classes.responsiveDisplay}>voltar</div>
              </Button>
            </div>
            <div className={classes.titleContainer}>
              {Boolean(clipping.name) ? clipping.name : Boolean(date) ? `CLIPPING ${date.format('DD/MM/YYYY')}` : ''}
            </div>
            <div className={classes.menuIcons}>
              {!['TRT'].includes(concurso.name) && (
                <Tooltip title="Ler depois" enterDelay={300} className={classes.mobileDisplay}>
                  <IconButton
                    aria-label="change direction"
                    onClick={() => handleBookmark({resourceId: selectedData._id, title: selectedData.title}, !Boolean(bookmarks[selectedData._id]))}
                  > 
                    {bookmarks[selectedData._id] ? <BookmarkFullIcon className={classNames(classes.bookmark, bookmarks[selectedData._id] ? classes.bookmarked : '')}/> :
                    <BookmarkIcon className={classNames(classes.bookmark, bookmarks[selectedData._id] ? classes.bookmarked : '')} />}
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </Toolbar>
        </AppBar>
          <Drawer
            scrollTop={this.scrollTop}
            pushRouter={handleOpen}
            location={location}
            className={classes.drawer}
            disablePermanent={false}
            onRequestClose={this.handleDrawerToggle}
            mobileOpen={this.state.mobileOpen}
            closeDrawer={this.closeDrawer}
            items={sections}
            type={clipping.type}
          />
          <ClippingContent 
            {...selectedData} date={date} totalArticles={total}
            idNextArticle={idNextArticle} openNext={this.openNext} type={clipping.type}
            handleModalFichamento={this.handleModalFichamento} configGetFichamentos={this.configGetFichamentos}
            handleBookmark={handleBookmark}  bookmark={bookmarks[selectedData._id]} />
          <ModalFicharConteudo open={fichamento} handleOpen={this.handleModalFichamento} clippingId={clipping._id}
            getFichamentos={getFichamentos} title={selectedData.title} _id={selectedData._id}/>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, {open = {}} ) => {
  return {
    user: state.user.profile,
    clipping: state.clipping.data[open.clippingId],
    concurso: state.concurso.active
  }
}

const mapDispatchToProps = {
  crudGetOne: (_id) => crudGetOneAction('clipping', _id, '', false, true, {$embed: ['sections.articles']}),
  pushRouter: (path) => push(path),
  trackMetric: (name, data) => trackMetricAction(name, data)
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ModalClipping);
