import { SHOW_ALERT, HIDE_ALERT } from '../actions/alertActions';

const defaultState = {
    text: '',
    type: 'info', // one of 'info', 'confirm', 'warning'
};

export default (previousState = defaultState, { type, payload }) => {
    switch (type) {
    case SHOW_ALERT:
        return { text: payload.text, type: payload.type };
    case HIDE_ALERT:
        return { ...previousState, text: '' };
    default:
        return previousState;
    }
};
