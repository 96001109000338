import React, { useState } from "react";
import styled from "styled-components";
import Dialog from "material-ui/Dialog";
import DialogContent from "material-ui/Dialog/DialogContent";
import { withStyles } from "material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PaymentSlider from "../../../components/PaymentSlider";
import { Close, ArrowBack } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import CreditPaymentModal from "./CreditPaymentModal";
import ChooseCard from "./ChooseCard";
import FinancialResponsible from "./FinancialResponsible";
import RegisterForm from "../../Checkout/Register";
import ConfirmExit from "../../Checkout/ConfirmExit";

import profileIcon from "../../../assets/img/profileIcon.png";

import { TextField, InputAdornment } from "@material-ui/core";

import { setChooseCardOpen, setPaymentModalOpen } from "../actions";
import { openRegister } from "../../Checkout/actions";

const styles = {
  root: {
    position: "relative",
    padding: 0,
    paddingTop: 0,
    display: "block",
    height: "500px",
    width: "650px",
    // maxWidth: ""
    "&:first-of-type": {
      paddingTop: 0,
    },
  },
  rootResponsive: {
    position: "relative",
    padding: 0,
    paddingTop: 0,
    display: "block",
    height: "100vh",
    width: "100vw",
    // maxWidth: ""
    "&:first-of-type": {
      paddingTop: 0,
    },
  },
  rootOtherOption: {
    position: "relative",
    padding: 0,
    paddingTop: 0,
    display: "block",
    height: "fit-content",
    width: "650px",
    // maxWidth: ""
    "&:first-of-type": {
      paddingTop: 0,
    },
  },
  inputFieldStart: {
    width: "100%",
    "& label.Mui-focused": {
      color: "rgba(0,0,0, 0.6)",
    },
    "& label": {
      fontFamily: "Proxima Nova",
    },
    "&:hover label": {
      color: "rgba(0,0,0, 0.6)",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "rgba(0,0,0, 0.6)",
      },
      "&.Mui-focused fieldset": {
        fontFamily: "Proxima Nova",
        borderColor: "rgba(0,0,0, 0.6)",
      },
    },
  },
  inputFieldCorrect: {
    width: "100%",
    "& label.Mui-focused": {
      color: "rgba(0,0,0, 0.5)",
    },
    "& label": {
      color: "#31c400",
      fontFamily: "Proxima Nova",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#31c400",
      },
      "&:hover fieldset": {
        borderColor: "#31c400",
      },
      "&.Mui-focused fieldset": {
        fontFamily: "Proxima Nova",
        borderColor: "#31c400",
      },
    },
  },
};

const PaymentHeaderResponsive = styled.div`
  display: flex;
  position: relative;
  width: 100vw;
  height: 70px;
  justify-content: center;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const CloseContainerResponsive = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
`;

const ClassInfoGroupResponsive = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  margin-top: 24px;
`;

const CourseGroupResponsive = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CourseNameResponsive = styled.p`
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0px;
  width: fit-content;
  margin: 0px;
  align-self: flex-end;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const ModuleNumberResponsive = styled.div`
  margin-left: 10px;
  padding: 2px 8px;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  height: 16px;
  width: fit-content;
  align-self: center;
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 14px;
  text-align: center;
`;

const ClassInfoResponsive = styled.p`
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0px;
  width: 100%;
  margin: 6px 0px 0px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  align-self: center;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const PaymentModalContainer = styled.div`
  position: relative;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: 0px 22px 10px 0px rgba(0, 0, 0, 0.07);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CloseContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
`;

const ClassGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${(props) => (props.responsive ? "145px" : "170px")};
  border-bottom: 1px solid rgb(229, 229, 229);
  margin-top: 34px;
`;

const TeacherImage = styled.div`
  align-self: center;
  position: relative;
  display: flex;
  height: ${(props) => (props.responsive ? "58px" : "72px")};
  width: ${(props) => (props.responsive ? "62px" : "77px")};
`;

const TeacherDollarSign = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: ${(props) => (props.responsive ? "20px" : "24px")};
  height: ${(props) => (props.responsive ? "20px" : "24px")};
  color: rgb(65, 205, 151);
`;

const MainAsk = styled.span`
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  font-size: ${(props) => (props.responsive ? "17px" : "24px")};
  font-weight: bold;
  height: fit-content;
  letter-spacing: 0px;
  margin: ${(props) =>
    props.responsive ? "15px 0px 9px 0px" : "16px 0px 12px 0px"};
  align-self: center;
`;

const ClassInfoGroup = styled.div`
  width: 470px;
  display: flex;
  justify-content: space-between;
  align-self: center;
`;

const CourseName = styled.p`
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  font-size: 15px;
  font-weight: normal;
  height: 20px;
  letter-spacing: 0px;
  line-height: 20px;
  width: 180px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const ModuleNumber = styled.div`
  margin-left: 10px;
  padding: 2px 8px;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  height: 18px;
  width: 70px;
  align-self: center;
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 16px;
  text-align: center;
`;

const ClassInfo = styled.p`
  padding-left: 16px;
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  font-size: 15px;
  font-weight: bold;
  height: 20px;
  letter-spacing: 0px;
  width: 280px;
  line-height: 20px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const PaymentSliderWrapper = styled.div`
  width: 100%;
  max-width: ${(props) => props.responsive && "600px"};
  height: fit-content;
  margin: ${(props) => props.responsive && "48px 0px"};
  padding: ${(props) => (props.responsive ? "0px 32px" : "40px 86px")};
  display: flex;
  flex-direction: column;
  align-content: center;
`;

const PaymentCurrentValue = styled.span`
  width: fit-content;
  color: rgba(0, 0, 0, 0.45);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: 500;
  height: 16px;
  letter-spacing: 0px;
  line-height: 16px;
  text-align: center;
  align-self: center;
`;

const PaymentFixedValuesGroup = styled.div`
  width: 100%;
  height: ${(props) => (props.responsive ? "16px" : "20px")};
  display: flex;
  justify-content: space-between;
`;

const PaymentFixedValue = styled.span`
  color: rgba(0, 0, 0, 0.5);
  font-family: "Proxima Nova";
  font-size: ${(props) => (props.responsive ? "13px" : "15px")};
  font-weight: 600;
  height: ${(props) => (props.responsive ? "16px" : "20px")};
  letter-spacing: 0px;
  line-height: ${(props) => (props.responsive ? "16px" : "20px")};
  text-align: center;
`;

const PayButton = styled.button`
  border: 0px;
  width: 100%;
  height: 40px;
  max-width: 400px;
  border-radius: 20px;
  background: rgb(252, 121, 84);
  color: rgb(255, 255, 255);
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
`;

const OtherOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: fit-content;
  height: 16px;
  margin-top: 16px;
  align-self: center;
`;

const OtherOptionText = styled.span`
  cursor: pointer;
  padding: 0px 8px;
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: 500;
  height: 13px;
  letter-spacing: 0px;
  text-align: center;
  width: fit-content;
  border-right: ${(props) =>
    props.last ? "none" : "1px solid rgba(0, 0, 0, 0.75)"};
`;

const OtherValueContainer = styled.div`
  background: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: 0px 22px 10px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const OtherValueHeader = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
`;

const OtherValueHeaderText = styled.span`
  color: rgba(0, 0, 0, 0.7);
  font-family: "Proxima Nova";
  font-size: 20px;
  font-weight: bold;
  width: fit-content;
  height: 24px;
  letter-spacing: 0px;
  text-align: center;
`;

const CloseOtherValue = styled.div`
  width: fit-content;
  height: fit-content;
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
`;

const OtherValueContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px 80px 0px 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const OtherValueQuestion = styled.span`
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: 600;
  height: 16px;
  letter-spacing: 0px;
  line-height: 16px;
  width: fit-content;
  text-transform: uppercase;
  align-self: flex-start;
`;

const InputContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 48px;
  width: 100%;
  height: 76px;
  display: flex;
  flex-direction: column;

  & > div.Mui-focused {
    border-color: #31c400 !important;
  }
`;

const ErrorLog = styled.p`
  margin: 0px;
  font-family: "Proxima Nova";
  height: fit-content;
  width: fit-content;
  align-self: left;
  font-size: 14px;
  padding-left: 6px;
  color: #f22424;
  margin-top: 4px;
`;

const PayOtherValueButton = styled.button`
  height: 40px;
  width: 248px;
  margin-bottom: 95px;
  background: rgb(252, 121, 84);
  border-radius: 20px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
`;

function PaymentModal({
  classes,
  open,
  imageUrl = "",
  moduleNumber,
  courseName,
  classNumber,
  className,
  pacoteID,
  price,
}) {
  const dispatch = useDispatch();

  const { data: userData } = useSelector((state) => state.userData);
  const openCreditPayment = useSelector(
    (state) => state.paymentGroup.creditPaymentModal.open
  );

  function goToCreditPaymentModal() {
    if (userData.address.length) {
      if (
        !userData.address[0].zipcode ||
        !userData.address[0].city ||
        !userData.address[0].street ||
        !userData.address[0].street_number ||
        !userData.address[0].neighborhood ||
        !userData.address[0].state ||
        !userData.mobile ||
        !userData.cpf
      ) {
        dispatch(openRegister());
      } else {
        dispatch(setPaymentModalOpen(false));
        dispatch(setChooseCardOpen(true));
      }
    } else {
      dispatch(openRegister());
    }
  }

  const [value, setValue] = useState(60);
  const [otherValue, setOtherValue] = useState(60);
  const [validNumber, setValidNumber] = useState("initial");
  const [valueError, setValueError] = useState({
    error: false,
    message: "",
  });
  const [showOtherOption, setShowOtherOption] = useState(false);

  function handleInputChange({ target }) {
    if (target.value < 0) {
      setValueError({
        error: true,
        message: `O valor deve ser positivo. O valor mínimo para essa é R$${price}`,
      });
      setValidNumber(false);
    } else if (target.value < price) {
      setValueError({
        error: true,
        message: `O valor mínimo para essa aula é R$${price}`,
      });
      setValidNumber(false);
    } else {
      setValueError({
        error: false,
        message: "",
      });
      setValidNumber(true);
    }
    setOtherValue(target.value);
  }

  const responsive = useSelector((state) => state.plataform.responsive);

  return (
    <div>
      <Dialog
        id="paymentDialog"
        scroll={"paper"}
        open={open}
        fullScreen={responsive}
        maxWidth={"xl"}
      >
        <DialogContent
          id="content"
          classes={{
            root: showOtherOption
              ? classes.rootOtherOption
              : responsive
              ? classes.rootResponsive
              : classes.root,
          }}
          dividers={false}
        >
          {showOtherOption ? (
            <OtherValueContainer>
              <OtherValueHeader>
                <CloseOtherValue>
                  <IconButton
                    style={{
                      color: "rgba(0, 0, 0, 0.4)",
                      width: "24px",
                      height: "24px",
                      padding: "0px",
                    }}
                    aria-label="fechar"
                    onClick={() => {
                      setOtherValue(60);
                      setShowOtherOption(false);
                    }}
                  >
                    <ArrowBack />
                  </IconButton>
                </CloseOtherValue>
                <OtherValueHeaderText>Pagar outro valor</OtherValueHeaderText>
              </OtherValueHeader>
              <OtherValueContent>
                <OtherValueQuestion>quanto valeu essa aula?</OtherValueQuestion>
                <InputContainer>
                  <TextField
                    classes={{
                      root:
                        validNumber === "initial"
                          ? classes.inputFieldStart
                          : validNumber === true && classes.inputFieldCorrect,
                      // : classes.inputFieldStart,
                    }}
                    variant="outlined"
                    id="qpqOtherValue"
                    name="value"
                    type="number"
                    label="Valor"
                    value={otherValue}
                    error={valueError.error}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      ),
                    }}
                    onChange={(e) => handleInputChange(e)}
                  />
                  {valueError.error && (
                    <ErrorLog>{valueError.message}</ErrorLog>
                  )}
                </InputContainer>
                <PayOtherValueButton
                  onClick={() =>
                    valueError.error
                      ? window.alert("Insira um valor válido para prosseguir")
                      : goToCreditPaymentModal()
                  }
                >
                  Pagar
                </PayOtherValueButton>
              </OtherValueContent>
            </OtherValueContainer>
          ) : (
            <PaymentModalContainer>
              {responsive ? (
                <PaymentHeaderResponsive>
                  <MainAsk responsive={true}>Quanto valeu minha aula?</MainAsk>
                  <CloseContainerResponsive>
                    <IconButton
                      style={{
                        color: "#FC7954",
                        width: "24px",
                        height: "24px",
                        padding: "0px",
                      }}
                      aria-label="fechar"
                      onClick={() => {
                        setValue(60);
                        dispatch(setPaymentModalOpen(false));
                      }}
                    >
                      <Close />
                    </IconButton>
                  </CloseContainerResponsive>
                </PaymentHeaderResponsive>
              ) : (
                <CloseContainer>
                  <IconButton
                    style={{
                      color: "rgba(0, 0, 0, 0.4)",
                      width: "24px",
                      height: "24px",
                      padding: "0px",
                    }}
                    aria-label="fechar"
                    onClick={() => {
                      setValue(60);
                      dispatch(setPaymentModalOpen(false));
                    }}
                  >
                    <Close />
                  </IconButton>
                </CloseContainer>
              )}
              <ClassGroup responsive={responsive}>
                <TeacherImage responsive={responsive}>
                  <img
                    src={imageUrl ? imageUrl : profileIcon}
                    style={{
                      width: "72px",
                      height: "72px",
                      alignSelf: "flex-end",
                      borderRadius: "50%",
                    }}
                  ></img>
                  <TeacherDollarSign responsive={responsive}>
                    <MonetizationOnIcon
                      style={{ width: "100%", height: "100%" }}
                    />
                  </TeacherDollarSign>
                </TeacherImage>
                {!responsive && (
                  <MainAsk responsive={false}>Quanto valeu minha aula?</MainAsk>
                )}
                {responsive ? (
                  <ClassInfoGroupResponsive>
                    <CourseGroupResponsive>
                      <CourseNameResponsive>{courseName}</CourseNameResponsive>
                      <ModuleNumberResponsive>
                        Mód.{" "}
                        {moduleNumber < 10 ? `0${moduleNumber}` : moduleNumber}
                      </ModuleNumberResponsive>
                    </CourseGroupResponsive>
                    <ClassInfoResponsive>
                      {classNumber} - {className}
                    </ClassInfoResponsive>
                  </ClassInfoGroupResponsive>
                ) : (
                  <ClassInfoGroup>
                    <CourseName>{courseName}</CourseName>
                    <ModuleNumber>
                      Mód.{" "}
                      {moduleNumber < 10 ? `0${moduleNumber}` : moduleNumber}
                    </ModuleNumber>
                    <ClassInfo>
                      {classNumber} - {className}
                    </ClassInfo>
                  </ClassInfoGroup>
                )}
              </ClassGroup>
              <PaymentSliderWrapper responsive={responsive}>
                <PaymentCurrentValue>{`R$${value},00`}</PaymentCurrentValue>
                <PaymentSlider
                  min={20}
                  max={100}
                  value={value}
                  setValue={setValue}
                />
                <PaymentFixedValuesGroup responsive={responsive}>
                  <PaymentFixedValue
                    responsive={responsive}
                  >{`R$20,00`}</PaymentFixedValue>
                  <PaymentFixedValue
                    responsive={responsive}
                  >{`R$60,00`}</PaymentFixedValue>
                  <PaymentFixedValue
                    responsive={responsive}
                  >{`R$100,00`}</PaymentFixedValue>
                </PaymentFixedValuesGroup>
              </PaymentSliderWrapper>
              <PayButton onClick={() => goToCreditPaymentModal()}>
                Pagar R${value},00
              </PayButton>
              <OtherOptions onClick={() => setShowOtherOption(true)}>
                <OtherOptionText last={true}>Pagar outro valor</OtherOptionText>
              </OtherOptions>
            </PaymentModalContainer>
          )}
        </DialogContent>
      </Dialog>
      <RegisterForm />
      <ConfirmExit />
      <FinancialResponsible />
      <ChooseCard
        pacoteID={pacoteID}
        amount={showOtherOption ? Number(otherValue) : Number(value)}
      />
      <CreditPaymentModal
        open={openCreditPayment}
        qpq_price={showOtherOption ? Number(otherValue) : Number(value)}
        pacoteID={pacoteID}
      />
    </div>
  );
}

export default withStyles(styles)(PaymentModal);
