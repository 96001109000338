import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from 'material-ui/styles';
import Paper from 'material-ui/Paper';
import Switch from 'material-ui/Switch'
import { Button } from '../../components/common';
import Tooltip from 'material-ui/Tooltip';
import HelpIcon from 'material-ui-icons/HelpOutline';
import Cached from 'material-ui-icons/Cached';
import upload from '../../utils/upload';
import moment from 'moment';
import { crudUpdate as crudUpdateAction, crudCreate as crudCreateAction } from '../../actions/dataActions';
import { createAudio as createAudioAction } from '../../actions/extraActions'
const RecordRTC = require('recordrtc');

const styles = theme => ({
  container: {
    padding: '16px',
    maxWidth: '900px',
    marginBottom: '16px',
    '& pre': {
      '&:first-child': {
        marginTop: '0',
      },
      margin: '8px 0',
      whiteSpace: 'pre-line',
      fontFamily: theme.typography.fontFamily2,
    },
    '& div': {
      fontFamily: theme.typography.fontFamily2
    },
    '& h3': {
      marginBottom: '8px'
    }
  },
  recordingContainer: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  score: {
    marginLeft: '0px',
    fontSize: '20px'
  },
  link: {
    ...theme.typography.link
  },
  textArea: {
    width: '100%',
    padding: '8px',
    borderRadius: '3px',
    border: '1px solid rgba(0,0,0,0.1)',
    marginBottom: '14px',
    fontSize: '15px',
    marginTop: '16px',
    '&:focus': {
      height: '306px',
    }
  },
  input: {
    borderRadius: '3px',
    border: '1px solid rgba(0,0,0,0.1)',
    fontSize: '15px',
    padding: '8px'
  },
  shareButton: {
    marginLeft: 'auto',
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center'
  },
  audioDisabled: {
    opacity: '0.5'
  },
  timer: {
    marginLeft: '10px',
    fontSize: '18px'
  },
  helpIcon: {
    marginLeft: '6px',
    color: 'rgba(0,0,0,.6)',
    cursor: 'pointer'
  },
  cached:{
    fontSize: theme.typography.pxToRem(24),
    width: '1em',
    height: '1em',
    // Chrome fix for https://bugs.chromium.org/p/chromium/issues/detail?id=820541
    // To remove at some point.
    overflow: 'hidden',
    flexShrink: 0,
    webkitAnimation: "girar  1.5s linear infinite",
          animation: "rotate 1.5s linear infinite"
  },
  '@keyframes girar': {
    transform: 'rotate(360deg);'
  }
})

var recordRTC = null;
var microphone = null;

class CardItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audioComments:   [],
      newAudioComment: false,
      score: isNaN(props.score)? false : props.score,
      comments: props.comments || '',
      recording: false,
      timerSeconds: 0,
      timer: null,
      waiting: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    let { audioComments = [], score = '', comments = '' } = nextProps;

    if(this.state.audioComments !== audioComments
      || this.state.score !== score
      || this.state.comments !== comments)
    {
      this.setState({ audioComments, score, comments });
    }
  }

  startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      microphone = stream;

      recordRTC = RecordRTC(stream, {
        recorderType: RecordRTC.MediaStreamRecorder,
        mimeType: 'audio/webm'
      });

      var timer = setInterval(() => {
        var { timerSeconds } = this.state;
        this.setState({ timerSeconds: timerSeconds + 1 });
      }, 1000);

      recordRTC.startRecording();
      this.setState({ timer, recording: true });
    });
  }

  stopRecording = () => {
    const { question, user } = this.props;
    const { timer } = this.state;

    recordRTC.stopRecording(() => {
      microphone.stop();
      clearInterval(timer);

      this.setState({ timerSeconds: 0, recording: false });

      var container = 'user-media-for-streaming';
      var fileName = `question-${question._id}/user-${user._id}/comment-${Date.now()}.webm`;
      var blob = recordRTC.getBlob();

      var file = new File([blob], `comment-${Date.now()}.webm`, {
        type: 'audio/webm'
      });

      upload(container, file, fileName, (error) => {
        if (!error) {
          var blobUrl = `https://avatars.blob.core.windows.net/${container}/${fileName}`;
          this.setState({ newAudioComment: blobUrl });
        }
        else {
          alert('Ocorreu um erro com o upload do arquivo...');
        }
      });
    });
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  }

  sendAudio = () => {
    var { createAudio, _id } = this.props;
    var { newAudioComment} = this.state;
    createAudio( _id,newAudioComment);
  }

  sendComments = () => {
    var { crudUpdate, _id } = this.props;
    var {comments, score } = this.state;
    var data = {comments: comments };
    Boolean(score) && (data.score = score);
    crudUpdate('questionEssayAnswer', _id, data, {}, '', false);
  }

  handleShare = () => {
    var { crudUpdate, _id } = this.props;
    var confirmation = confirm("Tem certeza que deseja compartilhar a sua nota com outros usuários do clipping? Essa ação não pode ser desfeita.");

    if(confirmation) {
      var data = { shared: true };
      crudUpdate('questionEssayAnswer', _id, data, {}, '', false);
    }
  }
  componentWillReceiveProps(nextProps){
      if(this.props.waiting != nextProps.waiting ){
        this.setState({waiting : nextProps.waiting})
        if(nextProps.saved){
          this.setState({newAudioComment : false})

        }
      }
  }

  parseTimer = (timerSeconds) => {
    var integer = Math.floor(timerSeconds / 60);
    var decimals = timerSeconds % 60;
    return `${('00' + integer).slice(-2)}:${('00' + decimals).slice(-2)}`;
  }

  showFile = file =>{
    var fileName = file && file.split("/").slice(-1);
    let type = file.split('.').slice(-1);
    if(type=="pdf"){
      return (<embed src={`${file}#toolbar=0&navpanes=0&sc=1`} width="100%" height="600px" />)
    }else if(type == "jpeg" || type == "jpg" || type == "png "){
       return (<embed src={file} style={{width:'100%'}} />)
    }

    return (<a href={file} target="_blank"> {fileName} </a>)

  }

  render() {
    const { classes, introduction, bullets, conclusion, files,createdAt, question, item, user, shared, type } = this.props;
    const { audioComments, newAudioComment, recording, score, comments, timerSeconds, waiting } = this.state;

    var fileName = files[0] && files[0].split('/').slice(-1);
    var valid = Boolean(audioComments) || Boolean(comments);
    var isCorrecao = type === 'redacao-correcao';
    return (
      <div>
        <Paper className={classes.container}>
            {( user._id == this.props.loggedUser) && <h2  style={{color : "#F8A947"}}>Sua Redação </h2>}
            {isCorrecao && <h5 style={{color:"gray"}}> Enviado em {moment(createdAt).format('DD/MM/YYYY [às] HH:mm') } - {moment().diff(moment(createdAt),'h')} horas atrás</h5>}
            <div dangerouslySetInnerHTML={{__html: question.statement}} />
            <div dangerouslySetInnerHTML={{__html: item.text}} />

            <h3>Resposta</h3>

            {Boolean(introduction) && (
              <div>
                <pre>{introduction}</pre>
                <pre>{bullets}</pre>
                <pre>{conclusion}</pre>
              </div>
            )}

            {Boolean(files[0]) && (
              <div>

                  {this.showFile(files[0])}
              </div>
            )}
            { this.props.audioComments.map((audio,i) =>{
               return (<p><audio id={i}  controls src={audio}></audio></p>)
            }) }
            {!isCorrecao &&   <hr/> }
            {!isCorrecao && !((typeof score) == "number") && <div>Correção Pendente
              <Tooltip className={classes.helpIcon}
                title="Não é possível tornar a resposta pública caso ainda esteja pendente de correção ou tenha sido avaliada com nota zero.">
                <HelpIcon/>
              </Tooltip>
            </div>}
            {!isCorrecao && (typeof score) == "number" && <div className={classes.score}>{score} pts
              <Tooltip className={classes.helpIcon}
                title="Não é possível tornar a resposta pública caso ainda esteja pendente de correção ou tenha sido avaliada com nota zero.">
                <HelpIcon/>
              </Tooltip>
            </div>}

            {!isCorrecao && !shared && Boolean(score) && (
              <div className={classes.shareButton}>
                <Switch checked={false} onChange={this.handleShare}/>
                Tornar pública
                <Tooltip className={classes.helpIcon}
                  title="Deixe sua correção pública para ver outras respostas">
                  <HelpIcon/>
                </Tooltip>
              </div>
            )}


          {(Boolean(comments)) && (
            <textarea placeholder="Comentar por texto (opcional)"
              onChange={this.handleChange('comments')}
              value={comments}
              disabled
              className={classes.textArea} rows="20"></textarea>
          )}



        </Paper>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    crudUpdate: (...params) => {
      dispatch(crudUpdateAction(...params));
    },
    createAudio: (_id,newAudioComment) => {
      dispatch(createAudioAction(_id,{newAudioComment:newAudioComment}, false));
    }
  }
}
const mapStateToProps = (state, {open} ) => {
  return {
    waiting: state.waiting,
    saved: state.waiting
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(CardItem);
