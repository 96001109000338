import { 
    SYNC_COMMENTS,
    CLEAR_COMMENT_LIST,
    SYNC_COMMENT_REMOVED,
    LOGIN_DISQUS
} from '../actions/commentsActions'

const initialState = {list: {}, disqus_auth: ''}

export default (previousState = initialState, { type, payload }) => {
    switch (type) {
    case SYNC_COMMENTS: 
        return { ...previousState, list: {...previousState.list, ...payload } }
    case CLEAR_COMMENT_LIST:
        return { initialState }
    case SYNC_COMMENT_REMOVED:
        delete previousState.list[payload]
        return { ...previousState, list: {...previousState.list} }
    case `${LOGIN_DISQUS}_SUCCESS`:
        return { ...previousState, disqus_auth: payload && payload.data && payload.data.auth, disqus_key: payload && payload.data && payload.data.pubKey }    
    default:
        return previousState;
    }
};
