import React, { useState } from 'react';
import axios from 'axios';

import {
  ReportErroContainer,
  StyledTextField,
  LoadingIndicator,
  FeedbackError,
} from './styles';
import { Button } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

function ReportErrorArea({ question, concursoId, user }) {
  const [message, setMessage] = useState('');
  const [sendLoagind, setSendloagind] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const { search } = useLocation();

  function handleChange(event) {
    setMessage(event.target.value);
  }

  async function handleSubmit() {
    try {
      setSendloagind(true);
      let urlParam;
      let url;

      const questionItemId = new URLSearchParams(search).get('questionItemId');

      if (search) {
        if (questionItemId) {
          if (questionItemId !== question._id)
            urlParam = `&questionItemId=${question._id}`;
        } else {
          urlParam = `&questionItemId=${question._id}`;
        }
      } else {
        urlParam = `?questionItemId=${question._id}`;
      }

      urlParam
        ? (url = document.location.href + urlParam)
        : (url = document.location.href);

      const form = {
        url: url,
        message: message,
        user: user._id,
        concursoId: concursoId,
      };

      const base_url = process.env.REACT_APP_API_BASE_URL;
      const token = localStorage.getItem('token');

      if (!token) throw new Error('Token não encontrado');

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios.post(`${base_url}/ticket`, form, { headers });

      setMessage('');
      setFeedback(true);
    } catch (error) {
      setSendloagind(false);
      console.log('Falha ao enviar ticket de questão', error);
    }
  }

  return (
    <ReportErroContainer>
      {feedback ? (
        <FeedbackError>
          <span>Obrigado!</span>
          <span>Seu feedback foi enviado para nossa equipe.</span>
        </FeedbackError>
      ) : (
        <React.Fragment>
          <span className='fontSize'>Encontrou algum erro?</span>
          <StyledTextField
            style={{ marginTop: '20px' }}
            name='message'
            disabled={sendLoagind}
            value={message}
            onChange={handleChange}
            label='Escreva uma mensagem'
            variant='outlined'
            size='small'
            fullWidth
            multiline
          />
          <Button
            variant='text'
            size='small'
            onClick={handleSubmit}
            disabled={message ? false : true}
          >
            {sendLoagind ? (
              <React.Fragment>
                <LoadingIndicator />
                Enviando...
              </React.Fragment>
            ) : (
              <span className='fontSize'>Enviar</span>
            )}
          </Button>
        </React.Fragment>
      )}
    </ReportErroContainer>
  );
}
export default ReportErrorArea;
