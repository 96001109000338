export const NEW_PAY_COURSE_CLASS = "NEW_PAY_COURSE_CLASS";
export const NEW_PAY_COURSE_CLASS_SUCCESS = "NEW_PAY_COURSE_CLASS_SUCCESS";
export const NEW_PAY_COURSE_CLASS_FAILURE = "NEW_PAY_COURSE_CLASS_FAILURE";

export const DEBITS_FETCH = "DEBITS_FETCH";
export const DEBITS_FETCH_SUCCESS = "DEBITS_FETCH_SUCCESS";
export const DEBITS_FETCH_FAILURE = "DEBITS_FETCH_FAILURE";

export const CURRENT_DEBIT_FETCH = "CURRENT_DEBIT_FETCH";
export const CURRENT_DEBIT_FETCH_SUCCESS = "CURRENT_DEBIT_FETCH_SUCCESS";
export const CURRENT_DEBIT_FETCH_FAILURE = "CURRENT_DEBIT_FETCH_FAILURE";

export const CREATE_DEBIT = "CREATE_DEBIT";
export const CREATE_DEBIT_SUCCESS = "CREATE_DEBIT_SUCCESS";
export const CREATE_DEBIT_FAILURE = "CREATE_DEBIT_FAILURE";

export const SAVE_SPONSOR = "SAVE_SPONSOR";
export const CLEAR_SPONSOR = "CLEAR_SPONSOR";
export const SUBMIT_SPONSOR_PERSONALDATA = "SUBMIT_SPONSOR_PERSONALDATA";
export const SUBMIT_SPONSOR_ADDRESS = "SUBMIT_SPONSOR_ADDRESS";
export const SHOW_SPONSOR_MODAL = "SHOW_SPONSOR_MODAL";
export const SET_SPONSOR_ID = "SET_SPONSOR_ID";

export const SET_PAYMENT_MODAL_OPEN = "SET_PAYMENT_MODAL_OPEN";
export const SET_PAYMENT_STATUS_MODAL_OPEN = "SET_PAYMENT_STATUS_MODAL_OPEN";
export const SET_CREDITPAY_MODAL_OPEN = "SET_CREDITPAY_MODAL_OPEN";
export const SET_CREDITPAY_MODAL_TITLE = "SET_CREDITPAY_MODAL_TITLE";
export const SET_CREDITPAY_MODAL_INSTALLMENTS =
  "SET_CREDITPAY_MODAL_INSTALLMENTS";

export const SET_CREDITPAY_MODAL_ITEMS = "SET_CREDITPAY_MODAL_ITEMS";

export const SET_CHOOSE_PAYMENT_OPEN = "SET_CHOOSE_PAYMENT_OPEN";
export const SET_CHOOSE_CARD_OPEN = "SET_CHOOSE_CARD_OPEN";
export const SET_CHOOSE_INSTALLMENTS_OPEN = "SET_CHOOSE_INSTALLMENTS_OPEN";

export const SET_BOLETO_MODAL_OPEN = "SET_BOLETO_MODAL_OPEN";
export const SET_BOLETO_URL = "SET_BOLETO_URL";
export const BOLETO_FETCH = "BOLETO_FETCH";
export const BOLETO_MODAL_FETCH_SUCCESS = "BOLETO_MODAL_FETCH_SUCCESS";
export const BOLETO_MODAL_FETCH_FAILURE = "BOLETO_MODAL_FETCH_FAILURE";

export const PAYMENT_FETCH = "PAYMENT_FETCH";
export const PAYMENT_FETCH_SUCCESS = "PAYMENT_FETCH_SUCCESS";
export const PAYMENT_FETCH_FAILURE = "PAYMENT_FETCH_FAILURE";

export const USER_PAYMENTS_FETCH = "USER_PAYMENTS_FETCH";
export const USER_PAYMENTS_FETCH_SUCCESS = "USER_PAYMENTS_FETCH_SUCCESS";
export const USER_PAYMENTS_FETCH_FAILURE = "USER_PAYMENTS_FETCH_FAILURE";

export const SET_LOADING_MODAL_OPEN = "SET_LOADING_MODAL_OPEN";

export const SET_LAST_PAYED = "SET_LAST_PAYED";
export const FETCH_PENDING_PAYMENTS = "FETCH_PENDING_PAYMENTS";
export const INIT_PENDING_PAYMENTS = "INIT_PENDING_PAYMENTS";
export const CLEAR_CURRENT_PAYMENT = "CLEAR_CURRENT_PAYMENT";
export const SHOULD_UPDATE_MASK = "SHOULD_UPDATE_MASK";

export const OPEN_FINANCIAL_RESPONSIBLE = "OPEN_FINANCIAL_RESPONSIBLE";

export const USER_CARD_DELETE_REQUEST = "USER_CARD_DELETE_REQUEST";
export const USER_CARD_DELETE_SUCCESS = "USER_CARD_DELETE_SUCCESS";
export const USER_CARD_DELETE_FAILURE = "USER_CARD_DELETE_FAILURE";
export const USER_CARD_CONFIRM_DELETE = "USER_CARD_CONFIRM_DELETE";
