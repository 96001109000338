import styled, { css } from "styled-components";
import { Checkbox } from '@material-ui/core';
import { components } from "react-select";

export const SelectButton = styled.button`
    font-family: Inter;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    background: white;
    border: none;
    border-radius: 16px;
    text-align: left;
    padding: 2px 16px;
    color: rgba(0, 0, 0, .4);
    position: relative;
    z-index: 2;

    & * {
        cursor: pointer;
    }

    ${({ open }) => open && css`
        outline-width: 1px;
        outline-style: solid;
        outline-color: rgb(220, 220, 220);

        &:hover {
            outline-width: 2px;
        }
    `}

    & > div {
        flex: 1;
        
        & > label {
            color: rgba(0, 0, 0, .6);
            font-weight: 600;
            font-size: 13px;
        }
    }

    & > svg {
        height: 24px;
        width: 24px;
    }

    &:disabled {
        cursor: default;
        opacity: .5;

        &, & * {
            cursor: no-drop;
        }
    }
`;

export const DropdownMenu = styled.div`
    background-color: #FFFFFF;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 0 0 1px hsla(218, 50%, 10%, 0.1), 0 4px 11px hsla(218, 50%, 10%, 0.1);
    margin-top: 8px;
    position: absolute;
    min-width: 300px;
    z-index: 100;

    ${({ responsive }) => responsive && css`
        width: 100%;
    `}
`;

export const CountBadge = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    background: rgba(0, 0, 0, .4);
    color: #FFFFFF;
    border-radius: 50%;
    font-size: 11px;
    margin-left: 6px;
`;

export const SearchIconContainer = styled.div`
    padding: 0 8px;
    color: rgba(0, 0, 0, .4);

    & > svg {
        width: 20px;
        height: 20px;
    }
`;

export const CustomOption = styled(components.Option)`
    ${({ isFocused }) => isFocused && css`
        background: rgba(0, 0, 0, .05) !important;
    `}

    ${({ isSelected }) => isSelected && css`
        background: rgba(0, 0, 0, .1) !important;
        color: #000000 !important;
    `}
`;

export const CustomSearchInput = styled(components.Input)`
    & > input {
        padding-left: 4px !important;

        &::placeholder {
            color: rgba(0, 0, 0, .5) !important;
        }
    }
`;

export const CustomCheckbox = styled(Checkbox)`
  padding: 0px;

  & svg {
    width: 16px;
    height: 16px;
  }

  ${({ checked }) => checked && css`
    color: rgb(252, 121, 84) !important;
  `}
`;

export const NoOptionsMessageRoot = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    color: rgba(0, 0, 0, .4);

    & > svg {
        height: 32px;
        width: 32px;
    }

    & > span {
        display: block;
        margin-top: 8px;
        font-size: 13px;
    }
`;