import { put, call, takeLatest, takeEvery, select } from "redux-saga/effects";
import find from "lodash/find";
import axios from "axios";
import {
  isMobileOnly,
  isTablet,
  osName,
  browserName,
} from "react-device-detect";
import {
  PLATAFORM_PARAMS,
  DOWNLOAD_PDF,
  TRACK_METRIC,
} from "../actions/plataformActions";
import { setConcurso } from "../actions/concursoActions";
import { GET_ONE } from "../services/rest_types";
import { crudCreate } from "../actions/dataActions";
import { CACD } from "../config/index";

const handlePlataform = (restClient) => {
  function* handleActions(action = {}) {
    const { payload, type } = action;

    switch (type) {
      case PLATAFORM_PARAMS:
        try {
          if (payload.custom) {
            const { concursoId } = JSON.parse(payload.custom);
            if (concursoId) {
              const concursos = yield select((state) => state.concurso.data);
              const findConcurso = find(concursos, { concursoId });
              yield findConcurso && put(setConcurso(findConcurso._id));
              window.history.pushState("", "", window.location.pathname);
            }
          }
        } catch (error) {}
        break;

      case DOWNLOAD_PDF:
        try {
          const response = yield call(
            restClient,
            GET_ONE,
            "clipping/download",
            { id: payload._id, noFilter: true }
          );
          window.open(response.data.url, "_blank");
        } catch (error) {}
        break;

      case TRACK_METRIC:
        try {
          const levels = yield select(
            (state) => state.user.profile.levels || []
          );
          const status = yield select(
            (state) => state.user.subscription.status
          );

          const concurso = yield select((state) => state.concurso.active._id);
          const filteredLevel = levels.filter(
            (x) => x.concurso === concurso
          )[0];

          payload.data = {
            ...payload.data,
            trialing: status === "trialing",
            level: filteredLevel && filteredLevel.level,
            isMobileOnly,
            isTablet,
            osName,
            browserName,
            resolution: `${window.innerWidth}x${window.innerHeight}`,
          };
          // const ELASTIC_URL = 'https://es.clipping.dev/events/_doc';

          // yield call(axios.post, ELASTIC_URL, payload);
        } catch (error) {
          const levels = yield select(
            (state) => state.user.profile.levels || []
          );
          const status = yield select(
            (state) => state.user.subscription.status
          );

          const concurso = CACD._id;
          const filteredLevel = levels.filter(
            (x) => x.concurso === concurso
          )[0];

          payload.data = {
            ...payload.data,
            trialing: status === "trialing",
            level: filteredLevel && filteredLevel.level,
            isMobileOnly,
            isTablet,
            osName,
            browserName,
            resolution: `${window.innerWidth}x${window.innerHeight}`,
          };
        }

        yield put(crudCreate("event", payload, "", false, "", false));
        break;

      // no default
    }
  }

  return function* watchPlaraformActions() {
    yield [
      takeLatest(
        (action) =>
          action.meta && action.meta.plataform && action.meta.cancelPrevious,
        handleActions
      ),
      takeEvery(
        (action) =>
          action.meta && action.meta.plataform && !action.meta.cancelPrevious,
        handleActions
      ),
    ];
  };
};

export default handlePlataform;
