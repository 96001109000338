import React from 'react';
import moment from 'moment';
import { TextCollapse } from '@clippingcacd/core';
import { AiOutlineLike } from 'react-icons/ai';
import { BiComment } from 'react-icons/bi';

import * as S from './styles';

const resourceString = {
  questions: 'questão',
};

export default function CommentCard({ comment }) {
  const cretedAt = moment(comment.comment.createdAt);
  const [, topicId] = comment.comment.topic.split('_');

  function hashTagSubject() {
    if (comment.question.subject) {
      return `#${comment.question.subject.name}`;
    }

    return '';
  }

  return (
    <S.Root href={`/questions?questionItemId=${topicId}`}>
      <S.CardHeader>
        <span id='comment-user'>
          {comment.user.name.split(' ').slice(0, 1).join(' ')}
        </span>{' '}
        comentou{' '}
        <span id='comment-text'>
          "
          <TextCollapse maxCharacters={150} considerWords={true}>
            {comment.comment.text}
          </TextCollapse>
          "
        </span>{' '}
        na questão
        <span id='comment-question'>
          {' '}
          "
          <TextCollapse maxCharacters={150} considerWords={true}>
            {String(comment.question.text).replace(/(<([^>]+)>)/gi, '')}
          </TextCollapse>
          "
        </span>{' '}
        <mark>#{resourceString[comment.comment.resource]}</mark>{' '}
        <mark>{hashTagSubject()}</mark>
      </S.CardHeader>
      <S.Footer>
        <S.ActionsContainer>
          <div>
            <button>
              <AiOutlineLike />
              {comment.comment.likes}
            </button>

            <button>
              <BiComment />
              {comment.comment.answers}
            </button>
          </div>

          <button>Comentar</button>
        </S.ActionsContainer>
        <S.CreateDate>{cretedAt.fromNow()}</S.CreateDate>
      </S.Footer>
    </S.Root>
  );
}
