import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentModalOpen, debitsFetchRequest } from "../Payments/actions";
import { ChevronRight } from "@material-ui/icons";

import profileIcon from "../../assets/img/profileIcon.png";

const PaymentBarsWrapper = styled.div`
  z-index: 6;
  position: fixed;
  bottom: ${(props) => (props.responsive ? "0px" : "24px")};
  left: ${(props) => props.responsive && "50%"};
  transform: ${(props) =>
    props.responsive ? "translateX(-50%)" : "translateX(-20px)"};
  @media (min-width: 1440px) {
    width: ${(props) => !props.responsive && "800px"};
    left: ${(props) => !props.responsive && "50%"};
    transform: ${(props) => !props.responsive && "translateX(-50%)"};
  }
`;

const PaymentBarContainer = styled.div`
  background: #fff;
  display: flex;
  padding: 16px 24px;
  width: 700px;
  @media (min-width: 1440px) {
    width: 100%;
  }
  height: 100px;
  border-radius: 8px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
  border-left: 8px solid rgb(252, 121, 84);
`;

const PaymentBarContainerResponsive = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 24px 16px 16px 16px;
  width: 100vw;
  height: fit-content;
  box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.3);
  justify-content: flex-end;
`;

const TeacherContainerResponsive = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  min-height: 80px;
  align-items: center;
`;

const TeacherImageResponsive = styled.div`
  position: relative;
  display: flex;
  width: 55px;
  height: 52px;
`;

const TeacherDollarSignResponsive = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
  height: 18px;
  color: rgb(65, 205, 151);
`;

const PaymentTextGroupResponsive = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  align-self: center;
`;

const MainAskResponsive = styled.p`
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  font-size: 17px;
  font-weight: bold;
  height: fit-content;
  letter-spacing: 0px;
  margin: 0;
`;

const ClassInfoGroupResponsive = styled.div`
  margin-top: 4px;
  width: fit-content;
  display: flex;
  flex-direction: column;
`;

const CourseGroupResponsive = styled.div`
  display: flex;
  width: fit-content;
  height: 18px;
`;

const CategoryNameResponsive = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: normal;
  height: 20px;
  letter-spacing: 0px;
  line-height: 20px;
  width: fit-content;
  margin: 0;
  padding-right: 4px;
  min-width: fit-content;
`;

const CourseNameResponsive = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: normal;
  height: 16px;
  letter-spacing: 0px;
  line-height: 16px;
  width: fit-content;
  align-self: center;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding-right: 4px;
`;

const ModuleNumberResponsive = styled.p`
  margin: 0;
  border-radius: 3px;
  width: fit-content;
  align-self: center;
  color: rgba(0, 0, 0, 0.5);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0px;
  text-align: center;
  min-width: fit-content;
`;

const ClassInfoResponsive = styled.p`
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: normal;
  height: 16px;
  letter-spacing: 0px;
  line-height: 16px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const PayButtonResponsive = styled.button`
  background: linear-gradient(
    -225deg,
    rgb(253, 87, 40) 0%,
    rgb(255, 128, 0) 100%
  );
  border-radius: 24px;
  box-shadow: 0px 25px 11px -20px rgba(255, 85, 0, 0.6);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: 600;
  height: 48px;
  width: 100%;
  max-width: 300px;
  color: white;
  text-align: center;
  text-transform: uppercase;
  border: none;
  position: relative;
  align-self: center;
`;

const PayButtonWrapperResponsive = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PayButtonDisabledResponsive = styled.button`
  cursor: not-allowed;
  height: 48px;
  width: 100%;
  background: rgb(187, 187, 187);
  border: none;
  border-radius: 24px;
  color: rgba(0, 0, 0, 0.35);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
`;

const PayButtonInfoResponsive = styled.span`
  margin-top: 16px;
  color: rgba(0, 0, 0, 0.5);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: 500;
  height: 16px;
  letter-spacing: 0px;
  line-height: 16px;
  text-align: center;
  width: fit-content;
`;

const TeacherContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TeacherImage = styled.div`
  position: relative;
  display: flex;
  width: 54px;
  height: 51px;
`;

const TeacherDollarSign = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
  height: 18px;
  color: rgb(65, 205, 151);
`;

const ClassPaymentGroup = styled.div`
  flex: 1;
  max-width: 700px;
  padding: 0px 24px 0px 16px;
  display: flex;
  justify-content: space-between;
`;

const ClassPaymentGroupTitle = styled.div`
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  font-size: 17px;
  font-weight: bold;
  height: fit-content;
  letter-spacing: 0px;
  margin: 0;
  margin-bottom: 4px;
`;

const ClassInfoGroup = styled.div`
  /* width: fit-content; */
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  /* max-width: 65%; */
`;

const CourseGroup = styled.div`
  display: flex;
  /* width: fit-content; */
  height: 20px;
`;

const CourseName = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: normal;
  height: 20px;
  letter-spacing: 0px;
  line-height: 20px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding-right: 4px;
`;

const CategoryName = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: normal;
  height: 20px;
  letter-spacing: 0px;
  line-height: 20px;
  /* width: fit-content; */
  min-width: fit-content;
  margin: 0;
  padding-right: 4px;
`;

const ModuleNumber = styled.p`
  margin: 0;
  width: fit-content;
  align-self: center;
  color: rgba(0, 0, 0, 0.5);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0px;
  text-align: center;
  min-width: fit-content;
`;

const ClassInfo = styled.p`
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  font-size: 15px;
  /* font-weight: bold; */
  height: 20px;
  letter-spacing: 0px;
  line-height: 20px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const PayButton = styled.button`
  background: linear-gradient(
    -225deg,
    rgb(253, 87, 40) 0%,
    rgb(255, 128, 0) 100%
  );
  border-radius: 24px;
  box-shadow: 0px 25px 11px -20px rgba(255, 85, 0, 0.6);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: 600;
  height: 48px;
  width: 210px;
  color: white;
  text-align: center;
  text-transform: uppercase;
  border: none;
  position: relative;
  align-self: center;
  @media (max-width: 1440px) {
    width: 180px;
  }
`;

const PayButtonWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PayButtonDisabled = styled.button`
  cursor: not-allowed;
  height: 48px;
  width: 229px;
  background: rgb(187, 187, 187);
  border: none;
  border-radius: 24px;
  color: rgba(0, 0, 0, 0.35);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
`;

const PayButtonInfo = styled.span`
  margin-top: 6px;
  color: rgba(0, 0, 0, 0.5);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: 500;
  height: 16px;
  letter-spacing: 0px;
  line-height: 16px;
  text-align: center;
  width: fit-content;
`;

export default function PaymentBar({
  imageUrl = "",
  moduleNumber,
  courseName,
  courseYear,
  classNumber,
  className,
  category,
  pacoteID,
}) {
  const dispatch = useDispatch();
  const [showYear, setShowYear] = useState(false);

  const courses = useSelector((state) => state.courseGroup.courses.data);

  const { pacotes } = useSelector(
    (state) => state.paymentGroup.pendingPayments
  );

  useEffect(() => {
    const coursesByCategory = courses.filter(
      (items) => items.categoria.id === category.id
    );

    for (let index = 0; index < coursesByCategory.length - 1; index++) {
      if (coursesByCategory[index].ano !== coursesByCategory[index + 1].ano) {
        setShowYear(true);
      }
    }
  }, []);

  const responsive = useSelector((state) => state.plataform.responsive);

  return (
    <PaymentBarsWrapper responsive={responsive}>
      {responsive ? (
        <PaymentBarContainerResponsive>
          <TeacherContainerResponsive>
            <TeacherImageResponsive>
              <img
                src={imageUrl ? imageUrl : profileIcon}
                style={{
                  width: "48px",
                  height: "48px",
                  alignSelf: "flex-end",
                  borderRadius: "50%",
                }}
              ></img>
              <TeacherDollarSignResponsive>
                <MonetizationOnIcon style={{ width: "100%", height: "100%" }} />
              </TeacherDollarSignResponsive>
            </TeacherImageResponsive>
            <PaymentTextGroupResponsive>
              <MainAskResponsive>Pagamento pendente</MainAskResponsive>
              <ClassInfoGroupResponsive>
                <CourseGroupResponsive>
                  <CategoryNameResponsive>
                    {category.abreviacao} {showYear && courseYear} /
                  </CategoryNameResponsive>
                  <CourseNameResponsive>{courseName}</CourseNameResponsive>
                  <ModuleNumberResponsive>
                    / Mód.{" "}
                    {moduleNumber < 10 ? `0${moduleNumber}` : moduleNumber}
                  </ModuleNumberResponsive>
                </CourseGroupResponsive>
                <ClassInfoResponsive>
                  {classNumber < 10 ? `0${classNumber}` : classNumber} -{" "}
                  {className}
                </ClassInfoResponsive>
              </ClassInfoGroupResponsive>
            </PaymentTextGroupResponsive>
          </TeacherContainerResponsive>
          {pacotes.includes(pacoteID) ? (
            <PayButtonWrapperResponsive>
              <PayButtonDisabledResponsive>
                Processando pagamento
              </PayButtonDisabledResponsive>
              <PayButtonInfoResponsive>
                Geralmente não leva mais que 5 minutos.
              </PayButtonInfoResponsive>
            </PayButtonWrapperResponsive>
          ) : (
            <PayButtonResponsive
              onClick={() => dispatch(setPaymentModalOpen(true))}
            >
              Pagar aula
              <ChevronRight
                style={{
                  color: "#fff",
                  width: "24px",
                  height: "24px",
                  position: "absolute",
                  transform: "translateY(-50%)",
                  right: "16px",
                  top: "50%",
                }}
              />
            </PayButtonResponsive>
          )}
        </PaymentBarContainerResponsive>
      ) : (
        <PaymentBarContainer>
          <TeacherContainer>
            <TeacherImage>
              <img
                src={imageUrl ? imageUrl : profileIcon}
                style={{
                  width: "48px",
                  height: "48px",
                  alignSelf: "flex-end",
                  borderRadius: "50%",
                }}
              ></img>
              <TeacherDollarSign>
                <MonetizationOnIcon style={{ width: "100%", height: "100%" }} />
              </TeacherDollarSign>
            </TeacherImage>
          </TeacherContainer>
          <ClassPaymentGroup>
            <ClassInfoGroup>
              <ClassPaymentGroupTitle>
                Pagamento pendente
              </ClassPaymentGroupTitle>
              <CourseGroup>
                <CategoryName>
                  {category.nome} {showYear && courseYear} /
                </CategoryName>
                <CourseName>{courseName}</CourseName>
                <ModuleNumber>
                  / Mód. {moduleNumber < 10 ? `0${moduleNumber}` : moduleNumber}
                </ModuleNumber>
              </CourseGroup>
              <ClassInfo>
                {classNumber < 10 ? `0${classNumber}` : classNumber} -{" "}
                {className}
              </ClassInfo>
            </ClassInfoGroup>
          </ClassPaymentGroup>
          {pacotes.includes(pacoteID) ? (
            <PayButtonWrapper>
              <PayButtonDisabled>Processando pagamento</PayButtonDisabled>
              <PayButtonInfo>
                Geralmente não leva mais que 5 minutos.
              </PayButtonInfo>
            </PayButtonWrapper>
          ) : (
            <PayButton onClick={() => dispatch(setPaymentModalOpen(true))}>
              Pagar aula
              <ChevronRight
                style={{
                  color: "#fff",
                  width: "24px",
                  height: "24px",
                  position: "absolute",
                  transform: "translateY(-50%)",
                  right: "16px",
                  top: "50%",
                }}
              />
            </PayButton>
          )}
        </PaymentBarContainer>
      )}
    </PaymentBarsWrapper>
  );
}
