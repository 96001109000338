import {
    CUSTOM
} from '../services/rest_types';

export const MATERIAL_CHECK_USER_STATUS = 'MATERIAL_CHECK_USER_STATUS';
export const CHANGE_EDITAL_TOPICS = 'CHANGE_EDITAL_TOPICS';
export const GET_EDITAL_TOPICS_QUESTION_PERFORMANCE = 'GET_EDITAL_TOPICS_QUESTION_PERFORMANCE';

export const materialCheckUserStatus = (material, resourceId, subject, week, resourceName, checked, concursoId) => ({
    type: MATERIAL_CHECK_USER_STATUS,
    payload: { data: checked ? { material, subject, resourceId, week, resourceName, concursoId } : { material, resourceId, resourceName, concursoId } },
    meta: { custom: true, customMethod: checked ? 'POST' : 'DELETE', resource: `biblioSubjectMaterialUser${checked ? '' : '/byresourceid'}`, cancelPrevious: false, fetchCustom: CUSTOM }
});

export const changeEditalTopics = (payload, editalTopic) => ({
    type: CHANGE_EDITAL_TOPICS,
    payload: { data: { ...payload, editalTopic } },
    meta: { custom: true, customMethod: 'POST', resource: 'editalTopicsUser/byeditaltopic', cancelPrevious: false, fetchCustom: CUSTOM }
});