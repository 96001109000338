import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import toastr from 'toastr'
import { hideAlert as hideAlertAction } from '../../actions/alertActions'

class Alert extends React.Component {
    componentDidMount() {
        const config = {
            onHidden : () => { this.props.hideAlert() },
            showMethod: 'slideDown'
        }   
        toastr.options = config
    }

    render() {
        const { type, message } = this.props;
        if(!!message) {
            if (type === 'warning') {
                toastr.warning(message)
            } else if (type === 'info') {
                toastr.success(message)
            } else if (type === 'error') {
                toastr.error(message)
            }
        }
        return null
    }
}

Alert.propTypes = {
    message: PropTypes.string,
    type: PropTypes.string.isRequired,
    hideAlert: PropTypes.func.isRequired
};

Alert.defaultProps = {
    type: 'info',
};

const mapStateToProps = state => ({
    message: state.alert.text,
    type: state.alert.type,
});

export default connect(
  mapStateToProps,
  { hideAlert: hideAlertAction },
)(Alert);
