import {
    CUSTOM
} from '../services/rest_types';

export const PAY_COURSE_CLASS = 'PAY_COURSE_CLASS';
export const PAY_COURSE_CLASS_LOADING = 'PAY_COURSE_CLASS_LOADING';
export const PAY_COURSE_CLASS_FAILURE = 'PAY_COURSE_CLASS_FAILURE';
export const PAY_COURSE_CLASS_SUCCESS = 'PAY_COURSE_CLASS_SUCCESS';

export const PAY_COURSE_CLASS_PAGARME = 'PAY_COURSE_CLASS_PAGARME';
export const PAY_COURSE_CLASS_PAGARME_LOADING = 'PAY_COURSE_CLASS_PAGARME_LOADING';
export const PAY_COURSE_CLASS_PAGARME_FAILURE = 'PAY_COURSE_CLASS_PAGARME_FAILURE';
export const PAY_COURSE_CLASS_PAGARME_SUCCESS = 'PAY_COURSE_CLASS_PAGARME_SUCCESS';

export const MORE_INFO_MODAL_SHOW = 'MORE_INFO_MODAL_SHOW';
export const MORE_INFO_MODAL_HIDE = 'MORE_INFO_MODAL_HIDE';

export const ABOUT_COURSE_MODAL_SHOW = 'ABOUT_COURSE_MODAL_SHOW';
export const ABOUT_COURSE_MODAL_HIDE = 'ABOUT_COURSE_MODAL_HIDE';

export const payCourseClass = (data, id) => ({
    type: PAY_COURSE_CLASS,
    payload: { data },
    meta: {
        custom: true,
        resource: `courseClass/${id}/iugu/payment`,
        fetchCustom: CUSTOM,
        customMethod: 'POST'
    }
});

export const payCourseClassPagarme = (data, id) => ({
    type: PAY_COURSE_CLASS_PAGARME,
    payload: { data },
    meta: {
        custom: true,
        resource: `courseClass/${id}/payment`,
        fetchCustom: CUSTOM,
        customMethod: 'POST'
    }
});

export const showMoreInfo = (enrollmentInfo) => ({
    type: MORE_INFO_MODAL_SHOW,
    payload: { open: true, enrollmentInfo },
});

export const hideMoreInfo = () => ({
    type: MORE_INFO_MODAL_HIDE,
    payload: { open: false, enrollmentInfo: null },
});

export const showAboutCourse = ({ courseName, courseInfo }) => ({
    type: ABOUT_COURSE_MODAL_SHOW,
    payload: {
        open: true,
        courseName,
        courseInfo,
    }
});

export const hideAboutCourse = () => ({
    type: ABOUT_COURSE_MODAL_SHOW,
    payload: {
        open: false,
        courseInfo: null,
        courseName: null,
    }
});