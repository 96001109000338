/**
 *
 * @param {String} resource
 * @param {Object} concurso
 * @param {String} concurso.name
 * @returns {String}
 */
export function titleFor(resource, concurso) {
  const data = {
    bibliografia: "Plano de Estudos",
    edital: ["TRT"].includes(concurso.name) ? "Edital" : "Edital Esquematizado",
    ciclo: "Ciclo",
  };

  return data[resource];
}

export function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-.]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}
