import React, { Component } from 'react'

class MainActions extends Component {
  render () {
    const { children } = this.props
    return ( 
      <div className="content--main-actions">
        {
          children
          //children.map((component, id) => React.cloneElement(component, {key: id, ...component.props, action: true}))
        }
      </div>
    )
  }
}

export default MainActions