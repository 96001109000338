export const CHECKOUTS_OPEN = "CHECKOUTS_OPEN";
export const CHECKOUTS_CLOSE = "CHECKOUTS_CLOSE";

export const CHECKOUTS_FETCH = "CHECKOUTS_FETCH";
export const CHECKOUTS_FETCH_FAILURE = "CHECKOUTS_FETCH_FAILURE";
export const CHECKOUTS_FETCH_SUCCESS = "CHECKOUTS_FETCH_SUCCESS";

export const CHECKOUT_FETCH = "CHECKOUT_FETCH";
export const CHECKOUT_FETCH_FAILURE = "CHECKOUT_FETCH_FAILURE";
export const CHECKOUT_FETCH_SUCCESS = "CHECKOUT_FETCH_SUCCESS";

export const CHECKOUT_SET_DATA = "CHECKOUT_SET_DATA";
export const CHECKOUT_SET_GOOGLEGA = "CHECKOUT_SET_GOOGLEGA";
export const CHECKOUT_SET_URL = "CHECKOUT_SET_URL";
export const CHECKOUT_SET_VALUE = "CHECKOUT_SET_VALUE";

export const CART_SET_PRODUCTS = "CART_SET_PRODUCTS";

export const RESET_CHECKOUT = "RESET_CHECKOUT";

export const OPEN_CONFIRM_EXIT = '@register/OPEN_CONFIRM_EXIT';
export const OPEN_REGISTER = '@register/OPEN_REGISTER';
export const CLOSE_REGISTER = '@register/CLOSE_REGISTER';

export const STEP_LOADING = '@register/STEP_LOADING';
export const SET_STEPS = '@register/SET_STEPS';
export const NEXT_STEP = '@register/NEXT_STEP';
export const PREVIOUS_STEP = '@register/PREVIOUS_STEP';

export const SUBMIT_ADDRESS = '@register/SUBMIT_ADDRESS';
export const SUBMIT_PERSONAL_DATA = '@register/SUBMIT_PERSONAL_DATA';
