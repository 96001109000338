import styled from 'styled-components';

export const Container = styled.div`
  width: calc(100vw-15px);
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  flex-direction: column;
  margin-top: 24px;
`;

export const ClassTabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100vw-15px);
  align-items: center;
`;

export const ClassTabsContent = styled.div`
  display: flex;
  width: calc(100vw-15px);
  padding-top: 32px;
  align-items: center;
`;

export const TabsContainer = styled.div`
  flex: 1;
  height: 35px;
  display: flex;
  width: 400px;
  align-items: center;
  border-color: #d8d8d8;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  justify-content: center;
  @media (max-width: 900px) {
    width: 200px;
  } ;
`;

export const Tab = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: 14px;
  min-height: 34px;
  text-transform: initial;
  background-color: ${(props) => (props.active ? '#D8D8D8' : '#F2EEED')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  &:hover {
    background-color: #d8d8d8;
  }
`;

export const TabName = styled.span`
  height: 16px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  font-family: 'Proxima Nova';
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  cursor: pointer;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  max-width: 770px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 720px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
  }

  font-family: 'Inter' !important;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  & p {
    margin: 0px;
    font-size: 22px;
    line-height: 32px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.75);

    @media (max-width: 720px) {
      font-size: 18px;
      line-height: 28px;
    }

    & b {
      font-weight: 700;
    }
  }

  & span {
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.4);
  }

  @media (max-width: 720px) {
    text-align: center;
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px;

  height: 44px;

  background: #eae3e1;
  border-radius: 16px;

  @media (max-width: 720px) {
    margin-bottom: 16px;
    height: 48px;
  }
`;

export const SwitchBtn = styled.div`
  cursor: pointer;
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: ${({ current }) => (current ? '#ffffff' : 'transparent')};
  color: ${({ current }) =>
    current ? 'rgba(0, 0, 0, 0.75)' : 'rgba(0, 0, 0, 0.4)'};

  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
