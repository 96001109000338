import React from 'react';
// import PropTypes from 'prop-types';
import { GenericSelect } from './Filters'

const Input = ({input, inputType = 'input', className, options, onChangeSort, width, fixMargin, label}) => {
  switch (inputType) {
    case 'select':
      return <GenericSelect options={options} {...input} onChangeSort={onChangeSort} width={width} fixMargin={fixMargin}/>
    case 'custom':
      return <input {...input} id={input.name} className={className} />
    case 'comment':
      return <textarea {...input} id={input.name}  cols="30" rows="5" ></textarea>
    case 'commentChild':
      return <textarea {...input} id={input.name}  cols="20" rows="1" className="comment-child" autoFocus></textarea>  
    case 'form':
      return (
        <div className="form-control-wrap">
            <label htmlFor="user-nome" className="form-label">{label}</label>
            <input {...input} id={input.name} />
        </div>
      ) 
    default:
      return (
        <div className="form-group">
          <div className="form-control-wrap">
              <input {...input} id={input.name} />
          </div>
        </div>
      )
  }
};

export default Input;