import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import userReducer from './userReducer';
import notificationReducer from './notificationReducer';
import paymentSliderModalReducer from './paymentSliderModalReducer';
import loadingReducer from './loadingReducer';
import savingRedux from './savingRedux';
import resourceReducer from './resource';
import referencesReducer from './references';
import waitingReducer from './waitingReducer';
import commentsReducer from './commentsReducer';
import modalReducer from './modalReducer';
import sectionReducer from './sectionReducer';
import alertReducer from './alertReducer';
import concursoReducer from './concursoReducer';
import themeReducer from './themeReducer';
import textEditorReducer from './textEditorReducer';
import questionsCommentsReducer from './questionsCommentsReducer';
import clippingChatReducer from './clippingChatReducer';

import coursePackageReducer from './coursePackageReducer';
import plataformReducer from './plataformReducer';
import loadingReportReducer from './loadingReportReducer';
import globalSettingReducer from './globalSettingReducer';
import subsPackReducer from './subsPackReducer';
// import localeReducer from './localeReducer'
import notificationBarReducer from './notificationBarReducer';
import courseReducer from './courseReducer';
// import courseGroupReducer from './courseGroupReducer';

import courseGroupReducer from '../containers/Cursos/reducer';
import paymentGroupReducer from '../containers/Payments/reducer';
import userDataReducer from '../containers/User/reducer';
import courseSelectedReducer from './courseSelectedReducer';
import checkoutReducer from '../containers/Checkout/reducer';
import testsReducer from '../containers/Simulados/reducer';

export default (resources = []) => {
  const resourceReducers = {};
  resources.forEach((resource) => {
    resourceReducers[resource] = resourceReducer(resource);
  });

  return combineReducers({
    ...resourceReducers,
    routing: routerReducer,
    user: userReducer,
    form: formReducer,
    notification: notificationReducer,
    loading: loadingReducer,
    saving: savingRedux,
    references: referencesReducer,
    waiting: waitingReducer,
    comments: commentsReducer,
    modal: modalReducer,
    globalModal: paymentSliderModalReducer,
    section: sectionReducer,
    alert: alertReducer,
    concurso: concursoReducer,
    theme: themeReducer,
    coursePackage: coursePackageReducer,
    textEditor: textEditorReducer,
    plataform: plataformReducer,
    dataReport: loadingReportReducer,
    globalSetting: globalSettingReducer,
    subsPackModal: subsPackReducer,
    notificationBar: notificationBarReducer,
    courseEnrollment: courseReducer,
    courseGroup: courseGroupReducer,
    courseSelected: courseSelectedReducer,
    paymentGroup: paymentGroupReducer,
    userData: userDataReducer,
    checkout: checkoutReducer,
    tests: testsReducer,
    commentsQuestion: questionsCommentsReducer,
    clippingChat: clippingChatReducer,
  });
};
