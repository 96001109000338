import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "react-router-redux";
import styled from "styled-components";

import IconButton from "material-ui/IconButton";
import ExpandMore from "material-ui-icons/ExpandMore";

import { Icon, InlineIcon } from "@iconify/react";
import School from "@iconify/icons-simple-line-icons/graduation";

import {
  setExpandedCourseCard,
  courseDataFetchRequest,
  courseModalAction,
} from "./actions";
import { ExpandLessRounded } from "@material-ui/icons";

const CollapsableCardHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 40px 24px 40px;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  border-bottom-left-radius: ${(props) =>
    props.removeBorderRadius ? "0px" : "8px"};
  border-bottom-right-radius: ${(props) =>
    props.removeBorderRadius ? "0px" : "8px"};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  /* min-height: 88px; */
  height: fit-content;
  width: 665px;
  font-family: "Proxima Nova";
  margin: 10px auto;
  margin-bottom: ${(props) => (props.removeBorderRadius ? "0px" : "16px")};
  box-shadow: ${(props) =>
    props.removeBorderRadius
      ? "inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1)"
      : ""};
  cursor: pointer;
  user-select: none;
  @media (max-width: 578px) {
    max-width: 92vw;
    padding-left: 16px;
    padding-right: 24px;
  }
`;

const CollapsableCardBody = styled.div`
  display: ${(props) => (props.expanded ? "flex" : "none")};
  flex-direction: column;
  align-items: flex-start;
  /* padding: 32px 0px 40px 96px; */
  background: rgb(255, 255, 255);
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  width: 665px;
  font-family: "Proxima Nova";
  margin: 0px auto;
  margin-bottom: 16px;
  @media (max-width: 578px) {
    max-width: 92vw;
  }
`;

const CollapsableCardTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 32px;
  flex: 1;
  user-select: none;
  @media (max-width: 578px) {
    padding-left: 16px;
  }
`;

const CollapsableCardTitleCourse = styled.div`
  color: rgba(0, 0, 0, 0.7);
  font-family: "Proxima Nova";
  font-size: 20px;
  font-weight: bold;
  /* height: 24px; */
  letter-spacing: 0px;
  user-select: none;
  @media (max-width: 578px) {
    /* max-width: 224px; */
  }
`;

const CollapsableCardTitleContest = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: 500;
  /* height: 16px; */
  letter-spacing: 0px;
  line-height: 16px;
  text-transform: uppercase;
`;

const CollapsableCardCourseList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 32px 0px 40px 96px;
  @media (max-width: 578px) {
    padding: 24px 8px 24px 32px;
  }
`;

const CollapsableCardCourseItem = styled.li`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  font-weight: 600;
  font-size: 17px;
  min-height: 32px;
  max-height: 40px;
  cursor: pointer;
  margin-bottom: 4px;
  user-select: none;
  &:hover {
    font-weight: bold;
  }
`;

function CollapsableCard({ expanded, title, contest, year, courses }) {
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.userData.data.id);
  const expandedCard = useSelector((state) => state.courseGroup.expandedCard);

  function actionCreator(payload) {
    dispatch(courseDataFetchRequest(payload, profile));
  }

  function handleExpandedCard() {
    if (expandedCard === `${title}-${year}`) {
      dispatch(setExpandedCourseCard({ payload: "" }));
    } else {
      dispatch(setExpandedCourseCard({ payload: `${title}-${year}` }));
    }
  }

  return (
    <div>
      <CollapsableCardHeader
        removeBorderRadius={expanded}
        onClick={handleExpandedCard}
      >
        <Icon
          icon={School}
          color={"rgb(252, 121, 84)"}
          width={"24px"}
          height={"24px"}
        />
        <CollapsableCardTitle>
          <CollapsableCardTitleContest>
            {contest} {year}
          </CollapsableCardTitleContest>
          <CollapsableCardTitleCourse>{title}</CollapsableCardTitleCourse>
        </CollapsableCardTitle>
        <IconButton
          style={{
            height: "24px",
            width: "24px",
            transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
          }}
          aria-label="Fechar"
          onClick={handleExpandedCard}
        >
          <ExpandMore />
        </IconButton>
      </CollapsableCardHeader>
      <CollapsableCardBody expanded={expanded}>
        <CollapsableCardCourseList expanded={ExpandLessRounded}>
          {courses
            .sort((courseA, courseB) =>
              courseA.name.localeCompare(courseB.name)
            )
            .map((course) => {
              return (
                <CollapsableCardCourseItem
                  key={course.id}
                  onClick={() => actionCreator(course)}
                >
                  {course.name}
                </CollapsableCardCourseItem>
              );
            })}
        </CollapsableCardCourseList>
      </CollapsableCardBody>
    </div>
  );
}

export default CollapsableCard;
