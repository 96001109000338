import styled from 'styled-components';
import { TextCollapse, Button } from '@clippingcacd/core';

export const Root = styled.div`
  width: 100%;
`;

export const CommentsList = styled.div`
  & > :not(:first-child) {
    margin-top: 16px;
  }
`;

export const Comment = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.surface.orangeGray};
  border-radius: 8px;
  padding: 24px;
`;

export const CommentHeader = styled.div`
  display: flex;
  line-height: 20px;

  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 12px;

    & > label {
      font-weight: normal;
      color: #222222;
    }

    & > span {
      color: ${({ theme }) => theme.colors.grayscale.grayMedium};
    }
  }
`;

export const Message = styled(TextCollapse).attrs({
  maxCharacters: 300,
  label: {
    onCollapsed: "Ver mais",
    onUncollapsed: "Ver menos",
  },
})`
  color: #717171;
  white-space: pre-line;
  font-size: 14px;

  & > button {
    border: none;
    color: #2574ce;
    padding: 0px;
    margin-top: 8px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;

  & > :not(:first-child) {
    margin-left: 8px;
  }
`;

export const CommentParent = styled.div`
  margin-top: ${({ answer }) => (answer ? '16px' : '0')};
  margin-left: ${({ answer }) => (answer ? '24px' : '0')};
`;
export const ContentComment = styled.div`
  margin-left: ${({ responsive, answer }) =>
    responsive || !answer ? '0' : '10%'};
`;

export const LineDividerParent = styled.div`
  width: 100%;
  height: 0px;
  border: 1px solid #e0e0e0;
  margin-top: 24px;
`;

export const LoadMoreButton = styled(Button)`
  margin-top: 20px;
  height: 40px;
  width: ${({ primary, responsive }) =>
    primary ? '100%' : responsive ? '81%' : '50%'};
  border-radius: 8px;
  outline-color: #cccccc;
  padding: 8px 16px;
  align-self: center;
  color: #585858;
  &:hover {
    color: #585858;
    outline-color: black;
  }
`;

export const AnswersContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;

export const EmptySeach = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 16px;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.7);
  padding: 32px;
  height: 80px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 28px;
  width: 100%;
  height: 50%;
`;

export const AvatarContainer = styled.div`
  max-width: 40px;
  max-height: 40px;
`;
