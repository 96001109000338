import React from 'react';
import { TopicsArea } from './styles';

export function TopicosEdital({ subjects = '', topics = [] }) {
  return (
    <React.Fragment>
      {subjects.map((subject) => {
        const topicsEdital = topics.filter((topic) => {
          if (topic.subject === subject._id) {
            return topic;
          }
        });
        const topicsString = topicsEdital
          .map(
            (topic) =>
              `${topic.orderName} - ${topic.name.replace('.', '')}` || ''
          )
          .join('/ ');
        return (
          <TopicsArea>
            <span key={subject._id}>{subject.name} ∙ </span>
            <p>{topicsString}</p>
          </TopicsArea>
        );
      })}
    </React.Fragment>
  );
}
