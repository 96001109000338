// @flow

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import List from 'material-ui/List';
import Drawer from 'material-ui/Drawer';
import Hidden from 'material-ui/Hidden';
import AppDrawerNavItem from './NavItem';
import _ from 'lodash'
import FormatAlignLeft from 'material-ui-icons/FormatAlignLeft'

const styles = theme => {
  return({
  paper: {
    width: '305px',
    backgroundColor: '#F2EEED',
    top: '65px',
    paddingTop: '16px',
    paddingLeft: '32px',
    paddingRight: '16px',
    paddingBottom: '75px',
    border: '1px solid rgba(151, 151, 151, 0.3)',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  title: {
    color: theme.palette.text.clear,
    display: 'flex',
    fontSize: '12px',
    fontWeight: '600',
    marginTop: '40px',
    paddingLeft: '16px',
    marginBottom: '24px',
  },
  titleText: {
    paddingTop: '5px',
    paddingLeft: '8px'
  },
  titleIcon: {
    color: theme.palette.text.clear,
    height: '20px',
    width: '20px'
  },
  toolbarIe: {
    display: 'flex',
  },
  toolbar: {
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  anchor: {
    color: theme.palette.text.secondary,
  },
})};

function renderNavItems({location, pushRouter, closeDrawer}, items = [], classes) {
  let number = 0;
  return <List>
    {_.map(items, item => {
        return (
          <div className={classes.navItem} key={item._id}>
            <AppDrawerNavItem key={number}
              location={location}
              pushRouter={pushRouter}
              closeDrawer={closeDrawer}
              {...item} />
          </div>
        )
      })}
  </List>;
}

function AppDrawer(props, context) {
  const { classes, className, disablePermanent, mobileOpen, onRequestClose, items } = props;

  const drawer = (
    <div className={classes.nav}>
      <div className={classes.title}>
        <FormatAlignLeft className={classes.titleIcon}/> <div className={classes.titleText}>ÍNDICE DO EDITAL</div>
      </div>
      {renderNavItems(props, items, classes)}
    </div>
  );

  return (
    <div className={className}>
      <Hidden mdUp={!disablePermanent}>
        <Drawer
          classes={{
            paper: classNames(classes.paper, 'algolia-drawer')
          }}
          type="temporary"
          open={mobileOpen}
          onRequestClose={onRequestClose}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      {disablePermanent ? null : (
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.paper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      )}
    </div>
  );
}

AppDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  disablePermanent: PropTypes.bool.isRequired,
  mobileOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

AppDrawer.contextTypes = {
  activePage: PropTypes.object,
};

export default withStyles(styles)(AppDrawer);
