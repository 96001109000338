import React, { PureComponent } from 'react';
import {connect} from 'react-redux';
import {crudGetListByOne as crudGetListByOneAction} from '../../actions/dataActions';
import _ from 'lodash'; 

class FetchListByOne extends PureComponent {
  constructor(props) {
      super(props);
      this.state = { key: 0 };
  }

  componentWillReceiveProps(nextProps) {
    const {crudGetListByOne, _id, resource, concurso, data, stateRefresh} = this.props
    if (nextProps.concurso !== concurso 
        || nextProps.stateRefresh !== stateRefresh) {
        crudGetListByOne(resource, _id)
    }
    if (nextProps.data !== data && this.fullRefresh) {
        this.fullRefresh = false;
        this.setState({ key: this.state.key + 1 });
    }
  }

  refresh = (event) => {
    event.stopPropagation();
    this.fullRefresh = true;
    this.updateData();
  }

  updateData = () => {
    const {crudGetListByOne, _id, resource} = this.props
    if(_id) {
      crudGetListByOne(resource, _id)
    }
  }

  componentDidMount() {
      this.updateData()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps._id === this.props._id
     && nextProps.resource === this.props.resource
     && nextProps.concurso === this.props.concurso
     && nextProps.stateRefresh === this.props.stateRefresh
     && nextProps.data === this.props.data) {
        return false;
    }

    if(Boolean(this.props.keepStateOnRefresh)
     && nextProps.list.ids.length === 0) {
       return false;
     }

    return true;
  }

  render() {
    const {children} = this.props
    return (
      children && React.cloneElement(children, this.props)
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  const resourceState = state[ownProps.resource];
  const idList = resourceState.list.ids.map(String)
  const data = _.keys(resourceState.data)
      .filter(_id => idList.includes(_id))
      .reduce((obj, _id) => {
          return {
          ...obj,
          [_id]: resourceState.data[_id]
          };
      }, {});

  return {
      ...resourceState,
      data,
      concurso: state.concurso.active
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    crudGetListByOne: (resource, _id) => crudGetListByOneAction(dispatch, resource, _id)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FetchListByOne)
