import React, { useCallback } from "react";
import compose from "recompose/compose";
import { useDispatch } from "react-redux";

import { withStyles } from "material-ui/styles";

import Close from "material-ui-icons/Close";
import Check from "material-ui-icons/Check";

import { trackMetric } from "../../actions/plataformActions";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: "63px",
  },
  list: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  title: {
    ...theme.typography.body3,
    fontSize: "15px",
    color: theme.palette.text.clear,
    textAlign: "center",
    paddingTop: "18px",
  },
  question: {
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: "17.5px",
    height: "39px",
    width: "133px",
    marginTop: "8px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    cursor: "pointer",
  },
  item: {
    ...theme.typography.small,
    fontSize: "11px",
    color: theme.palette.text.clear,
    fontWeight: "bold",
    position: "relative",
    right: "-10px",
  },
  verRelatorio: {
    textAlign: "center",
    margin: "24px auto",
    ...theme.typography.body3,
    color: theme.palette.orange,
    borderBottom: "1px solid #9797974d",
    paddingBottom: "16px",
    maxWidth: "160px",
    cursor: "pointer",
  },
  notaContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "150px",
    margin: "0 auto",
    borderBottom: "1px solid rgba(151, 151, 151, 0.3)",
    paddingBottom: "20px",
  },
  nota: {
    ...theme.typography.display1,
    fontWeight: "bold",
  },
  notaText: {
    ...theme.typography.body1,
    color: theme.palette.text.basic,
    marginTop: "-8px",
  },
  check: {
    color: "#36C463",
    width: "20px",
    height: "20px",
  },
  close: {
    color: "#E05061",
    width: "20px",
    height: "20px",
  },
  icon: {
    position: "relative",
    right: "-30px",
  },
  blank: {
    color: theme.palette.text.clear,
    width: "20px",
    height: "20px",
  },
});

const QuestionList = ({ test, classes }) => {
  const dispatch = useDispatch();

  const scrollToQuestionItem = useCallback(
    (indexQuestions, indexItem) => {
      dispatch(
        trackMetric("Simulados - Ativação", {
          target: "navigation",
          trigger: "click",
          type: test.type,
          parentResource: test.questions[indexQuestions]._id,
          resource: test.questions[indexQuestions].items[indexItem]._id,
          test: test,
          source: "gabarito",
        })
      );

      let elem = document.getElementById(
        test.questions[indexQuestions].items[indexItem]._id
      );

      elem?.scrollIntoView({ block: "start", behavior: "smooth" });
    },
    [dispatch, test]
  );

  let sequence = 0;

  const renderIcon = (question) => {
    if (
      !question?.userAnswer ||
      (question?.userAnswer === "B" && question.type === "right_wrong") ||
      question.correct?.toLowerCase() === "x"
    ) {
      return <div className={classes.blank}></div>;
    } else if (question.correct === question.userAnswer) {
      return <Check className={classes.check} />;
    } else {
      return <Close className={classes.close} />;
    }
  };

  if (!test?.questions) {
    return <></>;
  }

  return (
    <div className={classes.container}>
      <div className={classes.notaContainer}>
        <div className={classes.nota}>{test?.testAnswer?.score}</div>
        <div className={classes.notaText}>sua nota</div>
      </div>
      <div className={classes.title}> Folha de respostas</div>
      <div className={classes.list}>
        {test.questions.map((question, questionIndex) =>
          question.items.map((questionItem, questionItemIndex) => {
            sequence++;

            return (
              <div
                key={question._id}
                className={classes.question}
                onClick={() =>
                  scrollToQuestionItem(questionIndex, questionItemIndex)
                }
              >
                <div className={classes.item}>ITEM {sequence}</div>
                <div className={classes.icon}>{renderIcon(questionItem)}</div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default compose(withStyles(styles))(QuestionList);
