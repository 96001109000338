import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';

import styled from 'styled-components';

import { createDebitRequest } from '../../Payments/actions';
import { redirectToCheckoutAction } from '../../Cursos/actions';
import { setCartProducts } from '../../Checkout/actions';

import VideoTextWithButton from './VideoTextWithButton';
import VideoTextWithIcon from './VideoTextWithIcon';

import aulaBackground from '../../../assets/img/aula_background.png';

const FakeVideoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.responsive ? '100vw' : '665px')};
  height: ${(props) => (props.responsive ? '56.25vw' : '375px')};
  background-image: ${(props) =>
    props.background
      ? `url(${aulaBackground})`
      : `url(${props.videoThumbnail})`};
  background-color: rgba(0, 0, 0, 0.5);
  background-position: center;
  background-size: cover;
`;

function FakeVideo({ responsive }) {
  const dispatch = useDispatch();
  const info = useSelector((state) => state.courseGroup.classInfo.data);

  const [masktype, setMasktype] = useState('');
  const [background, setBackground] = useState(false);
  const [data, setData] = useState({});

  const { showLive, availableDate, time, qpq, type } = info;

  const perfil = useSelector((state) => state.userData.data.id);

  const { webinar_id: liveID, id: aula } = useSelector(
    (state) => state.courseGroup.currentClass.data
  );
  const {
    id: modulo,
    value,
    pacotes_modulo,
    more_info,
  } = useSelector((state) => state.courseGroup.currentModule.data);
  const curso = useSelector((state) => state.courseGroup.currentCourse.data.id);
  const videoThumbnail = useSelector(
    (state) => state.courseGroup.currentModule.data.thumbnail
  );

  const checkout = useSelector((state) => state.courseGroup.currentCourse.data);

  const body = {
    perfil,
    curso,
    modulo,
    aula,
  };

  const ENROLLEDFOR_LIVE = 'ENROLLEDFOR_LIVE';
  const LOCKED_CLASS = 'LOCKED_CLASS';
  const EXPIRED_CLASS = 'EXPIRED';
  const PROCESSING_LIVE = 'PROCESSING_LIVE';

  function watchLiveClass(payload, showLive, liveID, qpq) {
    window.open(`https://zoom.us/j/${liveID}`, '_blank');
    if (!showLive && qpq) {
      dispatch(createDebitRequest(payload));
    }
  }

  function registerDebit(payload) {
    dispatch(createDebitRequest(payload));
  }

  function redirectToCheckout() {
    dispatch(redirectToCheckoutAction(true));
    if (pacotes_modulo.length) {
      dispatch(
        setCartProducts({
          items: [{ id: pacotes_modulo[0].id }],
          price: value,
        })
      );
    }

    if (checkout.checkout_url !== undefined) {
      return dispatch(push(`/checkout/${checkout.checkout_url}`));
    } else {
      return dispatch(push(`/checkout`));
    }
  }

  useEffect(() => {
    switch (type) {
      case 'expired':
        setData({
          text: `GRAVAÇÃO EXPIRADA\nEM ${availableDate}`,
          tipo: EXPIRED_CLASS,
        });
        setMasktype('icon');
        setBackground(true);

        break;
      case 'hasDebits':
        setData({
          text: 'Para ter acesso a essa aula é preciso\nque você pague outra(s) aula(s) que já assistiu.',
          tipo: LOCKED_CLASS,
        });
        setMasktype('icon');
        setBackground(false);
        break;
      case 'notEnrolled':
        setData({
          text: 'Para ter acesso às aulas desse módulo\nvocê precisa se matricular.',
          tipo: LOCKED_CLASS,
          buttonText: 'Matricule-se Aqui',
          // footer: more_info ? "Mais informações sobre a matrícula" : "",
          footer: '',
          icon: true,
          action: () => redirectToCheckout(),
        });
        setMasktype('button');
        setBackground(false);
        break;
      case 'available':
        setData({
          text: 'Gravação disponível, clique para assistir agora.',
          footer: '',
          buttonText: 'Liberar Gravação',
          action: () => registerDebit(body),
        });
        setMasktype('button');
        setBackground(false);
        break;
      case 'live':
        setData({
          text: 'Aula acontecendo agora, entre para assistir.',
          footer: '',
          buttonText: 'Entrar na sala',
          action: () => watchLiveClass(body, showLive, liveID, qpq),
        });
        setMasktype('button');
        setBackground(false);
        break;
      case 'processing':
        setData({
          text: 'Aula ao vivo encerrada.\nA gravação está em processamento e será liberada em breve.',
          tipo: PROCESSING_LIVE,
        });
        setMasktype('icon');
        setBackground(false);
        break;
      case 'inscribed':
        setData({
          text: `Você se inscreveu para essa aula.`,
          tipo: ENROLLEDFOR_LIVE,
          date: availableDate,
          time,
        });
        setMasktype('icon');
        setBackground(false);
        break;
      case 'hasToInscribe':
        setData({
          text: `Essa aula vai acontecer dia ${availableDate}.\nInscreva-se para ser notificado.`,
          footer:
            'Ao se inscrever, você será notificado por e-mail, cerca de 45 minutos antes da aula começar.',
          buttonText: 'Inscrever para a aula ao vivo',
          action: () => registerDebit(body),
        });
        setMasktype('button');
        setBackground(false);
        break;
      default:
        setData({
          text: 'Ainda não foi definida uma data para essa aula.\nMais informações em breve',
          tipo: '',
        });
        setMasktype('icon');
        setBackground(true);
    }
  }, []);

  return (
    <FakeVideoContainer
      responsive={responsive}
      background={background}
      videoThumbnail={videoThumbnail}
    >
      {masktype === 'button' ? (
        <VideoTextWithButton
          text={data.text}
          buttonText={data.buttonText}
          footer={data.footer}
          icon={data.icon}
          action={data.action}
          responsive={responsive}
        />
      ) : (
        <VideoTextWithIcon data={data} />
      )}
    </FakeVideoContainer>
  );
}

export default FakeVideo;
