import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Dialog, { DialogContent, DialogTitle } from 'material-ui/Dialog';

import { makeStyles } from '@material-ui/core';
import { AiOutlineWarning, AiOutlineCheck } from 'react-icons/ai';
import { Button } from '@clippingcacd/core';
import {
  getCommentsAnswers,
  getCommentsByTopic,
  deleteComment,
} from '../../../../services/comments';

import {
  setAnswers,
  setQuestionComments,
  removeComment,
  removeCommentAnswer,
  updateAnswerCount,
} from '../../../../actions/questionsCommentsActions';

const styles = (theme) => ({
  container: {
    borderRadius: '8px',
    minHeight: '247px',
    '@media (max-width: 1024px)': {
      minHeight: 'auto',
      margin: 0,
      maxWidth: '95vw',
      width: '95vw',
    },
  },
  close: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    margin: '10px',
    color: theme.palette.text.clear,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  content: {
    padding: '50px',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Inter',
    alignItems: 'center',
    color: '#B0B0B0',
    '& h2': {
      color: '#444444',
    },
    '& span': {
      color: '#808080',
      marginBottom: '30px',
      fontSize: '15px',
      textAlign: 'center',
    },
    '@media (max-width: 1024px)': {
      padding: '5px',
    },
  },
  textarea: {
    resize: 'none',
    width: '100%',
    margiTop: '16px',
    border: ' 1.4px solid #B0B0B0',
    borderRadius: '8px',
    height: '88px',
    padding: '16px',
    fontFamily: 'Inter',
  },
  iconWarning: {
    color: '#F7626B',
    backgroundColor: '#FFE1E1',
    padding: '11px',
    borderRadius: '50%',
  },
  iconCheck: {
    backgroundColor: '#D7F9EC',
    padding: '11px',
    borderRadius: '50%',
    color: '#2DB87E',
  },
  buttonsDiv: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: '20px',
    '@media (max-width: 620px)': {
      flexDirection: 'column-reverse',
      width: '90%',
    },
  },
  button: {
    margin: '5px',
    height: '40px',
    width: '100%',
    borderRadius: '8px',
    padding: ' 8px 16px',
    '@media (max-width: 620px)': {
      marginTop: '8px',
    },
  },
});

const useStyles = makeStyles(styles);

export const ModalDeleteDialog = ({
  onClose,
  open,
  setOpen,
  commentId,
  answer,
  commentParent,
  objectResource,
  resource,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  async function handleDeleteComment() {
    try {
      setLoading(true);
      await deleteComment(commentId);

      if (answer) {
        dispatch(
          removeCommentAnswer(
            resource,
            commentId,
            objectResource._id,
            commentParent
          )
        );
        dispatch(
          updateAnswerCount(
            resource,
            'DECREMENT',
            objectResource._id,
            commentParent
          )
        );
      } else {
        dispatch(removeComment(resource, commentId, objectResource._id));
      }

      setLoading(false);
      setOpen(false);
    } catch (err) {
      console.log(err);
      if (err?.response?.status === 404) {
        dispatch(setQuestionComments(resource, [], objectResource._id));
      }
      setLoading(false);
    }
  }

  return (
    <Dialog open={open} classes={{ paper: classes.container }}>
      <DialogContent className={classes.content}>
        <div className={classes.iconWarning}>
          <AiOutlineWarning size={25} />
        </div>
        <h2>Excluir seu comentário?</h2>
        <span>
          Tem certeza que deseja remover seu comentário? Essa ação não poderá
          ser desfeita.
        </span>
        <div className={classes.buttonsDiv}>
          <Button
            className={classes.button}
            size='sm'
            variant='outlined'
            onClick={onClose}
            style={{ color: 'black', outlineColor: '#B0B0B0' }}
          >
            Cancelar
          </Button>
          <Button
            className={classes.button}
            style={{ backgroundColor: '#F7626B' }}
            onClick={handleDeleteComment}
            loading={loading}
            labelLoading='Excluindo...'
            size='md'
          >
            Excluir meu comentário
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
