import axios from 'axios';

const headers = {
  'Content-Type': 'application/json',
};

export const apiComments = axios.create({
  baseURL: process.env.REACT_APP_API_COMMENTS_BASE_URL,
  headers,
});

apiComments.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `${token}` : '';
  return config;
});

export * from './controllers';
