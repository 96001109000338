import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { ClickAwayListener } from '@material-ui/core';

import {
  ArrowDropDownRounded as ChevronDownIcon,
  ArrowDropUpRounded as ChevronUpIcon,
  Search as SearchIcon,
} from '@material-ui/icons';

import {
  CountBadge,
  SearchIconContainer,
  SelectButton,
  CustomCheckbox,
  CustomOption,
  CustomSearchInput,
  NoOptionsMessageRoot,
  DropdownMenu,
} from './styles';

function DropdownButton({ open, handleOpen, disabled, label, count }) {
  return (
    <SelectButton open={open} onClick={handleOpen(!open)} disabled={disabled}>
      <div>
        <label>{label}</label>

        {count > 0 && <CountBadge>{count}</CountBadge>}
      </div>

      {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </SelectButton>
  );
}

const Dropdown = ({
  label,
  count,
  children,
  open,
  handleOpen,
  disabled,
  responsive,
}) => (
  <ClickAwayListener onClickAway={handleOpen(false)}>
    <div style={{ position: 'relative' }}>
      <DropdownButton
        open={open}
        handleOpen={handleOpen}
        disabled={disabled}
        label={label}
        count={count}
      />

      {open && <DropdownMenu responsive={responsive}>{children}</DropdownMenu>}
    </div>
  </ClickAwayListener>
);

function Option(props) {
  const { isSelected, label } = props;

  return (
    <div>
      <CustomOption {...props}>
        <CustomCheckbox checked={isSelected} />
        <label style={{ marginLeft: '8px' }}>{label}</label>
      </CustomOption>
    </div>
  );
}

function Input(props) {
  return (
    <CustomSearchInput {...props} placeholder={props.selectProps.placeholder} />
  );
}

function NoOptionsMessage() {
  return (
    <NoOptionsMessageRoot>
      <SearchIcon />

      <span>Nenhum resultado encontrado...</span>
    </NoOptionsMessageRoot>
  );
}

export default function SelectWrapperSearchOption({
  label,
  style,
  disabled,
  options,
  value,
  setOption,
  allowSelectAll,
  responsive,
}) {
  const [open, setOpen] = useState(false);
  const [optionSelected, setOptionSelected] = useState([]);

  const allOption = {
    label: 'Selecionar tudo',
    value: '*',
  };

  function handleOpen(open) {
    return () => setOpen(open);
  }

  function onChange(selected, event) {
    if (!selected || selected?.length <= 0) return handleChange(selected);

    let innerOptions = options;

    if (options && 'options' in options[0])
      innerOptions = innerOptions = [].concat.apply(
        [],
        options.map((option) => option.options)
      );

    if (selected[selected.length - 1].value === allOption.value)
      return handleChange([allOption, ...innerOptions]);

    if (
      event.action === 'deselect-option' &&
      selected[0].value === allOption.value
    )
      return handleChange(
        selected.filter((option) => option.value !== allOption.value)
      );

    if (selected.length === innerOptions.length) {
      let result = [];

      if (event.action === 'select-option')
        result = [allOption, ...innerOptions];

      return handleChange(result);
    }

    return handleChange(selected);
  }

  function handleChange(selected) {
    setOptionSelected(selected);
    setOption(selected.filter((x) => x.value !== '*'));
  }

  function getAllOptions() {
    if (!allowSelectAll) return options;

    return [allOption, ...options];
  }

  useEffect(() => {
    if (disabled) setOpen(false);
  }, [disabled]);

  useEffect(() => {
    function setValue() {
      if (value === optionSelected) return;

      if (options?.length > 0 && 'options' in options[0]) {
        return;
      }

      if (value?.length === options.length)
        return setOptionSelected([allOption, ...value]);

      setOptionSelected(value);
    }

    setValue();
  }, [value]);

  return (
    <div style={style}>
      <Dropdown
        open={open}
        handleOpen={handleOpen}
        label={label}
        count={value?.length}
        disabled={disabled}
        responsive={responsive}
      >
        <Select
          placeholder='Busca rápida'
          options={getAllOptions()}
          isMulti
          hideSelectedOptions={false}
          menuIsOpen
          autoFocus
          value={optionSelected}
          components={{
            IndicatorsContainer: () => (
              <SearchIconContainer>
                <SearchIcon />
              </SearchIconContainer>
            ),
            Option,
            Input,
            NoOptionsMessage,
          }}
          styles={{
            container: (base) => ({ ...base, fontFamily: 'Inter' }),
            control: (base) => ({
              ...base,
              boxShadow: '0px !important',
              borderWidth: '0px 0px 1px 0px !important',
              borderStyle: 'solid',
              borderColor: 'rgba(0, 0, 0, .2)',
              borderRadius: '0px',
              cursor: 'text',
              '&:hover': { borderColor: 'rgba(0, 0, 0, .4)' },
            }),
            menu: () => ({ margin: '0px' }),
            menuList: (base) => ({
              ...base,

              '&::-webkit-scrollbar': {
                width: '12px',
              },

              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0, 0, 0, .4)',
                borderRadius: '20px',
                border: '3px solid #FFFFFF',
              },
            }),
            multiValue: () => ({ display: 'none' }),
            placeholder: () => ({ display: 'none' }),
            input: () => ({ fontSize: '13px' }),
            option: (base) => ({ ...base, fontSize: '13px' }),
          }}
          onChange={onChange}
        />
      </Dropdown>
    </div>
  );
}
