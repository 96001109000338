import React, { Component } from 'react'
import { withStyles } from 'material-ui/styles';
import Table from '../../components/Table'

const styles = theme => ({
  wrapper: {
    flex: 1,
    position: 'absolute',
    background: '#fff',
    padding: '16px',
    paddingTop: '40px',
    height: 'max-content',
    paddingBottom: '100px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: '32px',
      paddingLeft: '345px',
      paddingTop: '64px',
      paddingBottom: '100px',
    }
  },
})

const columnData = [
  { id: 'item', numeric: false, disablePadding: true, label: 'Item do Edital'},
  { id: 'relevance', numeric: false, disablePadding: true, label: 'Tópico Quente?'},
  { id: 'teoria', numeric: false, disablePadding: false, label: 'Teoria', center: true },
  { id: 'revisao', numeric: false, disablePadding: false, label: 'Revisão', center: true },
  { id: 'questoes', numeric: false, disablePadding: false, label: '% de acertos em questões', center: true },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status', center: true },
];

class EditalContent extends Component {


  render () {
    const {classes, data = {}, changeEditalTopics, questionPerformance} = this.props
    const {topics = []} = data
    return (
      <div className={classes.wrapper} id="clippingContent">
        <Table columnData={columnData} data={topics} changeEditalTopics={changeEditalTopics} questionPerformance={questionPerformance}/>
      </div>
    )
  }
}

export default withStyles(styles)(EditalContent)
