import React, { Component } from 'react';
import { withStyles } from 'material-ui/styles';
import classNames from 'classnames';
import Star from 'material-ui-icons/Star';
import MultipleChoice from '../Questoes/MultipleChoice';

const styles = theme => ({
    containerRespostas: {
        fontWeight: 'bold',
        display: 'flex',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '4px',
        margin: '16px 0 24px 0',
        padding: '9px 16px'
    },
    star: {
        color: '#F8A947',
        height: '12px',
        width: '12px',
    },
    starPosition: {
        float: 'left',
        marginTop: '-1px',
        marginRight: '4px'
    },
    correct: {
        borderColor: '#8BCFB3',
        backgroundColor: '#EDFFF8',
        '& $answerMessage': {
            color: '#2DB87E'
        }  
    },
    incorrect: {
        borderColor: '#F69DA7',
        backgroundColor: '#FCDEE2',
        '& $answerMessage': {
            color: '#E05162'
        }
    },
    empty: {
        borderColor: '#D6D6D6',
        '& $answerMessage': {
            color: theme.palette.black.a4
        }
    },
    answerMessage: {
        fontSize: '14px',
        lineHeight: '17px'
    },
    answerIcon: {
        width: '45px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left center'
    },
    answer: {
        fontSize: '15px',
        color: theme.palette.black.a6
    },
    comentarioTitle: {
        fontWeight: 600,
        fontSize: '12px',
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        padding: '16px 14px 14px 14px'
    },
    comentarioProfessor: {
        ...theme.typography.body1,
        border: '1px solid rgba(0,0,0,0.1)',
        borderRadius: '3px',
        backgroundColor: 'rgba(178,178,178,0.1)',
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily2,
    },
    comentarioText: {
        padding: '13.5px 21px 15.8px 14px',
        fontSize: '15px',
        lineHeight: '22px',
        color: theme.palette.text.basic,
        ...theme.typography.noMargin,
        '& span': {
            color: `${theme.palette.text.basic} !important`
        },
        '& a': {
            color: '#4A90E2',
            textDecoration: 'none',
            wordBreak: 'break-all'
        },
        '& img': {
            maxWidth: '100%'
        }
    },
})

const answers = {
    'C': 'Certo',
    'E': 'Errado',
    'B': 'Branco'
}

const answerIcons = {
    'correct': 'image-like',
    'incorrect': 'image-dislike',
    'empty': 'image-question-circle'
}

const answerResult = (type, userAnswer, correct, annulled) => {
    if(correct.toLowerCase() === 'x' || annulled) { return 'empty' }
    if(!userAnswer || ((type !== 'multiple_choice') && (userAnswer === 'B'))) { return 'empty' }
    return (userAnswer === correct) ? 'correct' : 'incorrect';
}

class ItemGabarito extends Component {   
    render() {
        const {classes, userAnswer, alternatives, correct, type, comment, annulled} = this.props
        let result = answerResult(type, userAnswer, correct, annulled);
        return (
            <div>
                {(type === 'multiple_choice') && (
                    <MultipleChoice readOnly={true} alternatives={alternatives} />
                )}

                <div className={classNames(classes.containerRespostas, classes[result])}>
                    <div className={classNames(classes.answerIcon, answerIcons[result])}></div>
                    <div className={classes.answersRight}>                        
                        <div className={classes.answerMessage}>
                            {(result === 'correct')
                                ? 'Parabéns! Você acertou'
                                : 'Você marcou: ' + ((type === 'multiple_choice')
                                ? `${userAnswer || 'Branco'}`
                                : `${answers[userAnswer || 'B']}`)
                            }
                        </div>
                        <div className={classes.answer}>
                            Resposta: {(correct.toLowerCase() === 'x' || annulled) ? 'Anulada' : (type === 'multiple_choice') ? correct : answers[correct]}
                        </div>
                    </div>
                </div>
                { comment && comment.length > 15 ? <div className={classes.comentarioProfessor}>
                    <div className={classes.comentarioTitle}>
                        <div className={classes.starPosition}>
                            <Star className={classes.star}/>
                        </div> 
                        COMENTÁRIO
                    </div>
                    <div dangerouslySetInnerHTML={{__html: comment}} className={classes.comentarioText}></div> 
                </div> : null}
            </div>
        )
    }
}
    
export default withStyles(styles)(ItemGabarito)
    