import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { withStyles } from "material-ui/styles";
import Print from "material-ui-icons/Print";
import compose from "recompose/compose";
import classNames from "classnames";
import ButtonAction from "../../components/Elements/ButtonAction";
import ModalCancel from "./ModalCancelRec";
import ModalUpgrade from "./ModalUpgrade";
import moment from "moment";
import _ from "lodash";
import { updateCreditCard as updateCreditCardAction } from "../../actions/profileActions";
import { connect } from "react-redux";

const termsPlatformFile = "https://clippingcacd.com.br/termsofuse.pdf";

const styles = (theme) => ({
  container: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    paddingTop: "32px",
    paddingLeft: "90px",
    paddingBottom: "32px",
    maxWidth: "970px",
    "&:last-child": {
      borderBottom: "none",
      marginBottom: "100px",
    },
  },
  title: {
    ...theme.typography.subtitle,
    margin: "0px",
    marginBottom: "40px",
  },
  planContainer: {
    display: "flex",
  },
  planImage: {
    width: "99px",
    height: "85px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  planDesc: {
    marginLeft: "16px",
    ...theme.typography.small,
  },
  planUpgrade: {
    marginTop: "24px",
  },
  planName: {
    color: theme.palette.text.basic,
    fontWeight: 600,
    fontSize: "15px",
  },
  activePlan: {
    color: theme.palette.black.a3,
    fontWeight: "400",
  },
  planInfo: {
    color: theme.palette.text.basic,
  },
  cancelInfo: {
    color: theme.palette.text.basic,
  },
  planRecurrence: {
    color: theme.palette.text.clear,
  },
  cancelRecurrence: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  planPrice: {
    //color: '#71D3D9',
    color: theme.palette.text.basic,
    fontWeight: "normal",
    lineHeight: "24px",
    fontSize: "17px",
  },
  priceOff: {
    color: theme.palette.text.clear,
    marginLeft: "8px",
  },
  creditCardIcon: {
    fontSize: "25px",
    verticalAlign: "middle",
    marginRight: "11px",
  },
  creditCardNumber: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.clear,
    fontSize: "16px",
  },
  creditCardEdit: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.actionOrange,
    marginLeft: "16px",
    fontSize: "14px",
    cursor: "pointer",
  },
  contract: {
    ...theme.typography.longText,
    height: "197px",
    padding: "3px 16px 23px",
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: "3px",
    overflowY: "auto",
    marginBottom: "16px",
  },
  downloadContract: {
    ...theme.typography.observation,
    float: "right",
    cursor: "pointer",
    "&:hover": {
      color: "rgba(0, 0, 0, 0.8)",
    },
  },
  iconDownload: {
    width: "24px",
    height: "24px",
    color: theme.palette.actionOrange,
    verticalAlign: "middle",
  },
  formRow: {
    display: "flex",
  },
  inputLarge: {
    marginRight: "16px",
    flex: 3,
  },
  inputSmall: {
    flex: 1,
  },
  buttonSubmit: {
    marginRight: "16px",
  },
  th: {
    color: theme.palette.text.basic,
    fontFamily: theme.typography.fontFamily,
    fontSize: "12px",
    lineHeight: "21px",
    fontWeight: 600,
    textAlign: "initial",
    padding: "8px",
  },
  td: {
    ...theme.typography.observation,
    padding: "8px",
  },
  tableConbrancas: {
    width: "100%",
  },
  status: {
    fontSize: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "-2px",
  },
});

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PlanoEdit extends Component {
  state = {
    edit: false,
    modalCancel: false,
    modalUpgrade: false,
    platformTermsNumPages: 1,
    courseTermsNumPages: 1,
    upgrade: {
      open: false,
    },
  };

  handleModalCancel = (open) => {
    this.setState({ modalCancel: open });
  };

  handleModalUpgrade = (status) => {
    // this.setState({
    //   ...this.state,
    //   upgrade: { ...this.state.upgrade, open: status },
    // });

    window.open("https://checkout.clippingconcursos.com.br/acessar?gotoCheckout=1");
  };


  handleChangeCreditCard = () => {
    new window.PagarMeCheckout.Checkout({
      encryption_key: "ek_live_VHozuuu24n2d1yA32N3OmIMWJpbKcW",
      success: (data) => {
        this.setState({ loading: true });
        this.props.updateCreditCard(data.card_hash);
      },
    }).open({
      payment_methods: "credit_card",
      createToken: "false",
      customerData: "false",
      paymentButtonText: "Alterar Cartão",
      uiColor: "#F58220",
      headerText: "",
    });
  };

  renderPrice = (price) => {
    if (price) {
      return price.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    } else {
      return "Valor especial";
    }
  };

  printPlatformTerms = () => {
    const platformTermsUrl =
      "https://clippingcacd.com.br/termsofuse.pdf";
    window.open(platformTermsUrl);

    return false;
  };

  onPlatformDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ platformTermsNumPages: numPages });
  };

  render() {
    const {
      classes,
      subscription,
      cancelSubscription,
      plans,
      transactions = [],
      updateSubscription,
    } = this.props;

    const {
      name,
      price,
      current_period_start,
      current_period_end,
      createdAt,
      upgradeable = true,
      description,
      image,
      status,
      card_last_digits,
    } = subscription;

    const { modalCancel, modalUpgrade, upgrade } = this.state;
    /*
    Unused deconstructs
      props => handleSubmit, dirty, valid
      subscription => provider
      state => edit

      const cancelMsg = (status === 'trialing') ? 'Cancelar teste' : 'Cancelar recorrência';
    */

    const recurrenceMsg =
      status === "trialing" ? "primeira cobrança dia" : "renovado dia";

    return (
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <h2 className={classes.title}>Assinatura</h2>
          <div className={classes.planContainer}>
            <div
              className={classes.planImage}
              style={{
                backgroundImage: `url("${image}")`,
              }}
            ></div>
            {status !== "canceled" ? (
              <div className={classes.planDesc}>
                <div className={classes.planName}>
                  {name}
                  <span className={classes.activePlan}>(Atual)</span>
                </div>
                {/* MUDAR O PLAN NO BANCO DE DADOS  QUANDO FIZERMOS A MIGRAÇÃO */}
                {/* <div className={classes.planPrice}>
                  {this.renderPrice(price)}
                </div> */}
                <div className={classes.planInfo}>
                  {description},&nbsp;{recurrenceMsg}&nbsp;
                  {!!current_period_start
                    ? moment(current_period_start).format("DD/MM/YYYY")
                    : moment(createdAt).format("DD/MM/YYYY")}
                </div>
                <div className={classes.cancelInfo}>
                  Informações sobre cancelamento&nbsp;
                  <a
                    href="http://ajuda.clippingcacd.com.br/precos-planos-e-politica-de-cancelamento/como-cancelar-sua-assinatura"
                    target="_blank"
                  >
                    aqui
                  </a>
                </div>
                {subscription.upgradeable ? (
                  <div className={classes.planUpgrade}>
                    <ButtonAction
                      className={classes.buttonUpgrade}
                      onClick={() => this.handleModalUpgrade(true)}
                    >
                      FAÇA UPGRADE PARA PLUS
                    </ButtonAction>
                    <span className={classes.priceOff}>MELHOR PREÇO!</span>
                  </div>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </div>
            ) : (
              <div className={classes.planDesc}>
                <div className={classes.planName}>
                  {name}
                  <span className={classes.activePlan}>(Cancelado)</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={classes.container}>
          <h2 className={classes.title}>Detalhes de cobrança</h2>
          <div className={classes.creditCard}>
            <span className={classes.creditCardNumber}>
              <i className={`iconcreditcard icons ${classes.creditCardIcon}`} />
              •••• •••• •••• {card_last_digits}
            </span>
            <a
              className={classes.creditCardEdit}
              onClick={() => this.handleChangeCreditCard()}
            >
              alterar cartão
            </a>
          </div>
        </div>
        <div className={classes.container}>
          <h2 className={classes.title}>Últimas cobranças</h2>
          <table className={classes.tableConbrancas}>
            <thead>
              <tr>
                <th className={classes.th}></th>
                <th className={classes.th}>DATA</th>
                <th className={classes.th}>DESCRIÇÃO</th>
                <th className={classes.th}>VALOR</th>
              </tr>
            </thead>
            <tbody>
              {_.map(transactions, ({ amount, date_updated, status }, key) => {
                return (
                  <tr key={key}>
                    <td
                      className={classNames(classes.td, classes.status)}
                      style={
                        status === "paid"
                          ? {
                              color: "#54B881",
                            }
                          : status === "canceled"
                          ? {
                              color: "#ED4C4C",
                            }
                          : {}
                      }
                    >
                      •
                    </td>
                    <td className={classes.td}>
                      {moment(date_updated).format("DD/MM/YYYY")}
                    </td>
                    <td className={classes.td}>{name}</td>
                    <td className={classes.td}>
                      {this.renderPrice(amount / 100)}
                    </td>
                  </tr>
                );
              }).reverse()}
            </tbody>
          </table>
        </div>
        <div className={classes.container}>
          <h2 className={classes.title}>Termos de uso da plataforma e dos cursos</h2>
          <div className={classes.contract}>
            <Document
              file={termsPlatformFile}
              onLoadSuccess={this.onPlatformDocumentLoadSuccess}
            >
              {[1, 2, 3, 4, 5, 6].map((item, index) => (
                <Page key={`page_${index + 1}`} pageNumber={item} />
              ))}
            </Document>
          </div>
          <div
            className={classes.downloadContract}
            onClick={this.printPlatformTerms}
          >
            <Print className={classes.iconDownload} /> Imprimir termos de uso
          </div>
        </div>
        <ModalCancel
          handleOpen={this.handleModalCancel}
          open={modalCancel}
          cancelSubscription={cancelSubscription}
          subscription={subscription}
        />
        <ModalUpgrade
          handleOpen={this.handleModalUpgrade}
          open={upgrade.open}
          plans={plans}
          subscription={this.props.subscription}
          renderPrice={this.renderPrice}
          updateSubscription={updateSubscription}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  updateCreditCard: updateCreditCardAction,
};

const enhance = compose(withStyles(styles), connect(null, mapDispatchToProps));

export default enhance(PlanoEdit);
