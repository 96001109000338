import { Fragment, useEffect, useState } from "react"

import { Close as CloseIcon } from "@material-ui/icons";
import { Root, ClearButton, KeywordActiveItem, KeywordActiveList } from "./styles";

export default function KeywordsActiveFilters({ value, onChange, loading, responsive, separateByComma }) {
    const [keywords, setKeywords] = useState(null);

    function getEntities(value) {
        const entities = [];

        if(!separateByComma) {
            if(value !== "")
                entities.push([ value ]);

            return entities;
        }

        const keywords = value.split(/, |,/).filter(keyword => keyword !== "");
        let length = 0;
        let separator = "";

        keywords.map(keyword => {
            length += keyword.length + separator.length;
            separator = "";

            if(value[length] === ",")
                separator += ",";

            if(value[length+1] === " ")
                separator += " ";

            entities.push([ keyword, separator ]);
        });

        return entities;
    }

    function handleClear() {
        if(typeof onChange === "function")
            onChange("");
    }

    function handleRemoveKeyword(idx) {
        return () => {
            const entities = [...keywords];
            let newValue = "";

            entities.splice(idx, 1);

            if(entities[entities.length-1])
                if(entities[entities.length-1][1] !== "")
                    entities[entities.length-1][1] = ""; 

            entities.map(entity => newValue += entity.join(""));
                
            if(typeof onChange === "function")
                onChange(newValue);
        }
    }

    useEffect(() => {
        setKeywords(getEntities(value));
    }, [value]);

    if(!keywords || keywords.length <= 0)
        return ( <Fragment /> );

    return (
        <Root disabled={loading} responsive={responsive}>
            <ClearButton>
                <button onClick={handleClear} disabled={loading}>
                    <CloseIcon />
                </button>

                <span>
                    Palavra chave
                </span>
            </ClearButton>

            <KeywordActiveList responsive={responsive}>
                {keywords.map((keyword, idx) => (
                    <KeywordActiveItem key={idx} responsive={responsive}>
                        <span style={{ whiteSpace: "pre-wrap" }}>{keyword[0]}</span>

                        <button onClick={handleRemoveKeyword(idx)} disabled={loading}>
                            <CloseIcon />
                        </button>
                    </KeywordActiveItem>
                ))}
            </KeywordActiveList>
        </Root>
    )
}