import React, { Component } from 'react';
import { withStyles } from 'material-ui/styles';
import Paper from 'material-ui/Paper';
import ArrowDown from 'material-ui-icons/ArrowDropDown';
import ArrowUp from 'material-ui-icons/ArrowDropUp';
import { ElementList } from '../../components/common';
import ItemQuestao from './ItemQuestao';

const styles = (theme) => ({
  container: {
    width: '100%',
    marginBottom: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    fontFamily: theme.typography.fontFamily,
    flexDirection: 'column',
    maxWidth: '970px',
  },
  card: {
    boxShadow: theme.shadows[28],
    display: 'flex',
    flexDirection: 'column',
    minHeight: '70px',
    paddingBottom: '0px',
    width: '100%',
    position: 'relative',
    justifyContent: 'space-between',
    padding: '56px 0px 40px 0px',
  },
  number: {
    position: 'absolute',
    height: '32px',
    width: '52px',
    border: '2px solid #F68525',
    borderRadius: '0 20px 20px 0',
    backgroundColor: '#FFFFFF',
    color: '#F68525',
    fontSize: '17px',
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    paddingLeft: '13px',
    lineHeight: '32px',
    left: '-8px',
    top: '8px',
  },
  header: {
    padding: '0 40px',
  },
  statement: {
    color: 'rgba(0,0,0,0.8)',
    fontSize: '15px',
    lineHeight: '21px',
    '& span': {
      color: 'rgba(0,0,0,0.8) !important',
    },
    '& img': {
      maxWidth: '100%',
    },
    ...theme.typography.noMargin,
  },
  source: {
    color: theme.palette.text.clear,
    fontSize: '14px',
  },
  supportingTextControl: {
    fontSize: '15px',
    color: '#4A90E2',
    marginTop: '4px',
    cursor: 'pointer',
    '& svg': {
      fontSize: '20px',
    },
  },
  supportingText: {
    marginLeft: '100px',
    marginTop: '18px',
    color: theme.palette.black.a6,
    fontSize: '15px',
    lineHeight: '22px',
    maxWidth: '450px',
    ...theme.typography.noMargin,
  },
});

class Questao extends Component {
  state = {
    showSupportingText: false,
  };

  toggleSupportingText = () => {
    const { showSupportingText } = this.state;
    this.setState({ showSupportingText: !showSupportingText });
  };

  padNumber = (number) => {
    return number.toString().length > 1 ? number : `0${number}`;
  };

  render() {
    const {
      classes,
      answers = {},
      changeAnswer,
      source,
      board,
      year,
      position,
      supportingText,
      items,
      statement,
      _key,
      type,
      children,
      questions,
    } = this.props;
    const { showSupportingText } = this.state;

    var computedSourceTokens = [];

    Boolean(board) && computedSourceTokens.push(board.name);
    Boolean(year) && computedSourceTokens.push(year);
    Boolean(position) && computedSourceTokens.push(position);
    let sequenceKey;

    _key === 0
      ? (sequenceKey = 0)
      : (sequenceKey = questions[_key - 1].items.length);

    return (
      <div className={classes.container}>
        <Paper className={classes.card}>
          <div className={classes.number}>{this.padNumber(_key + 1)}</div>
          <div className={classes.header}>
            <div className={classes.source}>
              {computedSourceTokens.length > 0
                ? `(${computedSourceTokens.join('/')})`
                : source}
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: statement }}
              className={classes.statement}
            ></div>

            {supportingText && (
              <div
                className={classes.supportingTextControl}
                onClick={this.toggleSupportingText}
              >
                {showSupportingText ? 'Ocultar ' : 'Exibir '}
                texto de apoio
                {showSupportingText ? <ArrowUp /> : <ArrowDown />}
              </div>
            )}

            {showSupportingText && (
              <div
                className={classes.supportingText}
                dangerouslySetInnerHTML={{ __html: supportingText }}
              ></div>
            )}
          </div>

          <div className={classes.content}>
            <ElementList
              data={items}
              type={classes.list}
              renderRow={(props) => {
                sequenceKey++;
                return (
                  <ItemQuestao
                    children={children}
                    answers={answers}
                    sequenceKey={sequenceKey}
                    type={type}
                    changeAnswer={changeAnswer}
                    {...props}
                  />
                );
              }}
            />
          </div>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(Questao);
