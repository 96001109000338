import {
    CUSTOM
} from '../services/rest_types';

export const PAY_COURSE_PACK = 'PAY_COURSE_PACK';
export const PAY_COURSE_PACK_LOADING = 'PAY_COURSE_PACK_LOADING';
export const PAY_COURSE_PACK_FAILURE = 'PAY_COURSE_PACK_FAILURE';
export const PAY_COURSE_PACK_SUCCESS = 'PAY_COURSE_PACK_SUCCESS';

export const GET_COURSE_PACK_PURCHASES = 'GET_COURSE_PACK_PURCHASES';
export const GET_COURSE_PACK_PURCHASES_LOADING = 'GET_COURSE_PACK_PURCHASES_LOADING';
export const GET_COURSE_PACK_PURCHASES_FAILURE = 'GET_COURSE_PACK_PURCHASES_FAILURE';
export const GET_COURSE_PACK_PURCHASES_SUCCESS = 'GET_COURSE_PACK_PURCHASES_SUCCESS';

export const RESET_COURSE_PACK_STATE = 'RESET_COURSE_PACK_STATE';

export const payCoursePackage = (data) => ({
    type: PAY_COURSE_PACK,
    payload: { data },
    meta: { custom: true, resource: `coursePackageUser/payment`, fetchCustom: CUSTOM, customMethod: 'POST' }
});

export const getMyPurchases = () => ({
    type: GET_COURSE_PACK_PURCHASES,
    payload: {},
    meta: {
        custom: true,
        resource: 'coursePackageUser/myPurchases',
        fetchCustom: CUSTOM,
        customMethod: 'GET'
    }
});

export const resetCoursePack = () => ({
    type: RESET_COURSE_PACK_STATE,
    payload: {
        paid: false,
        loading: false,
        finish: false,
    }
})
