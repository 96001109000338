import { Fragment, useEffect, useRef } from 'react';

import {
  History as HistoryIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Close as CloseIcon,
} from '@material-ui/icons';

import {
  Root,
  FilterList,
  PrimaryButton,
  SecondaryButton,
  ActionsContainer,
  HideButton,
  HideContainer,
  FiltersItem,
  FiltersList,
  FilterListItem,
  ClearButton,
  Separator,
  LoadingIndicator,
} from './style';

export function ActiveFiltersItem({ value, onChange, label, responsive }) {
  function handleRemove(item) {
    return () => {
      const newValue = value.filter((x) => x.value !== item.value);

      onChange(newValue);
    };
  }

  if (!value || value.length <= 0) return <Fragment />;

  return (
    <FiltersItem responsive={responsive}>
      <ClearButton>
        <button onClick={() => onChange([])}>
          <CloseIcon />
        </button>

        <span>{label}</span>
      </ClearButton>

      <FiltersList responsive={responsive}>
        {value.map((item, idx) => (
          <FilterListItem key={idx} responsive={responsive}>
            <span style={{ whiteSpace: 'pre-wrap' }}>{item.label}</span>

            <button onClick={handleRemove(item)}>
              <CloseIcon />
            </button>
          </FilterListItem>
        ))}
      </FiltersList>
    </FiltersItem>
  );
}

export default function ActiveFilters({
  actived,
  hide,
  onClear,
  onFilter,
  hideFilters,
  onHide,
  loading,
  children,
}) {
  const didMount = useRef(false);

  function handleFilter() {
    if (!actived) return;

    onFilter();
  }

  useEffect(() => {
    if (!actived) onHide(false);
  }, [hideFilters, actived]);

  useEffect(() => {
    if (!loading) didMount.current = true;
  }, [loading]);

  return (
    <Root>
      <HideContainer hide={hide}>
        <HideContainer hide={hideFilters}>
          <Separator />
        </HideContainer>

        <h2>Filtros ativos</h2>

        <FilterList>{children}</FilterList>

        <HideContainer hide={hideFilters}>
          <div style={{ height: '36px', marginTop: '24px' }}></div>
        </HideContainer>
      </HideContainer>

      <HideContainer hide={hideFilters}>
        <ActionsContainer hide={hide}>
          <SecondaryButton onClick={onClear}>
            <HistoryIcon />

            <span>Limpar</span>
          </SecondaryButton>

          <PrimaryButton
            actived={actived}
            onClick={handleFilter}
            loading={didMount.current && loading}
          >
            {didMount.current && loading ? (
              <Fragment>
                <LoadingIndicator />
                Filtrando...
              </Fragment>
            ) : (
              'Filtrar'
            )}
          </PrimaryButton>
        </ActionsContainer>
      </HideContainer>

      {!hide && (
        <HideButton onClick={() => onHide(!hideFilters)}>
          {hideFilters ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </HideButton>
      )}
    </Root>
  );
}
