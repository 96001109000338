import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from 'material-ui/styles';
import ButtonBase from 'material-ui/ButtonBase'
import classNames from 'classnames';

const styles = theme => ({
  root: {
    ...theme.typography.button,
    border: '1px solid #F58220',
    color: theme.palette.actionOrange,
    padding: '4px 8px',
    borderRadius: '17.5px',
    height: '39px',
    minWidth: '133px',
    fontWeight: 'bold'
  },
  disable: {
    border: '1px solid rgba(0,0,0,0.3)',
    color: theme.palette.black.a3,
  }
})

class Button extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    customClasses: PropTypes.object,
    type: PropTypes.string
  }

  static defaultProps = {
    disabled: false,
    customClasses: {},
    type: 'button'
  }

  render() {
    const {onClick, children, classes, disabled, customClasses, type} = this.props
    return (
      <ButtonBase
        type={type}
        onClick={onClick}
        disabled={disabled} 
        classes={{root: classNames(classes.root, customClasses.root), disabled: classNames(classes.disable, customClasses.disable)}} 
        children={children}/>
    )
  }
}


export default withStyles(styles)(Button)
