import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import List from 'material-ui/List';
import Drawer from 'material-ui/Drawer';
import Hidden from 'material-ui/Hidden';
import _ from 'lodash';
import Select from 'material-ui/Select';
import { MenuItem } from 'material-ui/Menu';
import NavItem from './NavItem';
import { titleFor } from '../../utils/strings';

const styles = (theme) => ({
  paper: {
    width: '305px',
    backgroundColor: '#F2EEED',
    top: '65px',
    paddingTop: '16px',
    paddingLeft: '10px',
    paddingRight: '12px',
    paddingBottom: '75px',
    border: '1px solid rgba(151, 151, 151, 0.3)',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  title: {
    color: theme.palette.text.clear,
    display: 'flex',
    fontSize: '12px',
    fontWeight: '600',
    marginTop: '40px',
    paddingLeft: '16px',
    marginBottom: '24px',
  },
  titleText: {
    paddingTop: '5px',
    paddingLeft: '8px',
  },
  titleIcon: {
    color: theme.palette.text.clear,
    height: '20px',
    width: '20px',
  },
  toolbarIe: {
    display: 'flex',
  },
  toolbar: {
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  anchor: {
    color: theme.palette.text.secondary,
  },
  nav: {
    textAlign: 'center',
  },
  root: {
    color: theme.palette.black.a6,
    fontSize: '17px',
    marginTop: '15px',
    marginBottom: '5px',
    fontWeight: 600,
    maxWidth: '250px',
  },
  icon: {
    top: 'calc(50% - 14px)',
  },
  select: {
    paddingRight: '26px',
  },
});

function renderNavItems({ location, pushRouter, closeDrawer }, items = [], classes, exhibition) {
  const number = 0;
  return (
    <List>
      {_.map(items, (item) => (
        <div className={classes.navItem} key={item._id}>
          <NavItem
            key={number}
            location={location}
            pushRouter={pushRouter}
            exhibition={exhibition}
            closeDrawer={closeDrawer}
            {...item}
          />
        </div>
      ))}
    </List>
  );
}

function DrawerBibliografia(props, context) {
  const {
    classes, className, pushRouter, disablePermanent, mobileOpen, onRequestClose, concurso, items, material, exhibition,
  } = props;

  const navItems = (exhibition === 'byweek')
    ? _.values(props.navItems).map((x) => ({ name: `${titleFor('ciclo', concurso)} ${x.week}`, _id: x.week, material: x.material }))
    : _.values(props.navItems).map((x) => ({ name: x.details.name, _id: x.details._id, material: x.material }));

  const currentNavItem = (exhibition === 'byweek')
    ? material && material.week
    : material && material.subject._id;

  const handleChange = (event) => {
    const _material = _.find(navItems, { _id: event.target.value }).material;
    pushRouter(`${_material}/${exhibition}`);
  };

  const drawer = (
    <div className={classes.nav}>
      {!_.isEmpty(material) && (
        <Select
          value={currentNavItem}
          onChange={handleChange}
          displayEmpty
          disableUnderline
          classes={{
            root: classes.root,
            select: classes.select,
            icon: classes.icon,
          }}
        >
          {navItems.map((navItem) => (
            <MenuItem key={navItem._id} value={navItem._id}>{navItem.name}</MenuItem>
          ))}
        </Select>
      )}

      {renderNavItems(props, items, classes, exhibition)}
    </div>
  );

  return (
    <div className={className}>
      <Hidden mdUp={!disablePermanent}>
        <Drawer
          classes={{
            paper: classNames(classes.paper, 'algolia-drawer'),
          }}
          type="temporary"
          open={mobileOpen}
          onRequestClose={onRequestClose}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      {disablePermanent ? null : (
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.paper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      )}
    </div>
  );
}

DrawerBibliografia.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  disablePermanent: PropTypes.bool.isRequired,
  mobileOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

DrawerBibliografia.contextTypes = {
  activePage: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => ({
  concurso: state.concurso.active,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, null),
)(DrawerBibliografia);
