import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import {
  TableCell,
  TableRow
} from 'material-ui/Table';
import { Checkbox } from '../common';
import SelectStatus from '../common/SelectStatus'
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';
  
const styles = theme => ({
  center: {
    textAlign: 'center',
    minWidth: '80px'
  },
  itemText: {
    ...theme.typography.body1,
    color: theme.palette.text.basic,
    padding: '18px 56px 16px 0'
  },
  checked: {
    color: theme.palette.orange
  },
  controlRoot: {
    marginLeft: '0px',
    marginRight: '0px'
  }
});
  
class Row extends React.Component {
  constructor(props, context) {
    super(props, context);
  
    const userStatus = props.userStatus[0] || {}
    this.state = {
      questions: Boolean(userStatus.questions),
      reading: Boolean(userStatus.reading), 
      review: Boolean(userStatus.review),
      status: userStatus.status || 0,
    };
  }

  handleChange = name => (event, checked) => {
    const { trackMetric, changeEditalTopics, _id } = this.props;

    let fromBiblio = location.href.includes('plano-de-estudos');
    let data = (name === 'status') ? {target: 'status', trigger: 'change'} : {target: 'editalTopic', trigger: 'check'}
    data.filter = fromBiblio ? location.href.split('/').pop() : undefined;

    trackMetric('Edital - Ativação', { resource: _id, ...data })

    if (fromBiblio) {
      trackMetric('Bibliografia - Ativação', { resource: _id, ...data });
    }

    this.setState({ [name]: (name === 'status') ? checked : !this.state[name] }, 
      () => {
        const {questions, reading, review, status} = this.state
        changeEditalTopics({questions, reading, review, status}, _id)
      });
  };

  componentWillReceiveProps(nextProps) {
    const userStatus = nextProps.userStatus[0] || {}
    this.setState({
      questions: Boolean(userStatus.questions),
      reading: Boolean(userStatus.reading), 
      review: Boolean(userStatus.review),
      status: userStatus.status || 0,
    })
  }

  render() {
    const { classes, name, orderName, _id, relevance, questionPerformance } = this.props;
    const { questions, reading, review, status } = this.state;
    
    const percentage = questionPerformance ? (questionPerformance.correct_count / questionPerformance.count) * 100 : 0;
    

    return (
      <TableRow
        hover
        tabIndex={-1}
      >
        <TableCell classes={{root: classes.itemText}} padding="default" style={(parseFloat(orderName)%1 === 0) ? {fontWeight: 'bold'} : {}}>{`${orderName} - ${name}`}</TableCell>
        <TableCell padding="checkbox" className={classes.center}>
          {!relevance && ' '}{relevance == 1 && '🥶'}{relevance == 2 && '🙂'}{relevance == 3 && '🔥'}
        </TableCell>
        <TableCell padding="checkbox" className={classes.center}>
          <Checkbox checked={reading} 
            label={{ onClick: this.handleChange('reading') }} value="reading"/>
        </TableCell>
        <TableCell padding="checkbox" className={classes.center}>
          <Checkbox checked={review} 
            label={{ onClick: this.handleChange('review') }} value="review"/>
        </TableCell>
        <TableCell padding="none" className={classes.center}>
        {percentage.toFixed(2)}%
        </TableCell>
        <TableCell padding="none" className={classes.center}>
          <SelectStatus value={status} onChange={this.handleChange('status')} />
        </TableCell>
      </TableRow>
    );
  }
}

Row.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackMetric: (name, data) => dispatch(trackMetricAction(name, data))
  }
}

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps),
)(Row);
