// @flow

import React, { Fragment, useState } from 'react';
import { useSelector } from "react-redux";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import List from 'material-ui/List';
import Drawer from 'material-ui/Drawer';
import Hidden from 'material-ui/Hidden';
import Close from 'material-ui-icons/Close';
import IconButton from 'material-ui/IconButton';
import AppDrawerNavItem from './AppDrawerNavItem';
import SelectConcurso from './SelectConcurso';
import MobileItems from './MobileItems';
import { titleFor } from '../../utils/strings';
import AppDrawerSeparator from './AppDrawerSeparator';

import { ForumClipping, GPTClippingIcon  } from '../../components/common/SvgIcons'

import {
	SmsOutlined,
	PublicOutlined,
	ExploreOutlined,
	PlaylistAddCheckOutlined,
	AccountTreeOutlined,
	StyleOutlined,
	VideocamOutlined,
} from '@material-ui/icons';

const styles = (theme) => ({
	paper: {
		width: '300px',
		height: 'calc(100vh - 65px)',

		backgroundColor: 'transparent',
		borderRight: 'none',
		padding: '28px 16px 40px 16px',
		overflow: 'visible',
		display: 'flex',
		flexDirection: 'column',
		marginTop: '65px',
	},
	paperDivulgation: {
		width: '300px',
		height: 'calc(100vh - 185px)',
		backgroundColor: 'transparent',
		borderRight: 'none',
		padding: '28px 16px 40px 16px',
		overflow: 'visible',
		display: 'flex',
		flexDirection: 'column',
		marginTop: '185px',
	},
	title: {
		color: theme.palette.text.secondary,
		'&:hover': {
			color: theme.palette.primary[500],
		},
	},
	toolbarIe: {
		display: 'flex',
	},
	toolbar: {
		flexGrow: 1,
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'center',
	},
	anchor: {
		color: theme.palette.text.secondary,
	},
	nav: {
		height: '100%',
		overflowY: 'auto',
	},
	menuMobile: {
		height: '56px',
		[theme.breakpoints.up('sm')]: {
			height: '65px',
		},
		[theme.breakpoints.up('lg')]: {
			display: 'none',
		},
		position: 'absolute',
		top: 0,
		width: '100%',
		marginLeft: '-24px',
		display: 'flex',
		background: '#FFF',
	},
	navIconHide: {
		height: '56px',
		width: '56px',
		// [theme.breakpoints.up("sm")]: {
		//   height: "65px",
		//   width: "65px",
		// },
		backgroundColor: 'transparent',
		borderRadius: '0px',
		fontSize: '30px',
		position: 'fixed',
		zIndex: 99999,
		left: '300px',
		top: '0px',
		color: theme.palette.common.white,
		alignItems: 'center',
		justifyContent: 'center',
		// [theme.breakpoints.up("lg")]: {
		//   display: "none",
		// },
	},
	selectConcurso: {
		width: 'fit-content',
		height: 'fit-content',
	},
	mobileDrawer: {
		backgroundColor: '#fff !important',
		height: '100%',
		borderRadius: '0px 8px 8px 0px',
		marginTop: '0px',
		paddingBottom: '16px',
	},
});

function NavItems({ location, pushRouter, closeDrawer, concurso = {} }) {
	const [expandedItem, setExpandedItem] = useState(null);
	const user = useSelector((state) => state.user.profile);

	if (concurso.name === 'OFCHAN') {
		return (
			<List>
				<AppDrawerNavItem
					location={location}
					closeDrawer={closeDrawer}
					pushRouter={pushRouter}
					key={2}
					href='/mural'
					icon={<SmsOutlined />}
					title='Mural'
					items={false}
					expandedItem={expandedItem}
					setExpandedItem={setExpandedItem}
				/>
				<AppDrawerNavItem
					location={location}
					closeDrawer={closeDrawer}
					pushRouter={pushRouter}
					key={1}
					icon={<PublicOutlined />}
					title='Curadoria de Textos'
					expandable
					items={[
						{
							title: 'Leituras Diárias',
							href: '/clippings',
						},
						{
							title: 'Meus Fichamentos',
							href: '/cadernos',
						},
						{
							title: 'Ler Depois',
							href: '/ler-depois',
						},
					]}
					expandedItem={expandedItem}
					setExpandedItem={setExpandedItem}
				/>

				<AppDrawerNavItem
					location={location}
					closeDrawer={closeDrawer}
					pushRouter={pushRouter}
					key={3}
					icon={<ExploreOutlined />}
					title='Plano de Estudos e Aulas'
					expandable
					items={[
						{
							title: 'Teórico e Objetivo',
							href: '/plano-de-estudos',
						},
						{
							title: 'Preparatório discursivas',
							href: 'https://kderno.com/clippingofchan/preparatorio-discursivas',
						},
					]}
					expandedItem={expandedItem}
					setExpandedItem={setExpandedItem}
				/>
				
				{/* {["CACD", "PRF", "ABIN"].includes(concurso.name) && ( */}
				<AppDrawerNavItem
					location={location}
					closeDrawer={closeDrawer}
					pushRouter={pushRouter}
					key={4}
					icon={<PlaylistAddCheckOutlined />}
					title='Simulados e Questões'
					expandable
					items={[
						{
							title: 'Simulados semanais',
							href: '/simulados',
						},
						{
							title: 'Provas anteriores [em breve]',
							href: '/simulados?testType=tps',
						},
						{
							title: 'Plataforma de questões',
							href: '/questions',
						},
					]}
					expandedItem={expandedItem}
					setExpandedItem={setExpandedItem}
				/>
				{/* )} */}
				<AppDrawerNavItem
					location={location}
					closeDrawer={closeDrawer}
					pushRouter={pushRouter}
					key={5}
					href='/edital-esquematizado'
					icon={<AccountTreeOutlined />}
					title='Edital Esquematizado'
					expandedItem={expandedItem}
					setExpandedItem={setExpandedItem}
				/>
	
				<AppDrawerNavItem
					location={location}
					closeDrawer={closeDrawer}
					pushRouter={pushRouter}
					key={6}
					icon={<StyleOutlined />}
					title='Materiais de Estudo'
					expandable
					items={[
						{
							title: 'Provas Anteriores (PDF)',
							href: '/provas-anteriores',
						},
						{
							title: 'Ferramentas',
							href: '/extras',
						},
					]}
					expandedItem={expandedItem}
					setExpandedItem={setExpandedItem}
				/>
			</List>
		);
	}
	return (
		<List>
			<AppDrawerNavItem
				location={location}
				closeDrawer={closeDrawer}
				pushRouter={pushRouter}
				key={0}
				href='https://clipping.ai/'
				icon={<GPTClippingIcon />}
				title='Clipping.ai (beta)'
				items={false}
				expandedItem={expandedItem}
				setExpandedItem={setExpandedItem}
			/>
			{ (user?.role === '5a29412efcf99317fb323d6e' || localStorage.scope.includes('PLUS')) && (
				<AppDrawerNavItem
					location={location}
					closeDrawer={closeDrawer}
					pushRouter={pushRouter}
					key={0}
					href={'https://forum.clippingcacd.com.br'}
					icon={<ForumClipping />}
					title={"Fórum do Clipping"}
					items={false}
					expandedItem={expandedItem}
					setExpandedItem={setExpandedItem}
				/>
			)}
			<AppDrawerNavItem
				location={location}
				closeDrawer={closeDrawer}
				pushRouter={pushRouter}
				key={2}
				href='/mural'
				icon={<SmsOutlined />}
				title='Mural'
				items={false}
				expandedItem={expandedItem}
				setExpandedItem={setExpandedItem}
			/>
			<AppDrawerNavItem
				location={location}
				closeDrawer={closeDrawer}
				pushRouter={pushRouter}
				key={1}
				icon={<PublicOutlined />}
				title='Atualidades'
				expandable
				items={[
					{
						title: 'Notícias Diárias',
						href: '/clippings',
					},
					concurso.name === 'CACD'
						? {
							title: 'Discursos Oficiais',
							href: '/discursos-oficiais',
						}
						: false,
					{
						title: 'Meus Fichamentos',
						href: '/cadernos',
					},
					{
						title: 'Ler Depois',
						href: '/ler-depois',
					},
				]}
				expandedItem={expandedItem}
				setExpandedItem={setExpandedItem}
			/>
			<AppDrawerNavItem
				location={location}
				closeDrawer={closeDrawer}
				pushRouter={pushRouter}
				key={3}
				href='/plano-de-estudos'
				icon={<ExploreOutlined />}
				title={titleFor('bibliografia', concurso)}
				expandedItem={expandedItem}
				setExpandedItem={setExpandedItem}
			/>

			{/* {["CACD", "PRF", "ABIN"].includes(concurso.name) && ( */}
			<AppDrawerNavItem
				location={location}
				closeDrawer={closeDrawer}
				pushRouter={pushRouter}
				key={4}
				icon={<PlaylistAddCheckOutlined />}
				title='Simulados e Questões'
				expandable
				items={[
					{
						title: 'Simulados',
						href: '/simulados',
					},
					{
						title: 'Questões',
						href: '/questions',
					},
					concurso.name === 'CACD'
						? {
							title: 'Discursivas [beta]',
							href: 'https://kderno.com/discursivasclipping/home',
						}
						: false,
				]}
				expandedItem={expandedItem}
				setExpandedItem={setExpandedItem}
			/>
			{/* )} */}
			<AppDrawerNavItem
				location={location}
				closeDrawer={closeDrawer}
				pushRouter={pushRouter}
				key={5}
				href='/edital-esquematizado'
				icon={<AccountTreeOutlined />}
				title='Edital Esquematizado'
				expandedItem={expandedItem}
				setExpandedItem={setExpandedItem}
			/>

			<AppDrawerNavItem
				location={location}
				closeDrawer={closeDrawer}
				pushRouter={pushRouter}
				key={6}
				icon={<StyleOutlined />}
				title='Materiais de Estudo'
				expandable
				items={[
					concurso.name === 'TRT'
						? {
							title: 'Lei Seca',
							href: '/lei-seca',
						}
						: false,
					{
						title: 'Metodologia de Estudo',
						href: 'https://clippingcacd.com.br/metodologia/',
					},
					{
						title: 'Resumos',
						href: '/resumos',
					},
					{
						title: 'Provas Anteriores',
						href: '/provas-anteriores',
					},
					{
						title: 'Ferramentas',
						href: '/extras',
					},
				]}
				expandedItem={expandedItem}
				setExpandedItem={setExpandedItem}
			/>

			{concurso.name === 'CACD' ? (
				<React.Fragment>
					<AppDrawerSeparator />
					<AppDrawerNavItem
						location={location}
						closeDrawer={closeDrawer}
						pushRouter={pushRouter}
						key={20}
						href='/cursos'
						icon={<VideocamOutlined />}
						title='Cursos'
						expandedItem={expandedItem}
						setExpandedItem={setExpandedItem}
					/>
				</React.Fragment>
			) : (
				false
			)}
		</List>
	);
}

function AppDrawer({
	classes,
	divulgation = false,
	className,
	disablePermanent,
	mobileOpen,
	onRequestClose,
	closeDrawer,
	setConcurso,
	concursos,
	concurso,
	openTimer,
	pushRouter,
	userLogout,
	activeSubscriptions = [],
}) {
	const drawer = (
		<Fragment>
			<IconButton
				aria-label='open-drawer'
				onClick={onRequestClose}
				className={classes.navIconHide}
			>
				<Close />
			</IconButton>
			<div className={classNames(classes.nav, 'thin-scrollbar')}>
				<NavItems
					location={location}
					pushRouter={pushRouter}
					closeDrawer={closeDrawer}
					concurso={concurso}
				/>
				<MobileItems
					pushRouter={pushRouter}
					userLogout={userLogout}
					openTimer={openTimer}
					closeDrawer={closeDrawer}
				/>
			</div>
		</Fragment>
	);

	return (
		<div className={className}>
			<Hidden lgUp={!disablePermanent}>
				<Drawer
					classes={{
						paper: classNames(
							classes.paper,
							'algolia-drawer',
							classes.mobileDrawer,
							'abc'
						),
					}}
					variant='temporary'
					onClose={closeDrawer}
					open={mobileOpen}
					onRequestClose={onRequestClose}
					ModalProps={{
						keepMounted: true,
					}}
				>
					{mobileOpen && (
						<SelectConcurso
							setConcurso={setConcurso}
							concurso={concursos}
							activeSubscriptions={activeSubscriptions}
							buttonClassName={classes.selectConcurso}
						/>
					)}
					{drawer}
				</Drawer>
			</Hidden>

			{disablePermanent ? null : (
				<Hidden mdDown implementation='css'>
					<Drawer
						classes={{
							paper: divulgation ? classes.paperDivulgation : classes.paper,
						}}
						variant='permanent'
						open
					>
						{drawer}
					</Drawer>
				</Hidden>
			)}
		</div>
	);
}

export default withStyles(styles)(AppDrawer);
