import React, { Component } from 'react'
import CommentItem from './CommentItem'
import { ElementList, ProfilePicture } from '../common'

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { renderInput } from '../../utils/form';
import _ from 'lodash'

import { 
    sendComment as sendCommentAction,
    likeComment as likeCommentAction
} from '../../actions/commentsActions';

class Comments extends Component {
    handleSendComment = (comment) => {
        const {reset, sendComment, profile = {}} = this.props
        const { modalData, _id } = this.props.modal
        reset()
        sendComment(_id, comment, modalData, false, profile._id);
    }

    render () {
        const { handleSubmit, submitting, commentList, profile, likeComment } = this.props
        const parentList = _.filter(commentList, {parent_id: false})
        const childList = _.filter(commentList, (o) => o.parent_id !== false)
        return (
        <div className="comments">
            <div className="comment--area">
                <div>
                    <ProfilePicture userSocialId={profile.facebookId} />
                </div>

                <div>
                    <form onSubmit={handleSubmit(this.handleSendComment)}>
                        <Field
                            inputType="comment"
                            name="comment"
                            component={renderInput}
                            placeholder="Comentário"
                            disabled={submitting}
                        />
                        <button className={`btn btn-action ${ submitting && 'btn-disabled' }`} type="submit" disabled={submitting} >comentar</button>
                    </form>
                </div>
            </div>
            <ElementList clearPadding={false} data={parentList} type="comment--list" 
                renderRow={(props) => <CommentItem {...props} commentList={childList} profile={profile} likeComment={likeComment}/>}/>
        </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
  return  {
    submitting: state.waiting,
    modal: state.modal,
    commentList: state.comments.list,
    profile: state.user.profile
  }
}

const enhance = compose(
    reduxForm({
        form: 'comment',
        validate: (values, props) => {
            const errors = {};
            if (!values.comment) errors.comment = 'Digite um email válido.';
            return errors;
        },
    }),
    connect(mapStateToProps, { sendComment : sendCommentAction, likeComment: likeCommentAction }),
);

export default enhance(Comments)