import React, { Fragment, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import { Tab } from '@material-ui/core';
import { BarChart, ErrorOutline, Close } from '@material-ui/icons';
import { BsChatLeft } from 'react-icons/bs';
import { MdOutlineSchool } from 'react-icons/md';

import * as S from './styles';

export default function CardFooter({
  localQuestion,
  footerContent,
  handleFooterContent,
  totalComments,
}) {
  const footerGhostRef = useRef(null);
  const footerRef = useRef(null);
  const lastFooterContent = useRef('');
  const lastPageHeight = useRef(null);

  useEffect(() => {
    let sticky;

    function resetFooter() {
      footerGhostRef.current.style.display = 'none';
      footerRef.current.style.opacity = 1;
      footerRef.current.style.pointerEvents = 'auto';
      footerRef.current.classList.remove('sticky');
    }

    function getPageHeight() {
      const body = document.body;
      const html = document.documentElement;

      return Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
    }

    function onResize() {
      resetFooter();
      sticky = footerRef.current.offsetTop;
    }

    function onScrool() {
      const pageHeight = getPageHeight();

      if (lastPageHeight.current !== pageHeight) onResize();

      lastPageHeight.current = pageHeight;

      const maxScroll =
        footerRef.current.parentElement.offsetTop +
        footerRef.current.parentElement.offsetHeight -
        footerRef.current.offsetHeight -
        150;

      if (window.pageYOffset < sticky || window.pageYOffset >= maxScroll) {
        if (window.pageYOffset >= maxScroll) {
          footerRef.current.style.opacity = 0;
          return (footerRef.current.style.pointerEvents = 'none');
        }

        return resetFooter();
      }

      footerRef.current.style.opacity = 1;
      footerRef.current.style.pointerEvents = 'auto';

      if (
        !footerRef.current.classList.contains('sticky') &&
        window.pageYOffset < maxScroll
      ) {
        footerRef.current.classList.add('sticky');
        footerRef.current.style.maxWidth = `${footerRef.current.parentElement.offsetWidth}px`;
        footerGhostRef.current.style.display = 'block';
        footerGhostRef.current.style.height = `${footerRef.current.offsetHeight}px`;
      }
    }

    if (!sticky || footerContent !== lastFooterContent.current) {
      resetFooter();
      sticky = footerRef.current.offsetTop;

      if (
        footerContent !== lastFooterContent.current &&
        lastFooterContent.current !== '' &&
        window.pageYOffset > sticky
      )
        window.scroll({
          top: footerRef.current.offsetTop - footerRef.current.offsetHeight,
        });
    }

    if (footerContent) {
      document.addEventListener('scroll', onScrool);
      lastFooterContent.current = footerContent;
    } else lastFooterContent.current = null;

    return () => {
      document.removeEventListener('scroll', onScrool);
    };
  }, [footerContent]);

  return (
    <Fragment>
      <div ref={footerGhostRef} style={{ height: '56px' }} />

      <S.CardFooter ref={footerRef} openFooterContent={footerContent}>
        <S.Tabs value={footerContent}>
          <Tab
            label='Gabarito comentado'
            icon={<MdOutlineSchool size={18} />}
            value='feedback'
            onClick={handleFooterContent('feedback')}
            disabled={!localQuestion?.comment}
          />
          <Tab
            label={`Comentários ${totalComments}`}
            icon={<BsChatLeft style={{ padding: '1px' }} />}
            value='comments'
            onClick={handleFooterContent('comments')}
          />

          <Tab
            label='Estatísticas'
            icon={<BarChart />}
            value='charts'
            onClick={handleFooterContent('charts')}
          />

          <Tab
            label='Reportar erro'
            icon={<ErrorOutline />}
            value='report'
            onClick={handleFooterContent('report')}
          />
        </S.Tabs>

        {footerContent && (
          <S.IconButton
            alt='Enviar comentário'
            onClick={handleFooterContent(false)}
          >
            <Close />
          </S.IconButton>
        )}
      </S.CardFooter>
    </Fragment>
  );
}
