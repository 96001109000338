import {
    USER_LOGIN_LOADING,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAILURE,
} from '../actions/authActions';
import { CRUD_CREATE_FAILURE, CRUD_CREATE_SUCCESS, CRUD_CREATE } from '../actions/dataActions';
import { CREATE_AUDIO_LOADING, CREATE_AUDIO_SUCCESS, CREATE_AUDIO_FAILURE } from '../actions/extraActions';
export const WAITING_START = 'WAITING_START'
export const WAITING_DONE = 'WAITING_DONE'


export default (previousState = false, { type }) => {
    switch (type) {
    case WAITING_START:
    case USER_LOGIN_LOADING:
    case CREATE_AUDIO_LOADING:
    case CRUD_CREATE:
        return true
    case WAITING_DONE:
    case USER_LOGIN_SUCCESS:
    case USER_LOGIN_FAILURE:
    case CREATE_AUDIO_SUCCESS:
    case CREATE_AUDIO_FAILURE:
    case CRUD_CREATE_FAILURE:
    case CRUD_CREATE_SUCCESS:
        return false
    default:
        return previousState;
    }
};
