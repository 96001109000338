import {
  CURRENT_CLASS_FETCH,
  CURRENT_CLASS_FETCH_FAILURE,
  CURRENT_CLASS_FETCH_SUCCESS,
  MODULE_FETCH,
  MODULE_FETCH_SUCCESS,
  MODULE_FETCH_FAILURE,
  CLASS_INFO_FETCH,
  CLASS_INFO_FETCH_FAILURE,
  CLASS_INFO_FETCH_SUCCESS,
  CURRENT_MODULE_FETCH,
  CURRENT_MODULE_FETCH_FAILURE,
  CURRENT_MODULE_FETCH_SUCCESS,
  COURSE_FETCH,
  COURSE_FETCH_FAILURE,
  COURSE_FETCH_SUCCESS,
  CATEGORY_FETCH,
  CATEGORY_FETCH_FAILURE,
  CATEGORY_FETCH_SUCCESS,
  SET_EXPANDED_COURSE_CARD,
  SET_CURRENT_COURSE,
  SET_SHOWNED_CLASS,
  SET_SHOW_PRESENTATION,
  SET_LAST_VISITED,
  UPDATE_LAST_VISITED,
  LAST_VISITED_FETCH,
  LAST_VISITED_FETCH_FAILURE,
  LAST_VISITED_FETCH_SUCCESS,
  COURSE_DATA_FETCH,
  COURSE_DATA_FETCH_FAILURE,
  COURSE_DATA_FETCH_SUCCESS,
  SET_VIDEO_LOADED,
  RESET_COURSE,
  REDIRECT_TO_CHECKOUT,
  CLASS_SELECTOR_MODAL_OPEN,
  COURSE_MODAL_OPEN,
  UPDATE_MODULES_WATCHED,
  REMOVE_MODULE_WATCHED,
} from "./constants";

const INITIAL_STATE = {
  redirectToCheckout: false,
  videoLoaded: false,
  expandedCard: "",
  categories: {
    loading: false,
    error: false,
    data: [],
  },
  courses: {
    loading: false,
    error: false,
    data: [],
  },
  modules: {
    loading: false,
    error: false,
    data: [],
  },
  currentClass: {
    loading: false,
    error: false,
    data: [],
  },
  currentCourse: {
    data: {},
    lastVisited: false,
    showPresentation: true,
  },
  currentModule: {
    loading: false,
    error: false,
    data: {},
  },
  shownedClass: {
    moduleID: "",
    moduleNumber: "",
    classID: "",
  },
  classSelectorModal: {
    open: false,
  },
  courseModal: {
    open: false,
  },
  classInfo: {
    loading: false,
    error: false,
    data: {
      missing: false,
      enrolled: false,
      inscribed: false,
      available: false,
      live: false,
      processing: false,
      expired: false,
      showVideo: false,
      date: "",
    },
  },
  loadCourseData: {
    loading: false,
    error: false,
  },
  lastVisited: {
    loading: false,
    error: false,
  },
};

export default (previousState = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case CURRENT_CLASS_FETCH:
      return {
        ...previousState,
        currentClass: {
          ...previousState.currentClass,
          loading: true,
        },
      };

    case CURRENT_CLASS_FETCH_FAILURE:
      return {
        ...previousState,
        currentClass: {
          ...previousState.currentClass,
          loading: false,
          error: true,
        },
      };

    case CURRENT_CLASS_FETCH_SUCCESS:
      return {
        ...previousState,
        currentClass: {
          loading: false,
          error: false,
          data: payload,
        },
      };

    case LAST_VISITED_FETCH:
      return {
        ...previousState,
        lastVisited: {
          ...previousState.lastVisited,
          loading: true,
        },
      };

    case LAST_VISITED_FETCH_FAILURE:
      return {
        ...previousState,
        lastVisited: {
          loading: false,
          error: true,
        },
      };

    case LAST_VISITED_FETCH_SUCCESS:
      return {
        ...previousState,
        lastVisited: {
          loading: false,
          error: false,
        },
      };

    case MODULE_FETCH:
      return {
        ...previousState,
        modules: {
          ...previousState.modules,
          loading: true,
        },
      };

    case MODULE_FETCH_FAILURE:
      return {
        ...previousState,
        modules: {
          ...previousState.modules,
          loading: false,
          error: true,
        },
      };

    case MODULE_FETCH_SUCCESS:
      return {
        ...previousState,
        modules: {
          loading: false,
          error: false,
          data: payload,
        },
      };

    case UPDATE_MODULES_WATCHED:
        const updated = JSON.parse(JSON.stringify(previousState.modules.data));

        payload.map(x => {
          const targetModulo = updated.find(modulo => modulo.id === x.modulo);

          if(!targetModulo)
            return;

          const targetAula = targetModulo.aulas.find(aula => aula.id === x.aula);

          if(!targetAula)
            return;

          if(targetModulo?.courseProgress)
            targetModulo.courseProgress++;
          else
            targetModulo.courseProgress = 1;

          targetAula.watched = x;
        });

        return {
          ...previousState,
          modules: {
            loading: false,
            error: false,
            data: updated
          },
        };

    case REMOVE_MODULE_WATCHED:
      const data = previousState?.modules?.data || [];
      const moduloIdx = data.findIndex(x => x.id === payload.modulo);
      const aulaIdx = data[moduloIdx]?.aulas.findIndex(x => x.id === payload.aula);
      const aula = data[moduloIdx]?.aulas[aulaIdx];

      delete aula?.watched;

      if(data[moduloIdx]?.courseProgress)
        data[moduloIdx].courseProgress--;
      else
        data[moduloIdx].courseProgress = 0;

      return {
        ...previousState,
        modules: {
          ...previousState.modules,
          data: [
            ...previousState.modules.data
          ]
        }
      }

    case CLASS_INFO_FETCH:
      return {
        ...previousState,
        classInfo: {
          ...previousState.classInfo,
          loading: true,
        },
      };

    case CLASS_INFO_FETCH_FAILURE:
      return {
        ...previousState,
        classInfo: {
          ...previousState.classInfo,
          loading: false,
          error: true,
        },
      };

    case CLASS_INFO_FETCH_SUCCESS:
      return {
        ...previousState,
        classInfo: {
          loading: false,
          error: false,
          data: payload,
        },
      };

    case CURRENT_MODULE_FETCH:
      return {
        ...previousState,
        currentModule: {
          ...previousState.currentModule,
          loading: true,
        },
      };

    case CURRENT_MODULE_FETCH_FAILURE:
      return {
        ...previousState,
        currentModule: {
          ...previousState.currentModule,
          loading: false,
          error: true,
        },
      };

    case CURRENT_MODULE_FETCH_SUCCESS:
      return {
        ...previousState,
        currentModule: {
          loading: false,
          error: false,
          data: payload,
        },
      };

    case COURSE_FETCH:
      return {
        ...previousState,
        courses: {
          ...previousState.courses,
          loading: true,
        },
      };

    case COURSE_FETCH_FAILURE:
      return {
        ...previousState,
        courses: {
          ...previousState.courses,
          loading: false,
          error: true,
        },
      };

    case COURSE_FETCH_SUCCESS:
      return {
        ...previousState,
        courses: {
          loading: false,
          error: false,
          data: payload,
        },
      };

    case CATEGORY_FETCH:
      return {
        ...previousState,
        categories: {
          ...previousState.categories,
          loading: true,
        },
      };

    case CATEGORY_FETCH_FAILURE:
      return {
        ...previousState,
        categories: {
          ...previousState.categories,
          loading: false,
          error: true,
        },
      };

    case CATEGORY_FETCH_SUCCESS:
      return {
        ...previousState,
        categories: {
          loading: false,
          error: false,
          data: payload,
        },
      };

    case COURSE_DATA_FETCH:
      return {
        ...previousState,
        loadCourseData: {
          ...previousState.loadCourseData,
          loading: true,
        },
      };

    case COURSE_DATA_FETCH_FAILURE:
      return {
        ...previousState,
        loadCourseData: {
          loading: false,
          error: true,
        },
      };

    case COURSE_DATA_FETCH_SUCCESS:
      return {
        ...previousState,
        loadCourseData: {
          loading: false,
          error: false,
        },
      };

    case SET_EXPANDED_COURSE_CARD:
      return {
        ...previousState,
        expandedCard: payload,
      };

    case SET_CURRENT_COURSE:
      return {
        ...previousState,
        currentCourse: payload,
      };

    case SET_SHOWNED_CLASS:
      return {
        ...previousState,
        shownedClass: payload,
      };

    case UPDATE_LAST_VISITED:
      return {
        ...previousState,
      };

    case SET_LAST_VISITED:
      return {
        ...previousState,
        currentCourse: {
          ...previousState.currentCourse,
          lastVisited: payload,
        },
      };

    case SET_SHOW_PRESENTATION:
      return {
        ...previousState,
        currentCourse: {
          ...previousState.currentCourse,
          showPresentation: payload,
        },
      };

    case SET_VIDEO_LOADED:
      return {
        ...previousState,
        videoLoaded: payload,
      };

    case REDIRECT_TO_CHECKOUT:
      return {
        ...previousState,
        redirectToCheckout: payload,
      };

    case CLASS_SELECTOR_MODAL_OPEN:
      return { ...previousState, classSelectorModal: payload };

    case COURSE_MODAL_OPEN:
      return { ...previousState, courseModal: payload };

    case RESET_COURSE:
      return {
        ...INITIAL_STATE,
        categories: previousState.categories,
        courses: previousState.courses,
      };

    default:
      return previousState;
  }
};
