// @flow weak
/* eslint-disable no-underscore-dangle */

// import { create, SheetsRegistry } from 'jss';
// import preset from 'jss-preset-default';
// import { create } from 'jss';
// import rtl from 'jss-rtl';
import { createMuiTheme } from "material-ui/styles";
import orange from "material-ui/colors/orange";
import createGenerateClassName from "material-ui/styles/createGenerateClassName";
import { loadCSS } from "fg-loadcss/src/loadCSS";
import jssReact, { SheetsRegistry } from "react-jss/lib/jss";

export const LoadCssImports = () => {
  if (process.browser) {
    loadCSS(
      "https://fonts.googleapis.com/icon?family=Material+Icons|Adamina",
      document.querySelector("#insertion-point-jss")
    );
  }
};

// const white = {
//   50: '#FFFFFF',
//   100: '#FFFFFF',
//   200: '#FFFFFF',
//   300: '#FFFFFF',
//   400: '#FFFFFF',
//   500: '#FFFFFF',
//   600: '#FFFFFF',
//   700: '#FFFFFF',
//   800: '#FFFFFF',
//   900: '#FFFFFF',
//   A100: '#FFFFFF',
//   A200: '#FFFFFF',
//   A400: '#FFFFFF',
//   A700: '#FFFFFF',
//   contrastDefaultColor: 'light'
// }

const orangeTheme = {
  50: "#F58220",
  100: "#F58220",
  200: "#F58220",
  300: "#F58220",
  400: "#F58220",
  500: "#F58220",
  600: "#F58220",
  700: "#F58220",
  800: "#F58220",
  900: "#F58220",
  A100: "#F58220",
  A200: "#F58220",
  A400: "#F58220",
  A700: "#F58220",
  contrastDefaultColor: "light",
};

const black = {
  a1: "rgba(0,0,0,0.1)",
  a2: "rgba(0,0,0,0.2)",
  a3: "rgba(0,0,0,0.3)",
  a4: "rgba(0,0,0,0.4)",
  a5: "rgba(0,0,0,0.5)",
  a6: "rgba(0,0,0,0.6)",
  a7: "rgba(0,0,0,0.7)",
  a8: "rgba(0,0,0,0.8)",
  a9: "rgba(0,0,0,0.9)",
  a10: "rgba(0,0,0,1)",
};

const customColors = {
  blue: "#4A90E2",
  green: "#46DFB5",
  red: "#FE3F4B",
  orangeBackground: "#F8A947",
  orangeBorder: "#FFDCB1",
  themeOrange: "#F58220",
  brown: "#855836",
};

const shadows = [
  "none",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
  "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
  "0px 1px 8px 0px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 3px 3px -2px rgba(0, 0, 0, 0.12)",
  "0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
  "0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
  "0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)",
  "0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)",
  "0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
  "0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)",
  "0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
  "0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)",
  "0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)",
  "0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)",
  "0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)",
  "0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)",
  "0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)",
  "0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)",
  "0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)",
  "0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)",
  "0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)",
  "0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)",
  "0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)",
  "0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)",
  "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  "0 1px 2px 0 rgba(0,0,0,0.1)",
  "0 22px 10px 0 rgba(0,0,0,0.07)",
  "0 1px 0 0 #E8E8E8",
  "0 1px 12px 0 rgba(0,0,0,0.1)",
];

export function getTheme(theme) {
  return createMuiTheme({
    shadows,
    direction: theme.direction,
    zIndex: {
      appBar: 1300,
    },
    palette: {
      red: "#ED7F8C",
      green: "#50E3C2",
      clearOrange: "#F8A947",
      actionOrange: "#F87D37",
      clearGrey: "rgba(151, 151, 151, 0.2)",
      lightGrey: "#D8D8D8",
      orange: customColors.themeOrange,
      blue: customColors.blue,
      primary: orangeTheme,
      black,
      secondary: orange,
      type: theme.paletteType,
      brown: customColors.brown,
      background: {
        primary: "#f7f8f9",
        appBar: "#FFF",
      },
      text: {
        primary: black.a8,
        basic: black.a7,
        clear: black.a6,
        clearGrey: black.a2,
      },
    },
    typography: {
      body3: {
        color: black.a7,
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        fontSize: "0.875rem",
        fontWeight: 600,
        lineHeight: "1.46429em",
      },
      fontFamily:
        '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
      fontFamily2:
        '"Roboto", "Proxima Nova", "Helvetica", "Arial", "sans-serif"',
      fontTag: {
        color: "#FFB576",
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        fontSize: "0.9rem",
        fontWeight: 400,
        lineHeight: "0.7em",
      },
      menu: {
        fontWeight: 700,
        lineHeight: "14px",
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        fontSize: "14px",
      },
      percent: {
        color: "rgba(0, 0, 0, 0.3)",
        lineHeight: "1.6em",
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        fontSize: "1.3rem",
      },
      sectionTitle: {
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        color: "rgba(0, 0, 0, 0.7)",
        fontSize: "24px",
        letterSpacing: "-0.8px",
        lineHeight: "38px",
        fontWeight: "bold",
      },
      link: {
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        fontSize: "0.85rem",
        fontWeight: "bold",
        lineHeight: "1.36429em",
        color: customColors.blue,
        textDecoration: "none",
      },
      modalTitle: {
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        fontSize: "18px",
        lineHeight: "22px",
        fontWeight: 600,
        color: customColors.themeOrange,
      },
      pagination: {
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        fontSize: "18px",
        lineHeight: "22px",
        color: "rgba(0,0,0,0.4)",
        fontWeight: 500,
      },
      clipping: {
        fontFamily:
          '"Adamina", TimesNewRoman, "Times New Roman", "Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        fontSize: "16px",
        fontWeight: "normal",
        lineHeight: "28px",
        color: "#2A2A2A",
      },
      cardTitle: {
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        fontSize: "1rem",
        fontWeight: "bold",
        lineHeight: "1.36429em",
        color: "rgba(0, 0, 0, 0.7)",
      },
      // /after review
      articleTitle: {
        color: "rgba(0,0,0,0.9)",
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        fontSize: "28px",
        fontWeight: "bold",
        lineHeight: "36px",
      },
      articleDate: {
        color: "rgba(0,0,0,0.5)",
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        fontSize: "12px",
        lineHeight: "14px",
      },
      urlBase: {
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        color: "#4A90E2",
        fontSize: "14px",
        lineHeight: "17px",
      },
      observation: {
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        color: "rgba(0,0,0,0.5)",
        fontSize: "14px",
        lineHeight: "17px",
        fontWeight: "normal",
      },
      longText: {
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        color: "rgba(0,0,0,0.4)",
        fontSize: "14px",
        lineHeight: "17px",
        fontWeight: "normal",
      },
      back: {
        textTransform: "none",
        color: "rgba(0,0,0,0.5)",
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        fontSize: "18px",
        fontWeight: 400,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingRight: "8px",
        paddingLeft: "0px",
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      title: {
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        fontWeight: 300,
        color: "rgba(0,0,0,0.7)",
        fontSize: "24px",
        lineHeight: "20px",
      },
      subtitle: {
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        fontWeight: 400,
        color: "rgba(0,0,0,0.7)",
        fontSize: "20px",
        lineHeight: "24px",
      },
      small: {
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        fontSize: "12px",
        lineHeight: "14px",
        color: "rgba(0,0,0,0.3)",
      },
      default: {
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        fontWeight: 400,
        color: "rgba(0,0,0,0.7)",
        fontSize: "16px",
        lineHeight: "18px",
      },
      info: {
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        fontWeight: 600,
        color: "rgba(0,0,0,0.5)",
        fontSize: "12px",
        lineHeight: "14px",
      },
      error: {
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        fontWeight: 400,
        color: "#f44336",
        fontSize: "12px",
        lineHeight: "14px",
      },
      input: {
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        fontWeight: 400,
        color: "rgba(0,0,0,0.7)",
        fontSize: "15px",
        lineHeight: "18px",
      },
      text: {
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        color: "rgba(0,0,0,0.7)",
        fontSize: "15px",
        lineHeight: "21px",
      },
      button: {
        fontFamily:
          '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
        color: "rgba(0,0,0,0.3)",
        fontSize: "11px",
        lineHeight: "13px",
        textAlign: "center",
      },
      noMargin: {
        "& p:first-child": {
          marginTop: 0,
        },
        "& p:last-child": {
          marginBottom: 0,
        },
      },
    },
    transitions: {
      card: {
        width: "99%",
        position: "relative",
        boxShadow: shadows[25],
        display: "flex",
        "&:hover": {
          zIndex: 1,
          boxShadow: shadows[26],
          width: "100%",
        },
      },
    },
  });
}

const theme = getTheme({
  direction: "ltr",
  paletteType: "light",
});

// // Configure JSS
const jss = jssReact;
jss.options.createGenerateClassName = createGenerateClassName({
  productionPrefix: "jssMui",
});
// jss.options.insertionPoint = 'insertion-point-jss';

function createContext() {
  return {
    jss,
    theme,
    // This is needed in order to deduplicate the injection of CSS in the page.
    sheetsManager: new Map(),
    // This is needed in order to inject the critical CSS.
    sheetsRegistry: new SheetsRegistry(),
  };
}

export default function getContext() {
  // Make sure to create a new store for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return createContext();
  }

  // Reuse context on the client-side
  if (!global.__INIT_MATERIAL_UI__) {
    global.__INIT_MATERIAL_UI__ = createContext();
  }

  return global.__INIT_MATERIAL_UI__;
}
