import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withStyles } from 'material-ui/styles'
import Radio from 'material-ui/Radio'
import { Button } from '../../components/common'
import { FormControlLabel } from 'material-ui/Form'
import debounce from 'lodash/debounce'
import Dropzone from 'react-dropzone'
import upload from '../../utils/upload'
import { slugify } from '../../utils/strings'

const styles = theme => ({
    container: {
        marginTop: '14px',
        display:  'flex',
        flexDirection: 'column'
    },
    label: {
        fontSize: '13px',
        color: 'rgba(0,0,0,.6)',
        fontWeight: 600,
        position: 'relative',
        bottom: '-1px',
        left: '-4px'
    },
    labelRoot: {
        marginBottom: '14px'
    },
    radioDefault: {
        height: '0'
    },
    radioChecked: {
        color: '#4298FC'
    },
    textArea: {
        width: '100%',
        padding: '8px',
        borderRadius: '3px',
        border: '1px solid rgba(0,0,0,0.1)',
        marginBottom: '14px',
        fontSize: '15px',
        '&:focus': {
            height: '306px',
        }
    },
    dropZone: {
        width: '100%',
        border: '2px dashed rgba(0, 0, 0, 0.2)',
        borderRadius: '3px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px 70px'
    },
    dropZoneHeadline: {
        fontSize: '23px',
        textAlign: 'center',
        margin: '20px',
        color: 'rgba(0,0,0,0.5)'
    }
});

class Essay extends Component {
    state = {
        contentType: '',
        introduction: '',
        bullets: '',
        conclusion: '',
        files: [],
        status: ''
    }

    selectAnswer = (answer) => {
        const { question, _id, changeAnswer } = this.props
        changeAnswer(question, _id, answer)
    }

    selectContentType = (contentType) => {
        this.setState({ contentType });
    }

    changeAnswerDebounced = debounce((answer) => {
        const { question, _id, changeAnswer } = this.props;
        changeAnswer(question, _id, answer);
    }, 1000);

    handleChange = (name) => (event) => {
        const { introduction, bullets, conclusion } = this.state;

        this.setState({ [name]: event.target.value });

        let answer = { introduction, bullets, conclusion };
        answer[name] = event.target.value;

        Boolean(answer.introduction) &&  Boolean(answer.bullets) &&
            Boolean(answer.conclusion) && this.changeAnswerDebounced(answer);
    }

    handleDrop = (acceptedFiles, rejectedFiles) => {
        const { question, _id, changeAnswer, user } = this.props;
        this.setState({ status: 'Aguarde...' });

        var container = 'user-uploads';
        var file = acceptedFiles[0];
        var fileName = `question-${_id}/user-${user._id}/${slugify(file.name)}`;

        upload(container, file, fileName, (error) => {
            if (!error) {
                var blobUrl = `https://avatars.blob.core.windows.net/${container}/${fileName}`;

                this.setState({
                    status: fileName.split('/').slice(-1),
                    files: [blobUrl]
                });

                changeAnswer(question, _id, { files: [blobUrl] });
            }
            else {
                this.setState({ status: 'Ocorreu um erro...' });
            }
        });
    }

    render() {
        const { classes } = this.props;
        const { contentType, introduction, bullets, conclusion, status } = this.state;

        return (
            <div className={classes.container}>
                <FormControlLabel
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot
                    }}
                    control={<Radio
                        color={'primary'}
                        classes={{
                            checkedPrimary: classes.radioChecked,
                            default: classes.radioDefault
                        }}
                        checked={contentType === 'text'}
                        onChange={() => this.selectContentType('text')}
                    />}
                    label="Enviar texto" />

                {(contentType === 'text') && (
                    <div>
                        <textarea placeholder="Introdução"
                            onChange={this.handleChange('introduction')}
                            className={classes.textArea}
                            value={introduction}
                            rows="4"></textarea>

                        <textarea placeholder="Argumentação (em tópicos)"
                            onChange={this.handleChange('bullets')}
                            className={classes.textArea}
                            value={bullets}
                            rows="4"></textarea>

                        <textarea placeholder="Conclusão"
                            onChange={this.handleChange('conclusion')}
                            className={classes.textArea}
                            value={conclusion}
                            rows="4"></textarea>
                    </div>
                )}

                <FormControlLabel
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot
                    }}
                    control={<Radio
                        color={'primary'}
                        classes={{
                            checkedPrimary: classes.radioChecked,
                            default: classes.radioDefault
                        }}
                        checked={contentType === 'image'}
                        onChange={() => this.selectContentType('image')}
                    />}
                    label=" Enviar arquivo PDF" />

                {(contentType === 'image') && (
                    <div>
                        <Dropzone multiple={false} className={classes.dropZone} onDrop={this.handleDrop}>
                            <p className={classes.dropZoneHeadline}>
                                Arraste o arquivo ou clique para selecionar o PDF para upload.
                            </p>
                            <Button buttonFull={true}>Selecionar PDF</Button>
                        </Dropzone>

                        <p>{status}</p>
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user.profile
    }
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, null),
)(Essay);
