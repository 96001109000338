import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import {
  makeStyles,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@material-ui/core";

import InputMask from "react-input-mask";
import StepButton from "./StepButton";

import { getAddress } from "../User/services";

import { stepLoading, previousStep, closeRegister } from "./actions";
import { saveSponsorAddress, saveSponsor } from "../Payments/actions";

const styles = makeStyles({
  inputFieldStart: {
    width: "100%",
    "& label.Mui-focused": {
      color: "rgba(0,0,0, 0.6)",
    },
    "& label": {
      fontFamily: "Proxima Nova",
    },
    "&:hover label": {
      color: "rgba(0,0,0, 0.6)",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "rgba(0,0,0, 0.6)",
      },
      "&.Mui-focused fieldset": {
        fontFamily: "Proxima Nova",
        borderColor: "rgba(0,0,0, 0.6)",
      },
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: 0,
      color: "#f44336",
    },
  },
});

const Container = styled.div`
  width: 100%;
`;

const Form = styled.form`
  padding: 16px;
`;

const InputContainer = styled.div`
  height: 56px;
  margin-bottom: 24px;
  width: 100%;
  display: flex;
`;

const NumberInputContainer = styled.div`
  width: 150px;
  margin-right: 16px;
`;

const StepButtons = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 0px 16px;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 16px;
`;

const patterns = {
  zipcode: /[0-9]{8}$/,
  street: /./,
  neighborhood: /./,
  city: /./,
  state: /./,
  street_number: /[0-9]{1}$/,
};

function AddressForm() {
  const dispatch = useDispatch();
  const classes = styles();

  const { address, personalData: sponsorPersonalData } = useSelector(
    (state) => state.paymentGroup.creditCardSponsor
  );

  const [zipcode, setZipcode] = useState("");
  const [street, setStreet] = useState("");
  const [street_number, setStreetNumber] = useState("");
  const [complementary, setComplementary] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");

  const [disableFields, setDisableFields] = useState(true);

  const [invalidZipcode, setInvalidZipcode] = useState(false);
  const [invalidStreet, setInvalidStreet] = useState(false);
  const [invalidStreetNumber, setInvalidStreetNumber] = useState(false);
  const [invalidNeighborhood, setInvalidNeighborhood] = useState(false);
  const [invalidCity, setInvalidCity] = useState(false);
  const [invalidState, setInvalidState] = useState(false);

  useEffect(() => {
    setZipcode(address.zipcode);
    setStreet(address.street);
    setStreetNumber(address.street_number);
    setComplementary(address.complementary);
    setNeighborhood(address.neighborhood);
    setCity(address.city);
    setState(address.state);
  }, [JSON.stringify(address)]);

  const validateZipcode = (value) => {
    const validation = RegExp(patterns.zipcode).test(value);

    setInvalidZipcode(!validation);

    return validation;
  };

  const validateStreet = () => {
    const validation = RegExp(patterns.street).test(street);

    setInvalidStreet(!validation);

    return validation;
  };

  const validateNeighborhood = () => {
    const validation = RegExp(patterns.neighborhood).test(neighborhood);

    setInvalidNeighborhood(!validation);

    return validation;
  };

  const validateCity = () => {
    const validation = RegExp(patterns.city).test(city);

    setInvalidCity(!validation);

    return validation;
  };

  const validateState = () => {
    const validation = RegExp(patterns.state).test(state);

    setInvalidState(!validation);

    return validation;
  };

  const validateStreetNumber = () => {
    const validation = RegExp(patterns.street_number).test(street_number);
    setInvalidStreetNumber(!validation);

    return validation;
  };

  const handleInputChange = (ev) => {
    switch (ev.target.name) {
      case "zipcode":
        let addressZipCode = ev.target.value.replace(/[-]/gm, "");
        return handleCepChange(addressZipCode);
      case "street":
        return setStreet(ev.target.value);
      case "street_number":
        return setStreetNumber(ev.target.value.replace(/^\D+/gm, ""));
      case "complementary":
        return setComplementary(ev.target.value);
      case "city":
        return setCity(ev.target.value);
      case "neighborhood":
        return setNeighborhood(ev.target.value);
      case "estado":
        return setState(ev.target.value);
      default:
        break;
    }
  };

  const handleCepChange = async (value) => {
    setZipcode(value);
    const validCep = validateZipcode(value);
    if (validCep) {
      const address = await getAddress({ zipcode: value });

      if (address) {
        if (address.street) {
          setStreet(address.street);
        } else {
          setStreet("");
        }
        if (address.neighborhood) {
          setNeighborhood(address.neighborhood);
        }
        setCity(address.city);
        setState(address.state);

        setInvalidStreet(false);
        setInvalidNeighborhood(false);
        setInvalidCity(false);
        setInvalidState(false);
      }

      setInvalidZipcode(false);
      return setDisableFields(false);
    }

    setInvalidZipcode(true);
  };

  const handlePreviousStep = () => {
    dispatch(previousStep());
  };

  const handleNextStep = async () => {
    const validZipcode = validateZipcode(zipcode);
    const validStreet = validateStreet();
    const validNeighborhood = validateNeighborhood();
    const validCity = validateCity();
    const validState = validateState();
    const validStreetNumber = validateStreetNumber();

    const sponsorAddres = {
      zipcode,
      street,
      neighborhood,
      city,
      state,
      street_number,
      complementary,
    };

    if (
      validZipcode &&
      validStreet &&
      validNeighborhood &&
      validCity &&
      validState &&
      validStreetNumber
    ) {
      dispatch(stepLoading(true));
      dispatch(saveSponsorAddress(sponsorAddres));
      dispatch(
        saveSponsor({ address: sponsorAddres, personalData: sponsorPersonalData })
      );
    }
  };

  return (
    <Container>
      <Form noValidate>
        <InputContainer>
          <FormControl
            variant="outlined"
            fullWidth
            classes={{
              root: classes.inputFieldStart,
            }}
          >
            <InputLabel htmlFor="zipcode">CEP</InputLabel>
            <InputMask
              mask="99999-999"
              value={zipcode}
              onChange={handleInputChange}
            >
              {() => (
                <OutlinedInput
                  id="zipcode"
                  name="zipcode"
                  label="CEP"
                  variant="outlined"
                  error={invalidZipcode}
                />
              )}
            </InputMask>
            <FormHelperText>{invalidZipcode && "CEP inválido"}</FormHelperText>
          </FormControl>
        </InputContainer>
        <InputContainer>
          <FormControl
            variant="outlined"
            fullWidth
            classes={{
              root: classes.inputFieldStart,
            }}
          >
            <InputLabel htmlFor="street">Logradouro</InputLabel>
            <OutlinedInput
              id="street"
              name="street"
              label="Logradouro"
              variant="outlined"
              value={street}
              //   disabled={disableFields}
              error={invalidStreet}
              onChange={handleInputChange}
              onBlur={validateStreet}
            />
            <FormHelperText>
              {invalidStreet && "Endereço inválido"}
            </FormHelperText>
          </FormControl>
        </InputContainer>
        <InputContainer>
          <NumberInputContainer>
            <FormControl
              variant="outlined"
              fullWidth
              classes={{
                root: classes.inputFieldStart,
              }}
            >
              <InputLabel htmlFor="street_number">Número</InputLabel>
              <OutlinedInput
                id="street_number"
                name="street_number"
                label="Número"
                variant="outlined"
                value={street_number}
                type="tel"
                error={invalidStreetNumber}
                pattern="[0-9]*"
                onChange={handleInputChange}
                onBlur={validateStreetNumber}
              />
              <FormHelperText>
                {invalidStreetNumber && "Número inválido"}
              </FormHelperText>
            </FormControl>
          </NumberInputContainer>
          <FormControl
            variant="outlined"
            fullWidth
            classes={{
              root: classes.inputFieldStart,
            }}
          >
            <InputLabel htmlFor="complementary">Complemento</InputLabel>
            <OutlinedInput
              id="complementary"
              name="complementary"
              label="Complemento"
              variant="outlined"
              value={complementary}
              type="text"
              onChange={handleInputChange}
            />
          </FormControl>
        </InputContainer>
        <InputContainer>
          <FormControl
            variant="outlined"
            fullWidth
            classes={{
              root: classes.inputFieldStart,
            }}
          >
            <InputLabel htmlFor="neighborhood">Bairro</InputLabel>
            <OutlinedInput
              id="neighborhood"
              name="neighborhood"
              label="Bairro"
              variant="outlined"
              value={neighborhood}
              onChange={handleInputChange}
              //   disabled={disableFields}
              error={invalidNeighborhood}
              onBlur={validateNeighborhood}
            />
            <FormHelperText>
              {invalidNeighborhood && "Bairro inválido"}
            </FormHelperText>
          </FormControl>
        </InputContainer>
        <InputContainer>
          <FormControl
            variant="outlined"
            fullWidth
            classes={{
              root: classes.inputFieldStart,
            }}
          >
            <InputLabel htmlFor="city">Cidade</InputLabel>
            <OutlinedInput
              id="city"
              name="city"
              label="Cidade"
              variant="outlined"
              value={city}
              onChange={handleInputChange}
              disabled={disableFields}
              error={invalidCity}
              onBlur={validateCity}
            />
            <FormHelperText>{invalidCity && "Cidade inválida"}</FormHelperText>
          </FormControl>
        </InputContainer>
        <InputContainer>
          <FormControl
            variant="outlined"
            fullWidth
            classes={{
              root: classes.inputFieldStart,
            }}
          >
            <InputLabel htmlFor="state">Estado</InputLabel>
            <OutlinedInput
              id="state"
              name="state"
              label="Estado"
              variant="outlined"
              value={state}
              onChange={handleInputChange}
              disabled={disableFields}
              error={invalidState}
              onBlur={validateState}
            />
            <FormHelperText>{invalidState && "Estado inválido"}</FormHelperText>
          </FormControl>
        </InputContainer>
      </Form>
      <StepButtons>
        <StepButton
          name="previousStep"
          text="Etapa anterior"
          clickFn={handlePreviousStep}
          outlined={true}
        />
        <StepButton
          name="nextStep"
          text="Finalizar"
          clickFn={handleNextStep}
        />
      </StepButtons>
    </Container>
  );
}

export default AddressForm;
