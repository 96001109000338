export const SET_SORT = 'SET_SORT';
export const SORT_ASC = 'ASC';
export const SORT_DESC = 'DESC';

export const SET_PAGE = 'SET_PAGE';

export const SET_FILTER = 'SET_FILTER';

// const oppositeOrder = direction => (direction === SORT_DESC ? SORT_ASC : SORT_DESC);

/**
 * This reducer is for the react-router query string, NOT for redux.
 */
export default (previousState, { type, payload }) => {
    switch (type) {
    case SET_SORT:
        return {
            ...previousState,
            sort: payload.field,
            order: payload.order,
            page: 1,
            perPage: payload.perPage || 10,
        };

    case SET_PAGE:
        return { ...previousState, page: payload };

    case SET_FILTER: {
        return { ...previousState, page: 1, filter: payload };
    }

    default:
        return previousState;
    }
};
