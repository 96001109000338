import {SORT_DESC} from '../reducers/resource/list/queryReducer';
import _ from 'lodash'; 

export class HttpError extends Error {
    constructor(message, status) {
        super(message);
        this.message = message;
        this.status = status;
        this.name = this.constructor.name;
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = (new Error(message)).stack;
        }
        this.stack = new Error().stack;
    }
}

export const fetchJson = (url, options = {}) => {
    const requestHeaders = options.headers || new Headers({
        Accept: 'application/json',
    });
    if (!(options && options.body && options.body instanceof FormData)) {
        requestHeaders.set('Content-Type', 'application/json');
    }
    if (options.user && options.user.authenticated && options.user.token) {
        requestHeaders.set('Authorization', options.user.token);
    }

    return fetch(url, { ...options, headers: requestHeaders })
        .then(response => response.text().then(text => ({
            status: response.status,
            statusText: response.statusText,
            headers: response.headers,
            body: text,
        })))
        .then(({ status, statusText, headers, body }) => {
            let json;
            try {
                json = JSON.parse(body);
            } catch (e) {
                // not json, no big deal
            }
            if (status < 200 || status >= 300) {
                return Promise.reject(new HttpError((json && json.message) || statusText, status));
            }
            return { status, headers, body, json };
        });
};

export const queryParameters = data => _.keys(data)
    .map(key => {
        if(Array.isArray(data[key])) {
            return data[key].map(value => [key, value].map(encodeURIComponent).join('=')).join('&')
        }
        return [key, data[key]].map(encodeURIComponent).join('=')
    }).join('&');

export const queryParametersString = (data, label) => data
    .map(key => [label, key].map(encodeURIComponent).join('='))
    .join('&');

export const filterParameters = (filters = {}) => _.keys(filters)
    .map(key => {
        const value = key === 'categories' 
            ? `{"$all" : "${filters[key]}"}` 
            : typeof(filters[key]) === 'object'
            ? JSON.stringify(filters[key])
            : `"${filters[key]}"`
        return [`"${key}"`, value].map(param => `${param}`).join(' : ')
    })
    .join(', ');

export const handleFilters = (filter = {}, concurso, resource) => {
    const $embed = filter.$embed && filter.$embed.length ? `${queryParametersString(filter.$embed, '$embed')}&` : ''
    const urlParams = filter.urlParams
    
    if(typeof urlParams === 'object') {
        delete urlParams.custom
        delete urlParams._ga
        delete urlParams.utm_source
        delete urlParams.utm_campaign
        delete urlParams.utm_medium
        delete urlParams.utm_term
        delete urlParams.utm_content
    }

    var payload = filter.payload

    delete filter.payload
    delete filter.sort;
    delete filter.order;
    delete filter.$embed;
    delete filter.urlParams;

    const params = filterParameters(filter)
    const filters = params ? {$where : `{${params}}`} : {}

    const resourceMap = {
        'articles': 'clipping',
        'article': 'clipping',
        'notebookcontents': 'notebook'
    }

    let resourceName = resource.split("/")[0] !== 'search' ? resource.split("/")[0] : resource.split("/")[1];
    resourceName = resourceMap[resourceName] || resourceName;
    
    const concursoParam = (concurso && concurso[resourceName]) || [0];
    const concursoId = `${queryParametersString(concursoParam, 'concursoId')}&`

    const FilterValues = {filters, $embed, concursoId, urlParams, payload}
    
    if(resource.indexOf("Content") !== -1 || /^(article|concurso|plan)$/.test(resource)) {
        FilterValues.concursoId = ''
    }

    return FilterValues
}


export const handleSort = ({ field, order }) => {
    return field ? `${order === SORT_DESC ? '-' : ''}${field}` : '-createdAt'
}