import { put, call, cancelled, takeEvery, takeLatest, select} from 'redux-saga/effects';
import _ from 'lodash'
// import swal from 'sweetalert'
import {
    FETCH_START,
    FETCH_END,
    FETCH_ERROR,
    FETCH_CANCEL,
} from '../actions/fetchActions';

import {
    MATERIAL_CHECK_USER_STATUS
} from '../actions/organizadorActions'

import {
    EXTRAS_CHECK_USER_STATUS, CREATE_AUDIO
} from '../actions/extraActions'

import {
    crudGetOne,
    crudGetListByOneAction,
    crudClearList,
    crudGetList
} from '../actions/dataActions'
import { refreshState } from '../actions/plataformActions';
// import {
//     CUSTOM
// } from '../services/rest_types'

const crudFetch = (restClient) => {
    function* handleFetch(action) {
        const { type, payload, meta: { fetchCustom: fetchMeta, ...meta } } = action;
        const restType = fetchMeta;
        delete meta.custom
        yield [
            put({ type: `${type}_LOADING`, payload, meta }),
            put({ type: FETCH_START }),
        ];
        let response;
        try {
            const concurso = yield select(state => state.concurso.active);

            response = yield call(restClient, restType, meta.resource, {...meta, ...payload, concurso});
            if (!response.data) {
                throw new Error('REST response must contain a data key');
            }

            yield put({
                type: `${type}_SUCCESS`,
                payload: response,
                requestPayload: payload,
                meta: { ...meta, fetchResponse: restType, fetchStatus: FETCH_END },
            });

            switch (type) {
                case MATERIAL_CHECK_USER_STATUS:
                    yield put(crudGetOne('biblioSubjectMaterial', `${payload.data.material}/user`, '', false))
                    break;
                case EXTRAS_CHECK_USER_STATUS:
                    yield put(crudClearList('extraContent', false, 'CRUD_GET_LIST_BY_ONE', 'GET_LIST_BY_ONE'))
                    yield put(crudGetListByOneAction('extraContent', `bysection/${payload.data.extraSection}`, '', false))
                    break;
                case CREATE_AUDIO:
                //  yield put(refreshState())
                  break;
                default:
                    break;
            }

            yield put({ type: FETCH_END });
        }
        catch (error) {
            // if (restType === DELETE) {
                // swal('Erro', `Erro ao tentar excluir registro!`, "error")
            // }
            yield put({
                type: `${type}_FAILURE`,
                error: error.message ? error.message : error,
                requestPayload: payload,
                meta: { ...meta, fetchResponse: restType, fetchStatus: FETCH_ERROR },
            });
            yield put({ type: FETCH_ERROR, error });
        } finally {
            if (yield cancelled()) {
                yield put({ type: FETCH_CANCEL });
                return; /* eslint no-unsafe-finally:0 */
            }
        }
    }

    return function* watchCrudFetch() {
        yield [
            takeLatest(action => action.meta && action.meta.custom && action.meta.cancelPrevious, handleFetch),
            takeEvery(action => action.meta && action.meta.custom && !action.meta.cancelPrevious, handleFetch),
        ];
    };
};


export default crudFetch;
