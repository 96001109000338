import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import IconButton from "material-ui/IconButton";
import BackIcon from "material-ui-icons/ArrowBack";

import {
  setShowPresentation,
  courseModalAction,
} from "../../containers/Cursos/actions";
import { push } from "react-router-redux";

const PresentationHeaderContainer = styled.div`
  overflow: visible;
  z-index: 6;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: ${(props) =>
    props.backgroundUrl ? `url(${props.backgroundUrl})` : "#3C3C3C"};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-blend-mode: multiply; */
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

const BackButton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  padding: 16px;
`;

const CourseNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  width: fit-content;
  /* height: fit-content; */
`;

const CourseCategory = styled.p`
  margin: 0;
  color: rgba(255, 255, 255, 0.75);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: 500;
  width: fit-content;
  height: 16px;
  letter-spacing: 0px;
  line-height: 16px;
`;

const CourseName = styled.p`
  display: -webkit-box;
  margin: 0;
  color: rgb(255, 255, 255);
  font-family: "Proxima Nova";
  font-size: 20px;
  font-weight: bold;
  width: fit-content;
  height: fit-content;
  letter-spacing: -0.4px;
  line-height: 24px;
  text-align: left;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  user-select: none;
  @media (max-width: 900px) {
    max-width: 80vw;
  }
`;

function PresentationHeader() {
  const dispatch = useDispatch();

  const { name: nomeCurso, categoria, image } = useSelector(
    (state) => state.courseGroup.currentCourse.data
  );
  const { lastVisited } = useSelector(
    (state) => state.courseGroup.currentCourse
  );

  function closePresentation() {
    dispatch(setShowPresentation(false));
    lastVisited === false && dispatch(push("/cursos/"));
  }

  return (
    <PresentationHeaderContainer backgroundUrl={image}>
      <BackButton>
        <IconButton
          style={{ color: "#fff", width: "24px", height: "24px" }}
          aria-label="voltar"
          onClick={() => closePresentation()}
        >
          <BackIcon />
        </IconButton>
      </BackButton>
      <CourseNameContainer>
        <CourseCategory>{categoria?.nome} /</CourseCategory>
        <CourseName>{nomeCurso}</CourseName>
      </CourseNameContainer>
    </PresentationHeaderContainer>
  );
}

export default PresentationHeader;
