import React, { Component } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import compose from 'recompose/compose';
import moment from 'moment';
import { withStyles } from 'material-ui/styles';
import {ElementList, Title} from '../../components/common';
import Grid from 'material-ui/Grid';
import {FetchList} from '../../components';
import Clipping from './Clipping';
import ModalClipping from './ModalClipping';
import { crudGetList as crudGetListAction,
         crudCreate as crudCreateAction,
         crudDelete as crudDeleteAction } from '../../actions/dataActions';
import { SORT_DESC } from '../../reducers/resource/list/queryReducer';
import { downloadPDF as downloadPDFAction, trackMetric as trackMetricAction } from '../../actions/plataformActions';

const resource = 'clipping'
const bookmarkResource = 'bookmark/byresourcetype/article'

const fixedFilters = {
  $embed: ['sections.articles'],
  published: true,
  type: { $in: ['retrospectiva', 'diario', 'resumo'] },
  pubDate: { $lte: moment().utc().format() },
  sort: { field: 'pubDate', order: SORT_DESC }
}

const styles = theme => ({
  content: {
    paddingTop: '8px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '32px'
    }
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between'
  },
  contentMain: {
    padding: '40px 16px 16px 16px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px'
    }
  },
  contentSidebar: {
    display: 'none',
    padding: '8px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px 0 0 0',
      marginTop: '150px',
      marginLeft: '0',
      marginRight: 'auto',
      display: 'block',
      order: '1'
    }
  },
  root: {
    flexGrow: 1,
    padding: '8px'
  },
  tabsContainer: {
    height: '40px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerConatiner: {
    width: '100%',
    maxWidth: '770px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '24px',
    marginBottom: '20px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'flex-start'
    },

    fontFamily: "'Inter' !important",
  }
});


class Clippings extends Component {
  constructor(props) {
    super(props)

    const {clippingId , articleId }  = props.routeParams
    this.state = {
      exibition: 'createdAt',
      open: false,
      modal: clippingId ? {clippingId, articleId} : false,
      roteiro: false
    }
  }

  handleOpenClose = (_id) => {
    let open = (this.state.open === _id) ? false : _id;

    Boolean(open) && this.props.trackMetric('Clippings - Intenção de uso', {
      target: 'navigation',
      trigger: 'click',
      resource: open
    });

    this.setState({ open });
  }

  handleModal = (clipping) => {
    this.setState({modal: clipping})
    !clipping && window.history.back();
    this.props.replaceRouter(`/clippings${clipping.clippingId ? '/'+clipping.clippingId : ''}${clipping.articleId ? '/'+clipping.articleId : ''}`)
  }

  componentDidMount() {
    this.props.getBookmarks()
  }

  handleBookmark = (data, marked) => {
    const { setBookmark, deleteBookmark, trackMetric } = this.props;

    trackMetric(`Clippings - ${data.activation ? 'Ativação' : 'Intenção de uso'}`, {
      target: 'bookmark',
      trigger: 'click',
      action: marked ? 'create' : 'delete',
      type: data.type,
      resource: data.resourceId,
      parentResource: data.clippingId
    });

    delete data.clippingId;
    delete data.activation;
    delete data.type;

    if(marked) {
      setBookmark(data);
    }
    else {
      deleteBookmark(data);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {clippingId , articleId }  = nextProps.routeParams
    if(this.props.routeParams.clippingId !== clippingId || this.props.routeParams.articleId !== articleId) {
      this.setState({modal: clippingId ? {clippingId, articleId} : false})
    }

    if(nextProps.refreshState !== this.props.refreshState) {
      this.setState({open: false})
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {classes, bookmarks = {}, concurso, downloadPDF} = this.props
    const {open, modal} = this.state

    return (
      <div>
        <section className={classes.content}>
          <Grid container className={classes.root} >
            <Grid item xs={12} md={12} className={classes.contentMain}>
              { concurso.concursoId == 10 && 
              <div className={classes.headerConatiner}>
                <span variant='outlined'>
                  <span >
                    <a
                      style={{
                        backgroundColor: '#F9D55E',
                        color: 'rgba(0, 0, 0, 0.6);',
                      }}
                      href="https://kderno.com/clippingofchan/curadoria-de-textos-um-guia-pratico-para-os-futuros-ofchans"
                      target="_blank"
                    >
                      Clique aqui
                    </a>
                  </span>{' '}
                  para conferir o guia prático de como aproveitar a Curadoria de Textos. {' '}
                </span>
              </div>
              } 
              <FetchList {...this.props}
                  stateRefresh={`${open}${modal.clippingId}`}
                  fixedFilters={fixedFilters}
                  cancelPrevious={true}
                  resource={resource} >
                  <ElementList
                    renderRow={(props) => <Clipping {...props}
                    downloadPDF={downloadPDF}
                    concurso={concurso}
                    bookmarks={bookmarks}
                    handleBookmark={this.handleBookmark}
                    handleOpenClose={this.handleOpenClose}
                    active={open === props._id}
                    openModal={(id) => this.handleModal(id)} />}
                  />
              </FetchList>
            </Grid>

            <Grid item md={3} className={classes.contentSidebar}>
                <div></div>
            </Grid>

          </Grid>
      </section>
      <ModalClipping handleOpen={this.handleModal} open={modal} handleRoteiro={this.handleRoteiro} bookmarks={bookmarks} handleBookmark={this.handleBookmark} />
    </div>
    )
  }
}

const mapDispatchToProps = {
  replaceRouter: (path) => replace(path),
  getBookmarks: () => crudGetListAction(bookmarkResource, {page: 1, perPage: 9999}, {field: 'createdAt', order: 'ASC'}, {}, false),
  setBookmark: (data) => crudCreateAction(bookmarkResource, data, '', false, '', false, 'Artigo salvo em Ler Depois'),
  deleteBookmark: (data = {}) => crudDeleteAction(bookmarkResource, data.resourceId, '', false, false, false, 'Artigo removido de Ler Depois'),
  downloadPDF: (_id) => downloadPDFAction(_id),
  trackMetric: (name, data) => trackMetricAction(name, data)
}

const mapStateToProps = (state, ownProps) => {
  return {
    bookmarks: state[bookmarkResource].data,
    refreshState: state.plataform.refreshState,
    concurso: state.concurso.active
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(Clippings);
