import { CUSTOM } from "../services/rest_types";

export const LOAD_REPORT = "LOAD_REPORT";
export const LOAD_REPORT_SUCCESS = "LOAD_REPORT_SUCCESS";

export const loadReport = (testId) => {
  return {
    type: LOAD_REPORT,
    payload: testId,
    meta: {
      resource: `testAnswer/test/${testId}/report`,
      fetch: CUSTOM,
      customMethod: "GET",
    },
  };
};
