import styled from 'styled-components';

export const HeaderContainer = styled.div`
  width: 100%;
  max-width: 770px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 20px;

  @media (max-width: 720px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
  }

  font-family: 'Inter' !important;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  & p {
    margin: 0px;
    font-size: 22px;
    line-height: 32px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.75);

    @media (max-width: 720px) {
      font-size: 18px;
      line-height: 28px;
    }

    & b {
      font-weight: 700;
    }
  }

  & span {
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.4);
  }

  @media (max-width: 720px) {
    text-align: center;
  }
`;
