import React, { useState, useEffect } from 'react';
import {
  HeaderContainer,
  TextContainer,
} from './styles';

import { useSelector } from 'react-redux';

import api from '../../services/api';

import YoutubeModal from '../../components/YoutubeModal';


function Header() {
  const name = useSelector((state) => state.user.profile?.name);
  const concursoId = useSelector((state) => state.concurso.active.concursoId);

  const [questionsSize, setQuestionSize] = useState(0);

  async function getQuestionsSize({ concursoId }) {
    try {
      api.defaults.headers.Authorization = localStorage.getItem('token');
      const { data } = await api.get(
        `/questionItem/count?concursoId=${concursoId}`
      );

      if (!data) {
        return setQuestionSize(21000);
      }

      return setQuestionSize(data.questionItemsSize);
    } catch (error) {
      return setQuestionSize(21000);
    }
  }

  useEffect(() => {
    getQuestionsSize({ concursoId });
  }, [concursoId]);

  return (
    <HeaderContainer>
      <TextContainer>
        <p>
          {name && name.split(' ')[0]},<br />
          <b>comece a treinar agora! 💪 </b>
        </p>
        { concursoId === 1 && (
          <span>Novos simulados exclusivos de Política Internacional toda sexta-feira.</span>
        )}

        { concursoId === 10 && (
          <span>Toda semana teremos novos simulados de todas as matérias.</span>
        )}
        {concursoId === 1 && (
          <YoutubeModal
            numberQuestions={questionsSize.toLocaleString()}
            title='Saiba mais'
            embedId='eve0lV4mr7o'
          ></YoutubeModal>
        )}
      </TextContainer>
    </HeaderContainer>
  );
}

export default Header;
