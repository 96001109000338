import { CRUD_CHANGE_LIST_PARAMS } from '../../../actions/listActions';
import { CRUD_GET_LIST_FAILURE } from '../../../actions/dataActions'

const defaultState = {
    sort: null,
    order: null,
    page: 1,
    perPage: null,
    filter: {},
};

export default resource => (previousState = defaultState, { type, payload, meta, error }) => {
    if (!meta || meta.resource !== resource) {
        return previousState;
    }
    switch (type) {
    case CRUD_CHANGE_LIST_PARAMS:
        return payload;
    case CRUD_GET_LIST_FAILURE: 
        if(/is not allowed/.test(error)) {
            return defaultState
        }
        return previousState
    default:
        return previousState;
    }
};
