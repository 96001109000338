import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import {
  setShownedClass,
  classInfoFetchRequest,
  setShowPresentation,
  classSelectorModalAction
} from "../Cursos/actions";

import {
  RadioButtonUnchecked,
  RadioButtonChecked,
  StarOutlined,
} from "@material-ui/icons";
import { push } from "react-router-redux";

const ClassItemContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: fit-content;
  min-height: 76px;
  width: 305px;
  padding-right: 46px;
  cursor: pointer;
  &:hover {
    span {
      text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.75);
    }
  }
`;

const ClassItemName = styled.span`
  display: flex;
  align-items: center;
  min-height: 38px;
  width: 100%;
  height: fit-content;
  padding: 20px 0px 20px 50px;
  color: rgba(0, 0, 0, 0.75);
  text-shadow: ${(props) =>
    props.selected && "0px 0px 1px rgba(0, 0, 0, 0.75)"};
  font-weight: 500;
  font-family: "Proxima Nova";
  font-size: 15px;
`;

const ClassItemNumberContainer = styled.div`
  position: absolute;
  top: 50%;
  padding-left: 20px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: fit-content;
`;

const ClassItemIcon = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
  width: fit-content;
  border-radius: 50%;
  background: transparent;
  justify-content: center;
`;

const MarkedConcluded = styled.span`
  position: relative;
  display: block;
  background: #D7F9EE;
  height: 18px;
  width: 18px;
  margin: 0px -3px;
  border-radius: 50%;

  &::after {
    position: absolute;
    content: " ";
    inset: 5px;
    background: #37CD9B;
    border-radius: 50%;
  }
`;

const VerticalLine = styled.div`
  margin-top: 1px;
  border-left: ${(props) =>
    props.firstItem || props.lastItem ? "" : "1px dashed rgb(216, 216, 216)"};
  height: 100%;
`;

function ClassItem({
  id,
  classSelector,
  firstItem,
  lastItem,
  name,
  number,
  moduleID,
  moduleNumber,
  watched
}) {
  const dispatch = useDispatch();

  const selected = useSelector(
    (state) => state.courseGroup.shownedClass.classID
  );
  const profile = useSelector((state) => state.userData.data.id);

  const courseID = useSelector(
    (state) => state.courseGroup.currentCourse.data.id
  );

  function setSelectedClass(payload) {
    dispatch(setShowPresentation(false));
    dispatch(setShownedClass(payload));
    dispatch(push(`/cursos/${courseID}/modulo/${payload.moduleID}/aula/${payload.classID}`))
    classSelector && dispatch(classSelectorModalAction({ open: false }));
  }

  return (
    <ClassItemContainer
      onClick={() => setSelectedClass({ moduleID, moduleNumber, classID: id })}
      selected={selected === id}
    >
      <ClassItemNumberContainer>
        <VerticalLine firstItem={firstItem} />
        <ClassItemIcon>

          {watched ? ( <MarkedConcluded /> ) :
          number === 0 ? (
            <StarOutlined
              style={{
                color: "rgba(0, 0, 0, 0.4)",
                width: "18px",
                height: "18px",
              }}
            />
          ) : selected === id ? (
            <RadioButtonChecked
              style={{
                color: "rgba(0,0,0, 0.4)",
                width: "12px",
                height: "12px",
              }}
            />
          ) : (
            <RadioButtonUnchecked
              style={{
                color: "rgba(0,0,0, 0.4)",
                width: "12px",
                height: "12px",
              }}
            />
          )}
        </ClassItemIcon>
        <VerticalLine lastItem={lastItem} />
      </ClassItemNumberContainer>
      <ClassItemName selected={selected === id}>
        {number === 0
          ? "Aula zero"
          : `${number < 10 ? `0${number}` : number} - ${name}`}
      </ClassItemName>
    </ClassItemContainer>
  );
}

export default ClassItem;
