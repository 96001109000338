import React, { useEffect } from "react";
import { push } from "react-router-redux";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "material-ui/Dialog";
import { IconButton, CircularProgress } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import styled from "styled-components";

import {
  checkoutsOpen,
  checkoutsClose,
  checkoutsFetchRequest,
  checkoutSetUrl,
  resetCheckout,
} from "./actions";

import Checkout from "./Checkout";

import Logo from '../../assets/img/Simbolo_Laranja.svg';
import School from "../../assets/img/icon/school.svg";

const Container = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: ${(props) => (props.loading ? "center" : "flex-start")};
  align-items: ${(props) => (props.loading ? "center" : "flex-start")};
  height: 100vh;
  width: 100vw;
  font-family: "Proxima Nova";
`;

const Header = styled.div`
  display: flex;
  height: 64px;
  width: 100vw;
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
`;

const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 0;
  margin-right: 24px !important;
  height: 48px;
  width: 48px;
  @media (max-width: 578px) {
    margin-right: 0px !important;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  height: calc(100vh - 64px);
  width: 100vw;
  background-color: #F2EEED;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 650px;
  padding-top: 56px;
  @media (max-width: 578px) {
    padding: 36px 8px 0px 8px;
  }
`;

const ListTitle = styled.div`
  color: rgba(0, 0, 0, 0.75);
  font-size: 25px;
  font-weight: 300;
  letter-spacing: -0.8px;
`;

const ListItems = styled.div`
  padding-top: 48px;
`;

const ListItem = styled.a`
  min-height: 72px;
  width: 100%;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: white;
  text-decoration: none;
  &:hover {
    box-shadow: 0px 22px 10px 0px rgba(0, 0, 0, 0.12);
    z-index: 1;
  }
`;

const ListItemIcon = styled.div`
  display: flex;
  margin: 24px;
`;

const ListItemEmojiNew = styled.div`
  position: absolute;
  right: 35%;
  @media(max-width: 1600px)  {
    right: 30%;
  }
  @media(max-width: 770px)  {
    right: 10%;
  }
  text-align:center;
`;

const ListItemInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckoutName = styled.span`
  color: rgba(0, 0, 0, 0.75);
  font-size: 17px;
  font-weight: bold;
  padding-bottom: 4px;
`;

const CheckoutConcurso = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  font-weight: 500;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoText = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
`;

const LogoCursos = styled.div`
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.75);
  font-size: 14px;
`;

const LogoConcurso = styled.div`
  display: flex;
  align-items: baseline;
`;

const LogoConcursoName = styled.span`
  color: rgba(0, 0, 0, 0.75);
  font-size: 22px;
  font-weight: bold;
`;

const LogoConcursoAno = styled.span`
  padding-left: 4px;
`;

const CustomCircularProgress = styled(CircularProgress)`
  & > svg {
    color: rgb(252, 121, 84);
  }
`;

function CheckoutSelect() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkoutSetUrl({ url: "" }));
    dispatch(resetCheckout());
    dispatch(checkoutsFetchRequest());
    dispatch(checkoutsOpen());
  }, []);

  const checkouts = useSelector((state) => state.checkout.checkoutSelect.data);
  const loading = useSelector((state) => state.checkout.checkoutSelect.loading);
  const open = useSelector((state) => state.checkout.checkoutSelect.open);
  const url = useSelector((state) => state.checkout.currentCheckout.url);

  function redirectToCheckoutByCategory({ url }) {
    dispatch(checkoutSetUrl({ url }));
    dispatch(push(`/checkout/${url}`));
    dispatch(checkoutsClose());
  }

  function returnToCourses() {
    dispatch(checkoutsClose());
    dispatch(push(`/cursos`));
  }

  if (loading) {
    return (
      <Dialog fullScreen open={open}>
        <Container loading={1}>
          <CustomCircularProgress />
        </Container>
      </Dialog>
    );
  }

  return (
    <div>
      <Dialog fullScreen open={open}>
        <Container loading={0}>
          <Header>
            <LogoContainer>
              <img src={Logo} />
              <LogoText>
                <LogoCursos>cursos</LogoCursos>
                <LogoConcurso>
                  <LogoConcursoName>CACD</LogoConcursoName>
                  {/* <LogoConcursoAno>2020</LogoConcursoAno> */}
                </LogoConcurso>
              </LogoText>
            </LogoContainer>
            <CloseButton onClick={() => returnToCourses()}>
              <Close />
            </CloseButton>
          </Header>
          <Content>
            <List>
              <ListTitle>Escolha um curso</ListTitle>
              <ListItems>
                {checkouts.map((checkout) => (
                  <>
                    <ListItem
                      href={checkout.url}
                      key={checkout.id}
                      onClick={() =>
                        redirectToCheckoutByCategory({ url: checkout.url })
                      }
                    >
                      <ListItemIcon>
                        <img src={School} />
                      </ListItemIcon>
                      <ListItemInfo>
                        <CheckoutName>{checkout.nome}</CheckoutName>
                        <CheckoutConcurso>
                          {checkout.concurso} {checkout.ano}
                        </CheckoutConcurso>
                      </ListItemInfo>
                    </ListItem>
                    
                  </>
                ))}
              </ListItems>
            </List>
          </Content>
        </Container>
      </Dialog>
      {url && url !== "" && <Checkout />}
    </div>
  );
}

export default CheckoutSelect;
