import { put, call, takeLatest } from 'redux-saga/effects';

import { CRUD_CREATE_SUCCESS } from '../actions/dataActions';
import { 
    PAY_COURSE_CLASS_SUCCESS, 
    PAY_COURSE_CLASS_FAILURE,
    PAY_COURSE_CLASS_PAGARME_SUCCESS,
    PAY_COURSE_CLASS_PAGARME_FAILURE,
} from '../actions/courseActions';
import { refreshState } from '../actions/plataformActions';
import { showAlert } from '../actions/alertActions';
import { paymentSuccessModalShow, paymentErrorModalShow} from '../actions/paymentModalActions'

function* handleCourse(action) {
    const { type, requestPayload } = action;
    switch (type) {
        case CRUD_CREATE_SUCCESS: {
            yield put(refreshState());            
            break;
        }
        case PAY_COURSE_CLASS_SUCCESS: {
            let enrolment = false
            requestPayload.data.enrolment ? enrolment = true : enrolment = false
            yield put(refreshState());
            yield put(paymentSuccessModalShow(enrolment));
            break;
        }
        case PAY_COURSE_CLASS_FAILURE: {
            yield put(paymentErrorModalShow());
            break;
        }
        case PAY_COURSE_CLASS_PAGARME_SUCCESS: {
            yield put(refreshState());
            yield put(paymentSuccessModalShow(true));
            break;
        }
        case PAY_COURSE_CLASS_PAGARME_FAILURE: {
            yield put(refreshState());
            yield put(paymentSuccessModalShow());
            break;
        }
        // no default
    }
}

export default function* watchCourseActions() {
    yield [
        takeLatest(action => action.meta && action.meta.resource && (action.meta.resource === 'courseClassUser'), handleCourse),
        takeLatest(action => action.type === PAY_COURSE_CLASS_SUCCESS, handleCourse),
        takeLatest(action => action.type === PAY_COURSE_CLASS_FAILURE, handleCourse),
        takeLatest(action => action.type === PAY_COURSE_CLASS_PAGARME_SUCCESS, handleCourse),
        takeLatest(action => action.type === PAY_COURSE_CLASS_PAGARME_FAILURE, handleCourse),
    ];
};