import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import { withStyles } from 'material-ui/styles';
import Dialog from 'material-ui/Dialog';
import DialogTitle from 'material-ui/Dialog/DialogTitle';
import DialogContent from 'material-ui/Dialog/DialogContent';
import DialogContentText from 'material-ui/Dialog/DialogContentText';
import Close from 'material-ui-icons/Close';
import { Print } from 'material-ui-icons';
import IconButton from 'material-ui/IconButton';
import Button from 'material-ui/Button';

const termsCourseFile = "https://clippingcacd.com.br/termsofuse.pdf";

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1000px',
    width: '1000px',
    fontFamily: 'Proxima Nova',
    borderRadius: '4px',
    '@media (max-width: 900px)': {
      margin: 0,
      maxHeight: '100vh',
      height: '100vh',
      maxWidth: '100vw',
      width: '100vw',
    },
    '@media (max-width: 578px)': {
      margin: 0,
      maxHeight: '100vh',
      height: '100vh',
      maxWidth: '100vw',
      width: '100vw',
    },
  },
  contract: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '200px',
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    boxShadow: 'inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1)',
  },
  dialogTitleRoot: {
    paddingBottom: 0,
    paddingTop: 0,
    height: 64,
    paddingRight: 8,
  },
  modalHeaderTitle: {
    color: 'rgba(0, 0, 0, 0.7)',
    fontWeight: 'bold',
    fontSize: '20px',
    margin: '0 auto',
    textAlign: 'center',
    flex: 1,
    '@media (max-width: 578px)': {
      width: '75vw',
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': '1',
      '-webkit-box-orient': 'vertical',
    },
  },
  modalContent: {
    fontSize: '17px',
    color: 'rgba(0, 0, 0, 0.7)',
    paddingRight: '16px',
    paddingLeft: '16px',
    paddingTop: '16px',
    paddingBottom: '40px',
  },
  printButton: {
    background: '#4299FC',
    color: 'white',
    position: 'absolute',
    bottom: 0,
    right: 0,
    margin: 48,
    '&:hover': {
      background: '#2386F7',
    },
    '@media (max-width: 900px)': {
      bottom: 72,
      marginRight: 22,
      marginBottom: 20,
    },
    '@media (max-width: 578px)': {
      bottom: 72,
      marginRight: 22,
      marginBottom: 20,
    },
  },
});

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class TermsOfUse extends React.Component {
  constructor() {
    super();
  }

  state = {
    courseTermsNumPages: 1,
  };

  printTerms = () => {
    const terms_url =
      'https://clippingcacd.com.br/termsofuse.pdf';
    window.open(terms_url);

    return false;
  };

  onCourseDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ courseTermsNumPages: numPages });
  };

  render() {
    const { modalStatus, classes } = this.props;
    const termsPagesNumber = 8;

    return (
      <Dialog
        open={this.props.open}
        classes={{
          paper: classes.container,
        }}
        scroll={'paper'}
      >
        <DialogTitle
          disableTypography={true}
          className={classes.dialogTitle}
          classes={{
            root: classes.dialogTitleRoot,
          }}
        >
          <span className={classes.modalHeaderTitle}>Termos de uso</span>
          <IconButton
            className={classes.close}
            aria-label="Fechar"
            onClick={() => this.props.closeModal(false)}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.modalContent}>
            <div className={classes.contract}>
              <Document
                file={termsCourseFile}
                onLoadSuccess={this.onCourseDocumentLoadSuccess}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={item} />
                ))}
              </Document>
            </div>
          </DialogContentText>
        </DialogContent>
        <Button
          variant="fab"
          className={classes.printButton}
          onClick={this.printTerms}
        >
          <Print />
        </Button>
      </Dialog>
    );
  }
}

export default withStyles(styles)(TermsOfUse);
