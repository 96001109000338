import { 
    PAYMENT_MODAL_SLIDER_SHOW, 
    PAYMENT_MODAL_SLIDER_HIDE,
    PAYMENT_MODAL_TEXT_SHOW,
    PAYMENT_MODAL_TEXT_HIDE,
    ENROLMENT_PAYMENT_MODAL_SHOW,
    ENROLMENT_PAYMENT_MODAL_HIDE,
    PAYMENT_SUCCESS_MODAL_SHOW,
    PAYMENT_SUCCESS_MODAL_HIDE,
    PAYMENT_ERROR_MODAL_SHOW,
    PAYMENT_ERROR_MODAL_HIDE
} from '../actions/paymentModalActions'

const INITIAL_STATE = {
    paymentText: {},
};

export default (previousState = INITIAL_STATE, { type, payload }) => {        
    switch (type) {
        case PAYMENT_MODAL_SLIDER_SHOW:    
            return payload;            
        case PAYMENT_MODAL_SLIDER_HIDE:     
            return { ...previousState, paymentSlider : { open : false } };

        case PAYMENT_MODAL_TEXT_SHOW:    
            return payload;            
        case PAYMENT_MODAL_TEXT_HIDE:     
            return { ...previousState, ...payload };

        case ENROLMENT_PAYMENT_MODAL_SHOW:    
            return payload;            
        case ENROLMENT_PAYMENT_MODAL_HIDE:     
            return { ...previousState, enrolmentPayment: { open : false } };

        case PAYMENT_SUCCESS_MODAL_SHOW:    
            return payload;            
        case PAYMENT_SUCCESS_MODAL_HIDE:     
            return { ...previousState, paymentSuccess : { open : false } };

        case PAYMENT_ERROR_MODAL_SHOW:    
            return payload;            
        case PAYMENT_ERROR_MODAL_HIDE:     
            return { ...previousState, paymentError : { open : false } };

        default:
            return previousState;
    }
};
