export const SHOW_ALERT = 'SHOW_ALERT';

export const showAlert = (text, type = 'info') => ({
    type: SHOW_ALERT,
    payload: { text, type },
});

export const HIDE_ALERT = 'HIDE_ALERT';

export const hideAlert = () => ({
    type: HIDE_ALERT,
});
