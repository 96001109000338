import React, { Component } from 'react';
import { withStyles } from 'material-ui/styles';
import { connect } from "react-redux";
import FileDownload from 'material-ui-icons/FileDownload';
import PlayCircleIcon from 'material-ui-icons/PlayCircleFilled';
import PauseCircleIcon from 'material-ui-icons/PauseCircleFilled';
import Resumao from 'material-ui-icons/FlashOn';
import Eye from 'material-ui-icons/RemoveRedEye';
import classNames from 'classnames';
import ArticleItem from './ArticleItem';
import { ElementList } from '../../components/common';
import moment from 'moment';
import 'moment/locale/pt-br';
import sortBy from 'lodash/sortBy';
import Card from '../../components/Elements/Card';
import Tooltip from 'material-ui/Tooltip';

import compose from "recompose/compose";
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

moment.locale('pt-br');

const styles = (theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  iresumao: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily2,
    fontWeight: 500,
    color: theme.palette.orange,
    fontSize: '10px',
    marginRight: '32px',
    [theme.breakpoints.down('xs')]: {
      marginRight: '8px',
    },
  },
  icon: {
    width: '21px',
    height: '21px',
  },
  iconResumao: {
    width: '10px',
    color: theme.palette.orange,
    [theme.breakpoints.down('sm')]: {
      width: '17px',
    },
  },
  active: {},
  listContainer: {
    marginTop: '24px',
    minHeight: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '-8px',
  },
  textBox: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '8px',
    },
  },
  day: {
    ...theme.typography.display1,
    fontWeight: 300,
    color: theme.palette.clearOrange,
    width: '50px',
  },
  dayOfWeek: {
    ...theme.typography.caption,
    textTransform: 'uppercase',
    fontWeight: '600',
    color: theme.palette.text.basic,
    lineHeight: '15px',
  },
  monthYear: {
    ...theme.typography.subheading,
    color: theme.palette.text.clear,
    lineHeight: '15px',
    textTransform: 'lowercase',
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      width: '500px',
    },
  },
  read: {
    marginLeft: '190px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '200px',
    },
    position: 'absolute',
    color: theme.palette.text.clearGrey,
  },
  iconRead: {
    height: '19px',
  },
  responsiveSm: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  pdf: {
    color: theme.palette.text.clearGrey,
    fontSize: '14px',
    fontFamily: theme.typography.fontFamily,
    padding: '0px 4px 0px 0px',
    minWidth: '60px',
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      minWidth: '30px',
      padding: '0px',
    },
    '&:hover': {
      color: theme.palette.text.clear,
    },
  },
  retrospective: {
    backgroundColor: 'rgb(230, 228, 255)',
    border: '1px solid rgb(131, 119, 242)',
  },
  retrospectiveHeader: {
    color: theme.palette.black.a6,
    display: 'block',
    fontSize: '13px',
    lineHeight: '13px',
    fontWeight: '600',
    fontFamily: theme.typography.fontFamily,
  },
  retrospectiveText: {
    color: theme.palette.black.a3,
    fontFamily: theme.typography.fontFamily,
    fontSize: '13px',
  },
  retrospectiveIcon: {
    width: '27px',
    height: '21px',
    marginLeft: '2px',
    marginRight: '29px',
    color: 'rgb(151, 142, 245)',
  },
  retrospectiveContainer: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
  },
});

class Clipping extends Component {

  constructor(props) {
    super(props);
    this.state = {
      audioPlaying: null // default icon
    };
  }


  handleDownload = (e) => {
    e.stopPropagation();
    this.props.downloadPDF(this.props._id);
  };

  playAudio = new Audio();

  handlePlayAudio = (e, url) => {
    e.stopPropagation();
    // if (this.playAudio.src === url && !this.playAudio.paused) {
    //   // Está tocando, então pausa
    //   this.playAudio.pause();
    //   this.setState({ audioPlaying: null });
    // }
    // else if (this.playAudio.src === url && this.playAudio.paused) {
    //   // Está pausado, então toca
    //   this.playAudio.play();
    //   this.setState({ audioPlaying: url });
    // }

    // if (this.playAudio.src !== url) {
    //   // Não é o mesmo áudio, então toca o novo áudio
    //   this.playAudio.src = url;
    //   this.playAudio.play();
    //   this.setState({ audioPlaying: url });
    
      this.setState({ audioPlaying: url });
      try {
        this.props.trackMetric('ClippingCast - Ativação', {
          target: 'listen',
          trigger: 'click',
          resource: this.props._id
        });
      }
      catch (e) { console.log(e); }

    // }
  };

  componentWillUnmount() {
    try {
      this.playAudio.pause();
    } catch (e) {
      this.playAudio.src = null;
    }
  }

  render() {
    const {
      pubDate,
      _id,
      classes,
      active,
      handleOpenClose,
      openModal,
      type,
      bookmarks,
      handleBookmark,
      read = false,
      audio,
      concurso = {},
    } = this.props;
    const date = moment(pubDate, 'YYYY-MM-DD');
    const articlesList = [];
    var sections = sortBy(this.props.sections || [], 'order');
    sections.forEach(({ name, articles }) => {
      var sortedArticles = sortBy(articles, 'order');
      sortedArticles.map(({ title, _id, source, pubDate }) =>
        articlesList.push({ name, title, _id, source, pubDate })
      );
    });

    return (
      <Card
        active={active}
        onClick={() => handleOpenClose(_id)}
        customClasses={{
          card: type === 'retrospectiva' ? classes.retrospective : '',
        }}
        left={
          type === 'retrospectiva' ? (
            <div className={classes.retrospectiveContainer}>
              <div
                className={classNames(classes.retrospectiveIcon, 'image-loop')}
              ></div>
              <div>
                <span className={classes.retrospectiveHeader}>
                  Linha do tempo
                </span>
                <span className={classes.retrospectiveText}>
                  Retrospectiva do que aconteceu de mais importante para o CACD
                </span>
              </div>
            </div>
          ) : (
            <div className={classes.title}>
              <div
                className={classNames(
                  classes.day,
                  active ? classes.active : ''
                )}
              >
                {`0${date.format('DD')}`.slice(-2)}
              </div>
              <div className={classes.textBox}>
                <div
                  className={classNames(
                    classes.dayOfWeek,
                    active ? classes.active : ''
                  )}
                >
                  {date.format('dddd')}
                </div>
                <div
                  className={classNames(
                    classes.monthYear,
                    active ? classes.active : ''
                  )}
                >{`${date.format('MMMM')} de ${date.format('YYYY')}`}</div>
              </div>
              <div
                className={classes.read}
                style={read ? {} : { display: 'none' }}
              >
                <Eye className={classes.iconRead} />
              </div>
            </div>
          )
        }
        right={
          type !== 'retrospectiva' ? (
            <div className={classes.right}>
              {type === 'resumo' ? (
                <div className={classes.iresumao}>
                  <Resumao
                    className={classNames(classes.iconResumao, classes.active)}
                  />
                  <div className={classes.responsiveSm}>RESUMÃO</div>
                </div>
              ) : (
                ''
              )}
              {audio && this.state.audioPlaying !== audio ? (
                <Tooltip title="Tocar o ClippingCAST" enterDelay={300} className={classes.displayResponsive}>
                  <div
                    className={classes.pdf}
                    onClick={(e) => this.handlePlayAudio(e, audio)}
                  >
                    { this.state.audioPlaying === audio ? (
                    <PauseCircleIcon
                      onClick={(e) => this.handlePlayAudio(e, audio)}
                      className={classes.icon}
                    />
                    ) : (
                      <PlayCircleIcon
                        onClick={(e) => this.handlePlayAudio(e, audio)}
                        className={classes.icon}
                      />
                    )}
                    {/* <span className={classes.responsiveSm}> </span> */}
                  </div>
                </Tooltip>
              ) : (
                ''
              )}
              {audio && this.state.audioPlaying === audio ? (
                 <AudioPlayer
                 autoPlay={false}
                 showSkipControls={false}
                 showJumpControls={true}
                 autoPlayAfterSrcChange={false}
                 customAdditionalControls={[]}
                 customVolumeControls={[]}
                 layout="horizontal"
                 showFilledVolume={false}
                 src={this.state.audioPlaying}
                 onPlay={e => console.log("onPlay")}
                 // other props here
               />
              ) : (
                ''
              ) }
              <div
                className={classes.pdf}
                onClick={(e) => this.handleDownload(e)}
              >
                <FileDownload
                  onClick={(e) => this.handleDownload(e)}
                  className={classes.icon}
                />
                <span className={classes.responsiveSm}>PDF</span>
              </div>
            </div>
          ) : (
            <div></div>
          )
        }
        list={
          <ElementList
            data={articlesList}
            type={classes.listContainer}
            renderRow={(props) => {
              return (
                <ArticleItem
                  {...props}
                  openModal={openModal}
                  type={type}
                  clippingId={_id}
                  bookmark={bookmarks[props._id]}
                  handleBookmark={handleBookmark}
                />
              );
            }}
          />
        }
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    trackMetric: (name, data) => dispatch(trackMetricAction(name, data))
  }
}

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps),
)(Clipping);
