import React, { Component } from 'react';
import { withStyles } from 'material-ui/styles';
import compose from 'recompose/compose';
import { MenuItem } from 'material-ui/Menu';
import Select from 'material-ui/Select';
import { DateRangePicker } from 'react-dates';
import classNames from 'classnames';
import { SORT_ASC, SORT_DESC } from '../../reducers/resource/list/queryReducer';
import _ from 'lodash';

const styles = theme => ({
  filters: {
    display: 'flex',
    marginBottom: '40px',
    justifyContent: 'flex-end',
    alignItems: 'baseline'
  },
  selectIcon: {
    color: 'rgba(0, 0, 0, 0.4)'
  },
  select: {
    paddingTop: '13px',
    paddingLeft: '7px',
  },
  filterText: {
    color: 'rgba(0, 0, 0, 0.4)',
    fontFamily: '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
    fontSize: '14px',
    fontWeight: 600,
    letterSpacing: '0.54px',

    '&:hover': {
      color: 'rgba(0, 0, 0, 0.6)',
    }
  },
  filterDate: {
    marginLeft: '40px'
  },
  filterDateButton: {
    cursor: 'pointer'
  }
});

class SearchFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sort: props.showRelevance ? '' : SORT_DESC,
      startDate: null,
      endDate: null,
      openDRP: false,
      focusedInput: 'startDate',
      filters: {}
    };
  }

  componentWillMount() {
    const { setFilters, setSort, showRelevance } = this.props;
    this.setState({ sort: showRelevance ? '' : SORT_DESC, startDate: null, endDate: null });
    setFilters( { payload: { } } );
    setSort({ field: 'score', order: 'DESC' });
  }

  handleChangeSort = (event) => {
    var { setSort } = this.props;
    this.setState({ sort: event.target.value });

    if(this.state.sort !== event.target.value) {
      var field = Boolean(event.target.value.trim()) ? 'pubDate' : 'score';
      setSort({ field, order: event.target.value });
    }
  }

  getDateFilters = (startDate, endDate) => {
    let filters = { payload: {} };

    if (Boolean(startDate)) {
      filters.payload.startDate = startDate.format('YYYY-MM-DD');
    }

    if (Boolean(endDate)) {
      filters.payload.endDate = endDate.format('YYYY-MM-DD');
    }

    return filters;
  }

  handleChangeDates = (filter) => {
    const { setFilters } = this.props;
    const { startDate, endDate } = this.state;
    let parsedFilters = this.getDateFilters(filter.startDate, filter.endDate);
    this.setState({ startDate: filter.startDate, endDate: filter.endDate, filters: parsedFilters });

    if (!_.isEqual(startDate, filter.startDate) || !_.isEqual(endDate, filter.endDate)) {
      setFilters(parsedFilters);
    }
  }

  render() {      
    const { classes, showRelevance } = this.props;
    const { sort, focusedInput, startDate, endDate, openDRP } = this.state;

    return (
      <div className={classes.filters}>
        <Select
          value={sort}
          onChange={this.handleChangeSort}
          displayEmpty={true}
          classes={{icon: classes.selectIcon, root: classes.filterText, select: classes.select}}
          disableUnderline
          autoWidth={true}
        >
          { showRelevance && <MenuItem value={''}>MAIS RELEVANTES</MenuItem> }
          <MenuItem value={SORT_DESC}>MAIS RECENTES</MenuItem>
          <MenuItem value={SORT_ASC}>MAIS ANTIGOS</MenuItem>
        </Select>
        <div className={classNames(classes.filterText, classes.filterDate)}>
          { ( openDRP || Boolean(startDate) || Boolean(endDate) )  
            ? <DateRangePicker
                isOutsideRange={(date) => date.startOf('day').isAfter()}
                startDate={startDate}
                startDateId="startDateId"
                endDate={endDate}
                endDateId="endDateId"
                onDatesChange={this.handleChangeDates}
                focusedInput={focusedInput}
                onFocusChange={focusedInput => this.setState({ focusedInput })}
                noBorder
                small
                startDatePlaceholderText='DATA INICIAL'
                endDatePlaceholderText='DATA FINAL'
                hideKeyboardShortcutsPanel
                numberOfMonths={1}
                minimumNights={0}
                onClose={() => {
                  if (!Boolean(startDate) && !Boolean(endDate)) {
                    this.setState({openDRP: false, focusedInput: 'startDate'})
                  }
                }}
              />
            : <span onClick={() => this.setState({openDRP: true})} className={classes.filterDateButton}>FILTRAR POR DATA</span>
          }
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles)
)(SearchFilter);
