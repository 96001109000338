import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar } from '@clippingcacd/core';
import { HiOutlineArrowRight } from 'react-icons/hi';

import * as S from './styles';
import {
  createComment,
  getComments,
  getUsers,
} from '../../../../services/comments';
import { addQuestionComment } from '../../../../actions/questionsCommentsActions';

export default function WriteComment({ objectResource, resource }) {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const textareaRef = useRef(null);
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [showCommentButton, setShowCommentButton] = useState(false);

  async function handleComment() {
    try {
      setLoading(true);

      const { data: createdComment } = await createComment({
        topic: `${resource}_${objectResource._id}`,
        text,
        parentComment: null,
      });

      const { data: users } = await getUsers([createdComment.user]);

      if (users && users.length) {
        createdComment.user = users[0];
      }

      setText('');
      dispatch(
        addQuestionComment(resource, createdComment, objectResource._id)
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  function handleOnChange(e) {
    if (text.length >= 5000) return setText(e.target.value.substring(0, 1000));
    setText(e.target.value);
  }

  useEffect(() => {
    textareaRef.current.style.height = '0px';
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = scrollHeight + 'px';
  }, [text]);

  return (
    <S.Root>
      <S.EditorContainer>
        <S.TempEditor
          ref={textareaRef}
          placeholder='Participe da discussão...'
          value={text}
          onChange={handleOnChange}
          disabled={loading && text.length === 0}
          onFocus={() => {
            setShowCommentButton(true);
          }}
          onBlur={() => {
            if (text.length === 0) {
              setShowCommentButton(false);
            }
          }}
        />

        {showCommentButton && (
          <S.CommentButton
            startIcon={<HiOutlineArrowRight size={20} />}
            onClick={handleComment}
            loading={loading}
            disabled={text.length === 0}
          />
        )}
      </S.EditorContainer>

      <S.CharacterCounter>{text.length}/5000</S.CharacterCounter>
    </S.Root>
  );
}
