// @flow

import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { withStyles } from 'material-ui/styles';
import {activeRoute} from '../../utils';
import { replace } from 'react-router-redux';
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';

const styles = theme => ({
  wrapper: {
		cursor: 'pointer',
    flex: 1,
    alignItems: 'center',
    borderRadius: '3px',
    display: 'flex',
		background: '#fff',
		width: '100%',
		marginTop: '8px',
		marginBottom: '8px',
		padding: '8px',
		boxShadow: theme.shadows[25],
		paddingLeft: '12px',
		'&$active': {
			paddingLeft: '10px',
			borderLeftColor: theme.palette.clearOrange,
			borderLeftStyle: 'solid',
			borderLeftWidth: '2px'
    },
    ...theme.typography.small,
    color: theme.palette.text.basic,
    fontSize: '14px',
    lineHeight: '13px',
    textDecoration: 'none',
    minHeight: '60px'
	},
  active: {

  }
});

class NavItem extends React.Component {
  handleClick = (clipping, _id) => {
    this.props.pushRouter({clippingId: clipping, articleId: _id})
    this.props.closeDrawer();
  }

  track = () => {
    const { clipping, _id, type, trackMetric } = this.props;
    
    trackMetric('Clippings - Ativação', {
      target: 'navigation',
      trigger: 'click',
      resource: _id,
      type,
      parentResource: clipping
    });
  }

  handleClick = () => {
    const { scrollTop, replaceRouter, clipping, _id } = this.props;
    replaceRouter(`/clippings/${clipping}/${_id}`);
    scrollTop();
  }

  render() {
    const { classes, clipping, _id , title, number, location } = this.props;
    const active = activeRoute(`${clipping}/${_id}`, location) ? classes.active : '';

    return (
      <div onClick={this.track}>
        <div
          onClick={this.handleClick}
          className={classNames(classes.wrapper, active)}
        >          
          {`${number} - ${title}`}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    trackMetric: (name, data) => dispatch(trackMetricAction(name, data)),
    replaceRouter: (path) => dispatch(replace(path))
  }
}

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps),
)(NavItem);