import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import ExpansionPanel, {
  ExpansionPanelSummary,
} from 'material-ui/ExpansionPanel';

import { withStyles } from 'material-ui/styles';
import { Checkbox } from 'material-ui';
import { Tooltip } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import Verified from '../../components/SvgComponents/VerifiedIcon';

import Loading from '../../components/Loading/';

import {
  setChoosePaymentOpen,
  setCreditPayModalTitle,
  setCreditPayModalInstallments,
} from '../Payments/actions';

import { setCartProducts, checkoutSetGoogleGA } from './actions';
import TermsOfUse from './TermsOfUse';
import Alert from './Alert';

const styles = (theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 438px 1fr',
    gridTemplateRows: 'repeat(2, 64px) 1fr',
    fontFamily: 'Proxima Nova',
    paddingBottom: '50px',
    maxWidth: '100%',
    width: '100%',
    '@media (max-width: 1336px)': {
      gridTemplateColumns: '445px 445px 446px',
    },
    '@media (max-width: 578px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '64px 16px auto auto auto',
      maxWidth: 'auto',
      width: 'auto',
    },
  },
  header: {
    gridArea: '1 / 1 / 2 / 4',
    boxShadow: 'inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 578px)': {
      gridArea: '1 / 1 / 2 / 2',
    },
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
  },
  logoText: {
    paddingLeft: '16px',
  },
  logoTextTitle: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.75)',
  },
  cacd: {
    fontSize: '22px',
    color: 'rgba(0, 0, 0, 0.75)',
    fontWeight: 'bold',
  },
  year: {
    fontSize: '22px',
    color: 'rgba(0, 0, 0, 0.75)',
    fontWeight: '300',
  },
  margin: {
    gridArea: '2 / 1 / 3 / 4',
    '@media (max-width: 578px)': {
      gridArea: '2 / 1 / 3 / 2',
    },
  },
  titleContainer: {
    paddingTop: '6px',
    paddingBottom: '24px',
  },
  title: {
    color: 'rgba(0, 0, 0, 0.75)',
    fontSize: '25px',
    height: '28px',
    fontWeight: 'bold',
    paddingBottom: '8px',
  },
  titleDescription: {
    color: 'rgba(0, 0, 0, 0.75)',
    fontSize: '15px',
    fontWeight: 'normal',
    height: '60px',
    width: '342px',
  },
  matriculas: {
    gridArea: '3 / 1 / 4 / 2',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    paddingRight: '32px',
    paddingLeft: '56px',
    borderRight: '1px solid rgba(0, 0, 0, 0.2)',
    '@media (max-width: 578px)': {
      gridArea: '3 / 1 / 4 / 2',
      paddingRight: '16px',
      paddingLeft: '16px',
      borderRight: 'none',
      paddingBottom: '56px',
    },
  },
  infoContainer: {
    width: '374px',
    '@media (max-width: 578px)': {
      width: 'fit-content',
    },
  },
  matriculasAulas: {
    gridArea: '3 / 2 / 4 / 3',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '32px',
    paddingRight: '32px',
    borderRight: '1px solid rgba(0, 0, 0, 0.2)',
    '@media (max-width: 578px)': {
      gridArea: '4 / 1 / 5 / 2',
      paddingRight: '16px',
      paddingLeft: '16px',
      borderRight: 'none',
      paddingBottom: '56px',
    },
  },
  pacoteCompleto: {
    gridArea: '3 / 3 / 4 / 4',
    paddingLeft: '32px',
    paddingRight: '56px',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 578px)': {
      gridArea: '5 / 1 / 6 / 2',
      paddingRight: '16px',
      paddingLeft: '16px',
    },
  },
  expansionPanelStyle: {
    marginBottom: '8px',
    background: 'rgba(0, 0, 0, 0.08)',
    borderRadius: '4px',
    boxShadow: 'none',
    position: 'unset',
    width: '375px',
    '@media (max-width: 578px)': {
      width: '100%',
    },
  },
  lastExpansionPanelStyle: {
    marginBottom: '108px',
    background: 'rgba(0, 0, 0, 0.08)',
    borderRadius: '4px',
    boxShadow: 'none',
    position: 'unset',
    width: '375px',
    '@media (max-width: 578px)': {
      width: '100%',
    },
  },
  expansionPanel: {
    height: '32px',
    width: '374px',
  },
  expansionPanelExpanded: {
    margin: '0px',
  },
  lastExpansionPanelExpanded: {
    margin: '0px 0px 100px 0px',
  },
  expansionSummaryRoot: {
    margin: '0px',
    padding: '0px',
    height: '32px',
    minHeight: '32px',
  },
  expansionSummaryContent: {
    margin: '0px',
    alignItems: 'center',
  },
  expansionPanelSummaryExpanded: {
    minHeight: '32px',
  },
  expansionPanelDisabled: {
    background: 'white',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    opacity: '1',
    height: '32px',
    minHeight: '32px',
    paddingLeft: '8px',
  },
  expansionSummaryDisabled: {
    opacity: '1',
  },
  module: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '16px',
    boxShadow: 'inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1);',
    background: 'white',
  },
  modulePrice: {
    marginLeft: 'auto',
    paddingRight: '8px',
  },
  moduleCheckboxLabel: {
    color: 'rgba(0, 0, 0, 0.75)',
    fontSize: '15px',
    fontWeight: '600',
    margin: '0',
  },
  moduleCheckbox: {
    margin: '0',
  },
  heading: {
    fontSize: '15px',
    fontWeight: '600',
    color: 'rgba(0, 0, 0, 0.75)',
  },
  priceSymbol: {
    color: 'rgba(0, 0, 0, 0.5)',
    fontSize: '15px',
  },
  close: {
    position: 'absolute',
    left: '0',
    marginLeft: '24px',
    '&:hover': {
      color: theme.palette.orange,
    },
    '@media (max-width: 578px)': {
      marginLeft: 0,
    },
  },
  priceValue: {
    color: 'rgba(0, 0, 0, 0.75)',
    fontSize: '15px',
    fontWeight: '600',
  },
  enrollmentButton: {
    color: '#fc7954',
    border: '2px solid rgb(252, 121, 84)',
    textTransform: 'capitalize',
    height: '52px',
    width: '240px',
    borderRadius: '26px',
    fontSize: '16px',
    fontWeight: '600',
    '&:hover': {
      background: '#fc7954',
      color: 'white',
    },
    '@media (max-width: 578px)': {
      width: '100%',
    },
  },
  enrollmentCompleteButton: {
    color: 'white',
    background: '#fc7954',
    textTransform: 'capitalize',
    height: '52px',
    width: '290px',
    borderRadius: '26px',
    fontSize: '16px',
    fontWeight: '600',
    '&:hover': {
      background: '#E95B34',
    },
    '@media (max-width: 578px)': {
      width: '100%',
    },
  },
  priceComparison: {
    paddingBottom: '35px',
    width: '100%',
  },
  parcelPriceComparison: {
    paddingTop: '93px',
    paddingBottom: '36px',
    width: '100%',
    '@media (max-width: 578px)': {
      paddingTop: '0px',
      paddingBottom: '52px',
    },
  },
  checkIcon: {
    color: '#31c98e',
  },
  discount: {
    color: 'rgb(49, 201, 142)',
    fontFamily: 'Proxima Nova',
    fontSize: '15px',
    fontWeight: '600',
    paddingLeft: '6px',
  },
  pacoteCompletoDescricao: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '15px',
    color: 'rgba(0, 0, 0, 0.75)',
    paddingTop: '24px',
  },
  termsOfUseContainer: {
    fontSize: '13px',
    color: 'rgb(0, 0, 0, 0.5)',
    marginBottom: 8,
  },
  termsOfUseLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: 'rgb(25, 83, 171)',
  },
});

const PackageItem = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 100%;
  width: 440px;
  padding-left: 32px;
  padding-right: 32px;
  border-left: ${(props) => props.middle && '1px solid rgba(0, 0, 0, 0.2)'};
  border-right: ${(props) => props.middle && '1px solid rgba(0, 0, 0, 0.2)'};

  @media (max-width: 1334px) {
    min-height: 497px;
    height: fit-content;
    margin-bottom: 32px;
    border-right: ${(props) => props.middle && 'none'};
    padding-bottom: 30px;
    margin-bottom: 36px;
    width: 440px;
    align-items: flex-start;
  }

  @media (max-width: 894px) {
    border-left: ${(props) => props.middle && 'none'};
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 578px) {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: none;
  }
`;

const PackageDivider = styled.div`
  display: none;

  @media (max-width: 578px) {
    margin-left: 2.5vw;
    width: 95vw;
    display: block;
    margin-bottom: 32px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

const PackageTitle = styled.div`
  width: 100%;
  height: fit-content;
  color: rgba(0, 0, 0, 0.75);
  font-size: 25px;
  font-weight: bold;
  padding-bottom: 8px;
`;

const PackageSubtitle = styled.div`
  color: rgba(0, 0, 0, 0.75);
  font-size: 15px;
  font-weight: normal;
  min-height: 60px;
  margin-bottom: 20px;
`;

const ModuleTitle = styled.div`
  flex: 1;
  align-items: center;
  max-height: 32px;
  color: rgba(0, 0, 0, 0.75);
  font-size: 15px;
  font-weight: 600;
`;

const CheckboxStyle = styled(Checkbox)`
  .MuiSvgIcon-root {
    fill: ${(props) =>
      props.checked ? 'rgb(252, 121, 84)' : 'rgba(0, 0, 0, 0.54)'};
  }
`;

const PurschasedStyle = styled.div`
  padding: 12px;
  color: rgba(0, 0, 0, 0.54);
`;

const ModuleItem = styled.div`
  display: flex;
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
  background: white;
  align-items: center;
  padding-left: 16px;
  padding-right: 8px;
  flex-direction: row;
  &:last-child {
    box-shadow: none;
  }
`;

const ModuleItemTitle = styled.span`
  flex: 1;
  color: rgba(0, 0, 0, 0.75);
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
`;

const ModuleItemOwned = styled.div`
  line-height: 18px;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  background: #31c98e;
  border-radius: 4px;
  text-transform: uppercase;
  width: fit-content;
  cursor: help;
  padding: 3px 8px 0px 8px;
`;

const ModuleItemPriceContainer = styled.div`
  display: flex;
`;

const ModuleItemPricePrefix = styled.span`
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
`;

const ModuleItemPrice = styled.span`
  color: rgba(0, 0, 0, 0.75);
  font-size: 15px;
  font-weight: 600;
`;

const PaymentInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
`;

const PaymentButton = styled.button`
  font-family: 'Proxima Nova';
  width: 240px;
  height: 52px;
  background-color: transparent;
  border: 2px solid rgb(252, 121, 84);
  color: rgb(252, 121, 84);
  border-radius: 26px;
  font-size: 16px;
  font-weight: 600;
  transition: background 0.2s;
  &:hover {
    background: #fc7954;
    color: white;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const PaymentDisabledButton = styled.button`
  font-family: 'Proxima Nova';
  width: 240px;
  height: 52px;
  background-color: #f7f8f9;
  border: 2px solid rgba(97, 97, 98, 0.08);
  color: rgba(0, 0, 0, 0.3);
  border-radius: 26px;
  font-size: 16px;
  font-weight: 600;
  cursor: not-allowed;
  @media (max-width: 578px) {
    width: 100%;
  }
`;
const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-top: auto;
  @media (max-width: 1334px) {
    margin-top: 30px;
  }
`;

const PricePrefix = styled.span`
  height: fit-content;
  color: rgba(0, 0, 0, 0.5);
  font-size: 25px;
  font-weight: 300;
`;

const Price = styled.span`
  height: fit-content;
  color: rgba(0, 0, 0, 0.75);
  font-size: 25px;
  font-weight: bold;
  padding-left: 4px;
`;

const CashPriceContainer = styled.div`
  color: rgba(0, 0, 0, 0.5);
`;

const Installments = styled.div`
  height: fit-content;
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  font-weight: normal;
`;

const Terms = styled.div`
  display: flex;
  align-items: center;
  color: rgb(0, 0, 0, 0.5);
  margin-bottom: 8px;
  margin-top: 35px;
`;

const TermsText = styled.span`
  font-size: 13px;
  font-weight: bold;
`;

const TermsCheckBox = styled(Checkbox)`
  .MuiSvgIcon-root {
    fill: ${(props) =>
      props.checked ? 'rgb(252, 121, 84)' : 'rgba(0, 0, 0, 0.54)'};
  }
`;

const TermsLink = styled.a`
  text-decoration: underline;
  color: rgb(25, 83, 171);
  cursor: pointer;
`;

function Package({ type, middle = false, classes }) {
  const dispatch = useDispatch();
  const checkoutPackages = useSelector(
    (state) => state.checkout.currentCheckout.data[type]
  );

  const [alertOpen, setAlertOpen] = useState(false);
  const cartItems = useSelector((state) => state.checkout.cart.items);
  const userID = useSelector((state) => state.userData.data.id);
  const userPayments = useSelector(
    (state) => state.paymentGroup.userPayments.data
  );
  const { ano, categoria, concurso, nome } = useSelector(
    (state) => state.checkout.currentCheckout
  );

  const [expanded, setExpanded] = useState('');
  const [packages, setPackages] = useState(checkoutPackages);
  const [pacotes, setPacotes] = useState(packages.pacotes);
  const [checkedAll, setCheckedAll] = useState(packages.checkedAll);
  const [indeterminate, setIndeterminate] = useState(packages.indeterminate);
  const [price, setPrice] = useState(0);
  const [aVista, setAvista] = useState(0);
  const [update, setUpdate] = useState(0);
  const [openTerms, setOpenTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [termChecked, setTermChecked] = useState(false);

  useEffect(() => {
    try {
      setLoading(true);
      const copyPacotes = { ...checkoutPackages };
      let matriculaIsPaid = false;

      for (const [i, pacote] of copyPacotes.pacotes.entries()) {
        if (copyPacotes.tipo === 'matriculas') {
          matriculaIsPaid =
            userPayments.matriculas.find(
              (matricula) => matricula.pacote === pacote.id
            ) !== undefined;
        }
        let moduleIsPaid =
          userPayments.modulos.find(
            (modulo) => modulo.moduleID === pacote.modulo_id
          ) !== undefined;
        let courseIsPaid =
          userPayments.cursos.find(
            (curso) => curso.courseID === pacote.curso_id
          ) !== undefined;
        copyPacotes.pacotes[i] = {
          ...pacote,
          paid: matriculaIsPaid || moduleIsPaid || courseIsPaid,
        };
      }

      setPackages(copyPacotes);

      setLoading(false);
    } catch (error) {}
  }, [JSON.stringify(userPayments)]);

  useEffect(() => {
    for (const modulo of packages.modulos) {
      const checked = pacotes.filter(
        (pacote) => pacote.modulo === modulo && pacote.checked
      );

      if (checked.length && cartItems.length) {
        setExpanded(`modulo-${modulo}`);
      }
    }

    let _price = 0;
    let _aVista = 0;

    pacotes
      .filter((items) => items.checked && !items.paid)
      .map((pacote) => {
        _price += pacote.value_prazo;
        _aVista += pacote.value;
      });

    setPrice(_price);
    setAvista(_aVista);
  }, [update, checkedAll, price, cartItems]);

  function handleExpanded(moduleName) {
    if (expanded === moduleName) {
      return setExpanded('');
    }

    return setExpanded(moduleName);
  }

  function handleCheckAll(ev, moduleNumber) {
    setIndeterminate({ ...indeterminate, [moduleNumber]: false });
    setCheckedAll({ ...checkedAll, [moduleNumber]: ev.target.checked });

    const copyPacotes = pacotes.map((pacote) => {
      if (pacote.modulo === moduleNumber) {
        return {
          ...pacote,
          checked: ev.target.checked,
        };
      }

      return pacote;
    });

    let _price = 0;
    let _aVista = 0;

    copyPacotes
      .filter((items) => items.checked && !items.paid)
      .map((pacote) => {
        _price += pacote.value_prazo;
        _aVista += pacote.value;
      });

    setPrice(_price);
    setAvista(_aVista);

    setPacotes(copyPacotes);
    setUpdate(update + 1);
  }

  function handlePacotes(product) {
    const copyPacotes = pacotes;
    const [hasItem] = pacotes.filter((items) => items.id === product.id);

    if (hasItem.checked) {
      const index = copyPacotes.indexOf(hasItem);
      copyPacotes[index].checked = false;
      setPacotes(copyPacotes);
      setUpdate(update + 1);
    } else {
      const index = copyPacotes.indexOf(hasItem);
      copyPacotes[index].checked = true;
      setPacotes(copyPacotes);
      setUpdate(update + 1);
    }

    const pacotesPorModulo = pacotes.filter(
      (items) => items.modulo === hasItem.modulo
    );

    if (
      pacotesPorModulo.length ===
      pacotesPorModulo.filter((items) => items.checked === true).length
    ) {
      setCheckedAll({ ...checkedAll, [hasItem.modulo]: true });
      setIndeterminate({ ...indeterminate, [hasItem.modulo]: false });
    } else {
      setCheckedAll({ ...checkedAll, [hasItem.modulo]: false });
      setIndeterminate({ ...indeterminate, [hasItem.modulo]: true });

      if (
        pacotesPorModulo.filter((items) => items.checked === true).length === 0
      ) {
        setIndeterminate({ ...indeterminate, [hasItem.modulo]: false });
      }
    }

    let _price = 0;
    let _aVista = 0;

    copyPacotes
      .filter((items) => items.checked && !items.paid)
      .map((pacote) => {
        _price += pacote.value_prazo;
        _aVista += pacote.value;
      });

    setPrice(_price);
    setAvista(_aVista);
  }

  function handlePayment() {
    const checkedPackages = pacotes.filter(
      (items) => items.checked && !items.paid
    );
    // .map((pacote) => {
    //   return { id: pacote.id };
    // });

    if (!checkedPackages.length) {
      // return alert("Selecione os produtos");
      return setAlertOpen(true);
    }

    const itemsPayload = [];
    for (const item of checkedPackages) {
      let payload = {
        id: item.id,
        name: `${item.curso}-modulo-${item.modulo}`,
        category: `${categoria}-${ano}`,
        brand: concurso,
        price: item.value,
        quantity: 1,
      };
      if (type === 'matriculas') {
        payload.variant = 'matricula';
      } else {
        payload.variant = 'matricula + aula';
      }
      itemsPayload.push(payload);
    }

    const purchasePayload = {
      id: `${userID}`,
      affiliation: concurso,
      revenue: price,
      tax: 0,
      shipping: 0,
    };

    dispatch(
      checkoutSetGoogleGA({ items: itemsPayload, purchase: purchasePayload })
    );
    dispatch(setCreditPayModalInstallments(packages.parcelas));
    dispatch(
      setCreditPayModalTitle(`Comprar ${String(packages.titulo).toLowerCase()}`)
    );
    dispatch(
      setCartProducts({
        items: checkedPackages,
        price: {
          a_vista: aVista || 0,
          parcelado: price,
        },
      })
    );
    dispatch(setChoosePaymentOpen(true));
  }

  function getPriceTerm(valor, parcelas) {
    return (valor / parcelas).toFixed(2).replace('.', ',');
  }

  function PriceComposer() {
    if (packages.parcelas === 1) {
      return (
        <PriceContainer>
          <Price>R${aVista.toFixed(2).replace('.', ',')}</Price>
        </PriceContainer>
      );
    }

    return (
      <React.Fragment>
        <Installments>parcele até {packages.parcelas}x</Installments>
        <PriceContainer>
          <div>
            <PricePrefix>R$</PricePrefix>
            <Price>{getPriceTerm(price, packages.parcelas)}</Price>
          </div>
          <CashPriceContainer>
            OU <b>R${aVista.toFixed(2).replace('.', ',')}</b> À VISTA
          </CashPriceContainer>
        </PriceContainer>
      </React.Fragment>
    );
  }

  return (
    <div>
      <PackageItem middle={middle}>
        <PackageTitle>{packages.titulo}</PackageTitle>
        <PackageSubtitle>{packages.sub_titulo}</PackageSubtitle>
        <Alert
          message={'Selecione algum produto para efetuar a compra'}
          open={alertOpen}
          setClose={() => setAlertOpen(false)}
        />
        {packages.modulos.length > 0
          ? packages.modulos.map((modulo, index) => {
              const block_id = `modulo-${modulo}`;
              const last_child = index === packages.modulos.length - 1;

              return (
                <ExpansionPanel
                  key={index}
                  expanded={expanded === block_id}
                  onClick={(ev) => {
                    ev.stopPropagation();
                    handleExpanded(`modulo-${modulo}`);
                  }}
                  className={
                    last_child
                      ? classes.lastExpansionPanelStyle
                      : classes.expansionPanelStyle
                  }
                  classes={{
                    expanded: last_child
                      ? classes.lastExpansionPanelExpanded
                      : classes.expansionPanelExpanded,
                  }}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMore style={{ right: '0px' }} />}
                    classes={{
                      root: classes.expansionSummaryRoot,
                      content: classes.expansionSummaryContent,
                      expanded: classes.expansionPanelSummaryExpanded,
                    }}
                  >
                    <Checkbox
                      value={checkedAll[modulo].toString()}
                      checked={checkedAll[modulo]}
                      indeterminate={indeterminate[modulo]}
                      onClick={(ev) => {
                        ev.stopPropagation();
                        handleCheckAll(ev, modulo);
                      }}
                    />
                    <ModuleTitle>Módulo {modulo} </ModuleTitle>
                  </ExpansionPanelSummary>
                  {loading ? (
                    <Loading sidebar={true} />
                  ) : (
                    pacotes
                      .filter((items) => items.modulo === modulo)
                      .map((pacote) => {
                        if (pacote.paid) {
                          return (
                            <ModuleItem
                              onClick={(ev) => {
                                ev.stopPropagation();
                              }}
                              key={pacote.id}
                            >
                              <PurschasedStyle>
                                <Verified
                                  width={'24px'}
                                  height={'24px'}
                                  color={'#31c98e'}
                                />
                              </PurschasedStyle>
                              <ModuleItemTitle>{pacote.curso}</ModuleItemTitle>
                              <Tooltip title={'Você já possui esse pacote.'}>
                                <ModuleItemOwned>Obtido</ModuleItemOwned>
                              </Tooltip>
                            </ModuleItem>
                          );
                        }

                        return (
                          <ModuleItem
                            key={pacote.id}
                            onClick={(ev) => {
                              ev.stopPropagation();
                              handlePacotes(pacote);
                            }}
                          >
                            <CheckboxStyle checked={pacote.checked} />
                            <ModuleItemTitle>{pacote.curso}</ModuleItemTitle>
                            <ModuleItemPriceContainer>
                              <ModuleItemPricePrefix>R$</ModuleItemPricePrefix>
                              <ModuleItemPrice>
                                {pacote.value.toFixed(2).replace('.', ',')}
                              </ModuleItemPrice>
                            </ModuleItemPriceContainer>
                          </ModuleItem>
                        );
                      })
                  )}
                </ExpansionPanel>
              );
            })
          : 'Não há pacotes disponíveis.'}
        {packages.modulos.length > 0 && (
          <PaymentInfo>
            <PriceComposer />
            <Terms>
              <TermsCheckBox
                checked={termChecked}
                defaultChecked={termChecked}
                onChange={() => setTermChecked(!termChecked)}
              />
              <TermsText>
                Declaro que li e concordo com os {'  '}
                <TermsLink onClick={() => setOpenTerms(true)}>
                  Termos de Uso e Política de Privacidades
                </TermsLink>
              </TermsText>
            </Terms>
            {termChecked ? (
              <PaymentButton onClick={() => handlePayment()}>
                {packages.buttom ? packages.buttom : 'Efetuar pagamento'}
              </PaymentButton>
            ) : (
              <PaymentDisabledButton>
                {packages.buttom ? packages.buttom : 'Efetuar pagamento'}
              </PaymentDisabledButton>
            )}
          </PaymentInfo>
        )}
      </PackageItem>
      <PackageDivider />
      <TermsOfUse open={openTerms} closeModal={setOpenTerms} />
    </div>
  );
}

export default withStyles(styles, { index: 1 })(Package);
