import React, { Component } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "material-ui/styles";
import Paper from "material-ui/Paper";
import Switch from "material-ui/Switch";
import { Button } from "../../components/common";
import Tooltip from "material-ui/Tooltip";
import HelpIcon from "material-ui-icons/HelpOutline";
import Cached from "material-ui-icons/Cached";
import KeyboardVoice  from "material-ui-icons/KeyboardVoice"
import Stop  from "material-ui-icons/Stop"
import upload from "../../utils/upload";
import moment from "moment";
import {
  crudUpdate as crudUpdateAction,
  crudCreate as crudCreateAction
} from "../../actions/dataActions";
import { createAudio as createAudioAction } from "../../actions/extraActions";
const RecordRTC = require("recordrtc");

const styles = theme => ({
  container: {
    padding: "16px 30px",
    maxWidth: "80vw",
    marginBottom: "16px",
    "& pre": {
      "&:first-child": {
        marginTop: "0"
      },
      margin: "8px 0",
      whiteSpace: "pre-line",
      fontFamily: theme.typography.fontFamily2
    },
    "& div": {
      fontFamily: theme.typography.fontFamily2
    },
    "& h3": {
      marginBottom: "8px"
    }
  },
  flexContainer: {
    display: "flex"
  },
  flexDiv1: {
    flex: 1
  },
  flexDiv2: {
    flex: 2
  },
  flexDiv3: {
    flex: 3,
    paddingRight: "30px"
  },
  flexDiv4: {
    flex: 4
  },
  recordButton:{
    color:"white",
    border:"none",
    background:"red",
    width:"40px",
    height:"40px",
    borderRadius:"50%"
  },
  stopButton:{
    color:"green",
    background:"white",
    border:" 1px solid green",
    width:"40px",
    height:"40px",
    borderRadius:"50%"
  },
  recordingContainer: {
    marginTop: "16px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center"
  },
  score: {
    marginLeft: "24px",
    fontSize: "20px"
  },
  link: {
    ...theme.typography.link
  },
  textArea: {
    width: "100%",
    padding: "8px",
    borderRadius: "3px",
    border: "1px solid rgba(0,0,0,0.1)",
    marginBottom: "14px",
    fontSize: "15px",
    marginTop: "16px",
    "&:focus": {
      height: "306px"
    }
  },
  input: {
    borderRadius: "3px",
    border: "1px solid rgba(0,0,0,0.1)",
    fontSize: "15px",
    padding: "8px"
  },
  shareButton: {
    marginLeft: "auto",
    fontSize: "18px",
    display: "flex",
    alignItems: "center"
  },
  audioDisabled: {
    opacity: "0.5"
  },
  timer: {
    marginLeft: "10px",
    fontSize: "18px"
  },
  helpIcon: {
    marginLeft: "6px",
    color: "rgba(0,0,0,.6)",
    cursor: "pointer"
  },
  cached: {
    fontSize: theme.typography.pxToRem(24),
    width: "1em",
    height: "1em",
    // Chrome fix for https://bugs.chromium.org/p/chromium/issues/detail?id=820541
    // To remove at some point.
    overflow: "hidden",
    flexShrink: 0,
    webkitAnimation: "girar  1.5s linear infinite",
    animation: "rotate 1.5s linear infinite"
  },
  "@keyframes girar": {
    transform: "rotate(360deg);"
  }
});

var recordRTC = null;
var microphone = null;

class CardItemCorrecao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audioComments: [],
      newAudioComment: false,
      score: isNaN(props.score)? "" : props.score,
      comments: props.comments || "",
      recording: false,
      timerSeconds: 0,
      timer: null,
      waiting: false
    };
  }

  componentWillReceiveProps(nextProps) {
    let { audioComments = [], score = "", comments = "" } = nextProps;

    if (
      this.state.audioComments !== audioComments ||
      this.state.score !== score ||
      this.state.comments !== comments
    ) {
      this.setState({ audioComments, score, comments });
    }
  }

  startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      microphone = stream;

      recordRTC = RecordRTC(stream, {
        recorderType: RecordRTC.MediaStreamRecorder,
        mimeType: "audio/webm"
      });

      var timer = setInterval(() => {
        var { timerSeconds } = this.state;
        this.setState({ timerSeconds: timerSeconds + 1 });
      }, 1000);

      recordRTC.startRecording();
      this.setState({ timer, recording: true });
    });
  };

  stopRecording = () => {
    const { question, user } = this.props;
    const { timer } = this.state;

    recordRTC.stopRecording(() => {
      microphone.stop();
      clearInterval(timer);

      this.setState({ timerSeconds: 0, recording: false });

      var container = "user-media-for-streaming";
      var fileName = `question-${question._id}/user-${
        user._id
      }/comment-${Date.now()}.webm`;
      var blob = recordRTC.getBlob();

      var file = new File([blob], `comment-${Date.now()}.webm`, {
        type: "audio/webm"
      });

      upload(container, file, fileName, error => {
        if (!error) {
          var blobUrl = `https://avatars.blob.core.windows.net/${container}/${fileName}`;
          this.setState({ newAudioComment: blobUrl });
        } else {
          alert("Ocorreu um erro com o upload do arquivo...");
        }
      });
    });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  sendAudio = () => {
    var { createAudio, _id } = this.props;
    var { newAudioComment } = this.state;
    createAudio(_id, newAudioComment);
  };

  sendComments = () => {
    var { crudUpdate, _id } = this.props;
    var { comments, score } = this.state;
    var data = { comments: comments };
    Boolean(score) && (data.score = score);
    crudUpdate("questionEssayAnswer", _id, data, {}, "", false);
  };

  handleShare = () => {
    var { crudUpdate, _id } = this.props;
    var confirmation = confirm(
      "Tem certeza que deseja compartilhar a sua nota com outros usuários do clipping? Essa ação não pode ser desfeita."
    );

    if (confirmation) {
      var data = { shared: true };
      crudUpdate("questionEssayAnswer", _id, data, {}, "", false);
    }
  };
  componentWillReceiveProps(nextProps) {
    if (this.props.waiting != nextProps.waiting) {
      this.setState({ waiting: nextProps.waiting });
      if (nextProps.saved) {
        this.setState({ newAudioComment: false });
      }
    }
  }

  parseTimer = timerSeconds => {
    var integer = Math.floor(timerSeconds / 60);
    var decimals = timerSeconds % 60;
    return `${("00" + integer).slice(-2)}:${("00" + decimals).slice(-2)}`;
  };

  showFile = file =>{
    var fileName = file && file.split("/").slice(-1);
    let type = file.split('.').slice(-1);
    if(type=="pdf"){
      return (<embed src={`${file}#toolbar=0&navpanes=0&scrollbar=1`} width="100%" height="600px" />)
    }else if(type == "jpeg" || type == "jpg" || type == "png "){
       return (<embed src={file} style={{width:'100%'}} />)
    }

    return (<a href={file} target="_blank"> {fileName} </a>)

  }
  render() {
    const {
      classes, introduction, bullets, conclusion, files, createdAt, question,
      item, user, shared, type
    } = this.props;
    const {
      audioComments,
      newAudioComment,
      recording,
      score,
      comments,
      timerSeconds,
      waiting
    } = this.state;

    var fileName = files[0] && files[0].split("/").slice(-1);
    var valid = Boolean(score);
    var isCorrecao = type === "redacao-correcao";
    return (
      <div>
        <Paper className={classes.container}>
          {isCorrecao && <h2>{user.name} </h2>}
          {isCorrecao && (
            <h5 style={{ color: "gray" }}>
              {" "}
              Enviado em {moment(createdAt).format(
                "DD/MM/YYYY [às] HH:mm"
              )} - {moment().diff(moment(createdAt), "h")} horas atrás
            </h5>
          )}
          <h3>Questáo</h3>
          <div dangerouslySetInnerHTML={{ __html: question.statement }} />
          <div dangerouslySetInnerHTML={{ __html: item.text }} />
          <hr/>
          <div className={classes.flexContainer}>
            <div className={classes.flexDiv3}>
              <h3>Resposta</h3>
              {Boolean(introduction) && (
                <div style={{wordBreak: "break-all"}}>
                  <pre>{introduction}</pre>
                  <pre>{bullets}</pre>
                  <pre>{conclusion}</pre>
                </div>
              )}

              {Boolean(files[0]) && (
                <div>

                    {this.showFile(files[0])}
                </div>
              )}
            </div>
            <div className={classes.flexDiv1}>

              {this.props.audioComments.map((audio, i) => {
                return (
                  <p>
                    <audio id={i} controls src={audio}></audio>
                  </p>
                );
              })}
              <hr />
              <div className={classes.recordingContainer}>
                {isCorrecao && (
                  <audio
                    className={classes.audioDisabled}
                    style={{width: "60%"}}
                    controls
                    src={newAudioComment}
                  ></audio>
                )}
                {!recording && isCorrecao && (
                  <button className={classes.recordButton} onClick={this.startRecording}>
                    <KeyboardVoice/>
                  </button>
                )}
                {recording && isCorrecao && (
                  <button className={classes.stopButton} onClick={this.stopRecording}>
                    <Stop/>
                  </button>
                )}

                {isCorrecao && recording && (
                  <div className={classes.timer}>
                    {this.parseTimer(timerSeconds)}
                  </div>
                )}
                {!isCorrecao && Boolean(score) && (
                  <div className={classes.score}>{score} pts</div>
                )}
                {!isCorrecao && !shared && Boolean(score) && (
                  <div className={classes.shareButton}>
                    <Switch checked={false} onChange={this.handleShare} />
                    Tornar pública
                    <Tooltip
                      className={classes.helpIcon}
                      title="Deixe sua correção pública para ver outras respostas"
                    >
                      <HelpIcon />
                    </Tooltip>
                  </div>
                )}
                {isCorrecao && (
                  <Button
                    buttonFull={true}
                    disabled={!newAudioComment || waiting}
                    onClick={this.sendAudio}
                  >
                    {waiting && (
                      <span className={classes.cached}>
                        <Cached />
                      </span>
                    )}
                    {!waiting && "Enviar Comentário"}
                  </Button>
                )}
              </div>
              <hr />
              {(isCorrecao || Boolean(comments)) && (
                <textarea
                  placeholder="Comentar por texto (opcional)"
                  onChange={this.handleChange("comments")}
                  value={comments}
                  className={classes.textArea}
                  rows="20"
                ></textarea>
              )}

              {isCorrecao && (
                <input
                  placeholder="Nota"
                  onChange={this.handleChange("score")}
                  className={classes.input}
                  style={{    width: "60px"}}
                  value={score.toString()}
                  type="text"
                />
              )}
              {}
              {isCorrecao && (
                <Button
                  buttonFull={true}
                  disabled={!valid}
                  onClick={this.sendComments}
                >
                  Enviar
                </Button>
              )}
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    crudUpdate: (...params) => {
      dispatch(crudUpdateAction(...params));
    },
    createAudio: (_id, newAudioComment) => {
      dispatch(
        createAudioAction(_id, { newAudioComment: newAudioComment }, false)
      );
    }
  };
};
const mapStateToProps = (state, { open }) => {
  return {
    waiting: state.waiting,
    saved: state.waiting
  };
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CardItemCorrecao);
