import React from 'react';

const FooterNav = () => (
  <nav className="footer-nav">
      <ul>
          <li><a href="#">Sobre</a></li>
          <li><a href="#">Blog</a></li>
          <li><a href="#">Suporte</a></li>
          <li><a href="#">Privacidade</a></li>
          <li><a href="#">Termos</a></li>
      </ul>
  </nav>
);

export default FooterNav;