import React, { Component } from 'react'
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from 'material-ui/styles';
import classNames from 'classnames';
import {Checkbox} from '../../components/common';
import {extrasCheckUserStatus as extrasCheckUserStatusAction} from '../../actions/extraActions'
import _ from 'lodash'
import { Link } from 'react-router';
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';

const styles = theme => ({
  checkbox: {
    marginRight: '10px',
    float: 'left'
  },
  container: {
    clear: 'both',
    marginBottom: '14px',
    '&:last-child': {
      marginBottom: '0'
    }
  },
  textLink: {
    color: '#4A90E2',
    textDecoration: 'none',
    cursor: 'pointer'
  }
})

class ListItem extends Component {
  constructor(props) {
    super(props)

    const {userStatus} = props;

    this.state = { 
      checked: userStatus.length > 0
    }
  }

  track = (trigger) => {
    const {
      _id,
      extraSection,
      trackMetric
    } = this.props;
    
    Boolean(_id) && trackMetric('Extras - Ativação', { target: 'extra', trigger, resource: _id, parentResource: extraSection });
  } 

  handleClickItem = () => {
    const {
      _id,
      userStatus,
      extraSection,
      extrasCheckUserStatus,
    } = this.props;

    this.track('check')
    
    let checked = userStatus.length > 0;
    extrasCheckUserStatus(extraSection, _id, checked);
  }

  getAnchor() {
    const {title, url, classes} = this.props;
    const urlLink = url.trim() === '' ? undefined : url.trim();

    let urlParts = urlLink.split('/');

    return (
      <div onClick={() => this.track('click')}>
        {(urlParts && urlParts[urlParts.length - 3] === 'clippings')
          ? <Link className={classNames(classes.textLink)} to={`/extras/resumo/${urlParts[urlParts.length-2]}/${urlParts[urlParts.length-1]}`}>{title}</Link>
          : <a className={classNames(urlLink ? classes.textLink : false)} target="_blank" href={urlLink}>{title}</a>}
      </div>
    );
  }

  render() {
    const {_id, classes, userStatus} = this.props
    let checked = userStatus.length > 0;

    return (
      <div className={classes.container}>
        <div className={classes.checkbox}>
          <Checkbox id={_id} label={{onClick: () => this.handleClickItem()}} checked={checked}/>
        </div>  
        <div style={{marginLeft: '25px'}} >
          {this.getAnchor()}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    extrasCheckUserStatus: (...params) => dispatch(extrasCheckUserStatusAction(...params)),
    trackMetric: (name, data) => dispatch(trackMetricAction(name, data))
  }
}

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps),
)(ListItem);