import { useState } from 'react';
import Button from 'material-ui/Button';
import Dialog, { DialogContent, DialogTitle } from 'material-ui/Dialog';
import Close from 'material-ui-icons/Close';
import IconButton from 'material-ui/IconButton';
import { makeStyles } from '@material-ui/core';

const styles = (theme) => ({
  container: {
    borderRadius: '8px',
    minHeight: '247px',
    paddingTop: (props) => (props?.title ? '' : '64px'),
    [theme.breakpoints.up('md')]: {
      minWidth: '700px',
    },
    '@media (max-width: 1024px)': {
      paddingTop: (props) => (props?.title ? '' : '0x'),
      minHeight: 'auto',
      margin: 0,
      maxWidth: '95vw',
      width: '95vw',
    },
  },
  title: {
    color: theme.palette.orange,
    borderBottom: '1px solid #96969626',
    fontSize: '15px',
    fontFamily: 'Inter',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '64px',
    paddingLeft: '16px',
    paddingRight: '16px',
    '& span': {
      fontSize: '15px',
      color: 'rgba(0,0,0,0.5)',
    },
    '@media (max-width: 1024px)': {
      fontSize: '12px',
    },
  },
  close: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    margin: '10px',
    color: theme.palette.text.clear,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  responsiveEmbed: {
    overflow: 'hidden',
    paddingBottom: '56.25%',
    position: 'relative',

    '& iframe': {
      left: 0,
      top: 0,
      height: '100%',
      width: '100%',
      position: 'absolute',
    },
    '@media (max-width: 900px)': {
      height: 0,
    },
  },
  content: {
    padding: '18px',
    '@media (max-width: 1024px)': {
      padding: '5px',
    },
  },
  spanButton: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

const useStyles = makeStyles(styles);

export function YoutubeEmbed({ embedId }) {
  const classes = useStyles();

  return (
    <div className={classes.responsiveEmbed}>
      <iframe
        width='853'
        height='480'
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        title='Embedded youtube'
      />
    </div>
  );
}

function YoutubeDialog({ onClose, open, title, embedId, children }) {
  const classes = useStyles({ title: Boolean(title) });

  return (
    <Dialog open={open} classes={{ paper: classes.container }}>
      <IconButton className={classes.close} onClick={onClose}>
        <Close />
      </IconButton>

      {title && (
        <DialogTitle disableTypography className={classes.title}>
          <h2>{title}</h2>
        </DialogTitle>
      )}

      <DialogContent className={classes.content}>
        {children}
        {embedId && <YoutubeEmbed embedId={embedId} />}
      </DialogContent>
    </Dialog>
  );
}

export default function YoutubeModal({
  title,
  embedId,
  children,
  numberQuestions,
}) {
  const [open, setOpen] = useState(false);
  const classes = useStyles({ title: Boolean(title) });

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div>
      <span variant='outlined'>
        <span className={classes.spanButton} onClick={handleClickOpen}>
          <u
            style={{
              backgroundColor: '#F9D55E',
              color: 'rgba(0, 0, 0, 0.6);',
            }}
          >
            Veja as dicas
          </u>
        </span>{' '}
        de como utilizar nossas {numberQuestions} questões comentadas.{' '}
      </span>

      <YoutubeDialog
        open={open}
        onClose={handleClose}
        title={title}
        embedId={embedId}
        children={children}
      />
    </div>
  );
}
