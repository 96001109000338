import styled, { css } from "styled-components";

export const Root = styled.div`
    display: flex;
    background: #FFFFFF;
    padding: 1px 6px;
    border-radius: 8px;
    overflow: hidden;
    width: fit-content;
    max-width: 100%;
    margin-top: 16px;

    ${({ disabled }) => disabled && css`
        opacity: .5;
        pointer-events: none;
        user-select: none;
    `}

    ${({ responsive }) => responsive && css`
        flex-direction: column;
        padding: 8px;
        width: 100%;
    `}

    & button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        padding: 0px;

        &:disabled {
            cursor: default;
        }
    }
`;

export const KeywordActiveList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    margin: 0px;

    ${({ responsive }) => responsive && css`
        margin-top: 16px;
    `}
`;

export const KeywordActiveItem = styled.li`
    display: flex;
    align-items: center;
    background: #F2EEED;
    border-radius: 8px;
    padding: 4px 8px;
    font-size: 11px;
    font-weight: 500;
    color: rgba(0, 0, 0, .6);

    margin: 4px 0px;

    & > span {
        word-break: break-word;
    }

    &:not(:last-child) {
        margin-right: 8px;
    }

    & > button {
        margin-left: 4px;

        & > svg {
            width: 14px;
            height: 14px;
            color: rgba(0, 0, 0, .6);
        }
    }

    ${({ responsive }) => responsive && css`
        width: 100%;
        margin-right: 0px !important;

        & > span {
            width: 100%;
        }
    `}
`;

export const ClearButton = styled.div`
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 13px;
    color: rgba(0, 0, 0, .75);
    margin-right: 16px;

    & > span {
        white-space: nowrap;
    }
    
    & > button {
        min-height: 24px;
        min-width: 24px;
        border-radius: 8px;
        margin-right: 8px;
        background: #F2EEED;
    
        svg {
            width: 16px;
            height: 16px;
            color: rgba(0, 0, 0, .4);
        }
    }
`;