import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from 'material-ui/styles';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    marginBottom: '16px'
  },
  label: {
    ...theme.typography.small,
    fontWeight: 600,
    color: theme.palette.text.basic,
    marginBottom: '8px'
  },
  input: {
    ...theme.typography.input,
    border: '1px solid rgba(0,0,0,0.1)',
    borderRadius: '3px',
    padding: '12px 8px',
    width: '100%',
    '&:focus': {
      border: '1px solid rgba(0,0,0,0.5)',
      outline: 'none'
    }
  },
  display: {
    ...theme.typography.input,
    cursor: 'pointer',
    '&:hover $icon': {
      display: 'inline-block'
    }
  },
  disable: {
    color: theme.palette.black.a3,
    cursor: 'text',
    display: 'flex',
    '&:hover $icon': {
      display: 'none'
    },
  },
  icon: {
    color: '#71D3D9',
    marginLeft: '8px',
    display: 'none',
    verticalAlign: 'middle'
  },
  error:{
    color: "#f44336",
    borderColor: "#f44336"
  },
  helperText:{
    margin: 0,
    fontSize: "0.75rem",
    textAlign: "left",
    marginTop: "8px",
    minHeight: "1em",
    fontFamily: ["Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"],
    lineHeight: "1em"
  },
  empty: {
    color: theme.palette.black.a3,
  }
})

class InputText extends Component {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    disable: PropTypes.bool,
    modalEdit: PropTypes.any,
    input: PropTypes.object,
    customClasses: PropTypes.object,
    type: PropTypes.string,
    helperText: PropTypes.any
  }

  static defaultProps = {
    disable: false,
    modalEdit: false,
    input: {},
    customClasses: {},
    type: 'text',
    helperText : false
  }

  render() {
    const {label, classes, input, modalEdit, disable, customClasses, type, helperText} = this.props

    return (
      <div className={classNames(classes.root, customClasses.root)} onClick={() => modalEdit && modalEdit()} style={modalEdit ?  {cursor: 'text'} : {}}>
        <div className={classNames(classes.label, customClasses.label)+" "+(helperText && classes.error)}>{label}</div>
        { modalEdit ? (
          <div className={classNames(classes.display, disable && classes.disable, customClasses.display, !input.value && classes.empty)}>
            {input.value || input.placeholder}
            <i className={`icon-pencil icons ${classes.icon}`}/>
          </div> ) : (
            <div>
            <input className={classNames(classes.input, customClasses.input)+" "+(helperText && classes.error)} type={type} {...input}/>
            { Boolean(helperText) && <span className={classNames(classes.error, classes.helperText)}>{helperText}</span>}
            </div>
          )
        }
      </div>
    )
  }
}


export default withStyles(styles)(InputText)
