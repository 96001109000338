import React, { useEffect, useMemo } from "react";
import { withStyles } from "material-ui/styles";
import { connect, useDispatch, useSelector } from "react-redux";
import { Bar, Line } from "react-chartjs-2";
import Grid from "material-ui/Grid";
import moment from "moment";
import { Chart, LineElement, PointElement, LinearScale } from "chart.js";

import { loadReport } from "../../actions/loadReportActions";

Chart.register(LineElement, PointElement, LinearScale);

moment.locale("pt-BR");

const styles = (theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      minHeight: "calc(height + 10vw)",
      backgroundColor: "#f9f9f9",
    },
  },
  wrapper: {
    flex: 1,
    display: "flex",
    background: "#f9f9f9",
    overflow: "auto",
    overflowX: "hidden",
    height: "100%",

    padding: "6% 7% 10% 7%",
  },
  indicatorsOfUser: {
    display: "flex",
    alignItems: "flex-start",
    direction: "row",

    justify: "flex-start",
    "& h2": {
      margin: "10px 0 0 0",
      padding: 0,
      fontSize: "1.3em",
    },
    "& p": {
      color: "#F8A947",
      margin: 0,
      padding: 0,
      fontSize: "1.3em",
      fontWeight: "300",
    },
  },
  divisor: {
    width: "100%",
    backgroundColor: "#ddd",
    height: "1px",
    margin: "20px 0 0 0",
  },
  chartContent: {
    height: "350px",
    margin: "20px 0 100px 0",
    padding: "0 10px",
    "& h2": {
      fontWeight: "normal",
      fontSize: "0.6em",
    },
  },
});

const ReportContent = ({ classes = {}, _id }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (_id) {
      dispatch(loadReport(_id));
    }
  }, [_id]);

  const ranking = useSelector((state) => state.dataReport?.ranking || []);
  const history = useSelector((state) => state.dataReport?.history?.docs || []);

  const numberOfUsers = ranking?.reduce(
    (acc, curr) => acc + curr.scoreTotal,
    0
  );
  const lblScores = ranking
    .sort((a, b) => a._id - b._id)
    .map((score) => score._id);

  const dataBarXAxis = ranking.map((score) => score.scoreTotal);

  const lastScore = history
    .slice()
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const scoreOfUser = history.find((entry) => !entry.isDeleted);

  const dataBar = useMemo(
    () => ({
      labels: lblScores,
      datasets: [
        {
          label: "Quantidade de pessoas",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: dataBarXAxis,
        },
      ],
    }),
    [lblScores, dataBarXAxis]
  );

  const lineScores = lastScore.map((x) => x.score);
  const monthsLineChart = lastScore.map((x) => moment(x.createdAt).format("L"));

  const dataLine = {
    labels: monthsLineChart.reverse(),
    datasets: [
      {
        label: "Nota",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#000",
        pointBorderWidth: 10,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: lineScores.reverse(),
      },
    ],
  };

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        {history.length > 0 && (
          <Grid container className={classes.indicatorsOfUser}>
            <Grid item xs={12} md={2}>
              <h2>Sua nota</h2>
              <p>{scoreOfUser?.score}</p>
            </Grid>
            <Grid item xs={12} md={2}>
              <h2>Participantes</h2>
              <p>{numberOfUsers}</p>
            </Grid>
            {/* <Grid item xs={12} md={2}>
              <h2>Você ficou em</h2>
              <p>{position}º lugar</p>
            </Grid> */}
            <span className={classes.divisor} />
            <Grid item xs={12} md={6} className={classes.chartContent}>
              <p>Usuários agrupados por nota</p>
              <Bar
                data={dataBar}
                options={{
                  legend: { display: false },
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                        },
                        scaleLabel: {
                          display: true,
                          labelString: "Quantidade de pessoas",
                        },
                      },
                    ],
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Nota",
                        },
                      },
                    ],
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.chartContent}>
              <p>Evolução das suas notas neste simulado</p>

              <Line
                data={dataLine}
                options={{
                  legend: { display: false },
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                        },
                        scaleLabel: {
                          display: true,
                          labelString: "Nota",
                        },
                      },
                    ],
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Data de submissão",
                        },
                      },
                    ],
                  },
                }}
              />
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(connect()(ReportContent));
