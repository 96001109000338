import React, { useEffect, useRef, useState } from "react";
import { Search as SearchIcon } from "@material-ui/icons";

import {
    Root,
    FilterContainer,
    LoadingIndicator
} from "./styles";

export default function KeywordFilter({ value, onChange, onSearch, debounce, loading, searchOnBlur, style }) {
    const [keywords, setKeywords] = useState();
    const debounceSearch = useRef(null);
    const lastSearch = useRef(null);

    function runInDebounce(fn) {
        if(debounceSearch.current)
            clearTimeout(debounceSearch.current);

        debounceSearch.current = setTimeout(() => {
            fn();
        }, debounce);
    }

    function handleSearch(value) {
        if(lastSearch.current === value)
            return;

        if(debounceSearch.current)
            clearTimeout(debounceSearch.current);

        if(typeof onSearch === "function")
            onSearch(keywords);

        lastSearch.current = value;
    }

    function handleChange(e) {
        setKeywords(e.target.value);
        
        if(typeof onChange === "function")
            onChange(e.target.value);

        if(debounce)
            runInDebounce(() => {
                if(typeof onSearch === "function")
                    onSearch(e.target.value);
            });
    }

    function handleBlur(e) {
        if(!searchOnBlur)
            return;
        
        handleSearch(e.target.value)
    }

    function handleKeyDown(e) {
        if(e.keyCode === 13)
            handleSearch(keywords);
    }

    useEffect(() => {
        if(value !== keywords)
            return setKeywords(value);
    }, [value]);

    return (
        <Root style={style}>
            <FilterContainer disabled={loading}>
                <input
                    disabled={loading}
                    value={keywords}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    placeholder="Palavra chave" />

                {loading ? (
                    <LoadingIndicator />
                ) : (
                    <SearchIcon />
                )}
            </FilterContainer>
        </Root>
    );
}