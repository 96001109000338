import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { reset } from 'redux-form';
import {
  CRUD_CREATE_FAILURE,
  CRUD_CREATE_SUCCESS,
  CRUD_DELETE_FAILURE,
  CRUD_DELETE_SUCCESS,
  CRUD_GET_LIST_FAILURE,
  CRUD_GET_MANY_FAILURE,
  CRUD_GET_MANY_REFERENCE_FAILURE,
  CRUD_GET_ONE_FAILURE,
  CRUD_UPDATE_FAILURE,
  CRUD_UPDATE_SUCCESS,
} from '../actions/dataActions';
import {
  PROFILE_UPDATE_SUCCESS,
  KINDLE_CONNECT_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  PROFILE_UPDATE_FAILURE,
  KINDLE_CONNECT_FAILURE,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAILURE,
  FACEBOOK_DISCONNECT_FAILURE,
  FACEBOOK_CONNECT_FAILURE,
  FACEBOOK_CONNECT_SUCCESS,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  UPDATE_CREDITCARD_SUCCESS,
  UPDATE_CREDITCARD_FAILURE,
  KINDLE_DISCONNECT_SUCCESS,
  KINDLE_DISCONNECT_FAILURE,
} from '../actions/profileActions';
import {
  SUBSCRIBE_PLAN_SUCCESS,
  SUBSCRIBE_PLAN_FAILURE,
} from '../actions/subcriptionActions';
import {
  CREATE_AUDIO_SUCCESS,
  CREATE_AUDIO_FAILURE
} from '../actions/extraActions'
import { refreshState } from '../actions/plataformActions';

import { showAlert } from '../actions/alertActions';
import linkToRecord from '../utils/linkToRecord';

/**
 * Side effects for fetch responses
 *
 * Mostly redirects and alerts
 */
function* handleResponse({ type, requestPayload, error, payload, meta }) {
  switch (type) {
    case PROFILE_UPDATE_SUCCESS:
    case KINDLE_CONNECT_SUCCESS:
    case KINDLE_DISCONNECT_SUCCESS:
    case UPDATE_CREDITCARD_SUCCESS:
    case FACEBOOK_CONNECT_SUCCESS:
    case CANCEL_SUBSCRIPTION_SUCCESS:
      if (meta.message !== false) {
        yield put(
          showAlert(meta.customMessage || 'Dados de cadastro modificados!'),
        );
      }
      return requestPayload.redirect
        ? yield [put(push(requestPayload.basePath))]
        : yield [];
    case UPDATE_SUBSCRIPTION_SUCCESS:
      if (meta.message !== false) {
        yield put(showAlert(meta.customMessage));
      }
      // yield put(crudCreate('event', { name: 'Alterou plano', data: { plan: requestPayload.data.planId }}, '', false, '', false));
      return requestPayload.redirect
        ? yield [put(push(requestPayload.basePath))]
        : yield [];
    case SUBSCRIBE_PLAN_SUCCESS:
      if (meta.message !== false) {
        yield put(showAlert(meta.customMessage));
      }
      // yield put(crudCreate('event', { name: 'Adquiriu plano', data: { plan: requestPayload.data.planId }}, '', false, '', false));
      return requestPayload.redirect
        ? yield [put(push(requestPayload.basePath))]
        : yield [];
    case CREATE_AUDIO_SUCCESS:
    case CRUD_UPDATE_SUCCESS:
      if (meta.message !== false) {
        yield put(
          showAlert(meta.customMessage || 'Registro atualizado com sucesso!'),
        );
      }
      return requestPayload.redirect
        ? yield [put(push(requestPayload.basePath))]
        : yield [];
    case CRUD_CREATE_SUCCESS:
      // yield call(closeModal, meta.modal)
      yield put(reset(meta.resource));
      if (meta.message !== false) {
        yield put(refreshState());
        yield put(
          showAlert(meta.customMessage || 'Registro criado com sucesso!'),
        );
      }
      return requestPayload.redirect
        ? yield [
            requestPayload.customBasePath
              ? put(push(requestPayload.basePath))
              : put(
                  push(linkToRecord(requestPayload.basePath, payload.data._id)),
                ),
          ]
        : yield [];
    case CRUD_DELETE_SUCCESS:
      if (meta.message !== false) {
        yield put(
          showAlert(meta.customMessage || 'Registro excluído com sucesso!'),
        );
      }
      return requestPayload.redirect
        ? yield [put(push(requestPayload.basePath))]
        : yield [];
    case CRUD_GET_ONE_FAILURE:
      return requestPayload.basePath
        ? yield [
            put(showAlert('O registro informado não existe.', 'warning')),
            put(push(requestPayload.basePath)),
          ]
        : yield [];
    case CRUD_GET_LIST_FAILURE:
    case CRUD_GET_MANY_FAILURE:
    case CRUD_GET_MANY_REFERENCE_FAILURE:
    case CRUD_CREATE_FAILURE:
    case CRUD_UPDATE_FAILURE:
    case CRUD_DELETE_FAILURE:
    case UPDATE_PASSWORD_FAILURE:
    case PROFILE_UPDATE_FAILURE:
    case UPDATE_SUBSCRIPTION_FAILURE:
    case SUBSCRIBE_PLAN_FAILURE:
    case UPDATE_CREDITCARD_FAILURE:
    case FACEBOOK_DISCONNECT_FAILURE:
    case FACEBOOK_CONNECT_FAILURE:
    case CANCEL_SUBSCRIPTION_FAILURE:
    case KINDLE_CONNECT_FAILURE:
    case KINDLE_DISCONNECT_FAILURE: {
      if (/is not allowed/.test(error)) {
        window.history.pushState('', '', window.location.pathname);
      }
      const errorMessage =
        typeof error === 'string' ? error : error.message || 'Erro de conexão!';
      return yield [
        errorMessage !== 'Invalid token format' &&
          put(showAlert(errorMessage, 'warning')),
      ];
    }
    default:
      return yield [];
  }
}

export default function*() {
  yield takeEvery(
    action => action.meta && action.meta.fetchResponse,
    handleResponse,
  );
}
