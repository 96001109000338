import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import styled from "styled-components";

import { ChevronRight } from "@material-ui/icons";

const Button = styled.button`
  background: linear-gradient(
    -225deg,
    rgb(253, 87, 40) 0%,
    rgb(255, 128, 0) 100%
  );
  border-radius: 4px;
  box-shadow: 0px 25px 11px -20px rgba(255, 85, 0, 0.6);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: 600;
  height: 48px;
  width: 336px;
  color: white;
  text-align: center;
  text-transform: uppercase;
  border: none;
  position: relative;
  align-self: center;
  margin-bottom: ${(props) => !props.presentation && "40px"};
`;

const DisabledButton = styled.div`
  background: rgb(187, 187, 187);
  border-radius: 4px;
  box-shadow: 0px 25px 11px -20px rgba(0, 0, 0, 0.2);
  height: 48px;
  width: 336px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;

const DisabledText = styled.span`
  color: rgba(0, 0, 0, 0.35);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: bold;
  height: 16px;
  letter-spacing: 0px;
  text-align: center;
  width: fit-content;
`;

const DisabledTextSecondary = styled.span`
  color: rgba(0, 0, 0, 0.35);
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: normal;
  height: 16px;
  letter-spacing: 0px;
  text-align: center;
  width: fit-content;
`;

function VideoButton({ text, action, presentation = false }) {
  const pendingPayments = useSelector(
    (state) => state.paymentGroup.pendingPayments
  );
  const pacotes_modulo = useSelector(
    (state) => state.courseGroup.currentModule.data.pacotes_modulo
  );
  const pacotes_curso = useSelector(
    (state) => state.courseGroup.currentCourse.data.pacotes_curso
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {presentation ? (
        <Button presentation={true} onClick={() => action()}>
          {text}
          <ChevronRight
            style={{
              color: "#fff",
              width: "24px",
              height: "24px",
              position: "absolute",
              transform: "translateY(-50%)",
              right: "16px",
              top: "50%",
            }}
          />
        </Button>
      ) : pacotes_modulo.find((pacote) =>
          pendingPayments.pacotes.includes(pacote.id)
        ) !== undefined ||
        pacotes_curso.find((pacote) =>
          pendingPayments.pacotes.includes(pacote.id)
        ) !== undefined ? (
        <DisabledButton>
          <DisabledText>AGUARDANDO PAGAMENTO</DisabledText>
          <DisabledTextSecondary>
            O boleto pode demorar até 3 dias úteis.
          </DisabledTextSecondary>
        </DisabledButton>
      ) : (
        <Button presentation={false} onClick={() => action()}>
          {text}
          <ChevronRight
            style={{
              color: "#fff",
              width: "24px",
              height: "24px",
              position: "absolute",
              transform: "translateY(-50%)",
              right: "16px",
              top: "50%",
            }}
          />
        </Button>
      )}
    </div>
  );
}

export default VideoButton;

//added file
