import { UPDATE_EDITOR_STATE } from '../actions/textEditorActions';

const initialState = {};

export default (previousState = initialState, { type, payload = {} }) => {
    switch (type) {
    case UPDATE_EDITOR_STATE:
      return {
        ...previousState,
        [payload._id]: payload.editorState,
      };
    default:
        return previousState;
    }
};
