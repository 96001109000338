import React, { Component } from 'react'
import { connect } from 'react-redux';
import { push } from 'react-router-redux'
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import {ElementList, Title} from '../../components/common'
import Grid from 'material-ui/Grid';
import {FetchList} from '../../components'
import Item from './Item'
import EmptyList from './EmptyList'
import ModalEdital from './ModalEdital';
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';

const resource = 'editalSubject/user'

const styles = theme => ({
  content: {
    paddingTop: '8px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '32px'
    }
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between'
  },
  contentMain: {
    padding: '40px 16px 16px 16px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px'
    }
  },
  contentSidebar: {
    display: 'none',
    padding: '8px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px 0 0 0',
      marginTop: '150px',
      marginLeft: '0',
      marginRight: 'auto',
      display: 'block',
      order: '1'
    }
  },
  root: {
    flexGrow: 1,
    padding: '8px'
  }
});


class ListaLeitura extends Component {
  constructor(props) {
    super(props)
    const {_id}  = props.routeParams
    this.state = {
      modal: _id || false
    }
  }

  handleModal = (_id) => {
    const { pushRouter, trackMetric } = this.props;
    this.setState({modal: _id})
    trackMetric('Edital - Intenção de uso', { target: 'card', trigger: 'click', resource: _id });
    pushRouter(`/edital-esquematizado${_id ? `/${_id}` : ''}`)
  }
  
  componentWillReceiveProps(nextProps) {
    const {_id}  = nextProps.routeParams
    if(this.props.routeParams._id !== _id) {
      this.setState({modal: _id })
    }

    if(nextProps.refreshState !== this.props.refreshState) {
      window.scrollTo(0, 0);
    }
  }

  render() {

    const {classes, materias} = this.props
    const {modal} = this.state

    return (
      <div>
      <section className={classes.content}>
        <Grid container className={classes.root} >
          <Grid item xs={12} md={12} className={classes.contentMain}>
            <FetchList {...this.props}
                pagination={false}
                feedback={true}
                stateRefresh={modal}
                noData={<EmptyList />}
                resource={resource} >
              <ElementList
                    renderRow={(props) => <Item {...props} handleOpen={this.handleModal} />}
                  />
              </FetchList>
            </Grid>        
          </Grid>          
      </section>
      
      <ModalEdital handleOpen={this.handleModal} open={modal} materias={materias}/> 
    </div>
    )
  }
}

ListaLeitura.propTypes = {
  classes: PropTypes.object.isRequired,
  pushRouter: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => {
  return {
    pushRouter: (path) => dispatch(push(path)),
    trackMetric: (name, data) => dispatch(trackMetricAction(name, data))
  }
}

const mapStateToProps = (state, ownProps) => {
  return  {
    materias: state[resource],
    refreshState: state.plataform.refreshState
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ListaLeitura);
