export const SUBS_PACK_SUCCESS_MODAL_SHOW = 'SUBS_PACK_SUCCESS_MODAL_SHOW';
export const SUBS_PACK_SUCCESS_MODAL_HIDE = 'SUBS_PACK_SUCCESS_MODAL_HIDE';

export const SUBS_PACK_FAILURE_MODAL_SHOW = 'SUBS_PACK_FAILURE_MODAL_SHOW';
export const SUBS_PACK_FAILURE_MODAL_HIDE = 'SUBS_PACK_FAILURE_MODAL_HIDE';

export const TERMS_OF_USE_MODAL_SHOW = 'TERMS_OF_USE_MODAL_SHOW';
export const TERMS_OF_USE_MODAL_HIDE = 'TERMS_OF_USE_MODAL_HIDE';

export const subsPackSuccessModalShow = () => ({
    type: SUBS_PACK_SUCCESS_MODAL_SHOW,
    payload: { open: true }
});
export const subsPackSuccessModalHide = () => ({
    type: SUBS_PACK_SUCCESS_MODAL_HIDE,
    payload: {  open: false }
});

export const subsPackFailureModalShow = () => ({
    type: SUBS_PACK_FAILURE_MODAL_SHOW,
    payload: { open: true }
});
export const subsPackFailureModalHide = () => ({
    type: SUBS_PACK_FAILURE_MODAL_HIDE,
    payload: { open: false }
});

export const termsOfUseShow = () => ({
    type: TERMS_OF_USE_MODAL_SHOW,
    payload: { open: true }
});
export const termsOfUseHide = () => ({
    type: TERMS_OF_USE_MODAL_HIDE,
    payload: { open: false }
});