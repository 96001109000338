import React from 'react';
import { connect } from 'react-redux';
import { EditorState, RichUtils} from 'draft-js';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import Editor from 'draft-js-plugins-editor';
import compose from 'recompose/compose';
import 'draft-js/dist/Draft.css';
import debounce from 'lodash/debounce';
import { withStyles } from 'material-ui/styles';
import {stateToHTML} from 'draft-js-export-html'
import {stateFromHTML} from 'draft-js-import-html'
import classNames from 'classnames';
import Title from 'material-ui-icons/Title';
import FormatUnderlined from 'material-ui-icons/FormatUnderlined';
import FormatListBulleted from 'material-ui-icons/FormatListBulleted';
import FormatListNumbered from 'material-ui-icons/FormatListNumbered';
import FormatItalic from 'material-ui-icons/FormatItalic';
import FormatBold from 'material-ui-icons/FormatBold';

const linkifyPlugin = createLinkifyPlugin({
  component: (props) => (
    <a {...props} onClick={() => window.open(props.href, '_blank')} />
  )
});

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

const styles = theme => ({
  root: {
    width: '100%'
  }
})

const StyleButtonIcon = {
  height: '16px',
  width: '16px'
}

class RichEditorExample extends React.Component {
  constructor(props) {
    super(props);
    this.focus = () => this.refs.editor.focus();
    this.handleKeyCommand = this._handleKeyCommand.bind(this);
    this.onTab = this._onTab.bind(this);
    this.toggleBlockType = this._toggleBlockType.bind(this);
    this.toggleInlineStyle = this._toggleInlineStyle.bind(this);

    if (this.props.content) {
      this.state = {editorState: EditorState.createWithContent(stateFromHTML(this.props.content))}
    } else {
      this.state = {editorState: EditorState.createEmpty()}
    }
  }

  saveContent = debounce((content) => {
    this.props.onSaveEditorState(this.props._id, stateToHTML(content));
  }, this.props.debounce || 2000);

  onChange = (editorState) => {
    this.setState({editorState});
    if(this.state.editorState.getCurrentContent() !== editorState.getCurrentContent()) {
      this.saveContent(editorState.getCurrentContent());
      if(this.props.setSaving)
        this.props.setSaving(true)
    }
  }

  _handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  _onTab(e) {
    const maxDepth = 4;
    this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
  }

  _toggleBlockType(blockType) {
    this.onChange(
      RichUtils.toggleBlockType(
        this.state.editorState,
        blockType
      )
    );
  }

  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(
        this.state.editorState,
        inlineStyle
      )
    );
  }

  componentWillReceiveProps(nextProps) {
    const {saving, setSaving} = this.props
    if(nextProps.loading === 0 && saving) {
      setSaving(false)
    }
  }

  render() {
    const {classes, className, customClass} = this.props
    const {editorState} = this.state
    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let mainClassName = `RichEditor-editor ${customClass}`;
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        mainClassName += ' RichEditor-hidePlaceholder';
      }
    }
    return (
      <div className={classNames(classes.root, className)}>
        <div className="RichEditor-controls">
          <BlockStyleControls
            editorState={editorState}
            onToggle={this.toggleBlockType}
          />
          <InlineStyleControls
            editorState={editorState}
            onToggle={this.toggleInlineStyle}
          />
        </div>
        <div className={mainClassName} onClick={this.focus}>
          <Editor
            plugins={[linkifyPlugin]}
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.onChange}
            onTab={this.onTab}
            placeholder="Insira aqui o conteúdo do fichamento..."
            ref="editor"
            spellCheck={true}
          />
        </div>
      </div>
    );
  }
}

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    default: return null;
  }
}
class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }
  render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }
    return (
      <span className={className} style={{margin: '4px'}} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}
const BLOCK_TYPES = [
  {label: <Title style={StyleButtonIcon}  />, style: 'header-one'},
  {label: <Title  style={{height: '14px', marginBottom: '-2px'}} />, style: 'header-two'},
  {label: <FormatListBulleted style={StyleButtonIcon} />, style: 'unordered-list-item'},
  {label: <FormatListNumbered style={StyleButtonIcon} />, style: 'ordered-list-item'}
];
const BlockStyleControls = (props) => {
  const {editorState} = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div >
      {BLOCK_TYPES.map((type) =>
        <StyleButton
          key={type.style}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

var INLINE_STYLES = [
  {label: <FormatBold style={StyleButtonIcon} />, style: 'BOLD'},
  {label: <FormatItalic style={StyleButtonIcon} />, style: 'ITALIC'},
  {label: <FormatUnderlined style={StyleButtonIcon} />, style: 'UNDERLINE'}
];

const InlineStyleControls = (props = {}) => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div>
      {INLINE_STYLES.map(type =>
        <StyleButton
          key={type.style}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.loading
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(RichEditorExample);
