// @flow

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import List from 'material-ui/List';
import Drawer from 'material-ui/Drawer';
import Hidden from 'material-ui/Hidden';
import AppDrawerNavItem from './NavItem';
import _ from 'lodash'
const styles = theme => {
  return({
  paper: {
    backgroundColor: '#F7F8F9',
    paddingTop: '40px',
    paddingLeft: '32px',
    paddingRight: '16px',
    paddingBottom: '75px',
    border: '1px solid rgba(151, 151, 151, 0.3)',
    overflowX: 'hidden',
    overflowY: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '360px',
      top: '65px',
    }
  },
  title: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.primary[500],
    },
  },
  toolbarIe: {
    display: 'flex',
  },
  toolbar: {
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  anchor: {
    color: theme.palette.text.secondary,
  },
  navItemTitle: {
    ...theme.typography.body1,
    color: theme.palette.text.clear,
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '14px',
    paddingTop: '16px',
    textTransform: 'uppercase'
  },
})};

function renderNavItems({location, closeDrawer, section, pushRouter}, items = [], classes) {
  return <List>
    {_.map(items, item => {
      return <AppDrawerNavItem 
        key={item.name}
        location={location}
        closeDrawer={closeDrawer}
        section={section}
        pushRouter={pushRouter}
        {...item}
      />
    })}
  </List>;
}

function AppDrawer(props, context) {
  const { classes, className = {}, disablePermanent, mobileOpen, onRequestClose, items } = props;

  const drawer = (
    <div className={classes.nav}>
      {renderNavItems(props, items, classes)}
    </div>
  );

  return (
    <div className={className.wrapper}>
      <Hidden mdUp={!disablePermanent}>
        <Drawer
          classes={{
            paper: classNames(classes.paper, 'algolia-drawer')
          }}
          type="temporary"
          anchor="top"
          open={mobileOpen}
          onRequestClose={onRequestClose}
          onClose={onRequestClose}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      {disablePermanent ? null : (
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.paper
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      )}
    </div>
  );
}

AppDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  disablePermanent: PropTypes.bool.isRequired,
  mobileOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

AppDrawer.contextTypes = {
  activePage: PropTypes.object,
};

export default withStyles(styles)(AppDrawer);
