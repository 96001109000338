import React from 'react';
import _ from 'lodash';
import { CircularProgress } from 'material-ui/Progress';

/** Exibe uma lista de elementos
 * @param data Uma lista de objetos para ser iterada
 * @param type Classe do elemento <ol>
 * @param renderRow função chamada para renderizar o objeto iterado.
 * @param style custom style.
 * @param customData filtra a lista de objetos data por uma key especifica.
 * @param clearPadding força padding 0.
 * @param customField seleciona um campo de uma lista de objetos ja filtrada por uma key.
 * @return renderiza uma lista de componentes.
 */

export default ({
  data,
  ids,
  type,
  renderRow,
  clearPadding = true,
  style = {},
  customField,
  customData,
}) => {
  var renderData = customData
    ? customField
      ? data && data[customData] && data[customData][customField]
      : data && data[customData]
    : data;

  renderData = Boolean(ids)
    ? ids.filter((id) => Boolean(renderData[id])).map((hit) => renderData[hit])
    : _.map(renderData);

  return (
    <ol
      style={clearPadding ? { padding: '0', ...style } : style}
      className={type}
    >
      {renderData.length > 0 ? (
        _.map(renderData, (post, index) =>
          renderRow({ ...post, key: post._id, _key: index })
        )
      ) : (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress style={{ color: '#F58220' }} />
        </div>
      )}
    </ol>
  );
};
