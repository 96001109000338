export const COURSE_PACK_NOTIFICATION_BAR_OPEN = 'COURSE_PACK_NOTIFICATION_BAR_OPEN';
export const COURSE_PACK_NOTIFICATION_BAR_CLOSE = 'COURSE_PACK_NOTIFICATION_BAR_CLOSE';

export const closeNotificationBar = () => ({
    type: COURSE_PACK_NOTIFICATION_BAR_CLOSE,
    payload: { open: false }
});

export const openNotificationBar = () => ({
    type: COURSE_PACK_NOTIFICATION_BAR_OPEN,
    payload: { open: true }
});