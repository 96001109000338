import React from 'react';
import { Button } from '../../components/common';
import { withStyles } from 'material-ui/styles';
import Dialog, { DialogContent, DialogTitle } from 'material-ui/Dialog';
import Close from 'material-ui-icons/Close';
import { SORT_ASC } from '../../reducers/resource/list/queryReducer';
import IconButton from 'material-ui/IconButton';
import {
  crudCreate as crudCreateAction,
  crudGetList as crudGetListAction,
} from '../../actions/dataActions';
import { reduxForm, Field, change, SubmissionError } from 'redux-form';
import { renderCustomInput, renderCreatableSelect } from '../../utils/form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import TextEditor from '../../components/TextEditor';
import _ from 'lodash';
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';

const formName = 'fichador';

const ITEM_HEIGHT = 48;

const styles = (theme) => ({
  container: {
    borderRadius: '4px',
    width: '100vw',
    margin: '0px',
    overflowY: 'initial',
    [theme.breakpoints.up('md')]: {
      minWidth: '800px',
    },
  },
  title: {
    color: theme.palette.orange,
    fontSize: '18px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: '600',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '67px',
    paddingLeft: '24px',
    paddingRight: '24px',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
  },
  close: {
    color: theme.palette.text.clear,
    cursor: 'pointer',
    width: '20px',
    '&:hover': {
      color: theme.palette.orange,
    },
  },
  content: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    ...theme.typography.title,
    border: 'none',
    outline: 'none',
    width: '100%',
  },
  textEditor: {
    background: 'rgba(0,0,0,0.04)',
    borderRadius: '3px 3px 0 0',
    marginTop: '24px',
    overflowY: 'auto',
  },
  selectNotebooks: {
    width: '100%',
  },
  dialogActions: {
    width: '100%',
    padding: '0px 24px',
  },
  addNotebook: {
    minWidth: '200px',
  },
  addNotebookContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  error: {
    paddingLeft: '24px',
    ...theme.typography.error,
    marginBottom: '16px',
    marginTop: '-16px',
    float: 'right',
    paddingRight: '24px',
  },
  addIcon: {
    width: '32px',
    color: theme.palette.orange,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  customEditorClass: {
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '20vh',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  creatableSelectInput: {
    marginTop: '22px !important',
    minHeight: '49px',
    lineHeight: '37px',
  },
  creatableSelectLabel: {
    fontFamily: '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
    color: 'rgba(0,0,0,0.7)',
    fontWeight: 600,
    fontSize: '16px',
  },
  buttonSubmit: {
    margin: '23px 0',
    float: 'right',
  },
  '@global': {
    '.Select-control': {
      display: 'flex',
      alignItems: 'center',
      border: 0,
      height: 'auto',
      background: 'transparent',
      '&:hover': {
        boxShadow: 'none',
      },
    },
    '.Select-multi-value-wrapper': {
      flexGrow: 1,
      display: 'flex',
      flexWrap: 'wrap',
    },
    '.Select--multi .Select-input': {
      margin: 0,
    },
    '.Select.has-value.is-clearable.Select--single > .Select-control .Select-value':
      {
        padding: 0,
      },
    '.Select-noresults': {
      padding: theme.spacing.unit * 2,
    },
    '.Select-input': {
      display: 'inline-flex !important',
      padding: 0,
      height: 'auto',
    },
    '.Select-input input': {
      background: 'transparent',
      border: 0,
      padding: 0,
      cursor: 'default',
      display: 'inline-block',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      margin: 0,
      outline: 0,
    },
    '.Select-placeholder, .Select--single .Select-value': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(16),
      padding: 0,
    },
    '.Select-placeholder': {
      opacity: 0.42,
      color: theme.palette.common.black,
    },
    '.Select-menu-outer': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: '4px 12px 15px 0 rgba(0,0,0,0.28)',
      position: 'absolute',
      left: 0,
      top: `calc(100% + ${theme.spacing.unit}px)`,
      width: 'calc(100% + 18px)',
      zIndex: 2,
      marginLeft: '-9px',
      border: 'none',
      maxHeight: ITEM_HEIGHT * 3.5,
    },
    '.Select.is-focused:not(.is-open) > .Select-control': {
      boxShadow: 'none',
    },
    '.Select-menu': {
      maxHeight: ITEM_HEIGHT * 3.5,
      overflowY: 'auto',
    },
    '.Select-menu div': {
      boxSizing: 'content-box',
    },
    '.Select-arrow-zone, .Select-clear-zone': {
      color: theme.palette.action.active,
      cursor: 'pointer',
      marginRight: '5px',
      width: 21,
      zIndex: 1,
    },
    // Only for screen readers. We can't use display none.
    '.Select-aria-only': {
      position: 'absolute',
      overflow: 'hidden',
      clip: 'rect(0 0 0 0)',
      height: 1,
      width: 1,
      margin: -1,
    },
  },
});

class ModalFicharConteudo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fichamento: props.getFichamentos(),
    };
  }

  setFichamentos = (id, fichamento) => {
    this.setState({ fichamento });
  };

  handleClose = () => {
    this.props.handleOpen(false);
  };

  createPost = (post) => {
    const { notebooks, title, new_notebook } = post;
    const {
      crudCreate,
      _id,
      concurso = {},
      trackMetric,
      clippingId,
    } = this.props;

    if (!notebooks) {
      throw new SubmissionError({
        notebooks: 'Selecione ou crie um caderno.',
        _error: 'Selecione ou crie um caderno antes de fichar algum conteúdo!',
      });
    } else if (!this.state.fichamento) {
      throw new SubmissionError({
        notebooks: 'O seu fichamento deve ter algum conteúdo.',
        _error: 'O seu fichamento deve ter algum conteúdo.',
      });
    }

    const data = {
      source: _id,
      type: 'article',
      concursoId: concurso.concursoId,
      notebooks: notebooks.map((notebook) => notebook.value),
      new_notebook,
      title,
      content: this.state.fichamento,
    };

    trackMetric('Caderno - Ativação', {
      target: 'clipping',
      trigger: 'click',
      resource: _id,
      parentResource: clippingId,
    });

    crudCreate(
      'notebookContent/clipping',
      data,
      '/mural',
      false,
      'modal-add-post'
    );
    window.hltr.removeHighlights();
    this.props.reset();
    this.handleClose();
  };

  selectCaderno = (event) => {
    this.setState({ notebook: event.target.value });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && nextProps.open !== this.props.open) {
      this.setState({ fichamento: nextProps.getFichamentos() });
      this.props.getNotebookList();
    }
  }

  render() {
    const { classes, handleSubmit, open, notebooks, error, pristine } =
      this.props;
    const { fichamento } = this.state;
    return (
      <Dialog
        open={Boolean(open)}
        onClose={this.handleClose}
        aria-labelledby='form-dialog-title'
        classes={{ paper: classes.container }}
      >
        <DialogTitle disableTypography className={classes.title}>
          SALVAR FICHAMENTO
          <div className={classes.close}></div>
          <IconButton
            className={classes.close}
            aria-label='Fechar'
            onClick={this.handleClose}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <form className={classes.form} onSubmit={handleSubmit(this.createPost)}>
          <DialogContent className={classes.content}>
            <Field
              fullWidth
              autoFocus
              margin='dense'
              placeholder='Insira o título aqui...'
              label='Título'
              name='title'
              id='title'
              component={renderCustomInput}
              className={classes.input}
            />
            <TextEditor
              debounce={100}
              className={classes.textEditor}
              content={fichamento}
              onSaveEditorState={this.setFichamentos}
              customClass={classes.customEditorClass}
            />
          </DialogContent>
          <div className={classes.dialogActions}>
            <Field
              classes={classes}
              component={renderCreatableSelect}
              customClass={classes.selectNotebooks}
              options={_.map(notebooks, ({ _id, title }) => ({
                value: _id,
                label: title,
              }))}
              name='notebooks'
              id='notebooks'
              label='ADICIONAR AOS CADERNOS'
              placeholder='Digite o nome de um ou mais cadernos...'
              promptTextCreator={(label) =>
                `Criar caderno <strong>"${label}"</strong>`
              }
            />

            <Button
              onClick={handleSubmit(this.createPost)}
              customClass={classes.buttonSubmit}
              buttonFull={true}
              type='submit'
            >
              SALVAR
            </Button>
          </div>
          {error && <div className={classes.error}>{error}</div>}
        </form>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, { title }) => {
  return {
    submitting: state.waiting,
    profile: state.user.profile,
    initialValues: { title },
    notebooks: state.notebook.data,
    concurso: state.concurso.active,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getNotebookList: () =>
      dispatch(
        crudGetListAction(
          'notebook',
          { page: 1, perPage: 999 },
          { field: 'title', order: SORT_ASC },
          {},
          true
        )
      ),
    changeForm: (field, value) => {
      dispatch(change(formName, field, value));
    },
    crudCreate: (...params) => {
      dispatch(crudCreateAction(...params));
    },
    trackMetric: (name, data) => dispatch(trackMetricAction(name, data)),
  };
};

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    enableReinitialize: true,
    form: formName,
    validate: (values, props) => {
      const errors = {};
      if (!values.notebooks) errors.post = 'Selecione ou crie um caderno.';
      return errors;
    },
  })
);

export default enhance(ModalFicharConteudo);
