import { call, takeLatest, put } from "redux-saga/effects";

import { USER_FETCH } from "./constants";
import { userFetchFailure, userFetchSuccess } from "./actions";

import api from "../../services/newApi";

export function* fetchUser() {
  const headers = { Authorization: localStorage.getItem("token") };
  try {
    const { data } = yield call(api.get, "/users/students/", {
      headers,
    });
    if (data.id) {
      const body = {
        index: "student",
        query: {
          match: {
            id: data.id,
          },
        },
      };

      const { data: response } = yield call(api.post, "/elastic/", body, {
        headers,
      });

      if (response.results.length > 0) {
        yield put(userFetchSuccess({ payload: response.results[0] }));
      } else {
        // if (data.cpf) {
        //   delete data.cpf;
        // }
        yield put(userFetchSuccess({ payload: data }));
      }
    }
  } catch (error) {
    yield put(userFetchFailure());
  }
}

export default function* newUserSaga() {
  yield [takeLatest(USER_FETCH, fetchUser)];
}
