import React from "react";
import styled from "styled-components";
import { withStyles } from "material-ui/styles";
import Dialog from "material-ui/Dialog";

const styles = {
  paper: {
    minWidth: 650,
  },
  paperResponsive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 48px;
  font-family: "Proxima Nova" !important;
`;

const Message = styled.p`
  color: rgba(0, 0, 0, 0.75);
  font-size: 16px;
  font-weight: 600;
  height: fit-content;
  letter-spacing: 0px;
  line-height: 22px;
  text-align: center;
  white-space: pre-line;
  width: fit-content;
  margin: 0px;
  font-family: "Proxima Nova";
`;

const CustomButton = styled.button`
  margin-top: 30px;
  border: none;
  background-color: #fc7954;
  border-radius: 25px;
  font-size: 13px;
  color: #fff;
  font-weight: 600;
  width: 120px;
  height: 36px;
  text-transform: uppercase;
  font-family: "Proxima Nova";
  &:hover {
    background: #e95b34;
    border: #e95b34;
  }
`;

function Alert({ classes, message, open, setClose }) {
  return (
    <Dialog open={open} classes={{ root: classes.paper }}>
      <Container>
        <Content>
          <Message>{message}</Message>
          <CustomButton onClick={() => setClose()}>OK</CustomButton>
        </Content>
      </Container>
    </Dialog>
  );
}

export default withStyles(styles)(Alert);
