import { CircularProgress } from "material-ui";
import styled, { css } from "styled-components";

export const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  & h2 {
    width: 100%;
    margin: 0px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 8px;
  }

  * {
    font-family: Inter;
  }
`;

export const Separator = styled.span`
  display: block;
  width: 100%;
  margin: 32px 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.16);
`;

export const HideContainer = styled.div`
  width: 100%;
  transition: 350ms ease-in-out;
  opacity: 1;

  ${({ hide }) =>
    hide &&
    css`
      max-height: 0px;
      padding: 0px;
      opacity: 0;
      overflow: hidden;
    `}
`;

export const FilterList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  & > * {
    margin: 4px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 0px;
  bottom: 0px;
  top: ${({ hide }) => (hide ? "30px" : "")};
`;

export const SecondaryButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  color: #b0b0b0;
  padding: 8px 12px;
  font-weight: 600;
  min-height: 36px;

  ${({ loading }) =>
    loading &&
    css`
      opacity: 0.5;
      cursor: default;
    `}

  & > span {
    margin-left: 8px;
  }
`;

export const PrimaryButton = styled(SecondaryButton)`
  background: #fc7954;
  color: #ffffff;
  border-radius: 8px;
  margin-left: 8px;
  transition: 250ms ease-in-out;

  ${({ actived }) =>
    !actived &&
    css`
      background: #cccccc;
      cursor: default;
    `}
`;

export const HideButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2eeed;
  border: none;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  bottom: -24px;
  transform: translateY(50%);
  color: rgba(0, 0, 0, 0.6);
`;

export const FiltersItem = styled.div`
  display: flex;
  background: #ffffff;
  padding: 1px 6px;
  border-radius: 8px;
  overflow: hidden;
  width: fit-content;
  max-width: 100%;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
      user-select: none;
    `}

  & button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0px;

    &:disabled {
      cursor: default;
    }
  }

  ${({ responsive }) =>
    responsive &&
    css`
      flex-direction: column;
      width: 100%;
      padding: 8px;
    `}
`;

export const FiltersList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  margin: 0px;

  ${({ responsive }) =>
    responsive &&
    css`
      margin-top: 16px;
    `}
`;

export const FilterListItem = styled.li`
  display: flex;
  align-items: center;
  background: #f2eeed;
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 11px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);

  margin: 4px 0px;

  & > span {
    word-break: break-word;
  }

  &:not(:last-child) {
    margin-right: 8px;
  }

  & > button {
    margin-left: 4px;

    & > svg {
      width: 14px;
      height: 14px;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  ${({ responsive }) =>
    responsive &&
    css`
      width: 100%;
      margin-right: 0px !important;

      & > span {
        width: 100%;
      }
    `}
`;

export const ClearButton = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.75);
  margin-right: 16px;

  & > span {
    white-space: nowrap;
  }

  & > button {
    min-height: 24px;
    min-width: 24px;
    border-radius: 8px;
    margin-right: 8px;
    background: #f2eeed;

    svg {
      width: 16px;
      height: 16px;
      color: rgba(0, 0, 0, 0.4);
    }
  }
`;

export const LoadingIndicator = styled(CircularProgress).attrs({
  size: 20,
})`
  color: white;
  margin-right: 8px;
`;
