import React from 'react'
import { CircularProgress } from 'material-ui/Progress';
import { withStyles } from 'material-ui/styles';
import {
  TableCell,
  TableRow
} from 'material-ui/Table';

const styles = theme => ({
  root: {
    paddingRight: '24px',
    textAlign: 'center',
    height: '150px',
    borderBottom: 'none'
  },
})

const Loading = ({classes}) => {
  return <TableRow tabIndex={-1} >
    <TableCell colSpan={5} classes={{root: classes.root}} >
      <CircularProgress style={{color: '#F58220'}}/>
    </TableCell>
  </TableRow>
}

export default withStyles(styles)(Loading)