import {
  AUTH_LOGIN,
  AUTH_MOUNT,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
} from '../auth';
import { BaseUrl } from '../config';
import axios from 'axios';

async function getUserIp() {
  try {
    const { data } = await axios.get(
      'https://www.cloudflare.com/cdn-cgi/trace'
    );

    if (!data.length) {
      throw new Error();
    }

    const responseArr = data.split('\n');
    const ipString = responseArr[2];
    const [, ip] = ipString.split('=');

    localStorage.setItem('user_ip', ip);

    return ip;
  } catch (error) {
    console.error('Falha ao buscar IP do usuário');
    return '';
  }
}

export default (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { token } = params;
    const request = new Request(`${BaseUrl}/login/validate_token`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        localStorage.clear();
        getUserIp();
        localStorage.setItem('showDivulgation', true);
        localStorage.setItem('token', response.authHeader);
        localStorage.setItem('scope', response.scope.join(','));
        localStorage.setItem('userUploads', response.uploadTokens.userUploads);
        if (Boolean(response.uploadTokens.mediaUploads)) {
          localStorage.setItem(
            'mediaUploads',
            response.uploadTokens.mediaUploads
          );
        }
        return Promise.resolve(response);
      });
  }
  if (type === AUTH_MOUNT) {
    const token = localStorage.getItem('token');
    const request = new Request(`${BaseUrl}/login/validate_token`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        getUserIp();
        localStorage.setItem('showDivulgation', true);
        localStorage.setItem('token', response.authHeader);
        localStorage.setItem('scope', response.scope.join(','));
        localStorage.setItem('userUploads', response.uploadTokens.userUploads);
        if (Boolean(response.uploadTokens.mediaUploads)) {
          localStorage.setItem(
            'mediaUploads',
            response.uploadTokens.mediaUploads
          );
        }
        return Promise.resolve(response);
      });
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    localStorage.clear();
    localStorage.removeItem('showDivulgation');
    localStorage.removeItem('token');
    localStorage.removeItem('user_ip');
    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  }
  return Promise.reject('Unkown method');
};
