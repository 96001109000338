import React, {Component} from 'react'
import { Link } from 'react-router';
import {ProfilePicture} from '../common'

export default class UserMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  handleClickOption = () => {
    this.setState({open: false})
  }

  handleBlur = (e) => {
    var currentTarget = e.currentTarget;

    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
          this.setState({open: false})
      }
    }, 0);
  }
  
  render() {  
    const { open } = this.state
    const { profile: {firstName, facebookId} } = this.props
    return (
        <div className="user-menu" tabIndex="0" onBlur={this.handleBlur} onClick={() => this.setState({open: !open})}>
            <ProfilePicture userSocialId={facebookId} size="32px"/>
            <span>{firstName}</span>

            <ul className={`user-menu--list ${open ? 'is-active' : ''}`}>
                <Link to="/perfil" onClick={this.handleClickOption}><li>Perfil</li></Link>
                <Link to="/meu-plano" onClick={this.handleClickOption}><li>Meu Plano</li></Link>
                <Link to="/login" onClick={this.handleClickOption} ><li className="logout">Logout</li></Link>
            </ul>
        </div>
    )
  }
}