import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from 'material-ui/styles';
import ProfileImage from '../../components/Elements/ProfileImage';
import InputText from '../../components/Elements/InputText';
import FbButton from '../../components/Elements/FbButton';
import ModalEditField from './ModalEditField';
import { validateEmail } from '../../utils/validation';
import ModalSelectConcurso from './SelectConcurso';
import upload from '../../utils/upload';
import {showAlert as showAlertAction} from '../../actions/alertActions'

const styles = theme => ({
  container: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    maxWidth: '970px',
    margin: '0 auto',
    padding: '32px 24px',
    '&:last-child': {
      borderBottom: 'none',
      marginBottom: '100px'
    }
  },
  profileUser: {
    display: 'flex',
    marginBottom: '40px',
    cursor: 'pointer',
    width: 'fit-content'
  },
  title: {
    ...theme.typography.subtitle,
    margin: '0px'
  },
  profileDesc: {
    padding: '4px',
  },
  changeAvatar: {
    ...theme.typography.small,
  },
  fbDesc: {
    ...theme.typography.observation,
    marginBottom: '40px'
  }
})

class ContaEdit extends Component {
  state = { edit: false }

  handleEdit = (field) => {
    this.setState({edit: field})
  }

  handleEditConcurso = (open) => {
    this.setState({editConcurso: open})
  }

  handleUploadClick = () => {
    document.getElementById('uploadAvatar').click();
  }

  pollProfileImage = (blobUrl) => {
    const { changeAvatar } = this.props;
    const request = new Request(blobUrl, { method: 'HEAD' });
    let count = 0;

    let interval = setInterval(async () => {
      let response = await fetch(request);

      if (response.status === 200) {
        clearInterval(interval);
        changeAvatar(blobUrl);
        return
      }

      if(count++ > 60) {
        clearInterval(interval);
      }
    }, 1000);
  }

  uploadImage = () => {
    const { profile, showAlert } = this.props;

    let fileInput = document.getElementById('uploadAvatar');
    const file = fileInput.files[0];

    if (!file) return;

    var container = 'user-uploads';
    var fileName = `${profile._id}-${new Date().getTime()}.${file.name.split('.').pop()}`;

    upload(container, file, `original/${fileName}`, (error) => {
      if (!error) {
        var blobUrl = `https://avatars.blob.core.windows.net/${container}/thumbnail/${fileName}`;
        this.pollProfileImage(blobUrl);
      }
      else {
        showAlert('Falha ao trocar avatar.', 'warning');
      }
    });
  }
 
  render() {
    const {classes, concursos = {}, profile: {name, email, _id, facebookId, mainConcurso, avatar}, profileUpdate, facebookDisconnect, facebookConnect, updateMainConcurso} = this.props
    const {edit, editConcurso} = this.state
    return (
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.profileUser}>
            <ProfileImage name={name} url={avatar} onClick={() => this.handleUploadClick()} />
            <input id='uploadAvatar' type='file' style={{display: 'none'}} accept='image/png, image/gif, image/jpeg, image/bmp, image/x-icon' onChange={() => this.uploadImage()}/>
            <div className={classes.profileDesc}>
              <InputText customClasses={{display: classes.title}} input={{value: name, placeholder: 'Cadastre seu nome'}}
              modalEdit={() => this.handleEdit({name: 'Nome', value: name, submit: () => {}, field: 'name', placeholder: 'Nome não cadastrado'})} />
              <div className={classes.changeAvatar} onClick={() => this.handleUploadClick()} >Alterar avatar</div>
            </div>
          </div>
          <div className={classes.profileData}>
            {/* <InputText label="E-mail" input={{value: email, placeholder: 'Cadastre seu email'}}
              modalEdit={() => this.handleEdit({name: 'E-mail', value: email, submit: () => {}, field: 'e-mail', placeholder: 'Não cadastrado', validation: validateEmail})} /> */}
              <p>Email: {email} (não pode ser alterado)</p>
              <InputText label="Concurso principal" input={{value: concursos[mainConcurso] && concursos[mainConcurso].name, placeholder: 'Selecione um concurso'}}
              modalEdit={() => this.handleEditConcurso(true)} />  
            {/* <InputText label="Telefone" input={{value: phone, placeholder: 'Cadastre seu telefone'}} 
              modalEdit={() => this.handleEdit({name: 'Telefone', value: phone, submit: () => {}, field: 'phone', placeholder: 'Não cadastrado'})} />
              <InputText label="CPF" input={{value: cpf, placeholder: 'Cadastre seu CPF'}} modalEdit disable /> */}
          </div>
        </div>
        <div className={classes.container}>
          <h2 className={classes.title}>Facebook</h2>
          <div className={classes.fbDesc}>{facebookId ? 'Você está conectado ao Clipping com uma conta do facebook' : 'Você pode conectar sua conta no Clipping com uma conta do facebook' }</div>
          <FbButton facebookId={facebookId} email={email} facebookConnect={facebookConnect} facebookDisconnect={facebookDisconnect} profileId={_id}/>
        </div>
        <ModalEditField handleOpen={this.handleEdit} open={edit} profileUpdate={profileUpdate} profileId={_id}/>
        <ModalSelectConcurso handleRequestClose={() => this.handleEditConcurso(false)} updateMainConcurso={updateMainConcurso} open={editConcurso} concursos={concursos} active={mainConcurso}/>
      </div>
    )
  }
}

const mapDispatchToProps = {
  showAlert: (text, type) => showAlertAction(text, type)
}

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps),
)(ContaEdit);