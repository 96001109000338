import React, { Component } from 'react';
import { withStyles } from 'material-ui/styles';
import { reduxForm, Field } from 'redux-form';
import compose from 'recompose/compose';
import { renderInputText } from '../../utils/form';
import Button from '../../components/Elements/Button'
import {validatePassword} from '../../utils/validation'

const formName = 'passwordEdit'

const styles = theme => ({
  container: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    maxWidth: '970px',
    margin: '0 auto',
    padding: '32px 24px',
    '&:last-child': {
      borderBottom: 'none',
      marginBottom: '100px'
    }
  },
  title: {
    ...theme.typography.subtitle,
    margin: '0px',
    marginBottom: '40px'
  },
  inputContainer: {
    marginBottom: '24px',
    maxWidth: '350px',
    width: '350px',
    '@media (max-width: 960px)': {
      width: 'auto'
    }
  }
})

class SenhaEdit extends Component {
  state = {edit: false}

  handleEdit = (field) => {
    this.setState({edit: field})
  }

  handleUpdatePassword = (post) => {
    const {updatePassword, reset} = this.props
    const data =  {
      current_password: post.password,
      new_password: post.new_password
    }
    updatePassword(data, 'Senha atualizada com sucesso!')
    reset()
  }
 
  render() {
    const {classes, handleSubmit, dirty, valid} = this.props
    return (
      <div className={classes.wrapper}>
        <form className={classes.container} onSubmit={handleSubmit(this.handleUpdatePassword)}>
            <h2 className={classes.title}>Alterar senha</h2> 
            <div className={classes.inputContainer}>
              <Field autoFocus label="SENHA ATUAL" name="password" type="password" autocomplete="password" id="password" component={renderInputText} />
            </div>
            <div className={classes.inputContainer}>
              <Field autoFocus label="NOVA SENHA" name="new_password" type="password" autocomplete="new_password" id="new_password" component={renderInputText} />
            </div>
            <div className={classes.inputContainer}>
              <Field autoFocus label="REPETIR A NOVA SENHA" name="confirm_new_password" type="password" autocomplete="confirm_new_password" id="confirm_new_password" component={renderInputText} />
            </div>
            <Button disabled={!dirty || (dirty && !valid)} type="submit">ALTERAR SENHA</Button>
        </form>
      </div>
    )
  }
}

const enhance = compose(
  withStyles(styles),
  reduxForm({
      form: formName,
      validate: (values, props) => {
          const errors = {};
          if (!values['password']) {
            errors['password'] = 'Digite sua senha atual';
          } else {
            errors['password'] = validatePassword(values['password']) ? 'Senha inválida' : undefined
          }
          
          if (!values['new_password']) {
            errors['new_password'] = 'Digite uma nova senha';
          } else {
            errors['new_password'] = validatePassword(values['new_password']) ? 'Senha inválida' : undefined
          }
          
          if (!values['confirm_new_password']) {
            errors['confirm_new_password'] = 'Digite um e-mail';
          } else {
            errors['confirm_new_password'] = validatePassword(values['confirm_new_password']) ? 'Senha inválida' : undefined
          }

          if(values['new_password'] && values['confirm_new_password'] && (values['new_password'] !== values['confirm_new_password'])) {
            errors['confirm_new_password'] = 'A senhas não coincidem'
          }
          
          return errors;
      }
  })
);

export default enhance(SenhaEdit)
