import React from 'react';
import Button from 'material-ui/Button';
import { withStyles } from 'material-ui/styles';
import Dialog, {
    DialogActions,
    DialogContent,
    DialogTitle,
} from 'material-ui/Dialog';
import Close from 'material-ui-icons/Close'
import IconButton from 'material-ui/IconButton';
import { crudCreate as crudCreateAction } from '../../actions/dataActions';
import { payCourseClass as payCourseClassAction } from '../../actions/courseActions';
import { reduxForm, Field } from 'redux-form';
import { renderAmountTextField } from '../../utils/form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { createNumberMask } from 'redux-form-input-masks';

import {PAGARME_ENCRYPTION_KEY} from '../../config';

const formName = 'courseClass';

const styles = theme => ({
    container: {
        borderRadius: '4px',
        minHeight: '247px',
        [theme.breakpoints.up('md')]: {
            minWidth: '700px'
        },
    },
    title: {
        color: theme.palette.orange,
        borderBottom: '1px solid #96969626',
        fontSize: '18px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: '600',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '80px',
        paddingLeft: '16px',
        paddingRight: '16px',
        '& span': {
            fontSize: '15px',
            color: 'rgba(0,0,0,0.5)'
        }
    },
    close: {
        color: theme.palette.text.clear,
        cursor: 'pointer',
        marginTop: '-15px',
        width: '20px',
        '&:hover': {
            color: theme.palette.orange,
        },
    },
    content: {
        padding: '18px',
        minHeight: '115px'
    },
    error: {
        paddingLeft: '24px',
        ...theme.typography.error,
        marginBottom: '16px',
        float: 'right',
        paddingRight: '24px'
    },
    actions: {
        justifyContent: 'space-between'
    },
    minimumPayment: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: '600',
        fontSize: '15px',
        color: 'rgba(0,0,0,0.7)',
        paddingLeft: '8px'
    }
})

class PaymentModal extends React.Component {

    handleClose = () => {
        this.props.handleOpen(false)
        this.props.reset()
    }

    payCourseClass = (data) => {
        this.handleClose();
        const { open, profile } = this.props;

        new window.PagarMeCheckout.Checkout({
            encryption_key: PAGARME_ENCRYPTION_KEY,
            success: (data) => {
                delete data.installments;
                delete data.payment_method;
                data.type = 'payment';
                this.props.payCourseClass(data, open);
            }
        }).open({
            amount: data.amountPaid * 100,
            payment_methods: 'credit_card',
            maxInstallments: 1,
            createToken: 'false',
            customerData: `${!Boolean(profile.customer)}`,
            paymentButtonText: 'Pagar',
            uiColor: '#F58220'
        });
    }

    render() {
        const { classes, handleSubmit, open, dirty, valid, error, minimumPayment } = this.props;

        const currencyMask = createNumberMask({
            decimalPlaces: 0,
            locale: 'pt-BR',
            allowEmpty: true
        });

        return (
            <Dialog
                open={Boolean(open)}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
                classes={{ paper: classes.container }}
            >
                <DialogTitle disableTypography className={classes.title}>
                    <p>
                        Pague o quanto quiser<br />
                        <span>Você decide o valor que considera justo pela aula</span>
                    </p>
                    <div className={classes.close}></div>
                    <IconButton
                        className={classes.close}
                        aria-label="Fechar"
                        onClick={this.handleClose} >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <form onSubmit={handleSubmit(this.payCourseClass)}>
                    <DialogContent className={classes.content}>
                        <Field fullWidth autoFocus
                            margin="dense"
                            label="Valor"
                            name="amountPaid"
                            id="amountPaid"
                            component={renderAmountTextField}
                            {...currencyMask}
                        />
                    </DialogContent>
                    <DialogActions className={classes.actions}>

                        <span className={classes.minimumPayment}>{minimumPayment > 3 && `O valor mínimo para as aulas deste curso é de R$${minimumPayment}.`}</span>
                        <Button disabled={!dirty || (dirty && !valid)} type="submit" color="primary">
                            PAGAR
                    </Button>
                    </DialogActions>
                    {error && <div className={classes.error}>{error}</div>}
                </form>
            </Dialog>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        submitting: state.waiting,
        profile: state.user.profile
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        crudCreate: (...params) => {
            dispatch(crudCreateAction(...params))
        },
        payCourseClass: (...params) => {
            dispatch(payCourseClassAction(...params))
        }
    }
}

const enhance = compose(
    withStyles(styles),
    reduxForm({
        form: formName,
        validate: (values, props) => {
            const errors = {};
            if (!isNaN(values.amountPaid) && values.amountPaid === 0) {
                errors.amountPaid = 'Favor inserir um valor não-nulo.';
            } else if (!isNaN(values.amountPaid) && !isNaN(props.minimumPayment) && values.amountPaid < props.minimumPayment) {
                errors.amountPaid = `Favor inserir um valor maior que o mínimo de R$${props.minimumPayment}.`;
            }
            return errors;
        }
    }),
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(PaymentModal)
