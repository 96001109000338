import styled from "styled-components";
import { TextCollapse } from "@clippingcacd/core";

export const Root = styled.a`
  background: #ffffff;
  width: 100%;
  border-radius: 8px;
  padding: 24px 32px 16px;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  text-decoration: none;

  &,
  * {
    font-family: Inter;
    cursor: pointer;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  
  > mark {
    background: transparent;
    color: #249ac2;
    font-weight: 600;
  }

  > strong {
    margin-left: 4px;
  }

  #comment-user {
    font-weight: 600;
  }

  #comment-text {
    display: inline;
    font-weight: 600;

    > p {
      display: inline;
      margin: 0;

      > button {
        display: none;
      }
    }
  }

  #comment-question {
    color: #808080;
    > p {
      display: inline;
      margin: 0;

      > button {
        display: none;
      }
    }
  }
`;

export const Statement = styled(TextCollapse)`
  white-space: pre-wrap;
  margin: 0px;
  font-weight: bold;

  & > button {
    display: none;
  }
`;

export const Comment = styled(TextCollapse)`
  margin: 0px;
  margin-left: 4px;
  flex: 1;
  font-weight: bold;

  & > button {
    display: none;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionsContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  button {
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    font-size: 13px;
  }

  > button {
    height: fit-content;
    border-left: 1px rgba(224, 224, 224, 1) solid;
    margin-left: 4px;
    padding-left: 12px;
    color: #585858;
    font-weight: 500;
  }

  > div {
    display: flex;

    > button {
      padding: 8px;
      color: #b0b0b0;

      > svg {
        height: 16px;
        width: 16px;
        margin-right: 4px;
      }
    }
  }
`;

export const CreateDate = styled.span`
  font-size: 13px;
  font-weight: 500;
  color: #b0b0b0;
`;
