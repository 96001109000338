import React, { Component } from 'react'
import { withStyles } from 'material-ui/styles';
import FetchListByOne from '../../components/FetchListByOne'
import {ElementList} from '../../components/common'
import ListItem from './ListItem'
import ArrowDown from 'material-ui-icons/ArrowDropDown'
import ArrowUp from 'material-ui-icons/ArrowDropUp'
import classNames from 'classnames';

const resource = 'extraContent'

const styles = theme => ({
  container: {
    padding: '31px 70px 40px 56px'
  },
  note: {
    border: '1px solid #A1C7F3',
    borderRadius: '4px',
    backgroundColor: '#E6F2FF',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.black.a8,
    fontSize: '13px',
    padding: '10px 8px 8px 16px',
    marginBottom: '33px',
    display: 'flex',
    alignItems: 'top',
    cursor: 'pointer'
  },
  noteContent: {
    paddingRight: '12px',
    lineHeight: '18px',
    '& a': {
      textDecoration: 'none',
      color: '#4A90E2'
    }
  },
  noteClosed: {
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden'
  },
  icon: {
    opacity: '0.2'
  }
});

class ContentList extends Component {
  state = {
    noteActive: false
  }

  toggleNote = () => {
    this.setState({noteActive: !this.state.noteActive});
  }

  render() {
    const {classes, _id, note} = this.props;
    const {noteActive} = this.state

    return (
      <div className={classes.container}>
        {note && (
          <div className={classes.note} onClick={this.toggleNote}>
            <div dangerouslySetInnerHTML={{__html: note}}
              className={classNames(classes.noteContent, noteActive ? '' : classes.noteClosed)}></div>
            <div className={classes.noteRight}>
              {noteActive ? <ArrowUp className={classes.icon} /> : <ArrowDown className={classes.icon}/>}
            </div>
          </div>
        )}

        <FetchListByOne keepStateOnRefresh={true} resource={resource} _id={`bysection/${_id}`} >
          <ElementList renderRow={(props) => <ListItem extraSection={_id} {...props} />} />
        </FetchListByOne >  
      </div>
    )
  }
}

export default withStyles(styles)(ContentList)

