import { SORT_ASC, SORT_DESC } from '../reducers/resource/list/queryReducer';

export const BaseUrl = process.env.REACT_APP_API_BASE_URL;
export const defaultAvatar = 'https://d30y9cdsu7xlg0.cloudfront.net/png/15724-200.png';
export const LoginUrl = process.env.REACT_APP_LOGIN_URL;
export const PAGARME_ENCRYPTION_KEY = "";
export const IUGU_ENCRYPTION_KEY = process.env.REACT_APP_IUGU_ENCRYPTION_KEY;
export const NEW_API_BASE_URL = process.env.REACT_APP_NEW_API_BASE_URL;
export const GATracker = process.env.REACT_APP_GA_TRACKER_ID;

export const listReducers = ['timelinePost', 'userPM', 'clipping', 'organizador', 'notebook', 'notebookContent', 'biblioSubject/byweek', 'biblioSubjectMaterial/byweek', 'biblioSubjectMaterial/bysubject', 'biblioSubject/bysubject', 'biblioSubjectMaterial', 'video', 'test/user', 'test', 'doeContent', 'course', 'courseGroup', 'extraSection', 'extraContent', 'extraContentUser', 'bookmark', 'article', 'biblioReadingsRequired', 'bookmark/byresourcetype/article', 'biblioReadingsRecommendation', 'editalSubject/user', 'courseClass/user', 'courseClassUser/debts', 'editalTopic/bysubject', 'editalTopic/userPerformance', 'subscription/pagarme/transactions', 'plan', 'search', 'search/notebooks', 'search/notebookcontents', 'search/articles', 'search/articles/lastTerms', 'search/articles/trending', 'search/clippingsectioncategories', 'clippingSectionCategory', 'doeSection', 'questionEssayAnswer', 'article/category', 'law'];


export const selectCreated = [
  {
    label: 'Mais recentes',
    value: {
      field: 'createdAt',
      order: SORT_DESC,
    },
  },
  {
    label: 'Menos recentes',
    value: {
      field: 'createdAt',
      order: SORT_ASC,
    },
  },
];

export const selectOrdering = [
  {
    label: 'Mais recentes',
    value: {
      field: 'createdAt',
      order: SORT_DESC,
    },
  },
  {
    label: 'Menos recentes',
    value: {
      field: 'createdAt',
      order: SORT_ASC,
    },
  },
  {
    label: 'Mais comentadas',
    value: {
      field: 'comments',
      order: SORT_DESC,
    },
  },
];

export const selectOrderingLikes = [
  {
    label: 'Mais recentes',
    value: {
      field: 'createdAt',
      order: SORT_DESC,
    },
  },
  {
    label: 'Menos recentes',
    value: {
      field: 'createdAt',
      order: SORT_ASC,
    },
  },
  // {label: 'Mais curtidas', value: {
  //     field: 'likes',
  //     order: SORT_DESC,
  // }}
];

export const qualidadeSelect = [{ label: 'Selecione', value: undefined, disable: true },
  { label: 'Excelente', value: 'excelente' },
  { label: 'Regular', value: 'regular' },
  { label: 'Péssimo', value: 'pessimo' }];

export const selectCourses = [{ label: 'CACD', value: 1 },
  { label: 'Curso 2', value: 2 },
  { label: 'Curso 3', value: 3 }];

export const selectCategory = [{ label: 'Categorias', value: undefined, disable: true },
  { label: 'Todas', value: '' },
  { label: 'Geografia', value: 'geografia' },
  { label: 'Política Internacional', value: 'politica_internacional' },
  { label: 'Francês', value: 'frances' }];

export const selectType = (concurso) => {
  if (['PRF', 'TRIBUNAIS', 'TRT'].includes(concurso.name)) {
    return [{ label: 'exibir por ciclo', value: 'byweek' },
      { label: 'exibir por disciplina', value: 'bysubject' }];
  }

  return [{ label: 'exibir por disciplina', value: 'bysubject' },
    { label: 'exibir por ciclo', value: 'byweek' }];
};

export const clippingsTab = [{ label: 'exibir por dia', value: 'createdAt' },
  { label: 'exibir por tema', value: 'category' }];

export const essaysTab = [
  { label: 'pendentes', value: 'unreplied' },
  { label: 'corrigidas', value: 'replied' }];
export const essaysTabUser = [
  { label: 'Sua Redação', value: 'mine' },
  { label: 'Todas', value: 'all' }];
export const selectDisciplina = [{ label: 'Escolha uma disciplina', value: '', disable: true },
  { label: 'categoria 1', value: 1 },
  { label: 'categoria 2', value: 2 },
  { label: 'categoria 3', value: 3 }];

export const selectNaoResolvidas = [{ label: 'Não resolvidas', value: '', disable: true },
  { label: 'categoria 1', value: 1 },
  { label: 'categoria 2', value: 2 },
  { label: 'categoria 3', value: 3 }];

export const selectConcursos = [{ label: 'Concursos', value: '', disable: true },
  { label: 'categoria 1', value: 1 },
  { label: 'categoria 2', value: 2 },
  { label: 'categoria 3', value: 3 }];

export const selectAnos = [{ label: 'Ano', value: '', disable: true },
  { label: 'categoria 1', value: 1 },
  { label: 'categoria 2', value: 2 },
  { label: 'categoria 3', value: 3 }];

export const selectDisciplinas = [{ label: 'Disciplinas', value: '', disable: true },
  { label: 'categoria 1', value: 1 },
  { label: 'categoria 2', value: 2 },
  { label: 'categoria 3', value: 3 }];

export const selectTema = [{ label: 'Escolha por tema', value: '', disable: true },
  { label: 'categoria 1', value: 1 },
  { label: 'categoria 2', value: 2 },
  { label: 'categoria 3', value: 3 }];

export const showProfile = ['/mural', '/perfil', '/cobranca', '/alterar-senha', '/notificacoes'];

export const selectAno = [
  { label: 'Ano', value: '', disable: true },
  { label: '2017', value: 2017 },
  { label: '2018', value: 2018 },
  { label: '2019', value: 2019 },
  { label: '2020', value: 2020 },
  { label: '2021', value: 2021 },
  { label: '2022', value: 2022 },
  { label: '2023', value: 2023 },
  { label: '2024', value: 2024 },
  { label: '2025', value: 2025 },
];

export const selectMes = [
  { label: 'Mês', value: '', disable: true },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
];

export const CACD = {
  _id: '5978a90f1495ec577ff2b295',
  name: 'CACD',
  createdAt: '2017-07-26T14:37:03.304Z',
  updatedAt: '2017-09-11T17:49:01.985Z',
  isDeleted: false,
  concursoId: 1,
  extraSection: [1],
  doeSection: [1],
  exman: [1],
  question: [1],
  video: [1],
  organizer: [1],
  clipping: [1],
  timelinePost: [1, 2],
  biblioSubject: [1],
  biblioSubjectMaterial: [1],
  notebook: [1],
  article: [1],
  bookmark: [1],
  editalSubject: [1],
  editalTopic: [1],
  test: [1],
};
