import React from 'react';
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import SearchIcon from 'material-ui-icons/Search';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import compose from 'recompose/compose';
import Highlighter from "react-highlight-words";
import { Link } from 'react-router';
import SearchInput from './SearchInput';
import moment from 'moment';
import Divider from 'material-ui/Divider';
import _ from 'lodash';

const removeDiacritics = require('diacritics').remove;

const styles = theme => ({
  search: {
    height: '42px',
    borderRadius: '3px',
    backgroundColor: 'hsla(0, 0%, 0%, 0.07)',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexBasis: '0.000000001px',
    display: 'none',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      maxWidth: '58vw',
      margin: 'auto',
    },
    paddingLeft: '16px',
    position: 'relative'
  },
  searchActive: {
    borderRadius: '3px 3px 0 0'
  },
  searchIcon: {
    display: 'flex',
    color: '#bdbdbd',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },

  searchResults: {
    position: 'absolute',
    background: '#ffffff',
    paddingBottom: '8px',
    maxHeight: 'calc(100vh - 146px)',
    top: '42px',
    height: "fit-content",
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'auto',
    borderColor: 'rgba(0,0,0,0.08)',
    borderStyle: 'solid',
    borderWidth: '0 1px 1px 1px',
    [theme.breakpoints.up('md')]: {
      top: '42px',
      position: 'absolute',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      bottom: 'auto'
    },
  },
  searchSection: {
    margin: '24px 0px 27px 0px'
  },
  searchSectionTitle: {
    paddingLeft: '32px',
    marginBottom: '0px',
    fontSize: '15px',
    fontWeight: 300,
    color: 'rgba(0,0,0,0.6)',
    fontFamily: '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
    lineHeight: '1.47'
  },
  searchArticleSectionList: {
    marginTop: '14px'
  },
  searchSectionList: {
    padding: 0,
    margin: 0,
    listStyle: 'none'
  },
  searchSectionListNarrow: {
    '& > li:first-child': {
      marginTop: '8px'
    },
    '& $searchItem': {
      margin: '0',
      padding: '8px 32px'
    }
  },
  categorySearchItem: {
    padding: '0 10px'
  },
  searchItem: {
    margin: '8px 0',
    padding: '12px 32px',
    display: 'flex',
    color: 'rgba(0,0,0,0.6)',
    alignItems: 'center',
    borderRadius: '2px',
    fontFamily: '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
    cursor: 'pointer',
    '& a': {
      textDecoration: 'none',
      width: '100%',
      color: 'inherit'
    },
    '& span': {
      display: 'block',
      margin: 0,
      '& span, & strong': {
        display: 'inline'
      }
    },
    '& strong': {
      fontWeight: 'bold',
      color: 'rgba(0,0,0,0.8)'
    },
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.08)'
    }
  },
  categoryLink: {
    display: 'flex',
    alignItems: 'center',
  },
  simpleItem: {
    marginLeft: '18px',
    lineHeight: '32px'
  },
  icon: {
    color: '#c1c1c1',
    marginRight: '18px',
    fontSize: '18px'
  },
  searchItemMultiline: {
    padding: '5px 0',
    overflow: 'hidden'
  },
  itemCategory: {
    color: '#71D3D9',
    fontSize: '14px',
    lineHeight: '17px'
  },
  articleDate: {
    fontSize: '14px',
    lineHeight: '17px',
    color: 'rgba(0, 0, 0, 0.6)'
  },
  article: {
    marginLeft: '18px',
    marginTop: '8px'
  },
  itemTitle: {
    fontSize: '17px',
    lineHeight: '21px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.6)'
  },
  itemDescription: {
    fontSize: '14px',
    lineHeight: '17px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  articleDescription: {
    height: '21px',
    color: 'rgba(0, 0, 0, 0.6)'
  },
  articleIcon: {
    width: '13px',
    height: '13px',
    color: '#f68525',
    marginRight: '4px'
  },
  categoryIcon: {
    width: '16px',
    height: '16px',
    color: '#f68525',
    marginRight: '4px'
  },
  articleCategory: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
    fontSize: '13px',
    fontWeight: 500,
    color: '#f68525'
  },
  allResults: {
    marginRight: '40px',
    display: 'flex',
    justifyContent: 'flex-end',
    fontFamily: '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
    fontSize: '14px',
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.6)',
    '& a': {
      color: 'inherit',
      textDecoration: 'none'
    }
  },
  emptyResults: {
    fontFamily: '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
    padding: '24px 32px',
    '& h3': {
      fontSize: '18px',
      lineHeight: '21px',
      color: 'rgba(0,0,0,.6)',
      fontWeight: 'normal',
      margin: '0'
    },
    '& span': {
      fontSize: '14px',
      lineHeight: '21px',
      color: 'rgba(0,0,0,.4)'
    }
  },
  sectionDivider: {
    marginBottom: '8px'
  }
});

class Search extends React.Component {

  constructor(props) {
    super(props)


    this.state = {
      searchTerms: '',
      resources: {
        'Meus Fichamentos': {
          results: [
            { name: 'notebooks', method: this.renderNotebookResults },
            { name: 'notebookcontents', method: this.renderNotebookContentResults }
          ]
        },
        'Notícias Diárias': {
          results: [
            { name: 'articles', method: this.renderArticleResults },
            { name: 'clippingsectioncategories', method: this.renderCategoryResults }
          ],
          recommendations: [
            { name: 'articles/lastTerms', method: this.renderArticlesLastTerms },
            { name: 'articles/trending', method: this.renderArticlesTrending }
          ]
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { routeLabel, loadSearchTerms, loadSearchTrending } = this.props;

    if((nextProps.routeLabel !== routeLabel) &&
      (nextProps.routeLabel === 'Clippings'))
    {
      loadSearchTerms('articles');
      loadSearchTrending('articles');
    }
  }

  handleSearch = debounce((terms, resource) => {
    this.setState({ searchTerms: terms });
    let stateResource = this.state.resources[resource];
    stateResource && stateResource.results.forEach(res => {
      this.props.loadSearch(this.props.concurso, terms || ' ', res.name);
    });
  }, 500)

  handleClearSearch = () => {
    this.handleSearch('');
    this.props.onClear();
  }

  sanitizeQuery = (text) => {
    return removeDiacritics(text);
  }

  renderArticleResults = () => {
    const { classes, searchArticleResults } = this.props;
    const { docs, total } = searchArticleResults;
    const { searchTerms } = this.state;
    return (docs.length > 0) && (
      <div className={classes.searchSection}>
        <h3 className={classes.searchSectionTitle}>Notícias</h3>
        <ul className={classNames(classes.searchSectionList, classes.searchArticleSectionList)}>
          {docs.map(article => (
            <li key={article._id} className={classes.searchItem}>
                <Link to={`/clippings/${article.section.clipping}/${article._id}`} onClick={this.handleClearSearch}>
                  <span className={classes.articleDate}>{
                  article.pubDate ? 
                  moment(article.pubDate).format('D, MMM. YYYY'):
                  ''
                }</span>
                  <div className={classes.article}>
                    <Highlighter
                      className={classes.itemTitle}
                      highlightTag="strong"
                      searchWords={this.state.searchTerms.split(/\s/)}
                      autoEscape={true}
                      textToHighlight={article.title}
                      sanitize={this.sanitizeQuery}
                    />
                    <Highlighter
                      className={classNames(classes.itemDescription, classes.articleDescription)}
                      highlightTag="strong"
                      searchWords={this.state.searchTerms.split(/\s/)}
                      autoEscape={true}
                      textToHighlight={article.content.replace('&nbsp;', '')}
                      sanitize={this.sanitizeQuery}
                    />
                    <div className={classes.articleCategory}>{article.category && article.category.name.toUpperCase()}</div>
                  </div>
                </Link>
              </li>
          ))}
        </ul>
        <div className={classes.allResults}>
          <Link to={`/busca/noticias?q=${searchTerms}`} onClick={this.handleClearSearch}>Exibir todos os resultados ({total.values})</Link>
        </div>
      </div>
    )
  }

  renderCategoryResults = () => {
    const { classes, searchCategoryResults } = this.props;
    const { docs } = searchCategoryResults;

    return (docs.length > 0) && (
      <div className={classes.searchSection}>
        <h3 className={classes.searchSectionTitle}>Temas</h3>
        <ul className={classNames(classes.searchSectionList, classes.searchSectionListNarrow)}>
          {docs.map(category => (
            <li key={category._id} className={classNames(classes.searchItem, classes.categorySearchItem)}>
              <Link to={`/temas/${category._id}`} onClick={this.handleClearSearch} className={classNames(classes.categoryLink, classes.simpleItem)}>
                <Highlighter
                  className={classes.itemTitle}
                  highlightTag="strong"
                  searchWords={this.state.searchTerms.split(/\s/)}
                  autoEscape={true}
                  textToHighlight={category.name}
                  sanitize={this.sanitizeQuery}
                />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  renderNotebookResults = () => {
    const { classes, searchNotebookResults } = this.props;
    const { docs } = searchNotebookResults;

    return (docs.length > 0) && (
      <div className={classes.searchSection}>
        <h3 className={classes.searchSectionTitle}>Cadernos</h3>
        <ul className={classNames(classes.searchSectionList, classes.searchSectionListNarrow)}>
          {docs.map(notebook => (
            <li key={notebook._id} className={classes.searchItem}>
              <i className={classNames(classes.icon, "icon-book-open icons")}></i>
              <Link to={`/cadernos/${notebook._id}`} onClick={this.handleClearSearch}>
                <Highlighter
                    className={classes.simpleItem}
                    highlightTag="strong"
                    searchWords={this.state.searchTerms.split(/\s/)}
                    autoEscape={true}
                    textToHighlight={notebook.title}
                    sanitize={this.sanitizeQuery}
                  />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  renderNotebookContentResults = () => {
    const { classes, searchNotebookContentResults } = this.props;
    const { docs } = searchNotebookContentResults;

    return (docs.length > 0) && (
      <div className={classes.searchSection}>
        <h3 className={classes.searchSectionTitle}>Fichamentos</h3>
        <ul className={classes.searchSectionList}>
          {docs.map(notebookContent => (
            <li key={notebookContent._id} className={classes.searchItem}>
              <i className={classNames(classes.icon, "icon-book-open icons")}></i>
              <Link className={classes.searchItemMultiline}
                to={`/cadernos/${notebookContent.notebook._id}/${notebookContent._id}`}
                onClick={this.handleClearSearch}>
                <span className={classes.itemCategory}>{notebookContent.notebook.title}</span>
                <Highlighter
                  className={classes.itemTitle}
                  highlightTag="strong"
                  searchWords={this.state.searchTerms.split(/\s/)}
                  autoEscape={true}
                  textToHighlight={notebookContent.title}
                  sanitize={this.sanitizeQuery}
                />
                <Highlighter
                  className={classes.itemDescription}
                  highlightTag="strong"
                  searchWords={this.state.searchTerms.split(/\s/)}
                  autoEscape={true}
                  textToHighlight={notebookContent.content}
                  sanitize={this.sanitizeQuery}
                />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  renderArticlesLastTerms = () => {
    const { classes, searchArticleLastTerms } = this.props;
    const { docs } = searchArticleLastTerms;

    return (docs.length > 0) && (
      <div className={classes.searchSection}>
        <h3 className={classes.searchSectionTitle}>Minhas pesquisas recentes</h3>
        <ul className={classNames(classes.searchSectionList, classes.searchSectionListNarrow)}>
          {docs.sort((a, b) => (b.createdAt < a.createdAt ? -1 : 1)).map((doc) => (
            <li key={doc._id} className={classes.searchItem}>
              <Link to={`/busca/noticias?q=${doc.term}`} onClick={this.handleClearSearch} className={classes.simpleItem}>
                <div className={classes.itemTitle}>{doc.term}</div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  renderArticlesTrending = () => {
    const { classes, searchArticleTrending } = this.props;
    const { docs } = searchArticleTrending;

    return (docs.length > 0) && (
      <div className={classes.searchSection}>
        <h3 className={classes.searchSectionTitle}>Notícias mais lidas da semana</h3>
        <ul className={classNames(classes.searchSectionList, classes.searchArticleSectionList)}>
          {docs.map(article => (
            <li key={article._id} className={classes.searchItem}>
              <Link to={`/clippings/${article.clipping}/${article._id}`} onClick={this.handleClearSearch}>
                <span className={classes.articleDate}>{article.pubDate 
                ? moment(article.pubDate).format('D, MMM. YYYY')
                : ''}
                  </span>
                <div className={classes.article}>
                  <div className={classes.itemTitle}>{article.title}</div>
                  <div className={classNames(classes.itemDescription, classes.articleDescription)}>{article.content}</div>
                  <div className={classes.articleCategory}>{article.category && article.category.name.toUpperCase()}</div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  render() {

    const { classes, alertBarIsOn, openConcursos, concurso, routeLabel, search, onBlur, onFocus, searchFocus } = this.props;
    const { resources, searchTerms } = this.state;


    classes.searchResults
    let top = window.innerWidth > 992 ? 42 :(alertBarIsOn ? 144: 58)
    let height = window.innerHeight - top -2;

    let match = routeLabel.match(/Meus Fichamentos|Notícias Diárias/i);
    let contents = match && resources[match[0]].results.map(res => res.method.call(this)).filter(x => x);

    return match && (
      <div className={classNames(classes.search, searchFocus ? classes.searchActive : '')} style={search ? {display: 'flex'} :  {}}>
        <SearchIcon className={classes.searchIcon}/>

        <SearchInput onFocus={onFocus} onBlur={onBlur} handleClearSearch={this.handleClearSearch}
          routeLabel={routeLabel} handleSearch={(terms) => this.handleSearch(terms, match[0])}
          searchFocus={searchFocus} searchTerms={searchTerms} />

        {searchFocus && (
          Boolean(searchTerms) ? (
            <div  className={classes.searchResults}>
              {(contents.length > 0) ? (
                contents.map((content, index) => {
                  return (
                    <div key={index}>
                      { content }
                      { (index < contents.length - 1) && <Divider className={classes.sectionDivider}/>}
                    </div>
                  )
                })
              ) : (
                this.props.loading ? null : (
                  <div className={classes.emptyResults}>
                    <h3>Não encontramos nenhum resultado :(</h3>
                    <span>Tente procurar por outros termos.</span>
                  </div>
                )
              )}
            </div>
          ) :
          Boolean(resources[match[0]].recommendations) && (
            <div className={classes.searchResults}>
              { resources[match[0]].recommendations.map((resource, index) => {
                return (
                  <div key={resource.name}>
                    { resource.method.call(this) }
                    { (index < resources[match[0]].recommendations.length - 1) && <Divider className={classes.sectionDivider}/>}
                  </div>
                )
              })}
            </div>
          )
        )}
      </div>
    );
  }
}

const getSearchData = (state, resource) => state[resource].data;
const getSearchIds = (state, resource) => state[resource].list.ids;
const getSearchTotal = (state, resource) => state[resource].list.total;

const searchValues = createSelector(
  getSearchData,
  getSearchIds,
  getSearchTotal,
  (data, ids, total) => ({
    docs: _.values(data).filter(x => (
      ids.includes(x._id)
    )),
    total
  })
);

const mapStateToProps = state => {
  return {
    searchNotebookResults: searchValues(state, 'search/notebooks'),
    searchNotebookContentResults: searchValues(state, 'search/notebookcontents'),
    searchArticleResults: searchValues(state, 'search/articles'),
    searchArticleLastTerms: searchValues(state, 'search/articles/lastTerms'),
    searchArticleTrending: searchValues(state, 'search/articles/trending'),
    searchCategoryResults: searchValues(state, 'search/clippingsectioncategories'),
    loading: state.loading
  }
}

Search.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(Search);
