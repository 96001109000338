import {
  UPDATE,
  CUSTOM,
} from '../services/rest_types';
import {
  CRUD_CREATE,
} from './dataActions';

export const PROFILE_UPDATE = 'PROFILE_UPDATE';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAILURE = 'PROFILE_UPDATE_FAILURE';
export const profileUpdate = (id, data, redirect = false, message = true, customMessage = 'Dados de perfil atualizados com sucesso!') => ({
  type: PROFILE_UPDATE,
  payload: {
    id, data, previousData: {}, redirect,
  },
  meta: {
    resource: 'user', profile: UPDATE, cancelPrevious: false, message, customMessage,
  },
});

export const KINDLE_CONNECT = 'KINDLE_CONNECT';
export const KINDLE_CONNECT_SUCCESS = 'KINDLE_CONNECT_SUCCESS';
export const KINDLE_CONNECT_FAILURE = 'KINDLE_CONNECT_FAILURE';
export const kindleConnect = (id, data, redirect = false, message = true, customMessage = 'E-mail kindle conectado com sucesso!') => ({
  type: KINDLE_CONNECT,
  payload: {
    id, data, previousData: {}, redirect,
  },
  meta: {
    resource: 'userProfile', profile: UPDATE, cancelPrevious: false, message, customMessage,
  },
});

export const KINDLE_DISCONNECT = 'KINDLE_DISCONNECT';
export const KINDLE_DISCONNECT_SUCCESS = 'KINDLE_DISCONNECT_SUCCESS';
export const KINDLE_DISCONNECT_FAILURE = 'KINDLE_DISCONNECT_FAILURE';
export const kindleDisconnect = (id, customMessage = 'E-mail kindle desconectado com sucesso!') => ({
  type: KINDLE_DISCONNECT,
  payload: {
    id, data: { kindle: '' }, previousData: {}, redirect: false,
  },
  meta: {
    resource: 'userProfile', custom: true, fetchCustom: CUSTOM, customMethod: 'PUT', resource: 'userProfile/' + id, cancelPrevious: false, message: true, customMessage,
  },
});

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';
export const updatePassword = (data, customMessage = 'Sua senha foi atualizada!') => ({
  type: CRUD_CREATE,
  payload: { data },
  meta: {
    custom: true, customMethod: 'POST', resource: 'user/change-password', cancelPrevious: false, fetchCustom: CUSTOM, message: true, customMessage,
  },
});

export const GET_ACCOUNT_DATA = 'GET_ACCOUNT_DATA';
export const GET_ACCOUNT_DATA_SUCCESS = 'GET_ACCOUNT_DATA_SUCCESS';
export const getAccountData = (cancelPrevious = false) => ({
  type: GET_ACCOUNT_DATA,
  payload: {},
  meta: {
    resource: 'subscription/plan', fetch: CUSTOM, cancelPrevious, customMethod: 'GET',
  },
});

export const GET_ACCOUNT_ACTIVE_SUBSCRIPTIONS = 'GET_ACCOUNT_ACTIVE_SUBSCRIPTIONS';
export const GET_ACCOUNT_ACTIVE_SUBSCRIPTIONS_SUCCESS = 'GET_ACCOUNT_ACTIVE_SUBSCRIPTIONS_SUCCESS';
export const getActiveSubscriptions = (cancelPrevious = false) => ({
  type: GET_ACCOUNT_ACTIVE_SUBSCRIPTIONS,
  payload: {},
  meta: {
    resource: 'subscription/active-subscriptions', fetch: CUSTOM, cancelPrevious, customMethod: 'GET',
  },
});


export const GET_ACCOUNT_PM = 'GET_ACCOUNT_PM';
export const GET_ACCOUNT_PM_SUCCESS = 'GET_ACCOUNT_PM_SUCCESS';
export const getAccountPM = (cancelPrevious = false) => ({
  type: GET_ACCOUNT_PM,
  payload: { urlParams: 'source=iugu' },
  meta: {
    resource: 'userPM', fetch: CUSTOM, cancelPrevious, customMethod: 'GET',
  },
});


export const REQUEST_CHAT_CANCEL = 'REQUEST_CHAT_CANCEL';
export const REQUEST_CHAT_CANCEL_SUCCESS = 'REQUEST_CHAT_CANCEL_SUCCESS';
export const REQUEST_CHAT_CANCEL_FAILURE = 'REQUEST_CHAT_CANCEL_FAILURE';
export const requestChatCancel = (cancelPrevious = false) => ({
  type: CRUD_CREATE,
  payload: {},
  meta: {
    resource: 'chatWarner/request', fetchCustom: CUSTOM, cancelPrevious, custom: true, customMethod: 'POST',
  },
});

export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION_FAILURE = 'UPDATE_SUBSCRIPTION_FAILURE';
export const updateSubscription = (data, customMessage = 'Seu plano foi alterado com sucesso!', basePath, redirect = false, message = true) => ({
  type: UPDATE_SUBSCRIPTION,
  payload: { data, redirect, basePath },
  meta: {
    resource: 'subscription/plan', fetch: UPDATE, cancelPrevious: false, message, customMessage,
  },
});

export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE';
export const cancelSubscription = (data, customMessage = 'Seu plano não sera mais renovado automaticamente.') => ({
  type: CANCEL_SUBSCRIPTION,
  payload: { data },
  meta: {
    custom: true, resource: 'subscription/cancel', fetchCustom: CUSTOM, customMethod: 'POST', cancelPrevious: false, message: true, customMessage,
  },
});

export const UPDATE_CREDITCARD = 'UPDATE_CREDITCARD';
export const UPDATE_CREDITCARD_SUCCESS = 'UPDATE_CREDITCARD_SUCCESS';
export const UPDATE_CREDITCARD_FAILURE = 'UPDATE_CREDITCARD_FAILURE';
export const updateCreditCard = (cardHash, customMessage = 'Os dados do cartão foram atualizados') => ({
  type: UPDATE_CREDITCARD,
  payload: { data: { cardHash } },
  meta: {
    resource: 'subscription/credit_card', fetch: UPDATE, cancelPrevious: false, message: true, customMessage,
  },
});

export const FACEBOOK_CONNECT = 'FACEBOOK_CONNECT';
export const FACEBOOK_CONNECT_SUCCESS = 'FACEBOOK_CONNECT_SUCCESS';
export const FACEBOOK_CONNECT_FAILURE = 'FACEBOOK_CONNECT_FAILURE';
export const FACEBOOK_DISCONNECT = 'FACEBOOK_DISCONNECT';
export const FACEBOOK_DISCONNECT_SUCCESS = 'FACEBOOK_DISCONNECT_SUCCESS';
export const FACEBOOK_DISCONNECT_FAILURE = 'FACEBOOK_DISCONNECT_FAILURE';
export const facebookConnect = (token, customMessage) => ({
  type: FACEBOOK_CONNECT,
  payload: { data: { token } },
  meta: {
    custom: true, resource: 'login/facebook/bind', fetchCustom: CUSTOM, customMethod: 'POST', cancelPrevious: false, message: true, customMessage,
  },
});
export const facebookDisconnect = (customMessage) => ({
  type: FACEBOOK_DISCONNECT,
  payload: { },
  meta: {
    custom: true, resource: 'login/facebook/unbind', fetchCustom: CUSTOM, customMethod: 'POST', cancelPrevious: false, message: true, customMessage,
  },
});


// export const UPDATE_ACCOUNT_DATA = 'UPDATE_ACCOUNT_DATA';
// export const UPDATE_ACCOUNT_DATA_SUCCESS = 'UPDATE_ACCOUNT_DATA_SUCCESS';
// export const UPDATE_ACCOUNT_DATA_FAILURE = 'UPDATE_ACCOUNT_DATA_FAILURE';
// export const updateAccountData = (resource, id, data, previousData, basePath,
// redirect = true) => ({
//     type: UPDATE_ACCOUNT_DATA,
//     payload: { id, data, previousData, basePath, redirect },
//     meta: { resource, fetch: UPDATE, cancelPrevious: false },
// });
