import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
    background-color: ${
        props => props.outlined
            ? 'white'
            : '#fc7954'
    };
    border: 2px solid rgb(252, 121, 84);
    border-radius: 23px;
    min-width: 120px;
    height: 46px;
    padding: 0 12px;
    outline: none;
    color: ${props => props.outlined ? '#fc7954' : 'white'};
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    transition: all 0.3s;
    &:hover {
        background: #E95B34;
        border: 2px solid  #E95B34;
        color: white;
    }
`;

function StepButton({ name, text, clickFn, outlined = false }) {
    return <Button
        name={name}
        type="button"
        onClick={clickFn}
        outlined={outlined}
    >
        <span>{text}</span>
    </Button>
}

export default StepButton;