import React, { useEffect, useState } from 'react';
import Dialog, { DialogContent, DialogTitle } from 'material-ui/Dialog';

import { makeStyles } from '@material-ui/core';
import { AiOutlineWarning, AiOutlineCheck } from 'react-icons/ai';
import { Button } from '@clippingcacd/core';
import { createReport } from '../../../../services/comments';

const styles = (theme) => ({
  container: {
    borderRadius: '8px',
    minHeight: '247px',
    '@media (max-width: 1024px)': {
      minHeight: 'auto',
      margin: 0,
      maxWidth: '95vw',
      width: '95vw',
    },
  },
  close: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    margin: '10px',
    color: theme.palette.text.clear,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  content: {
    padding: '50px',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Inter',
    alignItems: 'center',
    color: '#B0B0B0',
    '& h2': {
      color: '#444444',
    },
    '& span': {
      color: '#808080',
      marginBottom: '30px',
      fontSize: '15px',
      textAlign: 'center',
    },
    '@media (max-width: 1024px)': {
      padding: '5px',
    },
  },
  textarea: {
    resize: 'none',
    width: '100%',
    margiTop: '16px',
    border: ' 1.4px solid #B0B0B0',
    borderRadius: '8px',
    height: '88px',
    padding: '16px',
    fontFamily: 'Inter',
  },
  iconWarning: {
    backgroundColor: '#F2EEED',
    padding: '11px',
    borderRadius: '50%',
  },
  iconCheck: {
    backgroundColor: '#D7F9EC',
    padding: '11px',
    borderRadius: '50%',
    color: '#2DB87E',
  },
  buttonsDiv: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: '20px',
    '@media (max-width: 620px)': {
      flexDirection: 'column-reverse',
      width: '90%',
    },
  },
  button: {
    margin: '5px',
    height: '40px',
    width: '100%',
    borderRadius: '8px',
    padding: ' 8px 16px',
    '@media (max-width: 620px)': {
      marginTop: '8px',
    },
  },
});

const useStyles = makeStyles(styles);

export const ModalReportDialog = ({ onClose, open, commentId }) => {
  const classes = useStyles();
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(false);

  function handleOnChange(e) {
    if (text.length >= 1000) return setText(e.target.value.substring(0, 1000));

    setText(e.target.value);
  }

  async function sendReport(e) {
    try {
      setLoading(true);
      await createReport({
        comment: commentId,
        message: text,
      });
      setLoading(false);
      setFeedback(true);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setText('');
    setLoading(false);
    setFeedback(false);
  }, [open]);

  return (
    <Dialog open={open} classes={{ paper: classes.container }}>
      {!feedback ? (
        <DialogContent className={classes.content}>
          <div className={classes.iconWarning}>
            <AiOutlineWarning size={25} />
          </div>
          <h2>Denunciar abuso</h2>
          <span>
            Você considera este conteúdo impróprio? Vamos avaliar a situação.
          </span>
          <textarea
            className={classes.textarea}
            placeholder='Descreva a situação...'
            value={text}
            onChange={handleOnChange}
            disabled={loading}
          />
          <div className={classes.buttonsDiv}>
            <Button
              className={classes.button}
              size='sm'
              variant='outlined'
              onClick={onClose}
              style={{ color: 'black', outlineColor: '#B0B0B0' }}
            >
              Cancelar
            </Button>
            <Button
              className={classes.button}
              onClick={sendReport}
              loading={loading}
              disabled={loading || text.length === 0}
              size='md'
              labelLoading={'Enviando...'}
            >
              Enviar
            </Button>
          </div>
        </DialogContent>
      ) : (
        <DialogContent className={classes.content}>
          <div className={classes.iconCheck}>
            <AiOutlineCheck size={25} />
          </div>
          <h2>Recebemos seu feedback</h2>
          <span>
            Obrigado pela sua ajuda! Você receberá um email após a análise da
            nossa equipe
          </span>
          <div className={classes.buttonsDiv}>
            <Button className={classes.button} size='sm' onClick={onClose}>
              Fechar
            </Button>
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};
