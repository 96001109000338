import React from 'react';

import styled from 'styled-components'

// import { Dialog, DialogContent, withStyles } from '@material-ui/core'
import Dialog from "material-ui/Dialog";
import DialogContent from "material-ui/Dialog/DialogContent";

import { withStyles } from "material-ui/styles";
import { useSelector } from 'react-redux';

import ClassSelectorHeader from '../CourseHeader/responsive'
import ModuleItem from '../../containers/Curso/ModuleItem'

const styles = {
  root: {
    position: "relative",
    padding: 0,
    paddingTop: 0,
    display: "block",
    width: "100vw",
    height: "fit-content",
    "&:first-of-type": {
      paddingTop: 0,
    },
  },
};


function CurrentClassSelector({ classes, open }) {
  
  return (
    <Dialog id="classSelectorDialog" scroll={"paper"} open={open} fullScreen={true}>
      <DialogContent
        id="content"
        classes={{ root: classes.root }}
        dividers={false}
      >
        <ClassSelectorHeader classSelector={true}/>
        <ModuleItem classSelector={true}/>
      </DialogContent>
    </Dialog>
  );
}

export default withStyles(styles, {index: 1})(CurrentClassSelector);

