import React from "react";

import { Slide, IconButton } from "@material-ui/core";

import {
    Close as CloseIcon,
    Sort as SortIcon,
    History as ClearIcon
} from "@material-ui/icons";

import {
    ResponsiveFilterButton,
    Toolbar,
    Dialog,
    DialogActions,
    ActionButton,
    Content
} from "./styles";

function Button({ totalFilters, ...props }) {
    return  (
        <ResponsiveFilterButton {...props}>
            <SortIcon />

            {(totalFilters <= 0) ? (
                <span>Filtrar</span>
            ) : (
                <span>
                    Filtros ativos ({totalFilters})
                </span>
            )}
        </ResponsiveFilterButton>
    );
}

function Modal({ open, setOpen, children, onFilter, onClear }) {
    const Transition = React.forwardRef((props, ref) => (
        <Slide direction="up" ref={ref} {...props} />
    ));
    
    function handleOpen(open) {
        return () => setOpen(open);
    }

    function handleFilter() {
        onFilter();
        setOpen(false);
    }

    return (
        <Dialog
            open={open}
            fullScreen
            onClose={handleOpen(false)}
            TransitionComponent={Transition}>
                <Toolbar>
                    <h2>Filtros</h2>

                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleOpen(false)}
                        aria-label="close">
                            <CloseIcon />
                    </IconButton>
                </Toolbar>

                <Content>
                    {children}
                </Content>

                <DialogActions>
                    <ActionButton onClick={onClear}>
                        <ClearIcon />
                        <span>Limpar</span>
                    </ActionButton>

                    <ActionButton
                        onClick={handleFilter}
                        style={{ background: "#FC5836", color: "#FFFFFF" }}>
                            Filtrar
                    </ActionButton>
                </DialogActions>
        </Dialog>
    );
}

export default {
    Button,
    Modal
}