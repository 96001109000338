import React, { Component } from "react";
import { withStyles } from "material-ui/styles";
import Dialog from "material-ui/Dialog";
import { connect } from "react-redux";
import compose from "recompose/compose";
import CloseIcon from "material-ui-icons/Close";
import classNames from "classnames";
import { crudGetOne as crudGetOneAction } from "../../actions/dataActions";
import FeedbackPopover from "../../components/FeedbackPopover";

const styles = (theme) => ({
  header: {
    display: "flex",
    height: "80px",
    alignItems: "center",
    color: theme.typography.modalTitle,
    padding: "40px",
    justifyContent: "space-between",
  },
  screen: {
    ...theme.typography.modalTitle,
  },
  content: {
    height: "fit-content",
  },
  dialog: {
    overflow: "auto",
    alignItems: "baseline",
    paddingTop: "24px",
    paddingBottom: "24px",
  },
  container: {
    maxHeight: "none",
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
    "& img": {
      width: "100%",
    },
  },
  close: {
    width: "21px",
    height: "21px",
    color: "#999",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.orange,
    },
  },
  section: {
    padding: "16px 40px 16px 40px",
    ...theme.typography.body1,
    borderBottom: "rgba(0, 0, 0, 0.1)",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
  },
  main: {
    border: "1px solid rgba(0,0,0,0.1)",
    backgroundColor: "#F6F6F6",
  },
  weeks: {
    ...theme.typography.caption,
    fontWeight: 600,
    textTransform: "uppercase",
  },
  title: {
    ...theme.typography.headline,
  },
  footer: {
    padding: "16px 40px 16px 40px",
    ...theme.typography.body1,
    fontSize: "0.7rem",
    lineHeight: "0.85rem",
    textAlign: "center",
  },
  feedback: {
    position: "fixed",
    bottom: "0px",
  },
});

class ModalRoteiro extends Component {
  handleRequestClose = () => {
    this.props.handleOpen(false);
  };

  render() {
    const { open = {}, classes } = this.props;
    const { name, week, materia, guide, introduction, overview } = open;

    return (
      <div>
        <Dialog
          classes={{ root: classes.dialog, paper: classes.container }}
          open={Boolean(this.props.open)}
          onRequestClose={this.handleRequestClose}
        >
          <div className={classes.header}>
            <h3 className={classes.screen}>Roteiro de leitura</h3>
            <CloseIcon
              className={classes.close}
              onClick={this.handleRequestClose}
            />
          </div>

          <div className={classes.content}>
            <div className={classNames(classes.section, classes.main)}>
              <div className={classes.weeks}>
                Ciclo {week} • {materia}
              </div>
              <h2 className={classes.title}>{name}</h2>
              <div
                className={classes.introduction}
                dangerouslySetInnerHTML={{ __html: introduction }}
              ></div>
            </div>

            <div className={classes.section}>
              <h4 className={classes.sectionTitle}>
                <strong>Observações iniciais:</strong>
              </h4>
              <p dangerouslySetInnerHTML={{ __html: overview }}></p>
            </div>
            <div className={classes.section}>
              <h4 className={classes.sectionTitle}>
                <strong>Roteiro de leitura:</strong>
              </h4>
              <p dangerouslySetInnerHTML={{ __html: guide }}></p>
            </div>
          </div>
          <div className={classes.footer}>
            <FeedbackPopover />
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let data = {};
  if (ownProps.open) {
    const { resource, _id } = ownProps.open;
    data = state[resource].data[_id];
  }
  return {
    ...data,
  };
};

const mapDispatchToProps = {
  crudGetOne: (resurce, _id) => crudGetOneAction(resurce, _id, "", false, true),
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ModalRoteiro);
