import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  courseModalAction,
  classSelectorModalAction,
} from "../../containers/Cursos/actions";

import IconButton from "material-ui/IconButton";
import BackIcon from "material-ui-icons/ArrowBack";
import CloseIcon from "material-ui-icons/Close";
import { push } from "react-router-redux";
// import ClockIcon from "material-ui-icons/Timer";

const CourseHeaderContainer = styled.div`
  overflow: visible;
  z-index: 6;
  top: 0;
  position: fixed;
  width: 100vw;
  background: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
  box-shadow: 0px 1px 0px 0px rgb(232, 232, 232);
  height: 56px;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  text-align: center;
`;

const BackButton = styled.div`
  width: fit-content;
  height: fit-content;
  align-self: center;
  justify-self: flex-start;
`;

const DropdownWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  justify-self: flex-start;
  padding-right: 16px;
`;

// const RightGroup = styled.div`
//   width: fit-content;
//   height: fit-content;
//   display: flex;
//   flex: 1;
//   justify-self: flex-end;
//   justify-content: flex-end;
//   align-self: center;
// `;

const SelectedItem = styled.p`
  align-self: center;
  font-family: "Proxima Nova";
  text-align: left;
  background: transparent;
  height: fit-content;
  border: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.75);
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: uppercase;
`;

const CategoryInfo = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-family: "Proxima Nova";
  font-size: 11px;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
  text-align: left;
`

function CourseHeader({ classSelector = false }) {
  const selected = useSelector(
    (state) => state.courseGroup.currentCourse.data.name
  );
  const year = useSelector(
    (state) => state.courseGroup.currentCourse.data.ano
  );
  const category = useSelector(
    (state) => {
      console.log(state.courseGroup.currentCourse)
      return state.courseGroup.currentCourse?.data?.categoria?.nome}
  );

  const dispatch = useDispatch();

  function hideCourse() {
    dispatch(push("/cursos"));
  }

  return (
    <CourseHeaderContainer classSelector={classSelector}>
      <BackButton>
        <IconButton
          style={{ color: "rgba(0,0,0, .75)" }}
          aria-label="voltar"
          onClick={() =>
            classSelector ? dispatch(classSelectorModalAction({ open: false })) : hideCourse()
          }
        >
          {classSelector ? <CloseIcon /> : <BackIcon />}
        </IconButton>
      </BackButton>

      <DropdownWrapper>
        <SelectedItem>{selected}</SelectedItem>
        <CategoryInfo>{category} {year}</CategoryInfo>
      </DropdownWrapper>
      {/* {!classSelector &&
      <RightGroup>
        <IconButton
          href={"https://clippingcacd.com.br/timer/CACD/"}
          target={"blank"}
          style={{ color: "rgba(0,0,0, .75)" }}
          aria-label="cronometro"
        >
          <ClockIcon />
        </IconButton>
      </RightGroup>} */}
    </CourseHeaderContainer>
  );
}

export default CourseHeader;
