import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withStyles } from 'material-ui/styles';
import compose from 'recompose/compose';
import Dialog from '../../components/ModalScreen';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import IconButton from 'material-ui/IconButton';
import Button from 'material-ui/Button';
import Slide from 'material-ui/transitions/Slide';
import ArrowLeft from 'material-ui-icons/ChevronLeft'
import Tooltip from 'material-ui/Tooltip';
import TimerIcon from 'material-ui-icons/AccessTime';
import Profile from 'material-ui-icons/Person';
import CursoContent from './CursoContent';
import { crudGetOne as crudGetOneAction } from '../../actions/dataActions';
import { setModalData as setModalDataAction } from '../../actions/modalActions';
import mixpanel from '../../utils/courseEvents';
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';

const modalData = { type: 'biblioSubjectMaterial', id: 'forum' }

const styles = theme => ({
  appBar: {
    top: 0,
    left: 'auto',
    right: 0,
    backgroundColor: '#FFF',
    boxShadow: '0 1px 0 0 #E8E8E8',
    transition: theme.transitions.create('width'),
    height: '65px',
    paddingRight: '24px',
    justifyContent: 'center'
  },
  backContainer: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      padding: '18px'
    },
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  backIcon: {
    ...theme.typography.menu,
    color: theme.palette.text.clear,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '8px',
    paddingLeft: '0px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  responsiveDisplay: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inherit'
    }
  },
  content: {
    marginTop: '65px',
    flex: 1,
    marginLeft: '8px',
    marginRight: '8px'
  },
  toolbar: {
    padding: '0px'
  },
  ajuda: {
    fontWeight: 600,
    lineHeight: '14px',
    fontSize: '12px',
    color: theme.palette.text.clear,
  },
  icon: {
    color: theme.palette.text.clear,
  },
  titleContainer: {
    paddingTop: '4px',
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    ...theme.typography.headline,
    lineHeight: '1.1em',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: theme.palette.text.clear,
  },
  subtitle: {
    ...theme.typography.body1,
    textTransform: 'uppercase',
    display: 'none',
    flex: 1,
    textAlign: 'center',
    color: theme.palette.text.clear,
    lineHeight: '1rem',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '1.8rem',
    margin: '0 auto',
    fontSize: '12px'
  },
  menuIcons: {
    display: 'flex',
    color: theme.palette.text.clear,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  dialog: {
    alignItems: 'initial',
  },
  slide: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'auto',
    width: '100%'
  },
  paper: {
    overflowY: 'hidden'
  }
});

class ItemCurso extends Component {
  constructor(props) {
    super(props);

    this.state = {
      identified: false
    };
  }

  componentDidMount() {
    if (this.props.openId) {
      this.props.crudGetOne(this.props.openId)
    }
  }

  transition = (props) => {
    return <Slide direction="up" {...props} className={this.props.classes.slide} />;
  }

  componentWillUpdate(nextProps) {
    const { data } = this.props;

    if (data && data._id && !this.state.identified) {
      mixpanel.identifyUser(this.props.user, this.props.data);
      this.setState({ identified: true });
    }
  }


  componentWillReceiveProps(nextProps) {
    const { refreshState, crudGetOne, openId } = this.props;
    if (((nextProps.refreshState !== refreshState) || (openId !== nextProps.openId)) && Boolean(nextProps.openId)) {
      crudGetOne(nextProps.openId)
    }
  }

  track = () => {
    this.props.trackMetric('Clicou em ajuda', { url: location.href });
    window.open('http://ajuda.clippingcacd.com.br/', "_blank");
  }

  openTimer = () => {
    const { concurso } = this.props
    const token = localStorage.token && localStorage.token.replace('Bearer ', '')
    this.props.trackMetric('Clicou no cronômetro', { url: location.href });
    window.open(`https://clippingcacd.com.br/timer/${concurso && concurso.active.name}?token=${token}`, '_blank')
  }

  render() {
    const { openId, handleOpen, classes, data = {}, refreshState } = this.props;

    return (
      <Dialog open={Boolean(openId)} handleOpen={handleOpen} >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.backContainer}>
              <Button
                disableRipple
                disableFocusRipple
                aria-label="voltar"
                onClick={() => handleOpen(false)}
                className={classes.backIcon}
              >
                <ArrowLeft /> <div className={classes.responsiveDisplay}>voltar</div>
              </Button>
            </div>
            <div className={classes.titleContainer}>
              <div className={classes.title}>
                {data.name}
              </div>
              <div className={classes.subtitle}>
                {data.teacher}
              </div>
            </div>
            <div className={classes.menuIcons}>
              <Tooltip title="Ir para central de ajuda" enterDelay={300} className={classes.responsiveDisplay}>
                <Button color="primary" className={classes.ajuda} onClick={this.track}>
                  Ajuda
              </Button>
              </Tooltip>
              <Tooltip title="Cronômetro de estudos" enterDelay={300} className={classes.responsiveDisplay}>
                <IconButton
                  aria-label="change theme"
                  onClick={this.openTimer}
                >
                  <TimerIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Ajustes do perfil" enterDelay={300} className={classes.responsiveDisplay}>
                <IconButton
                  aria-label="change direction"
                  onClick={() => this.props.pushRouter('/perfil')}
                >
                  <Profile className={classes.icon} />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
        </AppBar>
        {data._id && <CursoContent course={data} refreshState={refreshState} location={this.props.location} />}
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.profile,
    data: state.course.data[ownProps.openId],
    concurso: state.concurso,
    refreshState: state.plataform.refreshState
  }
}

const mapDispatchToProps = {
  crudGetOne: (_id) => crudGetOneAction('course', _id, '', false, true),
  setModalData: (data) => setModalDataAction(data, modalData),
  trackMetric: (name, data) => trackMetricAction(name, data)
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ItemCurso);

