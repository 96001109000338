import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Toolbar from "material-ui/Toolbar";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory, useLocation } from "react-router-dom";

const styles = (theme) => ({
  container: {
    ...theme.typography.pagination,
    display: "flex",
    width: "70%",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "10vw",
    color: "rgba(0, 0, 0, 0.5)",
  },

  buttonNavigate: {
    "& > ul > li > button": {
      ...theme.typography.pagination,
      color: theme.palette.text.clearGrey,
      fontSize: "17px",
      fontWeight: "bold",
    },
  },
});

function UsedPagination({ total, perPage, currentPage, tab }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();

  const classes = makeStyles(styles);

  const state = useSelector((reduxState) => reduxState.tests);

  const [page, setPage] = useState(currentPage);

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  function updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf("?") !== -1 ? "&" : "?";

    if (uri.match(re)) {
      return uri.replace(re, "$1" + key + "=" + value + "$2");
    } else {
      return uri + separator + key + "=" + value;
    }
  }

  const change_page = (page_number) => {
    if (tab === "por_materia") {
      if (state.porMaterias.filter.filtered) {
        dispatch({
          type: "CHANGE_POR_MATERIAS_PAGE",
          payload: 1,
        });
      } else {
        dispatch({
          type: "CHANGE_POR_MATERIAS_FILTER_PAGE",
          payload: 1,
        });
      }

      const newQueryParams = updateQueryStringParameter(
        search,
        "page",
        page_number
      );

      history.push(`/questions${newQueryParams}`);
    }

    if (tab === "atualidades") {
      const newQueryParams = updateQueryStringParameter(
        search,
        "page",
        page_number
      );

      history.push(`/simulados${newQueryParams}`);
    }

    dispatch({
      type: "SET_LOADING",
      payload: true,
    });
  };

  const nbPages = Math.ceil(total / perPage) || 1;

  function gotoPage(event, value) {
    if (value < 1 || value > nbPages) {
      throw new Error(`A página ${page} não existe`);
    }
    if (value !== page) {
      setPage(value);
      change_page(value);
    }

    window.scrollTo(0, 0);
  }
  const matches = useMediaQuery("(max-width:720px)");

  if (matches) {
    return (
      <Toolbar style={{ justifyContent: "center" }}>
        <div className={classes.container}>
          <Pagination
            count={nbPages}
            page={page}
            selected
            onChange={gotoPage}
            className={classes.buttonNavigate}
            siblingCount={1}
            boundaryCount={1}
            size="small"
          />
        </div>
      </Toolbar>
    );
  }
  return (
    <Toolbar style={{ justifyContent: "center" }}>
      <div className={classes.container}>
        <Pagination
          count={nbPages}
          page={page ?? 1}
          selected
          onChange={gotoPage}
          className={classes.buttonNavigate}
          siblingCount={3}
          boundaryCount={1}
        />
      </div>
    </Toolbar>
  );
}
UsedPagination.propTypes = {
  props: PropTypes.element.isRequired,
  total: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  tab: PropTypes.number.isRequired,
};

export default UsedPagination;
