import React from 'react';
import { withStyles } from 'material-ui/styles';
import Dialog, {
  DialogContent,
  DialogTitle,
} from 'material-ui/Dialog';
import Close from 'material-ui-icons/Close'
import IconButton from 'material-ui/IconButton';
import { crudCreate as crudCreateAction } from '../../actions/dataActions';
import { reduxForm, Field, change } from 'redux-form';
import { renderInputText } from '../../utils/form';
import InputText from '../../components/Elements/InputText'
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Button from '../../components/Elements/Button'

const formName = 'editField'

const styles = theme => ({
    container: {
      borderRadius: '4px',
      [theme.breakpoints.up('md')]: {
        minWidth: '700px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: '16px'
      }
    },
    title: {
        color: theme.palette.orange,
        borderBottom: '1px solid #96969626',
        fontSize: '18px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: '600',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '80px',
        paddingLeft: '38px',
        paddingRight: '24px'
    },
    close: {
        color: theme.palette.text.clear,
        cursor: 'pointer',
        marginTop: '-15px',
        width: '20px',
        '&:hover': {
            color: theme.palette.orange,
        },
    },
    content: {
        padding: '38px',
    },
    input: {
      maxWidth: '350px',
      paddingBottom: '16px',
      paddingTop: '16px'
    }
})

class ModalEditField extends React.Component {

  handleClose = () => {
    this.props.handleOpen(false)
  };

  handleProfileUpdate = (post) => {
    const {profileUpdate, reset, profileId} = this.props
    const data =  {
        email: post['e-mail'],
        name: post['name']
    }
    profileUpdate(profileId, data, 'Perfil atualizado com sucesso!')
    this.handleClose()
    reset()
  }

  componentWillReceiveProps({open, reset}) {
      if(this.props.open && !open) {
        reset()
      }
  }
  

  render(test1, test2) {
    const {classes, handleSubmit, open = {}, dirty, valid} = this.props
    const {name = '', value = '', field = '', placeholder = ''} = open
    const nameUpper = name.toUpperCase()

    return (
        <Dialog
            open={Boolean(name)}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
            classes={{paper: classes.container}}
        >
            <DialogTitle disableTypography className={classes.title}>
                Alterar {name || ''}
                <div className={classes.close} >
                    
                </div>
                <IconButton
                    className={classes.close}
                    aria-label="Fechar"
                    onClick={this.handleClose} >
                        <Close/> 
                    </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(this.handleProfileUpdate)}>
            <DialogContent className={classes.content}>
              <InputText customClasses={{root: classes.input}} label={`${name  || ''} atual`} input={{value: value, placeholder}} modalEdit disable />
              <Field customClasses={{root: classes.input}} autoFocus label={`NOVO ${nameUpper}`}
                name={field} autocomplete={field} id={field} placeholder={placeholder}
                component={renderInputText} />
              <Button disabled={!dirty || (dirty && !valid)} type="submit" customClasses={{root: classes.buttonSubmit}}>ALTERAR {nameUpper}</Button>  
            </DialogContent>
            </form>
        </Dialog>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
    return  {
        submitting: state.waiting
    }
}
  
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        changeForm: (field, value) => {
            dispatch(change(formName, field, value))
        },
        crudCreate : (...params) => {
            dispatch(crudCreateAction(...params))
        }
    }
}

const enhance = compose(
    withStyles(styles),
    reduxForm({
        form: formName,
        validate: (values, {open = {}, validation}) => {
            const errors = {};
            if (!values[open.field]) {
                errors[open.field] = 'Campo inválido';
            } else if(open.validation){
                errors[open.field] = open.validation(values[open.field]) ? 'E-mail inválido' : undefined
            }
            return errors;
        }
    }),
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(ModalEditField)
