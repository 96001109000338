import React from "react";
import { withStyles } from "material-ui/styles";
import { connect } from "react-redux";
import compose from "recompose/compose";
import moment from "moment";
import { Button, IconButton } from "../../components/common";
import classNames from "classnames";
import TimerIcon from "material-ui-icons/ArrowForward";
import BookmarkIcon from "material-ui-icons/BookmarkBorder";
import BookmarkIconFull from "material-ui-icons/Bookmark";
import Save from "material-ui-icons/Save";
import sanitizeHtml from "sanitize-html";
import FeedbackPopover from "../../components/FeedbackPopover";
import _ from "lodash";
import { Us, Br, Fr, Es } from "react-flags-select";

const styles = (theme) => ({
  wrapper: {
    flex: 1,
    display: "flex",
    background: "#fff",
    padding: "16px",
    paddingTop: "100px",
    left: "calc(50% - 360px)",
    position: "absolute",
    [theme.breakpoints.up("md")]: {
      padding: "32px",
      paddingTop: "124px",
    },
    "@media (max-width: 1600px)": {
      position: "static",
      marginLeft: "305px",
    },
    "@media (max-width: 960px)": {
      marginLeft: "0px",
      display: "block",
    },
  },
  content: {
    ...theme.typography.clipping,
    width: "100%",
    "& img": {
      marginBottom: "16px",
      maxWidth: "100%",
    },
    "& p": {
      clear: "both",
    },
  },
  container: {
    display: "none",
    height: "max-content",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    "@media (max-width: 1600px)": {
      margin: "0 auto",
      maxWidth: "900px",
      paddingRight: "166px",
    },
    "@media (max-width: 960px)": {
      paddingRight: "0",
    },
    paddingBottom: "80px",
    maxWidth: "720px",
    width: "100%",
  },
  mobileRender: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  sourceData: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "24px",
  },
  sourcePhoto: {
    background: "#999999",
    color: "#fff",
    borderRadius: "50%",
    height: "40px",
    width: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "8px",
    fontSize: "18px",
  },
  source: {
    ...theme.typography.body3,
    color: theme.palette.text.primary,
  },
  date: {
    ...theme.typography.articleDate,
  },
  titleContainer: {
    ...theme.typography.articleTitle,
    paddingTop: "24px",
    paddingBottom: "24px",
    color: theme.palette.text.primary,
    maxWidth: "90vw",
    [theme.breakpoints.up("md")]: {
      maxWidth: "70vw",
    },
  },
  section: {
    backgroundColor: theme.palette.clearOrange,
    width: "fit-content",
    padding: "8px 16px 8px 16px",
    minWidth: "190px",
    color: "#fff",
    fontSize: "13px",
    fontWeight: "600",
    marginLeft: "-15px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "-25px",
    },
    "&:before": {
      content: '""',
      borderTop: "15px solid transparent",
      borderLeft: `12px solid #FFF`,
      marginRight: "10px",
      marginLeft: "-16px",
      borderBottom: "15px solid transparent",
      marginTop: "-8px",
      float: "left",
    },
    "&:after": {
      content: '""',
      borderTop: "15px solid transparent",
      borderLeft: `12px solid ${theme.palette.clearOrange}`,
      marginRight: "-28px",
      borderBottom: "15px solid transparent",
      marginTop: "-8px",
      float: "right",
    },
  },
  continue: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px",
    paddingBottom: "80px",
    ...theme.typography.body2,
    color: theme.palette.text.clear,
  },
  timerIcon: {
    marginLeft: "8px",
    height: "12px",
    width: "12px",
  },
  buttonTools: {
    left: "calc(50% + 392px)",
    top: "45%",
    position: "fixed",
    display: "none",
    flexDirection: "column",
    width: "234px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    "@media (max-width: 1600px)": {
      right: "14px",
      width: "auto",
    },
  },
  bookmark: {
    color: theme.palette.text.clear,
    borderColor: theme.palette.text.clearGrey,
    marginBottom: "8px",
    "&$active": {
      color: theme.palette.text.basic,
    },
  },
  icon: {
    color: theme.palette.text.clear,
    borderColor: theme.palette.text.clearGrey,
    marginBottom: "8px",
    "&$active": {
      color: theme.palette.orange,
    },
    "&$disable": {
      color: theme.palette.text.clearGrey,
    },
  },
  active: {},
  disable: {},
  fichamentoActive: {
    animation: "pulse 1.5s",
    animationIterationCount: 3,
    boxShadow: `0 0 0 0 ${theme.palette.orange}`,
  },
  buttonWrapper: {
    color: theme.palette.text.clear,
    fontSize: "12px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: theme.typography.fontFamily,
    textAlign: "center",
    marginBottom: "19px",
    "&:hover $icon": {
      color: theme.palette.orange,
    },
    "&:hover $bookmark": {
      color: theme.palette.basic,
    },
    "&:hover $disable": {
      color: theme.palette.text.clearGrey,
    },
  },
  tooltip: {
    top: "-40px !important",
  },
  url: {
    ...theme.typography.urlBase,
    textDecoration: "none",
  },
  author: {
    ...theme.typography.observation,
  },
  fichadorIcon: {
    fontSize: "20px",
  },
});

class ClippingContent extends React.PureComponent {
  state = { fichamentoPendente: 0, language: 'pt_content'}

  componentDidMount() {
    const { concurso } = this.props;

    if (!["TRT"].includes(concurso.name)) {
      const options = {
        onAfterHighlight: () => {
          const items = document.getElementsByClassName("highlighted");
          this.setState({ fichamentoPendente: items.length });
          Array.from(items).forEach((element) => {
            element.addEventListener("click", (e) => {
              this.hltr.removeHighlights(e.target);
              const items = document.getElementsByClassName("highlighted");
              this.setState({ fichamentoPendente: items.length });
            });
          });
        },
      };
      this.hltr = new window.TextHighlighter(
        document.getElementById("clippingArticleContent"),
        options
      );
      window.hltr = this.hltr;
      this.props.configGetFichamentos(this.getFichamentos);
    }
  }

  getFichamentos = () => {
    const params = {
      andSelf: true,
      grouped: false,
    };
    return this.formatHighlightsChunks(
      this.hltr.getHighlights && this.hltr.getHighlights(params)
    );
  };

  closest = (child) => {
    let parent = child.parentNode;
    while (parent) {
      if (parent.matches && (parent.matches("p") || parent.matches("li"))) {
        return parent;
      }
      parent = parent.parentNode;
    }
    return null;
  };

  clearOldIndexes = (index) => {
    let similarParents = document.querySelectorAll(
      `[data-dict-index="${index}"]`
    );
    similarParents.forEach((item) => {
      delete item.dataset.dictIndex;
    });
  };

  formatHighlightsChunks = (chunks = []) => {
    let dict = {};
    let dictIndex = 0;
    chunks.forEach((chunk) => {
      let parent = this.closest(chunk);
      if (!parent) return;

      if (!parent.dataset.dictIndex) {
        this.clearOldIndexes(dictIndex);
        parent.dataset.dictIndex = dictIndex;
        dictIndex++;
      } else if (parent.dataset.dictIndex >= dictIndex) {
        dictIndex = +parent.dataset.dictIndex + 1;
      }

      let dictPosition = dict[`parent-${parent.dataset.dictIndex}`] || [];
      dict[`parent-${parent.dataset.dictIndex}`] = [...dictPosition, chunk];
    });

    let formattedHighlights = "";
    const orderDictsKeys = Object.keys(dict)
      .map((dictKey) => {
        const [, order] = dictKey.split("-");

        return Number(order);
      })
      .sort((a, b) => a - b);

    orderDictsKeys.forEach((key) => {
      if (dict[`parent-${key}`]) {
        let line = "";
        dict[`parent-${key}`].forEach(
          (item) => (line += ` ${item.textContent}`)
        );
        formattedHighlights += `<p>${line}</p>\n`;
      }
    });

    return formattedHighlights;
  };

  render() {
    const {
      pubDate,
      classes,
      content,
      en_content,
      pt_content,
      es_content,
      fr_content,
      clippingNote,
      questionItem,
      author,
      date,
      section,
      title,
      totalArticles,
      idNextArticle,
      number,
      openNext,
      bookmark,
      handleBookmark,
      _id,
      handleModalFichamento,
      source,
      url,
      type,
      concurso,
    } = this.props;
    const { fichamentoPendente, language } = this.state;
    const noticesToEnd = totalArticles - number;
    const articleDate = moment(pubDate, "YYYY-MM-DD");
    const allowedTags = [
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "blockquote",
      "p",
      "a",
      "ul",
      "ol",
      "nl",
      "li",
      "b",
      "i",
      "img",
      "strong",
      "em",
      "ins",
      "strike",
      "code",
      "hr",
      "br",
      "div",
      "table",
      "thead",
      "caption",
      "tbody",
      "tr",
      "th",
      "td",
      "pre",
      "iframe",
    ];

    if (type === "biblio") {
      allowedTags.push("img");
    }

    return (
      <div className={classes.wrapper} id="clippingContent">
        <div className={classes.container} id="clippingArticleContent">
          {!["biblio"].includes(type) && (
            <div className={classes.sourceData}>
              <div className={classes.sourcePhoto}>
                {(source && source.slice(0, 1)) || "C"}
              </div>
              <div className={classes.clippingData}>
                <div className={classes.source}> {source}</div>
                <div className={classes.date}>
                  {" "}
                  {articleDate.format("DD/MM/YYYY")} article date
                </div>
              </div>
            </div>
          )}
          <div className={classes.titleContainer}>
            <div className={classes.title}>{title}</div>
            <div className={classes.author}>
              {author ? `Por: ${author} - ` : ""}{" "}
              {url ? (
                <a href={url} target="_blank" className={classes.url}>
                  Leia na íntegra
                </a>
              ) : (
                ""
              )}
                <IconButton 
                  customClasses={{ button: classNames(classes.bookmark) }} 
                  onClick={() => this.setState({ language: 'en_content' })} 
                  tooltipTitle="Traduzir pra Inglês"
                >
                  <Us /> {/* 🇺🇸 */}
                </IconButton>
              <IconButton 
                customClasses={{ button: classNames(classes.bookmark) }} 
                onClick={() => this.setState({ language: 'fr_content' })} 
                tooltipTitle="Traduzir para Francês"
              >
                <Fr /> {/* 🇫🇷 */}
              </IconButton>
              <IconButton 
                customClasses={{ button: classNames(classes.bookmark) }} 
                onClick={() => this.setState({ language: 'es_content' })} 
                tooltipTitle="Traduzir para Espanhol"
              >
                <Es /> {/* 🇪🇸 */}
              </IconButton>
              <IconButton 
                customClasses={{ button: classNames(classes.bookmark) }} 
                onClick={() => this.setState({ language: 'pt_content' })} 
                tooltipTitle="Traduzir para Português"
              >
                <Br /> {/* 🇧🇷 */}
              </IconButton> 
              Traduções automáticas por IA
            </div>
          </div>
          <div>
            {Boolean(section) && (
              <div className={classes.section}>{section}</div>
            )}
          </div>
          {Boolean(content) && (
            <div
              className={classes.content}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(eval(language) || content, {
                  allowedTags,
                  allowedAttributes: {
                    a: ["href", "name", "target"],
                    img: ["src", "width", "height", "style"],
                    iframe: [
                      "width",
                      "height",
                      "scrolling",
                      "frameborder",
                      "allow",
                      "src",
                      "url",
                      "color",
                      "auto_play",
                      "hide_related",
                      "show_comments",
                      "show_user",
                      "show_reposts",
                      "show_teaser",
                    ],
                  },
                }),
              }}
            />
          )}
          {Boolean(clippingNote) && (
            <div
              className={classes.content}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(clippingNote, {
                  allowedTags,
                  allowedAttributes: {
                    a: ["href", "name", "target"],
                    img: ["src", "width", "height", "style"],
                    iframe: [
                      "width",
                      "height",
                      "scrolling",
                      "frameborder",
                      "allow",
                      "src",
                      "url",
                      "color",
                      "auto_play",
                      "hide_related",
                      "show_comments",
                      "show_user",
                      "show_reposts",
                      "show_teaser",
                    ],
                  },
                }),
              }}
            />
          )}
          {Boolean(questionItem) && (
            <div>
              <p><strong>🎯 CAIU NA PROVA!</strong> - Quer ver como esse assunto já caiu na prova ou pode ser cobrado na prova? Dê ma olhada nesta <a href={`/questions?questionItemId=${questionItem}`} target="_blank" className={classes.url}>questão</a>!</p>
            </div>
          )}
          <div className={classes.continue}>
            {noticesToEnd > 0 && !["biblio"].includes(type)
              ? `Esse clipping tem mais ${noticesToEnd} notícia${
                  noticesToEnd > 1 ? "s" : ""
                }`
              : ""}
            {noticesToEnd > 0 ? (
              <Button onClick={() => openNext(idNextArticle, "click")}>
                CONTINUAR LEITURA <TimerIcon className={classes.timerIcon} />
              </Button>
            ) : null}
          </div>

          {concurso.name !== "TRT" && (
            <div className={classes.buttonTools}>
              <div
                className={classNames(
                  classes.buttonWrapper,
                  bookmark ? classes.active : ""
                )}
              >
                <IconButton
                  customClasses={{
                    button: classNames(
                      classes.bookmark,
                      bookmark ? classes.active : ""
                    ),
                  }}
                  onClick={() =>
                    handleBookmark(
                      { type, activation: true, resourceId: _id, title },
                      !Boolean(bookmark)
                    )
                  }
                >
                  {bookmark ? <BookmarkIconFull /> : <BookmarkIcon />}
                </IconButton>
                Ler depois
              </div>

              <div
                className={classNames(
                  classes.buttonWrapper,
                  fichamentoPendente > 0 ? classes.active : classes.disable
                )}
              >
                <IconButton
                  customClasses={{
                    button: classNames(
                      classes.icon,
                      fichamentoPendente > 0
                        ? `${classes.fichamentoActive}`
                        : classes.disable
                    ),
                  }}
                  onClick={() => handleModalFichamento(true)}
                >
                  <Save />
                </IconButton>
                Salvar Fichamento
              </div>
            </div>
          )}

          <FeedbackPopover />
        </div>

        {/*BUG SCROLL SHIT FIX*/}

        <div className={classNames(classes.container, classes.mobileRender)}>
          {!["biblio"].includes(type) && (
            <div className={classes.sourceData}>
              <div className={classes.sourcePhoto}>
                {(source && source.slice(0, 1)) || "C"}
              </div>
              <div className={classes.clippingData}>
                <div className={classes.source}> {source}</div>
                <div className={classes.date}> {articleDate.format("DD/MM/YYYY")}</div>
              </div>
            </div>
          )}
          <div className={classes.titleContainer}>
            <div className={classes.title}>{title}</div>
            <div className={classes.author}>
              {author ? `Por: ${author} - ` : ""}{" "}
              {url ? (
                <a href={url} target="_blank" className={classes.url}>
                  Leia na íntegra
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          <div>
            {Boolean(section) && (
              <div className={classes.section}> {section}</div>
            )}
          </div>
          {Boolean(content) && (
            <div
              className={classes.content}
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(content) }}
            />
          )}
          <div className={classes.continue}>
            {noticesToEnd > 0 && !["biblio"].includes(type)
              ? `Esse clipping tem mais ${noticesToEnd} notícia${
                  noticesToEnd > 1 ? "s" : ""
                }`
              : ""}
            {noticesToEnd > 0 ? (
              <Button onClick={() => openNext(idNextArticle, "click")}>
                CONTINUAR LEITURA <TimerIcon className={classes.timerIcon} />
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  concurso: state.concurso.active,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, null)
)(ClippingContent);
