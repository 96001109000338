import React from 'react';
// import { withStyles } from 'material-ui/styles';

// const checkedColor = '#34b93d'
// const uncheckedColor = '#808595'
// const checkboxHeight = '20px'
// const backgroundColor = '#ffffff'
// const fontColor = '#efefef'
// const duration = '.4s'


// const styles = theme => ({
//   '@keyframes dothabottomcheck': {
//     from: { height: 0 },
//     to: {height: checkboxHeight/2 }
//   },
//   '@keyframes dothatopcheck' : {
//     '0%': { height: 0 },
//     '50%': { height: 0 },
//     '100%': { height: checkboxHeight * 1.2 }
//   },
//   animatedCheckbox: {
//     backgroundColor: backgroundColor,
//     color: fontColor,
//     fontFamily:'Open Sans',
//     '&input[type=checkbox]': {
//         display: 'none'
//     }
//   },
// }) 

const Checkbox = ({id, onChange = () => {}, checked, label = {}, classes}) => {
  return (
    <div className="animated-checkbox">
      <input type="checkbox" id={id} onChange={onChange} 
        checked={checked}/><label {...label} htmlFor={id} className="check-box" ></label>
    </div>
  );
};

export default Checkbox

// export default withStyles(styles)(Checkbox)