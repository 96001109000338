import { USER_FETCH, USER_FETCH_SUCCESS, USER_FETCH_FAILURE } from './constants'

export function userFetchRequest() {
  return {
    type: USER_FETCH,
  };
}

export function userFetchSuccess({ payload }) {
  return {
    type: USER_FETCH_SUCCESS,
    payload: { ...payload },
  };
}

export function userFetchFailure() {
  return {
    type: USER_FETCH_FAILURE,
  };
}