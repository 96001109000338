import React, { Component } from 'react';
import { connect } from 'react-redux';
import { crudCreate as crudCreateAction } from '../../actions/dataActions';

class VideoPlayer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            from: 0,
            to: 0,
            last: '',
            lastTime: 0
        }
    }

    setToTwoDigits = (value) => {
        value = value.toString();
        if (value.length === 0) {
            return '00';
        }
        if (value.length === 1) {
            return '0' + value;
        }
        return value;
    }

    toTimeString = (time) => {
        let seconds = this.setToTwoDigits(Math.trunc(time % 60));
        let minutes = this.setToTwoDigits(Math.trunc(time / 60 % 60));
        let hour = this.setToTwoDigits(Math.trunc(time / 60 / 60 % 60));


        return `${hour}:${minutes}:${seconds}`;
    }

    componentDidMount() {
        const { course, classData, crudCreate } = this.props;

        const iframe = this.refs.iframe;
        let player = new window.Vimeo.Player(iframe);

        player.on('play', (data) => {
            this.setState({ from: Math.trunc(data.seconds) });
            this.setState({ last: 'play' });
        });
        player.on('pause', (data) => {
            const { from } = this.state;
            this.setState({ to: data.seconds });
            let seconds = Math.trunc(data.seconds);

            if (seconds > from) {
                crudCreate('event', { name: 'Assistiu à gravação', data: { 'De': this.toTimeString(from), 'Até': this.toTimeString(seconds), 'Total': this.toTimeString(seconds - from), 'Curso': course.name, 'Nome da aula': classData.title, 'Número da aula': classData.number, course } }, '', false, '', false);
            }
            this.setState({ last: 'pause' });
        });
        player.on('timeupdate', (data) => {
            const { lastTime, from } = this.state;
            let seconds = Math.trunc(data.seconds);

            if (lastTime !== seconds) {
                this.setState({ lastTime: seconds })

                if (Math.abs(seconds - lastTime) > 1) {

                    if (lastTime > from) {
                        crudCreate('event', { name: 'Assistiu à gravação', data: { 'De': this.toTimeString(from), 'Até': this.toTimeString(lastTime), 'Total': this.toTimeString(lastTime - from), 'Curso': course.name, 'Nome da aula': classData.title, 'Número da aula': classData.number, course } }, '', false, '', false);
                        this.setState({ from: seconds });
                    }
                }
            }
        });
    }

    render() {
        const { open = {}, handleLoaded } = this.props

        return (
            <iframe ref="iframe" onLoad={() => handleLoaded()}
                src={`https://player.vimeo.com/video/${open.video}`} style={{ border: 0 }}
                width="100%" height="533.6px" frameBorder="0" allowFullScreen>
            </iframe>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        crudCreate: (...params) => {
            dispatch(crudCreateAction(...params))
        }
    }
}

export default connect(null, mapDispatchToProps)(VideoPlayer);