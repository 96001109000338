import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Radio, RadioGroup } from '@material-ui/core';

import {
  FiltersContainer,
  DefaultFilters,
  AdditionalFilters,
  SelectWrapper,
  ExtraOptionsContainer,
  CustomFormControlLabel,
  HideContainer,
  ResponsiveFilterList,
  ActiveFiltersList,
} from './styles';

import api from '../../services/api';
import KeywordFilterInput from './ActiveFilters/KeywordFilter';
import KeywordsActiveFilters from './ActiveFilters/KeywordFilter/KeywordsActiveFilters';
import ActiveFilters, { ActiveFiltersItem } from './ActiveFilters';
import normalizeKeywords from './ActiveFilters/./KeywordFilter/normalize';
import SelectWithSearch from './CustomSelect/SelectWithSearch';
import ResponsiveFilter from './ResponsiveFilter';

const extraOptions = [
  {
    value: 1,
    label: 'Todas',
  },
  {
    value: 2,
    label: 'Resolvidas',
  },
  {
    value: 3,
    label: 'Não Resolvidas',
  },
  {
    value: 4,
    label: 'Acertei',
  },
  {
    value: 5,
    label: 'Errei',
  },
  {
    value: 6,
    label: 'Comentadas por mim',
  },
];

function Filters({ loading, separateKeywordsByComma }) {
  const history = useHistory();
  const { search } = useLocation();

  const concursoId = useSelector(
    (reduxState) => reduxState.concurso.active.concursoId
  );
  const idConcursoRedux = useSelector(
    (reduxState) => reduxState.concurso.active._id
  );
  const concursoIdsData = useSelector(
    (reduxState) => reduxState.concurso.active.test
  );

  const [open, setOpen] = useState(false);
  const [extraSelected, setExtraSelected] = useState(1);

  const [subject, setSubject] = useState(null);
  const [cicle, setCicle] = useState(null);
  const [year, setYear] = useState(null);
  const [board, setBoard] = useState(null);
  const [optionsCicles, setOptionsCicles] = useState([]);
  const [keywords, setKeywords] = useState('');
  const [hideFilters, setHideFilters] = useState(false);

  const [filtersOptions, setFiltersOptions] = useState({
    boards: [],
    subjects: [],
    years: [],
  });

  const lastFilter = useRef({
    extraSelected,
    subject,
    cicle,
    year,
    board,
    optionsCicles,
    keywords,
  });

  function hasActiveFilter() {
    return (
      subject?.length > 0 ||
      keywords?.length > 0 ||
      cicle?.length > 0 ||
      year?.length > 0 ||
      board?.length > 0
    );
  }

  /** @TODO Refatorar filtros exclusivos */
  function customizeFiltersBoards({ filters, concursoId }) {
    if (concursoId === 1) {
      const allowedFilters = [
        '5c2e2f2ed415e2ec2f417aec', // Exclusivas Clipping
        '5f284e9804546e0ec7ea3c32', // IADES/TPS
        '5c2e2f39d415e2ec2f417aee', // CESPE/TPS
      ];

      return filters.filter((item) => allowedFilters.includes(item._id));
    }

    return filters;
  }

  function customizeFiltersSubjects({ filters, concursoId }) {
    if (concursoId === 1) {
      const allowedFilters = [
        '5a969bfea4102cefcc1e3d38', // Lingua Francesa
        '5a969c16a4102cefcc1e3d3a', // Lingua Espanhola
      ];

      return filters.filter((item) => !allowedFilters.includes(item._id));
    }

    return filters;
  }

  async function getFilters() {
    try {
      const subjectIds = new URLSearchParams(search).get('subjectIds');
      const years = new URLSearchParams(search).get('years');
      const boardIds = new URLSearchParams(search).get('boardIds');
      const resolvedAnswer = new URLSearchParams(search).get('resolvedAnswer');
      const correctAnswer = new URLSearchParams(search).get('correctAnswer');
      const commentedByMe = new URLSearchParams(search).get('commentedByMe');
      if (commentedByMe === 'true') {
        setExtraSelected(6);
      }
      if (resolvedAnswer === 'true') {
        setExtraSelected(2);
      } else {
        if (resolvedAnswer === 'false') {
          setExtraSelected(3);
        }
      }
      if (correctAnswer === 'true') {
        setExtraSelected(4);
      } else {
        if (correctAnswer === 'false') {
          setExtraSelected(5);
        }
      }

      const { data } = await api.get(
        `/questionItem/questionsFilters?${concursoIdsData.reduce(
          (acum, current) => {
            return (acum = `concursoId=${current}&` + acum);
          },
          ''
        )}`
      );

      if (!data) {
        return setFiltersOptions({ boards: [], subjects: [], years: [] });
      }

      data.boards = customizeFiltersBoards({
        filters: data.boards,
        concursoId,
      });

      data.boards = data.boards.map((obj) => {
        const newObject = {};
        delete Object.assign(newObject, { ['value']: obj['_id'] })['_id'];
        delete Object.assign(newObject, { ['label']: obj['name'] })['name'];

        return newObject;
      });

      if (boardIds) {
        const arrayBoardsIds = boardIds.replace(/"|\[|\]/g, '').split(',');
        const boardsUrl = arrayBoardsIds.map((value) => {
          let board;
          data.boards.map((v) => {
            if (v.value === value) {
              board = v;
            }
          });
          return board;
        });

        setBoard(boardsUrl);
      }

      data.subjects = customizeFiltersSubjects({
        filters: data.subjects,
        concursoId,
      });

      data.subjects = data.subjects.map((obj) => {
        const newObject = {};
        delete Object.assign(newObject, { ['value']: obj['_id'] })['_id'];
        delete Object.assign(newObject, { ['label']: obj['name'] })['name'];

        return newObject;
      });

      if (subjectIds) {
        const arraySubjectIds = subjectIds.split(',').map(String);

        const subjectsUrl = arraySubjectIds.map((value) => {
          let subject;
          data.subjects.map((v) => {
            if (v.value === value) {
              subject = v;
            }
          });
          return subject;
        });

        setSubject(subjectsUrl);
      }

      data.years = data.years
        .filter((year) => year !== null)
        .map((year) => ({ value: year, label: year }));

      if (years) {
        const arrayYears = years
          .replace(/"|\[|\]/g, '')
          .split(',')
          .map(Number);

        const yearsUrl = arrayYears.map((value) => {
          let year;
          data.years.map((v) => {
            if (v.value === value) {
              year = v;
            }
          });
          return year;
        });

        setYear(yearsUrl);
      }

      return setFiltersOptions(data);
    } catch (error) {
      return setFiltersOptions({ boards: [], subjects: [], years: [] });
    }
  }

  async function getCiclos() {
    const ciclesIds = new URLSearchParams(search).get('cicles');

    let URL_PARAMS = ``;
    if (subject && subject.length > 0 && cicle && cicle.length > 0) {
      let cicles = [];
      subject.map((v) => {
        cicle.filter((cicle) => {
          if (cicle.group === v.value) {
            cicles.push(cicle);
          }
        });
      });
      setCicle(cicles);
    }
    if (subject && subject.length > 0) {
      const subjectIds = subject.filter((v) => v.value !== '*');
      URL_PARAMS += `&subjectIds=${subjectIds
        .map((_subject) => _subject.value)
        .join(',')}`;
    } else {
      setOptionsCicles([]);
      setCicle(null);
    }
    try {
      const { data } = await api.get(
        `/questionItem/questionsFilters?${concursoIdsData.reduce(
          (acum, current) => {
            return (acum = `concursoId=${current}&` + acum);
          },
          ''
        )}${URL_PARAMS}`
      );

      if (!data.cicles) {
        return setOptionsCicles([]);
      }
      let ciclesNormal = [];
      let groupCicle = [];
      Object.keys(data.cicles).map((title) => {
        data.cicles[title] = data.cicles[title].map((cicle) => {
          const newObject = {};
          delete Object.assign(newObject, { ['value']: cicle['_id'] })['_id'];
          delete Object.assign(newObject, { ['label']: cicle['title'] })[
            'title'
          ];
          delete Object.assign(newObject, { ['group']: cicle['subjectId'] })[
            'subjectId'
          ];
          delete Object.assign(newObject, {
            ['index']: data.cicles[title].indexOf(cicle) + 1,
          });
          ciclesNormal.push(newObject);
        });
        groupCicle.push({ label: title, options: ciclesNormal });
        ciclesNormal = [];
      });

      if (ciclesIds) {
        const arrayCiclesIds = ciclesIds.split(',').map(String);

        const ciclesUrl = arrayCiclesIds.map((value) => {
          let cicle;
          groupCicle.map((v) => {
            v.options.map((cicles) => {
              if (cicles.value === value) {
                cicle = cicles;
              }
            });
          });
          return cicle;
        });

        if (ciclesUrl[0] !== undefined) {
          setCicle(ciclesUrl);
        }
      }

      return setOptionsCicles(groupCicle);
    } catch (error) {
      return setOptionsCicles([]);
    }
  }

  async function handleFilter() {
    lastFilter.current = {
      extraSelected,
      subject,
      cicle,
      year,
      board,
      optionsCicles,
      keywords,
    };

    let URL_PARAMS = `page=1`;

    if (keywords && keywords.length > 0) {
      if (separateKeywordsByComma)
        URL_PARAMS += `&keywords=${normalizeKeywords(keywords)}&separate`;
      else URL_PARAMS += `&keywords=${keywords}`;
    }

    if (subject && subject.length > 0 && subject[0].value !== '*') {
      URL_PARAMS += `&subjectIds=${subject
        .map((_subject) => _subject.value)
        .join(',')}`;
    }
    if (year && year.length > 0 && year[0].value !== '*') {
      URL_PARAMS += `&years=[${year
        .map((_year) => `"${_year.value}"`)
        .join(',')}]`;
    }
    if (board && board.length > 0 && board[0].value !== '*') {
      URL_PARAMS += `&boardIds=[${board
        .map((_board) => `"${_board.value}"`)
        .join(',')}]`;
    }
    if (cicle && cicle.length > 0 && cicle[0].value !== '*') {
      URL_PARAMS += `&cicles=${cicle
        .map((_subject) => _subject.value)
        .join(',')}`;
    }
    if (extraSelected && extraSelected !== 1) {
      if (extraSelected === 2) {
        URL_PARAMS += `&resolvedAnswer=${true}`;
      }
      if (extraSelected === 3) {
        URL_PARAMS += `&resolvedAnswer=${false}`;
      }
      if (extraSelected === 4) {
        URL_PARAMS += `&correctAnswer=${true}`;
      }
      if (extraSelected === 5) {
        URL_PARAMS += `&correctAnswer=${false}`;
      }
      if (extraSelected === 6) {
        URL_PARAMS += `&commentedByMe=${true}`;
      }
    }

    history.push(`/questions?${URL_PARAMS}`);
  }

  function handleClearFilters() {
    setKeywords('');
    setSubject([]);
    setCicle([]);
    setYear([]);
    setBoard([]);
    setExtraSelected(1);
  }

  function getTotalActivedFilters() {
    return (
      normalizeKeywords(keywords)
        .split(',')
        .filter((x) => x !== '').length +
      (subject?.length || 0) +
      (cicle?.length || 0) +
      (year?.length || 0) +
      (board?.length || 0)
    );
  }

  function filterDiff() {
    const current = {
      extraSelected,
      subject,
      cicle,
      year,
      board,
      optionsCicles,
      keywords,
    };

    return JSON.stringify(lastFilter.current) !== JSON.stringify(current);
  }

  useEffect(() => {
    async function init() {
      const idConcurso = localStorage.getItem('concurso');
      if (idConcurso === idConcursoRedux) {
        await getFilters();
      } else {
        await getFilters();
      }
    }

    init();
  }, [concursoId]);

  useEffect(() => {
    async function init() {
      await getCiclos();
    }
    init();
  }, [subject]);

  useEffect(() => {
    const initKeywords = new URLSearchParams(window.location.search).get(
      'keywords'
    );

    if (initKeywords) setKeywords(initKeywords);
  }, [window.location.search]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FiltersContainer>
        <HideContainer hide={hideFilters && hasActiveFilter()}>
          <KeywordFilterInput
            value={keywords}
            onChange={setKeywords}
            onSearch={handleFilter}
            style={{ width: '60%', paddingRight: '16px' }}
          />

          <DefaultFilters>
            <SelectWrapper>
              <SelectWithSearch
                label='Matéria'
                style={{ width: '30%' }}
                options={filtersOptions.subjects}
                value={subject}
                setOption={setSubject}
              />

              <SelectWithSearch
                label='Ciclo de estudo'
                style={{ width: '30%' }}
                options={optionsCicles}
                value={cicle}
                setOption={setCicle}
                disabled={!subject || subject.length <= 0}
              />

              <SelectWithSearch
                label='Ano'
                style={{ width: '20%' }}
                options={filtersOptions.years}
                value={year}
                setOption={setYear}
              />
              <SelectWithSearch
                label='Banca'
                style={{ width: '20%' }}
                options={filtersOptions.boards}
                value={board}
                setOption={setBoard}
              />
            </SelectWrapper>
          </DefaultFilters>

          <AdditionalFilters>
            <span>Exibir</span>
            <ExtraOptionsContainer>
              <RadioGroup
                name='question-type'
                value={extraSelected}
                onChange={(_, value) => setExtraSelected(Number(value))}
                row
                style={{ margin: '8px 0px 0px 8px' }}
              >
                {extraOptions.map(({ value, label }) => (
                  <CustomFormControlLabel
                    value={value}
                    control={<Radio color='default' />}
                    label={label}
                    selected={value === extraSelected}
                  />
                ))}
              </RadioGroup>
            </ExtraOptionsContainer>
          </AdditionalFilters>
        </HideContainer>

        <ActiveFilters
          actived={hasActiveFilter() || extraSelected > 1 || filterDiff()}
          hide={!hasActiveFilter()}
          onFilter={handleFilter}
          onClear={handleClearFilters}
          hideFilters={hideFilters}
          onHide={setHideFilters}
          loading={loading}
        >
          <KeywordsActiveFilters
            value={keywords}
            onChange={setKeywords}
            separateByComma={separateKeywordsByComma}
          />

          <ActiveFiltersItem
            label='Matéria'
            value={subject}
            onChange={setSubject}
          />

          <ActiveFiltersItem
            label='Ciclo de estudo'
            value={cicle}
            onChange={setCicle}
          />

          <ActiveFiltersItem label='Ano' value={year} onChange={setYear} />

          <ActiveFiltersItem label='Banca' value={board} onChange={setBoard} />
        </ActiveFilters>
      </FiltersContainer>

      <ResponsiveFilter.Button
        onClick={() => setOpen(true)}
        totalFilters={getTotalActivedFilters()}
      />

      <ResponsiveFilter.Modal
        open={open}
        setOpen={setOpen}
        onFilter={handleFilter}
        onClear={handleClearFilters}
      >
        <ResponsiveFilterList>
          <KeywordFilterInput
            value={keywords}
            onChange={setKeywords}
            onSearch={handleFilter}
          />

          <SelectWithSearch
            label='Matéria'
            options={filtersOptions.subjects}
            value={subject}
            setOption={setSubject}
            responsive
          />

          <SelectWithSearch
            label='Ciclo de estudo'
            options={optionsCicles}
            value={cicle}
            setOption={setCicle}
            disabled={!subject || subject.length <= 0}
            responsive
          />

          <SelectWithSearch
            label='Ano'
            options={filtersOptions.years}
            value={year}
            setOption={setYear}
            responsive
          />

          <SelectWithSearch
            label='Banca'
            options={filtersOptions.boards}
            value={board}
            setOption={setBoard}
            responsive
          />
        </ResponsiveFilterList>

        <AdditionalFilters>
          <span>Exibir</span>
          <ExtraOptionsContainer responsive>
            <RadioGroup
              name='question-type'
              value={extraSelected}
              onChange={(_, value) => setExtraSelected(Number(value))}
              style={{ margin: '8px 0px 0px 8px' }}
            >
              {extraOptions.map(({ value, label }) => (
                <CustomFormControlLabel
                  value={value}
                  control={<Radio color='default' />}
                  label={label}
                  selected={value === extraSelected}
                />
              ))}
            </RadioGroup>
          </ExtraOptionsContainer>
        </AdditionalFilters>

        <ActiveFiltersList>
          <KeywordsActiveFilters
            value={keywords}
            onChange={setKeywords}
            separateByComma={separateKeywordsByComma}
            responsive
          />

          <ActiveFiltersItem
            label='Matéria'
            value={subject}
            onChange={setSubject}
            responsive
          />

          <ActiveFiltersItem
            label='Ciclo de estudo'
            value={cicle}
            onChange={setCicle}
          />

          <ActiveFiltersItem label='Ano' value={year} onChange={setYear} />

          <ActiveFiltersItem label='Banca' value={board} onChange={setBoard} />
        </ActiveFiltersList>
      </ResponsiveFilter.Modal>
    </div>
  );
}

export default Filters;
