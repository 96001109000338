import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from 'material-ui/styles';
import classNames from 'classnames';
import Paper from 'material-ui/Paper';

const styles = theme => ({
  root: {
    width: '100%',
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    color: theme.palette.text.clearGrey,
    flexDirection: 'column',
    '&:hover': {
      color: theme.palette.text.primary
    }
  },
  card: {
    ...theme.transitions.card,
    flexDirection: 'column',
    paddingBottom: '0px',
    justifyContent: 'space-between',
    '&:hover $icon': {
      color: theme.palette.orange
    },
    '&$active': {
      boxShadow: theme.shadows[26],
      width: '100%'
    }
  },
  row: {
    cursor: 'pointer',
    alignItems: 'center',
    minHeight: '70px',
    padding: '8px 8px 8px 16px',
    [theme.breakpoints.up('sm')]: {
      padding: '8px 32px',
    },
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    '&$active': {
      borderBottom: '1px solid rgba(0,0,0,0.1)'
    }
  },
  expandableList: {
    paddingBottom: '16px'
  },
  left: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    minWidth: 'fit-content'
  },
  middle: {
    flex: 1,
    justifyContent: 'center'
  }, 
  right: {
    flex: 1,
    justifyContent: 'flex-end'
  },
  active: {
  },
  
})

class Card extends Component {
  static propTypes = {
    customClasses: PropTypes.object,
    left: PropTypes.element,
    middle: PropTypes.element,
    right: PropTypes.element,
    list: PropTypes.element,
    onClick: PropTypes.func,
    active: PropTypes.bool
  }

  static defaultProps = {
    customClasses: {},
    onClick: () => {},
  }

  render() {
    const {classes, customClasses, left, middle, right, onClick, list, active} = this.props
    
    return (
      <div className={classNames(classes.root, active ? classes.active : '', customClasses.root)}>
        <Paper className={classNames(classes.card, active ? classes.active : '', customClasses.card)}  >
          <div className={classNames(classes.row, active ? classes.active : '', customClasses.row)} onClick={onClick}>
            <div className={classNames(classes.left, customClasses.left)}>{left}</div>
            <div className={classNames(classes.middle, customClasses.middle)}>{middle}</div>
            <div className={classNames(classes.right, customClasses.right)}>{right}</div>
          </div>
          {active && list ? <div className={classes.expandableList}>{list}</div> : null}
        </Paper>
      </div>
    )
  }
}


export default withStyles(styles)(Card)
