import {
  SET_COMMENTS,
  ADD_COMMENT,
  SET_ANSWERS,
  ADD_ANSWERS,
  PAGINATION_ADD_COMMENTS,
  PAGINATION_ADD_ANSWERS,
} from '../actions/questionsCommentsActions';
const initialState = {};

const moveArrayElement = (array, from, to) => {
  const el = array[from];
  array.splice(from, 1);
  array.splice(to, 0, el);
};

export default (previousState = initialState, { type, payload }) => {
  switch (type) {
    case SET_COMMENTS:
      return {
        ...previousState,
        [`${payload.resource}_${payload.questionId}`]: {
          ...previousState[`${payload.resource}_${payload.questionId}`],
          comments: payload.comments,
        },
      };
    case ADD_COMMENT: {
      const commentQuestion =
        previousState[`${payload.resource}_${payload.questionId}`];
      const commentQuestionComments = commentQuestion.comments;

      commentQuestionComments.unshift(payload.comment);
      if (payload.resource === 'questions') {
        moveArrayElement(commentQuestionComments, 1, 0);
      }

      return {
        ...previousState,
        [`${payload.resource}_${payload.questionId}`]: {
          ...previousState[`${payload.resource}_${payload.questionId}`],
          comments: commentQuestionComments,
        },
      };
    }
    case PAGINATION_ADD_COMMENTS: {
      const commentQuestion =
        previousState[`${payload.resource}_${payload.questionId}`];
      const commentQuestionComments = commentQuestion.comments;

      if (payload.comments && payload.comments.length) {
        commentQuestionComments.push(...payload.comments);
      }

      return {
        ...previousState,
        [`${payload.resource}_${payload.questionId}`]: {
          ...previousState[`${payload.resource}_${payload.questionId}`],
          comments: commentQuestionComments,
        },
      };
    }
    case SET_ANSWERS:
      const commentQuestion =
        previousState[`${payload.resource}_${payload.questionId}`];
      const commentQuestionComments = commentQuestion.comments;

      if (!commentQuestionComments || !commentQuestionComments.length) {
        return { ...previousState };
      }

      // Procuro index do comentário no array de comentários
      const commentIndex = commentQuestionComments.findIndex(
        (prevComment) => prevComment._id === payload.commentId
      );

      if (commentIndex !== -1) {
        commentQuestionComments[commentIndex].answerItems = payload.answers;
      }

      return {
        ...previousState,
        [`${payload.resource}_${payload.questionId}`]: {
          ...previousState[`${payload.resource}_${payload.questionId}`],
          comments: commentQuestionComments,
        },
      };

    case ADD_ANSWERS: {
      const commentQuestion =
        previousState[`${payload.resource}_${payload.questionId}`];
      const commentQuestionComments = commentQuestion.comments;

      if (!commentQuestionComments || !commentQuestionComments.length) {
        return { ...previousState };
      }

      const commentIndex = commentQuestionComments.findIndex(
        (prevComment) => prevComment._id === payload.commentId
      );

      if (commentIndex === -1) {
        return { ...previousState };
      }

      if (
        commentQuestionComments[commentIndex].answerItems &&
        commentQuestionComments[commentIndex].answerItems.length
      ) {
        commentQuestionComments[commentIndex].answerItems.unshift(
          payload.answer
        );
      } else {
        commentQuestionComments[commentIndex].answerItems = [payload.answer];
      }

      return {
        ...previousState,
        [`${payload.resource}_${payload.questionId}`]: {
          ...previousState[`${payload.resource}_${payload.questionId}`],
          comments: commentQuestionComments,
        },
      };
    }
    case PAGINATION_ADD_ANSWERS: {
      const commentQuestion =
        previousState[`${payload.resource}_${payload.questionId}`];
      const commentQuestionComments = commentQuestion.comments;

      if (!commentQuestionComments || !commentQuestionComments.length) {
        return { ...previousState };
      }

      const commentIndex = commentQuestionComments.findIndex(
        (prevComment) => prevComment._id === payload.commentId
      );

      if (commentIndex === -1) {
        return { ...previousState };
      }

      if (
        commentQuestionComments[commentIndex].answerItems &&
        commentQuestionComments[commentIndex].answerItems.length
      ) {
        commentQuestionComments[commentIndex].answerItems.push(
          ...payload.answers
        );
      } else {
        commentQuestionComments[commentIndex].answerItems = [payload.answers];
      }

      return {
        ...previousState,
        [`${payload.resource}_${payload.questionId}`]: {
          ...previousState[`${payload.resource}_${payload.questionId}`],
          comments: commentQuestionComments,
        },
      };
    }
    case '@comments/DELETE_COMMENT': {
      const commentQuestion =
        previousState[`${payload.resource}_${payload.questionId}`];
      const commentQuestionComments = commentQuestion.comments;

      if (!commentQuestionComments || !commentQuestionComments.length) {
        return { ...previousState };
      }

      // Procuro index do comentário no array de comentários
      const commentIndex = commentQuestionComments.findIndex(
        (prevComment) => prevComment._id === payload.commentId
      );

      commentQuestionComments.splice(commentIndex, 1);

      return {
        ...previousState,
        [`${payload.resource}_${payload.questionId}`]: {
          ...previousState[`${payload.resource}_${payload.questionId}`],
          comments: commentQuestionComments,
        },
      };
    }
    case '@comments/DELETE_COMMENT_ANSWER': {
      const commentQuestion =
        previousState[`${payload.resource}_${payload.questionId}`];
      const commentQuestionComments = commentQuestion.comments;

      if (!commentQuestionComments || !commentQuestionComments.length) {
        return { ...previousState };
      }

      // Procuro index do comentário no array de comentários
      const commentIndex = commentQuestionComments.findIndex(
        (prevComment) => prevComment._id === payload.parentCommentId
      );

      // Procuro index da resposta do comentários no array de answerItems
      const answerIndex = commentQuestionComments[
        commentIndex
      ].answerItems.findIndex((answer) => answer._id === payload.commentId);

      if (answerIndex !== -1) {
        commentQuestionComments[commentIndex].answerItems.splice(
          answerIndex,
          1
        );
      }

      return {
        ...previousState,
        [`${payload.resource}_${payload.questionId}`]: {
          ...previousState[`${payload.resource}_${payload.questionId}`],
          comments: commentQuestionComments,
        },
      };
    }
    case '@comments/UPDATE_ANSWER_COUNT': {
      const commentQuestion =
        previousState[`${payload.resource}_${payload.questionId}`];
      const commentQuestionComments = commentQuestion.comments;

      const commentIndex = commentQuestionComments.findIndex(
        (comment) => comment._id === payload.commentId
      );

      if (commentIndex === -1) {
        return { ...previousState };
      }

      if (payload.type === 'INCREMENT') {
        commentQuestionComments[commentIndex].answers += 1;
      } else if (payload.type === 'DECREMENT') {
        commentQuestionComments[commentIndex].answers -= 1;
      }

      return {
        ...previousState,
        [`${payload.resource}_${payload.questionId}`]: {
          ...previousState[`${payload.resource}_${payload.questionId}`],
          comments: commentQuestionComments,
        },
      };
    }
    default:
      return previousState;
  }
};
