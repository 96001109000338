import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { DropTarget, DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Card from './Card';
import compose from 'recompose/compose';
import { trackMetric as trackMetricAction } from '../../actions/plataformActions';

const style = {
	width: '100%',
}

const styleEmpty = {
	textAlign: 'center',
	fontFamily: '"Proxima Nova", "Roboto", "Helvetica", "Arial", "sans-serif"',
	fontSize: "16px",
	fontWeight: 400,
	paddingBottom: '8px',
	color: '#F06D1A'
}

const cardTarget = {
	drop() {},
}

// @DragDropContext(HTML5Backend)
// @DropTarget('card', cardTarget, connect => ({
// 	connectDropTarget: connect.dropTarget(),
// }))

class Container extends Component {
	static propTypes = {
		connectDropTarget: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
		this.moveCard = this.moveCard.bind(this)
    this.findCard = this.findCard.bind(this)
		this.state = {
			cards: props.items
		}
	}

	moveCard(_id, atIndex) {
		const { card, index } = this.findCard(_id)
		this.setState(
			update(this.state, {
				cards: {
					$splice: [[index, 1], [atIndex, 0, card]],
				},
			}), () => this.props.changeOrder(this.state.cards.map(({_id}, order) => {return {_id, order}}))
		)
	}

	findCard(_id) {
		const { cards } = this.state
		const card = cards.filter(c => c._id === _id)[0]

		return {
			card,
			index: cards.indexOf(card),
		}
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.items !== this.props.items) {
			this.setState({cards: nextProps.items})
		}
		// if(nextProps.items.length !== this.props.items.length) {
		// 	this.props.changeOrder(this.state.cards.map(({_id}, order) => {return {_id, order: (order-1) * 10}}))
		// }
	}

	scrollToFichamento = (card) => {
		const elem = document.getElementById(card._id); 
		  elem && elem.scrollIntoView({block: "start", behavior: "smooth"})
		  
		this.props.trackMetric('Caderno - Ativação', {
			target: 'navigation',
			trigger: 'click',
			resource: card._id,
			parentResource: card.notebook
		});
	}	

	render() {
		const { connectDropTarget } = this.props
		const { cards } = this.state
		return connectDropTarget(
			<div style={style}>
				{cards.length > 0 ? cards.map(card => (
					<Card
						key={card._id}
						_id={card._id}
						title={card.title}
						content={card.content}
						moveCard={this.moveCard}
						findCard={this.findCard}
						onClick={() => this.scrollToFichamento(card)}
					/>
				)) : <div style={styleEmpty}>Você ainda não possui fichamentos nesse caderno.</div>}
			</div>,
		)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		trackMetric: (name, data) => dispatch(trackMetricAction(name, data))
	}
}

export default compose(
    DragDropContext(HTML5Backend),
    DropTarget('card', cardTarget, connect => ({
        connectDropTarget: connect.dropTarget(),
	})),
	connect(null, mapDispatchToProps)
)(Container);
  