import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setResponsive } from "../../actions/plataformActions";
// import { Container } from './styles';

function ResizeListener() {
  const dispatch = useDispatch();

  useEffect(() => {
    let timeoutID = null;

    function updateSize() {
      clearTimeout(timeoutID);
      timeoutID = setTimeout(() => {
        const width = window.innerWidth;
        if (width > 1000) {
          dispatch(setResponsive(false));
        } else {
          dispatch(setResponsive(true));
        }
      }, 250);
    }
    updateSize();

    window.addEventListener("resize", updateSize);

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return <div />;
}

export default ResizeListener;
